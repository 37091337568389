import { httpGrow } from "../http-common";


const getFreight = (query) => {
  return httpGrow.get(
    `/additionalFreight?pageNumber=${query.pageNumber}&pageSize=${query.size}`
  );
};
const getCuringUniqueCode = () => {
  return httpGrow.get(`/curingunit/CuringCode`);
};


const getCuringById = ({ id }) => {
  return httpGrow.get(
    `culture/{cuCultureId}?cuCultureId=${id}&pageNumber=0&pageSize=10`
  );
};

const updateFreight = (data) => {
  return httpGrow.put(`/additionalFreight`, data);
};


const createFreight = (data) => {
  return httpGrow.post(
    `/additionalFreight`, data
  );
};

const deleteFreigth = ({id}) => {
  return httpGrow.delete(`/additionalFreight/${id}`)
}

// const searchCuringUnitName = ({
//   tobaccoGroup,
//   status,
//   isNew,
//   countryCode,
//   curingUnitName,
//   page,
//   size,
// }) => {
//   const params = {
//     tobaccoGroup,
//     status,
//     isNew,
//     countryCode,
//     curingUnitName,
//     pageNumber: page,
//     pageSize: size,
//   };
//   const filteredEntries = Object.entries(params).filter(([key, value]) => value != "");
//   let newParams = Object.fromEntries(filteredEntries);
//   newParams['pageNumber'] = params.pageNumber
//   return httpGrow.get('/curingunit/search', { params: newParams });
// };



const searchFreightData = (keyword) => {
  return httpGrow.get(`/additionalFreight/search?pageNumber=0&pageSize=10&additionalFreightGroupDescription=${keyword}`);
};


const searchFreightFullData = () => {
  return httpGrow.get(`/additionalFreight?pageNumber=0&pageSize=10`);
};



const getAllCuringData = (countryCode) => {
  return httpGrow.get(`curingunit/allCuringUnits?countryCode=${countryCode}`);
};


const filterFreigth = (query) => {
  return httpGrow.get(`additionalFreight/search?pageNumber=${query?.pageNumber}&pageSize=${query?.size || 50}&additionalFreightGroupDescription=${query?.keyword||''}&isActive=${query?.status !== undefined ? query.status : ''}`);
};


const filterFreigthAutocomplete = (keyword) => {
  return httpGrow.get(`/additionalFreight/search?pageNumber=0&pageSize=10&additionalFreightGroupDescription=${keyword}`);
};

const FreightService = {
  getFreight,
  getCuringUniqueCode,
  createFreight,
  getAllCuringData,
  getCuringById,
  searchFreightData,
  updateFreight,
  deleteFreigth,
  searchFreightFullData,
  filterFreigth,
  filterFreigthAutocomplete
};
export default FreightService;

