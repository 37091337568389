import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  ListItemIcon,
} from "@mui/material";
import * as Yup from "yup";
import "./CropStage.css";
import Layout from "components/structure/Layout";
import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { SnackbarContext } from "context/snackBarContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CheckboxTick from "assets/CheckboxTick.svg";
import CheckboxUntick from "assets/CheckboxUntick.svg";
import CustomButton from "utils/CustomButton";
import { formatDateDetails } from "utils/utils";
import CropStageService from "services/CropStageService";
import { AuthData } from "auth/AuthWarpper";
const validationSchema = Yup.object({
  cropStageName: Yup.string()
    .required("Crop Stage Name is required")
    .max(50, "Crop Stage must be less than 50 characters")
    .test(
      'no-special-characters',
      'Crop Stage Name cannot contain special characters',
      value => /^[a-zA-Z0-9\s]+$/.test(value)
    )
    .test(
      'no-blank-spaces',
      'Crop Stage Name is required and cannot consist only of blank spaces',
      value => value.trim().length > 0
    ),
  cropStageCountryDetail: Yup.array()
    .min(1, "At least one item is required")
    .required("Country is required"),
});


const AddCropStage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = AuthData();
  const { id } = useParams();

  const { state } = location;
  const {
    title,
    status,
    countryArr,
    code,
    compliance,
    modifiedTime,
    modifiedBy,
    sequence,
    edit,
  } = state || {};
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [selected, setSelected] = useState([]);
  const [formData, setFormData] = useState({
    cropStageName: title || "",
    countryCode: code || "",
    isActive: status || false,
    visitCompliance: compliance || false,
    cropStageCountryDetail: selected
  });


  const { setOpenAlert, setSeverity, setMessage } =
    useContext(SnackbarContext);




  const handleChange = (e) => {
    const { name, value, checked } = e.target;
     const val =
      name === "isActive" || name === "visitCompliance" ? checked : value;

    setFormData({
      ...formData,
      [name]: val,
    });
  };


  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const response = await CropStageService.getCountryList();
        if (response.status === 200) {
          const data = response.data;
          // Concatenate the fetched countries with the existing countries array

          if (edit === true) {
            setCountries([
              ...countryArr,
              ...data.response.filter(
                (country) =>
                  !countryArr.some(
                    (existingCountry) =>
                      existingCountry.countryCode === country.countryCode
                  )
              ),
            ]);

            // Initialize selected countries with countryArr
            const selectedCountries = countryArr.filter((country) =>
              data.response.some(
                (selectedCountry) =>
                  selectedCountry.countryCode === country.countryCode
              )
            );
            setSelected(selectedCountries);

            setFormData({
              ...formData,
              cropStageCountryDetail: selectedCountries
            })
          } else {
            setCountries(data.response);
            setSelected(data.response);
            setFormData({
              ...formData,
              cropStageCountryDetail: data.response
            })
          }
        } else {
          console.error("Failed to fetch countries");
        }
      } catch (error) {
        console.error("Failed to fetch countries:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  const isAllSelected =
    countries.length > 0 && selected.length === countries.length;

  const handleChangeCountry = (event) => {
    const option = event.target.value;

    if (option[option.length - 1] === "all") {
      setSelected(selected.length === countries.length ? [] : countries);
      // Update formData to reflect selected or deselected countries
      setFormData(prevFormData => ({
        ...prevFormData,
        cropStageCountryDetail: selected.length === countries.length ? [] : countries
      }));
      return;
    }

    setSelected(option);
    setFormData(prevFormData => ({
      ...prevFormData,
      cropStageCountryDetail: option
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      setErrors({});
      await validationSchema.validate(formData, { abortEarly: false });


      // Constructing the payload object
      const payload = {
        cropStageName: formData.cropStageName,
        // countryDetail: selectedCountries.map((country) => ({
        //   countryCode: country,
        // })),
        cropStageCountryDetail: selected.map((country) => ({
          createdByUser: user.userName,
          modifiedByUser: user.userName,
          countryName: country.countryName,
          countryCode: country.countryCode,
        })),
        isActive: formData.isActive,
        sequence: sequence || 0,
        createdByUser: user.userName,
        modifiedByUser: user.userName,
        visitCompliance: formData.visitCompliance,
      };

      // Make a POST request using Axios
      const response = await CropStageService.createCropRegistration(payload);

      if (response.status === 201) {
        // Show success snackbar
        navigate("/cropStage");
        setSeverity("success");
        setMessage("Added successfully");
        setOpenAlert(true);
        setLoading(false)
      } else {
        console.error("Failed to submit form");
        // Show error snackbar
        setSeverity("error");
        setMessage("Failed to submit form");
        setOpenAlert(true);
      }
    } catch (error) {
      let newError = {};
      if (error?.name && error.name === "ValidationError") {
        error?.inner?.forEach((err) => {
          newError[err.path] = err.message;
          setErrors(newError || error?.message);
          return;
        });
      } else {
        setSeverity("error");
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
      }
      setLoading(false)

    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      setErrors({});
      await validationSchema.validate(formData, { abortEarly: false });


      const payload = {
        cropStageUid: id,
        cropStageName: formData.cropStageName,

        cropStageCountryDetail: selected.map((country) => ({
          modifiedByUser: user.userName,
          countryName: country.countryName,
          countryCode: country.countryCode,
          cropStageCountryUid: country.cropStageCountryUid,
        })),
        isActive: formData.isActive,
        sequenceId: sequence,
        modifiedByUser: user.userName,
        visitCompliance: formData.visitCompliance,
      };

      // Make a PUT request using Axios
      const response = await CropStageService.updateCropStageRegistration(
        payload
      );

      if (response.status === 200) {
        // Updated successfully

        navigate("/cropStage");

        // Show success snackbar
        setSeverity("success");
        setMessage("Updated successfully");
        setOpenAlert(true);
        setLoading(false)
      } else {
        console.error("Failed to update form");
        // Show error snackbar
        setSeverity("error");
        setMessage("Failed to update form");
        setOpenAlert(true);
      }
    } catch (error) {
      let newError = {};
      if (error?.name && error.name === "ValidationError") {
        error?.inner?.forEach((err) => {
          newError[err.path] = err.message;
          setErrors(newError || error?.message);
          return;
        });
      } else {
        setSeverity("error");
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
      }
    }
    setLoading(false)
  };

  return (
    <Layout title="Farmer’s Sustainability Management Portal">
      <div className="GridItem">
        <div>{id ? "Edit Crop Stage" : "Add Crop Stage"}</div>
        {id && (
          <Box>
            <div className="detailsText">
              Last Modified By :
              <span className="spanDetailsValue">{modifiedBy}</span>
            </div>
            <div className="detailsText">
              Last Modified Date :
              <span className="spanDetailsValue">
                {formatDateDetails(modifiedTime)}
              </span>
            </div>
          </Box>
        )}
      </div>
      <Paper sx={{ p: 2 }}>
        <Grid container>
          <Grid
            item
            md={4}
            xs={12}
            sx={{
              display: { md: "flex", xs: "block" },
              alignItems: "center",
              flexDirection: "end",
            }}
          >
            <InputLabel required sx={{ width: "10rem" }} htmlFor="my-input">
              Crop Stage Name
            </InputLabel>
            <Box>
              <TextField
                sx={{
                  width: { xs: "100%", md: "14rem" },
                  marginTop: { xs: "1rem", md: "0" },
                }}
                id="cropStageName"
                value={formData.cropStageName}
                name="cropStageName"
                onChange={handleChange}
                error={!!errors.cropStageName}
                disabled={edit}
              />
              {errors.cropStageName && (
                <FormHelperText error={true}>
                  {errors.cropStageName}
                </FormHelperText>
              )}
            </Box>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            sx={{
              display: { md: "flex", xs: "block" },
              alignItems: "center",
              marginTop: { xs: "1rem", md: "0" },
            }}
          >
            <InputLabel sx={{ width: "10rem" }} htmlFor="my-input">
              Country
            </InputLabel>
            <FormControl
              sx={{ width: { xs: "100%", md: "14rem" }, minHeight: 50 }}
            >
              <Select
                labelId="mutiple-select-label"
                name="cropStageCountryDetail"
                multiple
                value={selected}
                onChange={handleChangeCountry}
                renderValue={(selected) =>
                  selected.map((select) => select.countryName).join(", ")
                }
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 250,
                      overflowY: "auto",
                    },
                  },
                }}
              >
                <MenuItem value="all">
                  <ListItemIcon>
                    <Checkbox
                      checked={isAllSelected}
                      indeterminate={
                        selected.length > 0 &&
                        selected.length < countries.length
                      }
                      icon={<img src={CheckboxUntick} />}
                      checkedIcon={<img src={CheckboxTick} />}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Select All" />
                </MenuItem>
                {countries.map((option) => (
                  <MenuItem key={option.countryName} value={option}>
                    <ListItemIcon>
                      <Checkbox
                        checked={selected.indexOf(option) > -1}
                        icon={<img src={CheckboxUntick} />}
                        checkedIcon={<img src={CheckboxTick} />}
                      />
                    </ListItemIcon>
                    <ListItemText primary={option.countryName} />{" "}
                  </MenuItem>
                ))}
              </Select>
              {errors.cropStageCountryDetail && (
                <FormHelperText error={true}>
                  {errors.cropStageCountryDetail}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box sx={{ marginBottom: { xs: "0", md: 0 }, marginTop: "5px" }}>
              <InputLabel>Active</InputLabel>
            </Box>
            <Box sx={{ marginLeft: "2rem" }}>
              <Checkbox name="isActive"
                checked={formData.isActive}
                onChange={(e)=>handleChange(e)}
                icon={<img src={CheckboxUntick} />}
                checkedIcon={<img src={CheckboxTick} />}
              />
            </Box>
          </Grid>

          <Grid
            item
            md={4}
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: { md: "2rem", xs: "1rem" },
            }}
          >
            <InputLabel
              sx={{ margin: "0 2.1rem 0  0rem" }}
              id="mutiple-select-label"
            >
              Visit Compliance
            </InputLabel>
            <Checkbox
              name="visitCompliance"
              onChange={handleChange}
              checked={formData.visitCompliance}
              icon={<img src={CheckboxUntick} alt="untick" />}
              checkedIcon={<img src={CheckboxTick} alt="tick" />}
            />
          </Grid>
        </Grid>
        <ButtonContainer>
          <CustomButton
            onClick={() => navigate("/cropStage")}
            label="Cancel"
            variant="cancel"
          />
          {edit === true ? (
            <CustomButton loading={loading} onClick={handleUpdate} label="Update" />
          ) : (
            <CustomButton loading={loading} onClick={handleSubmit} label=" Submit" />
          )}
        </ButtonContainer>
      </Paper>
    </Layout>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 3rem 0 2rem 0;
`;

export default AddCropStage;
