import { httpGrow } from "../http-common";

const getSoilParameter = async (query) => {
  const response = await httpGrow.get(
    `/soil-analysis-parameters/list?pageNumber=${query?.pageNumber}&size=${query?.size}`
  );
  return response;
};

const getDataType = async () => {
  const response = await httpGrow.post(`/picklist`, {
    pickListCodes: ["ASAPD"],
  });
  return response;
};

const createSoilParameter = (data) => {
  return httpGrow.post(`/soil-analysis-parameters`, data);
};

const updateSoilParameter = (data) => {
  return httpGrow.put(`/soil-analysis-parameters`, data);
};

const deleteSoilParameter = (soilAnalysisParametersId) => {
  return httpGrow.delete(`/soil-analysis-parameters/deleteSoilAnalysisParameters/${soilAnalysisParametersId}`);
};
// const searchTobacco = async (query) => {
//   const response = await httpGrow.get(
//     `/tobaccoCompaniesCompetitors/search?pageNumber=${query?.pageNumber}&pageSize=${
//       query?.size || 50
//     }&tobaccoCompanyName=${query?.tobaccoCompanyName || ""}&
//     tccCountryCode=${
//       query?.tccCountryCode || ""
//     }&region=${query?.region || ""}&status=${
//       query?.status !== undefined ? query.status : ""
//     }`
//   );
//   return response;
// };


const searchSoilParameter =async(queryString)=>{
  const response =await httpGrow.get(`/soil-analysis-parameters/find${queryString}`)
  return response;
}

const SoilParameterService = {
  getSoilParameter,
  createSoilParameter,
  updateSoilParameter,
  deleteSoilParameter,
  searchSoilParameter,
  getDataType
  
};

export default SoilParameterService;
