// NotFound.js
import React from 'react';

const NotFound = () => {
  return (
    <div style={{textAlign:"center"}}>
      <h1>404 - Not Found</h1>
      <p>The page you are looking for does not exist,<br/>
        Or you are not authorized to view.</p>
    </div>
  );
};

export default NotFound;
