import { httpGrow } from "../http-common";

const getEquipment = async (query) => {
  const response = await httpGrow.get(
    `/equipment/list?pageNumber=${query?.pageNumber}&size=${query?.size}`
  );
  return response;
};

const getEquipmentUniqueCode = () => {
  const response = httpGrow.get(`/equipment/code`);
  //  console.log("jkl",response);
  return response;
};

const createEquipment = (data) => {
  return httpGrow.post(`/equipment`, data);
};

const deleteEquipment = async (equipmentID) => {
  // const equipmentID = data?.equipmentId;
  const response = await httpGrow.delete(`/equipment/${equipmentID}`);
  return response;
};

const searchEquipment = async (query) => {
  const response = await httpGrow.get(
    `/equipment/find?pageNumber=${query?.pageNumber}&size=${query?.size}&userInput=${query?.userInput}`
  );
  return response;
};

const EquipmentService = {
  getEquipment,
  createEquipment,
  deleteEquipment,
  getEquipmentUniqueCode,
  searchEquipment,
};

export default EquipmentService;
