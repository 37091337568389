
import { http } from "../http-common";

const fetchCountries = () => {
  return http.get('/countries');
};
const fetchProfiles = () => {
  return http.get('/profile/list?pageNumber=0&size=1000');
};
const CommonService = {
  fetchCountries,
  fetchProfiles
};

export default CommonService;

