// ListTransporterConfiguration.jsx
import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Checkbox,
  FormControl,
  Grid,
  Typography,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import TransporterConfigurationTable from "./TransporterConfigurationTable";
import TransporterConfigurationService from "services/TransporterConfigurationService";
import debounce from "lodash/debounce";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { AuthData } from "auth/AuthWarpper";
import { useTranslation } from "react-i18next";
import SearchTextInput from "components/common/SearchTextInput";

const ListTransporterConfiguration = () => {
  const { t, i18n } = useTranslation();
  const {user} = AuthData();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [menu, setMenu] = useState([]);
  const [showUsedRecords, setShowUsedRecords] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const debouncedSearch = useCallback(
    debounce((value) => {
      fetchData(0, itemsPerPage, value);
    }, 10),
    [itemsPerPage]
  );

  useEffect(() => {
    if (isInitialLoad) {
      // Use getListAPI for initial load
      fetchListData(currentPage, itemsPerPage);
      setIsInitialLoad(false);
    } else {
      // Use getAPI for subsequent loads or searches
      fetchData(currentPage, itemsPerPage, searchKeyword);
    }
    
    if (currentPage === 0) {
      fetchMenuData();
    }
    
    return () => {
      debouncedSearch.cancel(); // Clean up on unmount
    };
  }, [currentPage, itemsPerPage, isInitialLoad]);

  const handleSearchChange = (value) => {
    setSearchKeyword(value);
    setIsInitialLoad(false); // Ensure we use getAPI when searching
    
    if (value === "") {
      fetchData(0, itemsPerPage); // Fetch data without search keyword when input is cleared
    } else {
      debouncedSearch(value);
    }
  };

  const fetchListData = (
    pageNumber,
    size
  ) => {
    setLoading(true);
    const query = {
      pageNumber,
      size
    };
    TransporterConfigurationService.getListAPI(query)
      .then((res) => {
        const results = res?.data?.response?.["Transporter Configuration"] || [];
        setData(results);
        setTotalItems(res?.data?.response?.totalItems || 0);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setSeverity("error");
        setOpenAlert(true);
        setData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchData = (
    pageNumber,
    size,
    keyword = undefined
  ) => {
    setLoading(true);
    const query = {
      pageNumber,
      size,
      keyword
    };
    TransporterConfigurationService.getAPI(query)
      .then((res) => {
        const results = res?.data?.response?.["Transporter Configuration"] || [];
        setData(results);
        setTotalItems(res?.data?.response?.totalItems || 0);
        if (results.length === 0 && keyword?.length > 0) {
          setMessage("No records found.");
          setSeverity("info");
          setOpenAlert(true);
        }
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setSeverity("error");
        setOpenAlert(true);
        setData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchMenuData = () => {
    setLoading(true);
    TransporterConfigurationService.getAPI({
      pageNumber: currentPage,
      size: 50,
    })
      .then((res) => {
        // const menuItems =
        //   res?.data?.response?.localityFieldTechnicianConfig?.map(
        //     (e) => e.localityName
        //   ) || [];
        const menuItems =
          res?.data?.response?.localityFieldTechnicianConfig
            ?.filter((e) => e.useThisRecord === true) // Filter only the records where useThisRecord is true
            .map((e) => e.localityName) || [];
        setMenu(menuItems);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setSeverity("error");
        setOpenAlert(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleShowUsedRecords = () => {
    setShowUsedRecords(!showUsedRecords);
    fetchData(0, itemsPerPage);
    fetchMenuData();
  };

  const paginate = (pageNumber, itemsPerPage) => {
    setCurrentPage(pageNumber - 1);
    setItemsPerPage(itemsPerPage);
    fetchData(pageNumber - 1, itemsPerPage);
  };

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={t("transporterConfiguration.pageTitle")}
      background="#ffffff"
      useNewPagination={true}
    >
      <Grid
        container
        spacing={2}
        sx={{
          background: "#EFEFEF",
          borderRadius: "10px",
          p: 2,
          maxWidth: "100%",
          margin: "auto",
          paddingBottom: "8px",
        }}
      >
        {/* Filter and arrow */}
        <Grid item xs={12} style={{ paddingTop: "0px", paddingLeft: "0px" }}>
          <Typography variant="h8" display="flex">
            <ArrowDropDownIcon />
            {t("filtersLable")}
          </Typography>
        </Grid>

        {/* Search input field */}
        <Grid item xs={9.5} style={{ paddingLeft: "0px", marginBottom: "10px" }}>
          <FormControl fullWidth>
            <SearchTextInput
              label={t("transporterConfiguration.searchField")}
              value={searchKeyword}
              onChange={(e) => handleSearchChange(e.target.value)}
              placeholder={t("")}
            />
          </FormControl>
        </Grid>

        {/* Empty Grid for Space */}
        <Grid item xs={.2}>

        </Grid>

        {/* <Grid item xs={3}>
          <Autocomplete
            disablePortal
            options={postalCodes}
            onChange={(event, newValue) =>
              handlePostalCodeSearchChange(event, newValue)
            }
            onInputChange={handlePostalCodeSearchChange}
            getOptionLabel={(option) => option.toString()} // Convert number to string
            renderInput={(params) => (
              <TextField {...params} label={t("postalCode")} fullWidth />
            )}
          />
        </Grid> */}

        {/* Region Field
        <Grid item xs={3}>
          <Autocomplete
            disablePortal
            options={regionOptions || []}
            value={region}
            onChange={(event, newValue) => handleRegionChange(event, newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("region")}
                fullWidth
                required
                // error={!region}
                // helperText={!region ? "Region is required" : ""}
              />
            )}
          />
        </Grid> */}

        {/* City Field
        <Grid item xs={3}>
          <Autocomplete
            disablePortal
            options={cityOptions || []} // Now this is an array of city names
            onChange={(event, newValue) => handleCityChange(event, newValue)}
            renderInput={(params) => (
              <TextField {...params} label={t("city")} fullWidth />
            )}
          />
        </Grid> */}

        <Grid item xs={2} style={{ paddingTop: "12px", paddingLeft: "0px", marginTop: "5px" }}>
          <Typography variant="subtitle2">
            <Checkbox
              sx={{ paddingLeft: "0px" }}
              checked={showUsedRecords}
              onChange={handleShowUsedRecords}
              color="primary"
            />
            {t("usedRecords")}
          </Typography>
        </Grid>
      </Grid>

      {/* // Table Section */}
      {loading ? (
        <Typography variant="h6" align="center">
          {t("loading")}
        </Typography>
      ) : (
        <>
          {showUsedRecords ? (
            data.filter((row) => row.useThisRecord).length > 0 ? (
              <TransporterConfigurationTable
                TableData={data.filter((row) => row.useThisRecord)}
              />
            ) : (
              // Set alert if no used records found
              <>
                {setMessage("cityConfiguration.noUsedRecordsFound")}
                {setSeverity("error")}
                {setOpenAlert(true)}
                <Typography variant="h6" align="center">
                  {t("notfound")}
                </Typography>
              </>
            )
          ) : (
            totalItems > 0 && <TransporterConfigurationTable TableData={data} />
          )}
        </>
      )}
    </Layout>
  );
};

export default ListTransporterConfiguration;
