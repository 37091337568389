import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
import { adultSchema } from "utils/validations";
import { updateAdults } from "slices/adultsSlice";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useState } from "react";
import RenderField from "utils/RenderField";
import { addAdultsField } from "./FormConfig";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "90%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "#f8f8f8",
  border: "none",
  overflow: "none",
};

export default function AdultsModal(props) {
  const dispatch = useDispatch();
  const [propertytype, setpropertytype] = useState("");

  const adultInformation = useSelector((state) => state?.adult?.addAdult);

  const [values, setValues] = useState({});
  console.log("🚀 ~ PropertyRegisterationModal ~ values:", values);
  const [errors, setErrors] = useState({});
  const [dateError, setDateError] = useState(false);
  const [tenureType, setTenureType] = useState("");
  const [id, setId] = useState(""); 
  const [edit,setEdit] = useState(false)

  const handleChangePropertyType = (event) => {
    setpropertytype(event.target.value);
  };
  console.log("🚀 ~ GeneralInformation ~ adultInformation:", adultInformation);

  useEffect(() => {
    if (props.editData) {
      setValues(props.editData);
      setEdit(true);
    } else {
      setValues({});
      setEdit(false);
    }
  }, [props.editData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleDateChange = (date, fieldName) => {
    if (!date || date === "Invalid Date") {
      setDateError(true);
      setValues((prevValues) => ({
        ...prevValues,
        [fieldName]: "",
      }));
      return;
    }

    setDateError(false);

    const dateValue = new Date(date);
    if (isNaN(dateValue.getTime())) {
      setDateError(true);
      setValues((prevValues) => ({
        ...prevValues,
        [fieldName]: "",
      }));
      return;
    }

    const year = dateValue.getFullYear();
    const month = String(dateValue.getMonth() + 1).padStart(2, "0");
    const day = String(dateValue.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setValues((prevValues) => ({
      ...prevValues,
      [fieldName]: formattedDate,
    }));
  };

  //   const handleDateChange = (date, fieldName) => {
  //     if (!date || date === "Invalid Date") {
  //       setDateError(true);
  //       setValues((prevValues) => ({
  //         ...prevValues,
  //         [fieldName]: "",
  //       }));
  //       return;
  //     }

  //     setDateError(false);

  //     const dateValue = new Date(date);
  //     if (isNaN(dateValue.getTime())) {
  //       setDateError(true);
  //       setValues((prevValues) => ({
  //         ...prevValues,
  //         [fieldName]: "",
  //       }));
  //       return;
  //     }

  //     const year = dateValue.getFullYear();
  //     const month = String(dateValue.getMonth() + 1).padStart(2, "0");
  //     const day = String(dateValue.getDate()).padStart(2, "0");
  //     const formattedDate = `${year}-${month}-${day}`;

  //     setValues((prevValues) => ({
  //       ...prevValues,
  //       [fieldName]: formattedDate,
  //     }));
  //   };

  useEffect(() => {
    setId(uuidv4());
  }, [props.open]);

  // Rest of your component...

  useEffect(() => {
    if (props.editData) {
      setValues(props.editData);
      setEdit(true);
    } else {
      setValues({});
      setEdit(false);
    }
  }, [props.editData]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await adultSchema.validate(values, { abortEarly: false });
      setErrors({});
      const {
        partnerPropertyName,
        cpfNumber,
        regDt,
        relationshipType,
        propertyId
      } = values;
      dispatch(
        updateAdults([
          {
            id,
            propertyId,
            partnerPropertyName,
            cpfNumber,
            regDt,
            relationshipType,
          },
        ])
      );
      setValues({});
      props.onClose();
      console.log("dispatch Successfully");
    } catch (err) {
      const validationErrors = {};
      console.log("fail", validationErrors);

      err?.inner?.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
    }
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   try {
  //     await propertySchema.validate(values, { abortEarly: false });
  //     setErrors({});

  //     dispatch(updateProperty([values]));
  //     props.onClose()
  //     console.log("dispatch Successfully");
  //   } catch (err) {
  //     const validationErrors = {};
  //     err?.inner?.forEach((error) => {
  //       validationErrors[error.path] = error.message;
  //     });
  //     setErrors(validationErrors);
  //   }
  // };

  const getCoordinates = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setValues((prevValues) => ({
            ...prevValues,
            latitude: latitude.toFixed(6),
            longitude: longitude.toFixed(6),
          }));
        },
        (error) => {
          console.error("Error getting coordinates:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const handleClear = (field) => {
    setValues((prevValues) => ({
      ...prevValues,
      [field]: "",
    }));
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const formFields = Object.keys(formValues);
  //   let newFormValues = { ...formValues };
  //   for (let index = 0; index < formFields.length; index++) {
  //     const currentField = formFields[index];
  //     const currentValue = formValues[currentField].value;
  //     if (currentValue === "") {
  //       newFormValues = {
  //         ...newFormValues,
  //         [currentField]: {
  //           ...newFormValues[currentField],
  //           error: true,
  //         },
  //       };
  //     } else {
  //       let newFormValues = { ...formValues };
  //       for (let index = 0; index < formFields.length; index++) {
  //         const currentField = formFields[index];
  //         const currentValue = formValues[currentField].value;
  //         newFormValues = {
  //           ...newFormValues,
  //           [currentField]: currentValue,
  //         };
  //       }
  //       newFormValues["propertytype"] = propertytype;
  //       props.addNewProperty(newFormValues);
  //       props.onClose();
  //     }
  //   }
  //   setFormValues(newFormValues);
  // };
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormValues({
  //     ...formValues,
  //     [name]: {
  //       ...formValues[name],
  //       value,
  //       error: false,
  //     },
  //   });
  // };
  console.log("🚀 ~ PropertyRegisterationModal ~ propertyType:", tenureType);

  const handleChangeProperty = (event) => {
    setTenureType(event.target.name);
  };

  // const [formValues, setFormValues] = React.useState({
  //     name: {
  //         value: '',
  //         error: false,
  //         errorMessage: 'You must enter a name'
  //     },
  //     cpf: {
  //         value: '',
  //         error: false,
  //         errorMessage: 'You must enter a cpf'
  //     },
  //     relationship: {
  //         value: '',
  //         error: false,
  //         errorMessage: 'You must enter a Relationship'
  //     },
  //     includedate: {
  //         value: '',
  //         error: false,
  //         errorMessage: 'You must enter a includedate'
  //     }
  // })
  // const currencies = [
  //     {
  //         value: 'Crop Year',
  //         label: 'cropyear',
  //     },
  //     {
  //         value: 'Dependent',
  //         label: 'dependent',
  //     },
  //     {
  //         value: 'Study',
  //         label: 'study',
  //     },
  //     {
  //         value: 'Grade Level in school',
  //         label: 'gradelevelinschool',
  //     },
  // ];
  // const handleSubmit = (e) => {
  //     e.preventDefault();
  //     const formFields = Object.keys(formValues);
  //     let newFormValues = { ...formValues }
  //     for (let index = 0; index < formFields.length; index++) {
  //         const currentField = formFields[index];
  //         const currentValue = formValues[currentField].value;
  //         if (currentValue === '') {
  //             newFormValues = {
  //                 ...newFormValues,
  //                 [currentField]: {
  //                     ...newFormValues[currentField],
  //                     error: true
  //                 }
  //             }
  //         } else {
  //             let newFormValues = { ...formValues }
  //             for (let index = 0; index < formFields.length; index++) {
  //                 const currentField = formFields[index];
  //                 const currentValue = formValues[currentField].value;
  //                 newFormValues = {
  //                     ...newFormValues, [currentField]: currentValue
  //                 }
  //             }
  //             newFormValues['propertytype'] = propertytype;
  //             props.addNewAdult(newFormValues);
  //             props.onClose();

  //         }

  //     }
  //     setFormValues(newFormValues)
  // }
  // const handleChange = (e) => {
  //     const { name, value } = e.target;
  //     setFormValues({
  //         ...formValues,
  //         [name]: {
  //             ...formValues[name],
  //             value,
  //             error: false
  //         }
  //     })
  // }

  const handleCloseModal = () => {
    setEdit(false);
    setValues({});
    props.onClose();
  };


  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => props.onClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            className="property-modal-heading"
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Partners on the property
          </Typography>
          <Box
            sx={{
              minHeight: 180,
              marginLeft: "0px",
              width: "100%",
              "& .MuiTextField-root": { m: 1, color: "red", width: "25ch" },
              "& .MuiFormLabel-root": {
                color: "rgba(16, 42, 98, 1)",
                "& .MuiFormLabel-asterisk": {
                  color: "rgba(239, 0, 0, 1)",
                  fontSize: "18px",
                },
              },
            }}
          >
            <form
              style={{ padding: "38px" }}
              autoComplete="off"
              noValidate
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              {addAdultsField.slice(0, 5).map((field) => (
                <RenderField
                  key={field.name}
                  field={field}
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  handleDateChange={handleDateChange}
                  handleClear={handleClear}
                />
              ))}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                  paddingBottom: "1rem",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#737D86",
                    padding: " 6px, 16px, 6px, 16px",
                    borderRadius: " 4px",
                    top: "25px",
                    left: "20px",
                  }}
                  variant="contained"
                  type="secondary"
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    backgroundColor: "#004F9F",
                    top: "25px",
                    left: "20px",
                  }}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Save Partner Registeration
                </Button>
              </div>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
