import styled from "@emotion/styled";
import { Box } from "@mui/material";
import React from "react";
import Pagination from "./pages/Hierarchy/Paginationcc";
import Pagination2024 from "./pages/Hierarchy/Paginationcc2024";
const Footer = ({
  itemsPerPage,
  totalItems,
  paginate,
  currentPage,
  cancel,
  useNewPagination,
}) => {
  // const version = process.env.REACT_APP_VERSION;
  const version = process.env.REACT_APP_VERSION;
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const currentYear = new Date().getFullYear();

  const FooterContainer = styled.div`
    position: fixed;
    bottom: 0;
    background: #112a63;

    width: 100%;
    z-index: 999;
  `;
  // height: ${totalItems ? "7.5rem" : "3.5rem"};
  console.log({
    itemsPerPage,
    totalItems,
    paginate,
    currentPage,
    cancel,
  });

  return (
    <FooterContainer>
      {/* {cancel && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "column", sm: "column", md: "row" },
            paddingTop: "10px",
            background: "white",
            padding: "10px 30px",
            alignItems: "center",
          }}
        :
          <Box>Cancel</Box>
          <Box>Save</Box>
        </Box>
      )} */}
      {totalItems > 0 &&
        (useNewPagination ? (
          <Pagination2024
            itemsPerPage={itemsPerPage}
            totalItems={totalItems}
            paginate={paginate}
            currentPage={currentPage}
          />
        ) : (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={totalItems}
            paginate={paginate}
            currentPage={currentPage}
          />
        ))}
      {/* <div> */}
      <Box
        sx={{
          color: "white",
          display: "flex",
          height: "20px",
          alignItems: "center",
          justifyContent: "space-between",
          padding: { md: "0 2rem", xs: "0 1rem" },
        }}
      >
        <Box
          sx={{
            fontWeight: 300,
            // fontSize: { md: "0.875rem", xs: "0.7rem" },
            fontSize: "10px !important",
          }}
        >
          <span>&#169; {currentYear}, British American Tobacco</span>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: { md: "0.5rem", xs: "0" },
            fontWeight: 300,
            // fontSize: { md: "0.875rem", xs: "0.7rem" },
            fontSize: "10px !important",
          }}
        >
          <span>Ver: {version} </span>
          {environment && <span> - {environment} Environment</span>}
        </Box>
      </Box>
      {/* </div> */}
      {/* <RainbowContainer /> */}
    </FooterContainer>
  );
};

const RainbowContainer = styled.div`
  background: linear-gradient(
      90deg,
      #0e2b63 30.4785%,
      rgba(0, 0, 0, 0) 30.4785%
    ),
    linear-gradient(90deg, #004f9f 52.15788%, rgba(0, 0, 0, 0) 52.15788%),
    linear-gradient(90deg, #00b1eb 64.72807%, rgba(0, 0, 0, 0) 64.72807%),
    linear-gradient(90deg, #ef7d00 73.22551%, rgba(0, 0, 0, 0) 73.22551%),
    linear-gradient(90deg, #ffbb00 79.62792%, rgba(0, 0, 0, 0) 79.62792%),
    linear-gradient(90deg, #50af47 86.69237%, rgba(0, 0, 0, 0) 86.69237%),
    linear-gradient(90deg, #afca0b 92.0305%, rgba(0, 0, 0, 0) 92.0305%),
    linear-gradient(90deg, #5a328a 97.0083%, rgba(0, 0, 0, 0) 97.0083%),
    linear-gradient(90deg, #e72582 100%, rgba(0, 0, 0, 0) 100%);
  height: 1.5rem;
`;
export default Footer;
