//Field names
export const SUPPLY_DESCRIPTION_FIELD_NAME = "supplyDescription";
export const UOM_FIELD_NAME = "uom";
export const SUPPLY_TYPE_FIELD_NAME = "supplyType";
export const STATUS_FIELD_NAME = "status";
export const NITROGEN_PERC_FIELD_NAME = "nitrogenPerc";
export const OCCURENCE_CATEGORY= "occurenceCategory";
export const OCCURENCE= "occurence";
export const PHOSPHORUS_PERC_FIELD_NAME = "phosphorusPerc";
export const POTASSIUM_PERC_FIELD_NAME = "potassiumPerc";
export const CODE_TBL_FIELD_NAME = "code";
export const DESCRIPTION_TBL_FIELD_NAME = "description";
export const ACTIONS_TBL_FIELD_NAME = "actions";
export const STATUS_FILTER_FIELD_NAME = "fieldNotebookStatus";
export const SUPPLY_TYPE_FILTER_FIELD_NAME = "fieldNotebookSupplyType";
export const SEARCH_FILTER_FIELD_NAME = "searchSupplies";
export const SEARCH_FILTER_PLACEHOLDER = "Search";
export const ACTIVE_FILTER_KEY = "isActive";

//Field labels
export const SUPPLY_DESCRIPTION_FIELD_LABEL = "Agricultural Supply Description";
export const UOM_FIELD_LABEL = "Unit of Measurement";
export const SUPPLY_TYPE_FIELD_LABEL = "Supply Type";
export const STATUS_FIELD_LABEL = "Status";
export const NITROGEN_PERC_FIELD_LABEL = "Nitrogen %";
export const PHOSPHORUS_PERC_FIELD_LABEL = "Phosphorus %";
export const POTASSIUM_PERC_FIELD_LABEL = "Potassium %";
export const OCCURENCE_CATEGORY_LABEL= "Occurence Category";
export const OCCURENCE_LABEL= "Occurence";
export const ADD_AGRICULTURAL_SUPPLY_PAGE_HEADING = "Supply Registration";
export const LIST_AGRICULTURAL_SUPPLY_PAGE_HEADING = "Supplies List – Field Notebook";
export const CODE_TBL_FIELD_LABEL = "Code";
export const DESCRIPTION_TBL_FIELD_LABEL = "Description";
export const UOM_TBL_FIELD_LABEL = "Uom";
export const ACTIONS_TBL_FIELD_LABEL = "Actions";
export const EDIT_TBL_FIELD_LABEL = "Edit";
export const DETAILS_TBL_FIELD_LABEL = "Details";
export const ACTIVE_FIELD_LABEL = "Active";
export const INACTIVE_FIELD_LABEL = "Inactive";

//LOVs
export const STATUS_LOV = "ASFNBS";
export const UOM_LOV = "ASFNB";
