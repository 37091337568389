import { http } from "../http-common";

const getSeasonRegistration = (year,countryCode) => {
  return http.get(
    `/season-registration?cropYear=${year}&countryCode=${countryCode}`
  );
};

const createSeasonRegistration = (data) => {
  return http.post("/season-registration?copyParam=false", data);
};

const updateSeasonRegistration = (data) => {
  return http.put(`/season-registration?copyParam=false`, data);
};

const handleYesRegistration = (res, data) => {
  return http.post(`/season-registration?copyParam=${res}`, data);
};

const getCropYears = (data) => {
    return http.get(`/cropYears`, data);
  };

const addCropStageCalendar = (data) => {
  return http.post('/crop-stage-calendar',data);
};

const SeasonRegistrationService = {
  getSeasonRegistration,
  createSeasonRegistration,
  updateSeasonRegistration,
  handleYesRegistration,
  getCropYears,
  addCropStageCalendar
};

export default SeasonRegistrationService;
