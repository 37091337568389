// PlantingTable.jsx
import React, { useEffect, useState, useContext } from "react";
import { Typography, Box, Button, Modal, Tooltip } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import "./Planting.css";
import _ButtonEdit_ from "assets/_ButtonEdit_.svg";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import { useNavigate, useLocation } from "react-router-dom";
import PlantingService from "services/PlantingService";
import { SnackbarContext } from "context/snackBarContext";
import {
  DELETE_SUCCESS_MSG
} from 'utils/AgriErrorMsg';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 580,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  // p: 4,
};

const PlantingTable = (props) => {
  const navigate = useNavigate();
  const [plantingData, setPlantingData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false); // Modal state
  const [deleteId, setDeleteId] = useState(null); // ID of the record to delete
  console.log("ITEMSPerPage", props.plantingData)
  useEffect(() => {
    setPlantingData(props.plantingData);
    setOriginalData(props.plantingData);
  }, [props.plantingData]);

  const handleDeleteClick = (plantingTypeId) => {
    setDeleteId(plantingTypeId); // Store the ID of the record to delete
    setOpen(true); // Open the confirmation modal
  };

  const handleDeleteConfirm = async () => {
    try {
      setLoading(true);
      const response = await PlantingService.deletePlanting(deleteId); // Delete by stored ID
      if (response.status === 200) {
        setMessage(DELETE_SUCCESS_MSG);
        setSeverity("success");
        setOpenAlert(true);
        setPlantingData((prevData) =>
          prevData.filter((item) => item.ptPlantingId !== deleteId)
        );
      } else {
        setMessage("Failed to delete planting type");
        setSeverity("error");
        setOpenAlert(true);
      }
    } catch (error) {
      setMessage("Error deleting planting type");
      setSeverity("error");
      setOpenAlert(true);
      console.error("Error deleting planting type:", error);
    } finally {
      setLoading(false);
      setOpen(false); // Close the modal after deletion
    }
  };

  const handleClose = () => {
    setOpen(false); // Close the modal without deleting
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...plantingData].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setPlantingData(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        return <ArrowUpwardIcon fontSize="small" />;
      } else if (sortConfig.direction === "desc") {
        return <ArrowDownwardIcon fontSize="small" />;
      }
    }
    // Default icon (ArrowDownward) when no sorting is applied
    return <ArrowDownwardIcon fontSize="small" />;
  };

  return (
    <div className="table-container">
      <table className="custom-table">
        <thead>
          <tr>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => handleSort("plantTypeDescription")}
              >
                Planting Type {getSortIcon("plantTypeDescription")}
              </div>
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  justifyContent: "left",
                }}
                onClick={() => handleSort("status")}
              >
                Status {getSortIcon("status")}
              </div>
            </th>
            <th className="actions-header">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  justifyContent: "left",
                }}
              >
                Actions
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {plantingData?.map((row, index) => (
            <tr
              key={index}
            // onClick={() =>
            //   navigate("/addplantingRegistration", {
            //     state: { row: row, edit: false },
            //   })
            // }
            >
              <td
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/viewplantingRegistration", {
                    state: { row: row, edit: false },
                  })
                }
              >
                {row.plantTypeDescription}
              </td>
              <td
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/viewplantingRegistration", {
                    state: { row: row, edit: false },
                  })
                }
              >
                {row.status ? "Active" : "Inactive"}
              </td>
              <td className="actions-cell">
                <Tooltip
                  title="EDIT"
                  placement="top"
                  arrow
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -5],
                        },
                      },
                    ],
                  }}
                >
                  <img
                    className="btn edit-btn"
                    src={_ButtonEdit_}
                    alt="Edit Button"
                    onClick={() =>
                      navigate("/editplantingRegistration", {
                        state: { row: row, edit: true },
                      })
                    }
                  />
                </Tooltip>

                <Tooltip
                  title="DELETE"
                  placement="top"
                  arrow
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -5],
                        },
                      },
                    ],
                  }}
                >
                  <img
                    className="btn delete-btn"
                    onClick={() => handleDeleteClick(row.ptPlantingId)}
                    src={_ButtonDelete_}
                    alt="Delete"
                  />
                </Tooltip>

              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal for delete confirmation */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "520px",
            margin: "auto",
            mt: 25,
            height: "200px",
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
            >
              Delete Confirmation
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, fontWeight: 400 }}
            >
              Are you sure you want to delete this record? Deleted records
              cannot be recovered.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              mb: 1,
            }}
          >
            <Button
              onClick={handleClose}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              No
            </Button>
            <Button
              onClick={handleDeleteConfirm}
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#004F9F" },
              }}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default PlantingTable;
