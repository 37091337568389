import { httpGrow } from "../http-common";

const getGreenManure = (query) => {
  return httpGrow.get(
    `/greenManure?pageNumber=${query.pageNumber}&pageSize=${query.size}`
  );
};
const getCuringUniqueCode = () => {
  return httpGrow.get(`/curingunit/CuringCode`);
};

const getCuringById = ({ id }) => {
  return httpGrow.get(
    `culture/{cuCultureId}?cuCultureId=${id}&pageNumber=0&pageSize=10`
  );
};

const updateGreen = (data) => {
  return httpGrow.put(`/greenManure`, data);
};

const createGreenManure = (data) => {
  return httpGrow.post(`/greenManure`, data);
};

const deleteManure = ({ id }) => {
  return httpGrow.delete(`/greenManure/${id}`);
};

// const searchCuringUnitName = ({
//   tobaccoGroup,
//   status,
//   isNew,
//   countryCode,
//   curingUnitName,
//   page,
//   size,
// }) => {
//   const params = {
//     tobaccoGroup,
//     status,
//     isNew,
//     countryCode,
//     curingUnitName,
//     pageNumber: page,
//     pageSize: size,
//   };
//   const filteredEntries = Object.entries(params).filter(([key, value]) => value != "");
//   let newParams = Object.fromEntries(filteredEntries);
//   newParams['pageNumber'] = params.pageNumber
//   return httpGrow.get('/curingunit/search', { params: newParams });
// };

const searchManureData = (keyword) => {
  return httpGrow.get(
    `/greenManure/search?pageNumber=0&pageSize=10&keyword=${keyword}`
  );
};

const searchManureDataFull = () => {
  return httpGrow.get(`/greenManure/search?pageNumber=0&pageSize=50`);
};

const getAllCuringData = (countryCode) => {
  return httpGrow.get(`curingunit/allCuringUnits?countryCode=${countryCode}`);
};

const filterManure = async (query) => {
  return httpGrow.get(
    `/greenManure/search?pageNumber=${query?.pageNumber}&pageSize=${
      query?.size || 50
    }&keyword=${query?.keyword || ""}&status=${
      query?.status !== undefined ? query.status : ""
    }`
  );
};

const filterManureAutocomplete = (keyword) => {
  return httpGrow.get(
    `/greenManure/search?pageNumber=0&pageSize=10&keyword=${keyword}`
  );
};

const GreenManureService = {
  getGreenManure,
  getCuringUniqueCode,
  createGreenManure,
  getAllCuringData,
  getCuringById,
  searchManureData,
  updateGreen,
  deleteManure,
  searchManureDataFull,
  filterManure,
  filterManureAutocomplete,
};
export default GreenManureService;
