import { tr } from "date-fns/locale";
import { options } from "utils/utils";

export const generalFormConfig = [
  {
    name: "farmerCode",
    label: "Code",
    type: "text",
    required: true,
    disabled: true,
  },
  {
    name: "cpf",
    label: "CPF",
    type: "text",
    required: true,
  },
  {
    name: "farmerFullName",
    label: "Name",
    type: "text",
    required: true,
  },
  {
    name: "dateOfBirth",
    label: "Date of Birth",
    type: "date",
    required: true,
  },
  {
    name: "contactNumber",
    label: "Telephone",
    type: "text",
    required: true,
  },
  {
    name: "email",
    label: "Email",
    type: "text",
    required: true,
  },
  {
    name: "nationalId",
    label: "Id Card",
    type: "number",
    required: true,
  },
  {
    name: "issuingAuthority",
    label: "Issuing Authority",
    type: "text",
    required: true,
  },
  {
    name: "emissionDt",
    label: "Emission Date",
    type: "date",
    required: true,
  },

  {
    name: "gender",
    label: "Gender",
    type: "select",
    options: [
      // { value: "", label: "None" },
      { value: "Male", label: "Male" },
      { value: "Female", label: "Female" },
    ],
    required: true,
  },
  {
    name: "nationalityCountry",
    label: "Nationality Country",
    type: "text",
    // options: [
    //   // { value: "", label: "None" },
    //   { value: "Brazil - BR", label: "Brazil - BR" },
    //   { value: "Protugul - PT", label: "Protugul - PT" },
    //   { value: "Spain - ESP", label: "Spain - ESP" },
    // ],
    required: true,
  },
  {
    name: "nationality",
    label: "Nationality",
    type: "text",
    disabled: true,
    required: true,
  },
  {
    name: "region",
    label: "Region",
    type: "select",
    options: [
      // { value: "", label: "None" },
      // { value: "Brazil - BR", label: "Brazil - BR" },
      // { value: "Protugul - PT", label: "Protugul - PT" },
      // { value: "Spain - ESP", label: "Spain - ESP" },
    ],
    required: true,
  },
  {
    name: "city",
    label: "City",
    type: "select",
    options: [],
    required: true,
  },
  {
    name: "locality",
    label: "Locality",
    type: "text",
    required: true,
  },
  {
    name: "zipCode",
    label: "Postal Code",
    type: "text",
    required: true,
  },
  {
    name: "residence",
    label: "Residence",
    type: "text",
    // required: true,
  },
  {
    name: "dripIrrigation",
    label: "Farmer Use Drip Irrigation",
    type: "select",
    options: [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ],
    required: true,
  },
  {
    name: "externalCode",
    label: "External Code",
    type: "text",
  },
  {
    name: "farmerCountry",
    label: "Country",
    type: "select",
    options: [],
    required: true,
  },
  {
    name: "regionOfBirth",
    label: "Region Of Birth",
    type: "select",
    options: [
      // { value: "", label: "None" },
      // { value: "Brazil - BR", label: "Brazil - BR" },
      // { value: "Protugul - PT", label: "Protugul - PT" },
      // { value: "Spain - ESP", label: "Spain - ESP" },
    ],
    required: true,
  },
  {
    name: "maritalStatus",
    label: "Marital Status",
    type: "select",
    options: [
      // { value: "", label: "None" },
      { value: "Married", label: "Married" },
      { value: "Single", label: "Single" },
    ],
    required: true,
  },
  {
    name: "spouseName",
    label: "Spouse Name",
    type: "text",
    required: false,
  },
  {
    name: "fatherName",
    label: "Father's Name",
    type: "text",
    required: true,
  },
  {
    name: "motherName",
    label: "Mother's Name",
    type: "text",
    required: true,
  },
  {
    name: "minorsUnder18",
    label: "Minors Under 18",
    type: "select",
    options: [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ],
    required: true,
  },
  {
    name: "adultsOver18",
    label: "Adults Over 18",
    type: "select",
    options: [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ],
    required: true,
  },
  {
    name: "latitude",
    label: "Latitude",
    type: "text",
    required: false,
  },
  {
    name: "longitude",
    label: "Longitude",
    type: "text",
    required: false,
  },
  {
    name: "altitude",
    label: "Altitude",
    type: "text",
  },
];

export const taxFormConfig = [
  {
    name: "bankName",
    label: "Bank Name",
    type: "text",
    // required: true,
  },
  {
    name: "bankCode",
    label: "Bank Code",
    type: "number",
    disabled: true,
  },
  {
    name: "branchNumber",
    label: "Branch Number",
    type: "text",
    // required: true,
  },
  {
    name: "accountType",
    label: "Account Type",
    type: "select",
    options: [],
    // required: true,
  },
  {
    name: "accountNumber",
    label: "Account Number",
    type: "text",
    // required: true,
  },
];

export const addPropertyField = [
  {
    name: "ruralEnvironmentalType",
    label: "Rural Environment Registry",
    type: "text",
    // required: true,
  },
  {
    name: "farmer",
    label: "Farmer",
    type: "select",
    options:[],
    required: true,
  },
  {
    name: "property",
    label: "Property",
    type: "select",
    options: [
      // { value: "", label: "None" },
      // { value: "Brazil - BR", label: "Brazil - BR" },
      // { value: "Protugul - PT", label: "Protugul - PT" },
      // { value: "Spain - ESP", label: "Spain - ESP" },
    ],
    disabled: true,
  },
  {
    name: "ha",
    label: "Hectares",
    type: "text",
    required: true,
  },
  {
    name: "region",
    label: "Region",
    type: "select",
    options: [
      // { value: "", label: "None" },
      { value: "Brazil - BR", label: "Brazil - BR" },
      { value: "Protugul - PT", label: "Protugul - PT" },
      { value: "Spain - ESP", label: "Spain - ESP" },
    ],
    required: true,
  },
  {
    name: "locality",
    label: "Locality",
    type: "text",
    required: true,
  },
  {
    name: "city",
    label: "City",
    type: "select",
    options: [
      // { value: "", label: "None" },
      { value: "Brazil - BR", label: "Brazil - BR" },
      { value: "Protugul - PT", label: "Protugul - PT" },
      { value: "Spain - ESP", label: "Spain - ESP" },
    ],
    required: true,
  },
  {
    name: "haProperty",
    label: "HA Property",
    type: "text",
    required: true,
  },
  {
    name: "haExploited",
    label: "HA Exploited",
    type: "text",
    required: true,
  },
  {
    name: "estimatedValue",
    label: "Estimated Value",
    type: "text",
    required: true,
  },
  {
    name: "agriculturalLandTax",
    label: "Agricultural Land Tax",
    type: "text",
    required: true,
  },
  {
    name: "value",
    label: "Value",
    type: "text",
    required: true,
  },
  {
    name: "registryOfficeCity",
    label: "Registry Office City",
    type: "select",
    options: [
      // { value: "", label: "None" },
      // { value: "Brazil - BR", label: "Brazil - BR" },
      // { value: "Protugul - PT", label: "Protugul - PT" },
      // { value: "Spain - ESP", label: "Spain - ESP" },
    ],
    required: true,
  },
  {
    name: "registrationNumber",
    label: "Registration Number",
    type: "text",
    required: true,
  },
  {
    name: "book",
    label: "Book",
    type: "text",
    required: true,
  },
  {
    name: "page",
    label: "Page",
    type: "text",
    required: true,
  },
  {
    name: "registryOfficeName",
    label: "Registry Office Name",
    type: "text",
    required: true,
  },
  {
    name: "regDate",
    label: "Registration Date",
    type: "date",
    required: true,
  },
  {
    name: "personType",
    label: "Person Type",
    type: "select",
    options: [
      // { value: "", label: "None" },
      { value: "Individual", label: "Individual" },
      { value: "Company", label: "Company" },
    ],
    required: true,
  },
  {
    name: "cpfNumber",
    label: "Id Number",
    type: "number",
    required: true,
  },
  {
    name: "name",
    label: "Name",
    type: "text",
    required: true,
  },
  {
    name: "hectares",
    label: "Hectares",
    type: "text",
    required: true,
  },
];

export const addDependentField = [
  {
    name: "cyCropYear",
    label: "Crop Year",
    type: "select",
    options: [
      // { value: "", label: "None" },
      { value: "2021", label: "2021" },
      { value: "2022", label: "2022" },
      { value: "2023", label: "2023" },
      { value: "2024", label: "2024" },
    ],
    required: true,
  },
  {
    name: "dependentName",
    label: "Name",
    type: "text",
    required: true,
  },
  {
    name: "dateOfBirth",
    label: "Date Of Birth",
    type: "date",
    required: true,
  },
  {
    name: "dependentRelationshipType",
    label: "Relationship",
    type: "select",
    options: [
      // { value: "", label: "None" },
      { value: "Father", label: "Father" },
      { value: "Mom", label: "Mom" },
    ],
    required: true,
  },
  {
    name: "fatherName",
    label: "Father's Name",
    type: "text",
    required: true,
  },
  {
    name: "motherName",
    label: "Mother's Name",
    type: "text",
    required: true,
  },
  {
    name: "attendSchool",
    label: "Attends School",
    type: "select",
    options: [
      // { value: "", label: "None" },
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ],
    required: true,
  },
  {
    name: "schoolGrade",
    label: "School Grade",
    type: "select",
    options: [
      // { value: "", label: "None" },
      { value: "1", label: "1" },
      { value: "2", label: "2" },
    ],
    required: false,
  },
];

export const addAdultsField = [
  {
    name: "partnerPropertyName",
    label: "Name",
    type: "text",
    required: true,
  },
  {
    name: "cpfNumber",
    label: "CPF",
    type: "text",
    required: true,
  },
  {
    name: "regDt",
    label: "Date of Inclusion",
    type: "date",
    required: true,
  },
  {
    name: "relationshipType",
    label: "Relationship",
    type: "select",
    options: [
      { value: "Parent", label: "Parent" },
      { value: "Aunt", label: "Aunt" },
    ],
    required: true,
  },
];

export const environmentField = [
  {
    name: "environmentalLicenseNumber",
    label: "Environmental License Number",
    type: "text",
    required: false,
  },

  {
    name: "environmentalLicenseExpirationDt",
    label: "Expiration Date",
    type: "date",
    required: false,
  },
  {
    name: "grantNumber",
    label: "Grant Number",
    type: "text",
    required: false,
  },

  {
    name: "elGrantExpirationDt",
    label: "Grant Expiration Date",
    type: "date",
    required: false,
  },
];
