import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Layout from "components/structure/Layout";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  Autocomplete,
  Divider,
  FormLabel,
  Modal,
  FormControl,
  CircularProgress,
} from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import arrowLeft from "assets/arrowLeft.svg";
import Save from "assets/SaveNew.svg";
import SoilAnalysisService from "services/SoilAnalysisService";
import { SnackbarContext } from "context/snackBarContext";
import { useTranslation } from "react-i18next";
import debounce from "lodash/debounce";
import dayjs from "dayjs";
import { ADD_SUCCESS_MSG, UPDATE_SUCCESS_MSG } from "utils/AgriErrorMsg";
import MapLeaflet from "./MapLeaflet";

const convertDMSToDecimal = (dmsCoord) => {
  // Remove the degree symbol and split
  const match = dmsCoord.match(/(\d+)º(\d+)'([\d.]+)"([NSEW])/);

  if (!match) return null;

  const [, degrees, minutes, seconds, direction] = match;

  let decimal = Number(degrees) + Number(minutes) / 60 + Number(seconds) / 3600;

  // Adjust sign based on direction
  if (direction === "S" || direction === "W") {
    decimal = -decimal;
  }

  return decimal.toFixed(6);
};

const AddSoilAnalysis = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const formRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const [farmerOptions, setFarmerOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
    altitude: null,
  });
  const [selectedFarmer, setSelectedFarmer] = useState(null);
  const [collectionDate, setCollectionDate] = useState(null);
  const [resultDate, setResultDate] = useState(null);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [altitude, setAltitude] = useState("");
  const { edit, soilAnalysisData } = location.state || {};
  const [soilAnalysisDescription, setSoilAnalysisDescription] = useState("");
  const [soilAnalysisParameters, setSoilAnalysisParameters] = useState([]);
  const [soilParameterValues, setSoilParameterValues] = useState({});
  const { t } = useTranslation();
  const [errors, setErrors] = useState({
    soilAnalysisDescription: '',
    farmer: '',
    collectionDate: '',
    resultDate: '',
    latitude: '',
    longitude: '',
    altitude: '',
    // Additional error fields can be added here
  });

  useEffect(() => {
    console.log("Location state:", location.state);
    console.log("Edit value:", location.state?.edit);
  }, [location.state]);

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    // Validate required fields
    if (!soilAnalysisDescription.trim()) {
      newErrors.soilAnalysisDescription = "Soil Analysis Description is required";
      isValid = false;
    }

    if (!selectedFarmer) {
      newErrors.farmer = "Farmer is required";
      isValid = false;
    }

    if (!collectionDate) {
      newErrors.collectionDate = "Collection Date is required";
      isValid = false;
    }

    if (!resultDate) {
      newErrors.resultDate = "Result Date is required";
      isValid = false;
    }

    if (!latitude) {
      newErrors.latitude = "Latitude is required";
      isValid = false;
    }

    if (!longitude) {
      newErrors.longitude = "Longitude is required";
      isValid = false;
    }

    if (!altitude) {
      newErrors.altitude = "Altitude is required";
      isValid = false;
    }

    // Add the new validation for Collection Date and Result Date
    if (collectionDate && resultDate && collectionDate.isAfter(resultDate)) {
      newErrors.collectionDate = "Collection Date cannot be after Result Date";
      newErrors.resultDate = "Result Date cannot be before Collection Date";
      isValid = false;
    }

    // Validate soil parameters
    const activeParameters = soilAnalysisParameters.filter(
      (param) => param.sapStatus !== false
    );
    activeParameters.forEach((param) => {
      const value = soilParameterValues[param.sapAnalysisId];
      if (!value || value.trim() === "") {
        newErrors[`param_${param.sapAnalysisId}`] = `${param.sapAbbreviation} is required`;
        isValid = false;
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  useEffect(() => {
    fetchSoilAnalysisParameters();
  }, []);

  const fetchSoilAnalysisParameters = async () => {
    try {
      setLoading(true);
      const response = await SoilAnalysisService.getSoilAnalysisParameter();
      const addData = response.data.response.soilAnalysisParameters || [];
      console.log("respp:::", addData);
      setSoilAnalysisParameters(addData);
    } catch (error) {
      setMessage(error?.response?.data?.message || error?.message);
      setOpenAlert(true);
      setSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = () => setOpenMap(true);
  const handleClose = () => {
    setCoordinates({ lat: "", lng: "", altitude: "" }); // Reset coordinates inside the modal
    setOpenMap(false); // Close the modal
  };

  const handleMapClick = async (obj) => {
    setCoordinates({
      lat: obj?.latitude,
      lng: obj?.longitude,
      altitude: obj?.altitude,
    });
  };

  const handleSetLocation = () => {
    setLatitude(coordinates.lat);
    setLongitude(coordinates.lng);
    setAltitude(coordinates.altitude);
    handleClose();
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleDeleteConfirm = () => {
    setOpen(false);
    navigate("/soilAnalysis");
  };

  const handleParameterChange = (paramId, value) => {
    setSoilParameterValues((prevValues) => ({
      ...prevValues,
      [paramId]: value,
    }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      // Show a general snackbar message for unfilled fields
      setMessage(t("Please fill all the Fields"));
      setOpenAlert(true);
      setSeverity("error");
      return;
    }

    const dynamicSoilParameters = soilAnalysisParameters
      .filter((param) => param.sapStatus !== false)
      .map((param) => {
        const value = soilParameterValues[param.sapAnalysisId];
        let parameterValue = {
          spParameterInt: null,
          spParameterDecimal: null,
          spParameterText: null,
        };

        if (value !== undefined && value !== "") {
          switch (param.sapDataType) {
            case "Integer":
              parameterValue.spParameterInt = Number.isInteger(Number(value))
                ? Number(value)
                : null;
              break;
            case "Decimal":
              parameterValue.spParameterDecimal = !isNaN(parseFloat(value))
                ? parseFloat(value)
                : null;
              break;
            default:
              parameterValue.spParameterText = String(value);
          }
        }

        return {
          sapAnalysisId: param.sapAnalysisId,
          ...parameterValue,
          saStatus: true,
        };
      });

    const payload = {
      saAnalysisID: edit ? soilAnalysisData.saAnalysisID : undefined,
      saSoilAnalysisDescription: soilAnalysisDescription,
      farmerId: selectedFarmer?.farmerId || "",
      saCollectionDate: collectionDate
        ? collectionDate.format("DD/MM/YYYY")
        : null,
      saResultDate: resultDate ? resultDate.format("DD/MM/YYYY") : null,
      saLatitude: latitude,
      saLongitude: longitude,
      saAltitude: altitude,
      saStatus: false,
      countryId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      languageId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      soilParametersDto: dynamicSoilParameters,
      createdDateTimeUTC: edit
        ? soilAnalysisData.createdDateTimeUTC
        : new Date().toISOString(),
      createdByUser: edit ? soilAnalysisData.createdByUser : "string",
      modifiedDateTimeUTC: new Date().toISOString(),
      modifiedByUser: "string",
      isDelete: false,
    };

    console.log("payload", payload);

    const apiCall = edit
      ? SoilAnalysisService.updateSoilAnalysis(payload)
      : SoilAnalysisService.createSoilAnalysis(payload);

    apiCall
      .then(() => {
        setMessage(edit ? UPDATE_SUCCESS_MSG : ADD_SUCCESS_MSG);
        setSeverity("success");
        setOpenAlert(true);
        navigate("/soilAnalysis");
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  const fetchAllFarmers = useCallback(async () => {
    setLoading(true);
    try {
      const res = await SoilAnalysisService.getAllFarmer();
      const farmerResults = res?.data?.response || [];
      const farmerData = farmerResults.map((e) => ({
        farmerId: e.farmerId,
        farmerFullName: e.farmerFullName || "Unknown Farmer",
        farmerCode: e.farmerCode || "N/A",
      }));
      setFarmerOptions(farmerData);
    } catch (error) {
      setMessage(error?.response?.data?.message || error?.message);
      setOpenAlert(true);
      setSeverity("error");
    } finally {
      setLoading(false);
    }
  }, [setMessage, setOpenAlert, setSeverity]);

  // Fetch farmers based on search keyword
  const searchFarmers = useCallback(
    debounce(async (keyword) => {
      setLoading(true);
      try {
        const res = await SoilAnalysisService.searchFarmer({ keyword });
        const farmerResults = res?.data?.response.farmers || [];
        const farmerData = farmerResults.map((e) => ({
          farmerId: e.farmerId,
          farmerFullName: e.farmerFullName || "Unknown Farmer",
          farmerCode: e.farmerCode || "N/A",
        }));
        setFarmerOptions(farmerData);
      } catch (error) {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      } finally {
        setLoading(false);
      }
    }, 300),
    [setMessage, setOpenAlert, setSeverity]
  );

  // Trigger search when user types (not when selecting an option)
  const handleInputChange = (event, value, reason) => {
    if (reason === "input") {
      // Only trigger search if the user is typing
      setSearchQuery(value); // Update the input value
      if (value) {
        searchFarmers(value); // Call API with the current input value
      } else {
        fetchAllFarmers(); // Fetch all farmers when input is cleared
      }
    }
  };

  useEffect(() => {
    if (soilAnalysisData) {
      // Convert DMS to decimal
      const decimalLatitude = convertDMSToDecimal(soilAnalysisData.saLatitude);
      const decimalLongitude = convertDMSToDecimal(
        soilAnalysisData.saLongitude
      );

      setLatitude(decimalLatitude || "");
      setLongitude(decimalLongitude || "");

      console.log("soilAnalysisData:", soilAnalysisData);
      setSoilAnalysisDescription(
        soilAnalysisData.saSoilAnalysisDescription || ""
      );
      setSelectedFarmer({
        farmerId: soilAnalysisData.farmerId,
        farmerFullName: soilAnalysisData.farmerFullName,
        farmerCode: soilAnalysisData.farmerCode,
      });
      setFarmerOptions([
        {
          farmerId: soilAnalysisData.farmerId,
          farmerFullName: soilAnalysisData.farmerFullName,
          farmerCode: soilAnalysisData.farmerCode,
        },
      ]);
      setCollectionDate(
        soilAnalysisData.saCollectionDate
          ? dayjs(soilAnalysisData.saCollectionDate, "DD/MM/YYYY")
          : null
      );
      setResultDate(
        soilAnalysisData.saResultDate
          ? dayjs(soilAnalysisData.saResultDate, "DD/MM/YYYY")
          : null
      );
      setAltitude(soilAnalysisData.saAltitude || "");

      // Populate soilParameterValues with existing data
      const existingParameterValues = {};
      if (
        soilAnalysisData.soilParametersDtos &&
        Array.isArray(soilAnalysisData.soilParametersDtos)
      ) {
        soilAnalysisData.soilParametersDtos.forEach((param) => {
          if (param.spParameterInt !== undefined) {
            existingParameterValues[param.sapAnalysisId] =
              param?.spParameterInt?.toString();
          } else if (param.spParameterDecimal !== undefined) {
            existingParameterValues[param.sapAnalysisId] =
              param?.spParameterDecimal?.toString();
          } else if (param.spParameterText !== undefined) {
            existingParameterValues[param.sapAnalysisId] =
              param.spParameterText;
          }
        });
      }
      setSoilParameterValues(existingParameterValues);
    }
  }, [soilAnalysisData]);

  useEffect(() => {
    if (!edit) {
      fetchAllFarmers();
    }
  }, [edit, fetchAllFarmers]);

  return (
    <Layout title={t("Soil Analysis Registration Form")} background="#ffffff">
      <Box
        sx={{
          minHeight: "10%",
          flexGrow: 1,
          width: "100%",
          "& .MuiTextField-root": { m: 1 },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
          },
        }}
      >
        <form
          autoComplete="off"
          noValidate
          ref={formRef}
          onSubmit={handleFormSubmit}
        >
          <Box sx={{ display: "flex" }}>
            <ArrowDropDownIcon sx={{ marginRight: 1 }} />
            <Typography>{t("form")}</Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              bgcolor: "#EFEFEF",
              height: Object.keys(errors).length > 0 ? "150px" : "120px",
              marginTop: "-25px",
              borderRadius: "5px",
              padding: "5px",
              transition: "height 0.3s ease",
            }}
          >
            <Grid container spacing={2}>
              {/* Soil Analysis Description */}
              <Grid item xs={4}>
                <TextField
                  name="soilAnalysisDescription"
                  label={t(
                    "SoilAnalysisRegistration.formLabels.soilAnalysisDescription"
                  )}
                  variant="outlined"
                  fullWidth
                  required
                  value={soilAnalysisDescription}
                  disabled={edit === false}
                  onChange={(e) => setSoilAnalysisDescription(e.target.value)}
                  error={!!errors.soilAnalysisDescription}
                  helperText={errors.soilAnalysisDescription}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                  }}
                  InputProps={{
                    style: { height: "55px" },
                    inputProps: { maxLength: 50 },
                  }}
                />
              </Grid>

              {/* Farmer Dropdown */}
              <Grid item xs={4}>
                <FormControl fullWidth error={!!errors.farmer}>
                  <Autocomplete
                    options={farmerOptions}
                    loading={loading}
                    value={selectedFarmer}
                    disabled={edit === false}
                    getOptionLabel={(option) =>
                      `${option.farmerCode} - ${option.farmerFullName}`
                    }
                    onChange={(event, newValue) => {
                      setSelectedFarmer(newValue);
                    }}
                    onInputChange={handleInputChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("SoilAnalysisRegistration.formLabels.farmer")}
                        variant="outlined"
                        fullWidth
                        required
                        error={!!errors.farmer}
                        helperText={errors.farmer}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              {/* Collection Date */}
              <Grid item xs={2}>
                <FormControl fullWidth required error={!!errors.collectionDate}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label={t(
                        "SoilAnalysisRegistration.formLabels.collectionDate"
                      )}
                      value={collectionDate}
                      disabled={edit === false}
                      onChange={(newDate) => setCollectionDate(newDate)}
                      format="DD/MM/YYYY"
                      slotProps={{
                        textField: {
                          required: true,
                          error: !!errors.collectionDate,
                          helperText: errors.collectionDate,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>

              {/* Result Date */}
              <Grid item xs={2}>
                <FormControl fullWidth required error={!!errors.resultDate}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label={t(
                        "SoilAnalysisRegistration.formLabels.resultDate"
                      )}
                      value={resultDate}
                      disabled={edit === false}
                      onChange={(newDate) => setResultDate(newDate)}
                      minDate={collectionDate}
                      format="DD/MM/YYYY"
                      slotProps={{
                        textField: {
                          required: true,
                          error: !!errors.resultDate,
                          helperText: errors.resultDate,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              alignItems: "center",
              bgcolor: "#EFEFEF",
              height: "80px",
              marginTop: "-30px",
              borderRadius: "5px",
              padding: "5px",
            }}
          >
            <Grid container spacing={2}>
              {/* Latitude, Longitude, Altitude */}
              <Grid item xs={2}>
                <TextField
                  label={t("SoilAnalysisRegistration.formLabels.latitude")}
                  variant="outlined"
                  fullWidth
                  value={latitude}
                  disabled={edit === false}
                  onChange={(e) => setLatitude(e.target.value)}
                  type="number"
                  inputProps={{
                    step: "any",
                  }}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  label={t("SoilAnalysisRegistration.formLabels.longitude")}
                  variant="outlined"
                  fullWidth
                  value={longitude}
                  disabled={edit === false}
                  onChange={(e) => setLongitude(e.target.value)}
                  type="number"
                  inputProps={{
                    step: "any",
                  }}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  label={t("SoilAnalysisRegistration.formLabels.altitude")}
                  variant="outlined"
                  fullWidth
                  value={altitude}
                  disabled={edit === false}
                  onChange={(e) => setAltitude(e.target.value)}
                  type="number"
                  inputProps={{
                    step: "any",
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  sx={{
                    borderRadius: 1,
                    minWidth: 95,
                    minHeight: 50,
                    background: "#004F9F",
                    marginLeft: "10px",
                    marginTop: "9px",
                    width: "200px",
                  }}
                  variant="contained"
                  onClick={handleOpen}
                  disabled={edit === false}
                >
                  {t("SoilAnalysisRegistration.buttons.selectOnMap")}
                </Button>
                <Modal
                  open={openMap}
                  onClose={handleClose}
                  aria-labelledby="map-modal-title"
                  aria-describedby="map-modal-description"
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "60%",
                      height: "90%",
                      bgcolor: "background.paper",
                      boxShadow: 24,
                      borderRadius: 2,
                    }}
                  >
                    <Box
                      sx={{
                        background: "#102A62",
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderTopLeftRadius: "7px",
                        borderTopRightRadius: "7px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#ffffff",
                          fontSize: "20px",
                          fontWeight: 500,
                        }}
                      >
                        {t("SoilAnalysisRegistration.modals.selectGeoLocation")}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        p: 3,
                      }}
                    >
                      <MapLeaflet
                        setCoordinatesParent={(obj) => handleMapClick(obj)}
                      />
                      {/* Row for latitude, longitude, and altitude */}
                      <Grid container spacing={2} sx={{ marginTop: 1 }}>
                        <Grid item xs={4}>
                          <TextField
                            label={t(
                              "SoilAnalysisRegistration.formLabels.latitude"
                            )}
                            variant="outlined"
                            fullWidth
                            value={coordinates.lat || ""}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            label={t(
                              "SoilAnalysisRegistration.formLabels.longitude"
                            )}
                            variant="outlined"
                            fullWidth
                            value={coordinates.lng || ""}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            label={t(
                              "SoilAnalysisRegistration.formLabels.altitude"
                            )}
                            variant="outlined"
                            fullWidth
                            value={coordinates.altitude || ""}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                      </Grid>

                      {/* Divider and Buttons */}
                      <Divider sx={{ marginY: 2 }} />
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        gap={2}
                        marginTop={2}
                      >
                        <Button
                          style={{
                            backgroundColor: "#737D86",
                            padding: "6px 16px",
                            borderRadius: "4px",
                            minHeight: 40,
                          }}
                          onClick={handleClose}
                          variant="contained"
                        >
                          {t("cancel")}
                        </Button>
                        <Button
                          sx={{
                            borderRadius: 1,
                            //minWidth: 95,
                            minHeight: 40,
                            background: "#004F9F",
                            width: "150px",
                          }}
                          variant="contained"
                          onClick={handleSetLocation}
                        >
                          {t("SoilAnalysisRegistration.buttons.setLocation")}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Modal>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>

      {/* Divider */}
      <Box
        display="flex"
        alignItems="center"
        flexDirection={"row"}
        marginTop={"5px"}
      >
        <FormLabel
          sx={{
            ml: 1,
            color: "#000000",
            display: "flex",
          }}
        >
          {t("SoilAnalysisRegistration.parameters")}
        </FormLabel>
      </Box>
      <hr
        style={{
          color: "#004F9F",
          backgroundColor: "#004F9F",
          height: 2,
          borderColor: "#004F9F",
        }}
      />

      {loading ? (
        <CircularProgress />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "flex-start",
            bgcolor: "#EFEFEF",
            marginTop: "25px",
            borderRadius: "5px",
            padding: "10px",
          }}
        >
          <Grid container spacing={2}>
            {soilAnalysisParameters
              .filter((param) => param.sapStatus !== false)
              .map((param) => {
                const paramValue = soilParameterValues[param.sapAnalysisId] || "";

                // Safely split the value only if it's a string and contains a decimal point
                const decimalPart = param.sapDataType === "Decimal" && paramValue.includes(".")
                  ? paramValue.split(".")[1]
                  : "";

                // Check for decimal place errors
                const isDecimalError = param.sapDataType === "Decimal" && decimalPart.length > 2;

                // Check for length errors (excluding the decimal point)
                const isLengthError = param.sapDataType === "Decimal"
                  ? paramValue.replace(".", "").length > 10
                  : paramValue.length > 10;

                return (
                  <Grid item xs={2} key={param.sapAnalysisId}>
                    <TextField
                      name={param.sapAnalysisName}
                      label={`${param.sapAbbreviation} (${param.sapUom})`}
                      variant="outlined"
                      fullWidth
                      value={paramValue}
                      disabled={edit === false}
                      onChange={(e) => {
                        const value = e.target.value;

                        let valid = false;
                        if (param.sapDataType === "Decimal") {
                          // Allow up to 10 digits and 2 decimal places
                          const decimalRegex = /^\d{0,10}(\.\d{0,2})?$/;
                          valid = decimalRegex.test(value);
                        } else if (param.sapDataType === "Integer") {
                          const integerRegex = /^\d{0,10}$/;
                          valid = integerRegex.test(value);
                        } else {
                          // For non-numeric types, handle normally
                          valid = true;
                        }

                        if (valid) {
                          handleParameterChange(param.sapAnalysisId, value);
                        }
                      }}
                      type={
                        (param.sapDataType === "Integer" || param.sapDataType === "Decimal")
                          ? "text" // Changed from "number" to "text"
                          : "text"
                      }
                      inputProps={{
                        maxLength: 12, // 10 digits + dot + 2 decimals
                        inputMode: (param.sapDataType === "Decimal" || param.sapDataType === "Integer") ? "decimal" : "text",
                        pattern: param.sapDataType === "Decimal" ? "\\d{0,10}(\\.\\d{0,2})?" : "\\d{0,10}",
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          padding: "7px",
                          minHeight: "50px",
                        },
                        "& .MuiInputLabel-root": {
                          lineHeight: "1.2em",
                        },
                      }}
                      InputProps={{
                        style: { height: "50px" },
                      }}
                      // Display error messages based on validation
                      error={isDecimalError || isLengthError}
                      helperText={
                        isDecimalError
                          ? "Only 2 decimal places allowed."
                          : isLengthError && param.sapDataType === "Decimal"
                          ? "Maximum 10 digits allowed (excluding decimal point)."
                          : isLengthError
                          ? "Maximum 10 digits allowed."
                          : ""
                      }
                    />
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      )}

      <Box sx={{ height: "60px" }}></Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0.5rem",
          mt: 4,
        }}
      >
        <Button
          style={{
            backgroundColor: "#737D86",
            padding: "6px 16px",
            borderRadius: "4px",
          }}
          onClick={handleOpenModal}
          variant="contained"
          startIcon={<img src={arrowLeft} alt="Arrow" />}
        >
          {t("cancel")}
        </Button>

        {/* {loading && <CircularProgress size={20} />} */}

        <Modal
          open={open}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: "7px",
              width: "585px",
              margin: "auto",
              mt: 25,
              height: "200px",
            }}
          >
            <Box
              sx={{
                background: "#102A62",
                height: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderTopLeftRadius: "7px",
                borderTopRightRadius: "7px",
              }}
            >
              <Typography
                sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
              >
                {t("cancelTitle")}
              </Typography>
            </Box>
            <Box sx={{ p: 2 }}>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, fontWeight: 400 }}
              >
                {t("cancelMessage")}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
                mb: 1,
              }}
            >
              <Button
                onClick={handleCloseModal}
                sx={{
                  background: "#737D86",
                  width: "100px",
                  color: "#ffffff",
                  "&:hover": { background: "#737D86" },
                }}
              >
                {t("no")}
              </Button>
              <Button
                onClick={handleDeleteConfirm}
                sx={{
                  background: "#004F9F",
                  width: "100px",
                  color: "#ffffff",
                  "&:hover": { background: "#004F9F" },
                }}
              >
                {t("yes")}
              </Button>
            </Box>
          </Box>
        </Modal>

        {/* Show save button only when not in view mode */}
        {location.state?.edit !== false && (
          <Button
            sx={{
              padding: "6px 16px",
              borderRadius: "4px",
              backgroundColor: "#EF7D00",
              color: "white",
              "&:hover": {
                backgroundColor: "#EF7D00",
              },
            }}
            type="button"
            startIcon={<img src={Save} alt="Right Arrow" />}
            variant="contained"
            onClick={handleFormSubmit}
          >
            {t("save")}
          </Button>
        )}
      </Box>

      {/* Extra Space */}
      <Box sx={{ height: "60px" }}></Box>
    </Layout>
  );
};

export default AddSoilAnalysis;
