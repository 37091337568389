import * as React from "react";
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Grid,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Layout from "../../../structure/Layout";
import uploadIcon from "../../../../../src/assets/uploadIcon.svg";
import uploadCloud from "../../../../../src/assets/uploadCloud.svg";
import CloudDownloadSharpIcon from "@mui/icons-material/CloudDownloadSharp";
import deleteVector from "../../../../../src/assets/deleteVector.svg";
import rectangleLine from "../../../../../src/assets/rectangleLine.svg";
import iconSearchs from "../../../../../src/assets/iconSearchs.svg";
import {
  getBase64,
  validateEmail,
  validateForm,
} from "../../../../utils/CommonFunction";
import UserService from "../../../../services/UserService";
import { useTranslation } from "react-i18next";
import "./UserAdd.css";
import { json2csv } from "json-2-csv";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import CustomizedSnackbars from "../../../../utils/SnackBar";
import { SnackbarContext } from "../../../../context/snackBarContext";
import CustomSnackbar from "../../../../utils/SnackBar";
import CommonService from "services/CommonService";
import CheckboxTick from "../../../../../src/assets/CheckboxTick.svg";
import CheckboxUntick from "../../../../../src/assets/CheckboxUntick.svg";
import { UserContext } from "App";
import { AuthData } from "auth/AuthWarpper";
import { formatDateDetails } from "../../../../utils/utils";
import SurityModal from "utils/SurityModal/SurityModal";
import RoundTick from "../../../../../src/assets/RoundTick.svg";
import RoundCross from "../../../../../src/assets/RoundCross.svg";
import AdService from "services/AdService";

let staticFiles = [];
export default function UserAdd() {
  const location = useLocation();
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const [files, setfiles] = React.useState([]);
  const [countries, setCountries] = React.useState(null);
  const [profiles, setProfiles] = React.useState(null);
  const [percentage, setPercentage] = React.useState(0);
  const [mode, setMode] = React.useState("add");
  const [User, setUser] = React.useState(null);
  const [surityModalOpen, setSurityModalOpen] = React.useState(false);
  const [surityModalMessage, setSurityModalMessage] = React.useState("");
  const [iconName, setIconName] = React.useState("");
  const [actionType, setActionType] = React.useState("");
  const [searchString, setSearchString] = React.useState("");
  const [selectedProfile, setSelectedProfile] = React.useState({
    name: User?.profileName || "Select Profile",
    value: User?.profileName || "Select Profile",
    profileAttribute: ""
  });
  const [isProfileGlobal, setIsProfileGlobal] = React.useState(false);

  // const [loggedinUserType, setLoggedinUserType] = React.useState('Local Admin')
  const { user } = AuthData();

  const [loggedinUserType, setLoggedinUserType] = React.useState(
    user?.profileAttribute
  );
  const { t, i18n } = useTranslation();
  const [formValues, setFormValues] = React.useState({
    userName: {
      value: "",
      error: false,
      errorMessage: "You must enter full name",
    },
    countryCode: {
      value: loggedinUserType == "Local" ? user.countryCode : "Select country",
      error: false,
      errorMessage: "You must enter country",
    },
    profileCode: {
      value: "",
      error: false,
      errorMessage: "You must select profile",
    },
    batOneId: {
      value: "",
      error: false,
      errorMessage: "You must enter a BAT one ID",
    },
    email: {
      value: "",
      error: false,
      errorMessage: "You must enter a email",
    },
    legacyCode: {
      value: "",
      error: false,
      errorMessage: "You must enter legacy code",
    },
    active: {
      value: false,
      error: false,
      errorMessage: "You must select checkbox",
    },
  });

  const {
    severity,
    message,
    openAlert,
    setOpenAlert,
    setSeverity,
    setMessage,
  } = React.useContext(SnackbarContext);

  useEffect(() => {
    if (location && location.state) {
      setMode(location.state.mode);
      if (location.state.mode == "Edit") {
        fetchSingleUser(location.state.state.batOneId);
      }
    }
    fetchCountries();
    fetchProfiles();
  }, []);

  useEffect(() => {
    return () => {
      // if (staticFiles && staticFiles.length > 0) {
      //   staticFiles.forEach((ele) => {
      //     try {
      //       alert('delete' + JSON.stringify(ele.fileUrl))
      //       UserService.deleteUserFile(ele.fileUrl).then(() => {
      //         staticFiles = staticFiles.filter(file => file !== ele);
      //       })
      //     } catch (ex) {
      //       console.log('ex', ex)
      //     }
      //   })
      // }
    };
  }, [!files]);


  const handleChange = (e) => {
    let { name, value, checked } = e.target;
    const regex = /^(?!\s)(?!.*\s{2,}).*$/;
    const isValid = regex.test(value);
    if (name == "active") {
      value = checked;
    }
    const regex2 = /^(?!\s)(?!.*\s{2,}).*$/;

    if (regex2.test(value) || value === "") {
    } else {
      if (name == "email") {
      } else {
        return;
      }
    }

    const hasTrailingSpaces = !isValid;
    setFormValues({
      ...formValues,
      [name]: {
        ...formValues[name],
        value,
        error: hasTrailingSpaces ? true : false,
        errorMessage: hasTrailingSpaces
          ? "Trailing spaces are not allowed"
          : formValues[name].errorMessage,
      },
    });
    if (name == "email") {
      let valid = validateEmail(value);
      if (!valid) {
        setFormValues({
          ...formValues,
          [name]: {
            ...formValues[name],
            value,
            error: true,
            errorMessage: "Invalid email",
          },
        });
      }
    }
  };

  const handleSubmit = async () => {
    setSurityModalOpen(false);
    if (actionType == "cancel") {
      navigate("/users");
      return;
    }
    // e.preventDefault();
    // const formFields = Object.keys(formValues);
    // let newFormValues = { ...formValues }
    // for (let index = 0; index < formFields.length; index++) {
    //   const currentField = formFields[index];
    //   const currentValue = formValues[currentField].value;
    //   if (currentValue === '' || currentValue === undefined) {
    //     newFormValues = {
    //       ...newFormValues,
    //       [currentField]: {
    //         ...newFormValues[currentField],
    //         error: loggedinUserType == 'Local' ?
    //         currentField == 'active' || currentField == 'legacyCode' || currentField == "profileCode" ? false : true
    //         : currentField == 'active' || currentField == 'legacyCode' ? false : true
    //       }
    //     }
    //   }
    // }

    // setFormValues(newFormValues)
    // if (validateForm(newFormValues) == true) {
    //   mode == "Edit" ? saveUserSingle() : saveUser()

    // }
    mode == "Edit" ? saveUserSingle() : saveUser();
  };

  const arrangeFormKeyValue = (formValues) => {
    let arranged = {};
    for (const [k, v] of Object.entries(formValues)) {
      arranged[k] = v.value;
    }
    return arranged;
  };

  const handleFileUpload = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    let c_code = formValues.countryCode.value;
    let country = countries.filter((ele) => ele.countryCode == c_code);
    getBase64(file)
      .then((res) => {
        const new_file = {
          country: country.length > 0 ? country[0].countryName : null,
          fileContent: res,
          fileName: file.name,
          mimeType: file.type,
          batOneId: formValues.batOneId.value,
        };
        handleUpload(new_file);
      })
      .catch((err) => console.log(err));
  };

  const config = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percent = Math.floor((loaded * 100) / total);
      if (percent <= 100) {
        setPercentage(percent); // hook to set the value of current level that needs to be passed to the progressbar
      }
    },
  };
  const fetchCountries = (file) => {
    CommonService.fetchCountries()
      .then((response) => {
        if (loggedinUserType == "Local") {
          setCountries([
            {
              countryCode: user.countryCode,
              countryName: user.countryName,
            },
          ]);
        } else {
          setCountries(response?.data?.response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchProfiles = (file) => {
    CommonService.fetchProfiles()
      .then((response) => {
        let profiles = response?.data?.response?.profiles;
        if (user?.profileAttribute == "Local") {
          let local_profiles = profiles.filter(
            (ele) => ele.profileAttribute == "Local"
          );
          setProfiles(local_profiles);
        } else {
          setProfiles(profiles);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateCountryCode = (newCountryCode) => {
    setFormValues(prevFormValues => ({
      ...prevFormValues,
      countryCode: {
        ...prevFormValues.countryCode,
        value: newCountryCode,
      },
    }));
  };
  const fetchSingleUser = (id) => {
    UserService.FetchSingleUser(id)
      .then((response) => {
        const User = response?.data?.response;
        setUser(User);
        let f_values = JSON.parse(JSON.stringify(formValues));
        for (let ele in formValues) {
          f_values[ele].value = User[ele];
        }
        setFormValues(f_values);
        setSelectedProfile({
          name: User?.profileName,
          value: User?.profileName,
          profileAttribute: User?.profileAttribute
        });
        updateCountryCode(User?.countryCode)
      })
      .catch((error) => {
        console.log(error);
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };
  const handleUpload = (file) => {
    // if (!formValues.countryCode.value || formValues.countryCode.value == "Select country") {
    //   setMessage("Please select country to upload file")
    //   setOpenAlert(true)
    //   setSeverity("error")
    //   return
    // }
    setLoading(true);
    UserService.uploadUserFile(file, config)
      .then((response) => {
        let fileData = {
          fileUrl: response.data.response[file.fileName],
          fileName: file.fileName,
          country: formValues.countryCode.value,
          mimeType: file.mimeType,
        };
        setfiles((olddata) => [...olddata, fileData]);
        console.log("user file data log",files)
        staticFiles.push(fileData);
        console.log("user file data log",files)
        setPercentage(0);
        setLoading(false);
        let fileInput = document.getElementById("contained-button-file");
        fileInput.value = "";
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setTimeout(() => {
          setPercentage(0);
        }, 1000);
        setLoading(false);
        let fileInput = document.getElementById("contained-button-file");
        fileInput.value = "";
      });
  };

  const saveUser = () => {
    const data = arrangeFormKeyValue(formValues);
    data.profileCode =
      data.profileCode == "Select profile" || data.profileCode == ""
        ? ""
        : data.profileCode;
    // data.countryCode = data.countryCode == 'Select country' ? "" : data.
    data["userName"] = data["userName"].trim();
    Object.assign(data, {
      userAttachmentsDtoList: files,
      createdByUser: user?.userName,
      modifiedByUser: user?.userName,
    });
    setLoading(true);
    UserService.saveUser(data)
      .then((res) => {
        staticFiles = [];
        setLoading(false);
        navigate("/users");
        setMessage("Added successfully.");
        setOpenAlert(true);
        setSeverity("success");
      })
      .catch((error) => {
        console.error(error);
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setLoading(false);
      });
  };
  const saveUserSingle = () => {
    const data = arrangeFormKeyValue(formValues);
    data.profileCode =
      data.profileCode == "Select profile" || data.profileCode == ""
        ? ""
        : data.profileCode;
    data["userName"] = data["userName"].trim();
    Object.assign(data, {
      userAttachmentsDtoList: files,
      modifiedByUser: user?.userName,
    });
    setLoading(true);
    UserService.saveUserSingle(data)
      .then((res) => {
        staticFiles = [];
        setLoading(false);
        navigate("/users");
        setMessage(t("snackbarMessage.updatedSuccessfully"));
        setOpenAlert(true);
        setSeverity("success");
      })
      .catch((error) => {
        console.error(error);
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setLoading(false);
      });
  };

  const onDeleteFile = (ele, existing) => {
    setLoading(true);
    let query = `?fileUrl=${ele.fileUrl}`;
    if (existing) query = query + `&fileUid=${ele?.fileUid}`;

    UserService.deleteUserFile(query)
      .then((response) => {
        if (existing) {
          let new_user = JSON.parse(JSON.stringify(User));
          let newfiles = new_user.userAttachmentsDtoList.filter(
            (file) => file.fileUid != ele.fileUid
          );
          new_user.userAttachmentsDtoList = newfiles;
          setUser(new_user);
        } else {
          let newfiles = files.filter((file) => file !== ele);
          setfiles(newfiles);
          staticFiles.push(newfiles);
        }
        setLoading(false);
        setMessage("deleted successfully.");
        setOpenAlert(true);
        setSeverity("success");
      })
      .catch((error) => {
        setMessage(error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setLoading(false);
      });
  };

  const smapleData = {
    name_english: "Hello friend how are you", // english
    name_portuguese: "Olá amigo como está você", //portuguese
    name_urdu: "ہیلو دوست کیسے ہو", //urdu
    name_spanish: "Hola amiga como estas", //spanish
    name_hebrew: "שלום חבר מה שלומך", //hebrew
    name_punjabi: "ਹੈਲੋ ਦੋਸਤੋ ਕਿਵੇਂ ਹੋ", //pinjabi
    name_chinese: "嗨，朋友你好吗", //chinese
    name_bangla: "বন্ধু, কেমন আছো", //bangla
    name_portuguese2: "Você foi encerrado", //portuguese
    name_portuguese3: "Tudo de bom para os seus pés", //portuguese
  };
  const callFileDownloadAPI = (url, mimeType) => {
    UserService.fetchFile(url)
      .then((response) => {
        handleFileDownload(response?.data?.response, mimeType);
        setMessage("File downloaded successfully.");
        setOpenAlert(true);
        setSeverity("success");
      })
      .catch((error) => {
        console.error(error);
        setMessage(error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };
  const handleFileDownload = (data, mimeType) => {
    // let d = {
    //   "fileName": "value12.txt",
    //   "fileContent": "TWF2ZW4gLSAzLjguNA0KRWNsaXBzZSAtIExhdGVzdCBWZXJzaW9uDQpBenVsIEphdmEvSkRLIC0gMTcNClBvc3RtYW4gLSBMYXRlc3QgVmVyc2lvbg0KTm90ZXBhZCsrIC0gTGF0ZXN0IFZlcnNpb24NCjcgWmlwIC0gTGF0ZXN0IFZlcnNpb24NCk1pY3Jvc29mdCBTUUwgU2VydmVyIE1hbmFnZW1lbnQgU3R1ZGlvIC0gTGF0ZXN0IFZlcnNpb24NCg==",
    //   "tenant": "India"
    // }
    var a = document.createElement("a");
    a.href = `data:${mimeType};base64,` + data.fileContent;
    a.download = data.fileName;
    a.click();
  };
  const handleDownload = () => {
    let cd = json2csv(smapleData, { excelBOM: true });
    const url = window.URL.createObjectURL(new Blob([cd]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "yourfilename.csv");
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  const handleModal = (type) => {
    setActionType(type);
    if (type == "submit") {
      const formFields = Object.keys(formValues);
      let newFormValues = { ...formValues };
      console.clear()
      console.log('selected', selectedProfile)

      for (let index = 0; index < formFields.length; index++) {
        const currentField = formFields[index];
        const currentValue = formValues[currentField].value;
        console.log('currentfield', currentField)
        console.log('currentValue', currentValue)


        if (currentValue === "" || currentValue === undefined) {
          newFormValues = {
            ...newFormValues,
            [currentField]: {
              ...newFormValues[currentField],

              // error: currentField == 'active' || currentField == 'legacyCode' ? false :  true;
              error:
                loggedinUserType == "Local"
                  ? currentField == "active" ||
                    currentField == "legacyCode" ||
                    currentField == "profileCode"
                    ? false
                    : true
                  : currentField == "active" || currentField == "legacyCode"
                    ? false
                    : true,
            },
          };
        }
        if (currentField == "countryCode") {
          newFormValues = {
            ...newFormValues,
            [currentField]: {
              value:
                newFormValues[currentField].value == "Select country"
                  ? ""
                  : newFormValues[currentField].value,
              error:
                loggedinUserType == "Local"
                  ? false
                  : (selectedProfile?.profileAttribute == "Local" && formValues.countryCode?.value == "Select country")
                    || (selectedProfile?.profileAttribute == "Local" && formValues.countryCode?.value == "")
                    ||  (selectedProfile?.profileAttribute == "Local" && formValues.countryCode?.value == undefined)
                    ? true
                    : false,
              errorMessage:
                loggedinUserType == "Local"
                  ? false
                  : selectedProfile?.profileAttribute != "Global"
                    ? "Please select country"
                    : "",
            },
          };
        }
        if (currentField == "profileCode" && currentValue == "Select profile") {
          newFormValues = {
            ...newFormValues,
            [currentField]: {
              ...newFormValues[currentField],
              error: true,
            },
          };
        }
        if (
          mode == "Edit" &&
          currentField == "profileCode" &&
          currentValue == "Select profile" &&
          User?.profileAttribute == "Local"
        ) {
          newFormValues = {
            ...newFormValues,
            [currentField]: {
              ...newFormValues[currentField],
              error: false,
            },
          };
        }
      }
      setFormValues(newFormValues);
      if (validateForm(newFormValues) == true) {
        if (mode !== "Edit" && files.length <= 0) {
          setMessage("Attachment requirred");
          setOpenAlert(true);
          setSeverity("error");
          return;
        } else {
          setIconName(RoundTick);
          setSurityModalMessage("Are you sure! You want to submit");
          setSurityModalOpen(true);
        }
        setIconName(RoundTick);
        setSurityModalMessage("Are you sure! You want to submit");
        setSurityModalOpen(true);
      } else {
        return;
      }
    } else {
      setIconName(RoundCross);
      setSurityModalMessage("Are you sure! You want to cancel");
      setSurityModalOpen(true);
    }
  };


  const onSearchAD = (string) => {
    let email = `${string}@bat.com`;
    if (string == "") {
      setMessage("Please enter BAT ID to search");
      setOpenAlert(true);
      setSeverity("error");
      return;
    }
    setLoading(true);
    UserService.FindAdUser(string)
      .then((res) => {
        setLoading(false);
        setMessage("Success");
        setOpenAlert(true);
        setSeverity("success");
        let data = res?.data?.response;
        let fullname = `${data?.givenName ? data?.givenName : ""} ${data?.surName ? data?.surName : ""
          }`;
        let mail = data.mail;
        let batOneID = string;
        if (!mail) {
          mail = "";
        }
        if (fullname == " ") fullname = "";

        setFormValues({
          ...formValues,
          ["userName"]: {
            value: fullname,
            error: false,
            errorMessage: fullname == "" ? "You must enter full name" : "",
          },
          ["batOneId"]: {
            value: batOneID,
            error: false,
            errorMessage: batOneID == "" ? "You must enter a BAT one ID" : "",
          },
          ["email"]: {
            value: mail,
            error: false,
            errorMessage: mail == "" ? "You must enter a email" : "",
          },
        });
      })
      .catch((error) => {
        setFormValues({
          ...formValues,
          ["userName"]: {
            value: "",
            error: false,
            errorMessage: "You must enter full name",
          },
          ["batOneId"]: {
            value: "",
            error: false,
            errorMessage: "You must enter a BAT one ID",
          },
          ["email"]: {
            value: "",
            error: false,
            errorMessage: "You must enter a email",
          },
        });
        setLoading(false);
        let status = error?.response?.status;
        let errorMessage = "";
        if (status && status == "404") {
          errorMessage = "User not found";
        } else {
          errorMessage = error?.response?.data?.message || error?.message;
        }

        setMessage(errorMessage);
        setOpenAlert(true);
        setSeverity("error");
      });
  };
  const onSearchChange = (e) => {
    if (isValidPositiveInteger(e)) {
      setSearchString(e);
    }
    if (e == "") {
      setSearchString("");
    }
  };
  function isValidPositiveInteger(input) {
    return /^[1-9]\d*$|^0$/.test(input);
  }
  const onCountryClick = () => {
    alert("1");
    // if (loggedinUserType != 'Global') {
    //    if(formValues.profileCode == "Select Profile"){
    //     alert('Please select profile first')
    //     return
    //    }
    // }
  };
  return (
    <Layout title={t("manageUsers.pageTitle")}>
      <Box sx={{ paddingBottom: "50px !important" }}>
        {<CustomSnackbar />}
        <SurityModal
          message={surityModalMessage}
          iconName={iconName}
          loading={loading}
          string={"string"}
          onNext={() => {
            handleSubmit();
          }}
          open={surityModalOpen}
          onClose={() => {
            setSurityModalOpen(false);
          }}
        />
        <Grid container justifyContent={"space-between"}>
          <Box className="usersHeading">
            {mode == "Edit" ? "Edit User" : "Add User"}{" "}
          </Box>
          {/* <Button style={{ backgroundColor: "#112A63", height: "40px", marginLeft: "40px" }}
          variant="contained" type='button' onClick={(e) => handleDownload(e)}>
          {t('buttons.import')}</Button> */}
          <Grid item xs={6} md={3}>
            <Box sx={{ display: "flex", right: "1.6rem" }}>
              {mode == "add" && (
                <TextField
                  fullWidth
                  className="User-search-input"
                  placeholder={t("search")}
                  variant="outlined"
                  onChange={(e) => onSearchChange(e.target.value)}
                  // type="number"
                  value={searchString}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="start"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => onSearchAD(searchString)}
                      >
                        {loading && (
                          <CircularProgress
                            size={20}
                            style={{ marginBottom: "3px" }}
                          />
                        )}
                        &nbsp;
                        <img src={iconSearchs} />
                      </InputAdornment>
                    ),
                    sx: {
                      borderRadius: "30px",
                    },
                  }}
                />
              )}
              {mode == "Edit" && (
                <Box className="userTopRight">
                  <Box className="">
                    Last Modified by :{" "}
                    <span>
                      <b>{User?.modifiedByUser}</b>
                    </span>
                  </Box>
                  {/* <Box>Last Modified date : <span><b>03/jan/2024 12:52:25</b></span></Box> */}
                  <Box>
                    Last Modified date :{" "}
                    <span>
                      <b>{formatDateDetails(User?.modifiedTime)}</b>
                    </span>
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
        <Box>
          <Card
            variant="outlined"
            sx={{
              height: "50%",
              borderRadius: "10px",
              paddingTop: "10px",
              padding: "2rem",
              boxShadow: "0px 4px 4px 0px #00000040",
            }}
          >
            <Grid container>
              <Grid md={9} xs={12}>
                <form autoComplete="off" noValidate>
                  <Grid container>
                    <Grid md={6} xs={12}>
                      <Grid container>
                        <Grid md={4} xs={12} className="userAddLable">
                          <InputLabel
                            required
                            sx={{ width: "10rem" }}
                            htmlFor="my-input"
                          >
                            {t("manageUsers.fullName")}
                          </InputLabel>
                        </Grid>
                        <Grid md={7} xs={12}>
                          <TextField
                            fullWidth
                            value={formValues.userName.value}
                            onChange={(e) => handleChange(e)}
                            name="userName"
                            inputProps={{ maxLength: 50 }}
                            error={formValues.userName.error}
                            helperText={
                              formValues.userName.error &&
                              formValues.userName.errorMessage
                            }
                            placeholder={t("typeHere")}
                            required
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>{" "}
                    </Grid>
                    <Grid md={6} xs={12}>
                      <Grid container>
                        <Grid md={4} xs={12} className="userAddLable">
                          <InputLabel
                            required={
                              selectedProfile?.profileAttribute !== "Global" ||
                              (User?.profileAttribute !== "Global" &&
                                selectedProfile?.profileAttribute !== "Global")
                            }
                            sx={{ width: "10rem" }}
                            htmlFor="my-input"
                          >
                            {t("country")}
                          </InputLabel>
                        </Grid>
                        <Grid md={7} xs={12}>
                          <TextField
                            select
                            fullWidth
                            value={`${formValues?.countryCode?.value
                              ? formValues?.countryCode?.value
                              : t("selectCountry")
                              }`}
                            onChange={(e) => handleChange(e)}
                            name="countryCode"
                            error={formValues.countryCode.error}
                            helperText={
                              formValues.countryCode.error &&
                              formValues.countryCode.errorMessage
                            }
                            required
                            variant="outlined"
                            disabled={loggedinUserType == "Local"}
                          >
                            <MenuItem key={1} value={"Select country"}>
                            {t("selectCountry")}
                            </MenuItem>
                            {countries &&
                              countries.map((option) => (
                                <MenuItem
                                  key={option.countryCode}
                                  value={option.countryCode}
                                >
                                  {option.countryName}
                                </MenuItem>
                              ))}
                          </TextField>
                        </Grid>
                      </Grid>{" "}
                    </Grid>
                  </Grid>
                  <Grid container sx={{ paddingTop: "1rem" }}>
                    <Grid md={6} xs={12}>
                      <Grid container>
                        <Grid md={4} xs={12} className="userAddLable">
                          <InputLabel
                            required={
                              loggedinUserType == "Local" ? false : true
                            }
                            sx={{ width: "10rem" }}
                            htmlFor="my-input"
                          >
                            {t("profile")}
                          </InputLabel>
                        </Grid>
                        <Grid md={7} xs={12}>
                          <TextField
                            select
                            fullWidth
                            // SelectProps={{
                            //   native: true,
                            // }}
                            value={`${formValues?.profileCode?.value
                              ? formValues?.profileCode?.value
                              : "Select profile"
                              }`}
                            onChange={(e) => handleChange(e)}
                            name="profileCode"
                            error={formValues.profileCode.error}
                            helperText={
                              formValues.profileCode.error &&
                              formValues.profileCode.errorMessage
                            }
                            required
                            variant="outlined"
                          >
                            <MenuItem
                              selected={true}
                              key={"1"}
                              value={"Select profile"}
                            >
                              Select Profile{" "}
                            </MenuItem>
                            {profiles &&
                              profiles.map((option) => (
                                <MenuItem
                                  onClick={() =>
                                    setSelectedProfile({
                                      name: option.profileName,
                                      code: option.profileCode,
                                      profileAttribute: option.profileAttribute
                                    })
                                  }
                                  key={option.profileCode}
                                  value={option.profileCode}
                                >
                                  {option.profileName}
                                </MenuItem>
                              ))}
                          </TextField>
                        </Grid>
                      </Grid>{" "}
                    </Grid>
                    <Grid md={6} xs={12}>
                      <Grid container>
                        <Grid md={4} xs={12} className="userAddLable">
                          <InputLabel
                            required
                            sx={{ width: "10rem" }}
                            htmlFor="my-input"
                          >
                            {t("manageUsers.batOneId")}
                          </InputLabel>
                        </Grid>
                        <Grid md={7} xs={12}>
                          <TextField
                            fullWidth
                            value={formValues.batOneId.value}
                            onChange={(e) => handleChange(e)}
                            name="batOneId"
                            error={formValues.batOneId.error}
                            helperText={
                              formValues.batOneId.error &&
                              formValues.batOneId.errorMessage
                            }
                            placeholder={t("typeHere")}
                            required
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>{" "}
                    </Grid>
                  </Grid>
                  <Grid container sx={{ paddingTop: "1rem" }}>
                    <Grid md={6} xs={12}>
                      <Grid container>
                        <Grid md={4} xs={12} className="userAddLable">
                          <InputLabel
                            required
                            sx={{ width: "10rem" }}
                            htmlFor="my-input"
                          >
                            {t("manageUsers.email")}
                          </InputLabel>
                        </Grid>
                        <Grid md={7} xs={12}>
                          <TextField
                            fullWidth
                            value={formValues.email.value}
                            onChange={(e) => handleChange(e)}
                            name="email"
                            error={formValues.email.error}
                            helperText={
                              formValues.email.error &&
                              formValues.email.errorMessage
                            }
                            placeholder={t("typeHere")}
                            required
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>{" "}
                    </Grid>
                    <Grid md={6} xs={12}>
                      <Grid container>
                        <Grid md={4} xs={12} className="userAddLable">
                        {t("manageUsers.legacyCode")}
                        </Grid>
                        <Grid md={7} xs={12}>
                          <TextField
                            fullWidth
                            value={formValues.legacyCode.value}
                            onChange={(e) => handleChange(e)}
                            name="legacyCode"
                            inputProps={{ maxLength: 50 }}
                            error={formValues.legacyCode.error}
                            helperText={
                              formValues.legacyCode.error &&
                              formValues.legacyCode.errorMessage
                            }
                            placeholder={t("typeHere")}
                            required
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>{" "}
                    </Grid>
                  </Grid>
                </form>
              </Grid>
              <Grid md={3} xs={12}>
                {percentage > 0 && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ width: "100%", mr: 1 }}>
                      <LinearProgress
                        variant="determinate"
                        value={percentage}
                      />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                      >{`${percentage}%`}</Typography>
                    </Box>
                  </Box>
                )}
                <Grid container>
                  <Grid md={4} className="userAddLable">
                  {t("active")}
                  </Grid>
                  <Grid md={8}>
                    <Checkbox
                      name="active"
                      checked={formValues.active.value}
                      onChange={(e) => handleChange(e)}
                      icon={<img src={CheckboxUntick} />}
                      checkedIcon={<img src={CheckboxTick} />}
                    />
                  </Grid>
                </Grid>
                {/* {loggedinUserType == 'Global' && <Grid container sx={{ paddingTop: "1rem" }} >
                <Grid md={12} lg={5} sm={5} className="userAddLable">{t('formLables.attachment')}<span style={{ color: 'red' }}>*</span></Grid>
                <Grid md={12} lg={7} sm={5} xs={12} sx={{ cursor: "pointer" }}>
                  <input
                    style={{ display: 'none' }}
                    id="contained-button-file"
                    type="file"
                    // multiple
                    accept='application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,message/rfc822'
                    onChange={(e) => handleFileUpload(e)}
                  />
                  <label htmlFor="contained-button-file">
                    <Box sx={{ backgroundColor: '#F2F6FF', cursor: "pointer", display: "flex", padding: "10px", justifyContent: "space-around", borderRadius: "10px", border: ' 0.62px dashed #3483E29C' }} >
                      <img src={uploadCloud} />
                      <img src={uploadIcon} />
                    </Box>
                  </label>
                </Grid>
              </Grid>
              } */}
                {
                  <Grid container sx={{ paddingTop: "1rem" }}>
                    <Grid md={12} lg={5} sm={5} className="userAddLable">
                      <InputLabel
                        required
                        sx={{ width: "10rem" }}
                        htmlFor="my-input"
                      >
                       {t("manageUsers.attachment")}
                      </InputLabel>
                    </Grid>
                    <Grid
                      md={12}
                      lg={7}
                      sm={5}
                      xs={12}
                      sx={{ cursor: "pointer" }}
                    >
                      <input
                        style={{ display: "none" }}
                        id="contained-button-file"
                        type="file"
                        // multiple
                        accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,message/rfc822"
                        onChange={(e) => handleFileUpload(e)}
                      />
                      <label htmlFor="contained-button-file">
                        <Box
                          sx={{
                            backgroundColor: "#F2F6FF",
                            cursor: "pointer",
                            display: "flex",
                            padding: "10px",
                            justifyContent: "space-around",
                            borderRadius: "10px",
                            border: " 0.62px dashed #3483E29C",
                          }}
                        >
                          <img src={uploadCloud} />
                          <img src={uploadIcon} />
                        </Box>
                      </label>
                    </Grid>
                  </Grid>
                }
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "20px",
                paddingTop: "40px",
              }}
            >
              <Button
                disabled={loading}
                style={{ backgroundColor: "#737D86" }}
                variant="contained"
                onClick={() => handleModal("cancel")}
              >
                {" "}
                {t("cancel")}
              </Button>
              <Button
                disabled={loading}
                style={{ backgroundColor: "#112A63" }}
                variant="contained"
                type="button"
                onClick={(e) => handleModal("submit")}
              >
                {loading && <CircularProgress size={20} />}

                {mode == "Edit" ? t("update") : t("submit")}
              </Button>
            </Grid>
          </Card>
          <Grid container gap={2} sx={{ padding: "2rem 0" }}>
            {User && User?.userAttachmentsDtoList?.length > 0
              ? User?.userAttachmentsDtoList.map((ele, i) => (
                <Grid md={3} key={i}>
                  <Box className="filename-card">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          fontFamily: "Montserrat",
                          fontSize: "17px",
                          fontWeight: 500,
                          letterSpacing: "0em",
                          color: "#737D86",
                        }}
                      >
                        {ele.fileName}
                      </Box>
                      <Box>
                        <CloudDownloadSharpIcon
                          onClick={() =>
                            callFileDownloadAPI(ele?.fileUrl, ele.mimeType)
                          }
                          color="primary"
                          sx={{ cursor: "pointer", paddingRight: "10px" }}
                        />
                        <img
                          style={{ cursor: "pointer", marginBottom: "3px" }}
                          onClick={() => {
                            onDeleteFile(ele, true);
                          }}
                          src={deleteVector}
                        />
                      </Box>
                    </Box>
                    <img
                      style={{ paddingTop: "5px" }}
                      width="100%"
                      src={rectangleLine}
                    />
                  </Box>
                </Grid>
              ))
              : ""}
            {files.length > 0
              ? files.map((ele, i) => (
                <Grid md={3} key={i}>
                  <Box className="filename-card">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          fontFamily: "Montserrat",
                          fontSize: "17px",
                          fontWeight: 500,
                          letterSpacing: "0em",
                          color: "#737D86",
                        }}
                      >
                        {ele.fileName}
                      </Box>
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          onDeleteFile(ele, false);
                        }}
                        src={deleteVector}
                      />
                    </Box>
                    <img
                      style={{ paddingTop: "5px" }}
                      width="100%"
                      src={rectangleLine}
                    />
                  </Box>
                </Grid>
              ))
              : ""}
          </Grid>
        </Box>
      </Box>
    </Layout>
  );
}
