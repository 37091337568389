import axios from "axios";
import styled from "@emotion/styled";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Layout from "components/structure/Layout";
import React, { useContext, useEffect, useState } from "react";
import { FormHelperText, Grid, FormControlLabel, Checkbox } from "@mui/material";
import * as Yup from "yup";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CountryFetcher from "utils/CountryFetcher";
import { useLocation, useNavigate } from "react-router-dom";
import { SnackbarContext } from "context/snackBarContext";
import dayjs from "dayjs";
import CustomButton from "utils/CustomButton";
import SeasonRegistrationService from "services/SeasonRegistrationService";
import RedSwitch from "utils/RedSwitch";
import { AuthData } from "auth/AuthWarpper";
import CheckboxUntick from "../../../assets/CheckboxUntick.svg"
import CheckboxTick from "../../../assets/CheckboxTick.svg"
import { useTranslation } from "react-i18next";

const AddSeason = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { setOpenAlert, setSeverity, setMessage, handleClose } =
    useContext(SnackbarContext);

  const {
    seasonCode,
    country,
    cropYear,
    seasonName,
    countryCode,
    initialDate,
    endDate,
    status,
    edit,
    id,
  } = location.state || {};
  const reversedDate = initialDate?.split("/").reverse().join("-");
  const reversedEndDate = endDate?.split("/").reverse().join("-");
  const { user } = AuthData();

  const [formData, setFormData] = useState({
    countryName: country || user?.countryName,
    // cyCropYear: cropYear !== "" ? cropYear : 0,
    cyCropYear: edit ? cropYear : location?.state?.cropYear ? location?.state?.cropYear : "",

    seasonCode: seasonCode || "",
    isActive: status === "Active" ? true : false || false,
    startDate: reversedDate || "",
    endDate: reversedEndDate || "",
    countryCode: countryCode || user?.countryCode,
  });


  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [dateError, setDateError] = useState(false);
  const [cropYears, setCropYears] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEndCalendar, setOpenEndCalendar] = useState(false);
  const { t } = useTranslation();
  // const handleChange = (e) => {
  //   const { name, value, checked } = e.target;

  //   let val;

  //   if (name === "isActive") {
  //     val = checked;
  //   } else if (name === "startDate" || name === "endDate") {
  //     // Format date to ISO format
  //     val = value instanceof Date ? value.toISOString() : value;
  //   } else if (name === "countryName") {
  //     // Find the selected country object from countries array

  //       (country) => country.countryName === value
  //     );

  //     // If selectedCountry is found, update countryCode
  //     if (selectedCountry) {
  //       val = value;
  //       setFormData({
  //         ...formData,
  //         countryName: val,
  //         countryCode: selectedCountry.countryCode, // Update countryCode
  //       });
  //       return; // Exit early
  //     } else {
  //       // Handle case when selected country is not found
  //       console.error(`Country "${value}" not found in countries array`);
  //       return;
  //     }
  //   } else {
  //     val = value;
  //   }

  //   setFormData({
  //     ...formData,
  //     [name]: val,
  //   });
  // };

  // const handleChange = (e) => {
  //   const { name, value, checked } = e.target;

  //   let val;

  //   if (name === "isActive") {
  //     val = checked;
  //   } else if (name === "startDate" || name === "endDate") {
  //     // Parse the input value as a Date object
  //     const dateValue = new Date(value);

  //     // Check if the parsed date is valid
  //     if (isNaN(dateValue.getTime())) {
  //       // Invalid date, set an error state or handle as needed
  //       console.error(`Invalid date entered for ${name}`);
  //       setDateError(true);
  //       // Set error state or perform error handling here
  //       return;
  //     }

  //     // Format date to ISO format
  //     setDateError(false);

  //     val = dateValue.toISOString();
  //   } else if (name === "countryName") {
  //     // Find the selected country object from countries array
  //     const selectedCountry = countries.find(
  //       (country) => country.countryName === value
  //     );

  //     // If selectedCountry is found, update countryCode
  //     if (selectedCountry) {
  //       val = value;
  //       setFormData({
  //         ...formData,
  //         countryName: val,
  //         countryCode: selectedCountry.countryCode, // Update countryCode
  //       });
  //       return; // Exit early
  //     } else {
  //       // Handle case when selected country is not found
  //       console.error(`Country "${value}" not found in countries array`);
  //       return;
  //     }
  //   } else {
  //     val = value;
  //   }

  //   setFormData({
  //     ...formData,
  //     [name]: val,
  //   });
  // };

  // Add state variable to track if crop year is selected
  const [cropYearSelected, setCropYearSelected] = useState(true);
  const [seasonCodeClicked, setSeasonCodeClicked] = useState(false);

  // Update handleChange function to set cropYearSelected state

  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    let val;

    if (name === "isActive") {
      val = checked;
    } else if (name === "startDate" || name === "endDate") {
      // Parse the input value as a Date object
      const dateValue = new Date(value);

      // Check if the parsed date is valid
      if (isNaN(dateValue.getTime())) {
        // Invalid date, set an error state or handle as needed
        console.error(`Invalid date entered for ${name}`);
        setDateError(true);
        // Set error state or perform error handling here
        return;
      }

      // Format date to custom format (YYYY-MM-DD)
      setDateError(false);

      const year = dateValue.getFullYear();
      const month = String(dateValue.getMonth() + 1).padStart(2, "0");
      const day = String(dateValue.getDate()).padStart(2, "0");
      val = `${year}-${month}-${day}`;
    } else if (name === "countryName") {
      // Find the selected country object from countries array
      const selectedCountry = countries.find(
        (country) => country.countryName === value
      );

      // If selectedCountry is found, update countryCode
      if (selectedCountry) {
        val = value;
        setFormData({
          ...formData,
          countryName: val,
          countryCode: selectedCountry.countryCode, // Update countryCode
        });
        return; // Exit early
      } else {
        // Handle case when selected country is not found
        console.error(`Country "${value}" not found in countries array`);
        return;
      }
    } else if (name === "cyCropYear") {
      // Set cropYearSelected to true when crop year is selected
      setCropYearSelected(true);
      val = value;
    } else {
      val = value;
    }

    setFormData({
      ...formData,
      [name]: val,
    });
  };

  // Update season code selection to be disabled until crop year is selected
  const handleSeasonCodeClick = () => {
    if (!cropYearSelected) {
      setSeasonCodeClicked(true); // Set flag indicating that user clicked on season code without choosing crop year
    }
  };

  const validationSchema = Yup.object({
    countryName: Yup.string()
      .required("Country is required")
      .max(50, "Country must be less than 50 characters"),
    cyCropYear: Yup.number().required("Crop Year is required"),
    seasonCode: Yup.string().required("Season Code is required"),
    startDate: Yup.string().required("Start Date is required"),
    endDate: Yup.string().required("End Date is required"),
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      setErrors({});
      await validationSchema.validate(formData, { abortEarly: false });

      // Format startDate and endDate before submitting
      const formattedFormData = {
        ...formData,
        startDate: new Date(formData.startDate).toISOString(),
        endDate: new Date(formData.endDate).toISOString(),
        createdByUser: user.userName,
        modifiedByUser: user.userName,
      };

      // Make a POST request with the formatted data using Axios
      const response = await SeasonRegistrationService.createSeasonRegistration(
        formattedFormData
      );

      if (response.status === 201) {
        navigate("/seasonRegistration", {
          state: { year: formData.cyCropYear, couCode: user?.countryCode, isFetched: location.state.isFetched },
        });

        setSeverity("success");
        setMessage(t("snackbarMessage.successAdd"));
        setOpenAlert(true);
        SeasonRegistrationService.addCropStageCalendar(
          response?.data?.response
        ).catch((err) => console.log(err));
      } else {
        setSeverity("error");
        setMessage("An error occurred while submitting the form");
        setOpenAlert(true);
      }
    } catch (error) {
      let newError = {};
      if (error?.name && error.name === "ValidationError") {
        error?.inner?.forEach((err) => {
          newError[err.path] = err.message;
          setErrors(newError || error?.message);
          return;
        });
      } else {
        setSeverity("error");
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
      }
    }
    setLoading(false);
  };

  const handleUpdate = async () => {
    setLoading(true);
    try {
      setErrors({});
      await validationSchema.validate(formData, { abortEarly: false });

      const formattedFormData = {
        ...formData,
        cropSeasonUid: id,
        countryCode: user.countryCode,
        modifiedByUser: user.userName,
      };

      // Make a PUT request with the formatted data
      const response = await SeasonRegistrationService.updateSeasonRegistration(
        formattedFormData
      );

      if (response.status === 200) {
        navigate("/seasonRegistration", {
          state: { year: formData.cyCropYear, isFetched: location.state.isFetched },
        });
        // Show success snackbar
        setSeverity("success");
        setMessage(t("snackbarMessage.updatedSuccessfully"));
        setOpenAlert(true);
      } else {
        console.error("Failed to update form");
        setSeverity("error");
        setMessage("Failed to update form");
        setOpenAlert(true);
      }
    } catch (error) {
      let newError = {};
      if (error?.name && error.name === "ValidationError") {
        error?.inner?.forEach((err) => {
          newError[err.path] = err.message;
          setErrors(newError || error?.message);
          return;
        });
      } else {
        setSeverity("error");
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    SeasonRegistrationService.getCropYears()
      .then((response) => {
        // Extract crop years from response
        const cropYearsFromApi = response.data.response.map(
          (item) => item.cropYear
        );
        setCropYears(cropYearsFromApi);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <Layout title={edit === true ? "Edit Season" : "Add Season"}>
      <Heading>{edit === true ? "Edit " : "Add "}Season</Heading>
      <PaperContainer>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid
              item
              md={4}
              xs={12}
              sx={{
                display: { md: "flex", sx: "block" },
                alignItems: "center",
              }}
            >
              <CountryFetcher
                setCountries={setCountries}
                setLoading={setLoading}
              />
              <InputLabel
                id="country-select-label"
                sx={{ width: "10rem" }}
                htmlFor="my-input"
              >
                Country
              </InputLabel>
              <Box sx={{ width: { xs: "100%", md: "14rem" } }}>
                <FormControl
                  sx={{ width: { xs: "100%", md: "14rem" } }}
                  error={!!errors.countryName}
                >
                  <TextField
                    id="countryName"
                    value={user?.countryName}
                    name="countryName"
                    onChange={handleChange}
                    labelId="country-select-label"
                    disabled={true}
                  >
                    {/* <MenuItem value="">Select Country</MenuItem> */}
                    {/* {loading ? (
                      <MenuItem disabled>Loading...</MenuItem>
                    ) : (
                      countries.map((country) => (
                        <MenuItem
                          key={country.countryName}
                          value={country.countryName}
                        >
                          {country.countryName}
                        </MenuItem>
                      ))
                    )} */}
                  </TextField>
                </FormControl>
                {errors.countryName && (
                  <FormHelperText error={true}>
                    {errors.countryName}
                  </FormHelperText>
                )}
              </Box>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              sx={{
                display: { md: "flex", xs: "block" },
                alignItems: "center",
              }}
            >
              <InputLabel sx={{ width: "10rem" }} htmlFor="my-input">
                Crop Year
              </InputLabel>
              <FormControl
                sx={{ width: { xs: "100%", md: 222 }, minHeight: 50 }}
              >
                <TextField
                  select
                  id="cyCropYear"
                  labelId="cy-select-label"
                  label={"Select Crop Year"}
                  value={formData.cyCropYear}
                  name="cyCropYear"
                  onChange={handleChange}
                  error={!!errors.cyCropYear}
                  disabled
                >
                  {cropYears.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </TextField>
                <FormHelperText error={true}>
                  {errors.cyCropYear}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              sx={{
                display: { md: "flex", xs: "block" },
                alignItems: "center",
                flexDirection: "end",
              }}
            >
              <InputLabel required sx={{ width: "10rem" }} htmlFor="season-code-select">
                Season Code
              </InputLabel>
              <Box>
                <FormControl
                  sx={{ width: { xs: "100%", md: "14rem" } }}
                  error={!!errors.seasonCode}
                >
                  <TextField
                    select
                    id="season-code-select"
                    labelId="Select Season Code"
                    label="Season Code"
                    value={formData.seasonCode}
                    name="seasonCode"
                    onChange={handleChange}
                    onClick={edit === true ? null : handleSeasonCodeClick}
                    disabled={edit} // Disable until crop year is selected
                  >
                    {/* <MenuItem value="">Select Season Code</MenuItem> */}
                    {formData.cyCropYear &&
                      Array.from({ length: 3 }, (_, i) => (
                        <MenuItem
                          key={i + 1}
                          value={`${String(formData.cyCropYear).slice(-2)}/${i + 1
                            }`}
                        >
                          {String(formData.cyCropYear).slice(-2)}/{i + 1}
                        </MenuItem>
                      ))}
                  </TextField>
                </FormControl>
                {(errors.seasonCode ||
                  (seasonCodeClicked)) && (
                    <FormHelperText error={true}>
                      {errors.seasonCode || "Please select a crop year first"}
                    </FormHelperText>
                  )}
              </Box>
            </Grid>

            <Grid
              item
              md={4}
              xs={12}
              sx={{
                display: { md: "flex", sx: "block" },
                alignItems: "center",
              }}
            >
              <InputLabel required sx={{ width: "10rem" }} htmlFor="my-input">
                Start Date
              </InputLabel>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    sx={{ width: { sx: "100%", md: 224 } }}
                    components={["DatePicker"]}
                  >
                    <DatePicker
                      id="startDate"
                      name="startDate"
                      open={open}
                      onClose={() => setOpen(false)}
                      label="Start Date"
                      format="DD/MM/YYYY"
                      value={reversedDate ? dayjs(reversedDate) : null}
                      onChange={(date) =>
                        handleChange({
                          target: { name: "startDate", value: date },
                        })
                      }
                      maxDate={dayjs(
                        formData.endDate ? formData.endDate : null
                      )}
                      renderInput={(params) => <TextField {...params} />}
                      slotProps={{
                        field: {
                          readOnly: true,
                        },
                        textField: {
                          onClick: () => setOpen(true),
                        },
                      }}
                      error={!!errors.startDate}
                    />
                  </DemoContainer>
                </LocalizationProvider>

                {errors.startDate && (
                  <FormHelperText error={true}>
                    {errors.startDate}
                  </FormHelperText>
                )}
                <FormHelperText error={true}>
                  {errors.profileName}
                </FormHelperText>
              </Box>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              sx={{
                display: { md: "flex", sx: "block", alignItems: "center" },
                alignItems: "center",
              }}
            >
              <InputLabel required sx={{ width: "10rem" }} htmlFor="my-input">
                End Date
              </InputLabel>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    sx={{ width: { sx: "100%", md: 224 } }}
                    components={["DatePicker"]}
                  >
                    <DatePicker
                      id="endDate"
                      label="End Date"
                      name="endDate"
                      format="DD/MM/YYYY"
                      open={openEndCalendar}
                      onClose={() => setOpenEndCalendar(false)}
                      minDate={dayjs(
                        formData.startDate ? formData.startDate : null
                      )}
                      value={reversedEndDate ? dayjs(reversedEndDate) : null}
                      onChange={(date) =>
                        handleChange({
                          target: { name: "endDate", value: date },
                        })
                      }
                      slotProps={{
                        field: {
                          readOnly: true,
                        },
                        textField: {
                          onClick: () => setOpenEndCalendar(true),
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>

                {errors.endDate && (
                  <FormHelperText error={true}>{errors.endDate}</FormHelperText>
                )}
                <FormHelperText error={true}>
                  {errors.profileName}
                </FormHelperText>
              </Box>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <InputLabel
                sx={{ margin: { md: "0 4.1rem 0 3.5rem", xs: "0 4.1rem 0 0rem" } }}
                id="mutiple-select-label"
              >
                Active
              </InputLabel>
              <Checkbox name="isActive"
                checked={formData.isActive}
                onChange={handleChange}
                icon={<img src={CheckboxUntick} />}
                checkedIcon={<img src={CheckboxTick} />}
              />
            </Grid>
          </Grid>
        </form>
        <ButtonContainer>
          <CustomButton
            variant="cancel"
            label="Cancel"
            onClick={() => navigate("/seasonRegistration", { state: { year: cropYear, isFetched: location?.state?.isFetched } })}
          />
          <CustomButton
            loading={loading}
            onClick={edit === true ? handleUpdate : handleSubmit}
            label={edit === true ? "Update" : "Submit"}
          />
        </ButtonContainer>
      </PaperContainer>
    </Layout>
  );
};

const Heading = styled.div`
  color: rgba(115, 125, 134, 1);
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
`;

const PaperContainer = styled(Paper)`
  padding: 3rem 2rem 2rem 2rem;
  margin-bottom: 2rem
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 3rem 0 2rem 0;
`;

export default AddSeason;
