import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { AuthData } from "auth/AuthWarpper";
import { nav } from "./navigation";
import NotFound from "components/NotFound";
import { CircularProgress } from "@mui/material";

export const RenderRoutes = () => {
  const { user } = AuthData();
  const navigate = useNavigate();
  const [test, setTest] = useState(true)





 
  const hasPermission = (permission) => {
    return true
    if (permission == "Dashboard" || permission == "Login") {
      return true
    } else {
      // return true
      return user?.functionalities?.includes(permission);
    }
  };

  return (
    <Routes>
      {nav.map((r, i) =>
        // (<Route key={i} path={r.path} element={r.element} />)
        (<React.Fragment key={i}>{hasPermission(r.name) == true ? <Route exact key={i} path={r.path} element={r.element} /> : <>not found</>}</React.Fragment>)
      )}
     <Route key="222" path="*" element={user? <NotFound/>:<div><CircularProgress position={'center'} size={20} /></div>}   />
    </Routes>
  );
};
