import { httpGrow } from "../http-common";

const picklist = (data) => {
  return httpGrow.post(`/picklist`, data);
};

const PickListService = {
  picklist,
};

export default PickListService;
