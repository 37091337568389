import Layout from "components/structure/Layout";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useContext, useEffect, useState } from "react";
import CustomButton from "utils/CustomButton";
import IosShareIcon from "@mui/icons-material/IosShare";
import GrowingHierarchyService from "services/GrowingHierarchyService";
import { SnackbarContext } from "context/snackBarContext";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import HierarchyTreeTable from "./HierarchyTreeTable";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "49.5rem",
  bgcolor: "background.paper",
  height: "20.125rem",
  borderRadius: "0.625rem",
};

const GrowingHierarchy = () => {
  const navigate = useNavigate();
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [moadalOpen, setModalOpen] = useState(false);
  console.log("🚀 ~ GrowingHierarchy ~ moadalOpen:", moadalOpen)
  const [data, setData] = useState();
  const [rowsData, setRowsData] = useState([]);
  console.log("🚀 ~ GrowingHierarchy ~ rowsData:", rowsData);
  const [selectedId, setSelectedId] = useState(null);
  const [startDate, setStartDate] = useState();

  console.log("🚀 ~ GrowingHierarchy ~ startDate:", startDate);
  const [endDate, setEndDate] = useState();
  console.log("🚀 ~ GrowingHierarchy ~ endDate:", endDate);
  const [code, setCode] = useState("");
  console.log("🚀 ~ GrowingHierarchy ~ code:", code);
  const [dateError, setDateError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);


  console.log("🚀 ~ GrowingHierarchy ~ dateError:", dateError);

  const handleAdd = () => {
    setModalOpen(true);
  };

  const fetchData = async () => {
    try {
      const response = await GrowingHierarchyService.gendrateCode();
      setCode(response.data.response);
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleNo = () => {
    setDateError(false);
    setModalOpen(false);
  };

  const handleDelete = async () => {
    if (!startDate) {
      setDateError(true);
      return;
    }

    const payload = {
      ihvCode: code,
      startDate,
      endDate,
      languageId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      countryId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    };

    console.log("🚀 ~ handleDelete ~ payload:", payload);
    setLoading(true);

    try {
      await GrowingHierarchyService.generateVersion(payload);
      handleData();
      fetchData();
      setModalOpen(false);
      setSeverity("success");
      setMessage("Added successfully");
      setOpenAlert(true);
      setLoading(false);
    } catch (error) {
      setSeverity("error");
      setMessage("Something went wrong");
      setOpenAlert(true);
      console.error("Error deleting irregularity:", error);
      setLoading(false);
    }
  };

  const handleDateChange = (date, setDate) => {
    if (!date || isNaN(new Date(date).getTime())) {
      setDateError(true);
      setDate("");
      return;
    }

    setDateError(false);
    const dateValue = new Date(date);
    const year = dateValue.getFullYear();
    const month = String(dateValue.getMonth() + 1).padStart(2, "0");
    const day = String(dateValue.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setDate(formattedDate);
  };

  const handleData = async () => {
    try {
      const response = await GrowingHierarchyService.getHierarchyList();
      const data = response.data;
      console.log("🚀 ~ handleData ~ data:", data);
      setData(data);
      const rows = data.response.map((irregular) => ({
        id: irregular.ihvCode,
        IsActive: irregular.IsActive,
        endDate: irregular.endDate,
        startDate: irregular.startDate,
        status: irregular.status === true ? "Active" : "Inactive",
        ihvId: irregular.ihvId,
      }));
      setRowsData(rows);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleData();
  }, []);

  return (
    <>
      <Layout title="Hierarchy Tree Configuration">
        <div style={{ float: "right" }}>
          <Button
            sx={{
              borderRadius: 100,
              minWidth: 95,
              minHeight: 44,
              background: "#102A62",
              marginTop: { xs: 1 },
              marginLeft: { xs: 1 },
            }}
            variant="contained"
            startIcon={<AddCircleIcon />}
            onClick={handleAdd}
          >
            Add New version
          </Button>
        </div>
        <Modal
          open={moadalOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              sx={{
                mt: 4,
                justifyContent: "center",
                display: "flex",
                gap: "1rem",
                alignItems: "center",
                padding: "20px",
              }}
            >
              <TextField
                label={code !== "" && "Code"}
                value={code}
                disabled={true}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container>
                  <Grid>
                    <FormControl sx={{ minWidth: "270px" }}>
                      <DatePicker
                        sx={{ width: 270, mr: "1rem" }}
                        label="Start Date"
                        format="DD/MM/YYYY"
                        open={open}
                        onClose={() => setOpen(false)}
                        value={startDate}
                        maxDate={dayjs(
                          endDate ? endDate : null
                        )}
                        slotProps={{
                          field: {
                            readOnly: true,
                          },
                          textField: {
                            onClick: () => setOpen(true),
                          },
                        }}
                        onChange={(newValue) =>
                          handleDateChange(newValue, setStartDate)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            error={dateError}
                            inputProps={{
                              ...params.inputProps,
                              readOnly: true,
                            }}
                            helperText={
                              dateError === true && "Please select a start date"
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid>
                    <FormControl sx={{ minWidth: "270px" }}>
                      <DatePicker
                        label="End Date"
                        sx={{ width: 270 }}
                        format="DD/MM/YYYY"
                        value={endDate}
                        open={openEndDate}
                        minDate={dayjs(
                          startDate ? startDate : null
                        )}
                        slotProps={{
                          field: {
                            readOnly: true,
                          },
                          textField: {
                            onClick: () => setOpenEndDate(true),
                          },
                        }}
                        onClose={() => setOpenEndDate(false)}
                        onChange={(newValue) => {
                          handleDateChange(newValue, setEndDate);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              readOnly: true,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </Box>
            {dateError === true && (
              <span
                style={{
                  color: "red",
                  textAlign: "center",
                  marginLeft: "1.2rem",
                }}
              >
                Please select a start date
              </span>
            )}
            <Box
              sx={{
                mt: 4,
                justifyContent: "center",
                display: "flex",
                gap: "1rem",
              }}
            >
              <CustomButton variant="cancel" label="No" onClick={handleNo} />

              <CustomButton
                color="#EF7D00"
                label="Yes"
                onClick={handleDelete}
                loading={loading}
              />
            </Box>
          </Box>
        </Modal>
        <DataContainer>
          {data ? (
            // <DataTable getRowId={(rowsData) => rowsData.ihvId} data={data} rows={rowsData} columns={columns} />
            <HierarchyTreeTable
              hierarchyData={rowsData}
              fetchData={handleData}
            />
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              No Data Available
            </Box>
          )}
        </DataContainer>
      </Layout>
    </>
  );
};

const DataContainer = styled.div`
  margin-top: 4rem;
`;

export default GrowingHierarchy;
