import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import RedSwitch from "utils/RedSwitch";
import Save from "assets/SaveNew.svg";
import { SnackbarContext } from "context/snackBarContext";
import VarietySeedService from "services/VarietySeedService";

// Styled Components
const StyledTableContainer = styled(TableContainer)(() => ({
  borderRadius: "8px",
  margin: "20px auto",
  overflowX: "visible",
}));

const StyledHeaderCell = styled(TableCell)(() => ({
  fontWeight: "bold",
  textAlign: "center",
  padding: "0px",
  backgroundColor: "#e0e0e0",
  //borderBottom: "2px solid #ddd", // Adds bottom border
  borderLeft: "10px solid white",
  borderRight: "10px solid white",
  borderBottom: "5px solid white",
}));

const StyledDataCell = styled(TableCell)(() => ({
  textAlign: "center",
  padding: "0px",
  borderTop: "1px solid #ddd", // Adds border for cells
  borderBottom: "5px solid white",
  backgroundColor: "grey",
}));

const StyledRow = styled(TableRow)(() => ({
  height: "50px",
  // Optional: Adjust the gap between rows if needed
  "&:not(:last-child)": {
    marginBottom: "8px",
  },
  padding: 0,
}));

export default function CustomTable({
  varietyData,
  cropSeasonId,
  geneticTypeId,
  hierarchicalNodeId,
  childNodes,
  isTopHierarchyNode,
}) {
  const [varietyArray, setVarietyArray] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [importHierarchyMap, setImportHierarchyMap] = useState({});
  const [editedValues, setEditedValues] = useState({});
  const [newValues, setNewValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  useEffect(() => {
    if (varietyData) {
      const extractedValues = varietyData.map((item) => item.vrVariety);
      setVarietyArray(extractedValues);
    }
  }, [varietyData]);

  useEffect(() => {
    const initialState = {};
    varietyArray.forEach((header) => {
      initialState[header] = true; // Set each header as true
    });
    setSelectedHeaders(initialState);
  }, [varietyArray]);

  // const firstTableData = ["Row 1", "Row 2", "Row 3"];
  const [isHaTargetActive, setIsHaTargetActive] = useState(false);

  const handleToggleChange = () => {
    setIsHaTargetActive((prev) => !prev);
  };

  const handleCheckboxChange = (header) => {
    setSelectedHeaders((prevSelected) => ({
      ...prevSelected,
      [header]: !prevSelected[header],
    }));
  };

  const secondHeaderFirstTwo = ["Ha Target", "Total"];

  const { t } = useTranslation();

  const [selectedHeaders, setSelectedHeaders] = useState(() => {
    const initialState = {};
    varietyArray.forEach((header) => {
      initialState[header] = true;
    });
    return initialState;
  });

  const fetchVarietySeedsData = async () => {
    try {
      let response = await VarietySeedService.getVarietySeedsDataAPI({
        cropYearId: cropSeasonId,
        geneticTypeId,
        importVersionId: hierarchicalNodeId 
      });
      
      // Create a mapping of importHierarchyId to varietyId
      const hierarchyMap = response.data.response.reduce((acc, item) => {
        acc[item.importHierarchyId] = {
          varietyId: item.varietyId,
          vtTargetInHa: item.vtTargetInHa,
          vtTargetInPercentage: item.vtTargetInPercentage
        };
        return acc;
      }, {});

      setImportHierarchyMap(hierarchyMap);
      setTableData(response.data.response);
    } catch (err) {
      console.log(err);
    } 
  };

  useEffect(() => {
    fetchVarietySeedsData();
  }, [hierarchicalNodeId]);

  const handleTargetValueChange = (row, header, value) => {
    const key = `${row.label}-${header}`;
    
    // Find the matching node to get the correct identifiers
    const matchingNode = tableData.find(
      item => item.ihLocationName === row.label && 
              item.varietyName === header
    );

    if (matchingNode) {
      // Update existing values
      setEditedValues(prev => ({
        ...prev,
        [key]: {
          importHierarchyId: row.value, // Use the specific child node's ID
          varietyId: matchingNode.varietyId,
          value: value,
          isHaTarget: isHaTargetActive
        }
      }));
    } else {
      // Handle new value creation
      setNewValues(prev => ({
        ...prev,
        [key]: {
          ihLocationName: row.label,
          varietyName: header,
          importHierarchyId: row.value, // Use the specific child node's ID
          value: value,
          isHaTarget: isHaTargetActive
        }
      }));
    }
  };

  const saveChanges = async () => {
    try {
      const currentDateTime = new Date().toISOString();
      const currentUser = "Mayur"; // Replace with actual user info

      // Combine edited and new values
      const combinedValues = {
        ...editedValues,
        ...Object.fromEntries(
          Object.entries(newValues).map(([key, value]) => {
            // Find the matching variety in varietyData to get varietyId
            const matchingVariety = varietyData.find(
              item => item.vrVariety === value.varietyName
            );

            return [key, {
              ...value,
              varietyId: matchingVariety ? matchingVariety.vrVarietyId : null
            }];
          })
        )
      };

      let payload = Object.values(combinedValues).map(item => {
        const varietyId = item.varietyId || 
          (varietyData.find(v => v.vrVariety === item.varietyName)
            ? varietyData.find(v => v.vrVariety === item.varietyName).vrVarietyId
            : null);

        // Find the matching node in tableData to check if it already exists
        const matchingNode = tableData.find(
          node => 
            node.importHierarchyId === item.importHierarchyId && 
            node.varietyId === varietyId
        );

        // Base payload object
        const basePayload = {
          createdDateTimeUTC: currentDateTime,
          createdByUser: currentUser,
          modifiedDateTimeUTC: currentDateTime,
          modifiedByUser: currentUser,
          cropYearId: cropSeasonId,
          geneticTypeId: geneticTypeId,
          importHierarchyId: item.importHierarchyId, // Use the specific child node's ID
          varietyId: varietyId,
          productionTargetDistributionId: "0A2A8E4B-92DE-145B-8193-04E3A11B0049",
          vtTargetInHa: !isHaTargetActive ? Number(item.value) : 0,
          vtTargetInPercentage: isHaTargetActive ? Number(item.value) : 0
        };

        // Add vtId only if the entry already exists
        if (matchingNode && matchingNode.vtId) {
          basePayload.vtId = matchingNode.vtId;
        }

        return basePayload;
      });

      // Add entries for child nodes that don't exist in the current payload
      childNodes.forEach(child => {
        varietyArray.forEach(variety => {
          // Check if an entry for this child node and variety already exists
          const existingEntry = payload.find(
            entry => 
              entry.importHierarchyId === child.value && 
              entry.varietyId === (varietyData.find(v => v.vrVariety === variety)?.vrVarietyId)
          );

          // If no existing entry, add a new one with zero values
          if (!existingEntry) {
            const varietyId = varietyData.find(v => v.vrVariety === variety)?.vrVarietyId;
            
            payload.push({
              createdDateTimeUTC: currentDateTime,
              createdByUser: currentUser,
              modifiedDateTimeUTC: currentDateTime,
              modifiedByUser: currentUser,
              cropYearId: cropSeasonId,
              geneticTypeId: geneticTypeId,
              importHierarchyId: child.value, // Use the specific child node ID
              varietyId: varietyId,
              productionTargetDistributionId: "0A2A8E4B-92DE-145B-8193-04E3A11B0049",
              vtTargetInHa: 0,
              vtTargetInPercentage: 0
            });
          }
        });
      });

      // Call the API to update/create values
      const response = await VarietySeedService.updateVarietySeedsTargets(payload);
      
      // Refresh the data after successful update
      fetchVarietySeedsData();
      
      // Clear the edited and new values
      setEditedValues({});
      setNewValues({});

      // Show success message
      setOpenAlert(true);
      setSeverity("success");
      setMessage("Variety Seeds Targets saved successfully");

    } catch (error) {
      console.error('Error saving updates:', error);
      
      // Show error message
      setOpenAlert(true);
      setSeverity("error");
      setMessage(error.message || "Error saving Variety Seeds Targets");
    }
  };

  const getTargetValue = (row, header, isHaTargetActive) => {
    const key = `${row.label}-${header}`;
    
    // Check if there's an edited value
    if (editedValues[key]) {
      return editedValues[key].value;
    }

    // Check if there's a new value
    if (newValues[key]) {
      return newValues[key].value;
    }

    // If no edited or new value, find from tableData
    const matchingNode = tableData.find(
      item => item.ihLocationName === row.label && 
              item.varietyName === header
    );

    if (matchingNode) {
      // Switch between Ha and Percentage based on toggle state
      return isHaTargetActive 
        ? matchingNode.vtTargetInPercentage 
        : matchingNode.vtTargetInHa;
    }
    return '';
  };

  // Modify the save button to be enabled when either edited or new values exist
  const isSaveDisabled = 
    Object.keys(editedValues).length === 0 && 
    Object.keys(newValues).length === 0;

  // Add a new function to calculate column total
  const calculateColumnTotal = (header) => {
    // Filter table data for the specific variety
    const varietyData = tableData.filter(item => item.varietyName === header);
    
    // Sum the target values based on the current toggle state
    const total = varietyData.reduce((sum, item) => {
      return sum + (isHaTargetActive 
        ? item.vtTargetInPercentage 
        : item.vtTargetInHa
      );
    }, 0);

    return total.toFixed(2); // Format to 2 decimal places
  };

  // Add a new function to calculate row total
  const calculateRowTotal = (row) => {
    // Sum the values for the current row across all visible varieties
    const total = varietyArray
      .filter(header => selectedHeaders[header]) // Only include selected headers
      .reduce((sum, header) => {
        const value = getTargetValue(row, header, isHaTargetActive);
        return sum + (Number(value) || 0);
      }, 0);

    return total.toFixed(2); // Format to 2 decimal places
  };

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {/* toggle grid */}
        <Grid item xs={2} sx={{ height: "10px" }}>
          <Box sx={{ backgroundColor: "#EFEFEF", borderRadius: "8px" }}>
            <Box sx={{ display: "flex" }}>
              <ArrowDropDownIcon sx={{ marginBottom: 1.1 }} />
              <Typography>{t("VarietySeedsTargets.toggle")}</Typography>
            </Box>

            <Box sx={{ display: "flex", ml: 5, height: "46px" }}>
              <Box sx={{ mr: "6px" }}>{t("Ha")}</Box>
              <RedSwitch
                color="primary"
                onChange={handleToggleChange}
                checked={isHaTargetActive}
              />
              <Box sx={{ ml: "6px" }}>{t("%")}</Box>
            </Box>
          </Box>
        </Grid>

        {/* Header checkboxes */}
        <Grid item xs={10}>
          <Box sx={{ backgroundColor: "#EFEFEF", borderRadius: "8px" }}>
            <Box sx={{ display: "flex" }}>
              <ArrowDropDownIcon sx={{ marginBottom: 1.1 }} />
              <Typography>Show Headers</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                flexWrap: "wrap", // This ensures that checkboxes wrap within the container
                maxWidth: "100%", // Ensures wrapping within the container
              }}
            >
              <Box sx={{ display: "flex", ml: 1, flexWrap: "wrap" }}>
                {varietyArray.map((header) => (
                  <Box
                    item
                    key={header}
                    sx={{
                      flexShrink: 0,
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedHeaders[header] || false}
                          onChange={() => handleCheckboxChange(header)}
                        />
                      }
                      label={header}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "16px",
                          fontWeight: 400,
                          wordWrap: "break-word", // Ensures the text wraps within its container
                        },
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container item xs={12}>
        {/* Hierarchical Node Table */}
        <Grid item xs={3}>
          <StyledTableContainer component={Paper}>
            <Table>
              <TableHead>
                <StyledRow>
                  <StyledHeaderCell
                    style={{ backgroundColor: "transparent", border: "none" }}
                  ></StyledHeaderCell>
                  
                </StyledRow>
                <StyledRow>
                  <StyledHeaderCell sx={{fontsize:"35px"}}>
                    {t("VarietySeedsTargets.tbHeader")}
                  </StyledHeaderCell>
                </StyledRow>
              </TableHead>
              <TableBody>
                {childNodes.map((row, index) => (
                  <StyledRow key={index}>
                    <StyledDataCell
                      style={{
                        borderLeft: "10px solid white",
                        backgroundColor: "#EFEFEF",
                        borderRight: "10px solid white",
                      }}
                    >
                      {row.label}
                    </StyledDataCell>
                  </StyledRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Grid>

        {/* When Toggle is OFF  */}
        {isHaTargetActive && (
          <Grid item xs={9}>
            <StyledTableContainer
              component={Paper}
              sx={{
                borderRadius: "8px",
                margin: "20px auto",
                overflowX: "auto",
                maxHeight: "400px",
                pb: "20px",
                "&::-webkit-scrollbar": {
                  width: "12px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#888",
                  borderRadius: "6px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "#555",
                },
              }}
            >
              <Table sx={{ tableLayout: "fixed" }}>
                <TableHead>
                  {/* First Header Row */}
                  <StyledRow>
                    <StyledHeaderCell
                      colSpan={1}
                      style={{ backgroundColor: "transparent", width: "150px" }}
                    />
                    {varietyArray.map(
                      (header, index) =>
                        selectedHeaders[header] && (
                          <StyledHeaderCell
                            key={index}
                            style={{
                              width: "150px",
                              fontSize: "20px",
                              fontWeight: 600,
                            }}
                          >
                            {header}
                          </StyledHeaderCell>
                        )
                    )}
                  </StyledRow>

                  {/* Second Header Row */}
                  <StyledRow>
                    {secondHeaderFirstTwo[1] && (
                      <StyledHeaderCell style={{ backgroundColor: "#e0e0e0" }}>
                        {secondHeaderFirstTwo[1]}
                      </StyledHeaderCell>
                    )}
                    {varietyArray.map(
                      (header, index) =>
                        selectedHeaders[header] && (
                          <StyledHeaderCell
                            key={index}
                            style={{ width: "150px", backgroundColor: "white" }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                              }}
                            >
                              <div style={{ fontWeight: 500 }}>
                                {t("VarietySeedsTargets.secondrow")}
                              </div>
                              <div>
                                <span style={{ fontWeight: 600 }}>
                                  <>
                                  {0} / {calculateColumnTotal(header)}
                                  </>
                                </span>
                              </div>
                            </Box>
                          </StyledHeaderCell>
                        )
                    )}
                  </StyledRow>
                </TableHead>

                <TableBody>
                  {childNodes.map((row, rowIndex) => (
                    <StyledRow key={rowIndex}>
                      {/* First column with hierarchical node label */}
                      <StyledDataCell style={{ backgroundColor: "#EFEFEF" }}>
                        {row.label}
                      </StyledDataCell>

                      {/* Remaining columns conditionally rendered based on checkbox state */}
                      {varietyArray.map(
                        (header, index) =>
                          selectedHeaders[header] && (
                            <StyledDataCell
                              style={{ backgroundColor: "#EFEFEF"  }}
                              key={index}
                            >
                              <TextField
                                variant="outlined"
                                size="small"
                                value={getTargetValue(row, header, isHaTargetActive)}
                                onChange={(e) => {
                                  // Only allow numeric input
                                  const value = e.target.value.replace(/[^0-9.]/g, '');
                                  handleTargetValueChange(row, header, value);
                                }}
                                sx={{
                                  width: "100%",
                                  maxWidth: "150px",
                                  "& input": {
                                    textAlign: "left",
                                    backgroundColor: "white",
                                    border: "1px solid #ccc",
                                    borderRadius: "2px",
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                  },
                                }}
                              />
                            </StyledDataCell>
                          )
                      )}
                    </StyledRow>
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </Grid>
        )}
        {/* When Toggle is ON  */}
        {!isHaTargetActive && (
          <Grid item xs={9}>
            <StyledTableContainer
              component={Paper}
              sx={{
                borderRadius: "8px",
                margin: "20px auto",
                overflowX: "auto",
                maxHeight: "400px",
                pb: "20px",
                "&::-webkit-scrollbar": {
                  width: "12px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#888",
                  borderRadius: "6px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "#555",
                },
              }}
            >
              <Table sx={{ tableLayout: "fixed" }}>
                <TableHead>
                  {/* First Header Row */}
                  <StyledRow>
                    <StyledHeaderCell
                      colSpan={2}
                      style={{ backgroundColor: "transparent", width: "300px" }}
                    />
                    {varietyArray.map(
                      (header, index) =>
                        selectedHeaders[header] && (
                          <StyledHeaderCell
                            key={index}
                            style={{
                              width: "150px",
                              fontSize: "20px",
                              fontWeight: 600,
                            }}
                          >
                            {header}
                          </StyledHeaderCell>
                        )
                    )}
                  </StyledRow>

                  {/* Second Header Row */}
                  <StyledRow>
                    {secondHeaderFirstTwo.map((header, index) => (
                      <StyledHeaderCell
                        style={{ backgroundColor: "#e0e0e0", width: "150px" }}
                        key={index}
                      >
                        {header}
                      </StyledHeaderCell>
                    ))}
                    {varietyArray.map(
                      (header, index) =>
                        selectedHeaders[header] && (
                          <StyledHeaderCell
                            key={index}
                            style={{
                              backgroundColor: "white",
                              width: "150px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                              }}
                            >
                              <div style={{ fontWeight: 500 }}>
                                {t("VarietySeedsTargets.secondrow")}
                              </div>
                              <div>
                                <span style={{ fontWeight: 600 }}>
                                  {0} / {calculateColumnTotal(header)}
                                </span>
                              </div>
                            </Box>
                          </StyledHeaderCell>
                        )
                    )}
                  </StyledRow>
                </TableHead>

                <TableBody>
                  {/* Synchronize rows with the Hierarchical Node Table */}
                  {childNodes.map((row, rowIndex) => (
                    <StyledRow key={rowIndex}>
                      {/* First two columns filled from childNodes */}
                      <StyledDataCell style={{ backgroundColor: "#EFEFEF"  }}>
                        {row.label}
                      </StyledDataCell>
                      <StyledDataCell
                        style={{
                          backgroundColor: "#EFEFEF",
                          color: "Red",
                          fontWeight: 700,
                        }}
                      >
                        {calculateRowTotal(row)}
                      </StyledDataCell>

                      {/* Remaining columns are conditionally rendered based on checkbox state */}
                      {varietyArray.map(
                        (header, index) =>
                          selectedHeaders[header] && (
                            <StyledDataCell
                              style={{ backgroundColor: "#EFEFEF" }}
                              key={index}
                            >
                              <StyledDataCell
                                style={{ backgroundColor: "#EFEFEF"  }}
                              >
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  value={getTargetValue(row, header, isHaTargetActive)}
                                  onChange={(e) => {
                                    // Only allow numeric input
                                    const value = e.target.value.replace(/[^0-9.]/g, '');
                                    handleTargetValueChange(row, header, value);
                                  }}
                                  sx={{
                                    width: "100%",
                                    maxWidth: "150px",
                                    "& input": {
                                      textAlign: "left",
                                      backgroundColor: "white",
                                      border: "1px solid #ccc",
                                      borderRadius: "2px",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      border: "none",
                                    },
                                  }}
                                />
                              </StyledDataCell>
                            </StyledDataCell>
                          )
                      )}
                    </StyledRow>
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </Grid>
        )}
      </Grid>

      <div style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        minHeight: '10vh',
        paddingBottom: '1.5rem',
      }}>
        <Button
          sx={{
            padding: "6px 16px",
            borderRadius: "4px",
            backgroundColor: "#EF7D00",
            color: "white",
            "&:hover": {
              backgroundColor: "#EF7D00",
            },
          }}
          startIcon={<img src={Save} alt="Right Arrow" />}
          onClick={saveChanges}
          variant="contained"
        >
          {t('save')}
        </Button>
      </div>
    </>
  );
}
