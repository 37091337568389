import React from "react";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";

const CustomButton = ({ onClick, variant, label, loading ,width,color}) => {
  console.log("load", loading);
  const buttonStyles = {
    backgroundColor: variant === "cancel" ? "#737D86" : variant === "warning"?"#EF7D00":"#112A63",
    fontWeight: 600,
    width: width ? width : "8.125rem",
    height: "3.375rem",
    borderRadius: "0.625rem",
  };

  return (
    <Button
      sx={buttonStyles}
      variant="contained"
      onClick={onClick}
      disabled={loading}
    >
      {loading && <CircularProgress size={20} />}
      {label}
    </Button>
  );
};

export default CustomButton;
