import React, { useState, useEffect, useContext } from "react";
import { Box, Button, FormControl, MenuItem, TextField, Typography,IconButton } from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import CultureTable from "./CultureTable";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CultureService from "services/CultureService";
import CloseIcon from '@mui/icons-material/Close';

const GetCultureRegistration = () => {
  const navigate = useNavigate();
  const [cultureData, setCultureData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [menuItems, setMenuItems] = useState([]);
  const [menuCulture, setMenuCulture] = useState({});

  const getMenuItemsAPI = () => {
    setLoading(true);
    CultureService.getMenuItems()
      .then((res) => {
        setMenuItems(res.data.response);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  const fetchMenuItemsById = (cuCultureId) => {
    if (cuCultureId == "") return;
    setLoading(true);
    CultureService.getMenuItemsById(cuCultureId)
      .then((res) => {
        setCultureData([res.data.response.culture]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  const callCultureAPI = (pageNumber, size) => {
    setLoading(true);
    CultureService.getCulture({ pageNumber, size })
      .then((res) => {
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setLoading(false);
        setCultureData(res?.data?.response.cultures);
        console.log("Culture Data " + res?.data?.response.cultures[0].cuCultureName);
        setTotalItems(res?.data?.response?.totalItems);
      })
      .catch((error) => {
        setLoading(false);
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  const paginate = (pageNumber, itemsPerPage) => {
    callCultureAPI(pageNumber - 1, itemsPerPage);
  };

  const [selectedValue, setSelectedValue] = useState('');

 

  useEffect(() => {
    getMenuItemsAPI();
    callCultureAPI(currentPage, itemsPerPage);
  }, []);

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={"Culture "}
    >
      <Box mt={4} mb={6}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <FormControl sx={{ minWidth: "18.75rem" }}>
            <TextField
              select
              name="tobaccoGroup"
              label={"Culture"}
              variant="outlined"
              SelectProps={{ IconComponent: ArrowDropDownIcon }}
              onChange={(e) => fetchMenuItemsById(e.target.value)}
            >
              <MenuItem onClick={() => callCultureAPI(currentPage, itemsPerPage)} value="">
                <em>None</em>
              </MenuItem>
              {menuItems.map((item, index) => (
                <MenuItem key={index} value={item.cuCultureId}>
                  {item.cuCultureName}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <Button
            sx={{
              borderRadius: 100,
              minWidth: 95,
              minHeight: 44,
              background: "#102A62",
              marginTop: { xs: 1 },
              marginLeft: { xs: 1 },
            }}
            variant="contained"
            startIcon={<AddCircleIcon />}
            onClick={() => navigate("/addcultureRegistration")}
          >
            Add Culture
          </Button>
        </Box>
        {!loading && cultureData && cultureData.length === 0 && (
          <Typography variant="h6" align="center" color="textSecondary">
            No Data Available
          </Typography>
        )}
        {!loading && cultureData && cultureData.length > 0 && (
          <CultureTable
            setCulturenull={() => setCultureData([])}
            callCultureAPI={() => callCultureAPI(currentPage, itemsPerPage)}
            cultureData={cultureData}
          />
        )}
      </Box>
    </Layout>
  );
};

export default GetCultureRegistration;
