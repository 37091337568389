import { httpGrow } from "../http-common";

const getCuring = async (query) => {
  const response = await httpGrow.get(
    `/curing-type/list?pageNumber=${query?.pageNumber}&size=${query?.size}`
  );
  return response;
};

const getAllCultureNames = async () => {
  const response = await httpGrow.get(`/culture/cultureNames-and-Id`);
  return response;
};

const createCuringType = (data) => {
  return httpGrow.post(`/curing-type`, data);
};

const updateCuringType = (data) => {
  return httpGrow.put(`curing-type`, data);
};

const searchCuring = async (query) => {
  let url = `/curing-type/list?pageNumber=${query?.pageNumber || 0}&size=${
    query?.size || 50
  }`;

  if (query?.keyword) {
    url += `&curingType=${query.keyword}`;
  }

  if (query?.cultureName) {
    url += `&cultureName=${query.cultureName}`;
  }

  if (query?.status !== undefined &&  query?.status !== null) {
    url += `&status=${query.status}`;
  }

  const response = await httpGrow.get(url);

  return response;
};

const deleteCuringType = (curingTypeId) => {
  return httpGrow.delete(`/curing-type/${curingTypeId}`);
};

const PaymentService = {
  getCuring,
  getAllCultureNames,
  createCuringType,
  updateCuringType,
  searchCuring,
  deleteCuringType,
};

export default PaymentService;
