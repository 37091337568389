import { httpAD } from "../http-common";

const getMe = () => {
  return httpAD.get("/me");
};

const get = id => {
  return httpAD.get(`/farmers/${id}`);
};

const create = data => {
  return httpAD.post("/farmers", data);
};

const update = (id, data) => {
  return httpAD.put(`/farmers/${id}`, data);
};

const remove = id => {
  return httpAD.delete(`/farmers/${id}`);
};

const removeAll = () => {
  return httpAD.delete(`/farmers`);
};

const findByBatId = title => {
  // return httpAD.get(`/users?$filter=startswith(name,'85191990@bat.com')`);
  return httpAD.get(`/me`);

};
const genericGet = url => {
  return httpAD.get(url);
};


const AdService = {
  get,
  create,
  update,
  remove,
  removeAll,
  findByBatId,
  genericGet
};

export default AdService;