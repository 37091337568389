import React from 'react';
import { Box, Typography } from "@mui/material";

const Divider = ({ title }) => {
  return (
    <Box
      id="divider"
      sx={{
        marginTop: "8px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "0px 20px 10px",
        gap: "20px",
        isolation: "isolate",
        background: "#FFFFFF",
        borderBottom: "5px solid #004F9F",
        flex: "none",
        order: 2,
        alignSelf: "stretch",
        flexGrow: 0,
        zIndex: 1,
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Montserrat',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '20px',
          color: '#5A646E',
          flex: 'none',
          order: 0,
          flexGrow: 0,
          zIndex: 0,
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default Divider;
