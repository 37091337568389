import React from "react";

const CustomSVG = (props) => {
  return (
    <svg
      width="79"
      height="34"
      viewBox="0 0 79 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.564286"
        y="0.564286"
        width="77.8714"
        height="32.8714"
        rx="16.4357"
        fill="#FEF4D5"
        stroke="#F2BB40"
        stroke-width="1.12857"
      />
      <path
        d="M44.24 19.91H48.19V21H42.94V14H48.05V15.09H44.24V16.91H47.62V17.98H44.24V19.91ZM53.4504 13.58H54.7004V21H53.5004V20.31C53.0804 20.82 52.4604 21.07 51.7304 21.07C50.1804 21.07 49.0304 19.99 49.0304 18.33C49.0304 16.67 50.1804 15.6 51.7304 15.6C52.4204 15.6 53.0304 15.83 53.4504 16.32V13.58ZM51.8804 20C52.7804 20 53.4704 19.35 53.4704 18.33C53.4704 17.31 52.7804 16.66 51.8804 16.66C50.9804 16.66 50.2904 17.31 50.2904 18.33C50.2904 19.35 50.9804 20 51.8804 20ZM56.9656 14.78C56.5056 14.78 56.1656 14.45 56.1656 14.03C56.1656 13.61 56.5056 13.28 56.9656 13.28C57.4256 13.28 57.7656 13.59 57.7656 14C57.7656 14.44 57.4356 14.78 56.9656 14.78ZM56.3356 21V15.66H57.5856V21H56.3356ZM62.0863 19.82L62.4363 20.71C62.1363 20.95 61.6863 21.07 61.2363 21.07C60.0663 21.07 59.3963 20.45 59.3963 19.27V16.7H58.5163V15.7H59.3963V14.48H60.6463V15.7H62.0763V16.7H60.6463V19.24C60.6463 19.76 60.9062 20.04 61.3863 20.04C61.6463 20.04 61.8963 19.97 62.0863 19.82Z"
        fill="#F2BB40"
      />
      <path
        d="M26.3529 11.0438L29.1765 13.8674M24.4706 24.2203H32M16.9412 20.4556L16 24.2203L19.7647 23.2791L30.6692 12.3747C31.0221 12.0217 31.2203 11.543 31.2203 11.0438C31.2203 10.5447 31.0221 10.066 30.6692 9.71301L30.5073 9.55113C30.1543 9.19824 29.6756 9 29.1765 9C28.6773 9 28.1986 9.19824 27.8456 9.55113L16.9412 20.4556Z"
        stroke="#F2BB40"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CustomSVG;
