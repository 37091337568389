import { httpGrow } from "../http-common";

const getTray = async (query) => {
  const response = await httpGrow.get(
    `/tray-type/list?pageNumber=${query?.pageNumber}&size=${query?.size}`
  );
  return response;
};
const createTray = (data) => {
  return httpGrow.post(`/tray-type/save-tray-types`, data);
};

const deleteTray = (ttTrayTypeId) => {
  return httpGrow.delete(`/tray-type/${ttTrayTypeId}`);
};
const searchTray = async (query) => {
  const response = await httpGrow.get(
    `/tray-type/find?pageNumber=${query?.pageNumber}&size=${
      query?.size || 50
    }&ttTrayTypeDescription=${query?.ttTrayTypeDescription || ""}&ttNoOfCells=${
      query?.ttNoOfCells || ""
    }&ttLossPercentage=${query?.ttLossPercentage || ""}&isActive=${
      query?.status !== undefined ? query.status : ""
    }`
  );
  return response;
};

const TrayService = {
  getTray,
  createTray,
  deleteTray,
  searchTray,
};

export default TrayService;
