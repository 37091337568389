import { Box, Button, FormControl, Grid, MenuItem, TextField } from "@mui/material";
import Layout from 'components/structure/Layout'
import React, { useEffect, useState, useContext } from 'react'
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CultureGroupTable from "./CultureGroupTable";
import { useNavigate } from "react-router-dom";
import CultureGroupService from "services/CultureGroup";
import { SnackbarContext } from "context/snackBarContext";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from 'react-i18next';


function GetCultureGroup() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [CultureGroupItems, setCultureGroupItems] = useState([]);
  const [cultureGroupData, setCultureGroupData] = useState(null);

  const getMenuCultureGroupApi = () => {
    setLoading(true);
    CultureGroupService.getMenuCultureGroup()
      .then((res) => {

        setCultureGroupItems(res.data.response);
        setLoading(false);
      })
      .catch((error) => {
      });
  };

  const fetchMenuItemsById = (id) => {

    setLoading(true);
    CultureGroupService.getCultureGroupById(id)
      .then((res) => {
        setCultureGroupData([res.data.response.cultureGroup])
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };
  const callCultureGroupAPI = (pageNumber, size) => {
    setLoading(true);
    CultureGroupService.getCultureGroup({ pageNumber, size })
      .then((res) => {
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setLoading(false);
        setCultureGroupData(res?.data?.response.cultureGroups);
        setTotalItems(res?.data?.response?.totalItems);
      })
      .catch((error) => {
        setLoading(false);
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  const paginate = (pageNumber, itemsPerPage) => {
    callCultureGroupAPI(pageNumber - 1, itemsPerPage);
  };

  const refreshCultureGroupData = () => {
    callCultureGroupAPI(currentPage, itemsPerPage);
    getMenuCultureGroupApi();
  };

  useEffect(() => {
    callCultureGroupAPI(currentPage, itemsPerPage);
    getMenuCultureGroupApi();
  }, []);

  return (
    <div>
      <Layout
        itemsPerPage={itemsPerPage}
        totalItems={totalItems}
        paginate={paginate}
        currentPage={currentPage}
        title={t("cultureGroupRegistration.pageTitle")}
      >
        <Box mt={4} mb={6}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Box display={"flex"} gap={4}>
            <Grid item xs={12} sm={6}>
            <FormControl sx={{ minWidth: "18.75rem" }}>
              <TextField
                select
                name="tobaccoGroup"
                label={t("description")}
                variant="outlined"
                SelectProps={{ IconComponent: ArrowDropDownIcon }}
              >
                <MenuItem value="" onClick={() => callCultureGroupAPI(currentPage, itemsPerPage)}>
                  <em>None</em>
                </MenuItem>
                {CultureGroupItems.map((item, idx) => (
                  <MenuItem onClick={() => fetchMenuItemsById(item.cultureGroupId)} key={idx} value={item.cultureGroupId}>
                    {item.cultureGroupName}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
            <FormControl sx={{ minWidth: "200px" }}>
                <TextField
                  select
                  name="status"
                  // onChange={handleChange}
                  label={t("status")}
                  // value={searchParams.status}
                  SelectProps={{
                    IconComponent: ArrowDropDownIcon,
                    endAdornment: 
                    // searchParams.status && 
                    (
                      <CloseIcon
                        onClick={() => ""}
                        style={{
                          cursor: "pointer",
                          marginRight: "12px",
                        }}
                      />
                    ),
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"Active"}>{t("active")}</MenuItem>
                  <MenuItem value={"Inactive"}>{t("inactive")}</MenuItem>
                </TextField>
              </FormControl>
              </Grid>
          </Box>
            <Button
              sx={{
                borderRadius: 100,
                minWidth: 95,
                minHeight: 44,
                background: "#102A62",
                marginTop: { xs: 1 },
                marginLeft: { xs: 1 },
              }}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => navigate("/addnewcultureGroup")}>
              {t("addNew")}
            </Button>
          </Box>
          {!loading && (
            <CultureGroupTable
              cultureGroupData={cultureGroupData}
              refreshData={refreshCultureGroupData}
            />
          )}
        </Box>
      </Layout>
    </div>
  )
}
export default GetCultureGroup


