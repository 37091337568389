import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate, useNavigation } from "react-router-dom";
import { GridActionsCellItem } from "@mui/x-data-grid";
import editButton from "../../../assets/editButton.svg";
import iconView from "../../../assets/iconView.svg";
import iconEdit from "../../../assets/iconEdit.svg";
import iconDelete from "../../../assets/iconDelete.svg";
import Layout from "components/structure/Layout";
import DataTable from "utils/DataTable";

const cropYearDetailsList = [
  {
    cropYear: 2022,
    initialDate: "2022-01-01T00:00:00",
    endDate: "2022-12-31T00:00:00",
    status: "Y",
    territories: ["ZONE_ABC", "ZONE_XYZ", "ZONE_BCD"],
  },
  {
    cropYear: 2023,
    initialDate: "2023-01-01T00:00:00",
    endDate: "2023-12-31T00:00:00",
    status: "Y",
    territories: ["ZONE_ABC", "ZONE_XYZ", "ZONE_BCD"],
  },
  {
    cropYear: 2021,
    initialDate: "2021-01-01T00:00:00",
    endDate: "2021-12-31T00:00:00",
    status: "Y",
    territories: ["ZONE_ABC", "ZONE_XYZ", "ZONE_BCD"],
  },
];

const columns = [
  {
    field: "cropYear",
    headerName: "Crop Year",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "initialDate",
    headerName: "Initial Date",
    width: 200,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "endDate",
    headerName: "End Date",
    width: 200,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "territories",
    headerName: "Territories",
    width: 250,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "actions",
    type: "actions",
    headerName: "Actions",
    width: 250,
    headerAlign: "center",
    align: "center",
    getActions: (params) => [
      <GridActionsCellItem
        icon={<img src={editButton} />}
        label="Edit"
        className="gridActionCellEditSingle"
        onClick={() => alert(`Edit ${params.id}`)}
      />,
      <GridActionsCellItem
        icon={<img src={iconView} height="30px" width="30px" />}
        label="Details"
        className="gridActionCell gridActionCellDetails"
        onClick={() => alert(`Details ${params.id}`)}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<img src={iconEdit} height="30px" width="30px" />}
        className="gridActionCell gridActionCellEdit"
        label="Edit"
        onClick={() => alert(`Edit ${params.id}`)}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<img src={iconDelete} height="30px" width="30px" />}
        label="Delete"
        className="gridActionCell gridActionCellDelete"
        onClick={() => alert(`Delete ${params.id}`)}
        showInMenu
      />,
    ],
  },
];

// Convert cropYearDetailsList to rows
const rows = cropYearDetailsList.map((cropYear) => ({
  id: cropYear.cropYear,
  cropYear: cropYear.cropYear,
  initialDate: cropYear.initialDate,
  endDate: cropYear.endDate,
  status: cropYear.status,
  territories: cropYear.territories.join(", "), // Join territories array into a string
}));

console.log("Columns:", columns);
console.log("Rows:", rows);

const CropYearRegistration = () => {
  const [age, setAge] = React.useState("");
  const [data, setData] = useState();

  console.log({ data });

  const navigate = useNavigate();

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  // const rows = [
  //   {
  //     id: 1,
  //     farmercode: "005423",
  //     farmername: "José Carlos  Alcantra Neto",
  //     region: "Rio Grande do Sul - RS",
  //     status: "Active",
  //   },
  //   {
  //     id: 2,
  //     farmercode: "000221",
  //     farmername: "João Marcos Silveira",
  //     region: "Acre - AC",
  //     status: "Active",
  //   },
  //   {
  //     id: 3,
  //     farmercode: "003698",
  //     farmername: "Antônio Augusto da Costa",
  //     region: "Paraná - PR",
  //     status: "Active",
  //   },
  //   {
  //     id: 4,
  //     farmercode: "00421",
  //     farmername: "Lucinda da Silva Silveira",
  //     region: "Rio Grande do Sul - RS",
  //     status: "Active",
  //   },
  //   {
  //     id: 5,
  //     farmercode: "00365",
  //     farmername: "Silvano Correa Silva",
  //     region: "Rio Grande do Sul - RS",
  //     status: "Active",
  //   },
  //   {
  //     id: 6,
  //     farmercode: "003698",
  //     farmername: "Silvano Correa Silva",
  //     region: "Rio Grande do Sul - RS",
  //     status: "Active",
  //   },
  //   {
  //     id: 7,
  //     farmercode: "003698",
  //     farmername: "Silvano Correa Silva",
  //     region: "Rio Grande do Sul - RS",
  //     status: "Active",
  //   },
  //   {
  //     id: 8,
  //     farmercode: "003698",
  //     farmername: "Silvano Correa Silva",
  //     region: "Rio Grande do Sul - RS",
  //     status: "Active",
  //   },
  //   {
  //     id: 9,
  //     farmercode: "003698",
  //     farmername: "Silvano Correa Silva",
  //     region: "Rio Grande do Sul - RS",
  //     status: "Active",
  //   },
  //   {
  //     id: 10,
  //     farmercode: "003698",
  //     farmername: "Silvano Correa Silva",
  //     region: "Rio Grande do Sul - RS",
  //     status: "Active",
  //   },
  //   {
  //     id: 11,
  //     farmercode: "003698",
  //     farmername: "Silvano Correa Silva",
  //     region: "Rio Grande do Sul - RS",
  //     status: "Active",
  //   },
  // ];

  // const columns = [
  //   {
  //     field: "farmercode",
  //     headerName: "Farmer code",
  //     width: 230,
  //     headerAlign: "center",
  //     align: "center",
  //     headerClassName: "data-table-header",
  //   },
  //   {
  //     field: "farmername",
  //     headerName: "Farmer name",
  //     width: 230,
  //     headerAlign: "center",
  //     align: "center",
  //   },
  //   {
  //     field: "region",
  //     headerName: "Region",
  //     width: 230,
  //     headerAlign: "center",
  //     align: "center",
  //   },
  //   {
  //     field: "status",
  //     headerName: "Status",
  //     width: 230,
  //     headerAlign: "center",
  //     align: "center",
  //   },
  //   {
  //     field: "actions",
  //     type: "actions",
  //     headerName: "Actions",
  //     width: 230,
  //     headerAlign: "center",
  //     align: "center",
  //     getActions: (params) => [
  //       <GridActionsCellItem
  //         icon={<img src={editButton} />}
  //         label="Edit"
  //         className="gridActionCellEditSingle"
  //         onClick={() => alert(`Edit ${params.id}`)}
  //       />,
  //       <GridActionsCellItem
  //         icon={<img src={iconView} height="30px" width="30px" />}
  //         label="Details"
  //         className="gridActionCell gridActionCellDetails"
  //         onClick={() => alert(`Details ${params.id}`)}
  //         showInMenu
  //       />,
  //       <GridActionsCellItem
  //         icon={<img src={iconEdit} height="30px" width="30px" />}
  //         className="gridActionCell gridActionCellEdit"
  //         label="Edit"
  //         onClick={() => alert(`Edit ${params.id}`)}
  //         showInMenu
  //       />,
  //       <GridActionsCellItem
  //         icon={<img src={iconDelete} height="30px" width="30px" />}
  //         label="Delete"
  //         className="gridActionCell gridActionCellDelete"
  //         onClick={() => alert(`Delete ${params.id}`)}
  //         showInMenu
  //       />,
  //     ],
  //   },
  // ];

  const handleData = async () => {
    try {
      const response = await fetch(
        "https://jsonplaceholder.typicode.com/users"
      );
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleData();
  }, []);

  return (
    <Layout title="Crop Year Registration">
      <RegistrationOptions>
        <div>
          <FormControl sx={{ m: 1, minWidth: 300, minHeight: 50 }}>
            <Select
              value={age}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="">
                <em>Crop Year</em>
              </MenuItem>
              <MenuItem value={10}>2021</MenuItem>
              <MenuItem value={20}>2022</MenuItem>
              <MenuItem value={30}>2023</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 300, minHeight: 50 }}>
            <Select
              value={age}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="">
                <em>Status</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div>
          <Button
             sx={{
              width: { xs: "100%", md: 300 },
              borderRadius: 50,
              background: "#102A62",
            }}
            variant="contained"
            startIcon={<AddCircleIcon />}
            onClick={() => navigate("/saveCropYearRegistration")}
          >
            Add crop year aa
          </Button>
        </div>
      </RegistrationOptions>
      <DataContainer>
        <DataTable data={data} rows={rows} columns={columns} />
      </DataContainer>
    </Layout>
  );
};

const RegistrationOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5rem;
`;

const DataContainer = styled.div`
  margin-top: 2rem;
`;

export default CropYearRegistration;
