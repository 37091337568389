// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@media screen and (max-width:1510px) and (min-width:1270px ) {
    .TargetEditBody ,p{
        font-size: 0.8rem !important;
        padding-top: 0px !important;
    }
    .TargetEditHeader ,th{
        font-size: 16px !important; 
        font-weight: 400 !important;
    }
}

@media screen and (max-width:1270px) and (min-width:900px ) {
    .TargetEditBody ,p{
        font-size: 0.6rem !important;
        padding-top: 4px !important;
    }
    .TargetEditHeader ,th{
        font-size: 12px !important; 
        font-weight: 400;
    }
}
.TargetEditTableContainer{
    overflow: scroll !important;
}
@media screen and (min-width:1270px) {
    .TargetEditTableContainer{
        overflow: scroll !important;
    }

}

.table-container {
    max-width: 100%;
    overflow-x: auto; /* Enable horizontal scrolling */
}

.full-width-table {
    width: 100%;
    /* Add any additional styles you need for the table */
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Hierarchy/Hierarchy.css"],"names":[],"mappings":";AACA;IACI;QACI,4BAA4B;QAC5B,2BAA2B;IAC/B;IACA;QACI,0BAA0B;QAC1B,2BAA2B;IAC/B;AACJ;;AAEA;IACI;QACI,4BAA4B;QAC5B,2BAA2B;IAC/B;IACA;QACI,0BAA0B;QAC1B,gBAAgB;IACpB;AACJ;AACA;IACI,2BAA2B;AAC/B;AACA;IACI;QACI,2BAA2B;IAC/B;;AAEJ;;AAEA;IACI,eAAe;IACf,gBAAgB,EAAE,gCAAgC;AACtD;;AAEA;IACI,WAAW;IACX,qDAAqD;AACzD","sourcesContent":["\n@media screen and (max-width:1510px) and (min-width:1270px ) {\n    .TargetEditBody ,p{\n        font-size: 0.8rem !important;\n        padding-top: 0px !important;\n    }\n    .TargetEditHeader ,th{\n        font-size: 16px !important; \n        font-weight: 400 !important;\n    }\n}\n\n@media screen and (max-width:1270px) and (min-width:900px ) {\n    .TargetEditBody ,p{\n        font-size: 0.6rem !important;\n        padding-top: 4px !important;\n    }\n    .TargetEditHeader ,th{\n        font-size: 12px !important; \n        font-weight: 400;\n    }\n}\n.TargetEditTableContainer{\n    overflow: scroll !important;\n}\n@media screen and (min-width:1270px) {\n    .TargetEditTableContainer{\n        overflow: scroll !important;\n    }\n\n}\n\n.table-container {\n    max-width: 100%;\n    overflow-x: auto; /* Enable horizontal scrolling */\n}\n\n.full-width-table {\n    width: 100%;\n    /* Add any additional styles you need for the table */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
