import React, { useContext, useEffect, useState } from "react";
import "./Variety.css";
import EditButton from "utils/EditButton";
import VarietyService from "services/VarietyService";
import { useNavigate } from "react-router-dom";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Box, Button, Modal, Typography } from "@mui/material";
import { SnackbarContext } from "context/snackBarContext";
import _ButtonEdit_ from "assets/_ButtonEdit_.svg";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";

// const VarietyTable = (props) => {
//   const [varietyData, setVarietyData] = useState([]);

//   useEffect(() => {
//     setVarietyData(props.varietyData);
//   }, [props.varietyData]);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 580,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const VarietyTable = (props) => {
  const [varietyData, setVarietyData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...varietyData].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setVarietyData(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        return <ArrowUpwardIcon fontSize="small" />;
      } else if (sortConfig.direction === "desc") {
        return <ArrowDownwardIcon fontSize="small" />;
      }
    }
    // Default icon (ArrowDownward) when no sorting is applied
    return <ArrowDownwardIcon fontSize="small" />;
  };

  const handleOpen = (vrVarietyId) => {
    setDeleteId(vrVarietyId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const response = await VarietyService.deleteVariety(deleteId);
      if (response.status === 200) {
        setMessage("Variety deleted successfully");
        setSeverity("success");
        setOpenAlert(true);
        setVarietyData((prevData) => prevData.filter((item) => item.vrVarietyId !== deleteId));
      } else {
        setMessage("Failed to delete variety");
        setSeverity("error");
        setOpenAlert(true);
      }
    } catch (error) {
      setMessage("Error deleting variety");
      setSeverity("error");
      setOpenAlert(true);
      console.error("Error deleting variety:", error);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  useEffect(() => {
    setVarietyData(props.varietyData);
    setOriginalData(props.varietyData);
  }, [props.varietyData]);
  return (
    // <div className="table-container">
    //   <table className="full-width-table">
    //     {" "}
    //     {/* Add a class for styling */}
    //     <thead>
    //       <tr className="HierarchyHead">
    //         <th>Variety</th>
    //         <th>Culture</th>
    //         <th>Culture Type </th>
    //         <th>Status</th>
    //         <th>Actions</th>
    //       </tr>
    //     </thead>
    //     <tbody>
    //       {varietyData?.map((row, index) => (
    //         <tr className="hierarchyColumn" key={index} style={{}}>
    //           <td style={{ textAlign: "center" }}>
    //             <Typography
    //               sx={{
    //                 justifyContent: "left",
    //                 fontWeight: 600,
    //                 color: "#3B454F",
    //               }}
    //             >
    //               {row.vrVariety}
    //             </Typography>
    //           </td>
    //           <td style={{ textAlign: "center" }}>
    //             <Box
    //               sx={{
    //                 whiteSpace: "nowrap",
    //                 paddingLeft: "5px",
    //                 overflow: "hidden",
    //                 textOverflow: "ellipsis",
    //                 fontWeight: 600,
    //                 color: "#3B454F",
    //               }}
    //             >
    //               <span title={row.cuCultureName}>
    //                 {/* { > 20
    //                     ? row.hlcLevelName.substring(0, 20) + "..."
    //                     : row.hlcLevelName} */}
    //                 {row.cuCultureName}
    //               </span>
    //             </Box>
    //           </td>
    //           <td style={{ textAlign: "center" }}>
    //             <div
    //               className="hierarchyConfig"
    //               style={{
    //                 fontWeight: 600,
    //                 color: "#3B454F",
    //                 display: "flex",
    //                 justifyContent: "center",
    //                 alignItems: "center",
    //               }}
    //             >
    //             {row.varietyCultureType.map(item => item.ctCultureType).join(", ")}

    //             </div>
    //           </td>
    //           <td
    //             style={{
    //               textAlign: "center",
    //               fontWeight: 600,
    //               color: "#3B454F",
    //             }}
    //           >
    //             {row.isActive ? "Active" : "Inactive"}
    //           </td>
    //           <td
    //             style={{
    //               textAlign: "center",
    //             }}
    //           >
    //             <div
    //               style={{
    //                 display: "flex",
    //                 alignItems: "center",
    //                 justifyContent: "center",
    //               }}
    //             >
    //               <EditButton
    //                 url="/varietyForm"
    //                 state={{
    //                   vrVarietyId: row.vrVarietyId,
    //                   cuCultureName: row.cuCultureName,
    //                   vrVariety: row.vrVariety,
    //                   varietyCultureType: row?.varietyCultureType[0]?.ctCultureTypeId,
    //                   linearMeter: row.linearMeter,
    //                   vrVarietyCultureTypeId:row?.varietyCultureType[0]?.vrVarietyCultureTypeId,
    //                   newCuringUnit:row.newCuringUnit,
    //                   isActive: row.isActive,
    //                   tobaccoGroup:row.tobaccoGroup,
    //                   edit: true,
    //                 }}
    //               />
    //             </div>
    //           </td>
    //         </tr>
    //       ))}
    //     </tbody>
    //   </table>
    // </div>
    <div className="table-container">
      <table className="custom-table">
        <thead>
          <tr>
            <th
            onClick={() => handleSort("vrVariety")}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              Variety
              {getSortIcon("vrVariety")}
            </th>
            <th
              onClick={() => handleSort("cuCultureName")}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              Culture
              {getSortIcon("cuCultureName")}
            </th>
            <th
              onClick={() => handleSort("ctCultureType")}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              Culture Type
              {getSortIcon("ctCultureType")}
            </th>
            <th
              onClick={() => handleSort("isActive")}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              Status
              {getSortIcon("isActive")}
            </th>
            <th className="actions-header">Actions</th>
          </tr>
        </thead>
        <tbody>
          {varietyData.map((row, index) => (
            <tr key={index}>
              <td>{row.vrVariety}</td>
              <td>{row.cuCultureName}</td>
              <td>{row.varietyCultureType.map(item => item.ctCultureType).join(", ")}</td>
              <td>{row.isActive === true ? "Active" : "Inactive"}</td>
              <td className="actions-cell">
                <img
                  className="btn edit-btn"
                  onClick={() =>
                    navigate("/varietyForm", {
                      state: {

                        vrVarietyId: row.vrVarietyId,
                        cuCultureName: row.cuCultureName,
                        vrVariety: row.vrVariety,
                        varietyCultureType: row?.varietyCultureType[0]?.ctCultureTypeId,
                        linearMeter: row.linearMeter,
                        vrVarietyCultureTypeId: row?.varietyCultureType[0]?.vrVarietyCultureTypeId,
                        newCuringUnit: row.newCuringUnit,
                        isActive: row.isActive,
                        tobaccoGroup: row.tobaccoGroup,
                        edit: true,

                      },
                    })
                  }
                  src={_ButtonEdit_}
                  alt="Edit"
                />
                <img
                  className="btn delete-btn"
                  onClick={() => handleOpen(row.vrVarietyId)}
                  src={_ButtonDelete_}
                  alt="Delete"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}>
              Delete Confirmation
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography sx={{ mt: 2, fontWeight: 400 }}>
              Are you sure you want to delete this record? Deleted records cannot be recovered.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "20px", mb: 1 }}>
            <Button
              onClick={handleClose}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": {
                  background: "#737D86",
                },
              }}
            >
              No
            </Button>
            <Button
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": {
                  background: "#004F9F",
                },
              }}
              onClick={handleDelete}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default VarietyTable;
