import React, { useEffect, useState } from "react";
import {
    Typography,
    Box,
    Checkbox,
    Icon,
    Button,
    TextField,
} from "@mui/material";
import plusIcon from "assets/iconLeft.svg"
import './Hierarchy.css';

const TargetListEdit = (props) => {
    const [targetDataHlc, settargetHlcData] = useState([]);
    const [loading, setLoading] = React.useState(false);
    useEffect(() => {
        settargetHlcData(props.hierarchyTargetHlc)
    }, [props]);

    return (
        <>
            <Button style={{ margin: '30px 0px 30px 10px', color: "#004F9F", borderColor: '#004F9F' }}
                required variant="outlined" startIcon={<Icon>
                    <img alt="edit" style={{ display: "flex", justifyContent: "center" }} width="100%" height="100%" src={plusIcon} />
                </Icon>}
                onClick={() => props.setTargetHlcActive()}
            > Back Page</Button>
            <Box
                sx={{
                    margin: '0px',
                    flexGrow: 1,
                    width: "100%",
                    "& .MuiTextField-root": {
                        m: 1,
                        color: "green !important",
                    },
                    "& .MuiFormLabel-root": {
                        color: "rgba(16, 42, 98, 1)",
                        "& .MuiFormLabel-asterisk": {
                            color: "rgba(239, 0, 0, 1)",
                            fontSize: "18px",
                        }
                    },
                }}
            >
                <TextField
                    disabled
                    id="outlined-disabled"
                    label="Unit of Measure"
                    defaultValue={props?.targetHlcRow?.unitOfMeasure}
                />
            </Box>

            <div className="table-container ">
                <table className="full-width-table TargetEditTableContainer"> {/* Add a class for styling */}
                    <thead className="TargetEditHeader"  >
                        <tr className="HierarchyHead">
                            <th>Level Order</th>
                            <th>Level Name</th>
                            <th>Configurations</th>
                        </tr>
                    </thead>
                    <tbody className="TargetEditBody" >
                        {targetDataHlc &&
                            targetDataHlc?.targetHLC?.map((row, index) => (
                                <tr>
                                    <td style={{ textAlign: "" }}>
                                        <Typography sx={{ justifyContent: "left" }}>{index + 1}</Typography>
                                    </td>
                                    <td style={{ textAlign: "" }}>
                                        <Typography sx={{ justifyContent: "left" }}>{row?.targetHlcLevelName}</Typography>
                                    </td>
                                    <td style={{ display: "flex", textAlign: "" }}>
                                        <Checkbox disabled={loading || row.isGroupingLevelSet} checked={row.hasTarget} onClick={(e) => props.onCheckboxClickHlc(row.hasTarget, "hasTarget", row)}></Checkbox><Typography sx={{ marginTop: "10px" }}>Has Target/Limit</Typography>
                                        <Checkbox disabled={loading || row.isGroupingLevelSet} checked={row.editMyTarget} onClick={(e) => props.onCheckboxClickHlc(row.editMyTarget, "editMyTarget", row)}></Checkbox><Typography sx={{ marginTop: "10px" }}>Edit My Target/Limit</Typography>
                                        <Checkbox disabled={loading || row.isGroupingLevelSet} checked={row.editTargetsBelow} onClick={(e) => props.onCheckboxClickHlc(row.editTargetsBelow, "editTargetsBelow", row)}></Checkbox><Typography sx={{ marginTop: "10px" }}>Edit Targets/Limits Below</Typography>
                                        <Checkbox disabled={loading || row.isGroupingLevelSet} checked={row.editAllTargets} onClick={(e) => props.onCheckboxClickHlc(row.editAllTargets, "editAllTargets", row)}></Checkbox><Typography sx={{ marginTop: "10px" }}>Edit All Targets/Limits</Typography>
                                        <Checkbox disabled={loading || row.isGroupingLevelSet} checked={row.notifyNextLevel} onClick={(e) => props.onCheckboxClickHlc(row.notifyNextLevel, "notifyNextLevel", row)}></Checkbox><Typography sx={{ marginTop: "10px" }}>Notify Next Level</Typography>
                                        <Checkbox disabled={loading || row.isGroupingLevelSet} checked={row.notifyPreviousLevel} onClick={(e) => props.onCheckboxClickHlc(row.notifyPreviousLevel, "notifyPreviousLevel", row)}></Checkbox><Typography sx={{ marginTop: "10px" }}>Notify Previous Level</Typography>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </>
    )
};

export default TargetListEdit;
