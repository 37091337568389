import axios from "axios";
import Layout from "components/structure/Layout";
import styled from "@emotion/styled";
import Paper from "@mui/material/Paper";
import CustomButton from "utils/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useState, useContext, useEffect } from "react";
import { SnackbarContext } from "context/snackBarContext";
import {
  FormHelperText,
  Grid,
  ListItemText,
  InputLabel,
  Box,
  FormControl,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import RedSwitch from "utils/RedSwitch";
import IrregularityService from "services/IrregularityService";
import { AuthData } from "auth/AuthWarpper";
import { useTranslation } from 'react-i18next';

const validationSchema = Yup.object({
  gitIrregularityTypeDescription: Yup.string()
    .required("Description is required")
    .max(50, "Description must be less than 50 characters")
    .matches(
      /^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/,
      "Description is required and must not contain special characters or blank spaces"
    ),
});
const AddIrregularity = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = AuthData();
  const { setOpenAlert, setSeverity, setMessage, handleClose } =
    useContext(SnackbarContext);
  const { desc, code, status, edit, id } = location.state || {};
  console.log("🚀 ~ AddIrregularity ~ edit:", edit);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [unique, setUnique] = useState("")
  const [formData, setFormData] = useState({
    gitCode: edit ? code || 0 : 0,
    gitIrregularityTypeDescription: desc || "",
    gitIrregularityStatus: status === "Active" ? true : false || false,
  });

  console.log("🚀 ~ AddIrregularity ~ formData:", formData);


  const generateUniqueItrCode = async () => {
    try {
      const response = await IrregularityService.getUniqueCode();
      const uniqueCode = response.data.response;
      setFormData((prevData) => ({
        ...prevData,
        gitCode: uniqueCode,
      }));
      setUnique(uniqueCode)
    } catch (error) {
      console.error("Error fetching unique gitCode:", error);
      // Handle error
    }
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    let val;

    if (name === "gitIrregularityStatus") {
      val = checked;
    } else {
      val = value;
    }

    setFormData({
      ...formData,
      [name]: val,
    });
  };

  useEffect(() => {
    if (!edit) {
      generateUniqueItrCode();
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      setErrors({});
      await validationSchema.validate(formData, { abortEarly: false });

      // Format startDate and endDate before submitting
      const formattedFormData = {
        ...formData,
        gitCode: unique,
        createdByUser: user.userName,
        modifiedByUser: user.userName,
        // countryId:"5D68BC83-3635-4A2B-A0DF-1199B0B75C6E",
        // languageId:"960D7994-5B31-4E5B-8D05-C6D58D918229",
        countryId: "06fde653-a2c4-43b7-879f-4525039d9148",
        languageId: "0a31e178-fdf9-4c8c-bd7b-447a788eada5",
        gitCropYearUid: "0388F9D6-08C4-42A6-A8A2-54838E09EC91"
      };

      // Make a POST request with the formatted data using Axios
      const response = await IrregularityService.createIrregularity(formattedFormData)

      if (response.status === 200) {
        navigate("/irregularityRegistration", {
          state: { year: formData.cyCropYear },
        });

        setSeverity("success");
        setMessage(t("snackbarMessage.successAdd"));
        setOpenAlert(true);
      } else {
        setSeverity("error");
        setMessage("An error occurred while submitting the form");
        setOpenAlert(true);
        console.error("Failed to add");
      }
    } catch (error) {
      let newError = {};
      if (error?.name && error.name === "ValidationError") {
        error?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setErrors(newError); // Set errors outside of the loop
      } else {
        setSeverity("error");
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
      }
    }
    setLoading(false);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      setErrors({});
      await validationSchema.validate(formData, { abortEarly: false });

      // Format startDate and endDate before submitting
      const formattedFormData = {
        ...formData,
        gitIrregularityId: id,
        modifiedByUser: user.userName,
        countryId: "5D68BC83-3635-4A2B-A0DF-1199B0B75C6E",
        languageId: "960D7994-5B31-4E5B-8D05-C6D58D918229",
        gitCropYearUid: "0388F9D6-08C4-42A6-A8A2-54838E09EC91"
      };

      // Make a POST request with the formatted data using Axios
      const response = await IrregularityService.updateIrregularity(formattedFormData)

      if (response.status === 200) {
        navigate("/irregularityRegistration", {
          state: { year: formData.cyCropYear },
        });

        setSeverity("success");
        setMessage(t("snackbarMessage.updatedSuccessfully"));
        setOpenAlert(true);
      } else {
        setSeverity("error");
        setMessage("An error occurred while updating the form");
        setOpenAlert(true);
        console.error("Failed to add");
      }
    } catch (error) {
      let newError = {};
      if (error?.name && error.name === "ValidationError") {
        error?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setErrors(newError); // Set errors outside of the loop
      } else {
        setSeverity("error");
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
      }
    }
    setLoading(false);
  };

  return (
    <Layout title={edit ? "Edit Irregularity Type Registration" : "Add Irregularity Type Registration"}>
      <PaperContainer>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid
              item
              md={2}
              xs={12}
              sx={{
                display: { md: "block", sx: "block" },
                alignItems: "center",
              }}
            >
              <InputLabel
                id="gitCode-select-label"
                sx={{ width: "10rem", mb: 2 }}
                htmlFor="my-input"
              >
                Code
              </InputLabel>
              <Box sx={{ width: { xs: "100%", md: "14rem" } }}>
                <FormControl
                  sx={{ width: { xs: "100%", md: "14rem" } }}
                  error={!!errors.gitIrregularityTypeDescription}
                >
                  <TextField
                    id="gitCode"
                    value={formData.gitCode}
                    name="gitCode"
                    onChange={handleChange}

                    disabled={true}
                  ></TextField>
                </FormControl>
              </Box>
            </Grid>
            <Grid
              item
              md={8}
              xs={12}
              sx={{
                display: { md: "block", sx: "block" },
                alignItems: "center",
              }}
            >
              <InputLabel
                id="country-select-label"
                sx={{ width: "10rem", mb: 2 }}
                htmlFor="my-input"
              >
                Description
              </InputLabel>
              <Box sx={{ width: { xs: "100%", md: "100%" } }}>
                <FormControl
                  sx={{ width: { xs: "100%", md: "100%" } }}
                  error={!!errors.gitIrregularityTypeDescription}
                >
                  <TextField
                    id="gitIrregularityTypeDescription"
                    value={formData.gitIrregularityTypeDescription}
                    name="gitIrregularityTypeDescription"
                    onChange={handleChange}
                    labelId="country-select-label"
                    label={formData.gitIrregularityTypeDescription ? "" : "Type here"}
                  ></TextField>
                </FormControl>
                {errors.gitIrregularityTypeDescription && (
                  <FormHelperText error={true}>
                    {errors.gitIrregularityTypeDescription}
                  </FormHelperText>
                )}
              </Box>
            </Grid>
            <Grid
              item
              md={2}
              xs={12}
              sx={{
                display: "block",
                alignItems: "center",
                marginLeft: "0px"
              }}
            >
              <InputLabel sx={{ mb: 2 }} id="mutiple-select-label">
                Status
              </InputLabel>
              <FormControlLabel
                control={
                  <RedSwitch
                    checked={formData.gitIrregularityStatus}
                    onChange={handleChange}
                    name="gitIrregularityStatus"
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={formData.gitIrregularityStatus === true ? "Active" : "InActive"}
                labelPlacement="start"
              />
            </Grid>
          </Grid>
        </form>
        <ButtonContainer>
          <CustomButton
            variant="cancel"
            label="Cancel"
            onClick={() => navigate("/irregularityRegistration")}
          />
          <CustomButton
            onClick={edit === true ? handleUpdate : handleSubmit}
            label={edit === true ? "Update" : "ADD IRREGULARITY TYPE"}
            loading={loading}
            width="15rem"
          />
        </ButtonContainer>
      </PaperContainer>
    </Layout>
  );
};

const PaperContainer = styled(Paper)`
  padding: 3rem 2rem 2rem 2rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 3rem 0 2rem 0;
`;

export default AddIrregularity;
