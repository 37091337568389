import { http } from "../http-common";

const getAll = () => {
  return http.get("/farmers");
};

const getByIdFarmer = (id) => {
  return http.get(`/farmers/${id}`);
};

const create = (data) => {
  return http.post("/farmers", data);
};

const update = (id, data) => {
  return http.put(`/farmers/${id}`, data);
};

const remove = (id) => {
  return http.delete(`/farmers/${id}`);
};

const removeAll = () => {
  return http.delete(`/farmers`);
};

const searchFarmer = ({ searchParams, page, size }) => {
  const params = {
    ...searchParams,
    pageNumber: page,
    pageSize: size,
  };
  console.log("Request URL:", http.getUri({ url: "/farmer/search?", params }));
  return http.get("/farmer/search?", { params });
};
const findByTitle = (title) => {
  return http.get(`/farmers?title=${title}`);
};

const FarmerDataService = {
  getAll,
  getByIdFarmer,
  update,
  remove,
  removeAll,
  findByTitle,
  searchFarmer,
};

export default FarmerDataService;
