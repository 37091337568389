import { httpGrow } from "../http-common";

const getOccurrenceTypeList = (query) => {
  return httpGrow.get(
    `/fieldOccurrenceType?pageNumber=${query.pageNumber}&pageSize=${query.size}`
  );
};

const createOccurrence = (data) => {
  return httpGrow.post(`/fieldOccurrenceType`, data);
};

const updateOccurrence = (data) => {
  return httpGrow.put(`/fieldOccurrenceType`, data);
};

const deleteOccurrece = (data) => {
  return httpGrow.delete(`/fieldOccurrenceType/${data}`);
};

const searchOccurrenceType = (queryParams) => {
  const params = {
    pageNumber: queryParams.pageNumber,
    pageSize: queryParams.size,
    keyword: queryParams.keyword,
    occurrenceCategory: queryParams.occurrenceCategory,
    status:
      queryParams.status == "Active"
        ? true
        : queryParams.status == "Inactive"
          ? false
          : queryParams.status,
  };
  console.log("params", params);

  return httpGrow.get("/fieldOccurrenceType/search", { params });
};

const getBase64Image = (url) => {
  return httpGrow.get(`/storage?fileUrl=${url}`);
};

const OccurrenceTypeService = {
  getOccurrenceTypeList,
  createOccurrence,
  searchOccurrenceType,
  updateOccurrence,
  deleteOccurrece,
  getBase64Image,
};

export default OccurrenceTypeService;
