import { httpGrow } from "../http-common";

const getCropPhase = async (query) => {
  const response = await httpGrow.get(
    `/cropPhase?pageNumber=${query?.pageNumber}&pageSize=${query?.size}`
  );
  return response;
};

const createCropPhase = (data) => {
  return httpGrow.post(`/cropPhase`, data);
};
const updateCropPhase = (data) => {
  return httpGrow.put(`/cropPhase`, data);
};
const searchCropPhase = async (query) => {
  console.log("query", query);
  const response = await httpGrow.get(
    `/cropPhase/search?pageNumber=${query?.pageNumber}&pageSize=${query?.size}&keyword=${query?.keyword}`
  );
  return response;
};

const getFunctionality = async () => {
  const response = await httpGrow.post(`/picklist`, {
    pickListCodes: ["GRCPFUN"],
  });
  return response;
};

export const CropPhaseService = {
  getCropPhase,
  createCropPhase,
  updateCropPhase,
  searchCropPhase,
  getFunctionality,
};
