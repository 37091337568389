// SoilAnalysisTable.jsx
import React, { useEffect, useState, useContext } from "react";
import { Typography, Box, Button, Modal } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import "./SoilAnalysisTable.css";
import _ButtonEdit_ from "assets/_ButtonEdit_.svg";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import { useNavigate } from "react-router-dom";
import { SnackbarContext } from "context/snackBarContext";
import SoilAnalysisService from "services/SoilAnalysisService";

const SoilAnalysisTable = (props) => {
  const navigate = useNavigate();
  const [soilAnalysisData, setSoilAnalysisData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false); // Modal state
  const [deleteId, setDeleteId] = useState(null); // ID of the record to delete

  useEffect(() => {
    console.log("Fetched data:", props);
    setSoilAnalysisData(props.soilData);
    setOriginalData(props.soilData);
  }, [props.soilData]);

  const handleDeleteClick = (analysisId) => {
    setDeleteId(analysisId); // Store the ID of the record to delete
    setOpen(true); // Open the confirmation modal
  };

  const handleDeleteConfirm = async () => {
    try {
      setLoading(true);
      const response = await SoilAnalysisService.deleteSoilAnalysis(deleteId); // Delete by stored ID
      if (response.status === 200) {
        setMessage("Soil analysis deleted successfully");
        setSeverity("success");
        setOpenAlert(true);
        setSoilAnalysisData((prevData) =>
          prevData.filter((item) => item.saAnalysisID !== deleteId)
        );
      } else {
        setMessage("Failed to delete soil analysis");
        setSeverity("error");
        setOpenAlert(true);
      }
    } catch (error) {
      setMessage("Error deleting soil analysis");
      setSeverity("error");
      setOpenAlert(true);
    } finally {
      setLoading(false);
      setOpen(false); // Close the modal after deletion
    }
  };

  const handleClose = () => {
    setOpen(false); // Close the modal without deleting
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...soilAnalysisData].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setSoilAnalysisData(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        return <ArrowUpwardIcon fontSize="small" />;
      } else if (sortConfig.direction === "desc") {
        return <ArrowDownwardIcon fontSize="small" />;
      }
    }
    return <ArrowDownwardIcon fontSize="small" />;
  };

  const handleEdit = (row) => {
    navigate("/addSoilAnalysis", { 
      state: { 
        edit: true, 
        soilAnalysisData: {
          ...row,
          farmerId: row.farmerId,
          farmerFullName: row.farmerFullName,
          farmerCode: row.farmerCode,
        } 
      } 
    });
  };

  return (
    <div className="table-container">
      <table className="custom-table">
        <thead>
          <tr>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => handleSort("farmerFullName")}
              >
                Farmer
                {getSortIcon("farmerFullName")}
              </div>
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  justifyContent: "left",
                }}
                onClick={() => handleSort("saSoilAnalysisDescription")}
              >
                Soil Analysis Description 
                {getSortIcon("saSoilAnalysisDescription")}
              </div>
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  justifyContent: "left",
                }}
                onClick={() => handleSort("saCollectionDate")}
              >
                Collection Date
                {getSortIcon("saCollectionDate")}
              </div>
            </th>
            <th
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  justifyContent: "left",
                }}
                onClick={() => handleSort("saResultDate")}
              >
                Result Date
                {getSortIcon("saResultDate")}
              </div>
            </th>
            <th className="actions-header">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  justifyContent: "left",
                }}
                
              >
                Actions
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {soilAnalysisData?.map((row, index) => (
            <tr key={index}>
              <td>{row.farmerCode} - {row.farmerFullName}</td>
              <td>{row.saSoilAnalysisDescription}</td>
              <td>{row.saCollectionDate}</td>
              <td>{row.saResultDate}</td>
              <td className="actions-cell">
                <img
                  className="btn edit-btn"
                  src={_ButtonEdit_}
                  alt="Edit Button"
                  onClick={() => handleEdit(row)}
                />

                <img
                  className="btn delete-btn"
                  onClick={() => handleDeleteClick(row.saAnalysisID)}
                  src={_ButtonDelete_}
                  alt="Delete"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal for delete confirmation */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "520px",
            margin: "auto",
            mt: 25,
            height: "200px",
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
            >
              Delete Confirmation
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, fontWeight: 400 }}
            >
              Are you sure you want to delete this record? Deleted records
              cannot be recovered.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              mb: 1,
            }}
          >
            <Button
              onClick={handleClose}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              No
            </Button>
            <Button
              onClick={handleDeleteConfirm}
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#004F9F" },
              }}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default SoilAnalysisTable;
