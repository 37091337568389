import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Checkbox, FormControlLabel } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import { addPropertyField } from "./FormConfig";
import { useState } from "react";
import RenderField from "utils/RenderField";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  propertyLeasedRuralSchema,
  propertyLeasedUrbanSchema,
  propertyOwnedRuralFullSchema,
  propertyOwnedRuralSchema,
  propertyOwnedUrbanFullSchema,
  propertyOwnedUrbanSchema,
  propertySchema,
  propertyLeasesSchema,
} from "utils/validations";
import { clearProperty, updateProperty } from "slices/propertyAddSlice";
import { TreeView } from "@mui/x-tree-view";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import arrowDown from "assets/arrowDown.svg";
import { StyledTreeItem } from "utils/Styledtreeitem";
import { v4 as uuidv4 } from "uuid";
import { useEffect } from "react";
import PickListService from "services/PickListService";
import DataTable from "../DataTable/DataTable";
import FarmerRegisterationService from "services/FarmerRegistrationService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "90%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "#f8f8f8",
  border: "none",
  overflowY: "scroll",
};
const styleLeases = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "#f8f8f8",
  border: "none",
  overflowY: "scroll",
};

export default function PropertyRegisterationModal(props) {
  const dispatch = useDispatch();
  const propertyInformation = useSelector(
    (state) => state?.property?.propertyInformation
  );
  const [tenureType, setTenureType] = useState(
    props.editData?.tenureType || ""
  );
  const [propertyType, setpropertytype] = useState(
    props.editData?.propertyType || ""
  );
  const [values, setValues] = useState({});
  console.log("🚀 ~ PropertyRegisterationModal ~ values:", values);
  const [urbanPropertyLeases, setUrbanPropertyLeases] = useState(false);
  const [ruralPropertyLeases, setRuralPropertyLeases] = useState(false);
  const [propertyLeaseDetails, setPropertyLeases] = useState([]);
  const [newPropertyLeases, setNewPropertyLeases] = useState({});

  console.log("ssd", props.editData);
  console.log("newPropertyLeases", propertyLeaseDetails);
  console.log("propertyType", tenureType, propertyType);

  console.log("🚀 ~ PropertyRegisterationModal ~ values:", values);
  const [errors, setErrors] = useState({});
  const [leaseErrors, setLeaseErrors] = useState({});

  const [dateError, setDateError] = useState(false);
  const [edit, setEdit] = useState(false);
  console.log("🚀 ~ PropertyRegisterationModal ~ edit:", edit);
  const [id, setId] = useState(""); // State for storing the unique identifier

  const [popertyField, setPropertyField] = useState(addPropertyField);
  console.log("🚀 ~ PropertyRegisterationModal ~ errors:", addPropertyField);
  const [farmerData, setFarmerData] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const fetchPicklistData = async () => {
      try {
        const response = await PickListService.picklist({
          pickListCodes: ["FRGINFRE1", "FRGINFC47"],
        });
        const picklistResponse = response.data.response;
        const groupedPicklistData = Object.keys(picklistResponse).map(
          (pickListCode) => ({
            pickListCode,
            items: picklistResponse[pickListCode].map((item) => ({
              pickListItemCode: item.pickListItemCode,
              pickListDescription: item.pickListDescription,
            })),
          })
        );
        const regions =
          groupedPicklistData
            .find((picklist) => picklist.pickListCode === "FRGINFRE1")
            ?.items.map((item) => ({
              value: item.pickListItemCode,
              label: item.pickListDescription,
            })) || [];
        const cities =
          groupedPicklistData
            .find((picklist) => picklist.pickListCode === "FRGINFC47")
            ?.items.map((item) => ({
              value: item.pickListItemCode,
              label: item.pickListDescription,
            })) || [];
        setCities(cities);
        setPropertyField((prevFields) =>
          prevFields.map((field) => {
            if (field.name === "region" && !edit) {
              return {
                ...field,
                options: [...regions],
              };
            }
            if (field.name === "city" && !edit) {
              return {
                ...field,
                options: [...cities],
              };
            }
            if (field.name === "registryOfficeCity" && !edit) {
              return {
                ...field,
                options: [...cities],
              };
            }
            return field;
          })
        );
      } catch (error) {
        console.error("Error fetching relationshipType data:", error);
      }
    };
    const fetchFarmerData = async () => {
      try {
        const response = await FarmerRegisterationService.getAllFarmers();
        const fetchedFarmers = response.data?.response || [];
        setFarmerData(fetchedFarmers);
        const farmers =
          fetchedFarmers?.map((item) => ({
            value: item.farmerId,
            label: item.farmerFullName,
          })) || [];
        setPropertyField((prevFields) =>
          prevFields.map((field) => {
            if (field.name === "farmer") {
              return {
                ...field,
                options: [...farmers],
              };
            }
            return field;
          })
        );
      } catch (error) {
        console.error("Error fetching farmer data:", error);
      }
    };
    fetchPicklistData();
    fetchFarmerData();
  }, []);

  useEffect(() => {
    const properties =
      farmerData
        .find((farmer) => farmer.farmerId === values.farmer)
        ?.farmerPropertyDetails?.map((item) => ({
          value: item.propertyId,
          label:
            (item.locality ?? "") +
            ", " +
            (cities?.find((city) => city.value === item.city)?.label ??
              item.city ??
              "") +
            ", " +
            item.agriculturalLandTax +
            " hectares",
        })) || [];
    setPropertyField((prevFields) =>
      prevFields.map((field) => {
        if (field.name === "property") {
          if (values.farmer && values.farmer !== "") {
            return {
              ...field,
              options: [...properties],
              disabled: false,
              required: true,
            };
          } else {
            return {
              ...field,
              options: [],
              disabled: true,
              required: false,
            };
          }
        }
        return field;
      })
    );
  }, [values.farmer]);

  const handleCloseModal = () => {
    setTenureType("");
    setpropertytype("");
    setUrbanPropertyLeases(false);
    setRuralPropertyLeases(false);
    setEdit(false);
    setValues({});
    setPropertyLeases([]);
    setErrors({});
    props.onClose();
  };
  const handleCloseLeasesModal = () => {
    setUrbanPropertyLeases(false);
    setLeaseErrors({});
    setNewPropertyLeases({});
  };

  const handleChangePropertyType = (event) => {
    setpropertytype(event.target.value);
  };
  console.log(
    "🚀 ~ GeneralInformation ~ propertyInformation:",
    propertyInformation
  );

  useEffect(() => {
    if (props.editData) {
      setValues(props.editData);
      setId(
        props.editData.propertyId
          ? props.editData?.propertyId
          : props.editData.id
      );
      setPropertyLeases(
        props.editData.propertyLeaseDetails
          ? props.editData?.propertyLeaseDetails
          : []
      );
      setpropertytype(props.editData.propertyType);
      setTenureType(props.editData.tenureType);
      setEdit(true);
    } else {
      setId(uuidv4());
      setValues({});
      setPropertyLeases([]);
      setpropertytype("");
      setTenureType("");
      setEdit(false);
    }
  }, [props]);
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "farmer" && values.farmer !== value) {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
        property: "",
      }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (date, fieldName) => {
    if (!date || date === "Invalid Date") {
      setDateError(true);
      setValues((prevValues) => ({
        ...prevValues,
        [fieldName]: "",
      }));
      return;
    }

    setDateError(false);

    const dateValue = new Date(date);
    if (isNaN(dateValue.getTime())) {
      setDateError(true);
      setValues((prevValues) => ({
        ...prevValues,
        [fieldName]: "",
      }));
      return;
    }

    const year = dateValue.getFullYear();
    const month = String(dateValue.getMonth() + 1).padStart(2, "0");
    const day = String(dateValue.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setValues((prevValues) => ({
      ...prevValues,
      [fieldName]: formattedDate,
    }));
  };

  // useEffect(() => {
  //   setId(uuidv4());
  // }, [props.open]);

  // Rest of your component...

  const handleOwnedUrbanFullSubmit = async (event) => {
    // event.preventDefault();
    try {
      console.log("insildeeeee handleOwnedUrbanFullSubmit");

      await propertyOwnedUrbanFullSchema.validate(values, {
        abortEarly: false,
      });
      setErrors({});
      const {
        propertyId,
        region,
        agriculturalLandTax,
        book,
        city,
        estimatedValue,
        haExploited,
        haProperty,
        locality,
        page,
        regDate,
        registrationNumber,
        registryOfficeCity,
        registryOfficeName,
        value,
      } = values;

      dispatch(
        updateProperty([
          {
            propertyId,
            id,
            propertyType,
            tenureType,
            region,
            agriculturalLandTax,
            book,
            city,
            estimatedValue,
            haExploited,
            haProperty,
            locality,
            page,
            regDate,
            registrationNumber,
            registryOfficeCity,
            registryOfficeName,
            value,
            propertyLeaseDetails,
          },
        ])
      );
      setValues({});
      setPropertyLeases([]);
      props.onClose();
      console.log("dispatch Successfully");
    } catch (err) {
      const validationErrors = {};
      err?.inner?.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
    }
  };

  const handleOwnedUrbanSubmit = async (event) => {
    // event.preventDefault();
    try {
      console.log("insildeeeee handleOwnedUrbanSubmit");

      await propertyOwnedUrbanSchema.validate(values, {
        abortEarly: false,
      });
      setErrors({});
      const {
        region,
        agriculturalLandTax,
        book,
        city,
        estimatedValue,
        haExploited,
        haProperty,
        locality,
        page,
        regDate,
        registrationNumber,
        registryOfficeCity,
        registryOfficeName,
        value,
        farmerPropertyLinkId,
      } = values;
      dispatch(
        updateProperty([
          {
            id,
            farmerPropertyLinkId,
            propertyType,
            tenureType,
            region,
            agriculturalLandTax,
            book,
            city,
            estimatedValue,
            haExploited,
            haProperty,
            locality,
            page,
            regDate,
            registrationNumber,
            registryOfficeCity,
            registryOfficeName,
            value,
          },
        ])
      );
      setValues({});
      props.onClose();
      console.log("dispatch Successfully");
    } catch (err) {
      const validationErrors = {};
      err?.inner?.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
    }
  };

  const handleOwnedRuralSubmit = async (event) => {
    // event.preventDefault();
    try {
      console.log("insildeeeee handleOwnedRuralSubmit");

      await propertyOwnedRuralSchema.validate(values, {
        abortEarly: false,
      });
      setErrors({});
      const {
        ruralEnvironmentalType,
        region,
        agriculturalLandTax,
        book,
        city,
        estimatedValue,
        haExploited,
        haProperty,
        locality,
        page,
        regDate,
        registrationNumber,
        registryOfficeCity,
        registryOfficeName,
        value,
        farmerPropertyLinkId,
      } = values;
      dispatch(
        updateProperty([
          {
            farmerPropertyLinkId,
            ruralEnvironmentalType,
            id,
            propertyType,
            tenureType,
            region,
            agriculturalLandTax,
            book,
            city,
            estimatedValue,
            haExploited,
            haProperty,
            locality,
            page,
            regDate,
            registrationNumber,
            registryOfficeCity,
            registryOfficeName,
            value,
          },
        ])
      );
      setValues({});
      props.onClose();
      console.log("dispatch Successfully");
    } catch (err) {
      const validationErrors = {};
      err?.inner?.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
    }
  };
  const handleOwnedRuralFullSubmit = async () => {
    // event.preventDefault();
    try {
      console.log("insildeeeee handleOwnedRuralFullSubmit");

      await propertyOwnedRuralFullSchema.validate(values, {
        abortEarly: false,
      });
      setErrors({});

      const {
        propertyId,
        farmerPropertyLinkId,
        ruralEnvironmentalType,
        region,
        agriculturalLandTax,
        book,
        city,
        estimatedValue,
        haExploited,
        haProperty,
        locality,
        page,
        regDate,
        registrationNumber,
        registryOfficeCity,
        registryOfficeName,
        value,
      } = values;

      // const pl = propertyLeases.map((pr) => ({
      //   cpfNumber: pr.cpfNumber,
      //   hectares: pr.hectares,
      //   name: pr.name,
      //   personType: pr.personType,
      // }));
      console.log("PropertyLeased", propertyLeaseDetails);
      dispatch(
        updateProperty([
          {
            propertyId,
            farmerPropertyLinkId,
            ruralEnvironmentalType,
            id,
            propertyType,
            tenureType,
            region,
            agriculturalLandTax,
            book,
            city,
            estimatedValue,
            haExploited,
            haProperty,
            locality,
            page,
            regDate,
            registrationNumber,
            registryOfficeCity,
            registryOfficeName,
            value,
            propertyLeaseDetails,
          },
        ])
      );
      setValues({});
      setPropertyLeases([]);
      props.onClose();
      console.log("dispatch Successfully");
    } catch (err) {
      const validationErrors = {};
      err?.inner?.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
    }
  };

  const handleLeasedUrbanSubmit = async (event) => {
    // event.preventDefault();
    try {
      await propertyLeasedUrbanSchema.validate(values, {
        abortEarly: false,
      });
      setErrors({});
      const { farmer, property, ha, farmerPropertyLinkId } = values;
      dispatch(
        updateProperty([
          {
            id,
            farmerPropertyLinkId,
            propertyType,
            tenureType,
            farmer,
            property,
            ha,
          },
        ])
      );
      setValues({});
      props.onClose();
      console.log("dispatch Successfully");
    } catch (err) {
      const validationErrors = {};
      err?.inner?.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
    }
  };

  const handleLeasedRuralSubmit = async (event) => {
    // event.preventDefault();
    try {
      await propertyLeasedRuralSchema.validate(values, {
        abortEarly: false,
      });
      setErrors({});
      const {
        ruralEnvironmentalType,
        farmer,
        property,
        ha,
        farmerPropertyLinkId,
      } = values;
      dispatch(
        updateProperty([
          {
            farmerPropertyLinkId,
            id,
            propertyType,
            tenureType,
            ruralEnvironmentalType,
            farmer,
            property,
            ha,
          },
        ])
      );
      setValues({});
      props.onClose();
      console.log("dispatch Successfully");
    } catch (err) {
      const validationErrors = {};
      err?.inner?.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
    }
  };

  const handlePropertyLeases = (event) => {
    const { name, value } = event.target;
    setNewPropertyLeases((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const handleSavePropertyLease = async () => {
    try {
      await propertyLeasesSchema.validate(newPropertyLeases, {
        abortEarly: false,
      });
      setPropertyLeases((prev) => [...prev, newPropertyLeases]);
      setLeaseErrors({});
      setNewPropertyLeases({});
      setUrbanPropertyLeases(false);
    } catch (err) {
      const validationErrors = {};
      err?.inner?.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setLeaseErrors(validationErrors);
      console.log("insildeeeee handleOwnedUrbanFullSubmit", leaseErrors);
    }
  };
  const propertyLeasesColumns = [
    {
      field: "cpfNumber",
      headerName: "CPF/CNPJ",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "hectares",
      headerName: "Hectares",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
  ];
  const handleUrbanPropertyLeases = () => {
    setUrbanPropertyLeases(true);
  };

  const handleRuralPropertyLeases = () => {
    setRuralPropertyLeases(true);
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   try {
  //     await propertySchema.validate(values, { abortEarly: false });
  //     setErrors({});

  //     dispatch(updateProperty([values]));
  //     props.onClose()
  //     console.log("dispatch Successfully");
  //   } catch (err) {
  //     const validationErrors = {};
  //     err?.inner?.forEach((error) => {
  //       validationErrors[error.path] = error.message;
  //     });
  //     setErrors(validationErrors);
  //   }
  // };

  const handleClear = (field) => {
    if (field === "farmer") {
      setValues((prevValues) => ({
        ...prevValues,
        [field]: "",
        property: "",
      }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [field]: "",
      }));
    }
  };

  console.log("🚀 ~ PropertyRegisterationModal ~ propertyType:", tenureType);

  const handleChangeProperty = (event) => {
    setTenureType(event.target.name);
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => props.onClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            className="property-modal-heading"
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Property Registration
          </Typography>
          <Box
            sx={{
              minHeight: 180,
              marginLeft: "0px",
              width: "100%",
              "& .MuiTextField-root": { m: 1, color: "red", width: "25ch" },
              "& .MuiFormLabel-root": {
                color: "rgba(16, 42, 98, 1)",
                "& .MuiFormLabel-asterisk": {
                  color: "rgba(239, 0, 0, 1)",
                  fontSize: "18px",
                },
              },
            }}
          >
            <form
              style={{ padding: "38px" }}
              autoComplete="off"
              noValidate
              // onSubmit={(e) => {
              //   handleSubmit(e);
              // }}
            >
              <Box
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "24px",
                  letterSpacing: " 0.5px",
                  paddingLeft: "10px",
                  paddingBottom: "38px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <FormControl>
                  <Typography>Tenure Type</Typography>
                  <Box display="flex">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={tenureType === "Owned"}
                          onChange={handleChangeProperty}
                          name="Owned"
                        />
                      }
                      label="Owned"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={tenureType === "Leased"}
                          onChange={handleChangeProperty}
                          name="Leased"
                        />
                      }
                      label="Leased"
                    />
                  </Box>
                </FormControl>
              </Box>
              {tenureType === "Leased" && (
                <>
                  {/* {popertyField.slice(0, 1).map((field) => (
                    <RenderField
                      key={field.name}
                      field={field}
                      values={values}
                      errors={errors}
                      handleChange={handleChange}
                      handleDateChange={handleDateChange}
                      handleClear={handleClear}
                    />
                  ))} */}
                  <TreeView
                    aria-label="file system navigator"
                    defaultCollapseIcon={
                      <img src={arrowDown} alt="Arrow Down" />
                    }
                    defaultExpandIcon={<ChevronRightIcon />}
                    defaultExpanded={["1", "4", "5"]}
                    sx={{ mt: 2 }}
                  >
                    <StyledTreeItem nodeId="1" labelText="Leased Properties">
                      <div className="farmer-add-tree-main">
                        {popertyField.slice(1, 4).map((field) => (
                          <RenderField
                            key={field.name}
                            field={field}
                            values={values}
                            errors={errors}
                            handleChange={handleChange}
                            handleDateChange={handleDateChange}
                            handleClear={handleClear}
                          />
                        ))}
                      </div>
                    </StyledTreeItem>
                  </TreeView>
                </>
              )}

              {/* {tenureType === "Leased" && (
                <TreeView
                  aria-label="file system navigator"
                  defaultCollapseIcon={<img src={arrowDown} alt="Arrow Down" />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  defaultExpanded={["1", "4", "5"]}
                  sx={{ mt: 2 }}
                >
                  <StyledTreeItem nodeId="1" labelText="Tax Information">
                    <div className="farmer-add-tree-main">
                      {popertyField.slice(1, 4).map((field) => (
                        <RenderField
                          key={field.name}
                          field={field}
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          handleDateChange={handleDateChange}
                          handleClear={handleClear}
                        />
                      ))}
                    </div>
                  </StyledTreeItem>
                </TreeView>
              )} */}

              {/* {tenureType === "Owned" && propertyType === "Rural" && (
                <>
                  <TreeView
                    aria-label="file system navigator"
                    defaultCollapseIcon={
                      <img src={arrowDown} alt="Arrow Down" />
                    }
                    defaultExpandIcon={<ChevronRightIcon />}
                    defaultExpanded={["1", "4", "5"]}
                    sx={{ mt: 2 }}
                  >
                    <StyledTreeItem nodeId="1" labelText="Property Data">
                      {popertyField.slice(0, 1).map((field) => (
                        <RenderField
                          key={field.name}
                          field={field}
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          handleDateChange={handleDateChange}
                          handleClear={handleClear}
                        />
                      ))}
                      <div>
                        {popertyField.slice(4, 12).map((field) => (
                          <RenderField
                            key={field.name}
                            field={field}
                            values={values}
                            errors={errors}
                            handleChange={handleChange}
                            handleDateChange={handleDateChange}
                            handleClear={handleClear}
                          />
                        ))}
                      </div>
                    </StyledTreeItem>
                  </TreeView>
                  <TreeView
                    aria-label="file system navigator"
                    defaultCollapseIcon={
                      <img src={arrowDown} alt="Arrow Down" />
                    }
                    defaultExpandIcon={<ChevronRightIcon />}
                    defaultExpanded={["1", "4", "5"]}
                    sx={{ mt: 3 }}
                  >
                    <StyledTreeItem nodeId="1" labelText="Registry Office Data">
                      <div className="farmer-add-tree-main">
                        {popertyField.slice(12, 18).map((field) => (
                          <RenderField
                            key={field.name}
                            field={field}
                            values={values}
                            errors={errors}
                            handleChange={handleChange}
                            handleDateChange={handleDateChange}
                            handleClear={handleClear}
                          />
                        ))}
                      </div>
                    </StyledTreeItem>
                  </TreeView>
                  <TreeView
                    aria-label="file system navigator"
                    defaultCollapseIcon={
                      <img src={arrowDown} alt="Arrow Down" />
                    }
                    defaultExpandIcon={<ChevronRightIcon />}
                    defaultExpanded={["1", "4", "5"]}
                    sx={{ mt: 3 }}
                  >
                    <StyledTreeItem nodeId="1" labelText="Property Leases">
                      {ruralPropertyLeases && (
                        <div className="farmer-add-tree-main">
                          {popertyField.slice(18, 22).map((field) => (
                            <RenderField
                              key={field.name}
                              field={field}
                              values={values}
                              errors={errors}
                              handleChange={handleChange}
                              handleDateChange={handleDateChange}
                              handleClear={handleClear}
                            />
                          ))}
                        </div>
                      )}
                      <div
                        onClick={handleRuralPropertyLeases}
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        + Add Property Leases
                      </div>
                    </StyledTreeItem>
                  </TreeView>
                </>
              )} */}

              {tenureType === "Owned" && (
                <>
                  <TreeView
                    aria-label="file system navigator"
                    defaultCollapseIcon={
                      <img src={arrowDown} alt="Arrow Down" />
                    }
                    defaultExpandIcon={<ChevronRightIcon />}
                    defaultExpanded={["1", "4", "5"]}
                    sx={{ mt: 0 }}
                  >
                    <StyledTreeItem nodeId="1" labelText="Property Data">
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          marginLeft: "1rem",
                        }}
                      >
                        <FormControl>
                          <Typography>Property Type</Typography>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={propertyType}
                            onChange={(e) => {
                              handleChangePropertyType(e);
                            }}
                          >
                            <FormControlLabel
                              value="Rural"
                              control={<Radio />}
                              label="Rural"
                            />
                            <FormControlLabel
                              value="Urban"
                              control={<Radio />}
                              label="Urban"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                      {propertyType === "Rural" &&
                        popertyField
                          .slice(0, 1)
                          .map((field) => (
                            <RenderField
                              key={field.name}
                              field={field}
                              values={values}
                              errors={errors}
                              handleChange={handleChange}
                              handleDateChange={handleDateChange}
                              handleClear={handleClear}
                            />
                          ))}
                      {popertyField.slice(4, 12).map((field) => (
                        <RenderField
                          key={field.name}
                          field={field}
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          handleDateChange={handleDateChange}
                          handleClear={handleClear}
                        />
                      ))}
                    </StyledTreeItem>
                  </TreeView>
                  <TreeView
                    aria-label="file system navigator"
                    defaultCollapseIcon={
                      <img src={arrowDown} alt="Arrow Down" />
                    }
                    defaultExpandIcon={<ChevronRightIcon />}
                    defaultExpanded={["1", "4", "5"]}
                    sx={{ mt: 3 }}
                  >
                    <StyledTreeItem nodeId="1" labelText="Register Office Data">
                      <div className="farmer-add-tree-main">
                        {popertyField.slice(12, 18).map((field) => (
                          <RenderField
                            key={field.name}
                            field={field}
                            values={values}
                            errors={errors}
                            handleChange={handleChange}
                            handleDateChange={handleDateChange}
                            handleClear={handleClear}
                          />
                        ))}
                      </div>
                    </StyledTreeItem>
                  </TreeView>
                  <TreeView
                    aria-label="file system navigator"
                    defaultCollapseIcon={
                      <img src={arrowDown} alt="Arrow Down" />
                    }
                    defaultExpandIcon={<ChevronRightIcon />}
                    defaultExpanded={["1", "4", "5"]}
                    sx={{ mt: 3 }}
                  >
                    <StyledTreeItem nodeId="1" labelText="Property Leases">
                      {propertyLeaseDetails &&
                        propertyLeaseDetails.length > 0 && (
                          // <div className="farmer-add-tree-main">
                          //   {popertyField.slice(18, 22).map((field) => (
                          //     <RenderField
                          //       key={field.name}
                          //       field={field}
                          //       values={values}
                          //       errors={errors}
                          //       handleChange={handleChange}
                          //       handleDateChange={handleDateChange}
                          //       handleClear={handleClear}
                          //     />
                          //   ))}
                          // </div>
                          <DataTable
                            getRowId={(row) => row.cpfNumber || row.id}
                            rows={propertyLeaseDetails}
                            columns={propertyLeasesColumns}
                          />
                        )}
                      <div
                        onClick={handleUrbanPropertyLeases}
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        + Add Property Leases
                      </div>
                    </StyledTreeItem>
                  </TreeView>
                </>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                  paddingBottom: "1rem",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#737D86",
                    padding: " 6px, 16px, 6px, 16px",
                    borderRadius: " 4px",
                    top: "25px",
                    left: "20px",
                  }}
                  variant="contained"
                  type="secondary"
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    backgroundColor: "#004F9F",
                    top: "25px",
                    left: "20px",
                  }}
                  variant="contained"
                  onClick={() => {
                    if (
                      tenureType === "Owned" &&
                      propertyType === "Urban" &&
                      propertyLeaseDetails.length > 0
                    ) {
                      handleOwnedUrbanFullSubmit();
                    } else if (
                      tenureType === "Owned" &&
                      propertyType === "Urban" &&
                      propertyLeaseDetails.length == 0
                    ) {
                      handleOwnedUrbanSubmit();
                    } else if (
                      tenureType === "Owned" &&
                      propertyType === "Rural" &&
                      propertyLeaseDetails.length == 0
                    ) {
                      handleOwnedRuralSubmit();
                    } else if (
                      tenureType === "Owned" &&
                      propertyType === "Rural" &&
                      propertyLeaseDetails.length > 0
                    ) {
                      handleOwnedRuralFullSubmit();
                    } else if (tenureType === "Leased") {
                      handleLeasedUrbanSubmit();
                    }
                    //  else if (
                    //   tenureType === "Leased" &&
                    //   propertyType === "Rural"
                    // ) {
                    //   handleLeasedRuralSubmit();
                    // }
                  }}
                >
                  {!edit ? "Save" : "Edit"} Property Registration
                </Button>
              </div>
            </form>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={urbanPropertyLeases}
        onClose={() => urbanPropertyLeases(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleLeases}>
          <Typography
            className="property-modal-heading"
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Property Leases
          </Typography>
          <Box
            sx={{
              minHeight: 150,
              marginLeft: "0px",
              width: "100%",
              "& .MuiTextField-root": { m: 1, color: "red", width: "25ch" },
              "& .MuiFormLabel-root": {
                color: "rgba(16, 42, 98, 1)",
                "& .MuiFormLabel-asterisk": {
                  color: "rgba(239, 0, 0, 1)",
                  fontSize: "18px",
                },
              },
            }}
          >
            <form
              style={{ padding: "8px" }}
              autoComplete="off"
              noValidate
              // onSubmit={(e) => {
              //   handleSubmit(e);
              // }}
            >
              <Box
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "24px",
                  letterSpacing: " 0.5px",
                  paddingLeft: "10px",
                  paddingBottom: "38px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <div className="farmer-add-tree-main">
                  {popertyField.slice(18, 22).map((field) => (
                    <RenderField
                      key={field.name}
                      field={field}
                      values={newPropertyLeases}
                      errors={leaseErrors}
                      handleChange={
                        (event) => handlePropertyLeases(event)
                        // handleChangePropertyLeases(event)
                      }
                      handleDateChange={handleDateChange}
                      handleClear={handleClear}
                    />
                  ))}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "20px",
                    paddingBottom: "1rem",
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: "#737D86",
                      padding: " 6px, 16px, 6px, 16px",
                      borderRadius: " 4px",
                      top: "25px",
                      left: "20px",
                    }}
                    variant="contained"
                    type="secondary"
                    onClick={handleCloseLeasesModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#004F9F",
                      top: "25px",
                      left: "20px",
                    }}
                    variant="contained"
                    onClick={handleSavePropertyLease}
                  >
                    Add
                  </Button>
                </div>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
