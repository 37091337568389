import { httpGrow } from "../http-common";

const getPayment = async (query) => {
    const response = await httpGrow.get(`/paymentPolicy?pageNumber=${query?.pageNumber}&pageSize=${query?.size}`)
    return response;
}

const createPayment = (data) => {
    return httpGrow.post(
        `/paymentPolicy`, data
    );
};

const updatePayment = (data) => {
    return httpGrow.put(`paymentPolicy`, data);
};

const searchPayment = async (query) => {
    const response = await httpGrow.get(
        `/paymentPolicy/search?pageNumber=${query?.pageNumber}&pageSize=${query?.size || 50}&keyword=${query?.keyword || ''}&status=${query?.status !== undefined ? query.status : ''}`
    );
    return response;
};

const deletePayment = (paymentTypeId) => {
    return httpGrow.delete(`/paymentPolicy/${paymentTypeId}`);
  };

const PaymentService = {
    getPayment,
    createPayment,
    updatePayment,
    searchPayment,
    deletePayment
}

export default PaymentService;