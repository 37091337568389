// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bottoMenu{
    position: sticky;
    top:64px;
    z-index: 999;
  
}
@media screen and (max-width:600px) {
    .bottoMenu{
        top:56px;  
    }
    .menuTitle{
        font-size: 1rem !important;
    }
    .navBarContainer{
            margin: unset !important;
    }
    .toolBar{
        /* padding-left: 5px !important;
        padding-right: 5px !important; */
        padding-left: -5px !important;
        padding-right: -5px !important;
    }
    
}
@media screen and (max-width:600px) {
    .navBarContainer{

    }
}
`, "",{"version":3,"sources":["webpack://./src/components/navbar/Navbar.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,QAAQ;IACR,YAAY;;AAEhB;AACA;IACI;QACI,QAAQ;IACZ;IACA;QACI,0BAA0B;IAC9B;IACA;YACQ,wBAAwB;IAChC;IACA;QACI;wCACgC;QAChC,6BAA6B;QAC7B,8BAA8B;IAClC;;AAEJ;AACA;IACI;;IAEA;AACJ","sourcesContent":[".bottoMenu{\n    position: sticky;\n    top:64px;\n    z-index: 999;\n  \n}\n@media screen and (max-width:600px) {\n    .bottoMenu{\n        top:56px;  \n    }\n    .menuTitle{\n        font-size: 1rem !important;\n    }\n    .navBarContainer{\n            margin: unset !important;\n    }\n    .toolBar{\n        /* padding-left: 5px !important;\n        padding-right: 5px !important; */\n        padding-left: -5px !important;\n        padding-right: -5px !important;\n    }\n    \n}\n@media screen and (max-width:600px) {\n    .navBarContainer{\n\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
