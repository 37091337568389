import React, { useState, useEffect, useContext } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FurnaceService from "services/FurnaceService";
import SoilParameterService from "services/SoilParameterService";
import Lable from "utils/lables.json";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SoilParameterTable from "./SoilParameterTable";
import { useTranslation } from "react-i18next";
import { ClearIcon } from "@mui/x-date-pickers";
import label from "../growingLabel.json";

const GetSoilParameter = () => {
  const navigate = useNavigate();
  const [soilParameterData, setSoilParameterData] = useState([]);
  const [filteredSoilParameterData, setFilteredSoilParameterData] = useState(
    []
  );
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [menu, setMenu] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const { t, i18n } = useTranslation();

  const handleSearchChange = (value) => {
    setSearchKeyword(value);
  };

  const handleStatusChange = (status) => {
    setStatusFilter(status); // Update the status filter
    setCurrentPage(0); // Reset to the first page when the status changes

    const isActive =
      status === "Active" ? true : status === "Inactive" ? false : null;

    callSearchSoilParameterAPI(0, itemsPerPage, searchKeyword, isActive);
  };

  const callSoilParameterAPI = (pageNumber, size) => {
    setLoading(true);
    SoilParameterService.getSoilParameter({ pageNumber, size })
      .then((res) => {
        const soilParameterResults =
          res?.data?.response?.soilAnalysisParameters || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setSoilParameterData(soilParameterResults);
        setFilteredSoilParameterData(soilParameterResults);
        setTotalItems(res?.data?.response?.totalItems || 0);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setSoilParameterData([]);
        setFilteredSoilParameterData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getfullSoilParameterAPI = () => {
    setLoading(true);
    SoilParameterService.getSoilParameter({ pageNumber: currentPage, size: 50 })
      .then((res) => {
        const soilParameterResults =
          res?.data?.response?.soilAnalysisParameters || [];
        const menues = soilParameterResults.map(
          (e) => e.sapParameterName || []
        );
        setMenu(menues);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setSoilParameterData([]);
        setFilteredSoilParameterData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const callSearchSoilParameterAPI = (pageNumber, size, Keyword, status) => {
    setLoading(true);
    setSearchKeyword(Keyword);

    let query = {
      pageNumber,
      size,
      sapParameterName: Keyword,
    };

    const params = [];

    if (query?.pageNumber !== undefined) {
      params.push(`pageNumber=${query.pageNumber}`);
    }
    if (query?.size !== undefined) {
      params.push(`pageSize=${query.size}`);
    }
    if (query?.sapParameterName) {
      params.push(`sapParameterName=${query.sapParameterName}`);
    }
    if (status !== null && status !== undefined) {
      params.push(`sapStatus=${status}`);
    }
    const queryString = params.length ? `?${params.join("&")}` : "";

    SoilParameterService.searchSoilParameter(queryString)
      .then((res) => {
        const Results = res?.data?.response?.soilAnalysisParameters || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setSoilParameterData(Results);
        setFilteredSoilParameterData(Results);
        setTotalItems(res?.data?.response?.totalItems || 0);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setSoilParameterData([]);
        setFilteredSoilParameterData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    callSoilParameterAPI(currentPage, itemsPerPage);
    if (currentPage <= 0) {
      getfullSoilParameterAPI();
    }
  }, [currentPage, itemsPerPage]);

  const paginate = (pageNumber, itemsPerPage) => {
    setCurrentPage(pageNumber - 1);
    if (searchKeyword) {
      callSearchSoilParameterAPI(pageNumber - 1, itemsPerPage, searchKeyword);
    } else {
      callSoilParameterAPI(pageNumber - 1, itemsPerPage);
    }
  };

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={t("soilAnalysisParameterList.pageTitle")}
      background="#ffffff"
      useNewPagination={true}
    >
      <Box
        sx={{
          background: "#EFEFEF",
          borderRadius: 1,
          padding: 2,
          height: "auto",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ArrowDropDownIcon sx={{ marginRight: 1 }} />
          <Typography>{Lable.filtersLable}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            //justifyContent: "space-between",
            gap: 2,
          }}
        >
          <FormControl sx={{ flex: 1, maxWidth: "37.5rem", width: "100%" }}>
            <Autocomplete
              disablePortal
              onChange={(event, newValue) => {
                if (newValue) {
                  setSearchKeyword(newValue);
                  callSearchSoilParameterAPI(0, itemsPerPage, newValue);
                } else {
                  setSearchKeyword("");
                  callSearchSoilParameterAPI(0, itemsPerPage, "");
                }
              }}
              onInputChange={(event, newInputValue) => {
                if (newInputValue) handleSearchChange(newInputValue);
                else getfullSoilParameterAPI();
              }}
              options={menu}
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "0.438rem",
                  minHeight: "3.125rem",
                },
              }}
              InputProps={{
                style: { height: "3.125rem" },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("soilAnalysisParameterList.parameterName")}
                  fullWidth
                />
              )}
            />
          </FormControl>
          
          {/* <FormControl sx={{ flex: 1, maxWidth: "37.5rem", width: "100%" }}>
            <TextField
              value={searchKeyword}
              onChange={(event) => {
                const newValue = event.target.value;
                setSearchKeyword(newValue);
                if (newValue) {
                  callSearchSoilParameterAPI(0, itemsPerPage, newValue); // Trigger search on value change
                } else {
                  callSearchSoilParameterAPI(0, itemsPerPage, ""); // Trigger search with empty string if cleared
                }
              }}
              onInputChange={(event, newInputValue) => {
                if (newInputValue) {
                  handleSearchChange(newInputValue); // Trigger your custom search handling
                } else {
                  getfullSoilParameterAPI(); // Reset search if input is cleared
                }
              }}
              label={t("soilAnalysisParameterList.parameterName")}
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "0.438rem",
                  minHeight: "3.125rem",
                },
              }}
              InputProps={{
                style: { height: "3.125rem" },
              }}
            />
          </FormControl> */}
          
          <FormControl sx={{ flex: 1, maxWidth: "18.125rem", width: "100%" }}>
            <TextField
              select
              name="status"
              label={t("Status")}
              value={statusFilter || ""}
              onChange={(event) => handleStatusChange(event.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "0.438rem",
                  minHeight: "3.125rem",
                },
                "& .MuiInputLabel-root": {
                  lineHeight: "1.2em",
                },
              }}
              InputProps={{
                style: { height: "3.125rem" },
                endAdornment: statusFilter && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleStatusChange("")} // Clears the filter
                      size="small"
                      //edge="end"
                      sx={{ color: "gray" }} // Optional: color for the icon
                      style={{ marginRight: "15px" }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value="pl">Select an option</MenuItem>
              {/* <MenuItem value="">
                  <em>None</em>
                </MenuItem> */}
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Inactive">Inactive</MenuItem>
            </TextField>
          </FormControl>

          <Button
            variant="contained"
            startIcon={<AddCircleIcon />}
            sx={{
              minWidth: 95,
              borderRadius: 1,
              background: "#004F9F",
              height: { xs: "40px", md: "44px" },
              marginLeft: { xs: 0, md: "auto" },
            }}
            onClick={() => navigate("/addsoilParameter")}
          >
            {t("addNew")}
          </Button>
        </Box>
      </Box>
      {/* <SoilParameterTable soilParameterData={filteredSoilParameterData} /> */}
      {!loading && soilParameterData && soilParameterData.length === 0 && (
        <Typography variant="h6" align="center" color="textSecondary">
          {label.noDataMessage}
        </Typography>
        // <Box
        //   sx={{
        //     display: "flex",
        //     justifyContent: "center",
        //     height: "40vh",
        //     // alignItems: "center",
        //   }}
        // >
        //   <Box>{label.noDataMessage}</Box>
        // </Box>
      )}
      {!loading && soilParameterData && soilParameterData.length > 0 && (
        <SoilParameterTable soilParameterData={filteredSoilParameterData} />
      )}
    </Layout>
  );
};

export default GetSoilParameter;
