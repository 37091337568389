import React, { useEffect } from "react";
import Gantt from "frappe-gantt";

// export default GanttChart;

const GanttChart = ({ tasks }) => {
  console.log("ganttTasks", tasks);
  useEffect(() => {
    const ganttContainer = document.querySelector("#gantt");

    if (tasks && tasks.length > 0) {
      // Ensure all task dates are valid
      const validTasks = tasks?.filter(
        (task) =>
          task.start &&
          task.end &&
          !isNaN(new Date(task?.start)) &&
          !isNaN(new Date(task?.end))
      );

      if (validTasks.length > 0) {
        // Clear previous Gantt chart
        if (ganttContainer) {
          ganttContainer.innerHTML = "";
        }

        // Create new Gantt chart
        new Gantt("#gantt", validTasks, {
          view_mode: "Month",
        });
      }
    } else {
      // If tasks are empty, clear the Gantt chart
      if (ganttContainer) {
        ganttContainer.innerHTML = "";
      }
    }
  }, [tasks]);

  return <div id="gantt" />;
};

export default GanttChart;
