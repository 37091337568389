import * as React from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import arrowLeft from "assets/arrowLeft.svg";
import arrowRight from "assets/arrowRight.svg";
import * as Yup from "yup";
import { useState } from "react";
import { curingSchema } from "utils/validations";
import RenderField from "utils/RenderField";
import { useLocation, useNavigate } from "react-router-dom";
import { curingField } from "./CuringFormConfig";
import Layout from "components/structure/Layout";
import { useEffect } from "react";
import CuringService from "services/CuringService";
import { useContext } from "react";
import { SnackbarContext } from "context/snackBarContext";
import { AuthData } from "auth/AuthWarpper";

export default function CuringUnitForm(props) {
  const location = useLocation();

  const navigate = useNavigate();
  const { user } = AuthData();

  const {
    curingId,
    edit,
  } = location.state || {};


  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [values, setValues] = useState([location.state]);
  const [errors, setErrors] = useState({});
  const [dateError, setDateError] = useState(false);



  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  useEffect(() => {
    const fetchCuringCode = async () => {
      try {
        const response = await CuringService.getCuringUniqueCode();
        const curingCode = response.data.response;
        setValues((prevValues) => ({
          ...prevValues,
          curingCode: curingCode,
        }));
      } catch (error) {
        console.error("Failed to fetch curing code:", error);
      }
    };

    if (!values.curingCode) {
      fetchCuringCode();
    }

    if (location.state) {
      const {
        curingId,
        capacity,
        curingCode,
        curingUnitName,
        linearMeter,
        newCuringUnit,
        tobaccoGroup,
        status,
      } = location.state;
      setValues((prevValues) => ({
        ...prevValues,
        curingId: curingId || "",
        capacity: capacity || "",
        curingCode: curingCode || "",
        curingUnitName: curingUnitName || "",
        linearMeter: linearMeter || "",
        newCuringUnit: newCuringUnit || "",
        tobaccoGroup: tobaccoGroup || "",
        status: status || "",
      }));
    }
  }, [values?.curingCode, location.state]);

  const handleDateChange = (date, fieldName) => {
    if (!date || date === "Invalid Date") {
      setDateError(true);
      setValues((prevValues) => ({
        ...prevValues,
        [fieldName]: "",
      }));
      return;
    }

    setDateError(false);

    const dateValue = new Date(date);
    if (isNaN(dateValue.getTime())) {
      setDateError(true);
      setValues((prevValues) => ({
        ...prevValues,
        [fieldName]: "",
      }));
      return;
    }

    const year = dateValue.getFullYear();
    const month = String(dateValue.getMonth() + 1).padStart(2, "0");
    const day = String(dateValue.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setValues((prevValues) => ({
      ...prevValues,
      [fieldName]: formattedDate,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await curingSchema.validate(values, { abortEarly: false });
      setErrors({});
      const payload = {
        curingCode: values.curingCode,
        curingUnitName: values.curingUnitName,
        tobaccoGroup: values.tobaccoGroup,
        linearMeter: parseFloat(values.linearMeter),
        newCuringUnit: values.newCuringUnit,
        capacity: parseFloat(values.capacity),
        status: values.status || false,
        createdByUser: user?.batOneId || 323222,
        modifiedByUser: user?.batOneId || 323222,
        countryCode: user?.countryCode || "BRA",
      };
      const response = await CuringService.createCuring(payload);
      if (response.status === 201) {
        navigate("/curing");
        setSeverity("success");
        setMessage("Added successfully");
        setOpenAlert(true);
        // setLoading(false);
      } else {
        console.error("Failed to submit form");
        setSeverity("error");
        setMessage("Submission failed");
        setOpenAlert(true);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        let newError = {};
        err?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setErrors(newError);
      } else {
        console.error("An error occurred while submitting the form:", err);
        setSeverity("error");
        setMessage("An error occurred while submitting the form");
        setOpenAlert(true);
      }
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    try {
      await curingSchema.validate(values, { abortEarly: false });
      setErrors({});
      const payload = {
        curingId: curingId,
        curingCode: values.curingCode,
        curingUnitName: values.curingUnitName,
        tobaccoGroup: values.tobaccoGroup,
        linearMeter: parseFloat(values.linearMeter),
        newCuringUnit: values.newCuringUnit,
        capacity: parseFloat(values.capacity),
        status: values.status || false,
        countryCode: user?.countryCode || "BRA",
        createdByUser: user?.batOneId || 323222,
        modifiedByUser: user?.batOneId || 323222,
      };
      const response = await CuringService.updateCuring(payload);
      if (response.status === 200) {
        navigate("/curing");
        setSeverity("success");
        setMessage("Added successfully");
        setOpenAlert(true);
        // setLoading(false);
      } else {
        console.error("Failed to submit form");
        setSeverity("error");
        setMessage("Submission failed");
        setOpenAlert(true);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        let newError = {};
        err?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setErrors(newError);
      } else {
        console.error("An error occurred while submitting the form:", err);
        setSeverity("error");
        setMessage("Submission failed");
        setOpenAlert(true);
      }
    }
  };

  const handleClear = (field) => {
    setValues((prevValues) => ({
      ...prevValues,
      [field]: "",
    }));
  };

  return (
    <Layout title="Curing Unit Registration">
      <Box
        sx={{
          minHeight: 180,
          flexGrow: 1,
          width: "100%",
          "& .MuiTextField-root": {
            m: 1,
            color: "green !important",
            width: "25ch",
          },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
            "& .Mui-focused": {
              color: "red !important",
            },
          },
        }}
      >
        <form
          autoComplete="off"
          noValidate
          onSubmit={edit ? handleUpdate : handleSubmit}
        >
          {curingField.slice(0, 7).map((field) => (
            <RenderField
              key={field.name}
              field={field}
              values={values}
              errors={errors}
              handleChange={handleChange}
              handleDateChange={handleDateChange}
              handleClear={handleClear}
            />
          ))}

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "2rem",
              marginTop: "100px",
            }}
          >
            <Button
              style={{
                backgroundColor: "#737D86",
                padding: " 6px, 16px, 6px, 16px",
                borderRadius: " 4px",
              }}
              startIcon={<img src={arrowLeft} alt="Arrow" />}
              variant="contained"
              type="secondary"
              onClick={() => {
                navigate("/curing");
              }}
            >
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: "#004F9F",
                padding: " 6px, 16px, 6px, 11px",
                borderRadius: " 4px",
                color: "white",
              }}
              endIcon={<img src={arrowRight} alt="Right Arrow" />}
              type="submit"
              variant="contained"
            >
              {edit ? "Update Registration" : "Save Registration"}
            </Button>
          </Box>
        </form>
      </Box>
    </Layout>
  );
}
