import React, { useContext, useEffect, useState } from "react";
import { Typography, Box, Button } from "@mui/material";
import "./HierarchyTree.css";
import EditButton from "utils/EditButton";
import {
    IconButton,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useHistory, useNavigate } from "react-router-dom";
import moreActions from "assets/moreActions.svg";
import HierarchyService from "services/GrowingHierarchyService";
import { SnackbarContext } from "context/snackBarContext";
import iconEdit from "assets/iconEdit.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import editIcon from "../../../../assets/edit.svg";
import FieldNotebookSupplyService from "services/FieldNotebookSupplyService";
import { AuthData } from "auth/AuthWarpper";


const FieldNotebookNewTable = (props) => {
    const [menuIndex, setMenuIndex] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const [selectedRow, setSelectedRow] = React.useState({});
    const { user } = AuthData();
    const [supplyData, setSupplyData] = useState([]);
    console.log("🚀 ~ SupplyTable ~ supplyData:", supplyData);
    const [originalData, setOriginalData] = useState([]);
    useEffect(() => {
        setSupplyData(props.supplyData);
        setOriginalData(props.supplyData);
    }, [props.supplyData]);
    const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

    const handleClick = (event, index) => {
        setAnchorEl(event.currentTarget);
        setMenuIndex(index);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setMenuIndex(null);
    };

    const handleMenuClose = ({ action, data }) => {
        if (!action || action == undefined) return;
        setSelectedRow(data);
        if (action == "details") {
            navigate("/viewAgriculturalSupplyRegistraion", {
                state: {
                    id: data.fieldNotebookSupplyId,
                    edit: false,
                },
            });
        } else if (action == "edit") {
            navigate("/editAgriculturalSupplyRegistraion", {
                state: {
                    id: data.fieldNotebookSupplyId,
                    edit: true,
                },
            });
        } else if (action === 'activate') {
            // Call handleUpdate to update the status to 'active'
            handleUpdate('active', data);
        } else if (action === 'deactivate') {
            handleUpdate('inactive', data);
        }
        setAnchorEl(null);
    };

    const handleUpdate = async (status, data) => {
        const payload = {
            fieldNotebookSupplyId: data.fieldNotebookSupplyId,
            externalCode: data.externalCode,
            supplyDescription: data.supplyDescription,
            unitOfMeasure: data.unitOfMeasure,
            nitrogenPerc: data.nitrogenPerc,
            phosphorunPerc: data.phosphorunPerc,
            potassiumPerc: data.potassiumPerc,
            fieldNotebookSupplyTypeId: data.fieldNotebookSupplyTypeId,
            status: status === 'active' ? true : false,
            createdByUser: user?.batOneId || 323222,
            modifiedByUser: user?.batOneId || 323222,
        };
        try {
            const response = await FieldNotebookSupplyService.saveFieldNotebookSupply(payload);
            if (response.status === 201) {
                setSeverity("success");
                setMessage(`Status updated to ${status} successfully`);
                setOpenAlert(true);
                props.refreshData();
            }
        } catch (error) {
            if (error.response) {
                const errorMessage = error.response.data.message;
                setMessage(errorMessage);
                setOpenAlert(true);
            } else {
                console.error("Failed to submit form");
                setSeverity("error");
                setMessage("Update failed");
                setOpenAlert(true);
            }
        }

    };

    const handleEdit = () => {
        handleClose();
    };

    const handleDelete = () => {
        // Add your delete logic here
        console.log("Delete action");
        handleClose();
    };

    const handleDuplicate = () => {
        // Add your duplicate logic here
        console.log("Duplicate action");
        handleClose();
    };


    const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

    const handleSort = (key) => {
        let direction = "asc";
        if (sortConfig.key === key) {
            if (sortConfig.direction === "asc") {
                direction = "desc";
            } else if (sortConfig.direction === "desc") {
                direction = null;
            }
        }
        setSortConfig({ key, direction });
        if (direction === null) {
            setSupplyData(originalData);
        } else {
            const sortedData = [...props.supplyData].sort((a, b) => {
                if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
                if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
                return 0;
            });
            setSupplyData(sortedData);
        }
    };

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            if (sortConfig.direction === "asc") return <NorthIcon fontSize="small" />;
            else if (sortConfig.direction === "desc")
                return <SouthIcon fontSize="small" />;
        }
        return <SouthIcon fontSize="small" />;
    };

    return (
        <div className="table-container">
            <table className="full-width-table">
                {" "}
                {/* Add a class for styling */}
                <thead>
                    <tr className="HierarchyHead">
                        <th
                            onClick={() => handleSort("externalCode")}
                            style={{
                                cursor: "pointer",
                                fontWeight: 600,
                                fontSize: "16px",
                            }}
                        >
                            Code
                            <span
                                style={{
                                    marginLeft: "5px",
                                }}
                            >
                                {getSortIcon("externalCode")}
                            </span>
                        </th>
                        <th
                            onClick={() => handleSort("supplyDescription")}
                            style={{ cursor: "pointer", fontWeight: 600, fontSize: "16px" }}
                        >
                            Description
                            <span
                                style={{
                                    marginLeft: "5px",
                                }}
                            >
                                {getSortIcon("supplyDescription")}
                            </span>
                        </th>
                        <th
                            onClick={() => handleSort("unitOfMeasure")}
                            style={{ cursor: "pointer", fontWeight: 600, fontSize: "16px" }}
                        >
                            Uom
                            <span
                                style={{
                                    marginLeft: "5px",
                                }}
                            >
                                {getSortIcon("unitOfMeasure")}
                            </span>
                        </th>
                        <th
                            onClick={() => handleSort("supplyTypeDescription")}
                            style={{ cursor: "pointer", fontWeight: 600, fontSize: "16px" }}
                        >
                            Supply Type
                            <span
                                style={{
                                    marginLeft: "5px",
                                }}
                            >
                                {getSortIcon("supplyTypeDescription")}
                            </span>
                        </th>
                        <th
                            onClick={() => handleSort("status")}
                            style={{ cursor: "pointer", fontWeight: 600, fontSize: "16px" }}
                        >
                            Status
                            <span
                                style={{
                                    marginLeft: "5px",
                                }}
                            >
                                {" "}
                                {getSortIcon("status")}
                            </span>
                        </th>
                        <th style={{ fontWeight: 600, fontSize: "16px" }}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {supplyData?.map((row, index) => (
                        <tr className="hierarchyColumn" key={index} style={{}}>
                            <td style={{ textAlign: "center" }}>
                                <Typography
                                    sx={{
                                        justifyContent: "left",
                                        fontWeight: 600,
                                        color: "#3B454F",
                                    }}
                                >
                                    {row.externalCode}
                                </Typography>
                            </td>
                            <td style={{ textAlign: "center" }}>
                                <Box
                                    sx={{
                                        whiteSpace: "nowrap",
                                        paddingLeft: "5px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        fontWeight: 600,
                                        color: "#3B454F",
                                    }}
                                >
                                    <span title={row.supplyDescription}>
                                        {row.supplyDescription}
                                    </span>
                                </Box>
                            </td>
                            <td style={{ textAlign: "center" }}>
                                <div
                                    className="hierarchyConfig"
                                    style={{
                                        fontWeight: 600,
                                        color: "#3B454F",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <span title={row.unitOfMeasure}>{row.unitOfMeasure}</span>
                                </div>
                            </td>
                            <td style={{ textAlign: "center" }}>
                                <div
                                    className="hierarchyConfig"
                                    style={{
                                        fontWeight: 600,
                                        color: "#3B454F",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <span title={row.supplyTypeDescription}>
                                        {row.supplyTypeDescription}
                                    </span>
                                </div>
                            </td>
                            <td
                                style={{
                                    textAlign: "center",
                                    fontWeight: 600,
                                    color: "#3B454F",
                                }}
                            >
                                {row.status === true ? "Active" : "Inactive"}
                            </td>
                            <td
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            borderRadius: "2.116rem",
                                            backgroundColor: "#FEF4D5",
                                            borderColor: "#F2BB40",
                                            height: "2.8rem",
                                            width: "2.8rem",
                                            color: "#F2BB40",
                                            minWidth: "2.8rem",
                                            "&:hover": {
                                                backgroundColor: "#F2BB40",
                                                color: "#FEF4D5",
                                                borderColor: "#F2BB40",
                                            },
                                        }}
                                        onClick={() =>
                                            navigate("/editAgriculturalSupplyRegistraion", {
                                                state: {
                                                    id: row.fieldNotebookSupplyId,
                                                    edit: true,
                                                },
                                            })
                                        }
                                    >
                                        <img src={editIcon} alt="Edit Button" />
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        className="moreButton"
                                        style={{ marginLeft: "10px" }}
                                        aria-label="more"
                                        aria-controls={
                                            menuIndex === index ? "long-menu" : undefined
                                        }
                                        aria-haspopup="true"
                                        onClick={(event) => handleClick(event, index)}
                                    >
                                        <div>
                                            <img src={moreActions} alt="more-actions" />
                                        </div>
                                        <div style={{ height: "14px" }}>More</div>
                                    </Button>
                                    <Menu
                                        id="long-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={menuIndex === index}
                                        onClose={handleClose}
                                    >
                                        <MenuItem
                                            onClick={() => {
                                                handleMenuClose({ action: "edit", data: row, tab: 1 });
                                                handleClose();
                                            }}
                                        >
                                            <Button
                                                variant="outlined"
                                                sx={{
                                                    borderRadius: "2.116rem",
                                                    backgroundColor: "#FEF4D5",
                                                    borderColor: "#F2BB40",
                                                    height: "3.125rem",
                                                    width: "7.938rem",
                                                    color: "#F2BB40",
                                                    "&:hover": {
                                                        backgroundColor: "#F2BB40",
                                                        color: "#FEF4D5",
                                                        borderColor: "#F2BB40",
                                                    },
                                                }}
                                                onClick={() =>
                                                    handleMenuClose({ action: "edit", data: row, tab: 1 })
                                                }
                                                startIcon={<EditIcon />}
                                            >
                                                Edit
                                            </Button>
                                            {/* <ListItemText primary="Edit" /> */}
                                        </MenuItem>
                                        <MenuItem
                                            onClick={async () => {
                                                try {
                                                    const response =
                                                        await HierarchyService.getDuplicateHierarchyList(
                                                            row?.ihvId
                                                        );
                                                    setSeverity("success");
                                                    setMessage("Duplicate Added successfully");
                                                    setOpenAlert(true);
                                                    props.fetchData();
                                                    handleClose();
                                                } catch (error) {
                                                    console.error("Error calling API:", error);
                                                }
                                            }}
                                        >
                                            {/* <Button
                        variant="outlined"
                        sx={{
                          borderRadius: "2.116rem",
                          backgroundColor: "#FEF4D5",
                          borderColor: "#F2BB40",
                          height: "3.125rem",
                          width: "7.938rem",
                          color: "#F2BB40",
                          "&:hover": {
                            backgroundColor: "#F2BB40",
                            color: "#FEF4D5",
                            borderColor: "#F2BB40",
                          },
                        }}
                        startIcon={<FileCopyIcon />}
                      >
                        Duplicate
                      </Button> */}
                                            <Button
                                                variant="outlined"
                                                sx={{
                                                    borderRadius: "2.116rem",
                                                    backgroundColor: "#D4EDDA",
                                                    borderColor: "#28A745",
                                                    height: "3.125rem",
                                                    width: "7.938rem",
                                                    color: "#28A745",
                                                    "&:hover": {
                                                        backgroundColor: "#28A745",
                                                        color: "#D4EDDA",
                                                        borderColor: "#28A745",
                                                    },
                                                }}
                                                startIcon={<VisibilityIcon />}
                                                onClick={() => {
                                                    handleMenuClose({
                                                        action: "details",
                                                        data: row,
                                                        tab: 1,
                                                    });
                                                    handleClose();
                                                }}
                                            >
                                                View
                                            </Button>
                                        </MenuItem>
                                        {row.status === false && (
                                            <MenuItem className="gridActionCell" sx={{ border: '1px solid #50AF47 !important', display: "flex", justifyContent: "space-around", cursor: "ponter" }}
                                                onClick={() => {
                                                    handleMenuClose({ action: 'activate', data: row, tab: 1 });
                                                    handleClose();
                                                }}
                                            >
                                                <div>Activate</div>
                                            </MenuItem>
                                        )}
                                        {row.status === true && (
                                            <MenuItem className="gridActionCell" sx={{ border: '1px solid #50AF47 !important', display: "flex", justifyContent: "space-around", cursor: "ponter" }}
                                                onClick={() => {
                                                    handleMenuClose({ action: 'deactivate', data: row, tab: 1 });
                                                    handleClose();
                                                }}
                                            >
                                                <div>Deactivate</div>
                                            </MenuItem>
                                        )}
                                    </Menu>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default FieldNotebookNewTable;
