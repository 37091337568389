import React, { useState } from "react";
import "./NewTable.css";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import Edit from "assets/EditNew.svg";
import Delete from "assets/DeleteNew.svg";
import { Box, Button, Modal, Typography, Tooltip } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GreenManureService from "services/GreenManureService";
import { useContext } from "react";
import { SnackbarContext } from "context/snackBarContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 580,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  // p: 4,
};

const NewTable = ({ greenManure, callManureApi }) => {
  console.log("🚀 ~ NewTable ~ greenManure:", greenManure);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  console.log("🚀 ~ NewTable ~ data:", data);
  const [isAscending, setIsAscending] = useState(true);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const handleOpen = (id) => {
    setDeleteId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteId(null);
  };

  const handleDelete = async () => {
    try {
      await GreenManureService.deleteManure({ id: deleteId });
      console.log(`Manure with id ${deleteId} deleted successfully.`);
      setData(data.filter((item) => item.greenManureId !== deleteId));
      handleClose();
      setSeverity("error");
      setMessage("Deleted successfully");
      setOpenAlert(true);
      callManureApi();
    } catch (error) {
      console.error(`Failed to delete manure with id ${deleteId}:`, error);
    }
  };

  useEffect(() => {
    if (greenManure) {
      setData(greenManure);
    }
  }, [greenManure]);

  const handleSort = () => {
    const sortedData = [...data].sort((a, b) => {
      if (isAscending) {
        return a.status > b.status ? 1 : -1;
      } else {
        return a.status < b.status ? 1 : -1;
      }
    });

    setData(sortedData);
    setIsAscending(!isAscending);
  };

  return (
    <div className="table-container">
      <table className="custom-table">
        <thead>
          <tr>
            <th
              onClick={handleSort}
              style={{
                cursor: "pointer",
                display: "flex",
                // justifyContent: "space-between",
              }}
            >
              Green Manure
              {isAscending === true ? (
                <SouthIcon sx={{ ml: 2, color: "#3B454F", fontSize: "18px" }} />
              ) : (
                <NorthIcon sx={{ ml: 2, color: "#3B454F", fontSize: "18px" }} />
              )}
            </th>
            <th
              onClick={handleSort}
              style={{
                cursor: "pointer",
                display: "flex",
                // justifyContent: "space-between",
              }}
            >
              Status
              {isAscending === true ? (
                <SouthIcon sx={{ ml: 2, color: "#3B454F", fontSize: "18px" }} />
              ) : (
                <NorthIcon sx={{ ml: 2, color: "#3B454F", fontSize: "18px" }} />
              )}
            </th>

            <th className="actions-header">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.greenManureId}>
              <td
                onClick={() =>
                  navigate("/viewGreenManure", {
                    state: {
                      greenManureIdEdit: row.greenManureId,
                      statusEdit: row.status,
                      createdByUserEdit: row.createdByUser,
                      modifiedByUserEdit: row.modifiedByUser,
                      greenManureNameEdit: row.greenManureName,
                      details: true,
                    },
                  })
                }
              >
                {row.greenManureName}
              </td>
              <td
                onClick={() =>
                  navigate("/viewGreenManure", {
                    state: {
                      greenManureIdEdit: row.greenManureId,
                      statusEdit: row.status,
                      createdByUserEdit: row.createdByUser,
                      modifiedByUserEdit: row.modifiedByUser,
                      greenManureNameEdit: row.greenManureName,
                      details: true,
                    },
                  })
                }
              >
                {row.status === true ? "Active" : "Inactive"}
              </td>
              <td className="actions-cell">
                <Tooltip
                  title="EDIT"
                  placement="top"
                  arrow
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -5],
                        },
                      },
                    ],
                  }}
                >
                  <img
                    className="btn edit-btn"
                    onClick={() =>
                      navigate("/editGreenManure", {
                        state: {
                          greenManureIdEdit: row.greenManureId,
                          statusEdit: row.status,
                          createdByUserEdit: row.createdByUser,
                          modifiedByUserEdit: row.modifiedByUser,
                          greenManureNameEdit: row.greenManureName,
                          edit: true,
                        },
                      })
                    }
                    src={Edit}
                    alt="Edit"
                    style={{ marginRight: "10px" }}
                  />
                </Tooltip>
                <Tooltip
                  title="DELETE"
                  placement="top"
                  arrow
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -5],
                        },
                      },
                    ],
                  }}
                >
                  <img
                    className="btn delete-btn"
                    onClick={() => handleOpen(row.greenManureId)}
                    src={Delete}
                    alt="Delete"
                  />
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
            >
              Delete Confirmation
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, fontWeight: 400 }}
            >
              Are you sure you want to delete this record? Deleted records
              cannot be recovered.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "80px",
              mb: 1,
            }}
          >
            <Button
              onClick={handleClose}
              sx={{
                background: "#737D86",
                width: "100px",
                // height: "60px",
                color: "#ffffff",
                "&:hover": {
                  background: "#5C646C",
                },
              }}
            >
              No
            </Button>
            <Button
              sx={{
                background: "#004F9F",
                width: "100px",
                // height: "60px",
                color: "#ffffff",
                "&:hover": {
                  background: "#003D7A",
                },
              }}
              onClick={handleDelete}
            >
              {" "}
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default NewTable;
