// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datagridContainer {
  width: '100%';
  height: "100% !important";
  margin-right: 2.5rem;
  margin-left: 2.5rem;
}

.datagridContainer div {
  border-style: none;
}

.moreButton {
  font-family: 'Montserrat';
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 14px !important;
  letter-spacing: 0em !important;
  border-radius: 50px !important;
  color: rgba(0, 79, 159, 1) !important;
  border: 1px solid rgba(0, 79, 159, 1) !important;
  padding: 8px, 14px, 8px, 14px !important;
  height: 45px;
}
.moreButton div {padding-left: 5px !important;}

.gridActionCellDetails{
  border-width: 1px;
  border-style: solid;
  margin: 8px 14px 8px 14px!important;
  border-radius: 50px !important;
  font-family: 'Montserrat'!important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 14px !important;
  cursor: pointer;
  justify-content: center;
  display: flex;
  justify-self: center;
  align-items: center;
  width: 130px;
}
`, "",{"version":3,"sources":["webpack://./src/utils/DataTable.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,0BAA0B;EAC1B,2BAA2B;EAC3B,4BAA4B;EAC5B,8BAA8B;EAC9B,8BAA8B;EAC9B,qCAAqC;EACrC,gDAAgD;EAChD,wCAAwC;EACxC,YAAY;AACd;AACA,iBAAiB,4BAA4B,CAAC;;AAE9C;EACE,iBAAiB;EACjB,mBAAmB;EACnB,mCAAmC;EACnC,8BAA8B;EAC9B,mCAAmC;EACnC,0BAA0B;EAC1B,2BAA2B;EAC3B,4BAA4B;EAC5B,eAAe;EACf,uBAAuB;EACvB,aAAa;EACb,oBAAoB;EACpB,mBAAmB;EACnB,YAAY;AACd","sourcesContent":[".datagridContainer {\n  width: '100%';\n  height: \"100% !important\";\n  margin-right: 2.5rem;\n  margin-left: 2.5rem;\n}\n\n.datagridContainer div {\n  border-style: none;\n}\n\n.moreButton {\n  font-family: 'Montserrat';\n  font-size: 14px !important;\n  font-weight: 700 !important;\n  line-height: 14px !important;\n  letter-spacing: 0em !important;\n  border-radius: 50px !important;\n  color: rgba(0, 79, 159, 1) !important;\n  border: 1px solid rgba(0, 79, 159, 1) !important;\n  padding: 8px, 14px, 8px, 14px !important;\n  height: 45px;\n}\n.moreButton div {padding-left: 5px !important;}\n\n.gridActionCellDetails{\n  border-width: 1px;\n  border-style: solid;\n  margin: 8px 14px 8px 14px!important;\n  border-radius: 50px !important;\n  font-family: 'Montserrat'!important;\n  font-size: 14px !important;\n  font-weight: 700 !important;\n  line-height: 14px !important;\n  cursor: pointer;\n  justify-content: center;\n  display: flex;\n  justify-self: center;\n  align-items: center;\n  width: 130px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
