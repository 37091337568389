import { createSlice } from "@reduxjs/toolkit";
const initialState = {test:'test'};
const cropSlice = createSlice({
    name: "crop",
    initialState,
    reducers: {
        // add your non-async reducers here
        crop: (state, action) => {
            console.log('action.payload', action.payload)
            console.log('state', state)
            state.crop = action.payload
        }
    }

});

export const { crop } = cropSlice.actions;
const { reducer } = cropSlice;
export default reducer;