import React, { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Layout from "components/structure/Layout";
import { AuthData } from "auth/AuthWarpper";
import { SnackbarContext } from "context/snackBarContext";
import CultureTable from "./VarietyTable";
import IosShareIcon from "@mui/icons-material/IosShare";
import { json2csv } from "json-2-csv";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CuringService from "services/CuringService";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { debounce } from "lodash";
import SpacingServices from "services/SpacingServices";
import VarietyService from "services/VarietyService";
import Lable from "utils/lables.json"
import styled from "@emotion/styled";
import label from "../growingLabel.json";

const VarietyList = () => {
  const navigate = useNavigate();
  const { user } = AuthData();
  const [varietyData, setVarietyData] = useState();
  console.log("🚀 ~ VarietyList ~ varietyData:", varietyData)
  const [allCuringData, setAllCuringData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [searchParams, setSearchParams] = useState({
    isNew: "",
    varietiesName: "",
    tobaccoGroup: "",
    status: "",
    countryCode: user ? user.countryCode : "BRA",
  });

  const [descSearch, setDescSearch] = useState({ varietiesName: "" });
  console.log("🚀 ~ Curing ~ descSearch:", descSearch);

  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(3); // Number of items per page
  const [totalItems, setTotalItems] = useState(5); // Number of items per page
  const [recordsError, setRecordsError] = useState(false);
  const [tobaccoGroups, setTobaccoGroups] = useState([]);
  console.log("🚀 ~ Curing ~ tobaccoGroups:", tobaccoGroups)

  const callCuringApi = (pNumber, size) => {
    setLoading(true);
    setRecordsError(false);

    VarietyService.getVariety({ pageNumber: pNumber, size: size })
      .then((res) => {
        setCurrentPage(pNumber);
        setItemsPerPage(size);
        setLoading(false);
        setRecordsError(false);
        setVarietyData(res?.data?.response?.varieties);
        setTotalItems(res?.data?.response?.totalItems);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  const paginate = (pageNumber, itemsPerPage) => {
    callCuringApi(pageNumber - 1, itemsPerPage);
  };

  useEffect(() => {
    callCuringApi(currentPage, itemsPerPage);
  }, []);

  const handleChangeDescription = (event) => {
    const { value } = event.target;
    setDescSearch({ varietiesName: value });
  };

  const handleSearch = useCallback(
    debounce(() => {
      const cleanedParams = cleanSearchParams(searchParams);

      if (
        Object.keys(cleanedParams).length === 1 &&
        cleanedParams.countryCode
      ) {
        callCuringApi(currentPage, itemsPerPage);
        return;
      }

      VarietyService.searchVarietyUnitName({
        page: currentPage,
        size: itemsPerPage,
        searchParams: cleanedParams,
      })
        .then((res) => {
          setCurrentPage(res?.data?.response?.currentPage);
          setTotalItems(res?.data?.response?.totalItems);
          setVarietyData(res?.data?.response?.varietiess);
          setRecordsError(false);
        })
        .catch((error) => {
          setRecordsError(true);
          console.log(error);
        });
    }, 500),
    [searchParams, currentPage, itemsPerPage]
  );

  const handleSearchDesc = useCallback(
    debounce(() => {
      if (descSearch.varietiesName === "") {
        callCuringApi(currentPage, itemsPerPage);
        return;
      }

      console.log("dw", descSearch.varietiesName)

      VarietyService.searchVarietyUnitName({
        searchParam: descSearch.varietiesName,
        page: currentPage,
        size: itemsPerPage,
      })
        .then((res) => {
          setCurrentPage(res?.data?.response?.currentPage);
          setTotalItems(res?.data?.response?.totalItems);
          setVarietyData(res?.data?.response?.searchedVariety);
          setRecordsError(false);
        })
        .catch((error) => {
          setRecordsError(true);
          console.log(error);
        });
    }, 500),
    [descSearch.varietiesName, currentPage, itemsPerPage]
  );

  useEffect(() => {
    handleSearchDesc();
    return () => {
      handleSearchDesc.cancel();
    };
  }, [descSearch.varietiesName, currentPage, itemsPerPage, handleSearchDesc]);

  useEffect(() => {
    if (Object.keys(cleanSearchParams(searchParams)).length > 1) {
      handleSearch();
    } else {
      callCuringApi(currentPage, itemsPerPage);
    }

    return () => {
      handleSearch.cancel();
    };
  }, [searchParams, currentPage, itemsPerPage, handleSearch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await CuringService.getAllCuringData(
          user ? user.countryCode : "BRA"
        );
        setAllCuringData(response.data.response);
      } catch (error) {
        console.error("There was an error!", error);
      }
    };

    fetchData();
  }, []);


  const cleanSearchParams = (params) => {
    const cleanedParams = { ...params };
    Object.keys(cleanedParams).forEach((key) => {
      if (cleanedParams[key] === "") {
        delete cleanedParams[key];
      }
    });
    return cleanedParams;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };

  const handleClear = (name) => {
    setSearchParams({
      ...searchParams,
      [name]: "",
    });
  };

  useEffect(() => {
    const fetchTobaccoGroups = async () => {
      try {
        const response = await SpacingServices.getTobbacoType()
        setTobaccoGroups(response.data.response);
      } catch (error) {
        console.error("Failed to fetch tobacco groups", error);
      }
    };

    fetchTobaccoGroups();
  }, [user]);

  // return (
  //   <Layout
  //     title={"Variety - List"}
  //     itemsPerPage={itemsPerPage}
  //     totalItems={totalItems}
  //     paginate={paginate}
  //     currentPage={currentPage}
  //   >
  //     <Box mt={2} sx={{ paddingBottom: "1rem", position: "relative" }}>
  //       <Box
  //         display="flex"
  //         justifyContent="space-between"
  //         alignItems="center"
  //         mb={2}
  //         background="pink"
  //       >
  //         <Box display={"flex"} gap={4}>
  //           <Grid item xs={12} sm={6} md={2}>
  //             <TextField
  //               name="varietiesName"
  //               label="Search"
  //               value={descSearch.varietiesName}
  //               onChange={handleChangeDescription}
  //               fullWidth
  //             />
  //           </Grid>
  //           {/* <Grid item xs={12} sm={6} md={2}>
  //             <FormControl sx={{ minWidth: "200px" }}>
  //               <TextField
  //                 select
  //                 name="tobaccoGroup"
  //                 label={"Variety"}
  //                 value={searchParams.tobaccoGroup}
  //                 onChange={handleChange}
  //                 variant="outlined"
  //                 SelectProps={{
  //                   IconComponent: ArrowDropDownIcon,
  //                   endAdornment: searchParams.tobaccoGroup && (
  //                     <CloseIcon
  //                       onClick={() => handleClear("tobaccoGroup")}
  //                       style={{
  //                         cursor: "pointer",
  //                         marginRight: "12px",
  //                       }}
  //                     />
  //                   ),
  //                 }}
  //               >
  //                 <MenuItem value="">
  //                   <em>None</em>
  //                 </MenuItem>
  //                 {tobaccoGroups?.map((tobacco) => (
  //                     <MenuItem key={tobacco.tobaccoGroup} value={tobacco.tobaccoGroup}>
  //                       {tobacco.tobaccoGroup}
  //                     </MenuItem>
  //                   ))}
  //               </TextField>
  //             </FormControl>
  //           </Grid> */}
  //         </Box>
  //         <Box>

  //           <Button
  //             sx={{
  //               borderRadius: 100,
  //               minWidth: 95,
  //               minHeight: 44,
  //               background: "#102A62",
  //               marginTop: { xs: 1 },
  //               marginLeft: { xs: 1 },
  //             }}
  //             variant="contained"
  //             startIcon={<AddCircleIcon />}
  //             onClick={() => navigate("/varietyForm")}
  //           >
  //             add variety
  //           </Button>
  //         </Box>
  //       </Box>
  //       {varietyData && varietyData.length > 0 && !recordsError ? (
  //         <CultureTable varietyData={varietyData} />
  //       ) : (
  //         <div>No records Found</div>
  //       )}
  //     </Box>
  //   </Layout>
  // );

  return (
    <Layout
      title={"Variety"}
      background="#ffffff"
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      currentPage={currentPage}
      paginate={paginate}
      useNewPagination={true}
    >

      <Box
        display=""
        alignItems="center"
        mb={6}>

        <PaperContainer
          background="#EFEFEF"
        >
          <Box sx={{ display: "flex" }}>
            <ArrowDropDownIcon sx={{ marginRight: 1, marginBottom: 1.1 }} />
            <Typography>{Lable.filtersLable}</Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            sx={{ gap: 2 }}>


            {/* Variety Name */}
            <TextField
              name="varietiesName"
              label="Variety"
              value={descSearch.varietiesName}
              onChange={handleChangeDescription}

              sx={{ flexGrow: 1, mx: 1 }}
            />

            {/* Culture */}
            <FormControl sx={{ flexGrow: 1, mx: 1 }}>
              <TextField
                select
                name="culture"
                label="Culture"
                // value={searchParams.varietiesName}
                // onChange={handleChange}
                variant="outlined"
                SelectProps={{
                  IconComponent: ArrowDropDownIcon,
                  endAdornment: (
                    <CloseIcon
                      // onClick={() => handleClear("varietiesName")}
                      style={{ width: "18px", cursor: "pointer", marginRight: "12px" }}
                    />
                  ),
                }}
              >
                <MenuItem value="Pest">Pest</MenuItem>
                <MenuItem value="Disease">Disease</MenuItem>
                <MenuItem value="Climate">Climate</MenuItem>
              </TextField>
            </FormControl>

            {/* Culture Type */}
            <FormControl sx={{ flexGrow: 1, mx: 1 }}>
              <TextField
                select
                name="cultureType"
                label="Culture Type"
                // value={searchParams.varietiesName}
                // onChange={handleChange}
                variant="outlined"
                SelectProps={{
                  IconComponent: ArrowDropDownIcon,
                  endAdornment: (
                    <CloseIcon
                      // onClick={() => handleClear("varietiesName")}
                      style={{ width: "18px", cursor: "pointer", marginRight: "12px" }}
                    />
                  ),
                }}
              >
                <MenuItem value="Pest">Pest</MenuItem>
                <MenuItem value="Disease">Disease</MenuItem>
                <MenuItem value="Climate">Climate</MenuItem>
              </TextField>
            </FormControl>

            {/* Status */}
            <FormControl sx={{ flexGrow: 1, mx: 1 }}>
              <TextField
                select
                name="status"
                label="Status"
                // value={searchParams.status}
                // onChange={handleChange}
                SelectProps={{
                  IconComponent: ArrowDropDownIcon,
                  endAdornment: (
                    <CloseIcon
                      // onClick={() => handleClear("status")}
                      style={{ width: "18px", cursor: "pointer", marginRight: "12px" }}
                    />
                  ),
                }}
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </TextField>
            </FormControl>


            <Button
              sx={{
                borderRadius: 1,
                minWidth: 95,
                minHeight: 44,
                background: "#004F9F",
              }}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => navigate("/varietyForm")}>

              Add New
            </Button>
          </Box>
        </PaperContainer>
        {!loading && varietyData && varietyData.length === 0 && (
          <Typography variant="h6" align="center" color="textSecondary">
            No Data Available
          </Typography>
        )}

        {varietyData && varietyData.length > 0 && !recordsError ? (
          <CultureTable varietyData={varietyData} />
        ) : (
          <div>{label.noDataMessage}</div>
        )}


      </Box>

    </Layout>
  )

};

const PaperContainer = styled(Paper)`
  padding: 2rem 1rem 1rem 1rem;
  background: ${(props) => props.background || props.theme.palette.background.default}`;
export default VarietyList;
