import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  farmerObj: [],
};

const farmerObjSlice = createSlice({
  name: "farmerObj",
  initialState,
  reducers: {
    updateFarmerObj(state, action) {
      state.farmerObj = {
        ...state.farmerObj,
        ...action.payload,
      };
    },
    
  },
});

export const { updateFarmerObj } = farmerObjSlice.actions;
export default farmerObjSlice.reducer;
