import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  Grid,
  TextField,
  MenuItem,
  Icon,
  Box,
  FormControl,
  InputAdornment,
} from "@mui/material";
import Button from "@mui/material/Button";
import plusIcon from "assets/plusIcon.svg";
import editButton from "assets/editButton.svg";
import iconEdit from "assets/iconEdit.svg";
import iconView from "assets/iconView.svg";
import iconDelete from "assets/iconDelete.svg";
import { useNavigate } from "react-router-dom";
import { GridActionsCellItem } from "@mui/x-data-grid";
import "./Farmers.css";
import Layout from "components/structure/Layout";
import { useSelector } from "react-redux";
import FarmerTable from "./FarmerTable";
import { SnackbarContext } from "context/snackBarContext";
import FarmerRegisterationService from "services/FarmerRegistrationService";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { debounce } from "lodash";
import FarmerDataService from "services/FarmerService";
import iconSearchs from "../../../../../../assets/iconSearchs.svg";
import label from "../../..//growingLabel.json";

const columns = [
  {
    field: "farmercode",
    headerName: "Farmer code",
    flex: 1,

    headerAlign: "center",
    align: "center",
    headerClassName: "data-table-header",
  },
  {
    field: "farmername",
    headerName: "Farmer name",
    flex: 1,

    headerAlign: "center",
    align: "center",
  },
  {
    field: "region",
    headerName: "Region",
    flex: 1,

    headerAlign: "center",
    align: "center",
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,

    headerAlign: "center",
    align: "center",
  },
  {
    field: "actions",
    type: "actions",
    headerName: "Actions",
    flex: 1,

    headerAlign: "center",
    align: "center",
    getActions: (params) => [
      <GridActionsCellItem
        icon={<img src={editButton} alt="Edit Button" />}
        label="Edit"
        className="gridActionCellEditSingle"
        onClick={() => alert(`Edit ${params.id}`)}
      />,
      <GridActionsCellItem
        icon={<img src={iconView} height="30px" width="30px" alt="View" />}
        label="Details"
        className="gridActionCell gridActionCellDetails"
        onClick={() => alert(`Details ${params.id}`)}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<img src={iconEdit} height="30px" width="30px" alt="Edit" />}
        className="gridActionCell gridActionCellEdit"
        label="Edit"
        onClick={() => alert(`Edit ${params.id}`)}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<img src={iconDelete} height="30px" width="30px" />}
        label="Delete"
        className="gridActionCell gridActionCellDelete"
        onClick={() => alert(`Delete ${params.id}`)}
        showInMenu
      />,
    ],
  },
];

const Farmers = () => {
  const navigate = useNavigate();
  const farmerObj = useSelector((state) => state.farmerObj.farmerObj);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [farmerData, setFarmerData] = useState(null);
  console.log("🚀 ~ Farmers ~ farmerData:", farmerData);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(7); // Number of items per page
  const [totalItems, setTotalItems] = useState(5); // Number of items per page
  const [recordsError, setRecordsError] = useState(false);
  const [searchParams, setSearchParams] = useState({
    search: "",
    region: "",
    status: "",
  });
  const [descSearch, setDescSearch] = useState({ search: "" });

  console.log("🚀 ~ Farmers ~ farmerObj:", farmerObj);

  const callFarmerApi = (pNumber, size) => {
    // setLoading(true);
    // setRecordsError(false);

    // FarmerRegisterationService.getAll({
    //   batId: 85168485,
    //   pageNumber: pNumber,
    //   size: size,
    // })
    //   .then((res) => {
    //     setCurrentPage(pNumber);
    //     setItemsPerPage(size);
    //     setLoading(false);
    //     setRecordsError(false);
    //     setFarmerData(res?.data?.response?.farmers);
    //     setTotalItems(res?.data?.response?.totalItems);
    //   })
    //   .catch((error) => {
    //     setMessage(error?.response?.data?.message || error?.message);
    //     setOpenAlert(true);
    //     setSeverity("error");
    //   });
    const cleanedParams = cleanSearchParams(searchParams);
    FarmerDataService.searchFarmer({
      searchParams: cleanedParams,
      page: pNumber,
      size: itemsPerPage,
    })
      .then((res) => {
        setLoading(false);
        setItemsPerPage(size);
        setCurrentPage(res?.data?.response?.currentPage);
        setTotalItems(res?.data?.response?.totalItems);
        setFarmerData(res?.data?.response?.farmers);
        console.log("FARMREG", res?.data?.response)
        setRecordsError(false);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message)
        setOpenAlert(true)
        setSeverity("error")
        setRecordsError(true);
        console.log(error);
      });


  };

  const paginate = (pageNumber, itemsPerPage) => {
    callFarmerApi(pageNumber - 1, itemsPerPage);
  };

  useEffect(() => {
    callFarmerApi(currentPage, itemsPerPage);
  }, []);

  const plusIconComp = (
    <Icon>
      <img
        alt="edit"
        style={{ display: "flex", justifyContent: "center" }}
        width="100%"
        height="100%"
        src={plusIcon}
      />
    </Icon>
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };

  const handleSearch = useCallback(
    debounce(() => {
      const cleanedParams = cleanSearchParams(searchParams);
      console.log("cleanedParams", cleanedParams);
      if (
        Object.keys(cleanedParams).length === 1 &&
        cleanedParams.countryCode
      ) {
        callFarmerApi(currentPage, itemsPerPage);
        return;
      }

      FarmerDataService.searchFarmer({
        searchParams: cleanedParams,
        page: currentPage,
        size: itemsPerPage,
      })
        .then((res) => {
          setCurrentPage(res?.data?.response?.currentPage);
          setTotalItems(res?.data?.response?.totalItems);
          setFarmerData(res?.data?.response?.farmers);
          setRecordsError(false);
        })
        .catch((error) => {
          setRecordsError(true);
          console.log(error);
        });
    }, 500),
    [
      searchParams.status,
      searchParams.region,
      searchParams.search,
      currentPage,
      itemsPerPage,
    ]
  );

  useEffect(() => {
    if (Object.keys(cleanSearchParams(searchParams)).length > 0) {
      handleSearch();
    } else {
      console.log("Api");
      callFarmerApi(currentPage, itemsPerPage);
    }

    return () => {
      handleSearch.cancel();
    };
  }, [searchParams, currentPage, itemsPerPage, handleSearch]);

  const cleanSearchParams = (params) => {
    const cleanedParams = { ...params };
    Object.keys(cleanedParams).forEach((key) => {
      if (cleanedParams[key] === "") {
        delete cleanedParams[key];
      }
    });
    return cleanedParams;
  };

  const handleClear = (name) => {
    setSearchParams({
      ...searchParams,
      [name]: "",
    });
  };

  const handleChangeDescription = (event) => {
    const { value } = event.target;
    setDescSearch({ search: value });
  };

  return (
    <Layout
      title={"Farmer List"}
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
    >
      <FarmerContainer
        style={{ backgroundColor: "#F8F8F8", height: "100%", width: "100%" }}
      >
        <FarmerSearchContainer>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Box display={"flex"} gap={4}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  name="search"
                  placeholder="Search"
                  value={searchParams.search}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={iconSearchs} alt="search-icon" />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormControl sx={{ minWidth: "200px" }}>
                  <TextField
                    select
                    name="region"
                    label={"Region"}
                    value={searchParams.region}
                    onChange={handleChange}
                    variant="outlined"
                    SelectProps={{
                      IconComponent: ArrowDropDownIcon,
                      endAdornment: searchParams.region && (
                        <CloseIcon
                          onClick={() => handleClear("region")}
                          style={{
                            cursor: "pointer",
                            marginRight: "12px",
                          }}
                        />
                      ),
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="Brazil">Brazil - BR</MenuItem>
                    <MenuItem value="Protugul">Protugul - PT</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormControl sx={{ minWidth: "200px" }}>
                  <TextField
                    select
                    name="status"
                    value={searchParams.status}
                    onChange={handleChange}
                    label="Status"
                    SelectProps={{
                      IconComponent: ArrowDropDownIcon,
                      endAdornment: (searchParams.status === "Active" ||
                        searchParams.status === "Inactive") && (
                          <CloseIcon
                            onClick={() => handleClear("status")}
                            style={{
                              cursor: "pointer",
                              marginLeft: "auto",
                              paddingRight: "20px",
                            }}
                          />
                        ),
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Inactive">Inactive</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
            </Box>
            <Grid
              item
              md={4}
              sx={{ float: "right" }}
              className="farmer-addbutton-container"
            >
              <Button
                style={{ backgroundColor: "#004F9F", borderRadius: "100px" }}
                variant="contained"
                startIcon={plusIconComp}
                onClick={() => navigate("/addFarmerRegistration")}
              >
                {" "}
                Add Farmer Registeration
              </Button>
            </Grid>
          </Box>
        </FarmerSearchContainer>
        {farmerData?.length > 0 && !recordsError ? (
          <FarmerTable farmerData={farmerData} columns={columns} />
        ) : (
          <div>{label.noDataMessage}</div>
        )}
      </FarmerContainer>
    </Layout>
  );
};

const FarmerContainer = styled.div``;
const FarmerSearchContainer = styled.div``;

export default Farmers;
