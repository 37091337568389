import React from 'react';
import {  useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";


const EditButton = ({ state, url }) => {
  const navigate = useNavigate();

  const handleEditClick = () => {
    navigate(url, { state });
  };


  return (
    <Button
            variant="outlined"
            sx={{
              borderRadius: "2.116rem",
              backgroundColor: "#FEF4D5",
              borderColor: "#F2BB40",
              height:"2.125rem",
              width:"4.938rem",
              color: "#F2BB40",
              "&:hover": {
                backgroundColor: "#F2BB40",
                color: "#FEF4D5",
                borderColor: "#F2BB40",
              },
            }}
            startIcon={<EditIcon />}
            onClick={handleEditClick}
 
          >
            Edit
          </Button>
  );
};

export default EditButton;
