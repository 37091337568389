import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import leftIcon from "../../../assets/left-icon-blue.svg";
import rightIcon from "../../../assets/right-icon-blue.svg";
import PaginationItem from "@mui/material/PaginationItem";

const Pagination2024 = ({
  itemsPerPage,
  totalItems,
  paginate,
  currentPage,
}) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  let fromData = currentPage * itemsPerPage || 1;
  let toData = currentPage * itemsPerPage + itemsPerPage;
  let totalPages = Math.ceil(totalItems / itemsPerPage);

  if (toData > totalItems) {
    toData = totalItems;
  }

  const [page, setPage] = React.useState(currentPage);
  const handleChange = (event, value) => {
    paginate(value, itemsPerPage);
    setPage(value);
  };

  const handleNextClick = (event, value) => {
    paginate(value.page, itemsPerPage);
    setPage(value.page);
  };

  const handlePreviousClick = (event, value) => {
    paginate(value.page, itemsPerPage);
    setPage(value.page);
  };

  useEffect(() => { }, [page, currentPage]);

  const handleClick = (pageNumber) => {
    if (pageNumber < 1) return;
    paginate(pageNumber, itemsPerPage);
  };

  const handleResize = (newItemsPerPage) => {
    if (newItemsPerPage < 1) return;

    // Calculate the new total pages
    const newTotalPages = Math.ceil(totalItems / newItemsPerPage);

    // Adjust the current page if it exceeds the new total pages
    const newCurrentPage = Math.min(currentPage, newTotalPages - 1);

    // Ensure at least one item is displayed
    const adjustedItemsPerPage = Math.min(newItemsPerPage, totalItems);

    paginate(newCurrentPage + 1, adjustedItemsPerPage);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: { xs: "column", sm: "column", md: "row" },
        paddingTop: "10px",
        background: "white",
        padding: "10px 30px",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontSize: "20px",
            fontWeight: 500,
            lineHeight: "30px",
            color: "#5A646E",
          }}
        >
          {`RECORDS PER PAGE `}
        </Typography>
        <Box sx={{ display: "flex", paddingLeft: "10px" }}>
          <RemoveIcon
            onClick={() => {
              handleResize(itemsPerPage - 1);
            }}
            style={{
              backgroundColor: "#004F9F",
              color: "white",
              cursor: "pointer",
            }}
          />
          <Typography sx={{ paddingLeft: "5px" }}> {itemsPerPage < 10 ? `0${itemsPerPage}` : itemsPerPage}</Typography>

          {currentPage + 1 !== pageNumbers.length && <AddIcon
            onClick={() => {
              handleResize(itemsPerPage + 1);
            }}
            style={{
              // backgroundColor: (currentPage + 1 !== pageNumbers.length) ? "#004F9F" : "#EBEBE4",
              backgroundColor: "#004F9F",
              color: "white",
              marginLeft: "5px",
              cursor: "pointer",
            }}
          />}
          {/* 
          <AddIcon
            onClick={() => {
              handleResize(itemsPerPage + 1);
            }}
            style={{
              backgroundColor: "#004F9F",
              color: "white",
              marginLeft: "5px",
              cursor: "pointer",
            }}
          /> */}
        </Box>
        <Typography sx={{ ml: "10px", color: "#5A646E", fontWeight: "700" }}>
          {`${fromData}-${toData} OF ${totalItems}`}{" "}
        </Typography>
      </Box>

      <Box sx={{ display: "flex" }}>
        {/* <img
          src={leftIcon}
          alt="Left Icon"
          onClick={() => {
            handleClick(currentPage);
          }}
          style={{
            paddingBottom: "5px",
            paddingRight: "5px",
            cursor: "pointer",
          }}
        /> */}
        <Pagination
          count={totalPages}
          shape="rounded"
          // color="secondary"
          sx={{
            // Base color for pagination items
            "& .MuiPaginationItem-root": {
              color: "#102A62 !important", // Non-selected page numbers
              "&:hover": {
                backgroundColor: "102A62 !important", // Light green hover background
              },
            },
            // Styles for the selected page
            "& .Mui-selected": {
              backgroundColor: "#004F9F !important", // Selected page background
              color: "white !important", // Selected page text color
              "&:hover": {
                backgroundColor: "#004F9F!important", // Keep the same color on hover for the selected page
              },
            },
            // Styles for the ellipsis (...)
            "& .MuiPaginationItem-ellipsis": {
              color: "#004F9F !important", // Color for ellipsis
            },
            "& .MuiPaginationItem-previousNext": {
              height: "40px", // Custom height for the left/right icons
              // Optional: you can also control the width
              "& svg": {
                fontSize: "1.5rem", // Custom font size for the arrow icons
              },
            },
            "& .MuiPaginationItem-icon": {
              fontSize: "2rem", // Set icon size directly if needed
            },
          }}
          onChange={handleChange}
          page={page + 1}
          renderItem={(item) => (
            <PaginationItem
              {...item}
              onClick={(e) => {
                if (item.type === "next") {
                  handleNextClick(e, item);
                } else if (item.type === "previous") {
                  handlePreviousClick(e, item);
                }

                // You can keep the default onClick behavior
                item.onClick?.(e);
              }}
            />
          )}
        />

        {/* {currentPage + 1 !== pageNumbers.length && (
          <img
            src={rightIcon}
            alt="Right Icon"
            onClick={() => {
              handleClick(currentPage + 2);
            }}
            style={{
              paddingBottom: "5px",
              paddingLeft: "5px",
              cursor: "pointer",
            }}
          />
        )} */}
      </Box>

      <Box sx={{ display: "flex" }}>
        {/* <img
          src={leftIcon}
          alt="Left Icon"
          onClick={() => {
            handleClick(currentPage);
          }}
          style={{
            paddingBottom: "5px",
            paddingRight: "5px",
            cursor: "pointer",
          }}
        /> */}
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontSize: "20px",
            fontWeight: 500,
            // lineHeight: "30px",
            color: "#5A646E",
          }}
        >
          PAGE
        </Typography>
        <Typography sx={{ paddingLeft: "5px", textDecoration: "underline", color: "#0E2B63" }}>
          {" "}
          {currentPage + 1 > 9 ? currentPage + 1 : "0" + (currentPage + 1)}
        </Typography>
        <Typography
          sx={{
            paddingLeft: "5px",
            fontFamily: "Montserrat",
            fontSize: "20px",
            fontWeight: 500,
            //lineHeight: "30px",
            color: "#5A646E",
          }}
        >
          {" "}
          OF
        </Typography>
        <Typography sx={{ paddingLeft: "5px", textDecoration: "underline", color: "#0E2B63" }}>
          {" "}
          {pageNumbers.length + 1 > 9
            ? pageNumbers.length
            : "0" + (pageNumbers.length)}
        </Typography>

        {/* {currentPage + 1 === pageNumbers.length} */}
        {/* {currentPage + 1 !== pageNumbers.length && (
          <img
            src={rightIcon}
            alt="Right Icon"
            onClick={() => {
              handleClick(currentPage + 2);
            }}
            style={{
              paddingBottom: "5px",
              paddingLeft: "5px",
              cursor: "pointer",
            }}
          />
        )} */}
      </Box>
    </Box>
  );
};

export default Pagination2024;
