import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Layout from "components/structure/Layout";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import Paper from "@mui/material/Paper";
import OccurrenceTable from "./OccurrenceTable";
import { useNavigate } from "react-router-dom";
import OccurrenceTypeService from "services/OccurrenceTypeService";
import { SnackbarContext } from "context/snackBarContext";
import { debounce } from "lodash";
import ExpandMoreIcon from "@mui/icons-material/ArrowDropDown";
import label from "../growingLabel.json";
import { useTranslation } from "react-i18next";

const FieldOccurenceList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [occurrenceData, setOccurrenceData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  // const [itemsPerPage, setItemsPerPage] = useState(10);
  const [itemsPerPage, setItemsPerPage] = useState(() => {
    return parseInt(localStorage.getItem("IPPFO")) || 5;
  });
  const [totalItems, setTotalItems] = useState(0);

  // const [searchKeyword, setSearchKeyword] = useState("");

  var isApiCalled = 0;

  const [searchParams, setSearchParams] = useState({
    description: "",
    category: "",
    status: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
    console.log("searching parameters", searchParams);

    handleSearchApi();
  };

  const handleClear = (name) => {
    setSearchParams({
      ...searchParams,
      [name]: "",
    });
  };

  const cleanSearchParams = (params) => {
    const cleanedParams = { ...params };
    Object.keys(cleanedParams).forEach((key) => {
      if (cleanedParams[key] === "") {
        delete cleanedParams[key];
      }
    });
    return cleanedParams;
  };

  const callOccurrenceTypeListAPI = (pageNumber, size) => {
    setLoading(true);
    OccurrenceTypeService.getOccurrenceTypeList({ pageNumber, size })
      .then((res) => {
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setLoading(false);
        setOccurrenceData(res?.data?.response.fieldOccurrences);
        setTotalItems(res?.data?.response?.totalItems);
      })
      .catch((error) => {
        setLoading(false);
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setOccurrenceData([]);
      });
  };

  const handleSearchApi = useCallback(
    debounce((pNumber, size) => {
      const cleanedParams = cleanSearchParams(searchParams);
      const queryParams = {
        pageNumber: pNumber,
        size: size,
        keyword: cleanedParams.description,
        occurrenceCategory: cleanedParams.category,
        status: cleanedParams.status,
      };
      OccurrenceTypeService.searchOccurrenceType(queryParams)
        .then((res) => {
          setCurrentPage(pNumber);
          setItemsPerPage(size);
          setLoading(false);
          setTotalItems(res?.data?.response?.totalItems);
          setOccurrenceData(res?.data?.response?.fieldOccurrences);
          // setRecordsError(false);
        })
        .catch((error) => {
          setLoading(false);
          setMessage(error?.response?.data?.message || error?.message);
          setOpenAlert(true);
          setSeverity("error");
          setOccurrenceData([]);
          setTotalItems(0);
        });
    }, 500),
    [searchParams]
  );

  // const paginate = (pageNumber, itemsPerPage) => {
  //   callOccurrenceTypeListAPI(pageNumber - 1, itemsPerPage);
  // };
  const paginate = (pageNumber, itemsPerPage) => {
    if (
      searchParams.description == "" &&
      searchParams.category == "" &&
      searchParams.status == ""
    ) {
      callOccurrenceTypeListAPI(pageNumber - 1, itemsPerPage);
    } else {
      handleSearchApi(pageNumber - 1, itemsPerPage);
    }
    localStorage.setItem("IPPFO", itemsPerPage);
  };
  useEffect(() => {
    callOccurrenceTypeListAPI(currentPage, itemsPerPage);
  }, []);

  useEffect(() => {
    if (Object.keys(cleanSearchParams(searchParams)).length > 0) {
      isApiCalled = 1;
      handleSearchApi(0, itemsPerPage);
      console.log("api call times if", isApiCalled);
    } else if (Object.keys(cleanSearchParams(searchParams)).length == 0) {
      callOccurrenceTypeListAPI(currentPage, itemsPerPage);
      console.log(
        "api call times else if",
        Object.keys(cleanSearchParams(searchParams)).length,
        isApiCalled
      );
    } else {
    }

    return () => {
      handleSearchApi.cancel();
    };
  }, [searchParams]);

  const refreshPage = () => {
    const totalItemsOnPage = occurrenceData?.length ?? 0;

    if (
      searchParams.description == "" &&
      searchParams.category == "" &&
      searchParams.status == ""
    ) {
      if (totalItemsOnPage === 1 && currentPage > 0) {
        callOccurrenceTypeListAPI(currentPage - 1, itemsPerPage);
      } else if (totalItemsOnPage === 1 && currentPage == 0) {
        setOccurrenceData([]);
      } else {
        callOccurrenceTypeListAPI(currentPage, itemsPerPage);
      }
    } else {
      if (totalItemsOnPage === 1 && currentPage > 0) {
        handleSearchApi(currentPage - 1, itemsPerPage);
      } else if (totalItemsOnPage === 1 && currentPage == 0) {
        setOccurrenceData([]);
      } else {
        handleSearchApi(currentPage, itemsPerPage);
      }
    }
  };

  return (
    <Layout
      title={t("fieldOccurrence.pageTitle")}
      background="#ffffff"
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      currentPage={currentPage}
      paginate={paginate}
      useNewPagination={true}
    >
      <Box display="" alignItems="center" mt={4} mb={6}>
        <PaperContainer background="#EFEFEF">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              paddingBottom: "10px",
              marginTop: "-20px",
              marginLeft: "-5px",
            }}
          >
            <ExpandMoreIcon sx={{ color: "#000" }} />
            <Typography sx={{ fontSize: "14px !important" }}>
              {t("filtersLable")}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={10}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={5}>
                    <TextField
                      name="description"
                      label={t(
                        "fieldOccurrence.fieldOccurrenceTypeDescription"
                      )}
                      value={searchParams.description}
                      onChange={handleChange}
                      sx={{ width: "100%", height: "46px" }}
                      // SelectProps={{
                      //   IconComponent: ArrowDropDownIcon,
                      //   endAdornment: searchParams.description && (
                      //     <CloseIcon
                      //       onClick={() => handleClear("description")}
                      //       style={{
                      //         cursor: "pointer",
                      //         marginRight: "12px",
                      //       }}
                      //     />
                      //   ),
                      // }}
                    >
                      {/* <MenuItem onClick={()=>  callSupplyTypeListAPI(currentPage, itemsPerPage)} value="">
                <em>None</em>
              </MenuItem> */}
                      {/* {dropDownItems.map((item, index) => (
                <MenuItem key={index} value={item.supplyTypeDescription}>
                  {item.supplyTypeDescription}
                </MenuItem>
              ))} */}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl sx={{ width: "100%" }}>
                      <TextField
                        select
                        name="category"
                        label={t("fieldOccurrence.occurrenceCategory")}
                        value={searchParams.category || "PL"}
                        onChange={handleChange}
                        variant="outlined"
                        sx={{
                          width: "100%",
                          height: "46px",
                        }}
                        SelectProps={{
                          IconComponent: ArrowDropDownIcon,
                          endAdornment: searchParams?.category && (
                            <CloseIcon
                              onClick={() => handleClear("category")}
                              style={{
                                width: "18px",
                                cursor: "pointer",
                                marginRight: "12px",
                              }}
                            />
                          ),
                        }}
                      >
                        <MenuItem value="PL" disabled sx={{ display: "none" }}>
                          {t("selectoption")}
                        </MenuItem>
                        <MenuItem
                          value={"Pest"}
                          sx={{
                            backgroundColor:
                              searchParams.category ===
                              t("fieldOccurrence.pest")
                                ? "#D3D3D3 !important"
                                : "transparent !important",
                            "&:hover": {
                              backgroundColor: "#D3D3D3 !important",
                            },
                          }}
                        >
                          {" "}
                          {t("fieldOccurrence.pest")}{" "}
                        </MenuItem>
                        <MenuItem
                          value={"Disease"}
                          sx={{
                            backgroundColor:
                              searchParams.category ===
                              t("fieldOccurrence.disease")
                                ? "#D3D3D3 !important"
                                : "transparent !important",
                            "&:hover": {
                              backgroundColor: "#D3D3D3 !important",
                            },
                          }}
                        >
                          {" "}
                          {t("fieldOccurrence.disease")}{" "}
                        </MenuItem>
                        <MenuItem
                          value={"Climate"}
                          sx={{
                            backgroundColor:
                              searchParams.category ===
                              t("fieldOccurrence.climate")
                                ? "#D3D3D3 !important"
                                : "transparent !important",
                            "&:hover": {
                              backgroundColor: "#D3D3D3 !important",
                            },
                          }}
                        >
                          {" "}
                          {t("fieldOccurrence.climate")}{" "}
                        </MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl sx={{ width: "100%" }}>
                      <TextField
                        select
                        name="status"
                        label={t("status")}
                        sx={{ width: "100%", height: "46px" }}
                        value={searchParams.status || "PL"}
                        onChange={handleChange}
                        SelectProps={{
                          IconComponent: ArrowDropDownIcon,
                          endAdornment: searchParams?.status && (
                            <CloseIcon
                              onClick={() => handleClear(t("status"))}
                              style={{
                                width: "18px",
                                cursor: "pointer",
                                marginRight: "12px",
                              }}
                            />
                          ),
                        }}
                      >
                        <MenuItem value="PL" disabled sx={{ display: "none" }}>
                          {t("selectoption")}
                        </MenuItem>
                        <MenuItem
                          value={t("Active")}
                          sx={{
                            backgroundColor:
                              searchParams.status === "Active"
                                ? "#D3D3D3 !important"
                                : "transparent !important",
                            "&:hover": {
                              backgroundColor: "#D3D3D3 !important",
                            },
                          }}
                        >
                          {t("Active")}
                        </MenuItem>
                        <MenuItem
                          value={t("Inactive")}
                          sx={{
                            backgroundColor:
                              searchParams.status === "Inactive"
                                ? "#D3D3D3 !important"
                                : "transparent !important",
                            "&:hover": {
                              backgroundColor: "#D3D3D3 !important",
                            },
                          }}
                        >
                          {t("Inactive")}
                        </MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={2} textAlign={"right"}>
                <Button
                  sx={{
                    // borderRadius: 100,
                    minWidth: 95,
                    minHeight: 44,
                    background: "#2F6FAF",
                    marginTop: "8px",
                  }}
                  variant="contained"
                  startIcon={<AddCircleIcon />}
                  onClick={() => navigate("/addOccurrenceType")}
                >
                  {t("addNew")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </PaperContainer>

        {!loading && occurrenceData && occurrenceData.length === 0 && (
          <Typography variant="h6" align="center" color="textSecondary">
            {label.noDataMessage}
          </Typography>
          // <Box
          //   sx={{
          //     display: "flex",
          //     justifyContent: "center",
          //     height: "40vh",
          //     // alignItems: "center",
          //   }}
          // >
          //   <Box>{label.noDataMessage}</Box>
          // </Box>
        )}

        {!loading && occurrenceData && occurrenceData.length > 0 && (
          <OccurrenceTable
            occurrenceData={occurrenceData}
            callOccuranceApi={refreshPage}
          />
        )}
      </Box>
    </Layout>
  );
};

const PaperContainer = styled(Paper)`
  padding: 2rem 1rem 1rem 1rem;
  background: ${(props) =>
    props.background || props.theme.palette.background.default};
`;

export default FieldOccurenceList;
