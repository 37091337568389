import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  IconButton,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ConfirmationModal from "./ConfirmationModal";
import SaveButton from "./SaveButton";
import { SnackbarContext } from "context/snackBarContext";
import RefreshIcon from "@mui/icons-material/Refresh";
import HistoryIcon from "@mui/icons-material/History";
import ProductionTargetsService from "services/ProductionTargetsService";
import { useTranslation } from 'react-i18next';

const StyledTableContainer = styled(TableContainer)({
  boxShadow: "none",
  border: "1px solid #E5E7EB",
  borderRadius: "8px",
  overflow: "hidden",
  maxWidth: "1880px",
  margin: "0 auto",
});

const StyledTable = styled(Table)({
  borderCollapse: "separate",
  borderSpacing: 0,
});

const HeaderCell = styled(TableCell)({
  backgroundColor: "#E9EBEE",
  color: "#3B454F",
  fontFamily: "Montserrat",
  fontWeight: 600,
  fontSize: "20px",
  textTransform: "uppercase",
  textAlign: "center",
  padding: "13px 20px",
  borderBottom: "none",
});

const SubHeaderCell = styled(TableCell)({
  backgroundColor: "#FFFFFF",
  color: "#3B454F",
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "16px",
  padding: "13px 20px",
  borderBottom: "1px solid #E9EBEE",
  borderRight: "1px solid #E9EBEE",
  "&:last-child": {
    borderRight: "none",
  },
});

const DataCell = styled(TableCell)({
  fontFamily: "Montserrat",
  fontWeight: 400,
  fontSize: "15px",
  color: "#3B454F",
  padding: "13px 20px",
  borderBottom: "1px solid #E9EBEE",
  borderRight: "1px solid #E9EBEE",
  "&:last-child": {
    borderRight: "none",
  },
});

const TotalCell = styled(TableCell)({
  backgroundColor: "#E9EBEE",
  color: "#000000",
  fontFamily: "Montserrat",
  fontWeight: 700,
  fontSize: "16px",
  padding: "13px 20px",
  borderBottom: "none",
});

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    padding: "13px 0",
    fontFamily: "Montserrat",
    fontSize: "15px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
});

const ViewHistoryButton = styled(Button)({
  fontFamily: "Montserrat",
  fontWeight: 600,
  fontSize: "14px",
  textTransform: "uppercase",
  marginTop: "20px",
});

export default function ProductionTargetsTable({
  data,
  cropYearUUID,
  tobaccoTypeUUID,
  ihId,
  onSave,
  hierarchyOptions,
  showSaveButton = true,
}) {
  const [tableData, setTableData] = useState([]);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [totals, setTotals] = useState({
    contractTarget: 0,
    contractActual: 0,
    productivityTarget: 0,
    productivityActual: 0,
    hectaresTarget: 0,
    hectaresActual: 0,
  });
  const [loading, setLoading] = useState(false);
  const [summaryValues, setSummaryValues] = useState({
    contractAvailable: 0,
    contractTarget: 0,
    productivityAvailable: 0,
    productivityTarget: 0,
    hectaresAvailable: 0,
    hectaresTarget: 0,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [distributionHistory, setDistributionHistory] = useState([]);
  const { t } = useTranslation();

  // Initialize table data when API data changes
  useEffect(() => {
    if (data) {
      setTableData(data.targetDistributionDtoList || []);
      calculateTotals(data.targetDistributionDtoList || []);

      // Calculate sum of all targets from targetDistributionDtoList
      const distributionTotals = (data.targetDistributionDtoList || []).reduce(
        (acc, row) => ({
          contractTarget:
            acc.contractTarget + (Number(row.contractTarget) || 0),
          productivityTarget:
            acc.productivityTarget + (Number(row.productivityTarget) || 0),
          hectaresTarget:
            acc.hectaresTarget + (Number(row.hectaresTarget) || 0),
        }),
        {
          contractTarget: 0,
          productivityTarget: 0,
          hectaresTarget: 0,
        }
      );

      // Calculate available values by subtracting distribution totals from overall targets
      setSummaryValues({
        contractAvailable:
          (Number(data.contractTarget) || 0) -
          distributionTotals.contractTarget,
        contractTarget: Number(data.contractTarget) || 0,
        productivityAvailable:
          (Number(data.productivityTarget) || 0) -
          distributionTotals.productivityTarget,
        productivityTarget: Number(data.productivityTarget) || 0,
        hectaresAvailable:
          (Number(data.hectaresTarget) || 0) -
          distributionTotals.hectaresTarget,
        hectaresTarget: Number(data.hectaresTarget) || 0,
      });
    }
  }, [data]);

  const calculateTotals = (currentData) => {
    const newTotals = currentData.reduce(
      (acc, row) => ({
        contractTarget: acc.contractTarget + (Number(row.contractTarget) || 0),
        contractActual: acc.contractActual + (Number(row.contractActual) || 0),
        hectaresTarget: acc.hectaresTarget + (Number(row.hectaresTarget) || 0),
        hectaresActual: acc.hectaresActual + (Number(row.hectaresActual) || 0),
        productivityTargetCount: acc.productivityTargetCount + (Number(row.productivityTarget) ? 1 : 0),
        productivityActualCount: acc.productivityActualCount + (Number(row.productivityActual) ? 1 : 0),
        productivityTargetSum: acc.productivityTargetSum + (Number(row.productivityTarget) || 0),
        productivityActualSum: acc.productivityActualSum + (Number(row.productivityActual) || 0),
      }),
      {
        contractTarget: 0,
        contractActual: 0,
        hectaresTarget: 0,
        hectaresActual: 0,
        productivityTargetCount: 0,
        productivityActualCount: 0,
        productivityTargetSum: 0,
        productivityActualSum: 0,
      }
    );

    // Calculate averages for productivity
    newTotals.productivityTarget = newTotals.productivityTargetCount
      ? newTotals.productivityTargetSum / newTotals.productivityTargetCount
      : 0;
    newTotals.productivityActual = newTotals.productivityActualCount
      ? newTotals.productivityActualSum / newTotals.productivityActualCount
      : 0;

    setTotals(newTotals);
  };

  const handleInputChange = (index, field, value) => {
    const updatedData = [...tableData];
    const row = { ...updatedData[index] };

    // Convert value to number and handle invalid inputs
    const numericValue = value === '' ? 0 : parseFloat(value) || 0;
    
    // Update the changed field
    row[field] = numericValue;

    // Recalculate dependent fields
    if (field === "contractTarget" || field === "productivityTarget") {
      const contract = parseFloat(row.contractTarget) || 0;
      const productivity = parseFloat(row.productivityTarget) || 0;
      
      // Calculate hectares only if both values are valid and productivity is non-zero
      if (productivity > 0) {
        row.hectaresTarget = (contract * 1000) / productivity;
      } else {
        row.hectaresTarget = 0;
      }
    }

    if (field === "contractActual" || field === "productivityActual") {
      const contract = parseFloat(row.contractActual) || 0;
      const productivity = parseFloat(row.productivityActual) || 0;
      
      // Calculate hectares only if both values are valid and productivity is non-zero
      if (productivity > 0) {
        row.hectaresActual = (contract * 1000) / productivity;
      } else {
        row.hectaresActual = 0;
      }
    }

    updatedData[index] = row;
    setTableData(updatedData);

    calculateTotals(updatedData);

    const distributionTotals = updatedData.reduce(
      (acc, row) => ({
        contractTarget: acc.contractTarget + (Number(row.contractTarget) || 0),
        productivityTarget: acc.productivityTarget + (Number(row.productivityTarget) || 0),
        hectaresTarget: acc.hectaresTarget + (Number(row.hectaresTarget) || 0),
      }),
      {
        contractTarget: 0,
        productivityTarget: 0,
        hectaresTarget: 0,
      }
    );

    setSummaryValues((prev) => ({
      ...prev,
      contractAvailable: (Number(data.contractTarget) || 0) - distributionTotals.contractTarget,
      productivityAvailable: (Number(data.productivityTarget) || 0) - distributionTotals.productivityTarget,
      hectaresAvailable: (Number(data.hectaresTarget) || 0) - distributionTotals.hectaresTarget,
    }));
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      if (!data?.productionTargetId) {
        console.error('Missing production target ID');
        setMessage("Missing production target ID");
        setSeverity("error");
        setOpenAlert(true);
        return;
      }

      const currentDateTime = new Date().toISOString();
      const currentUser = "currentUser"; // Replace with actual user info

      // Create a map of hierarchy levels for each ihId
      const hierarchyLevels = {};
      hierarchyOptions.forEach(option => {
        hierarchyLevels[option.value] = option.level;
      });

      // Find parent distributions for each level
      const parentDistributions = {};
      tableData.forEach(dist => {
        const level = hierarchyLevels[dist.ihId];
        if (level === 0) {
          parentDistributions[0] = dist.ptdId;
        } else if (level === 1) {
          parentDistributions[1] = dist.ptdId;
        }
      });

      // Map table data to match API structure
      const distributionPayload = tableData.map(dist => {
        const level = hierarchyLevels[dist.ihId];
        let parentDistributionUUID = null;
        
        if (level === 1) {
          parentDistributionUUID = parentDistributions[0];
        } else if (level === 2) {
          parentDistributionUUID = parentDistributions[1];
        }

        // Ensure all numeric values are properly converted and defaulted to 0
        const contractTarget = parseFloat(dist.contractTarget) || 0;
        const contractActual = parseFloat(dist.contractActual) || 0;
        const productivityTarget = parseFloat(dist.productivityTarget) || 0;
        const productivityActual = parseFloat(dist.productivityActual) || 0;
        const hectaresTarget = parseFloat(dist.hectaresTarget) || 0;
        const hectaresActual = parseFloat(dist.hectaresActual) || 0;

        return {
          isDelete: false,
          createdDateTimeUTC: dist.createdDateTimeUTC || currentDateTime,
          createdByUser: dist.createdByUser || currentUser,
          modifiedDateTimeUTC: currentDateTime,
          modifiedByUser: currentUser,
          ptdId: dist.ptdId,
          productionTargetUUID: data.productionTargetId,
          ihId: dist.ihId,
          hierarchyName: dist.hierarchyName,
          contractTarget,
          contractActual,
          productivityTarget,
          productivityActual,
          hectaresTarget,
          hectaresActual,
          parentDistributionUUID
        };
      });

      // Calculate totals for the main payload
      const totals = tableData.reduce((acc, row) => ({
        contractTarget: acc.contractTarget + (parseFloat(row.contractTarget) || 0),
        productivityTarget: acc.productivityTarget + (parseFloat(row.productivityTarget) || 0),
        hectaresTarget: acc.hectaresTarget + (parseFloat(row.hectaresTarget) || 0),
      }), {
        contractTarget: 0,
        productivityTarget: 0,
        hectaresTarget: 0
      });

      // Construct the final payload
      const payload = {
        isDelete: false,
        createdDateTimeUTC: data.createdDateTimeUTC || currentDateTime,
        createdByUser: data.createdByUser || currentUser,
        modifiedDateTimeUTC: currentDateTime,
        modifiedByUser: currentUser,
        productionTargetId: data.productionTargetId,
        cropYearId: cropYearUUID,
        cultureTypeId: tobaccoTypeUUID,
        ihId: ihId,
        targetDistributionDtoList: distributionPayload,
        contractTarget: parseFloat(totals.contractTarget) || 0,
        productivityTarget: (parseFloat(totals.productivityTarget) / tableData.length) || 0,
        hectaresTarget: parseFloat(totals.hectaresTarget) || 0
      };

      // Debug log
      console.log('Sending payload to API:', payload);

      // Make the API call
      await ProductionTargetsService.putPTAPI(payload);

      setMessage("Production targets saved successfully");
      setSeverity("success");
      setOpenAlert(true);

    } catch (error) {
      console.error("Error saving data:", error);
      setMessage(error.message || "Error saving production targets");
      setSeverity("error");
      setOpenAlert(true);
    } finally {
      setLoading(false);
    }
  };

  const handleHistoryClick = async (ptdId) => {
    try {
      const response = await ProductionTargetsService.getPTdistHistoryAPI(ptdId);
      setDistributionHistory(response.data.response);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetching distribution history:", error);
      setMessage("Error fetching history data");
      setSeverity("error");
      setOpenAlert(true);
    }
  };

  return (
    <>
      <StyledTableContainer component={Paper}>
        <StyledTable>
          <TableHead>
            <TableRow>
              <SubHeaderCell></SubHeaderCell>
              <HeaderCell colSpan={2}>{t('productionTargets.contract')}</HeaderCell>
              <HeaderCell colSpan={2}>{t('productionTargets.productivity')}</HeaderCell>
              <HeaderCell colSpan={2}>{t('productionTargets.hectares')}</HeaderCell>
            </TableRow>
            <TableRow>
              <SubHeaderCell></SubHeaderCell>
              <SubHeaderCell colSpan={2}>
                Available / Target {summaryValues.contractAvailable?.toFixed(2)}{" "}
                / {summaryValues.contractTarget?.toFixed(2)}
              </SubHeaderCell>
              <SubHeaderCell colSpan={2}>
                Available / Target{" "}
                {summaryValues.productivityAvailable?.toFixed(2)} /{" "}
                {summaryValues.productivityTarget?.toFixed(2)}
              </SubHeaderCell>
              <SubHeaderCell colSpan={2}>
                Available / Target {summaryValues.hectaresAvailable?.toFixed(2)}{" "}
                / {summaryValues.hectaresTarget?.toFixed(2)}
              </SubHeaderCell>
            </TableRow>
            <TableRow>
              <HeaderCell>{t('productionTargets.hierarchicalNodeColumn')}</HeaderCell>
              <SubHeaderCell>{t('productionTargets.target')}</SubHeaderCell>
              <SubHeaderCell>{t('productionTargets.actual')}</SubHeaderCell>
              <SubHeaderCell>{t('productionTargets.target')}</SubHeaderCell>
              <SubHeaderCell>{t('productionTargets.actual')}</SubHeaderCell>
              <SubHeaderCell>{t('productionTargets.target')}</SubHeaderCell>
              <SubHeaderCell>{t('productionTargets.actual')}</SubHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <TableRow
                key={row.ihId}
                style={{
                  backgroundColor: index % 2 === 0 ? "#F4F4F4" : "#EDEDED",
                }}
              >
                <DataCell>
                  <IconButton 
                    size="small" 
                    sx={{ mr: 1 }}
                    onClick={() => handleHistoryClick(row.ptdId)}
                  >
                    <HistoryIcon sx={{ color: "#3B454F" }} />
                  </IconButton>

                  {row.hierarchyName}
                </DataCell>
                <DataCell>
                  <StyledTextField
                    fullWidth
                    variant="outlined"
                    value={row.contractTarget || ""}
                    onChange={(e) =>
                      handleInputChange(index, "contractTarget", e.target.value)
                    }
                    type="number"
                  />
                </DataCell>
                <DataCell>{row.contractActual?.toFixed(2) || "0.00"}</DataCell>
                <DataCell>
                  <StyledTextField
                    fullWidth
                    variant="outlined"
                    value={row.productivityTarget || ""}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "productivityTarget",
                        e.target.value
                      )
                    }
                    type="number"
                  />
                </DataCell>
                <DataCell>
                  {row.productivityActual?.toFixed(2) || "0.00"}
                </DataCell>
                <DataCell>{row.hectaresTarget?.toFixed(2) || "0.00"}</DataCell>
                <DataCell>{row.hectaresActual?.toFixed(2) || "0.00"}</DataCell>
              </TableRow>
            ))}
            <TableRow>
              <TotalCell>{t('productionTargets.totals')}</TotalCell>
              <TotalCell>{totals.contractTarget?.toFixed(2)}</TotalCell>
              <TotalCell>{totals.contractActual?.toFixed(2)}</TotalCell>
              <TotalCell>
                {totals.productivityTarget?.toFixed(2)}
                <div style={{ fontSize: "10px", fontWeight: 500 }}>
                  {t('productionTargets.averageTarget')}
                </div>
              </TotalCell>
              <TotalCell>
                {totals.productivityActual?.toFixed(2)}
                <div style={{ fontSize: "10px", fontWeight: 500 }}>
                  {t('productionTargets.averageActual')}
                </div>
              </TotalCell>
              <TotalCell>{totals.hectaresTarget?.toFixed(2)}</TotalCell>
              <TotalCell>{totals.hectaresActual?.toFixed(2)}</TotalCell>
            </TableRow>
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
      {showSaveButton && (
        <>
          <div style={{ marginTop: "40px", marginBottom: "-25px" }}>
            <hr style={{ border: 'none', borderTop: '1px solid #E5E7EB' }} />
          </div>
          <SaveButton loading={loading} onSave={handleSave} />
        </>
      )}
      <ConfirmationModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        historyData={distributionHistory}
      />
    </>
  );
}
