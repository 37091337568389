import { httpGrow } from "../http-common";


const getFunding = (query) => {
  return httpGrow.get(
    `fundingGroup?pageNumber=${query.pageNumber}&pageSize=${query.size}`
  );
};
const getTobaccoType = () => {
  return httpGrow.get(`/culture/culture-type/tobacco`);
};


const getInvestmentDropdown = () => {
  return httpGrow.get(
    `investment-group/investments`
  );
};

const updateFunding = (data) => {
  return httpGrow.put(`/fundingGroup`, data);
};


const createFunding = (data) => {
  return httpGrow.post(
    `/fundingGroup`, data
  );
};

const deleteFunding = ({id}) => {
  return httpGrow.delete(`/fundingGroup/${id}`)
}



const searchFundingData = (keyword) => {
  return httpGrow.get(`/fundingGroup/search?pageNumber=0&pageSize=50&keyword=${keyword}`);
};


const searchFundingFull = () => {
  return httpGrow.get(`/fundingGroup?pageNumber=0&pageSize=50`);
};

const getAllCuringData = (countryCode) => {
  return httpGrow.get(`curingunit/allCuringUnits?countryCode=${countryCode}`);
};

const filterManure = (status) => {
  return httpGrow.get(`/greenManure/search?pageNumber=0&pageSize=10&status=${status}`);
};

const filterFundingAutocomplete = (keyword) => {
  return httpGrow.get(`/fundingGroup/search?pageNumber=0&pageSize=10&keyword=${keyword}`);
};

const filterInvestmentKeywordAndTobacco = (keyword,tobacco) => {
  return httpGrow.get(`fundingGroup/search?pageNumber=0&pageSize=50&keyword=${keyword}&cultureType=${tobacco}`);
};

const filterInvestmentStatus = (status) => {
  return httpGrow.get(`fundingGroup/search?pageNumber=0&pageSize=50&status=${status} `);
};

const filterAll = (keyword, tobacco, status) => {
  let queryParams = [];
  
  if (keyword) queryParams.push(`keyword=${keyword}`);
  if (tobacco) queryParams.push(`cultureType=${tobacco}`);
  if (status !== undefined) queryParams.push(`status=${status}`);
  
  const queryString = queryParams.length ? `?pageNumber=0&pageSize=50&${queryParams.join('&')}` : '?pageNumber=0&pageSize=50';
  
  return httpGrow.get(`fundingGroup/search${queryString}`);
};



const filterInvestmentKeywordAndStatus = (keyword,status) => {
  return httpGrow.get(`fundingGroup/search?pageNumber=0&pageSize=50&keyword=${keyword}&status=${status}`);
};

const filterInvestmentTobaccoAndStatus = (tobacco,status) => {
  return httpGrow.get(`fundingGroup/search?pageNumber=0&pageSize=50&cultureType=${tobacco}&statuss=${status} `);
};

const filterInvestment = (tobaccoType) => {
  return httpGrow.get(`fundingGroup/search?pageNumber=0&pageSize=50&cultureType=${tobaccoType} `);
};


const searchFunding = async (query) => {
  const params = {
    pageNumber: query.pageNumber,
    pageSize: query.size,
    keyword : query.keyword,
    cultureType : query.tobaccoType,
    status : query.status == "Active" ? true : query.status == "Inactive" ? false : query.status
  };
  console.log("params",params)

  return httpGrow.get('/fundingGroup/search?', {params});
  // const response = await httpGrow.get(
  //   `/investment-group/find?pageNumber=${query?.pageNumber}&size=${query?.size || 50
  //   }&investmentGroupDescription
  //   =${query?.investmentGroupDescription || ""}&tobaccoType
  //   =${query?.tobaccoType || ""
  //   }&isActive=${query?.status !== undefined ? query.status : ""
  //   }`
  // );
  // return response;
};
///fundingGroup/search?pageNumber=0&pageSize=10&status=true

//fundingGroup/search?pageNumber=0&pageSize=10&keyword=dd&cultureType=0a2a9004-921f-157a-8192-1f987fd70000

///investment-group/find?pageNumber=0&size=50&investmentGroupDescription=ks

const FundingService = {
  getFunding,
  getTobaccoType,
  createFunding,
  getAllCuringData,
  getInvestmentDropdown,
  searchFundingData,
  updateFunding,
  deleteFunding,
  searchFundingFull,
  filterManure,
  filterFundingAutocomplete,
  filterInvestmentKeywordAndTobacco,
  filterInvestmentStatus,
  filterAll,
  filterInvestmentKeywordAndStatus,
  filterInvestmentTobaccoAndStatus,
  filterInvestment,
  searchFunding,
};
export default FundingService;

