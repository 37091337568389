import { httpGrow } from "../http-common";

const getCulture = (query) => {
  return httpGrow.get(
    `/culture?pageNumber=${query.pageNumber}&pageSize=${query.size}&countryCode=BRA`
  );
};
//   http://20.13.204.151/grow_bs/culture?pageNumber=0&pageSize=10&countryCode=BRA


const getCultureUniqueCode = () => {
  return httpGrow.get(`/culture/UniqueCultureCode`);
};
// const getCultureUniqueCode = () => {
//   return httpGrow.get(`/curingunit/CuringCode`);
// };


const updateCulture = (data) => {
  return httpGrow.put(`/culture`, data);
};

const createCulture = (data) => {
  return httpGrow.post(
    `/culture`,data
  );
};      

const getCultureById = ({id}) => {
  return httpGrow.get(
    `/culture/{cuCultureId}?cuCultureId=${id}`
  
  );
};
       // http://20.13.204.151/grow_bs/culture   

// const searchCulture = (searchParams) => {
//   return httpGrow.get('/curingunit/search', { params: searchParams });
// };

const getAllCultureData = (countryCode) => {
  return httpGrow.get(`http://20.13.204.151/grow_bs/curingunit/allCuringUnits?countryCode=${countryCode}`);
};

const getMenuItems =async ()=>{
 
   const response =await httpGrow.get('/culture/cultureNames-and-Id')
 
   return response
}
const getMenuItemsById =async (cuCultureId)=>{
   
   const response =await httpGrow.get(`/culture/{cuCultureId}?cuCultureId=${cuCultureId}`)
   
   return response
}


const CultureService = {
  getCulture,
  getCultureUniqueCode,
  updateCulture,
  createCulture,
  // searchCulture,
  getAllCultureData,
  getMenuItems,
  getMenuItemsById,
  getCultureById

};

export default CultureService;



