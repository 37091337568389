import { Box, Button, CircularProgress, Grid, Modal, Typography } from "@mui/material";
import Layout from "components/structure/Layout";
import arrowLeft from "assets/arrowLeft.svg";
import saveIcon from "assets/saveIcon.svg";
import * as Yup from "yup";
import { useState, useEffect, useContext } from "react";
import RenderField from "utils/RenderField";
import { useLocation, useNavigate } from "react-router-dom";
import { SnackbarContext } from "context/snackBarContext";
import { AuthData } from "auth/AuthWarpper";
import SpacingServices from "services/SpacingServices";
import ExpandMoreIcon from "@mui/icons-material/ArrowDropDown";
import { useTranslation } from "react-i18next";

const AddSpacing = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { user } = AuthData();
  const [open, setOpen] = useState(false);
  const { edit, spaceRegistrationUid, details, viewOnly } = location.state || {};

  const isDisabled = location.state ? (viewOnly || !edit) : false;

  const initialSpacingField = [
    {
      name: "geneticType",
      label: t("spacingRegistration.geneticType"),
      type: "select",
      options: [],
      required: true,
      disabled: isDisabled
    },
    {
      name: "unitOfMeasure",
      label: t("spacingRegistration.unitOfMeasure"),
      type: "select",
      options: [],
      required: true,
      disabled: isDisabled
    },
    {
      name: "lineSpacing",
      label: t("spacingRegistration.lineSpacing"),
      type: "text",
      required: true,
      disabled: isDisabled
    },
    {
      name: "plantSpacing",
      label: t("spacingRegistration.plantSpacing"),
      type: "text",
      required: true,
      disabled: isDisabled
    },
    {
      name: "noOfPlants",
      label: t("spacingRegistration.numberOfPlants"),
      type: "number",
      unit: "K Plants/Ha",
      required: true,
      disabled: isDisabled
    },
    {
      name: "status",
      label: t("spacingRegistration.status"),
      type: "switch",
      sx: { pt: 0 },
      disabled: isDisabled
    },
  ];

  const editSpacingField = [
    {
      name: "geneticType",
      label: t("spacingRegistration.geneticType"),
      type: "select",
      options: [],
      required: true,
    },
    {
      name: "unitOfMeasure",
      label: t("spacingRegistration.unitOfMeasure"),
      type: "select",
      options: [],
      required: true,
    },
    {
      name: "lineSpacing",
      label: t("spacingRegistration.lineSpacing"),
      type: "text",
      required: true,
    },
    {
      name: "plantSpacing",
      label: t("spacingRegistration.plantSpacing"),
      type: "text",
      required: true,
    },
    {
      name: "noOfPlants",
      label: t("spacingRegistration.numberOfPlants"),
      type: "number",
      unit: "K Plants/Ha",
      required: true,
    },
    {
      name: "status",
      label: t("spacingRegistration.status"),
      type: "switch",
      sx: { pt: 0 },
    },
  ];

  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [values, setValues] = useState({});
  console.log("location?.state?.row", location?.state?.row);
  const [errors, setErrors] = useState({});
  const [dateError, setDateError] = useState(false);
  const [spacingField, setSpacingField] = useState(
    edit === true ? editSpacingField : initialSpacingField
  );
  console.log("🚀 ~ AddSpacing ~ spacingField:", spacingField);
  const [loading, setLoading] = useState(false);
  const [geneticType, setGeneticType] = useState([]);
  const [unitOfMeasure, setUnitOfMeasure] = useState([]);

  const fetchGeneticType = async () => {
    SpacingServices.geneticType()
      .then((response) => {
        const geneticData = response.data.response
          .filter((item) => !item.isDelete)
          .map((item) => ({
            value: item.gtGeneticTypeId,
            label: `${item.gtGeneticAbbreviation} - ${item.gtGeneticDescription}`,
          }));

        setGeneticType(geneticData);

        // Update spacing field with the new options
        setSpacingField((prevFields) => {
          return prevFields.map((field) => {
            if (field.name === "geneticType") {
              return { ...field, options: geneticData };
            }
            return field;
          });
        });
      })
      .catch((error) => console.error("Error fetching genetic types:", error));
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleDeleteConfirm = () => {
    setOpen(false);
    navigate("/spacingRegistration");
  };

  const fetchUnitOfMeasure = async () => {
    SpacingServices.getUnitMeasurment()
      .then((response) => {
        const unitData = response.data.response.map((item) => ({
          value: item.unitOfMeasurementUid,
          label: `${item.description}`,
        }));
        setUnitOfMeasure(unitData);

        // Update spacing field with the new options
        setSpacingField((prevFields) => {
          return prevFields.map((field) => {
            if (field.name === "unitOfMeasure") {
              return { ...field, options: unitData };
            }
            return field;
          });
        });
      })
      .catch((error) =>
        console.error("Error fetching unit of measure:", error)
      );
  };

  useEffect(() => {
    if (location.state?.row) {
      const row = location.state.row;
      setValues({
        geneticType: row.geneticTypeId,
        unitOfMeasure: row.unitOfMeasurementId,
        noOfPlants: row.noOfPlant,
        plantSpacing: row.plantSpacing,
        lineSpacing: row.lineSpacing,
        status: row.isActive
      });
      
      // Add these fields to spacingField for display purposes
      const updatedSpacingField = spacingField.map(field => {
        if (field.name === "geneticType") {
          return {
            ...field,
            defaultValue: `${row.geneticAbbreviation} - ${row.geneticDescription}`
          };
        }
        if (field.name === "unitOfMeasure") {
          return {
            ...field,
            defaultValue: row.unitDescription
          };
        }
        return field;
      });
      
      setSpacingField(updatedSpacingField);
      fetchGeneticType();
      fetchUnitOfMeasure();
    } else {
      fetchGeneticType();
      fetchUnitOfMeasure();
    }
  }, [location.state]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const payload = {
        createdByUser: "Mayur",
        modifiedByUser: "Mayur",
        geneticTypeId: values.geneticType,
        unitOfMeasurementId: values.unitOfMeasure,
        lineSpacing: values.lineSpacing,
        plantSpacing: values.plantSpacing,
        isActive: values.status || false,
        delete: false,
        noOfPlant: values.noOfPlants?.toString(),
        countryCode: user?.countryCode || "BRA",
      };

      const response = await SpacingServices.create(payload);
      setLoading(false);

      if (response.status === 201) {
        navigate("/spacingRegistration");
        setSeverity("success");
        setMessage(t("snackbarMessage.successAdd"));
        setOpenAlert(true);
      } else {
        setSeverity("error");
        setMessage(t("snackbarMessage.submissionFailed"));
        setOpenAlert(true);
      }
    } catch (err) {
      setLoading(false);
      if (err.response && err.response.status === 409) {
        setSeverity("error");
        setMessage(
          `${
            err.response.data.message || "Spacing registration already exists"
          }`
        );
        setOpenAlert(true);
      } else if (err instanceof Yup.ValidationError) {
        let newError = {};
        err?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setErrors(newError);
      } else {
        console.error("An error occurred while submitting the form:", err);
        setSeverity("error");
        setMessage("An error occurred while submitting the form");
        setOpenAlert(true);
      }
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const payload = {
        createdByUser: "Mayur",
        modifiedByUser: "Mayur",
        geneticTypeId: values.geneticType,
        unitOfMeasurementId: values.unitOfMeasure,
        lineSpacing: values.lineSpacing,
        plantSpacing: values.plantSpacing,
        isActive: values.status || false,
        delete: false,
        noOfPlant: values.noOfPlants?.toString(),
        countryCode: user?.countryCode || "BRA",
        spacingRegistrationUid: location.state?.row?.spacingRegistrationUid,
      };

      const response = await SpacingServices.update(payload);
      setLoading(false);

      if (response.status === 200) {
        navigate("/spacingRegistration");
        setSeverity("success");
        setMessage(t("snackbarMessage.updatedSuccessfully"));
        setOpenAlert(true);
      } else {
        setSeverity("error");
        setMessage(t("snackbarMessage.updateFailed"));
        setOpenAlert(true);
      }
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        let newError = {};
        err?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setErrors(newError);
      } else {
        console.error("An error occurred while submitting the form:", err);
        setSeverity("error");
        setMessage("An error occurred while submitting the form");
        setOpenAlert(true);
      }
    }
  };

  const handleClear = (field) => {
    setValues((prevValues) => ({
      ...prevValues,
      [field]: "",
    }));
  };

  return (
    <Layout background={"#ffffff"} title="Spacing Registration">
      <Grid
        container
        sx={{
          minHeight: 170,
          width: "100%",
          "& .MuiTextField-root": {
            width: "100%",
            m: 0,
            color: "green !important",
          },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
            "& .Mui-focused": {
              color: "red !important",
            },
          },
        }}
      >
        <form
          autoComplete="off"
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            if (location.state?.edit) {
              handleUpdate(e);
            } else {
              handleSubmit(e);
            }
          }}
          style={{ width: "100%" }}
        >
          <Grid
            container
            sx={{
              width: "100%",
              backgroundColor: "#EFEFEF",
              height: "170px",
              borderRadius: "8px",
              padding: "16px",
              margin: 0,
            }}
          >
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <ExpandMoreIcon sx={{ color: "#000", marginLeft: "8px" }} />
                {t("form")}
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ width: "100%", mt: 0 }}>
              <Grid container spacing={2} sx={{ width: "100%", m: 0 }}>
                {spacingField.slice(0, 7).map((field) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={2}
                    key={field.name}
                    sx={{
                      width: "100%",
                      "& .MuiFormControl-root":
                        field.name === "status"
                          ? {
                              paddingTop: "0 !important",
                            }
                          : {},
                    }}
                  >
                    <RenderField
                      field={field}
                      values={values}
                      errors={errors}
                      handleChange={handleChange}
                      handleClear={handleClear}
                      details={details}
                      disabled={isDisabled}
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                position: "fixed",
                borderTop: "1px solid #E9EBEE",
                bottom: 30,
                padding: "5px",
                left: 0,
                right: 0,
                px: 2,
              }}
            >
              <Button
                style={{
                  backgroundColor: "#737D86",
                  padding: " 6px, 16px, 6px, 16px",
                  borderRadius: " 4px",
                }}
                startIcon={<img src={arrowLeft} alt="Arrow" />}
                variant="contained"
                type="button"
                onClick={handleOpenModal}
                disabled={loading}
              >
                {loading && <CircularProgress size={20} />}
                {t("cancel")}
              </Button>

              <Modal
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "7px",
                    width: "585px",
                    margin: "auto",
                    mt: 25,
                    height: "200px",
                  }}
                >
                  <Box
                    sx={{
                      background: "#102A62",
                      height: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderTopLeftRadius: "7px",
                      borderTopRightRadius: "7px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#ffffff",
                        fontSize: "20px",
                        fontWeight: 500,
                      }}
                    >
                      {t("cancelTitle")}
                    </Typography>
                  </Box>
                  <Box sx={{ p: 2 }}>
                    <Typography
                      id="modal-modal-description"
                      sx={{ mt: 2, fontWeight: 400 }}
                    >
                      {t("cancelMessage")}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "20px",
                      mb: 1,
                    }}
                  >
                    <Button
                      onClick={handleCloseModal}
                      sx={{
                        background: "#737D86",
                        width: "100px",
                        color: "#ffffff",
                        "&:hover": { background: "#737D86" },
                      }}
                    >
                      {t("no")}
                    </Button>
                    <Button
                      onClick={handleDeleteConfirm}
                      sx={{
                        background: "#004F9F",
                        width: "100px",
                        color: "#ffffff",
                        "&:hover": { background: "#004F9F" },
                      }}
                    >
                      {t("yes")}
                    </Button>
                  </Box>
                </Box>
              </Modal>
              <Button
                sx={{
                  backgroundColor: "#EF7D00",
                  padding: " 6px, 16px, 6px, 11px",
                  borderRadius: " 4px",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#EF7D00",
                  },
                }}
                disabled={details || loading}
                startIcon={<img src={saveIcon} alt="save icon" />}
                type="submit"
                variant="contained"
              >
                {loading && <CircularProgress size={20} />}
                {t("save")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Layout>
  );
};

export default AddSpacing;
