import React, { useContext, useEffect, useState } from "react";
import { Typography, Box, Button, Menu, MenuItem } from "@mui/material";
import "components/pages/Growing/Curing/Curing.css";
import { useNavigate } from "react-router-dom";
import EditButton from "../../../../../../assets/editButton.svg";
import moreActions from "../../../../../../assets/moreActions.svg"
import deleteIcon from "../../../../../../assets/iconDelete.svg";
import iconView from "assets/iconView.svg";
import { SnackbarContext } from "context/snackBarContext";
import FarmerDataService from "services/FarmerService";


const FarmerTable = (props) => {
  const [farmerData, setFarmerData] = useState([]);
  console.log("🚀 ~ FarmerTable ~ farmerData:", farmerData)

  useEffect(() => {
    setFarmerData(props.farmerData);
  }, [props.farmerData]);

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = React.useState({});

  const handleMenuClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(data)
  };

  const handleMenuClose = ({ action, data }) => {
    if (!action || action == undefined) return
    setSelectedRow(data)
    if (action == 'details') {
      navigate(`/editFarmerRegistration/${data.farmerId}`, {
        state: {
          details: true
        }
      });
    } else if (action == 'edit') {
      navigate(`/editFarmerRegistration/${data.farmerId}`, {
        state: {
          edit: true
        }
      });
    } else if (action === 'delete') {      
      handleDeleteFarmer(data.farmerId)
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    setFarmerData(props.farmerData);
  }, [props.farmerData]);

  const {setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const handleDeleteFarmer = (deleteId) => {    
    // FarmerDataService.remove(deleteId)
    //   .then((res) => {        
    //     setFarmerData((prevData) => prevData.filter((data) => data.farmerId !== deleteId));
    //     setMessage("Farmer deleted successfully.");
    //     setSeverity("success");
    //     setOpenAlert(true);
    //     // props.refreshData();
    //     console.log("deleteID", deleteId)
    //   })
    //   .catch((error) => {
    //     setMessage(error?.response?.data?.message || error?.message);
    //     setSeverity("error");
    //     setOpenAlert(true);
    //   });
  };

  return (
    <div className="table-container">
      <table className="full-width-table">
        {" "}
        {/* Add a class for styling */}
        <thead>
          <tr className="HierarchyHead">
            <th>Farmer Code</th>
            <th>Farmer Name</th>
            <th>Region </th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {farmerData?.map((row, index) => (
            <tr className="hierarchyColumn" key={index} style={{}}>
              <td style={{ textAlign: "center" }}>
                <Typography
                  sx={{
                    justifyContent: "left",
                    fontWeight: 600,
                    color: "#3B454F",
                  }}
                >
                  {row.farmerCode}
                </Typography>
              </td>
              <td style={{ textAlign: "center" }}>
                <Box
                  sx={{
                    whiteSpace: "nowrap",
                    paddingLeft: "5px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontWeight: 600,
                    color: "#3B454F",
                  }}
                >
                  <span title={row.farmerFullName}>
                    {/* { > 20
                        ? row.hlcLevelName.substring(0, 20) + "..."
                        : row.hlcLevelName} */}
                    {row.farmerFullName}
                  </span>
                </Box>
              </td>
              <td style={{ textAlign: "center" }}>
                <div
                  className="hierarchyConfig"
                  style={{
                    fontWeight: 600,
                    color: "#3B454F",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {row.region}
                </div>
              </td>
              <td
                style={{
                  textAlign: "center",
                  fontWeight: 600,
                  color: "#3B454F",
                }}
              >
                {row.status ? "Active" : "Inactive"}
              </td>
              <td
                style={{
                  textAlign: "center",
                }}
              >
                {/* <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <EditButton
                    url= {`/editFarmerRegistration/${row.farmerId}`}
                  
                  />
                </div> */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingRight: "20px",
                    cursor: "pointer",
                  }}
                >
                  <img
                    style={{ maxHeight: '40px' }}
                    src={EditButton}
                    alt="Edit Button"
                    onClick={() => handleMenuClose({ action: 'edit', data: row, tab: 1 })}
                  />
                  <Button
                    variant="outlined"
                    className="moreButton"
                    style={{ marginLeft: "10px", maxHeight: "40px" }}
                    onClick={(e) => handleMenuClick(e, row)}
                  >
                    <div>
                      <img src={moreActions} alt="more-actions" />
                    </div>{" "}
                    <div style={{ height: "14px" }}>More</div>
                  </Button>
                  <div key={index}>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={() => handleMenuClose({ action: 'add', data: row, tab: 0 })}
                      key={index}
                    >
                      <div className="" >
                        <MenuItem className="gridActionCell" sx={{ border: '1px solid #50AF47 !important', backgroundColor: "#0AD0AD1F", display: "flex", justifyContent: "space-around", cursor: "ponter" }}
                          onClick={() => handleMenuClose({ action: 'details', data: selectedRow, tab: 1 })}
                        >
                          <img src={iconView} height="30px" width="30px" />
                          <div
                            style={{
                              display: "flex",
                              height: "14px",
                              color: "#50AF47",
                            }}
                          >
                            Details
                          </div>
                        </MenuItem>

                        <MenuItem className="gridActionCell" sx={{ border: '1px solid #FBBA00 !important', backgroundColor: "#FEF1CA", display: "flex", justifyContent: "space-around", cursor: "ponter" }}
                          onClick={() => handleMenuClose({ action: 'edit', data: selectedRow, tab: 1 })}
                        >
                          <img src={EditButton} height="30px" width="30px" />
                          <div
                            style={{
                              display: "flex",
                              height: "14px",
                              color: "#FBBA00",
                            }}
                          >
                            Edit
                          </div>
                        </MenuItem>


                        {/* <MenuItem className="gridActionCell" sx={{ border: '1px solid #EF0000 !important', backgroundColor: "#9F00001F", display: "flex", justifyContent: "space-around", cursor: "ponter" }}
                          onClick={() => handleMenuClose({ action: 'delete', data: selectedRow, tab: 1 })}
                        >
                          <img src={deleteIcon} height="30px" width="30px" />
                          <div
                            style={{
                              display: "flex",
                              height: "14px",
                              color: "#EF0000",
                            }}
                          >
                            Delete
                          </div>
                        </MenuItem> */}

                      </div>
                    </Menu>
                  </div>
                </div>

              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FarmerTable;
