import Layout from "components/structure/Layout";
import React, { useContext, useEffect, useState } from "react";
import { Button, Grid, Tab, Tabs, Typography } from "@mui/material";
import plusIcon from "../../../../src/assets/plusIcon.svg";
import { Box } from "@mui/system";
import HierarchyModal from "./HierrachyModal";
import HierarchyTable from "./HierarchyTable";
import HierarchyService from "services/HierarchyService";
import { SnackbarContext } from "context/snackBarContext";
import Pagination from "./Paginationcc";
import { AuthData } from "auth/AuthWarpper";
import TargetList from "./TargetList";
import TargetListEdit from "./TargetListEdit";
import { useTranslation } from "react-i18next";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Typography component="div" style={{ padding: 8 }}>
          {children}
        </Typography>
      )}
    </div>
  );
}
const HierarchyContainer = () => {
  const { user } = AuthData();
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [hierarchyData, sethierarchyData] = useState(null);
  const [targetData, setTargetData] = useState(null);
  const [hierarchyTargetHlc, setHierarchyTargetHlc] = useState(null);
  const [targetHlcActive, setTargetHlcActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage, handleClose } =
    useContext(SnackbarContext);
  const [string, setString] = React.useState("");

  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(5);

  const [currentPageTarget, setCurrentPageTarget] = useState(0);
  const [itemsPerPageTarget, setItemsPerPageTarget] = useState(5);
  const [totalItemsTarget, setTotalItemsTarget] = useState(5);

  const [currentPageTargetHlc, setCurrentPageTargetHlc] = useState(0);
  const [itemsPerPageTargetHlc, setItemsPerPageTargetHlc] = useState(5);
  const [totalItemsTargetHlc, setTotalItemsTargetHlc] = useState(5);

  const [value, setValue] = React.useState(0);
  const [targetHlcRow, setTargetHlcRow] = React.useState("");

  useEffect(() => {
    callHierarchyAPI(currentPage, itemsPerPage);
    callTargetAPI(currentPageTarget, itemsPerPageTarget);
  }, []);

  const callHierarchyAPI = (pNumber, size) => {
    setLoading(true);
    HierarchyService.FetchAllHierarchy({ pageNumber: pNumber, size: size })
      .then((res) => {
        setCurrentPage(pNumber);
        setItemsPerPage(size);
        setLoading(false);
        sethierarchyData(res?.data?.response);
        setTotalItems(res?.data?.response?.totalItems);
      })
      .catch((error) => {
        setLoading(false);
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };
  const callTargetAPI = (pNumber, size) => {
    setLoading(true);
    HierarchyService.FetchAllTarget({ pageNumber: pNumber, size: size })
      .then((res) => {
        setCurrentPageTarget(pNumber);
        setItemsPerPageTarget(size);
        setLoading(false);
        setTargetData(res?.data?.response);
        setTotalItemsTarget(res?.data?.response?.totalItems);
      })
      .catch((error) => {
        setLoading(false);
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (value == 1) {
      // setMode("Add")
    }
  };

  const onSubmitLevel = () => {
    let level = {
      hlcLevelName: string,
      createdByUser: user ? user.userName : "",
      modifiedByUser: user ? user.userName : "",
      country: user ? user.countryCode : "",
    };
    setLoading(true);
    HierarchyService.addLevel(level)
      .then((res) => {
        setLoading(false);
        setMessage(t("snackbarMessage.successAdd"));
        setOpenAlert(true);
        setSeverity("success");
        callHierarchyAPI(currentPage, itemsPerPage);
        setModalOpen(false);
        setString("");
      })
      .catch((error) => {
        setLoading(false);
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  const paginate = (pageNumber, itemsPerPage) => {
    callHierarchyAPI(pageNumber - 1, itemsPerPage);
  };
  const paginateTarget = (pageNumber, itemsPerPage) => {
    callTargetAPI(pageNumber - 1, itemsPerPage);
  };
  const paginateTargetEditAPI = (pageNumber, itemsPerPage) => {
    callTargetEditAPI(targetHlcRow, pageNumber - 1, itemsPerPage);
  };

  const handleInputChange = (str) => {
    const regex = /^[a-zA-Z0-9]*$/;
    if (regex.test(str) || str === "") {
      setString(str);
    }
  };

  const onEditTargetHlc = (row) => {
    callTargetEditAPI(row, currentPageTargetHlc, itemsPerPageTargetHlc);
  };

  const callTargetEditAPI = (row, pNumber, size) => {
    setTargetHlcActive(true);
    setTargetHlcRow(row);
    HierarchyService.GetHierarchyTargetHlc({
      id: row?.trgId,
      pageNumber: pNumber,
      size: size,
    })
      .then((res) => {
        setHierarchyTargetHlc(res?.data?.response);
        setLoading(false);
        setCurrentPageTargetHlc(pNumber);
        setItemsPerPageTargetHlc(size);
        setTotalItemsTargetHlc(res?.data?.response?.totalItems);
      })
      .catch((error) => {
        setLoading(false);
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };
  const onCheckboxClickHlc = (status, level, item) => {
    let targetDataNew = JSON.parse(JSON.stringify(item));
    let status_new = !status;
    targetDataNew[level] = status_new;
    Object.assign(targetDataNew, {
      modifiedByUser: user?.userName,
    });
    setLoading(true);
    HierarchyService.updateDataHlc(targetDataNew)
      .then((res) => {
        if (res && res?.data?.statusCode == 200) {
          setLoading(false);
          setMessage(t("snackbarMessage.updatedSuccessfully"));
          setOpenAlert(true);
          setSeverity("success");
          onEditTargetHlc(targetHlcRow);
        }
      })
      .catch((error) => {
        setLoading(false);
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };
  return (
    <Layout title={t("hierarchyConfiguration.pageTitle")}>
      <div className={"AgriculturalSupplyContainer"} style={{ flexGrow: 1 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          className="AgriSupplyTabs"
          sx={{
            "& ,.MuiTabs-flexContainer": {
              justifyContent: "space-around",
            },
            "& .Mui-selected": {
              color: "#EF7D00 !important",
            },
            "& .MuiTabs-indicator": {
              backgroundColor: "#EF7D00 !important",
            },
            "& .MuiButtonBase-root": {
              display: "flex",
              justifyContent: "space-around !important",
              minHeight: "unset",
            },
            "& .MuiTabs-scroller": {
              padding: "10px",
            },
          }}
        >
          <Tab label={t("hierarchyConfiguration.hierarchyLevel")} id="tab-0" />
          <Tab label={t("hierarchyConfiguration.target/limits")} id="tab-1" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <div className="hierarchyConatiner">
            <Grid
              item
              xs={12}
              md={2}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <Box sx={{ right: "1.6rem", padding: "10px" }}>
                <Button
                  style={{ backgroundColor: "#004F9F", borderRadius: "30px" }}
                  variant="contained"
                  startIcon={<img src={plusIcon} />}
                  onClick={() => setModalOpen(true)}
                >
                  {t("hierarchyConfiguration.addNewLevelButton")}
                </Button>
              </Box>
            </Grid>
            <HierarchyTable
              setHierarchynull={() => sethierarchyData([])}
              callHierarchyAPI={() =>
                callHierarchyAPI(currentPage, itemsPerPage)
              }
              hierarchyData={hierarchyData}
            ></HierarchyTable>
            {!hierarchyData || hierarchyData.length == 0 ? (
              <Typography style={{ textAlign: "center", marginTop: "5%" }}>
                No Data
              </Typography>
            ) : (
              ""
            )}

            <HierarchyModal
              loading={loading}
              string={string}
              onLevelNameInput={(str) => {
                handleInputChange(str);
              }}
              onNext={() => {
                onSubmitLevel();
              }}
              open={modalOpen}
              onClose={() => {
                setModalOpen(false);
              }}
            />
          </div>
          <div style={{ position: "absolute", width: "96%", bottom: "60px" }}>
            {hierarchyData && hierarchyData?.hierarchyLevelConfig && (
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={totalItems}
                paginate={paginate}
                currentPage={currentPage}
              />
            )}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {!targetHlcActive && (
            <>
              <TargetList
                targetData={targetData}
                onEditTargetHlc={(id) => onEditTargetHlc(id)}
              />
              <div
                style={{
                  position: "absolute",
                  marginLeft: "-8px",
                  width: "100%",
                  bottom: "60px",
                }}
              >
                {targetData && targetData?.targetHLC && (
                  <Pagination
                    itemsPerPage={itemsPerPageTarget}
                    totalItems={totalItemsTarget}
                    paginate={paginateTarget}
                    currentPage={currentPageTarget}
                  />
                )}
              </div>
            </>
          )}
          {targetHlcActive && (
            <>
              <TargetListEdit
                targetHlcRow={targetHlcRow}
                hierarchyTargetHlc={hierarchyTargetHlc}
                setTargetHlcActive={() => setTargetHlcActive(false)}
                onCheckboxClickHlc={(status, level, item) =>
                  onCheckboxClickHlc(status, level, item)
                }
              />
              <div
                style={{
                  position: "absolute",
                  marginLeft: "-8px",
                  width: "100%",
                  bottom: "60px",
                }}
              >
                {targetData && targetData?.targetHLC && (
                  <Pagination
                    itemsPerPage={itemsPerPageTargetHlc}
                    totalItems={totalItemsTargetHlc}
                    paginate={paginateTargetEditAPI}
                    currentPage={currentPageTargetHlc}
                  />
                )}
              </div>
            </>
          )}
        </TabPanel>
      </div>
    </Layout>
  );
};

export default HierarchyContainer;
