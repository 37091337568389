import React from "react";
import moreActions from "assets/moreActions.svg";
import "./DataTable.css";
import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const DataTable = (props) => {
  const CustomButton = () => (
    <Button variant="outlined" className="moreButton">
      <div>
        <img src={moreActions} alt="more-actions" />
      </div>{" "}
      <div style={{ height: "14px" }}>More</div>
    </Button>
  );
  return (
    <div style={{ width: "100%" }}>
      <div style={{ height: "100vh", width: "100%" }}>
        <DataGrid disableColumnSorting disableColumnFilter disableColumnSelector
          components={{
            MoreActionsIcon: CustomButton,
          }}
          rows={props.rows}
          columns={props.columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 100 },
            },
          }}
          style={{ borderStyle: "none" }}
          pageSizeOptions={[5, 10]}
          sx={{
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            "& .MuiDataGrid-row": {
              backgroundColor: "#ffffff",
              position: "relative",
              marginTop: "10px",
              marginBottom: "10px",
              borderRadius: "10px",
              height: "4.688rem",
              boxShadow: " 0px 2px 4px 0px #00000029",
              width: "99.8%",
              maxHeight: "unset !important",
              alignItems: "center",
              justifyContent: "space-between",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "unset",
              color: "rgba(59, 69, 79, 1)",
              fontFamily: "Montserrat",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "24.38px",
              letterSpacing: "0em",
              textAlign: "left",
              outline: "none !important",
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: "#FFFFFF",
              justifyContent: "center",
            },
            "&  .MuiDataGrid-columnHeaders": {
              border: "none",
              fontFamily: "Montserrat",
              fontSize: "20px",
              fontWeight: 400,
              color: " rgba(19, 22, 25, 1)",
              lineHeight: "24.38px",
            },
            //          "& .MuiDataGrid-virtualScroller": {},
            //          "& .MuiDataGrid-root": {},
          }}
        ></DataGrid>
      </div>
    </div>
  );
};

export default DataTable;
