// GetLocalityConfiguration.jsx
import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Autocomplete,
  Checkbox,
  InputAdornment,
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
  TableContainer,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import LocalityConfigurationTable from "./LocalityConfigurationTable";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import LocalityConfigurationService from "services/LocalityConfigurationService";
import debounce from "lodash/debounce";
import Lable from "utils/lables.json";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { set } from "date-fns";
import { se } from "date-fns/locale";
import { useTranslation } from "react-i18next";

const GetLocalityConfiguration = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [menu, setMenu] = useState([]);
  const [postalCodes, setPostalCodes] = useState([]);
  const [postalCode, setPostalCode] = useState("");
  const [regionOptions, setRegionOptions] = useState("");
  const [region, setRegion] = useState("");
  const [city, setCity] = useState("");
  const [cityOptions, setCityOptions] = useState([]);
  const [showUsedRecords, setShowUsedRecords] = useState(false);

  const debouncedSearch = useCallback(
    debounce((value) => {
      fetchData(0, itemsPerPage, value);
    }, 10),
    [itemsPerPage]
  );

  const handleRegionChange = (event, newValue, reason) => {
    const value = typeof newValue === 'string' ? newValue : newValue;
    setRegion(value);
    fetchData(0, itemsPerPage, searchKeyword, postalCode, value, city);
  };

  const handleCityChange = (event, newValue, reason) => {
    const value = typeof newValue === 'string' ? newValue : newValue;
    setCity(value);
    fetchData(0, itemsPerPage, searchKeyword, postalCode, region, value);
  };

  useEffect(() => {
    fetchPostalCodes();
    fetchCities();
    fetchRegions();
    fetchData(currentPage, itemsPerPage, searchKeyword);
    if (currentPage === 0) {
      fetchMenuData();
    }
    return () => {
      debouncedSearch.cancel(); // Clean up on unmount
    };
  }, [currentPage, itemsPerPage]);

  const fetchCities = async () => {
    try {
      const res = await LocalityConfigurationService.getCityAPI();
      const cityNames = res.data.response.map((city) => city.cityName);
      setCityOptions(cityNames);
    } catch (error) {
      // Handle error
      setMessage("Error fetching cities");
      setSeverity("error");
      setOpenAlert(true);
    }
  };

  const fetchRegions = async () => {
    try {
      const res = await LocalityConfigurationService.getRegionAPI();
      const regionNames = res.data.response.map((region) => region.stateCode);
      setRegionOptions(regionNames);
    } catch (error) {
      // Handle error
      setMessage("Error fetching regions");
      setSeverity("error");
      setOpenAlert(true);
    }
  };

  const handleSearchChange = (value) => {
    setSearchKeyword(value);
    if (value !== "") {
      debouncedSearch(value);
    } else {
      resetFilters();
    }
  };

  const debouncedPostalCodeSearch = useCallback(
    debounce((value) => {
      fetchPostalCodes(0, 50, searchKeyword, value); // Update with the proper pagination if needed
    }, 10),
    []
  );

  const handlePostalCodeSearchChange = (event, newValue) => {
    // Validate if the input is a number
    if (newValue && isNaN(newValue)) {
      setMessage(t("postalCodeMustBeNumber.postalCodeMustBeNumber"));
      setSeverity("error");
      setOpenAlert(true);
      return;
    }

    setPostalCode(newValue);

    if (!newValue) {
      resetFilters();
    } else {
      debouncedPostalCodeSearch(newValue);
      // Fetch data immediately if postal code is selected
      fetchData(0, itemsPerPage, searchKeyword, newValue, region, city);
    }
  };

  const resetFilters = () => {
    setSearchKeyword("");
    setPostalCode("");
    setRegion("");
    setCity("");
    setCurrentPage(0);
    fetchRegions();
    fetchCities();
    fetchData(0, itemsPerPage); // Fetch data without any filters
  };

  const fetchData = (
    pageNumber,
    size,
    keyword = undefined,
    postalCode = undefined,
    region = undefined,
    city = undefined
  ) => {
    setLoading(true);
    const query = {
      pageNumber,
      size,
      keyword,
      postalcode: postalCode,
      stateCode: region,
      cityName: city,
    };
    LocalityConfigurationService.getAPI(query)
      .then((res) => {
        const results =
          res?.data?.response?.localityFieldTechnicianConfig || [];
        setData(results);
        setTotalItems(res?.data?.response?.totalItems || 0);
        if (results.length === 0 && keyword.length > 0) {
          setMessage("No records found.");
          setSeverity("info");
          setOpenAlert(true);
        }
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setSeverity("error");
        setOpenAlert(true);
        setData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchMenuData = () => {
    setLoading(true);
    LocalityConfigurationService.getAPI({
      pageNumber: 0,
      size: 1000, // Increased size to get more records
    })
      .then((res) => {
        const menuItems = [...new Set(
          res?.data?.response?.localityFieldTechnicianConfig?.map(
            (e) => e.localityName
          )
        )].filter(Boolean); // Remove any null/undefined values
        
        setMenu(menuItems);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setSeverity("error");
        setOpenAlert(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchPostalCodes = (
    pageNumber,
    size,
    keyword = undefined,
    postalCode
  ) => {
    const query = {
      pageNumber,
      size,
      keyword,
      postalcode: postalCode,
      // stateCode: region,
      // cityName: city,
    };
    // Call your service to fetch postal codes based on the keyword
    LocalityConfigurationService.getAPI(query)
      .then((res) => {
        setPostalCodes(
          res?.data?.response?.localityFieldTechnicianConfig?.map(
            (e) => e.localityPostalCode
          ) || []
        ); // Update the postalCodes state
        setTotalItems(res?.data?.response?.totalItems || 0);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setSeverity("error");
        setOpenAlert(true);
        setTotalItems(0);
      });
  };

  const handleShowUsedRecords = () => {
    setShowUsedRecords(!showUsedRecords);
    fetchData(0, itemsPerPage);
    fetchMenuData();
  };

  const paginate = (pageNumber, itemsPerPage) => {
    setCurrentPage(pageNumber - 1);
    setItemsPerPage(itemsPerPage);
    fetchData(pageNumber - 1, itemsPerPage);
  };

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={t("localityConfiguration.pageTitle")}
      background="#ffffff"
      useNewPagination={true}
    >
      <Grid
        container
        spacing={2}
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          p: 2,
          maxWidth: "100%",
          margin: "auto",
          paddingBottom: "8px",
        }}
      >
        <Grid item xs={12} style={{ paddingTop: "0px", paddingLeft: "0px" }}>
          <Typography variant="h8" display="flex">
            <ArrowDropDownIcon />
            {t("filtersLable")}
          </Typography>
        </Grid>

        {/* Locality search field */}
        <Grid item xs={3} style={{ paddingLeft: "0px" }}>
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              value={searchKeyword}
              onChange={(event, newValue) => handleSearchChange(newValue)}
              onInputChange={(event, newInputValue) =>
                handleSearchChange(newInputValue)
              }
              options={menu}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("locality")}
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{
                            color: "#102a62",
                            width: "42px",
                            height: "42px",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <Autocomplete
            disablePortal
            options={postalCodes}
            onChange={(event, newValue) => handlePostalCodeSearchChange(event, newValue)}
            onInputChange={(event, newValue) => {
              // Only allow numbers
              const numericValue = newValue.replace(/[^0-9]/g, '');
              handlePostalCodeSearchChange(event, numericValue);
            }}
            getOptionLabel={(option) => option.toString()}
            renderInput={(params) => (
              <TextField 
                {...params} 
                label={t("postalCode")} 
                fullWidth 
                type="number"
                inputProps={{
                  ...params.inputProps,
                  pattern: '[0-9]*'  // Only allow numbers
                }}
              />
            )}
          />
        </Grid>

        {/* Region Field */}
        <Grid item xs={3}>
          <Autocomplete
            disablePortal
            options={regionOptions || []}
            value={region}
            onChange={handleRegionChange}
            onInputChange={(event, newInputValue) => {
              handleRegionChange(event, newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("region")}
                fullWidth
                // required
              />
            )}
          />
        </Grid>

        {/* City Field */}
        <Grid item xs={3}>
          <Autocomplete
            disablePortal
            options={cityOptions || []}
            value={city}
            onChange={handleCityChange}
            onInputChange={(event, newInputValue) => {
              handleCityChange(event, newInputValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label={t("city")} fullWidth />
            )}
          />
        </Grid>

        <Grid item xs={12} style={{ paddingTop: "12px", paddingLeft: "0px" }}>
          <Typography variant="subtitle2">
            <Checkbox
              sx={{ paddingLeft: "0px" }}
              checked={showUsedRecords}
              onChange={handleShowUsedRecords}
              color="primary"
            />
            {t("usedRecords")}
          </Typography>
        </Grid>
      </Grid>

      {/* // Table Section */}
      {loading ? (
        <Typography variant="h6" align="center">
          {t("loading")}
        </Typography>
      ) : (
        <>
          {showUsedRecords ? (
            data.filter((row) => row.useThisRecord).length > 0 ? (
              <LocalityConfigurationTable
                TableData={data.filter((row) => row.useThisRecord)}
              />
            ) : (
              // Set alert if no used records found
              <>
                {setMessage("No used records found.")}
                {setSeverity("error")}
                {setOpenAlert(true)}
                <Typography variant="h6" align="center">
                  {t("notfound")}
                </Typography>
              </>
            )
          ) : (
            totalItems > 0 && <LocalityConfigurationTable TableData={data} />
          )}
        </>
      )}
    </Layout>
  );
};

export default GetLocalityConfiguration;
