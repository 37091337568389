import React, {
    createContext,
    useState,
} from "react"

export const SnackbarContext = createContext()

export function SnackbarProvider(props) {

    // Snackbar State
    const [openAlert, setOpenAlert] = useState(false)
    const [severity, setSeverity] = useState("info")
    const [message, setMessage] = useState("")

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setOpenAlert(false)
    }

    return (
        <SnackbarContext.Provider
            value={{
                openAlert,
                setOpenAlert,
                severity,
                setSeverity,
                message,
                setMessage,
                handleClose
            }}
        >
            {props.children}
        </SnackbarContext.Provider>
    )
}