import { httpGrow } from "../http-common";

const getAsr = (query) => {
  return httpGrow.get(
    `/agricultural-supplies?pageNumber=${query.pageNumber}&pageSize=${query.size}&countryCode=BRA`
  );
};
const getAsrSupply = () => {
  return httpGrow.get(
    // `/agricultural-supplies?pageNumber=${query.pageNumber}&pageSize=${query.size}&countryCode=BRA`
    `/agricultural-supplies-details`
  );
};
const createAsr = (data) => {
  return httpGrow.post(
    `/agricultural-supplies`,data
  );
};
const deleteAsr = (id) => {
  return httpGrow.delete(
    `/agricultural-supplies/`+id
  );
};

const getAsrUniqueCode = () => {
  return httpGrow.get(`/Asrunit/AsrCode`);
};



const updateAsr = (data) => {
  return httpGrow.put(`/agricultural-supplies`, data);
};



const searchAsr = (query) => {
  return httpGrow.get('/agricultural-supplies/search?'+query);
};

const AsrService = {
  getAsr,
  getAsrSupply,
  deleteAsr,
  getAsrUniqueCode,
  updateAsr,
  createAsr,
  searchAsr
};

export default AsrService;
