import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import Layout from "components/structure/Layout";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Service from "../../../services/CropStageCalander";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import dayjs from "dayjs";
import RedSwitch from "utils/RedSwitch";
import { AuthData } from "auth/AuthWarpper";
import moment from "moment";
import { SnackbarContext } from "context/snackBarContext";
import './CropStageCalendar.css'
				  
import CheckboxUntick from "../../../assets/CheckboxUntick.svg"
import CheckboxTick from "../../../assets/CheckboxTick.svg"
const Heading = styled.div`
  color: rgba(115, 125, 134, 1);
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
`;

const PaperContainer = styled(Paper)`
  padding: 1rem 1rem;
  border-radius: 10px;
  background-color: white;
  height: auto;
  margin-top: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 2rem 0;
`;

const HeaderBody = styled.div`
  height: auto;
  margin-left: 5%;
`;

const EditRegistration = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [open, setOpen] = useState(false);
  const [openEnd, setOpenEnd] = useState(false);
  const { user } = AuthData()

  const [StartDate, setStartDate] = useState(state?.startDate || "");
  const [EndDate, setEndDate] = useState(state?.endDate || "");
  const [isActive, setIsActive] = useState(state?.activeInd || false);

  const {
    severity,
    message,
    openAlert,
    setOpenAlert,
    setSeverity,
    setMessage,
  } = React.useContext(SnackbarContext);
  console.log('sat', state)
  const [countryName, setCountryName] = useState(state?.countryName || "");
  const [cropStageName, setCropStageName] = useState(
    state?.cropStageName || state?.cropStageName
  );
  const [cropYear, setCropYear] = useState(state?.cropYear || "");
  const currentDate = new Date()?.toISOString()?.split("T")[0];
  const [seasonCode, setSeasonCode] = useState(state?.seasonCode || "");
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = useState({});
  const dateTime = new Date();
  const options = { year: "numeric", month: "long", day: "numeric" };
  const handleSwitchChange = (event) => {
    const newValue = event?.target?.checked;
    setIsActive(newValue);
  };

  const handleUpdate = async () => {
    setLoading(true);
    const request = {
      modifiedByUser: user?.modifiedByUser,
      cropStageCalendarUid: state?.cropStageCalendarUid,
      cropStageName: state?.cropStageName,
      countryName: state?.countryName,
      countryCode: state?.countryCode,
      cropYear: state?.cropYear,
      seasonName: state?.seasonName,
      seasonCode: state?.seasonCode,
      startDate: StartDate,
      endDate: EndDate,
      activeInd: isActive,
    };

    Service.updateCropStageCalendar(request).then((res) => {
      setLoading(false);
      navigate("/getCropStageCalendar", { state: { cropYear: state?.cropYear } });
      setMessage("Updated successfully")
      setOpenAlert(true)
      setSeverity("success")
    }).catch((error) => {
      setLoading(false);
      setMessage(error?.response?.data?.message || error?.message)
      setOpenAlert(true)
      setSeverity("error")
    })
  };
  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "startDate" || name === "endDate") {
      const dateValue = new Date(value);
      if (isNaN(dateValue.getTime())) {
        console.error(`Invalid date entered for ${name}`);
        // setDateError(true);
        return;
      }
      const formattedDate = moment(dateValue).toISOString();
      if (name == 'startDate') {
        setStartDate(formattedDate)

      } else {
        setEndDate(formattedDate)
      }
    }
  };

  
  return (
    <Layout title="Crop Stage Calendar">
      <div className="CropStageCalendarContainer" style={{marginBottom:"4rem"}}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={8} sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ marginLeft: "1rem", marginTop: 2 }}>
              <Heading>Edit Crop Stage</Heading>
            </Box>
            <Box sx={{ marginLeft: "2rem", alignItems: "center" }}></Box>
          </Grid>
          {/* <Grid item xs={4}>
          <Box
            sx={{ display: "flex", alignItems: "center", marginLeft: "1rem" }}
          >
            <InputLabel sx={{ margin: 0 }} htmlFor="my-input">
              Last Modified By:
            </InputLabel>
            <Typography variant="body1">
              {" "}
              &nbsp;{state?.modifiedByUser}
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", marginLeft: "1rem" }}
          >
            <InputLabel sx={{ margin: 0 }} htmlFor="my-input">
              Last Modified Date:
            </InputLabel>
            <Typography variant="body1"> &nbsp;{formatDateDetails(state?.modifiedDateTimeUTC)}</Typography>
          </Box>
        </Grid> */}
        </Grid>

        <PaperContainer>
          {/* <HeaderBody> */}
          <Grid
            container
            spacing={4}
            padding="20px"
          >

            <Grid item xs={12} sm={12} md={4}>
              <Grid container sx={{ alignItems: "center" }} >
                <Grid item xs={12} sm={12} md={6} >
                  <InputLabel
                    // sx={{ width: "10rem" }}
                    htmlFor="crop-stage-input-1"
                  >
                    Country
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={6} >
                  <TextField
                    width="130px"
                    id="crop-stage-input-1"
                    fullWidth
                    aria-describedby="crop-stage-helper-text-1"
                    label={""}
                    value={countryName}
                    name="mName"
                    disabled
                  // sx={{ width: { xs: "100%", md: "14rem" } }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Grid container sx={{ alignItems: "center" }}>
                <Grid item xs={12} sm={12} md={6} >
                  <InputLabel
                    // sx={{ width: "10rem" }}
                    htmlFor="crop-stage-input-1"
                  >
                    Crop Year
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={6} >
                  <TextField
                    id="crop-stage-input-1"
                    fullWidth
                    aria-describedby="crop-stage-helper-text-1"
                    label={""}
                    value={cropYear}
                    name="mName"
                    disabled
                  // sx={{ width: { xs: "100%", md: "14rem" } }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Grid container sx={{ alignItems: "center" }}>
                <Grid item xs={12} sm={12} md={6} >
                  <InputLabel
                    // sx={{ width: "10rem" }}
                    htmlFor="crop-stage-input-1"
                  >
                    Season Code
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={6} >
                  <TextField
                    fullWidth
                    id="crop-stage-input-1"
                    aria-describedby="crop-stage-helper-text-1"
                    label={""}
                    value={seasonCode}
                    name="mName"
                    disabled
                  // sx={{ width: { xs: "100%", md: "14rem" } }}
                  />
                </Grid>
              </Grid>
            </Grid>



            <Grid item xs={12} sm={12} md={4}>
              <Grid container sx={{ alignItems: "center" }} >
                <Grid item xs={12} sm={12} md={6} >
                  <InputLabel
                    htmlFor="crop-stage-input-1"
                  >
                    Season Name
                  </InputLabel>

                </Grid>
                <Grid item xs={12} sm={12} md={6} >
                  <TextField
                    fullWidth
                    id="crop-stage-input-3"
                    aria-describedby="crop-stage-helper-text-3"
                    value={cropStageName}
                    name="mName"
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Grid container sx={{ display:"flex",alignItems: "center" ,justifyContent:"space-between"}}>
                <Grid item xs={12} sm={12} md={5} >
                  <InputLabel
                    htmlFor="crop-stage-input-1"
                  >
                    Start date
                  </InputLabel>

                </Grid>
                <Grid item xs={12} sm={12} md={6} >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      sx={{ width: { sx: "100%", md: 200 } }}
                      components={["DatePicker"]}
                    >
                      <DatePicker
                        fullWidth
                        id="startDate"
                        name="startDate"
                        open={open}
                        onClose={() => setOpen(false)}
                        label="Start Date"
                        format="DD/MM/YYYY"
                        value={dayjs(StartDate)}
                        onChange={(date) =>
                          handleChange({
                            target: { name: "startDate", value: date },
                          })
                        }
                        maxDate={dayjs(EndDate)}
                        renderInput={(params) => <TextField {...params} />}
                        slotProps={{
                          field: {
                            readOnly: true,
                          },
                          textField: {
                            onClick: () => setOpen(true),
                          },
                        }}
                        error={!!errors.startDate}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Grid container  sx={{ display:"flex",alignItems: "center" ,justifyContent:"space-between"}}>
                <Grid item xs={12} sm={12} md={5} >
                  <InputLabel
                    htmlFor="crop-stage-input-1"
                  >
                    End date
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={6} >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["DatePicker"]}
                      sx={{ width: { sx: "100%", md: 200 } }}
                    >
                      <DatePicker
                        id="endDate"
                        name="endDate"
                        open={openEnd}
                        onClose={() => setOpenEnd(false)}
                        label="End Date"
                        format="DD/MM/YYYY"
                        value={dayjs(EndDate)}
                        onChange={(date) =>
                          handleChange({
                            target: { name: "endDate", value: date },
                          })
                        }
                        minDate={dayjs(StartDate)}
                        renderInput={(params) => <TextField {...params} />}
                        slotProps={{
                          field: {
                            readOnly: true,
                          },
                          textField: {
                            onClick: () => setOpenEnd(true),
                          },
                        }}
                        error={!!errors.endDate}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Grid container  sx={{ display:"flex",alignItems: "center" ,justifyContent:"space-between"}}>
                <Grid item xs={12} sm={12} md={5} >
                  <InputLabel
                  >
                    Active
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={6} >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["DatePicker"]}
                      sx={{ width: { sx: "100%", md: 200 } }}
                    >
                      <Checkbox name="isActive"
                      checked={isActive}
                      onChange={handleSwitchChange}
                      icon={<img src={CheckboxUntick} />}
                      checkedIcon={<img src={CheckboxTick} />}
                    />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 12, sm: 8, md: 12 }}
            sx={{ justifyContent: "center" }}
          >
            <ButtonContainer>
              <Button
                sx={{
                  borderRadius: 2,
                  background: "#737D86",
                  marginRight: { xs: 1, md: 0 },
                  marginBottom: { xs: 1, md: 0 },
                  width: { xs: "100%", md: "auto" },
                }}
                variant="contained"
                onClick={() => navigate("/getCropStageCalendar", { state: { cropYear: state?.cropYear } })}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  borderRadius: 2,
                  background: "#102A62",
                  width: { xs: "100%", md: "auto" },
                }}
                variant="contained"
                onClick={handleUpdate}
                disabled={loading}
              >
                {loading && <CircularProgress size={20} />}
                Update
              </Button>
            </ButtonContainer>
          </Grid>
          {/* </HeaderBody> */}
        </PaperContainer>
      </div>
    </Layout>
  );
};

export default EditRegistration;
