import { http, httpGrow } from "../http-common";

const getHierarchyList = () => {
  return httpGrow.get(`/importHierarchyVersion/list`);
};

const getDuplicateHierarchyList = (id) => {
  return httpGrow.get(`/importHierarchyVersion/duplicate/`+id);
};

const gendrateCode = () => {
  return http.get("/importHierarchyVersion/code");
};

///importHierarchyVersion

const generateVersion = (data) => {
  return http.post("/importHierarchyVersion/version",data);
};

const getHierarchy = ({id}) => {
  return http.get(`/importHierarchyVersion/${id}`);
};
const updateNodeData = (data) => {
  return http.post("/importHierarchyVersion/node", data);
};
const deleteLevel = (id) => {
  return http.delete("/importHierarchyVersion/node/" + id);
};
const updateSequence = (data) => {
  return http.put(
    `/hierarchyLevelConfig/sequence?oldSequence=${data.old}&newSequence=${data.new}`
  );
};

const getAllFarmers = ({batId,pageNumber,size}) => {
  return http.get(`/farmer/find?pageNumber=0&size=50`);
};


const getByVersion = ({id}) => {
  return http.get(`/importHierarchyVersion/node/${id}
`);
};

const getLatestTree = ({id}) => {
  return http.get(`/importHierarchyVersion/tree`);
};


const getLevelList = () => {
  return http.get(`hierarchyLevelConfig/list?pageNumber=0&size=10`)
}


///hierarchyLevelConfig/list?pageNumber=0&size=10

const HierarchyService = {
  getHierarchy,
  getHierarchyList,
  gendrateCode,
  updateNodeData,
  deleteLevel,
  updateSequence,
  getDuplicateHierarchyList,
  getAllFarmers,
  generateVersion,
  getByVersion,
  getLatestTree,
  getLevelList
};

export default HierarchyService;
