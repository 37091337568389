// src\services\ProductionTargetsService.jsx
import { httpGrow } from "../http-common";

const getAPI = async (query) => {
    const { cropYearUUID , ihId } = query;
    const response = await httpGrow.get(`/additionalFreightLimitsDistribution`, {
        params: {
            cropYearUUID,
            ihId 
        }
    });
    return response;
};

const getCropSeasonAPI = async () => {
    const response = await httpGrow.get('/cropYears');
    return response;
};

const getHierarchyNodeAPI = async () => {
    const response = await httpGrow.get('/importHierarchyVersion/tree');
    return response;
};

const getAdditionalFreightReg = async (query) => {
    const { additionalFreightGroupDescription } = query;
    const response = await httpGrow.get('additionalFreight/search?pageNumber=0&size=50', {
        params: {
            additionalFreightGroupDescription: additionalFreightGroupDescription || ""
        }
    });
    return response;
};

const putAdditionalFLD = (data) => {
    return httpGrow.put('additionalFreightLimitsDistribution', data);
};

const deleteAFDistributionAPI = async (query) => {
    const { additionalFreightLimitsDistributionId  , cropYearId  } = query;
    const response = await httpGrow.delete(`/additionalFreightLimitsDistribution/${additionalFreightLimitsDistributionId }`, {
        params: {
            cropYearId
        }
    });
    return response;
};

const AdditionalFreightLimitDistribution = {
    getAPI,
    getCropSeasonAPI,
    getHierarchyNodeAPI,
    getAdditionalFreightReg,
    putAdditionalFLD,
    deleteAFDistributionAPI
};

export default AdditionalFreightLimitDistribution;
