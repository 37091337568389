import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import { Box, TextField, CircularProgress, Typography, Checkbox } from "@mui/material";
import Service from "../../../services/ModulesRegistrationServices";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Layout from "components/structure/Layout";
import { FormHelperText, Grid } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import SubModuleIcon from "../../../assets/submoduleIcon";
import DeleteIcon from "../../../assets/deleteIcon";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { SnackbarContext } from "context/snackBarContext";
import './Module.css'
import CheckboxUntick from "../../../assets/CheckboxUntick.svg"
import CheckboxTick from "../../../assets/CheckboxTick.svg"
import * as Yup from "yup";
import PinkIosSwitch from "utils/PinkIosSwitch";
import RedSwitch from "utils/RedSwitch";
import { AuthData } from "auth/AuthWarpper";

const Heading = styled.div`
  color: rgba(115, 125, 134, 1);
  font-size: 1.25rem;
  font-weight: 600;
`;

const PaperContainer = styled(Paper)`
  padding: 1rem 1rem;
  border-radius: 10px;
  background-color: white;
  height: auto;
  margin-top: 20px;
`;

const Line = styled.div`
  border-bottom: 1px solid lightgray;
`;

const HeaderBody = styled.div`
  height: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

const DataContainer = styled.div`
  margin-top: 2rem;
`;


const AddRegistration = () => {
  const [isSubmodulesVisble, setSubmodulesVisble] = useState(true);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [formData, setFormData] = useState({
    moduleName: "",
    cropStage: "",
    selected: [],
    isActive: false,
  });
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = useState({});
  const [selectedCropStage, setSelectedCropStage] = useState('Default');
  const [cropStageUUID, setSetcropStageUUID] = useState('');
  const [getCropList, setCropList] = useState([]);
  const navigate = useNavigate();
  const [switchValue, setSwitchValue] = useState(false);
  const { user } = AuthData();


  const [submoduleList, setSubmoduleList] = useState([
    // {
    //   id: 0,
    //   "mName": '',
    //   "mLevelId": 2,
    //   "mLevelDesc": "sub",
    //   "createdByUser": "testuser",
    //   "modifiedByUser": "test_user",
    // },
  ]);

  const handleChange = (e, index) => {
    e.preventDefault()
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({});
    setSubmoduleList((prevSubmoduleList) => {
      const updatedSubmoduleList = [...prevSubmoduleList];
      updatedSubmoduleList[index] = {
        ...updatedSubmoduleList[index],
        [name]: value,
        mName: value,
      };
      return updatedSubmoduleList;
    });
  };

  const addSubmodule = () => {
    const lastSubmodule = submoduleList[submoduleList?.length - 1];
    if (lastSubmodule && lastSubmodule.name == "") {
      setMessage("Please provide name to last submodule")
      setOpenAlert(true)
      setSeverity("error")
      return
    }
    const newSubmodule = {
      id: lastSubmodule ? lastSubmodule.id + 1 : 1,
      mName: "",
      cropStageUUID: cropStageUUID,
      mLevelId: 2,
      mLevelDesc: "sub",
      createdByUser: user ? user.userName : "",
      modifiedByUser: user ? user.userName : "",
    };
    setSubmoduleList((prevSubmoduleList) => {
      return [...prevSubmoduleList, newSubmodule];
    });
  };

  const deleteSubmodule = (id) => {
    let newSubModule = submoduleList?.filter((submodule) => submodule?.id !== id);
    newSubModule.forEach((ele, index) => {
      //delete old key
      Object.keys(ele).forEach((key) => {
        if (key.startsWith("submodule-name-")) {
          delete ele[key];
        }
      });
      //set new key 
      ele[`submodule-name-${index}`] = ele.mName
    })
    setSubmoduleList(newSubModule);
  };

  const validationSchema = Yup.object({
    moduleName: Yup.string().trim()
      .matches(
        /^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/,
        "Speacial characters or extra spaces not allowed"
      )
      .strict(true)
      .min(1, 'Module name needs to be at least 1 char')
      .max(60, 'Module name cannot exceed 60 char').required("Module Name  is required"),
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setErrors({});
      await validationSchema.validate(formData, { abortEarly: false });
    } catch (error) {
      let newError = {};

      error.inner.forEach((err) => {
        newError[err.path] = err.message;
      });
      setErrors(newError);
    }
  };
  useEffect(() => {
    if (cropStageUUID) {
      const updatedSubmoduleList = submoduleList.map((submodule) => ({
        ...submodule,
        cropStageUUID: cropStageUUID,
      }));
      setSubmoduleList(updatedSubmoduleList);
    }
  }, [cropStageUUID]);
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    const updatedSubmoduleList = Array.from(submoduleList);
    const [removed] = updatedSubmoduleList.splice(startIndex, 1);
    updatedSubmoduleList.splice(endIndex, 0, removed);
    updatedSubmoduleList.forEach((ele, index) => {
      //delete old key
      Object.keys(ele).forEach((key) => {
        if (key.startsWith("submodule-name-")) {
          delete ele[key];
        }
      });
      //set new key 
      ele[`submodule-name-${index}`] = ele.mName
    })
    setSubmoduleList(updatedSubmoduleList);
  };

  const handleCreate = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      if (cropStageUUID == "" || cropStageUUID == "Default") if (cropStageUUID == "" || cropStageUUID == "Default") {
        setSeverity("error");
        setMessage("Please select crop stage name");
        setOpenAlert(true);
        return
      }
      const postData = {
        "mName": formData?.moduleName,
        "mLevelId": 1,
        "mLevelDesc": "main",
        createdByUser: user ? user.userName : "",
        modifiedByUser: user ? user.userName : "",
        "cropStageUUID": cropStageUUID,
        "subModule": submoduleList,
        "mStatus": switchValue
      };
      let subModuleError = false;
      let newError = {};
      submoduleList.forEach((ele, index) => {
        let subName = `submodule-name-${index}`;
        let regex = /^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/
        let valid = regex.test(ele[subName])
        if (!valid) {
          newError[subName] = "Speacial characters or extra spaces not allowed";
          subModuleError = true
        }
        if (ele[subName] == '' || ele[subName] == undefined) {
          newError[subName] = "Submodule name cannot be blank";
          subModuleError = true
        }
        ele.sequence = index + 1
      })
      if (subModuleError) {
        setErrors(newError);
        return
      }

      setLoading(true)
      const response = await Service.create(postData);
      if (response?.data) {
        setLoading(false);
        setSeverity("success");
        setMessage("Added successfully");
        setOpenAlert(true);
        setLoading(false);
        navigate("/getmoduleregistration", { state: { selectedCropStage, cropStageUUID } });
      }
    } catch (error) {
      let newError = {};
      setLoading(false)
      error?.inner?.forEach((err) => {
        newError[err.path] = err.message;
      });
      if (!newError) {
        setSeverity("error");
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
      }
      setErrors(newError);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Service.getCroplist();
        setCropList(response?.data?.response);
        // setSetcropStageUUID(response?.data?.response[0]?.cropStageUid)
        // setSelectedCropStage(response?.data?.response[0]?.cropStageName);
      } catch (error) {
        console.error("Error fetching data:", error);
        setSeverity("error");
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
      }
    };
    fetchData();
  }, []);

  const handleCropStageChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue == "") return
    const selectedCrop = getCropList?.find(
      (crop) => crop.cropStageName === selectedValue
    );
    setSelectedCropStage(selectedCrop.cropStageName
    );
    setSetcropStageUUID(selectedCrop.cropStageUid)

  };
  const handleSwitchChange = (event) => {
    const newValue = event?.target?.checked;
    setSwitchValue(newValue);
  };

  return (
    <Layout title="Add Module Setup">
      <div className="moduleContainer">

        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Heading>Add Module Setup</Heading>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              sx={{
                borderRadius: 100,
                minWidth: 195,
                minHeight: 44,
                background: "#102A62",
              }}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={addSubmodule}
              disabled={loading}
            >
               {loading && <CircularProgress size={20} />}
              Add Sub Modules
            </Button>
          </Grid>
        </Grid>

        <PaperContainer>
          <HeaderBody>

            <form autoComplete="off" onSubmit={handleSubmit}>
              <Grid
                container
                spacing={4}
                sx={{
                  justifyContent: "space-between",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid item xs={12} sm={12} md={4}>
                  <Grid container sx={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                    <Grid md={5} sm={6} xs={12}>
                      <InputLabel
                        // sx={{ marginRight: "1rem" }}
                        htmlFor="my-input"
                        required
                      // fullWidth
                      >
                        Module Name
                      </InputLabel>
                    </Grid>
                    <Grid xs={12} md={5} sm={6}>
                      <div style={{ position: "relative" }}>
                        <TextField
                          fullWidth
                          inputProps={{ maxLength: 60 }}
                          value={formData.moduleName}
                          onChange={handleChange}
                          name="moduleName"
                          placeholder="Type here"
                          required variant="outlined" />
                        {errors.moduleName && (
                          <Box sx={{ position: "absolute", marginLeft: "", marginTop: "0.5rem" }}>
                            <FormHelperText error={true}>
                              {errors.moduleName}
                            </FormHelperText>
                          </Box>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Grid container sx={{ display: "flex", justifyContent: { xs: "flex-start", sm: "center" }, alignItems: 'center' }}>
                    <Grid md={5} sm={6}>
                      <InputLabel
                        htmlFor="crop-stage-input"
                        required
                      >
                        Crop Stage Name
                      </InputLabel>
                    </Grid>
                    <Grid xs={12} md={5} sm={6}>
                      <Select
                        fullWidth
                        name="cropStageUUID"
                        value={selectedCropStage}
                        onChange={(e) => handleCropStageChange(e)}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem selected value="Default">Select Crop Stage</MenuItem>
                        {getCropList?.map((crop, index) => (
                          <MenuItem width="100%" key={index} value={crop?.cropStageName}>
                            {crop?.cropStageName}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.cropStageUUID && (
                        <FormHelperText error={true}>
                          {errors.cropStageUUID}
                        </FormHelperText>
                      )}
                    </Grid>
                    {errors.cropName && (
                      <Box sx={{ marginLeft: "", marginTop: "0.5rem" }}>
                        <FormHelperText error={true}>
                          {errors.cropName}
                        </FormHelperText>
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={4}
                  lg={4}
                  xs={4}
                  sx={{
                    display: { md: "flex", xs: "block" },
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "row", md: "row" },
                      alignItems: { xs: "flex-start", md: "space-between" },
                      justifyContent: { xs: "flex-start", md: "space-between" },
                    }}
                  >
                    <Box sx={{ marginBottom: { xs: "1rem", md: 0 }, marginTop: "5px" }}>
                      <InputLabel>Active</InputLabel>
                    </Box>
                    <Box sx={{ marginLeft: "2rem" }}>
                      <Checkbox name="active"
                        checked={switchValue}
                        onChange={handleSwitchChange}
                        icon={<img src={CheckboxUntick} />}
                        checkedIcon={<img src={CheckboxTick} />}
                      />
                    </Box>
                  </Box>
                </Grid>

                {/* <Grid item xs={12} sm={12} md={3}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ marginLeft: "0", paddingRight: "10px" }}>
                      <InputLabel htmlFor="my-input">Status</InputLabel>
                    </Box>
                    <RedSwitch
                      checked={switchValue}
                      onChange={handleSwitchChange}
                      name="isActive"
                    />
                    <Box sx={{ marginLeft: "1rem" }}>
                      <InputLabel sx={{ width: "10rem" }} htmlFor="my-input">
                        {switchValue == true ? "Active" : "Inactive"}
                      </InputLabel>
                    </Box>
                  </Box>
                </Grid> */}
              </Grid>
            </form>
          </HeaderBody>
          <DataContainer>
            <DragDropContext onDragEnd={onDragEnd}>
              {isSubmodulesVisble &&
                submoduleList?.map((submodule, index) => (
                  <Droppable key={submodule.id} droppableId={`submodule-${submodule.id}`}>
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <Draggable key={submodule.id} draggableId={`submodule-${submodule.id}`} index={index}>
                          {(provided) => (
                            <div
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <div style={{ marginTop: "20px" }}>
                                {<Line style={{ marginTop: "50px" }} />}
                                <ButtonContainer>
                                  <Grid container spacing={2}>
                                    <Grid
                                      item
                                      md={5}
                                      xs={12}
                                      sx={{
                                        display: { md: "flex", sx: "block" },
                                        alignItems: "center",
                                        marginTop: "20px",
                                      }}
                                    >
                                      <Box sx={{ marginLeft: "1rem" }}>
                                        <SubModuleIcon />
                                      </Box>
                                      <Box sx={{ marginLeft: "1rem" }}>
                                        <InputLabel
                                          sx={{ width: "10rem" }}
                                          htmlFor={`submodule-name-${index}`}
                                        >
                                          Sub Module Name
                                        </InputLabel>
                                      </Box>
                                      <Box sx={{ marginLeft: "1rem" }}>
                                        <TextField
                                          sx={{ width: { xs: "100%", md: "14rem" } }}
                                          id={`submodule-name-${index}`}
                                          aria-describedby={`submodule-name-helper-${index}`}
                                          label={submodule.newName ? "" : "Type here..."}
                                          value={submodule.newName}
                                          name={`submodule-name-${index}`}
                                          onChange={(e) => handleChange(e, index)}
                                          inputProps={{ maxLength: 60 }}
                                        />
                                        {errors[`submodule-name-${index}`] && (
                                          <FormHelperText
                                            error={true}
                                            id={`submodule-name-helper-${index}`}
                                          >
                                            {errors[`submodule-name-${index}`]}
                                          </FormHelperText>
                                        )}
                                      </Box>
                                    </Grid>
                                    <Grid
                                      item
                                      md={5}
                                      xs={12}
                                      sx={{
                                        display: { md: "flex", xs: "block" },
                                        alignItems: "center",
                                      }}
                                    ></Grid>
                                    <Grid
                                      item
                                      md={2}
                                      xs={12}
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <Box onClick={() => deleteSubmodule(submodule.id)}>
                                        <DeleteIcon />
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </ButtonContainer>
                              </div>
                            </div>
                          )}
                        </Draggable>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                ))}
            </DragDropContext>
          </DataContainer>
          <Box sx={{ height: "100px" }}></Box>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 12, sm: 8, md: 12 }}
            sx={{ justifyContent: "center", marginTop: 10 }}
          >
            <ButtonContainer>
              <Button
                sx={{
                  borderRadius: 2,
                  background: "#737D86",
                  marginRight: { xs: 1, md: 0 },
                  marginBottom: { xs: 1, md: 0 },
                  width: { xs: "100%", md: "auto" },
                }}
                variant="contained"
                onClick={() => navigate("/getmoduleregistration")}
                disabled={loading}
              >
                {loading && <CircularProgress size={20} />}
                Cancel
              </Button>
              <Button
                sx={{
                  borderRadius: 2,
                  background: "#102A62",
                  width: { xs: "100%", md: "auto" },
                }}
                variant="contained"
                onClick={handleCreate}
                disabled={loading}
              >
                {loading && <CircularProgress size={20} />}
                Submit
              </Button>
            </ButtonContainer>
          </Grid>
        </PaperContainer>
      </div>

    </Layout>
  );
};
export default AddRegistration;
