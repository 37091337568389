import { httpGrow } from "../http-common";

const getMenuCultureGroup =async ()=>{
 
    const response =await httpGrow.get('cultureGroup/namesAndIds')
  
    return response;
 }
 const createCultureGroup = (data) => {
  return httpGrow.post(
    `/cultureGroup`,data
  );
};      

 const getCultureGroup = async (query) => {
  const response =await httpGrow.get(`/cultureGroup?pageNumber=${query.pageNumber}&pageSize=${query.size}&countryCode=BRA`)
     
   return response;

 };

 const getCultureGroupById = (id) => {
  return httpGrow.get(
    `/cultureGroup/${id}`
  
  );
};

const updateCultureGroup = (data) => {
  return httpGrow.put(`/cultureGroup`, data);
};

 const CultureGroupService = {
       getMenuCultureGroup,
       getCultureGroup,
       createCultureGroup,
       getCultureGroupById,
       updateCultureGroup
    // getCultureUniqueCode,
    // updateCulture,
    // createCulture,
    // searchCulture,
    // getAllCultureData,
    // getMenuItems,
    // getMenuItemsById
  
  };
  
  export default CultureGroupService;