import React, { useContext, useEffect, useState } from "react";
import {
    Typography,
    Box,
    Checkbox,
} from "@mui/material";
import { SnackbarContext } from "context/snackBarContext";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import HierarchyService from "services/HierarchyService";
import icondeleteround from '../../../assets/iconDeleteRound.svg'
import shuffle_6_dots from '../../../assets/shuffle_6_dots.svg'
import './Hierarchy.css';
import { AuthData } from "auth/AuthWarpper";


const HierarcyTable = (props) => {
const { user } = AuthData();
    const [hierarchyData, sethierarchyData] = useState([]);
    const [loading, setLoading] = React.useState(false);
    const { setOpenAlert, setSeverity, setMessage, handleClose } =
        useContext(SnackbarContext);

    useEffect(() => {
        sethierarchyData(props.hierarchyData)
    }, [props.hierarchyData]);

    const onDragEnd = (result) => {
        if (!result.destination) {
            return; // Item dropped outside the list
        }
        console.log('result' ,result)

        const startIndex = result.source.index;
        const endIndex = result.destination.index;
        let data = {
            old: startIndex + 1,
            new: endIndex + 1
        }

        console.log('data' ,data)

        let newHierarchyData = JSON.parse(JSON.stringify(hierarchyData));
        const updatedmoduleList = Array.from(newHierarchyData.hierarchyLevelConfig);
        const [removed] = updatedmoduleList.splice(startIndex, 1);
        updatedmoduleList.splice(endIndex, 0, removed);
        updatedmoduleList.forEach((ele, index) => {
            ele.hlcLevelOrder = index + 1
        })
        newHierarchyData.hierarchyLevelConfig = updatedmoduleList
        sethierarchyData(newHierarchyData);
        setLoading(true)
        HierarchyService.updateSequence(data)
            .then(() => {
                setLoading(false)
                setMessage("Sequence updated successfully")
                setOpenAlert(true)
                setSeverity("success")
                props.callHierarchyAPI()

            }).catch((error) => {
                setLoading(false)
                setMessage(error?.response?.data?.message || error?.message)
                setOpenAlert(true)
                setSeverity("error")
                props.callHierarchyAPI()
            })
    };
    const onCheckboxClick = (status, item, level) => {
        let hierarchyDataNew = JSON.parse(JSON.stringify(hierarchyData));
        hierarchyDataNew.hierarchyLevelConfig.forEach((ele) => {
            if (ele.hlcId == level.hlcId) {
                ele[item] = !status;
                setLoading(true)
                HierarchyService.updateData(ele).then((res) => {
                    if (res && res?.data?.statusCode == 200) {
                        setLoading(false)
                        setMessage("Updated successfully")
                        setOpenAlert(true)
                        setSeverity("success")
                        props.callHierarchyAPI()
                    }
                }).catch((error) => {
                    setLoading(false)
                    setMessage(error?.response?.data?.message || error?.message)
                    setOpenAlert(true)
                    setSeverity("error")
                })
            }
        })
    }
    const onDelete = (item) => {
        HierarchyService.deleteLevel(item?.hlcId).then((res) => {
            props.setHierarchynull()
            if (res && res?.data?.statusCode == 200) {
                setLoading(false)
                setMessage("Deleted successfully")
                setOpenAlert(true)
                setSeverity("success")
                props.callHierarchyAPI()
            }
        }).catch((error) => {
            setLoading(false)
            setMessage(error?.response?.data?.message || error?.message)
            setOpenAlert(true)
            setSeverity("error")
        })

    };



    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div className="table-container">
                <table className="full-width-table"> {/* Add a class for styling */}
                    <thead>
                        <tr className="HierarchyHead">
                            <th style={{ visibility: "hidden" }}>Level Order</th>
                            <th>Level Order</th>
                            <th>Level Name</th>
                            <th>Level Configurations </th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <Droppable droppableId="rows" direction="vertical">
                        {(provided) => (
                            <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                {hierarchyData &&
                                    hierarchyData?.hierarchyLevelConfig?.map((row, index) => (
                                        <Draggable key={row.hlcId} draggableId={row.hlcId} index={index}>
                                            {(provided, snapshot) => (
                                                <tr
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className="hierarchyColumn"
                                                    style={{
                                                        ...provided.draggableProps.style, height: "62px",
                                                        borderSpacing: snapshot.isDragging ? '0px' : 'inherit',
                                                    }}
                                                >
                                                    <td style={{ textAlign: "left", width: snapshot.isDragging ? '5%' : '5%' }}>
                                                        <div>
                                                            <img style={{ paddingLeft: "20px" }} src={shuffle_6_dots} alt="Drag Icon" />
                                                        </div>
                                                    </td>
                                                    <td style={{ textAlign: "center", width: snapshot.isDragging ? '10%' : '8%', }}>
                                                        <Typography sx={{ justifyContent: "left" }}>{row.hlcLevelOrder}</Typography>
                                                    </td>
                                                    <td style={{ textAlign: "center", width: snapshot.isDragging ? '25%' : '25%', }}>
                                                        <Box sx={{ whiteSpace: "nowrap", paddingLeft: "5px", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                            <span title={row.hlcLevelName}>{row.hlcLevelName.length > 20 ? row.hlcLevelName.substring(0, 20) + '...' : row.hlcLevelName}</span>
                                                        </Box>
                                                    </td>
                                                    <td style={{ textAlign: "center", width: snapshot.isDragging ? '75%' : '50%', }}>
                                                        <div className="hierarchyConfig" style={{ color: "#191E31", fontWeight: 600, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                            <Checkbox disabled={loading} checked={row.hlcGroupingLevel} onClick={(e) => onCheckboxClick(row.hlcGroupingLevel, "hlcGroupingLevel", row)}></Checkbox><Typography>Grouping Level</Typography>
                                                            <Checkbox disabled={loading} checked={row.hlcEsgapproval} onClick={(e) => onCheckboxClick(row.hlcEsgapproval, "hlcEsgapproval", row)}></Checkbox><Typography>ESG Approval</Typography>
                                                            <Checkbox disabled={loading} checked={row.hlcFarmerLevel} onClick={(e) => onCheckboxClick(row.hlcFarmerLevel, "hlcFarmerLevel", row)}></Checkbox><Typography>Farmer Level</Typography>
                                                        </div >
                                                    </td>
                                                    <td style={{ textAlign: "center", width: snapshot.isDragging ? '10%' : '10%' }}>
                                                        <div onClick={() => onDelete(row)}>
                                                            <img style={{ marginRight: "20px", maxWidth: "30px", cursor: "pointer" }} draggable={false} src={icondeleteround} />
                                                        </div></td>

                                                </tr>
                                            )}
                                        </Draggable>
                                    ))}
                                {provided.placeholder}
                            </tbody>
                        )}
                    </Droppable>
                </table>
            </div>
        </DragDropContext>
    )
};

export default HierarcyTable;
