// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';

// import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';

// i18n
//   // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
//   // learn more: https://github.com/i18next/i18next-http-backend
//   .use(Backend)
//   // detect user language
//   // learn more: https://github.com/i18next/i18next-browser-languageDetector
//   .use(LanguageDetector)
//   // pass the i18n instance to react-i18next.
//   .use(initReactI18next)
//   // init i18next
//   // for all options read: https://www.i18next.com/overview/configuration-options
//   .init({
//     lng: 'en',
//     backend: {
//       /* translation file path */
//       loadPath: '/assets/i18n/{{ns}}/{{lng}}.json'
//     },
//     fallbackLng: 'en',
//     debug: true,
//     /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
//     ns: ['translations'],
//     defaultNS: 'translations',
//     keySeparator: false,
//     interpolation: {
//       escapeValue: false,
//       formatSeparator: ','
//     },
//     react: {
//       wait: true
//     }
//   });

// export default i18n;


import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import {
	format as formatDate,
	formatRelative,
	formatDistance,
	isDate
} from "date-fns";
import { enUS, pt } from "date-fns/locale"; // import all locales we need
const locales = { enUS, pt }; // used to look up the required locale

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            format: (value, rawFormat, lng) => {
                console.log('rawformat' ,rawFormat)
                if (isDate(value)) {
					const locale = locales[lng];
                    if (rawFormat === "shortTime")
                    return formatDate(value, "p", { locale });
                    if (rawFormat === "shortTime2")
                    return formatDate(value, "pp", { locale });
                    if (rawFormat === "shortTime3")
                    return formatDate(value, "ppp", { locale });
                    if (rawFormat === "shortTime4")
                    return formatDate(value, "pppp", { locale });
					if (rawFormat === "shorts")
						return formatDate(value, "PPp");
					if (rawFormat === "long")
						return formatDate(value, "PPPP", { locale });
					if (rawFormat === "relative")
						return formatRelative(value, new Date(), { locale });
					if (rawFormat === "ago")
						return formatDistance(value, new Date(), {
							locale,
							addSuffix: true
						});

					return formatDate(value, rawFormat, { locale });
				}
                // const [format, ...additionalValues] = rawFormat.split(',').map((v) => v.trim());
                console.log('value' ,value)

                return value
                // switch (format) {
                //     case 'uppercase':
                //         return value.toUpperCase();
                //     case 'price':
                //         return Intl.NumberFormat(lng, {
                //             style: 'currency',
                //             currency: additionalValues[0]
                //         }).format(value);
                // }
            }
        }
    });
// i18n.changeLanguage('en');
export default i18n;