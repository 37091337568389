import React, { useContext, useEffect, useState, useCallback } from "react";
import styled from "@emotion/styled";
import Layout from "components/structure/Layout";
import InputAdornment from "@mui/material/InputAdornment";
import iconSearchs from "assets/iconSearchs.svg";
import { TreeView } from "@mui/x-tree-view/TreeView";
import PermIdentityIcon from "@mui/icons-material/PermIdentityOutlined";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Grid,
  Box,
  TextField,
  FormHelperText,
  IconButton,
  CircularProgress,
  Modal,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DeleteIcon from "assets/deleteIcon";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Service from "../../../../services/SpacingServices";
import { SnackbarContext } from "context/snackBarContext";
import CloseIcon from "@mui/icons-material/Close";
import { AuthData } from "auth/AuthWarpper";
import Autocomplete from "@mui/material/Autocomplete";
import { json2csv } from "json-2-csv";
import GrowingHierarchyService from "services/GrowingHierarchyService";
import UserService from "services/UserService";
import FarmerService from "services/FarmerService";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { debounce } from "lodash";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomButton from "utils/CustomButton";
import dayjs from "dayjs";
import { format } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import deleteButton from "assets/delete.svg";


const UnderLineText = styled.div`
  border: none;
  background: none;
  text-decoration: underline;
  cursor: pointer;
  margintop: "10px";
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;
// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "60%",
// };

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90.5rem",
  bgcolor: "#E0E2E5",
  height: "40.125rem",
  borderRadius: "0.625rem",
  overflow: "scroll",
};

const styleBox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30.5rem",
  bgcolor: "background.paper",
  height: "20.125rem",
  borderRadius: "0.625rem",
};


function convertDateFormat(dateStr) {
  const [day, month, year] = dateStr.split("/");
  return `${year}-${month}-${day}`;
}

const originalDate = "04/08/2024";
const convertedDate = convertDateFormat(originalDate);

const GrowingHierarchy = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const { user } = AuthData();
  const [curingData, setCuringData] = useState(null);
  const [allCuringData, setAllCuringData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [code, setCode] = useState();
  const [farmerList, setFarmerList] = useState([]);
  const [treeList, setTreeList] = useState([]);
  const [parentId, setParentId] = useState("");
  const [levelId, setLevelId] = useState("");
  console.log("🚀 ~ GrowingHierarchy ~ levelId:", levelId);

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const [selectedNodeData, setSelectedNodeData] = useState({
    ihLegacyCode: "",
    locationName: "",
  });

  const [addNodeValues, setAddNodeValues] = useState({
    ihLegacyCode: "",
    ihLocationName: "",
  });

  const [nodeData, setNodeData] = useState({
    ihLegacyCode: "",
    ihLocationName: "",
    responsibleFieldTechnicianId: "",
  });

  console.log("nodeData", nodeData);

  console.log("🚀 ~ GrowingHierarchy ~ selectedNodeData:", selectedNodeData);
  const [filteredTreeList, setFilteredTreeList] = useState([]);
  console.log("🚀 ~ GrowingHierarchy ~ filteredTreeList:", filteredTreeList);
  const [searchInput, setSearchInput] = useState("");
  const [globalQuery, setGlobalQuery] = React.useState(null);
  const [loggedinUserType, setLoggedinUserType] = React.useState(
    user?.profileAttribute
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(7); // Number of items per page
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const [userList, setUserList] = useState([]);
  const [userValue, setUserValue] = useState([]);
  const [addUserValue, setAddUserValue] = useState([]);
  console.log("🚀 ~ GrowingHierarchy ~ addUserValue:", addUserValue);

  console.log("🚀 ~ GrowingHierarchy ~ userValue:", userValue);
  const [farmerValue, setFarmerValue] = useState([]);
  console.log("🚀 ~ GrowingHierarchy ~ farmerValue:", farmerValue);
  const [farmerAddValue, setAddFarmerValue] = useState([]);

  const [inputUserValue, setUserInputValue] = useState("");
  const [inputAddUserValue, setUserAddInputValue] = useState("");

  const [inputFarmerValue, setFarmerInputValue] = useState("");
  const [inputFarmerAddValue, setFarmerAddInputValue] = useState("");

  const [responsibleValue, setResponsibleValue] = useState([]);
  console.log("🚀 ~ GrowingHierarchy ~ responsibleValue:", responsibleValue);
  const [responsibleAddValue, setResponsibleAddValue] = useState([]);

  const [inputResponsibleValue, setResponsibleInputValue] = useState("");
  const [inputResponsibleAddValue, setResponsibleAddInputValue] = useState("");

  const [addResponsible, setAddResponsible] = useState("");
  console.log("🚀 ~ GrowingHierarchy ~ addResponsible:", addResponsible);
  const [versionData, setVersionData] = useState([]);

  const [startDate, setStartDate] = React.useState(
    formatDayjsObjectToDateString(versionData[0]?.startDate) || null
  );
  console.log("🚀 ~ GrowingHierarchy ~ startDate:", typeof startDate);
  const [endDate, setEndDate] = React.useState(
    formatDayjsObjectToDateString(versionData[0]?.endDate) || null
  );
  console.log("🚀 ~ GrowingHierarchy ~ endDate:", endDate);
  const [moadalOpen, setModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [levelList, setLevelList] = useState([]);
  const [nextLevelName, setNextLevelName] = useState("");
  const [newLevelId, setNewLevelId] = useState("");
  const [resetInputField, setResetInputField] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);

  console.log("🚀 ~ GrowingHierarchy ~ levelList:", levelList);
  console.log("🚀 ~ GrowingHierarchy ~ versionData:", versionData);

  function formatDayjsObjectToDateString(dateObj) {
    if (!dateObj || typeof dateObj !== "object") {
      return null;
    }

    const year = dateObj.$y;
    const month = String(dateObj.$M + 1).padStart(2, "0"); // $M is zero-indexed
    const day = String(dateObj.$D).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNodeData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddInputChange = (e) => {
    const { name, value } = e.target;
    setAddNodeValues((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    fetchHierachyDetails();
  }, []);

  const callFarmerApi = (pNumber, size) => {
    // setLoading(true);

    GrowingHierarchyService.getAllFarmers({
      batId: 85145788,
      pageNumber: "0",
      size: "7",
    })
      .then((res) => {
        setFarmerList(res?.data?.response?.farmers);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  const paginate = (pageNumber, itemsPerPage) => {
    callFarmerApi(pageNumber - 1, itemsPerPage);
  };

  const handleNo = () => setOpen(false);


  useEffect(() => {
    callFarmerApi(currentPage, itemsPerPage);
  }, []);

  //  UserService.FetchAllUser(query)

  const getLevel = async () => {
    try {
      const data = await GrowingHierarchyService.getLevelList();
      console.log("🚀 ~ getLevel ~ data:", data);
      setLevelList(data?.data?.response?.hierarchyLevelConfig);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const data = await UserService.FetchAllUser(
          "?pageNumber=0&size=500&attribute=local&countryCode=BRA"
        );
        setUserList(data?.data?.response?.users);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    getLevel();
    fetchUsers();
  }, []);

  console.log("userList", userList);
  console.log("FarmerList", farmerList);

  const fetchHierachyDetails = async () => {
    try {
      const response = await GrowingHierarchyService.getHierarchy({ id });
      setTreeList([response?.data?.response] || []);
      setFilteredTreeList([response?.data?.response] || []);
      setCode(response?.data?.response?.importHierarchyVersionDto?.ihvCode);
      setVersionData(
        [response?.data?.response?.importHierarchyVersionDto] || []
      );
      const startDateString =
        response?.data?.response?.importHierarchyVersionDto?.startDate;
      const endDateString =
        response?.data?.response?.importHierarchyVersionDto?.endDate;

      // Assuming the dates are in 'DD/MM/YYYY' format
      const startDate = dayjs(startDateString, "DD/MM/YYYY");
      const endDate = dayjs(endDateString, "DD/MM/YYYY");

      setStartDate(startDate);
      setEndDate(endDate);
    } catch (error) {
      console.error("There was an error!", error);
    }
  };
  const handleSearchInputChange = (event) => {
    const input = event.target.value.toLowerCase();
    setSearchInput(input);

    if (!input.trim()) {
      setFilteredTreeList(treeList);
    } else {
      const filteredHierarchy = treeList
        .map((hierarchy) => {
          const filteredTree = filterHierarchy(
            hierarchy.importHierarchyTreeDto,
            input
          );
          return filteredTree
            ? { ...hierarchy, importHierarchyTreeDto: filteredTree }
            : null;
        })
        .filter((hierarchy) => hierarchy !== null);

      setFilteredTreeList(filteredHierarchy);
    }
  };

  const filterHierarchy = (node, input) => {
    if (node.locationName.toLowerCase().includes(input)) {
      return node;
    }

    for (const child of node.childHierarchies) {
      const found = filterHierarchy(child, input);
      if (found) {
        return found;
      }
    }

    return null;
  };

  // const handleNodeSelect = async (item) => {
  //   setResponsibleValue("");
  //   const matchingLevel = levelList.find(
  //     (level) => level.hlcId === item.levelId
  //   );

  //   if (matchingLevel) {
  //     // Get the hlcLevelOrder of the matching level
  //     const currentLevelOrder = matchingLevel.hlcLevelOrder;

  //     // Find the next level based on hlcLevelOrder
  //     const nextLevel = levelList.find(
  //       (level) => level.hlcLevelOrder === currentLevelOrder + 1
  //     );

  //     if (nextLevel) {
  //       console.log("Next level object:", nextLevel);
  //       setNextLevelName(nextLevel?.hlcLevelName);
  //       setNewLevelId(nextLevel?.hlcId);
  //     } else {
  //       console.log("No next level found.");
  //     }
  //   } else {
  //     console.log("No matching level found.");
  //   }
  //   console.log("node is clicked", item);
  //   setSelectedNodeData(item);
  //   setParentId(item?.childHierarchies[0]?.parentId || item?.id);
  //   setLevelId(item?.childHierarchies[0]?.levelId);
  //   setUserValue(item?.linkedBackOfficeUsers);
  //   setFarmerValue(item?.linkedFarmers);

  //   console.log("node is clicked", item.id);
  //   const id = item.id;
  //   console.log("🚀 ~ handleNodeSelect ~ id:", id);
  //   try {
  //     const response = await GrowingHierarchyService.getByVersion({ id });
  //     console.log("🚀 ~ handleNodeSelect ~ response:", response);
  //     setNodeData(response?.data?.response);
  //     setUserValue(response?.data?.response?.linkedBackOfficeUsers);
  //     setFarmerValue(response?.data?.response?.linkedFarmers);
  //   } catch (error) {
  //     console.error("There was an error!", error);
  //   }
  //   // setSelectedNodeData(item);
  //   // setUserValue(item.linkedBackOfficeUsers);
  //   // setFarmerValue(item.linkedFarmers);
  // };

  // const handleNodeSelect = async (item) => {
  //   setResponsibleValue("");

  //   if (!item || !levelList || levelList.length === 0) {
  //     console.log("Invalid item or levelList is empty.");
  //     return;
  //   }

  //   const matchingLevel = levelList.find(
  //     (level) => level.hlcId === item.levelId
  //   );

  //   if (matchingLevel) {
  //     const currentLevelOrder = matchingLevel.hlcLevelOrder;

  //     // Check if the current level is the last one in the levelList
  //     const isLastLevel =
  //       currentLevelOrder ===
  //       Math.max(...levelList.map((level) => level.hlcLevelOrder));

  //     if (!isLastLevel) {
  //       // Find the next level if the current level is not the last one
  //       const nextLevel = levelList.find(
  //         (level) => level.hlcLevelOrder === currentLevelOrder + 1
  //       );

  //       if (nextLevel) {
  //         console.log("Next level object:", nextLevel);
  //         setNextLevelName(nextLevel.hlcLevelName);
  //         setNewLevelId(nextLevel.hlcId);
  //       } else {
  //         console.log("No next level found.");
  //       }
  //     } else {
  //       // If it's the last level, do not set next level name or id
  //       console.log("Current level is the last level.");
  //       setNextLevelName("");
  //       setNewLevelId("");
  //     }
  //   } else {
  //     console.log("No matching level found.");
  //   }

  //   console.log("Node is clicked", item);
  //   setSelectedNodeData(item);
  //   setParentId(item?.childHierarchies[0]?.parentId || item.id);
  //   setLevelId(item?.childHierarchies[0]?.levelId);
  //   setUserValue(item?.linkedBackOfficeUsers || []);
  //   setFarmerValue(item?.linkedFarmers || []);

  //   console.log("Node is clicked, ID:", item.id);
  //   try {
  //     const response = await GrowingHierarchyService.getByVersion({
  //       id: item.id,
  //     });
  //     console.log("API Response:", response);

  //     if (response?.data?.response) {
  //       setNodeData(response.data.response);
  //       setUserValue(response.data.response.linkedBackOfficeUsers || []);
  //       setFarmerValue(response.data.response.linkedFarmers || []);
  //     } else {
  //       console.log("No response data found.");
  //     }
  //   } catch (error) {
  //     console.error("There was an error fetching node data!", error);
  //   }
  // };


  const [firstLevelState,setFirstLevelState] = useState(false)

  const handleNodeSelect = async (item) => {
    setResponsibleValue("");
  
    if (!item || !levelList || levelList.length === 0) {
      console.log("Invalid item or levelList is empty.");
      return;
    }
  
    const matchingLevel = levelList.find(
      (level) => level.hlcId === item.levelId
    );
  
    if (matchingLevel) {
      const currentLevelOrder = matchingLevel.hlcLevelOrder;
  
      // Check if the current level is the first one in the levelList
      const isFirstLevel = currentLevelOrder === 1;
  
      if (isFirstLevel) {
        console.log("Current level is the first level.");
        setFirstLevelState(true);  
      } else {
        setFirstLevelState(false);
      }
  
      // Check if the current level is the last one in the levelList
      const isLastLevel =
        currentLevelOrder ===
        Math.max(...levelList.map((level) => level.hlcLevelOrder));
  
      if (!isLastLevel) {
        // Find the next level if the current level is not the last one
        const nextLevel = levelList.find(
          (level) => level.hlcLevelOrder === currentLevelOrder + 1
        );
  
        if (nextLevel) {
          console.log("Next level object:", nextLevel);
          setNextLevelName(nextLevel.hlcLevelName);
          setNewLevelId(nextLevel.hlcId);
        } else {
          console.log("No next level found.");
        }
      } else {
        // If it's the last level, do not set next level name or id
        console.log("Current level is the last level.");
        setNextLevelName("");
        setNewLevelId("");
      }
    } else {
      console.log("No matching level found.");
    }
  
    console.log("Node is clicked", item);
    setSelectedNodeData(item);
    setParentId(item?.childHierarchies[0]?.parentId || item.id);
    setLevelId(item?.childHierarchies[0]?.levelId);
    setUserValue(item?.linkedBackOfficeUsers || []);
    setFarmerValue(item?.linkedFarmers || []);
  
    console.log("Node is clicked, ID:", item.id);
    try {
      const response = await GrowingHierarchyService.getByVersion({
        id: item.id,
      });
      console.log("API Response:", response);
  
      if (response?.data?.response) {
        setNodeData(response.data.response);
        setUserValue(response.data.response.linkedBackOfficeUsers || []);
        setFarmerValue(response.data.response.linkedFarmers || []);
      } else {
        console.log("No response data found.");
      }
    } catch (error) {
      console.error("There was an error fetching node data!", error);
    }
  };
  

  const findNodeById = (nodes, nodeId) => {
    // Function to find node by ID in the tree data
    for (let node of nodes) {
      if (node.id === nodeId) {
        return node;
      } else if (node.children) {
        const found = findNodeById(node.children, nodeId);
        if (found) return found;
      }
    }
    return null;
  };

  const handleDeleteChip = (userName) => {
    const updatedUserValue = userValue.filter(
      (user) => (user.userName || user.bouName) !== userName
    );
    setUserValue(updatedUserValue);
  };

  // const filterHierarchy = (data) => {
  //   return data.flatMap((item) => {
  //     const matches = item?.ihLocationName
  //       ?.toLowerCase()
  //       ?.includes(searchInput);
  //     const children = filterHierarchy(item?.childHierarchies);
  //     return matches ? [{ ...item, childHierarchies: children }] : children;
  //   });
  // };

  const linkedFarmer = farmerValue?.map((farmer) => ({
    fhlID: farmer?.fhlID || "",
    farmerId: farmer.farmerId,
    farmerName: farmer?.farmerFullName || farmer.farmerName,
    farmerCode: farmer.farmerCode,
    isDeleted: farmer.isDeleted || false,
  }));

  const linkedFarmerAdd = farmerAddValue?.map((farmer) => ({
    fhlID: farmer?.fhlID || "",
    farmerId: farmer.farmerId,
    farmerName: farmer?.farmerFullName || farmer.farmerName,
    farmerCode: farmer.farmerCode,
    isDeleted: false,
  }));

  useEffect(() => {
    if (nodeData && nodeData.responsibleFieldTechnicianId) {
      const matchedUser = userList.find(
        (user) => user?.userId === nodeData.responsibleFieldTechnicianId
      );
      console.log("🚀 ~ useEffect ~ matchedUser:", matchedUser);
      if (matchedUser) {
        setResponsibleValue(matchedUser);
      }
    }
  }, [nodeData.responsibleFieldTechnicianId, userList]);

  // useEffect(() => {
  //   if (nodeData && nodeData?.linkedBackOfficeUsers?.length > 0) {
  //     const updatedUserValues = userValue.map((user) => {
  //       const matchedUser = userList.find(
  //         (item) => item.userName === user.bouName
  //       );
  //       return matchedUser || user; // Return matched user if found, otherwise return the original user
  //     });
  //     console.log(
  //       "🚀 ~ updatedUserValues ~ updatedUserValues:",
  //       updatedUserValues
  //     );

  //     setUserValue(updatedUserValues);
  //   }
  // }, [nodeData.linkedBackOfficeUsers, userList]);

  const handleSave = async () => {
    const payload = {
      modifiedDateTimeUTC: user.modifiedDateTimeUTC || new Date().toISOString(),
      modifiedByUser: user.modifiedByUser || "Rahul D",
      ihvId: versionData[0].ihvId,
      id: selectedNodeData.id,
      ihLocationCode: nodeData.locationCode || "",
      ihLocationName: nodeData.ihLocationName,
      ihLegacyCode: nodeData.ihLegacyCode || "",
      // ihPersonName: selectedNodeData.personName || "",
      // responsibleFieldTechnicianId:
      //   selectedNodeData.responsibleFieldTechnicianId || "",
      responsibleFieldTechnicianId: responsibleValue?.userId || "",
      linkedFarmers: [...linkedFarmer],
      linkedBackOfficeUsers: userValue?.map((user) => ({
        backOfficeUserId: user.backOfficeUserId || user.userId,
        bouName: user.bouName || user.userName,
        isDeleted: user.isDeleted || false,
        bouId: user.bouId || "",
      })),
      isDelete: selectedNodeData.isDelete || false,
      levelId: selectedNodeData.levelId,
      parentId: selectedNodeData.parentId,
      // languageId: selectedNodeData.languageId || "",
      // countryId: selectedNodeData.countryId || "",
    };
    try {
      const response = await GrowingHierarchyService.updateNodeData(payload);
      // setTreeList([response?.data?.response] || []);
      // setFilteredTreeList([response?.data?.response] || []);
      // setCode(response?.data?.response?.importHierarchyVersionDto?.ihvCode);
      // setStartDate(
      //   dayjs(response?.data?.response?.importHierarchyVersionDto?.startDate)
      // );
      if (response.status === 201) {
        fetchHierachyDetails();
        setUserInputValue("");
        setFarmerInputValue("");
        setResetInputField((prev) => !prev);
        setSeverity("success");
        setMessage("Success");
        setOpenAlert(true);
      }
    } catch (error) {
      console.error("There was an error!", error);
      setSeverity("error");
      setMessage("Something went wrong");
      setOpenAlert(true);
    }
  };

  // const handleAddNewNode = async () => {
  //   const payload = {
  //     createdDateTimeUTC: user.modifiedDateTimeUTC || new Date().toISOString(),
  //     createdByUser: user.modifiedByUser || "Rahul D",
  //     modifiedDateTimeUTC: user.modifiedDateTimeUTC || new Date().toISOString(),
  //     modifiedByUser: user.modifiedByUser || "Rahul D",
  //     ihvId: versionData[0].ihvId,
  //     // id: selectedNodeData.id,
  //     ihLocationCode: addNodeValues.locationCode || "",
  //     ihLocationName: addNodeValues.ihLocationName,
  //     ihLegacyCode: addNodeValues.ihLegacyCode || "",
  //     // ihPersonName: selectedNodeData.personName || "",
  //     responsibleFieldTechnicianId: addResponsible?.userId || "",
  //     linkedFarmers: [...linkedFarmerAdd],
  //     linkedBackOfficeUsers: addUserValue?.map((user) => ({
  //       createdDateTimeUTC: user.createdDateTimeUTC || new Date().toISOString(),
  //       createdByUser: user.createdByUser || "",
  //       backOfficeUserId: user.userId,
  //       bouName: user.bouName || user.userName,
  //       userName: user.userName,
  //       isDeleted: user.isDeleted || false,
  //     })),
  //     isDelete: selectedNodeData.isDelete || false,
  //     levelId: levelId || newLevelId,
  //     parentId: parentId,
  //     // languageId: selectedNodeData.languageId || "",
  //     // countryId: selectedNodeData.countryId || "",
  //   };
  //   try {
  //     const response = await GrowingHierarchyService.updateNodeData(payload);

  //     // setTreeList([response?.data?.response] || []);
  //     // setFilteredTreeList([response?.data?.response] || []);
  //     // setCode(response?.data?.response?.importHierarchyVersionDto?.ihvCode);
  //     // setStartDate(
  //     //   dayjs(response?.data?.response?.importHierarchyVersionDto?.startDate)
  //     // );

  //     if (response.status === 201) {
  //       fetchHierachyDetails();
  //       setAddNodeValues("");
  //       setResetInputField((prev) => !prev);
  //       setAddFarmerValue([]);
  //       setAddUserValue([]);
  //       setModalOpen(false);
  //       setSeverity("success");
  //       setMessage("Success");
  //       setOpenAlert(true);
  //     }
  //   } catch (error) {
  //     console.error("There was an error!", error);
  //     setSeverity("error");
  //     setMessage("Something went wrong");
  //     setOpenAlert(true);
  //   }
  // };

  const handleAddNewNode = async () => {
    if (
      !addNodeValues.ihLegacyCode ||
      !addNodeValues.ihLocationName ||
      !addResponsible
    ) {
      setSeverity("error");
      setMessage("Please fill out all required fields");
      setOpenAlert(true);
      return;
    }

    const payload = {
      createdDateTimeUTC: user.modifiedDateTimeUTC || new Date().toISOString(),
      createdByUser: user.modifiedByUser || "Rahul D",
      modifiedDateTimeUTC: user.modifiedDateTimeUTC || new Date().toISOString(),
      modifiedByUser: user.modifiedByUser || "Rahul D",
      ihvId: versionData[0].ihvId,
      ihLocationCode: addNodeValues.locationCode || "",
      ihLocationName: addNodeValues.ihLocationName,
      ihLegacyCode: addNodeValues.ihLegacyCode || "",
      responsibleFieldTechnicianId: addResponsible?.userId || "",
      linkedFarmers: [...linkedFarmerAdd],
      linkedBackOfficeUsers: addUserValue?.map((user) => ({
        createdDateTimeUTC: user.createdDateTimeUTC || new Date().toISOString(),
        createdByUser: user.createdByUser || "",
        backOfficeUserId: user.userId,
        bouName: user.bouName || user.userName,
        userName: user.userName,
        isDeleted: user.isDeleted || false,
      })),
      isDelete: selectedNodeData.isDelete || false,
      levelId: levelId || newLevelId,
      parentId: parentId,
    };

    try {
      setLoading(true);
      const response = await GrowingHierarchyService.updateNodeData(payload);

      if (response.status === 201) {
        fetchHierachyDetails();
        setAddNodeValues("");
        setResetInputField((prev) => !prev);
        setAddFarmerValue([]);
        setAddUserValue([]);
        setModalOpen(false);
        setSeverity("success");
        setMessage("Success");
        setOpenAlert(true);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("There was an error!", error);
      setSeverity("error");
      setMessage("Something went wrong");
      setOpenAlert(true);
    }
  };

  // const handleSaveVersion = async () => {
  //   function convertDateFormatUpdate(dateStr) {
  //     if (typeof dateStr !== "string") {
  //       // If dateStr is not a string, return it as-is
  //       return dateStr;
  //     }

  //     // Check if the date is in dd/MM/yyyy format
  //     if (dateStr.includes("/")) {
  //       const [day, month, year] = dateStr.split("/");
  //       return `${year}-${month}-${day}`;
  //     }

  //     // If the date is already in ISO format, just return the date part
  //     if (dateStr.includes("T")) {
  //       return dateStr.split("T")[0];
  //     }

  //     // If the date format is unrecognized, return it as-is
  //     return dateStr;
  //   }

  //   const startDateUpdate = convertDateFormatUpdate(
  //     startDate || versionData[0].startDate
  //   );
  //   const endDateUpdate = convertDateFormatUpdate(
  //     endDate || versionData[0].endDate
  //   );

  //   console.log("endDate",endDateUpdate)
  //   console.log("start",startDateUpdate)

  //   const payload = {
  //     modifiedDateTimeUTC: new Date().toISOString(),
  //     modifiedByUser: "Rahul D",
  //     ihvId: versionData[0].ihvId,
  //     ihvCode: versionData[0].ihvCode || code,
  //     startDate: startDateUpdate,
  //     endDate: endDateUpdate,
  //     status: false,
  //     isDeleted: false,
  //     // languageId: optional,
  //     // countryId: optional
  //   };

  //   try {
  //     const response = await GrowingHierarchyService.generateVersion(payload);
  //     // setTreeList([response?.data?.response] || []);
  //     // setFilteredTreeList([response?.data?.response] || []);
  //     // setCode(response?.data?.response?.importHierarchyVersionDto?.ihvCode);
  //     // setStartDate(
  //     //   dayjs(response?.data?.response?.importHierarchyVersionDto?.startDate)
  //     // );
  //   } catch (error) {
  //     console.error("There was an error!", error);
  //   }
  // };
  function convertDateFormatUpdate(dateStr) {
    // Check if the input is a dayjs-like object
    if (
      typeof dateStr === "object" &&
      dateStr !== null &&
      "$y" in dateStr &&
      "$M" in dateStr &&
      "$D" in dateStr
    ) {
      const year = dateStr.$y;
      const month = String(dateStr.$M + 1).padStart(2, "0"); // $M is zero-indexed, so add 1
      const day = String(dateStr.$D).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }

    // If dateStr is a string, proceed with the original checks
    if (typeof dateStr === "string") {
      // Check if the date is in dd/MM/yyyy format
      if (dateStr.includes("/")) {
        const [day, month, year] = dateStr.split("/");
        return `${year}-${month}-${day}`;
      }

      // Check if the date is in ISO 8601 format
      if (dateStr.includes("T")) {
        const isoDate = new Date(dateStr);
        const year = isoDate.getFullYear();
        const month = String(isoDate.getMonth() + 1).padStart(2, "0");
        const day = String(isoDate.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      }
    }

    // If the date format is unrecognized, return it as-is
    return dateStr;
  }

  const handleSaveVersion = async () => {
    const startDateUpdate = startDate
      ? convertDateFormatUpdate(startDate)
      : convertDateFormatUpdate(versionData[0]?.startDate);

    const endDateUpdate = endDate
      ? convertDateFormatUpdate(endDate)
      : convertDateFormatUpdate(versionData[0]?.endDate);

    console.log("eefefef", endDateUpdate);
    console.log("start", startDateUpdate);

    const payload = {
      modifiedDateTimeUTC: new Date().toISOString(),
      modifiedByUser: "Rahul D",
      ihvId: versionData[0]?.ihvId,
      ihvCode: versionData[0]?.ihvCode || code,
      startDate: startDateUpdate,
      endDate: endDateUpdate,
      status: false,
      isDeleted: false,
      // languageId: optional,
      // countryId: optional
    };

    try {
      const response = await GrowingHierarchyService.generateVersion(payload);
      // Handle the response here
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const handleSaveData = () => {
    handleSaveVersion();
    handleSave();
  };

  const handleDeleteFarmerChip = (farmer) => {
    setFarmerValue((current) =>
      current.map((f) =>
        (f.fhlID || f.tempId) === (farmer.fhlID || farmer.tempId)
          ? { ...f, isDeleted: true } // Mark as deleted
          : f
      )
    );
  };

  const handleFarmerChange = (event, newValue) => {
    const updatedValues = newValue.map((farmer) => ({
      ...farmer,
      tempId: farmer.fhlID ? null : uuidv4(),
    }));

    const combinedValues = [...farmerValue, ...updatedValues];

    const uniqueValues = combinedValues.reduce((acc, farmer) => {
      const nameToCheck = farmer.farmerFullName || farmer.farmerName;

      const isDuplicate = acc.some(
        (f) => (f.farmerFullName || f.farmerName) === nameToCheck
      );

      if (!isDuplicate) {
        acc.push(farmer);
      }
      return acc;
    }, []);

    setFarmerValue(uniqueValues);
  };

  const handleFarmerAddChange = (event, newValue) => {
    const updatedValues = newValue.map((farmer) => ({
      ...farmer,
      tempId: farmer.fhlID ? null : uuidv4(),
    }));

    setAddFarmerValue(updatedValues);
  };

  const normalizedFarmerList = farmerList.map((farmer) => ({
    ...farmer,
    farmerFullName: farmer.farmerFullName || farmer.farmerName,
    tempId: farmer.fhlID ? null : uuidv4(),
  }));

  const handleUserChange = (event, newValue) => {
    const combinedValues = [...userValue, ...newValue];

    const uniqueValues = combinedValues.reduce((acc, user) => {
      const identifier = user.userName || user.bouName;
      const isDuplicate = acc.some(
        (u) => (u.userName || u.bouName) === identifier
      );
      if (!isDuplicate) {
        acc.push(user);
      }
      return acc;
    }, []);

    setUserValue(uniqueValues);
  };

  const handleDelete = async () => {
    try {
      const response = await GrowingHierarchyService.deleteLevel(nodeData.id);
      console.log("🚀 ~ handleDelete ~ response:", response);
      if (response.status === 200) {
        fetchHierachyDetails();
      }
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const handleDeleteUserChip = (user) => {
    setUserValue((current) =>
      current.map((u) =>
        (u.bouId || u.userId) === (user.bouId || user.userId)
          ? { ...u, isDeleted: true } // Mark as deleted
          : u
      )
    );
  };

  const handleDateChange = (date, setDate) => {
    if (!date || isNaN(new Date(date).getTime())) {
      setDateError(true);
      setDate("");
      return;
    }

    setDateError(false);
    const dateValue = new Date(date);
    const year = dateValue.getFullYear();
    const month = String(dateValue.getMonth() + 1).padStart(2, "0");
    const day = String(dateValue.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setDate(formattedDate);
  };

  const renderTreeItems = (hierarchies) =>
    hierarchies?.map((item, index) => {
      return (
        <TreeItem
          key={index}
          sx={{ m: 2 }}
          nodeId={`node-${item.id}`}
          onClick={() => handleNodeSelect(item)}
          label={
            <span
              style={{
                color: "#102A62",
                display: "flex",
                alignItems: "center",
                fontWeight: "600",
                fontSize: "1.25rem",
              }}
            >
              <PermIdentityIcon
                sx={{
                  width: 30,
                  height: 30,
                  background: "#D9D9D9",
                  mr: 2,
                  borderRadius: "4px",
                }}
                style={{ color: "#102A62", fontSize: 40 }}
              />
              {item.locationName}
            </span>
          }
        >
          {item?.childHierarchies?.length > 0 &&
            renderTreeItems(item.childHierarchies)}
        </TreeItem>
      );
    });

  return (
    <Layout title="Hierarchy Tree Configuration">
      <Box
        component="section"
        sx={{ padding: "16px 16px 0 16px", border: "1px" }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Box display={"flex"} gap={4}>
            <Grid>
              <TextField
                name=""
                label=""
                value={code}
                sx={{ width: 240, mr: 3 }}
                placeholder="Enter code"
                InputProps={{
                  style: {
                    backgroundColor: "#f0f0f0",
                    borderColor: "#102A62",
                    borderWidth: "1px",
                    borderStyle: "solid",
                  },
                }}
              />
            </Grid>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container>
                <Grid>
                  <FormControl sx={{ minWidth: "270px" }}>
                    <DatePicker
                      sx={{ width: 240, mr: "50.3px" }}
                      label="Start Date"
                      format="DD/MM/YYYY"
                      value={startDate}
                      maxDate={dayjs(endDate ? endDate : null)}
                      onChange={(newValue) =>
                        handleDateChange(newValue, setStartDate)
                      }
                      slotProps={{
                        field: {
                          readOnly: true,
                        },
                        textField: {
                          onClick: () => setOpen(true),
                        },
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid>
                  <FormControl sx={{ minWidth: "270px" }}>
                    <DatePicker
                      label="End Date"
                      format="DD/MM/YYYY"
                      sx={{ width: 240 }}
                      value={endDate || null}
                      minDate={dayjs(startDate ? startDate : null)}
                      slotProps={{
                        field: {
                          readOnly: true,
                        },
                        textField: {
                          onClick: () => setOpenEndDate(true),
                        },
                      }}
                      onChange={(newValue) => {
                        handleDateChange(newValue, setEndDate);
                      }}
                      error={false}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={false}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </LocalizationProvider>
          </Box>
          <Box component="section" sx={{ p: 2, border: "1px" }}>
            <Button
              style={{
                backgroundColor: "#004F9F",
                margin: "10px",
                minWidth: "100px",
              }}
              variant="contained"
            >
              Rezone
            </Button>

            <Button
              style={{
                float: "right",
                marginBottom: "40px",
                background: "#EF7D00",
                marginTop: "10px",
              }}
              type="submit"
              variant="contained"
              onClick={handleSaveData}
            >
              Save Changes
            </Button>
          </Box>
        </Box>
      </Box>

      <Modal
          open={deleteOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              sx={{
                mt: 4,
                justifyContent: "center",
                display: "flex",
                gap: "1rem",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={deleteButton}
                alt="Delete Button Icon"
                style={{ height: "100px" }}
              />
              <div>Are you sure!</div>
              <div>You want to delete</div>
            </Box>
            <Box
              sx={{
                mt: 4,
                justifyContent: "center",
                display: "flex",
                gap: "1rem",
              }}
            >
              <CustomButton variant="cancel" label="No" onClick={handleNo} />

              <CustomButton
                color="#EF7D00"
                label="Yes"
                onClick={handleDelete}
              />
            </Box>
          </Box>
        </Modal> 

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Box>
              <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{
                  height: "100%",
                  flexGrow: 1,
                  maxWidth: "100%",
                  overflowY: "auto",
                }}
                defaultExpanded={[
                  "5", // The nodeId for "Hierarchy Level"
                  ...filteredTreeList?.reduce((acc, hierarchy, index) => {
                    acc.push(`5-${index}`);
                    const childNodeIds =
                      hierarchy?.importHierarchyTreeDto?.childHierarchies.map(
                        (child, idx) => `5-${index}-${idx}`
                      );
                    return acc.concat(childNodeIds);
                  }, []),
                ]}
              >
                <TreeItem
                  nodeId="5"
                  label="Hierarchy Level"
                  defaultExpanded={true}
                >
                  <Box
                    mt={2}
                    sx={{ paddingBottom: "1rem", position: "relative" }}
                  >
                    <TextField
                      value={searchInput}
                      onChange={handleSearchInputChange}
                      fullWidth
                      className="farmerTextField"
                      placeholder="Search"
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={iconSearchs} alt="Search" />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {filteredTreeList?.map((hierarchy, index) => {
                      return (
                        <Box
                          key={index}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          mb={2}
                          marginTop={5}
                        >
                          <TreeView
                            aria-label="file system navigator"
                            defaultCollapseIcon={
                              <ExpandMoreIcon style={{ color: "#102A62" }} />
                            }
                            defaultExpandIcon={
                              <ChevronRightIcon style={{ color: "#102A62" }} />
                            }
                            // defaultExpanded={[
                            //   "5",
                            //   ...hierarchy.importHierarchyDto.childHierarchies.map(
                            //     (child, idx) => `5-${idx}`
                            //   ),
                            // ]}
                            sx={{
                              height: 240,
                              flexGrow: 1,
                              maxWidth: 400,
                              overflowY: "auto",
                              paddingBottom: "100px",
                            }}
                          >
                            <TreeItem
                              nodeId="5"
                              onClick={() =>
                                handleNodeSelect(
                                  hierarchy.importHierarchyTreeDto
                                )
                              }
                              label={
                                <span
                                  style={{
                                    color: "#0E2B63",
                                    display: "flex",
                                    alignItems: "center",
                                    fontWeight: "600",
                                    fontSize: "1.25rem",
                                  }}
                                >
                                  <PermIdentityIcon
                                    sx={{
                                      width: 30,
                                      height: 30,
                                      background: "#D9D9D9",
                                      mr: 2,
                                      borderRadius: "4px",
                                    }}
                                    style={{ color: "#102A62", fontSize: 40 }}
                                  />
                                  {
                                    hierarchy?.importHierarchyTreeDto
                                      ?.locationName
                                  }
                                </span>
                              }
                              defaultExpanded={true}
                            >
                              {renderTreeItems(
                                hierarchy?.importHierarchyTreeDto
                                  ?.childHierarchies
                              )}
                            </TreeItem>
                          </TreeView>
                        </Box>
                      );
                    })}
                  </Box>
                </TreeItem>
              </TreeView>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Box
              component="section"
              sx={{
                border: "1px  grey",
                backgroundColor: "#E9EBEE",
                padding: "20px",
              }}
            >
              <Box sx={{ paddingBottom: "5rem", height: "100%" }}>
                <TreeView
                  aria-label="file system navigator"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{
                    height: "100%",
                    flexGrow: 1,
                    maxWidth: "100%",
                    overflowY: "auto",
                  }}
                  defaultExpanded={["5", "6", "7"]}
                >
                  <TreeItem
                    nodeId="5"
                    label="Level Configuration"
                    defaultExpanded={true}
                  >
                    <Box
                      mt={2}
                      sx={{ paddingBottom: "1rem", position: "relative" }}
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}
                        background="pink"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: "10px",
                          }}
                        >
                          <FormControl sx={{ minWidth: "200px" }}>
                            <TextField
                              name="ihLegacyCode"
                              label="Code"
                              type="number"
                              fullWidth
                              value={nodeData.ihLegacyCode || ""}
                              onChange={handleInputChange}
                            />
                          </FormControl>
                          <FormControl sx={{ minWidth: "200px" }}>
                            <TextField
                              name="ihLocationName"
                              label="Name"
                              fullWidth
                              value={nodeData.ihLocationName}
                              onChange={handleInputChange}
                            />
                          </FormControl>
                          <FormControl
                            sx={{ maxWidth: "250px", minWidth: "250px" }}
                          >
                            <Autocomplete
                              id="tags-standard"
                              value={responsibleValue || null}
                              onChange={(event, newValue) => {
                                setResponsibleValue(newValue);
                              }}
                              // inputValue={selectedNodeData?.ihPersonName }
                              onInputChange={(event, newInputValue) => {
                                setResponsibleInputValue(newInputValue);
                              }}
                              options={userList}
                              getOptionLabel={(option) => option.userName || ""}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Select Responsible"
                                  placeholder="Add Responsible"
                                />
                              )}
                            />
                          </FormControl>
                          {nextLevelName && (
                            <Button
                              sx={{
                                borderRadius: 100,
                                minWidth: 95,
                                minHeight: 44,
                                background: "#102A62",
                                marginTop: { xs: 1 },
                                marginLeft: { xs: 1 },
                              }}
                              variant="contained"
                              startIcon={<AddCircleIcon />}
                              onClick={() => setModalOpen(true)}
                            >
                              Add New {nextLevelName}
                            </Button>
                          )}

                          {nextLevelName && !firstLevelState && (
                            <Button
                              sx={{
                                borderRadius: 10,
                                minWidth: 95,
                                minHeight: 20,
                                background: "#E9EBEE",
                                marginTop: { xs: 1 },
                                marginLeft: { xs: 1 },
                              }}
                              onClick={() => {
                                // setSelectedId(params.row.id);
                                // handleDelete
                                setDeleteOpen(true);
                              }}
                      
                            >
                              <DeleteIcon />
                            </Button>
                          )}
                        </Box>
                      </Box>
                    </Box>

                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TreeItem
                            nodeId="6"
                            label="Back office Users"
                            defaultExpanded={true}
                          >
                            <Box
                              sx={{
                                backgroundColor: "white",
                                paddingLeft: "20px",
                                paddingTop: "20px",
                                borderRadius: "4px",
                                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                minHeight: 500,
                                maxHeight: 500,
                                overflowY: "scroll",
                                justifyContent: "space-between",
                              }}
                            >
                              <FormControl
                                sx={{
                                  maxWidth: "400px",
                                  minWidth: "200px",
                                }}
                              >
                                <Autocomplete
                                  multiple
                                  id="tags-standard"
                                  key={resetInputField}
                                  //  value={userValue}
                                  defaultValue={[]}
                                  onChange={handleUserChange}
                                  inputValue={inputUserValue}
                                  onInputChange={(event, newInputValue) => {
                                    setUserInputValue(newInputValue);
                                  }}
                                  filterSelectedOptions
                                  freeSolo
                                  forcePopupIcon 
                                  options={userList}
                                  getOptionLabel={(option) =>
                                    option.userName || option.bouName || ""
                                  }
                                  // renderTags={(value, getTagProps) =>
                                  //   value.map((option, index) => {
                                  //     const { key, ...tagProps } = getTagProps({ index });
                                  //     return (
                                  //       <Chip variant="outlined" label={option} key={key} {...tagProps} />
                                  //     );
                                  //   })
                                  // }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label="Select Users"
                                      placeholder="Add Users"
                                    />
                                  )}
                                />
                              </FormControl>
                              <Button
                                sx={{
                                  borderRadius: 100,
                                  minWidth: 95,
                                  minHeight: 44,
                                  background: "#102A62",
                                  marginTop: { xs: 1 },
                                  marginLeft: { xs: 1 },
                                }}
                                variant="contained"
                                startIcon={<AddCircleIcon />}
                              >
                                Add
                              </Button>

                              <Stack
                                direction="row"
                                spacing={1}
                                sx={{ flexWrap: "wrap", marginTop: "2rem" }}
                              >
                                <Box
                                  sx={{
                                    marginTop: "2rem",
                                    maxWidth: "200px",
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {userValue
                                    ?.filter((user) => !user.isDeleted)
                                    .map((user) => (
                                      <Chip
                                        key={user?.bouId || user.userId}
                                        label={user?.bouName || user.userName}
                                        onDelete={() =>
                                          handleDeleteUserChip(user)
                                        }
                                        sx={{
                                          maxWidth: "100%",
                                          marginBottom: "5px",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          marginRight: "5px",
                                        }}
                                      />
                                    ))}
                                </Box>
                              </Stack>
                            </Box>
                          </TreeItem>
                        </Grid>

                        <Grid item xs={6}>
                          <TreeItem
                            nodeId="6"
                            label="Farmers"
                            defaultExpanded={true}
                          >
                            <Box
                              sx={{
                                backgroundColor: "white",
                                paddingLeft: "20px",
                                paddingTop: "20px",
                                borderRadius: "4px",
                                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                minHeight: 500,
                                maxHeight: 500,
                                overflowY: "scroll",
                                justifyContent: "space-between",
                              }}
                            >
                              <FormControl
                                sx={{
                                  maxWidth: "400px",
                                  minWidth: "200px",
                                }}
                              >
                                <Autocomplete
                                  multiple
                                  id="tags-standard"
                                  // value={farmerValue}
                                  key={resetInputField}
                                  onChange={handleFarmerChange}
                                  inputValue={inputFarmerValue}
                                  onInputChange={(event, newInputValue) => {
                                    setFarmerInputValue(newInputValue);
                                  }}
                                  options={normalizedFarmerList}
                                  getOptionLabel={(option) =>
                                    option.farmerFullName
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label="Select Farmers"
                                      placeholder="Add Farmers"
                                    />
                                  )}
                                />
                              </FormControl>
                              <Button
                                sx={{
                                  borderRadius: 100,
                                  minWidth: 95,
                                  minHeight: 44,
                                  background: "#102A62",
                                  marginTop: { xs: 1 },
                                  marginLeft: { xs: 1 },
                                }}
                                variant="contained"
                                startIcon={<AddCircleIcon />}
                              >
                                Add
                              </Button>

                              <Stack
                                direction="row"
                                spacing={1}
                                sx={{ flexWrap: "wrap", marginTop: "2rem" }}
                              >
                                <Box
                                  sx={{
                                    marginTop: "2rem",
                                    maxWidth: "200px",
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {farmerValue
                                    ?.filter((farmer) => !farmer.isDeleted)
                                    .map((farmer) => (
                                      <Chip
                                        key={farmer.fhlID || farmer.tempId}
                                        label={
                                          farmer.farmerFullName ||
                                          farmer.farmerName
                                        }
                                        onDelete={() =>
                                          handleDeleteFarmerChip(farmer)
                                        }
                                        sx={{
                                          maxWidth: "100%",
                                          marginBottom: "5px",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          marginRight: "5px",
                                        }}
                                      />
                                    ))}
                                </Box>
                              </Stack>
                            </Box>
                          </TreeItem>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ height: 10 }}></Box>
                  </TreeItem>
                </TreeView>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Modal
          open={moadalOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              sx={{
                mt: 4,
                justifyContent: "center",
                display: "flex",
                gap: "1rem",
                alignItems: "center",
                padding: "20px",
              }}
            >
              <Box sx={{ paddingBottom: "5rem", height: "100%" }}>
                <TreeView
                  aria-label="file system navigator"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{
                    height: "100%",
                    flexGrow: 1,
                    maxWidth: "100%",
                    overflowY: "auto",
                  }}
                  defaultExpanded={["5", "6", "7"]}
                >
                  <TreeItem
                    nodeId="5"
                    label="Level Configuration"
                    defaultExpanded={true}
                  >
                    <Box
                      mt={2}
                      sx={{ paddingBottom: "1rem", position: "relative" }}
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: "10px",
                          }}
                        >
                          <FormControl sx={{ minWidth: "200px" }}>
                            <TextField
                              name="ihLegacyCode"
                              label="Code"
                              fullWidth
                              type="number"
                              value={addNodeValues?.ihLegacyCode}
                              onChange={handleAddInputChange}
                              required
                            />
                          </FormControl>
                          <FormControl sx={{ minWidth: "200px" }}>
                            <TextField
                              name="ihLocationName"
                              label="Name"
                              fullWidth
                              value={addNodeValues?.ihLocationName}
                              onChange={handleAddInputChange}
                              required
                            />
                          </FormControl>
                          <FormControl
                            sx={{ maxWidth: "250px", minWidth: "250px" }}
                          >
                            <Autocomplete
                              id="tags-standard"
                              value={addResponsible || null}
                              key={resetInputField}
                              onChange={(event, newValue) => {
                                setAddResponsible(newValue);
                              }}
                              inputValue={selectedNodeData?.ihPersonName}
                              onInputChange={(event, newInputValue) => {
                                setResponsibleInputValue(newInputValue);
                              }}
                              options={userList}
                              getOptionLabel={(option) => option.userName}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Select Responsible"
                                  placeholder="Add Responsible"
                                  required
                                />
                              )}
                            />
                          </FormControl>

                          {/* <Button
                            sx={{
                              borderRadius: 10,
                              minWidth: 95,
                              minHeight: 20,
                              background: "#E9EBEE",
                              marginTop: { xs: 1 },
                              marginLeft: { xs: 1 },
                            }}
                          >
                            <DeleteIcon />
                          </Button> */}
                        </Box>
                      </Box>
                    </Box>

                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TreeItem
                            nodeId="6"
                            label="Back office Users"
                            defaultExpanded={true}
                          >
                            <Box
                              sx={{
                                backgroundColor: "white",
                                paddingLeft: "20px",
                                paddingTop: "20px",
                                borderRadius: "4px",
                                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                minHeight: 500,
                                maxHeight: 500,
                                overflowY: "scroll",
                                justifyContent: "space-between",
                              }}
                            >
                              <FormControl
                                sx={{
                                  maxWidth: "400px",
                                  minWidth: "200px",
                                }}
                              >
                                <Autocomplete
                                  id="tags-standard"
                                  multiple
                                  // value={userValue}
                                  onChange={(event, newValue) => {
                                    setAddUserValue(newValue);
                                  }}
                                  key={resetInputField}
                                  inputValue={inputAddUserValue}
                                  onInputChange={(event, newInputValue) => {
                                    setUserAddInputValue(newInputValue);
                                  }}
                                  options={userList}
                                  getOptionLabel={(option) => option.userName}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label="Select Users"
                                      placeholder="Add Users"
                                    />
                                  )}
                                />
                              </FormControl>
                              <Button
                                sx={{
                                  borderRadius: 100,
                                  minWidth: 95,
                                  minHeight: 44,
                                  background: "#102A62",
                                  marginTop: { xs: 1 },
                                  marginLeft: { xs: 1 },
                                }}
                                variant="contained"
                                startIcon={<AddCircleIcon />}
                              >
                                Add
                              </Button>

                              <Stack
                                direction="row"
                                spacing={1}
                                sx={{ flexWrap: "wrap", marginTop: "2rem" }}
                              >
                                <Box
                                  sx={{
                                    marginTop: "2rem",
                                    maxWidth: "200px",
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {addUserValue?.map((user) => (
                                    <Chip
                                      key={user?.bouId}
                                      label={user?.bouName || user.userName}
                                      onDelete={() => handleDeleteChip(user)}
                                      sx={{
                                        maxWidth: "100%",
                                        marginBottom: "5px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        marginRight: "5px",
                                      }} // Ensure each Chip can take full width
                                    />
                                  ))}
                                </Box>
                              </Stack>
                            </Box>
                          </TreeItem>
                        </Grid>

                        <Grid item xs={6}>
                          <TreeItem
                            nodeId="6"
                            label="Farmers"
                            defaultExpanded={true}
                          >
                            <Box
                              sx={{
                                backgroundColor: "white",
                                paddingLeft: "20px",
                                paddingTop: "20px",
                                borderRadius: "4px",
                                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                minHeight: 500,
                                maxHeight: 500,
                                overflowY: "scroll",
                                justifyContent: "space-between",
                              }}
                            >
                              <FormControl
                                sx={{
                                  maxWidth: "400px",
                                  minWidth: "200px",
                                }}
                              >
                                <Autocomplete
                                  multiple
                                  id="tags-standard"
                                  // value={farmerValue}
                                  key={resetInputField}
                                  onChange={handleFarmerAddChange}
                                  inputValue={inputFarmerAddValue}
                                  onInputChange={(event, newInputValue) => {
                                    setFarmerAddInputValue(newInputValue);
                                  }}
                                  options={farmerList}
                                  getOptionLabel={(option) =>
                                    option.farmerFullName
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label="Select Farmers"
                                      placeholder="Add Farmers"
                                    />
                                  )}
                                />
                              </FormControl>
                              <Button
                                sx={{
                                  borderRadius: 100,
                                  minWidth: 95,
                                  minHeight: 44,
                                  background: "#102A62",
                                  marginTop: { xs: 1 },
                                  marginLeft: { xs: 1 },
                                }}
                                variant="contained"
                                startIcon={<AddCircleIcon />}
                              >
                                Add
                              </Button>

                              <Stack
                                direction="row"
                                spacing={1}
                                sx={{ flexWrap: "wrap", marginTop: "2rem" }}
                              >
                                <Box
                                  sx={{
                                    marginTop: "2rem",
                                    maxWidth: "200px",
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {farmerAddValue?.map((farmer) => (
                                    <Chip
                                      key={farmer.fhlID || farmer.tempId}
                                      label={
                                        farmer.farmerFullName ||
                                        farmer.farmerName
                                      }
                                      onDelete={() => handleDeleteChip(farmer)}
                                      sx={{
                                        maxWidth: "100%",
                                        marginBottom: "5px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        marginRight: "5px",
                                      }} // Ensure each Chip can take full width
                                    />
                                  ))}
                                </Box>
                              </Stack>
                            </Box>
                          </TreeItem>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ height: 10 }}></Box>
                  </TreeItem>
                </TreeView>
              </Box>
            </Box>
            <Box
              sx={{
                mt: 4,
                justifyContent: "center",
                display: "flex",
                gap: "1rem",
                paddingBottom: "70px",
              }}
            >
              <CustomButton
                variant="cancel"
                label="No"
                onClick={() => setModalOpen(false)}
              />

              <CustomButton
                onClick={handleAddNewNode}
                color="#EF7D00"
                label="Yes"
                loading={loading}
              />
            </Box>
          </Box>
        </Modal>
      </Box>
    </Layout>
  );
};

export default GrowingHierarchy;
