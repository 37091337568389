import { httpGrow } from "../http-common";


const getVariety = (query) => {
  return httpGrow.get(
    `/variety?pageNumber=${query.pageNumber}&pageSize=${query.size}&countryCode=BRA`
  );
};
const getCuringUniqueCode = () => {
  return httpGrow.get(`/curingunit/CuringCode`);
};

const updateVariety = (data) => {
  return httpGrow.put(`/variety/update-variety-registration`, data);
};
const createVariety = (data) => {
  return httpGrow.post(
    `/variety/save-variety-registration`,data
  );
};
const searchVariety = ({ page, size, searchParams }) => {
  const params = {
    pageNumber: page,
    pageSize: size,
    ...searchParams,
  };
  console.log('Request URL:', httpGrow.getUri({ url: 'variety/variety-filter', params }));
  return httpGrow.get('variety/variety-filter', { params });
};
const searchVarietyUnitName= ({searchParam, page, size}) => {
  const params = {
    searchParam:searchParam,
    pageNumber: page,
    pageSize: size,
  };
  console.log('Request URL:', httpGrow.getUri({ url: 'variety/variety-filter', params }));
  return httpGrow.get('variety/variety-filter', { params });
};
const getCultureData = (countryCode) => {
  return httpGrow.get(`/variety/cultureList?countryCode=${countryCode}`);
};

const deleteVariety = (vrVarietyId) => {
  return httpGrow.delete(`/variety/${vrVarietyId}`);
}

const VarietyService = {
  getVariety,
  getCuringUniqueCode,
  updateVariety,
  createVariety,
  searchVariety,
  getCultureData,
  searchVarietyUnitName,
  deleteVariety
};
export default VarietyService;
 
 