import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  taxInformation: {
    bankName: "",
    branchNumber: "",
    accountType:"",
    accountNumber:"",
    taxFields: [{ stateRegistrationNumber: "", city: "", nfe: false }],
  },
};

const taxInformationSlice = createSlice({
  name: "taxInformation",
  initialState,
  reducers: {
    updateTaxInformation(state, action) {
      state.taxInformation = { ...state.taxInformation, ...action.payload };
    },
    addTaxField(state) {
      if (!state.taxInformation.taxFields) {
        state.taxInformation.taxFields = [];
      }
      state.taxInformation.taxFields.push({ stateRegistrationNumber: "", city: "", nfe: false });
    },
    updateTaxField(state, action) {
      const { index, name, value } = action.payload;
      if (state.taxInformation.taxFields && state.taxInformation.taxFields[index]) {
        state.taxInformation.taxFields[index][name] = value;
      }
    },
    clearTaxInformation(state) {
      state.taxInformation = initialState.taxInformation
    }
  },
});

export const { updateTaxInformation, addTaxField, updateTaxField, clearTaxInformation } = taxInformationSlice.actions;
export default taxInformationSlice.reducer;
