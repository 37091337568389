export const spacingField = [
    {
      name: "greenManure",
      label: "Green Manure",
      type: "text",
      required: true,
      width:"80%"
    },
    {
      name: "status",
      label: "Status",
      type: "switch",
    },
  ];

    
  export const editSpacingField = [
   
    {
      name: "greenManure",
      label: "Green Manure",
      type: "text",
      required: true,
      width:"80%"
    },
    {
      name: "status",
      label: "Status",
      type: "switch",
    },
  ];