// GetTrayRegistration.jsx
import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Autocomplete,
  Box,
  Grid,
  Button,
  FormControl,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import TrayTable from "./TrayTable";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TrayService from "services/TrayService";
import debounce from "lodash/debounce";
import Lable from "utils/lables.json";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { max } from "date-fns";
import CloseIcon from "@mui/icons-material/Close";

const GetTrayRegistration = () => {
  const navigate = useNavigate();
  const [trayData, setTrayData] = useState([]);
  const [filteredTrayData, setFilteredTrayData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  // const [itemsPerPage, setItemsPerPage] = useState(5);
  const [itemsPerPage, setItemsPerPage] = useState(() => {
    return parseInt(localStorage.getItem("IPPTTR")) || 5;
  });
  const [totalItems, setTotalItems] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [loss, setLossKeyword] = useState("");
  const [cellKeyword, setCellKeyword] = useState("");
  const [focused, setFocused] = useState(false);
  const [menu, setMenu] = useState([]);
  const [lossMenu, setLossMenu] = useState([]);
  const [NoofCellsMenu, setNoOfCells] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");

  const debouncedSearch = useCallback(
    debounce((value) => {
      //callSearchPlantingAPI(0, itemsPerPage, value); // Reset to page 0 for new search
    }, 0),
    [itemsPerPage]
  );

  const handleSearchChange = (value) => {
    console.log("cllll");
    setSearchKeyword(value);
  };
  const handleLossChange = (value) => {
    console.log("cllll");
    setLossKeyword(value);
  };
  const handleCellsChange = (value) => {
    console.log("cllll");
    setCellKeyword(value);
  };
  const handleStatusChange = (status) => {
    setStatusFilter(status); // Update the status filter
    setCurrentPage(0); // Reset to the first page when the status changes

    // Convert the status to a boolean value or null
    const isActive =
      status === "Active" ? true : status === "Inactive" ? false : null;

    // Call the API with the status filter
    callSearchTrayAPI(
      0,
      itemsPerPage,
      searchKeyword,
      cellKeyword,
      loss,
      isActive
    );
  };

  const callTrayAPI = (pageNumber, size) => {
    console.log("7654kjhgf", pageNumber, size);
    setLoading(true);
    TrayService.getTray({ pageNumber, size })
      .then((res) => {
        const trayResults = res?.data?.response?.trayTypes || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setTrayData(trayResults);
        setFilteredTrayData(trayResults);
        setTotalItems(res?.data?.response?.totalItems || 0);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setTrayData([]);
        setFilteredTrayData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getfullTrayMenuAPI = () => {
    // console.log("asdfghjkl");
    setLoading(true);
    TrayService.getTray({ pageNumber: currentPage, size: 50 })
      .then((res) => {
        console.log("ress", res);
        const trayResults = res?.data?.response?.trayTypes || [];
        const NoOfCells = trayResults.map((e) => e.ttNoOfCells || []);
        const menudes = trayResults.map((e) => e.ttTrayTypeDescription || []);
        const loss = trayResults.map((e) => e.ttLossPercentage || []);
        setMenu(menudes);
        setLossMenu(loss);
        setNoOfCells(NoOfCells);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setTrayData([]);
        setFilteredTrayData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const callSearchTrayAPI = (pageNumber, size, Keyword, cell, los, status) => {
    setLoading(true);
    setSearchKeyword(Keyword);

    let query = {
      pageNumber,
      size,
      ttTrayTypeDescription: Keyword,
      ttNoOfCells: cell,
      ttLossPercentage: los,
    };

    // Only add the status filter if it's defined (true or false)
    if (status !== null && status !== undefined) {
      query.status = status;
    }

    TrayService.searchTray(query)
      .then((res) => {
        const Results = res?.data?.response?.TrayTypes || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setTrayData(Results); // Update trayData
        setFilteredTrayData(Results); // Update filtered data
        setTotalItems(res?.data?.response?.totalItems || 0);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setTrayData([]);
        setFilteredTrayData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    callTrayAPI(currentPage, itemsPerPage); // Fetch default data
    console.log("cp", currentPage);
    if (currentPage <= 0) {
      getfullTrayMenuAPI();
    }
  }, [currentPage, itemsPerPage]);

  const paginate = (pageNumber, itemsPerPage) => {
    localStorage.setItem("IPPTTR", itemsPerPage);
    setCurrentPage(pageNumber - 1); // Adjust for 0-based index
    if (searchKeyword) {
      callSearchTrayAPI(pageNumber - 1, itemsPerPage, searchKeyword); // Fetch with search keyword
    } else {
      callTrayAPI(pageNumber - 1, itemsPerPage); // Fetch default data
    }
  };

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={"Tray Type List"}
      background="#ffffff"
      useNewPagination={true}
    >
      {/* Filters options */}
      <Grid
        container
        xs={12}
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          // padding: "1rem",
          padding: "8px",
          height: "130px",
        }}
      >
        <Grid item xs={12} sx={{ display: "flex" }}>
          <ArrowDropDownIcon sx={{ marginRight: 1 }} />
          <Typography>{Lable.filtersLable}</Typography>
        </Grid>

        {/* <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        > */}
        <Grid item xs={10} display="flex" justifyContent="space-evenly">
          <Box>
            <FormControl sx={{ width: "35vw", flexGrow: 1 }}>
              <Autocomplete
                disablePortal
                onChange={(event, newValue) => {
                  // const newValue = event.target.value;

                  // console.log("called change", newValue);

                  if (newValue) {
                    setSearchKeyword(newValue);
                    callSearchTrayAPI(
                      0,
                      itemsPerPage,
                      newValue,
                      cellKeyword,
                      loss
                    );
                  } else {
                    setSearchKeyword("");
                    callSearchTrayAPI(0, itemsPerPage, "", cellKeyword, loss);
                    // console.log("zxcvbnm,");
                    // callTrayAPI(currentPage, itemsPerPage);
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  if (newInputValue) handleSearchChange(newInputValue);
                  else getfullTrayMenuAPI();
                }}
                options={menu}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "7px", // Adjust padding for the input content
                    minHeight: "50px", // Adjust the overall height
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1.2em", // Adjust label spacing if needed
                  },
                }}
                InputProps={{
                  style: { height: "60%" }, // Directly setting the height
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="trayType"
                    label="Tray Type Description"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Box>

          <Box>
            <FormControl sx={{ width: "13vw" }}>
              <Autocomplete
                disablePortal
                onChange={(event, newValue) => {
                  console.log("called change", newValue);
                  if (newValue) {
                    setCellKeyword(newValue);
                    callSearchTrayAPI(
                      0,
                      itemsPerPage,
                      searchKeyword,
                      newValue,
                      loss
                    );
                  } else {
                    setCellKeyword("");
                    callSearchTrayAPI(0, itemsPerPage, searchKeyword, "", loss);
                    // callTrayAPI(currentPage, itemsPerPage);
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  if (newInputValue) handleCellsChange(newInputValue);
                  else getfullTrayMenuAPI();
                }}
                options={NoofCellsMenu}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "7px", // Adjust padding for the input content
                    minHeight: "50px", // Adjust the overall height
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1.2em", // Adjust label spacing if needed
                  },
                }}
                InputProps={{
                  style: { height: "60%" }, // Directly setting the height
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="noOfCells"
                    label="Number of Cells"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Box>

          <Box>
            <FormControl sx={{ width: "13vw" }}>
              <Autocomplete
                disablePortal
                onChange={(event, newValue) => {
                  // console.log("called change", newValue);
                  if (newValue) {
                    setLossKeyword(newValue);
                    callSearchTrayAPI(
                      0,
                      itemsPerPage,
                      searchKeyword,
                      cellKeyword,
                      newValue
                    );
                  } else {
                    setLossKeyword("");
                    callSearchTrayAPI(
                      0,
                      itemsPerPage,
                      searchKeyword,
                      cellKeyword,
                      ""
                    );
                    // callTrayAPI(currentPage, itemsPerPage);
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  if (newInputValue) handleLossChange(newInputValue);
                  else getfullTrayMenuAPI();
                }}
                options={lossMenu}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "7px", // Adjust padding for the input content
                    minHeight: "50px", // Adjust the overall height
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1.2em", // Adjust label spacing if needed
                  },
                }}
                InputProps={{
                  style: { height: "60%" }, // Directly setting the height
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="%loss"
                    label="% Loss"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Box>

          {/* <Grid item xs={1} mr={-15}></Grid> */}

          <Box>
            <FormControl sx={{ width: "13vw" }}>
              {/* <FormControl sx={{ minWidth: "7rem" }}> */}
              <TextField
                select
                name="status"
                label="Status"
                value={statusFilter || "PL"}
                onChange={(event) => handleStatusChange(event.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "7px",
                    minHeight: "50px",
                    // width: "10rem",
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1.2em",
                  },
                }}
                InputProps={{
                  style: { height: "50px" },
                }}
                SelectProps={{
                  endAdornment: (statusFilter === "Active" ||
                    statusFilter === "Inactive") && (
                    <CloseIcon
                      onClick={() => handleStatusChange("PL")}
                      style={{
                        width: "18px",
                        cursor: "pointer",
                        marginRight: "15px",
                      }}
                    />
                  ),
                }}
                // InputLabelProps={{ shrink: shrink }}
              >
                <MenuItem value="PL" disabled>
                  Select an option
                </MenuItem>
                <MenuItem
                  value="Active"
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: "#EFEFEF",
                    },
                    "&:hover": {
                      backgroundColor: "#EFEFEF",
                    },
                    backgroundColor:
                      statusFilter === "Active"
                        ? "#D3D3D3 !important"
                        : "transparent !important",
                    "&:hover": {
                      backgroundColor: "#D3D3D3 !important",
                    },
                  }}
                >
                  Active
                </MenuItem>
                <MenuItem
                  value="Inactive"
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: "#EFEFEF",
                    },
                    "&:hover": {
                      backgroundColor: "#EFEFEF",
                    },
                    backgroundColor:
                      statusFilter === "Inactive"
                        ? "#D3D3D3 !important"
                        : "transparent !important",
                    "&:hover": {
                      backgroundColor: "#D3D3D3 !important",
                    },
                  }}
                >
                  Inactive
                </MenuItem>
              </TextField>
            </FormControl>
          </Box>
        </Grid>

        <Grid item display="flex" justifyContent="flex-end" xs={2}>
          <Button
            sx={{
              borderRadius: 1,
              minWidth: 95,
              // minHeight: 44
              height: "60%",
              background: "#004F9F",
              //marginRight: "10px",
              //marginLeft:"20px"
            }}
            variant="contained"
            startIcon={<AddCircleIcon />}
            onClick={() => navigate("/addTrayTypeRegistration")}
          >
            ADD NEW
          </Button>
        </Grid>
      </Grid>

      {/* Do not Render table header if no data is found */}
      {filteredTrayData.length === 0 ? (
        <></>
      ) : (
        <TrayTable
          setCulturenull={() => setTrayData([])}
          callTrayAPI={() => callTrayAPI(currentPage, itemsPerPage)}
          trayData={filteredTrayData}
        />
      )}
    </Layout>
  );
};

export default GetTrayRegistration;
