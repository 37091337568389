// src\services\SoilAnalysisApplicationPhaseService.jsx
import { httpGrow } from "../http-common";

// User Story constants
const basename = "soilAnalysisApplication";

const getAPI = async (query) => {
    const { pageNumber = 0, size = 50, keyword = '', status } = query;
    const response = await httpGrow.get(`/${basename}/search`, {
        params: {
            pageNumber,
            pageSize: size,
            applicationPhase: keyword,
            status: status !== undefined ? status : undefined
        }
    });
    return response;
};

const createAPI = (data) => {
    return httpGrow.post(`/${basename}`, data);
};

const updateAPI = (data) => {
    return httpGrow.put(`/${basename}`, data);
};

const deleteAPI = (Id) => {
    return httpGrow.delete(`/${basename}/${Id}`);
};

const SoilAnalysisApplicationPhaseService = {
    getAPI,
    createAPI,
    updateAPI,
    deleteAPI
};

export default SoilAnalysisApplicationPhaseService;
