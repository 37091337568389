import { useEffect } from 'react';
import FetchCountry from 'services/FetchCountry';

const CountryFetcher = ({ setCountries, setLoading }) => {
  const fetchCountries = async () => {
    try {
      setLoading(true);
      const response = await FetchCountry.getCountriesList();
      if (response.status === 200) {
        setCountries(response.data.response);
      } else {
        console.error("Failed to fetch countries");
      }
    } catch (error) {
      console.error("Failed to fetch countries:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []); 

  return null;
};

export default CountryFetcher;
