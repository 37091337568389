import React from "react";
import styled from "@emotion/styled";
import Navbar from "../../navbar/Navbar";

const Home = () => {

  return (
    <React.Fragment>
        <Navbar/>
        <HomeContainer>
            Welcome
        </HomeContainer>
    </React.Fragment>
  );
};

const HomeContainer = styled.div`
margin-top:4rem;
`




export default Home;
