import { httpGrow } from "../http-common";

const getFieldNotebookSupplies = (queryParams) => {
    const params = {
        pageNumber: queryParams.pageNumber,
        size: queryParams.size,
        userInput: queryParams.userInput || "",
        supplyTypeId: queryParams.supplyTypeId || null,
        isActive: queryParams.isActive 
    };

    console.log("params",params)
    return httpGrow.get(
        `/suppliesFieldNotebook/list`, { params }
    );
};

const getFieldNotebookSupply = (fieldNotebookSupplyId) => {
    return httpGrow.get(`/suppliesFieldNotebook/${fieldNotebookSupplyId}`);
};

const saveFieldNotebookSupply = (data) => {
    return httpGrow.post(
        `/suppliesFieldNotebook`, data
    );
};

const getFieldNotebookSupplyType = () => {
    return httpGrow.get(`/supplyTypeFieldNotebook/dropdown`);
};

const FieldNotebookSupplyService = {
    getFieldNotebookSupplies: getFieldNotebookSupplies,
    getFieldNotebookSupply: getFieldNotebookSupply,
    saveFieldNotebookSupply: saveFieldNotebookSupply,
    getFieldNotebookSupplyType: getFieldNotebookSupplyType,
};

export default FieldNotebookSupplyService;