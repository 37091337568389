import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CustomButton from 'utils/CustomButton';
import { Input } from '@mui/base';
import { TextField } from '@mui/material';
import { SnackbarContext } from 'context/snackBarContext';
import { htmlToPlainText } from 'utils/utils';
import './SurityModal.css'



export default function SurityModal(props) {
    console.log('props', props)
    const [loading, setLoading] = React.useState(false);

    const {
        setOpenAlert,
        setSeverity,
        setMessage,
    } = React.useContext(SnackbarContext);

    const onNext = () => {
        if (props.string == "") {
            setMessage("Please enter level name to proceed")
            setOpenAlert(true)
            setSeverity("error")
        } else {
            props.onNext()
        }
    }

    return (
        <div>
            <Modal
                open={props.open}
                onClose={() => { props.onClose() }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box  className="SurityModalContainer">
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, justifyContent: "center" }}>
                        <Box sx={{ textAlign: "center" }}>
                            <img src={props?.iconName} />
                        </Box>
                        <Box sx={{ textAlign: "center" }}>
                            <Typography>{htmlToPlainText(props.message)}</Typography>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                gap: "1rem",
                                justifyContent: "center",
                            }}
                        >
                            <CustomButton loading={props.loading} variant="cancel" onClick={() => { props.onClose() }} label="No" />
                            <CustomButton loading={props.loading} variant="warning" onClick={() => { onNext() }} label="Yes" />
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
