import React from 'react';
import { useMsal, useAccount, useMsalAuthentication } from '@azure/msal-react';
import { http, httpAD } from './http-common';
import { loginRequest } from './authConfig';


const RequestInterceptor = ({ children }) => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0]);

    // Add a response interceptor
    http.interceptors.response.use(
        response => {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with successful response data
            return response;
        },
        error => {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Handle errors here
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Response error:', error.response.data);
                if (error.response.data.statusCode == 401) {
                    // return getValidToken(config)
                    window.open(window?.location?.href, "_self")
                }
                // You can add more sophisticated error handling here, such as showing notifications or alerts
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Request error:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error:', error.status);
            }
            console.error('Config:', error.config);
            // You can return a Promise.reject() if you want to propagate the error further
            return Promise.reject(error);
        }
    );
    // Add a request interceptor

    http.interceptors.request.use(async (config, error) => {
        return getValidToken(config)
    });
    const getValidToken = async (config) => {
        try {
            if (!account) {
                throw Error('No active account! Verify a user has been signed in.');
            }
            const response = await instance.acquireTokenSilent({
                ...loginRequest,
                account,
            });
            if (response) {
                const bearer = `Bearer ${response.accessToken}`;
                config.headers.Authorization = bearer;
                return config;
            }

        } catch (error) {
            console.log('Silent token acquisition failedd:', error);
            // Method to redirect to microsoft in case of refresh token expires to get new access and refresh token.
            // const silenntLogin = await instance.ssoSilent({
            //     ...loginRequest,
            //     account,
            // });


            // Method to popup to microsoft in case of refresh token expires to get new access and refresh token.
            // if (error instanceof InteractionRequiredAuthError) {
            instance
                .acquireTokenRedirect(
                    {
                        ...loginRequest,
                        account,
                    }
                )
                .then(function (accessTokenResponse) {
                    // Acquire token interactive success
                    let accessToken = accessTokenResponse.accessToken;
                    const bearer = `Bearer ${accessToken}`;
                    config.headers.Authorization = bearer;
                    return config;
                })
                .catch(function (error) {
                    // Acquire token interactive failure
                    console.log(error);
                    window.open(window?.location?.href, "_self")
                    // alert("Please close the tab and open application in new tab")
                });
            // }
        }
    }
    /* eslint-enable no-param-reassign */
    return (
        <>
            {children}
        </>
    );
};

export default RequestInterceptor;
