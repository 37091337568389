import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  Switch,
  CircularProgress,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { cultureGroupSchema, supplyTypeSchema } from "utils/validations";
import { SnackbarContext } from "context/snackBarContext";
import arrowRight from "assets/arrowRight.svg";
import arrowLeft from "assets/arrowLeft.svg";
import RedSwitch from "utils/RedSwitch";
import SupplyTypeService from "services/SupplyTypeService";
import { t } from "i18next";
import { useTranslation } from 'react-i18next';
import * as yup from "yup";



const AddSupplyType = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const location = useLocation();
  // console.log(location)
  const { description, status, edit, details, useNpkEdit, id, forPest } =
    location.state || {};
  console.log("🚀 ~ AddSupplyType ~ description:", description);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  //  const [useNPK, setUseNPK] = useState(useNpkEdit === true ? "Yes" : "No");
  const [useNPK, setUseNPK] = useState(useNpkEdit || "");
  //  const [forPestDisease, setPestDisease] = useState(forPest === true ? "Yes" : "No");
  const [forPestDisease, setPestDisease] = useState(forPest || "");
  const [values, setValues] = useState({
    supplyTypeDescription: description || "",
    status: status === true ? true : false,
    useNPK: useNPK,
    forPestDisease: forPestDisease
  });
  const [errors, setErrors] = useState({});
  console.log("supply type errors", errors);
  const [loading, setLoading] = useState(false);



  const handleChangNPKType = (event) => {
    setUseNPK(event.target.value);
    if (event.target.value == "Yes" && forPestDisease == "Yes") {
      setPestDisease("No")
    }
    setValues((prevValues) => ({
      ...prevValues,
      useNPK: event.target.value,
    }));
  };

  const handleChangePestDiseaseType = (event) => {
    setPestDisease(event.target.value);
    if (event.target.value == "Yes" && useNPK == "Yes") {
      setUseNPK("No")
    }
    setValues((prevValues) => ({
      ...prevValues,
      forPestDisease: event.target.value,
    }));
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  console.log("use npk and pest disease values", values);

  const supplyTypeSchema = yup.object().shape({
    supplyTypeDescription: yup
      .string()
      .required(t("supplyType.validations.supplyRequired"))
      .max(40, t("supplyType.validations.supplyMax"))
      // .matches(
      //   /^[a-zA-Z0-9 ]*$/,
      //   "Supply Type must not contain special characters"
      // )
      // .matches(
      //   /^\S.*\S$|^\S$/,
      //   "Supply Type Description must not have leading or trailing spaces"
      // ),
      .test(
        "no-leading-or-trailing-spaces",
        t("supplyType.validations.supplySpace"),
        (value) => !value || /^\S.*\S$|^\S$/.test(value)
      ),
    useNPK: yup.string().required(t("supplyType.validations.useNpk")),
    forPestDisease: yup
      .string()
      .required(t("supplyType.validations.forPestDisease")),
  
    // useNPK_group: yup
    // .boolean(),
  
    // pest_group : yup
    // .boolean().when("useNPK_group", (useNPK_group, supplyTypeSchema) => {
    //   is: true,
    //   then: () => yup
    //     .number()
    //     .required("Nitrogen % is required!")
    //     .min(0, "Percentage must be enter between 0-100!")
    //     .max(100, "Percentage must be enter between 0-100!")
    // })
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("handle submit click event");
    let schemaValidationErrors = {};
    try {
      await supplyTypeSchema.validate(values, { abortEarly: false });
    } catch (err) {
      err?.inner?.forEach((error) => {
        schemaValidationErrors[error.path] = error.message;
      });
      setErrors(schemaValidationErrors);
    }

    if (Object.keys(schemaValidationErrors).length === 0) {
      const payload = {
        supplyTypeDescription: values.supplyTypeDescription,
        useNPK: useNPK == "Yes" ? true : false,
        forPestDisease: forPestDisease == "Yes" ? true : false,
        isActive: values.status,
        createdByUser: "abc",
        createdDateTimeUTC: new Date(),
        countryCode: "BRA",
      };
      console.log("supply type description", payload);
      try {
        setLoading(true);
        const response = await SupplyTypeService.createSupply({ ...payload });
        if (response.status === 201) {
          setSeverity("success");
          setMessage(t("snackbarMessage.successAdd"));
          setOpenAlert(true);
          navigate("/supplyTypeList");
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        if (error.response && error.response.status === 409) {
          // console.log("error",error.response.data.message)
          setSeverity("error");
          setMessage(error.response.data.message);
          setOpenAlert(true);
          // navigate("/cultureRegistration");
        } else {
          console.error("Failed to submit form");
          setSeverity("error");
          setMessage(t("snackbarMessage.submissionFailed"));
          setOpenAlert(true);
        }
      }
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    let schemaValidationErrors = {};
    try {
      await supplyTypeSchema.validate(values, { abortEarly: false });
    } catch (err) {
      err?.inner?.forEach((error) => {
        schemaValidationErrors[error.path] = error.message;
      });
      setErrors(schemaValidationErrors);
    }
    if (Object.keys(schemaValidationErrors).length === 0) {
      const payload = {
        supplyTypeDescription: values.supplyTypeDescription,
        useNPK: useNPK == "Yes" ? true : false,
        forPestDisease: forPestDisease == "Yes" ? true : false,
        isActive: values.status,
        createdByUser: "abc",
        createdDateTimeUTC: new Date(),
        countryCode: "BRA",
        supplyTypeId: id,
      };
      try {
        setLoading(true);
        const response = await SupplyTypeService.updateSupply({ ...payload });
        setLoading(false);
        if (response.status === 200) {
          setSeverity("success");
          setMessage(t("snackbarMessage.updatedSuccessfully"));
          setOpenAlert(true);
          navigate("/supplyTypeList");
        }
      } catch (error) {
        setLoading(false);
        if (error.response) {
          const errorMessage = error.response.data.message;
          setMessage(errorMessage);
          setOpenAlert(true);
        } else {
          console.error("Failed to submit form");
          setSeverity("error");
          setMessage(t("snackbarMessage.updateFailed"));
          setOpenAlert(true);
        }
      }
    }
  };

  return (
    <Layout title={t("supplyType.addPageTitle")}>
      <Box
        sx={{
          minHeight: "125vh",
          flexGrow: 1,
          paddingBottom: "10rem",
          width: "100%",
          "& .MuiTextField-root": { m: 1 },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
          },
        }}
      >
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box sx={{ display: "flex", alignItems: "left" }}>
            <TextField
              name="supplyTypeDescription"
              label={t("supplyType.filterLabels.description")}
              value={values.supplyTypeDescription}
              onChange={handleChange}
              error={!!errors.supplyTypeDescription}
              helperText={errors.supplyTypeDescription}
              required
              variant="outlined"
              disabled={details}
              //sx={{ mt: 2, width: "40rem", height: "3.5rem" }}
              placeholder="-"
              InputLabelProps={{ shrink: true }}
              sx={{
                mt: 2,
                width: "40rem",
                height: "3.5rem",
                "& .MuiOutlinedInput-root": {
                  "&.Mui-disabled": {
                    "& fieldset": {
                      borderColor: "gray",
                      borderWidth: "1px",
                    },
                    "&:hover fieldset": {
                      borderColor: "gray",
                      borderWidth: "1px",
                    },
                  },
                },
                "& .MuiInputBase-input::placeholder": {
                  opacity: 1,
                },
                "& .MuiInputLabel-root": {
                  "&.Mui-disabled": {
                    color: "#102a62",
                  },
                },
              }}
            />

            <FormControl sx={{ ml: 5 }}>
              {/* <Typography color={"#0E2B63"}>Is Fertilizer?</Typography> */}
              <FormLabel
                sx={{
                  color: "#0E2B63",
                  // mb: 2,
                  ml: 0.5,
                  // mr:17,
                  // bgcolor:"red",
                  "&.Mui-focused": { color: "#0E2B63" },
                  "&:hover": { color: "#0E2B63" },
                }}
              >
                {t("supplyType.isFertilizer")}
                <span style={{ color: "red" }}> *</span>
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="useNPK_group"
                value={useNPK}
                onChange={(e) => {
                  handleChangNPKType(e);
                }}
                sx={{ minWidth: "200px" }}
              >
                <FormControlLabel
                  disabled={details}
                  value="Yes"
                  control={<Radio />}
                  label={t("yes")}
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontWeight: 500,
                    },
                  }}
                />
                <FormControlLabel
                  disabled={details}
                  value="No"
                  control={<Radio />}
                  label={t("no")}
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontWeight: 500,
                    },
                  }}
                />
              </RadioGroup>
              {errors.useNPK && <Typography color="error">{errors.useNPK}</Typography>}
            </FormControl>

            <FormControl >
              {/* <Typography color={"#0E2B63"}>For Pest/Disease?</Typography> */}
              <FormLabel
                sx={{
                  color: "#0E2B63",
                  // mb: 2,
                  ml: 0.5,
                  // mr:17,
                  // bgcolor:"red",
                  "&.Mui-focused": { color: "#0E2B63" },
                  "&:hover": { color: "#0E2B63" },
                }}
              >
                {t("supplyType.table.headers.Pest/Disease")}
                <span style={{ color: "red" }}> *</span>
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="pest_group"
                value={forPestDisease}
                onChange={(e) => {
                  handleChangePestDiseaseType(e);
                }}
                sx={{ minWidth: "200px" }}
              >
                <FormControlLabel
                  disabled={details}
                  value="Yes"
                  control={<Radio />}
                  label={t("yes")}
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontWeight: 500,
                    },
                  }}
                />
                <FormControlLabel
                  disabled={details}
                  value="No"
                  control={<Radio />}
                  label={t("no")}
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontWeight: 500,
                    },
                  }}
                />
              </RadioGroup>
              {errors.forPestDisease && <Typography color="error">{errors.forPestDisease}</Typography>}
            </FormControl>

            <Box
              sx={{
                ml: "10px",
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <FormControl component="fieldset" sx={{ mb: 3 }}>
                <FormLabel
                  sx={{
                    color: "#5A646E !important",
                    mb: 1.5,
                    ml: 2,
                    fontWeight: 500,
                    "&.Mui-focused": { color: "#5A646E" },
                    "&:hover": { color: "#5A646E" },
                  }}
                >
                  {t("supplyType.table.headers.Status")}
                  <span style={{ color: "red" }}> *</span>
                </FormLabel>
                <FormControlLabel
                  sx={{
                    ml: 2,
                    mb: 0,
                    display: "flex",
                    // alignItems: "center",
                    // gap: "0.4rem",
                  }}
                  control={
                    <RedSwitch
                      checked={values.status}
                      onChange={handleChange}
                      name="status"
                      color="primary"
                      disabled={details}
                    />
                  }
                  label={
                    <Box sx={{ width: "3rem", fontWeight: "500" }}>
                      {values.status === true ? t("active") : t("inactive")}
                    </Box>
                  }
                  labelPlacement="start"
                />
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "2rem",
              marginTop: "12.313rem",
              //   marginLeft :"44.063rem"
            }}
          >
            <Button
              style={{
                backgroundColor: "#737D86",
                padding: "6px 16px",
                borderRadius: "4px",
              }}
              variant="contained"
              onClick={() => navigate("/supplyTypeList")}
              disabled={loading}
              startIcon={<img src={arrowLeft} alt="Arrow" />}
            >
              {loading && <CircularProgress size={20} />}
              {details ? t("back") : t("cancel")}
            </Button>
            {!details && ( // Hide save button if location.state is present
              <Button
                sx={{
                  backgroundColor: "#004F9F",
                  padding: "6px 16px",
                  borderRadius: "4px",
                  color: "white",
                }}
                type="submit"
                variant="contained"
                endIcon={<img src={arrowRight} alt="Right Arrow" />}
                onClick={!edit ? handleSubmit : handleUpdate}
                disabled={loading}
              >
                {loading && <CircularProgress size={20} />}
                {!edit ? t("save") : t("update")}
                {" "}
                 {t("supplyType.addPageTitle")}
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </Layout>
  );
};

export default AddSupplyType;
