import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  Switch,
  CircularProgress,
  Typography,
  Modal,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { soilParameterSchema } from "utils/validations";
import TransporterGetService from "services/TransporterGetService";
import CultureGroupService from "services/CultureGroup";
import { SnackbarContext } from "context/snackBarContext";
import arrowRight from "assets/arrowRight.svg";
import arrowLeft from "assets/arrowLeft.svg";
import RedSwitch from "utils/RedSwitch";
import PlantingService from "services/PlantingService";
import Save from "assets/SaveNew.svg";
import TobaccoService from "services/TobaccoService";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import Lable from "utils/lables.json";
import SoilParameterService from "services/SoilParameterService";

export default function AddTransporterRegistration() {
  console.log("runninggggg");
  const navigate = useNavigate();

  const location = useLocation();
  console.log("vkkkk", location);
  const {
    sapAnalysisId,
    sapExternalCode,
    sapParameterName,
    sapAbbreviation,
    sapUom,
    sapDataType,
    sapStatus,
    countryId,
    languageId,
    isDelete,
  } = location?.state?.row || {};
  const { edit } = location.state || {};

  //console.log("dhoni", tobaccoCompanyName, tccCountryCode, region);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [values, setValues] = useState({
    bankCode: "",
    BusinessType: "Individual",
    bankRegistrationId:"",
    sapExternalCode: sapExternalCode,
    sapParameterName: sapParameterName || "", // Assuming this is the tray type description
    sapAbbreviation: sapAbbreviation || "", // Default to 0 if not provided
    sapUom: sapUom, // Default to 0 if not provided
    sapDataType: sapDataType,
    sapStatus: sapStatus === true ? true : false, // Default to false if not true
    countryId: countryId || "", // Set default empty string or any fallback value
    languageId: languageId || "", // Set default empty string or any fallback value
    isDelete: isDelete === true ? true : false, // Default to false if not true
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [nationality, setNationality] = useState([]);
  const [marital, setMarital] = useState([]);
  const [region, setRegion] = useState([]);
  const [dataType, setDataType] = useState([]);
  const [open, setOpen] = useState(false);
  const [bank,setBank]  = useState([]);
  const [branches, setBranches] = useState([]);

  // Function to open the modal
  const handleOpenModal = () => {
    setOpen(true);
  };

  const getNationalityList = async () => {
    try {
      const response = await TransporterGetService.getNationality();
      const d = response.data.response;
      setNationality(d.map((e) => e.nationality));
    } catch (error) {
      console.error("Error generating unique code:", error);
      return null;
    }
  };

  const getMaritalList = async () => {
    try {
      const response = await TransporterGetService.getMarital();
      const d = response.data.response;
      setMarital(d.map((e) => e.maritalStatus));
    } catch (error) {
      console.error("Error generating unique code:", error);
      return null;
    }
  };

  const getRegionList = async () => {
    try {
      const response = await TransporterGetService.getRegion();
      const d = response.data.response;
      setRegion(d.map((e) => e.stateCode));
    } catch (error) {
      console.error("Error generating unique code:", error);
      return null;
    }
  };

  const getBankList = async () => {
    try {
      const response = await TransporterGetService.getBankDetails();
      console.log("tys", response.data.response);
      const d = response.data.response;
      setBank(d);
    } catch (error) {
      console.error("Error generating unique code:", error);
      return null;
    }
  };


  useEffect(() => {
    getNationalityList();
    getMaritalList();
    getRegionList()
    getBankList()
  }, []);

  useEffect(() => {
    console.log("sdfghn", dataType);
  });
  // Function to close the modal
  const handleCloseModal = () => {
    setOpen(false);
  };

  // Function when user confirms "Yes" on the modal
  const handleDeleteConfirm = () => {
    setOpen(false);
    navigate("/transporterRegistration");
  };
  // Add function to fetch branch details
  const fetchBranchDetails = async (bankRegistrationId) => {
    try {
      const response =
        await TransporterGetService.getBankBranchDetails(
          bankRegistrationId
        );

      setBranches(response.data.response || []);
    } catch (error) {
      console.error("Error fetching branch details:", error);
      setMessage("Failed to fetch bank branch details");
      setSeverity("error");
      setOpenAlert(true);
      setBranches([]); // Reset branches on error
    }
  };


  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    console.log("valdd",value)
    setValues((prevValues) => {
      // If the changed field is bankRegistrationId
      if (name === "bankRegistrationId") {
        console.log("vvvv",value)
        console.log("allabanks",bank)
        const selectedBank = bank.find(
          (bank) => bank.bankRegistrationId === value
        );
        // Fetch branches for the selected bank
        fetchBranchDetails(value); // Call the function to fetch branches
        return {
          ...prevValues,
          [name]: value,
          bankCode: selectedBank ? selectedBank.bankCode : "",
          bankBranchRegistrationId: "", // Reset branch selection when bank changes
        };
      }

      // For all other fields, update normally
      return {
        ...prevValues,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };
  const handleSubmit = async (event) => {
    console.log("submit");
    event.preventDefault();
    let schemaValidationErrors = {};

    // Validation (assuming you have validation logic, uncomment if needed)
    try {
      await soilParameterSchema.validate(values, { abortEarly: false });
    } catch (err) {
      err?.inner?.forEach((error) => {
        schemaValidationErrors[error.path] = error.message;
      });
      setErrors(schemaValidationErrors);
    }
    if (Object.keys(schemaValidationErrors).length === 0) {
      const payload = {
        sapExternalCode: values?.sapExternalCode, // Use trimmed value
        sapParameterName: values?.sapParameterName,
        sapAbbreviation: values?.sapAbbreviation,
        sapUom: values?.sapUom,
        sapDataType: values?.sapDataType,
        sapStatus: values?.sapStatus,
        createdDateTimeUTC: new Date().toISOString(),
        createdByUser: "string", // Change this to actual user data
        modifiedDateTimeUTC: new Date().toISOString(),
        modifiedByUser: "string", // Change this to actual user data
      };

      console.log("payload hai", payload);

      try {
        setLoading(true);
        const response = await SoilParameterService.createSoilParameter({
          ...payload,
        });
        setLoading(false);

        if (response.status === 201) {
          setSeverity("success");
          setMessage("Record saved successfuly!");
          setOpenAlert(true);
          navigate("/soilParameter");
        }
      } catch (error) {
        setLoading(false);

        if (error.response && error.response.status === 409) {
          setSeverity("error");
          setMessage(error.response.data.message);
          setOpenAlert(true);
        } else {
          console.error("Failed to submit form");
          setSeverity("error");
          setMessage("Submission failed");
          setOpenAlert(true);
        }
      }
    }
  };

  const handleUpdate = async (event) => {
    console.log("calledUpdat");
    event.preventDefault();
    let schemaValidationErrors = {};

    // Validation (assuming you have validation logic, uncomment if needed)
    // try {
    //   await tobaccoCompititorsSchema.validate(values, { abortEarly: false });
    // } catch (err) {
    //   err?.inner?.forEach((error) => {
    //     schemaValidationErrors[error.path] = error.message;
    //   });
    //   setErrors(schemaValidationErrors);
    // }

    if (Object.keys(schemaValidationErrors).length === 0) {
      const payload = {
        sapAnalysisId: values?.sapAnalysisId,
        sapExternalCode: values?.sapExternalCode, // Use trimmed value
        sapParameterName: values?.sapParameterName,
        sapAbbreviation: values?.sapAbbreviation,
        sapUom: values?.sapUom,
        sapDataType: values?.sapDataType,
        sapStatus: values?.sapStatus,
        createdDateTimeUTC: new Date().toISOString(),
        createdByUser: "string", // Change this to actual user data
        modifiedDateTimeUTC: new Date().toISOString(),
        modifiedByUser: "string", // Change this to actual user data
      };
      console.log("Update Payload:", payload);
      try {
        setLoading(true);
        const response = await SoilParameterService.updateSoilParameter({
          ...payload,
        });
        setLoading(false);
        if (response.status === 201) {
          setSeverity("success");
          setMessage("Updated successfully");
          setOpenAlert(true);
          navigate("/soilParameter");
        }
      } catch (error) {
        setLoading(false);
        if (error.response && error.response.status === 409) {
          setSeverity("error");
          setMessage(error.response.data.message);
          setOpenAlert(true);
        } else {
          console.error("Failed to update planting");
          setSeverity("error");
          setMessage("Update failed");
          setOpenAlert(true);
        }
      }
    }
  };

  return (
    <Layout title={"Soil Analysis-Parameter"} background="#ffffff">
      <Box
        sx={{
          minHeight: "125vh",
          flexGrow: 1,
          paddingBottom: "10rem",
          width: "100%",
          "& .MuiTextField-root": { m: 1 },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
          },
        }}
      >
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box
            sx={{
              bgcolor: "#EFEFEF",
              height: "430px",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <ArrowDropDownIcon sx={{ marginRight: 1 }} />
              <Typography>Transporter Data</Typography>
            </Box>

            <Grid item xs={12} sm={6} md={12}>
              <FormControl component="fieldset" required>
                <FormLabel component="legend" sx={{marginLeft: 1}}>Business Type</FormLabel>
                <RadioGroup row sx={{marginLeft: 1}}>
                  <FormControlLabel
                    value="Individual"
                    onChange={handleChange}
                    name="BusinessType"
                    control={<Radio />}
                    label="Individual"
                  />
                  <FormControlLabel
                    value="Legal Entity"
                    onChange={handleChange}
                    name="BusinessType"
                    control={<Radio />}
                    label="Legal Entity"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {values.BusinessType == "Individual" ? (
              <>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                      <TextField
                        name="fiscalID"
                        label="Fiscal ID"
                        value={values?.sapExternalCode}
                        onChange={handleChange}
                        disabled={edit == false}
                        fullWidth
                        required
                        error={!!errors.sapExternalCode} // Show error state
                        helperText={errors.sapExternalCode || ""} // Show error message
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "7px",
                            minHeight: "50px",
                          },
                          "& .MuiInputLabel-root": {
                            lineHeight: "1.2em",
                          },
                        }}
                        InputProps={{
                          style: { height: "50px" },
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={8.8}>
                    <FormControl fullWidth>
                      <TextField
                        name="transporterName"
                        label="Transporter Name"
                        value={values?.sapParameterName}
                        onChange={handleChange}
                        disabled={edit == false}
                        fullWidth
                        required
                        error={!!errors.sapParameterName} // Show error state
                        helperText={errors.sapParameterName || ""} // Show error message
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "7px",
                            minHeight: "50px",
                          },
                          "& .MuiInputLabel-root": {
                            lineHeight: "1.2em",
                          },
                        }}
                        InputProps={{
                          style: { height: "50px" },
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                      <TextField
                        name="nationalityId"
                        label="Nationality"
                        value={values?.sapDataType}
                        onChange={handleChange}
                        disabled={edit == false}
                        fullWidth
                        required
                        error={!!errors.sapDataType} // Show error state
                        helperText={errors.sapDataType || ""} // Show error message
                        select
                        SelectProps={{ IconComponent: ArrowDropDownIcon }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "7px",
                            minHeight: "50px",
                          },
                          "& .MuiInputLabel-root": {
                            lineHeight: "1.2em",
                          },
                        }}
                        InputProps={{
                          style: { height: "50px" },
                        }}
                      >
                        {nationality.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <FormControl fullWidth>
                      <TextField
                        name="maritalStatusId"
                        label="Martial Status"
                        value={values?.sapDataType}
                        onChange={handleChange}
                        disabled={edit == false}
                        fullWidth
                        required
                        error={!!errors.sapDataType} // Show error state
                        helperText={errors.sapDataType || ""} // Show error message
                        select
                        SelectProps={{ IconComponent: ArrowDropDownIcon }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "7px",
                            minHeight: "50px",
                          },
                          "& .MuiInputLabel-root": {
                            lineHeight: "1.2em",
                          },
                        }}
                        InputProps={{
                          style: { height: "50px" },
                        }}
                      >
                        {marital.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <FormControl fullWidth>
                      <TextField
                        name=""
                        label="Region"
                        value={values?.sapDataType}
                        onChange={handleChange}
                        disabled={edit == false}
                        fullWidth
                        required
                        error={!!errors.sapDataType} // Show error state
                        helperText={errors.sapDataType || ""} // Show error message
                        select
                        SelectProps={{ IconComponent: ArrowDropDownIcon }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "7px",
                            minHeight: "50px",
                          },
                          "& .MuiInputLabel-root": {
                            lineHeight: "1.2em",
                          },
                        }}
                        InputProps={{
                          style: { height: "50px" },
                        }}
                      >
                        {region.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <FormControl fullWidth>
                      <TextField
                        name="stateRegistrationNumber"
                        label="State Registration Number"
                        value={values?.sapDataType}
                        onChange={handleChange}
                        disabled={edit == false}
                        fullWidth
                        error={!!errors.sapDataType} // Show error state
                        helperText={errors.sapDataType || ""} // Show error message
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "7px",
                            minHeight: "50px",
                          },
                          "& .MuiInputLabel-root": {
                            lineHeight: "1.2em",
                          },
                        }}
                        InputProps={{
                          style: { height: "50px" },
                        }}
                      >
                        {dataType.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2.8}>
                    <FormControl fullWidth>
                      <TextField
                        name=""
                        label="RNTRC"
                        value={values?.sapDataType}
                        onChange={handleChange}
                        disabled={edit == false}
                        fullWidth
                        required
                        error={!!errors.sapDataType} // Show error state
                        helperText={errors.sapDataType || ""} // Show error message
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "7px",
                            minHeight: "50px",
                          },
                          "& .MuiInputLabel-root": {
                            lineHeight: "1.2em",
                          },
                        }}
                        InputProps={{
                          style: { height: "50px" },
                        }}
                      >
                        {dataType.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                      <TextField
                        name="businessNumber"
                        label="Business Number"
                        value={values?.sapExternalCode}
                        onChange={handleChange}
                        disabled={edit == false}
                        fullWidth
                        required
                        error={!!errors.sapExternalCode} // Show error state
                        helperText={errors.sapExternalCode || ""} // Show error message
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "7px",
                            minHeight: "50px",
                          },
                          "& .MuiInputLabel-root": {
                            lineHeight: "1.2em",
                          },
                        }}
                        InputProps={{
                          style: { height: "50px" },
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={8.8}>
                    <FormControl fullWidth>
                      <TextField
                        name="transporterName"
                        label="Transporter Name"
                        value={values?.sapParameterName}
                        onChange={handleChange}
                        disabled={edit == false}
                        fullWidth
                        required
                        error={!!errors.sapParameterName} // Show error state
                        helperText={errors.sapParameterName || ""} // Show error message
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "7px",
                            minHeight: "50px",
                          },
                          "& .MuiInputLabel-root": {
                            lineHeight: "1.2em",
                          },
                        }}
                        InputProps={{
                          style: { height: "50px" },
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6} md={2}>
                    <FormControl fullWidth>
                      <TextField
                        name="sapExternalCode"
                        label="Region"
                        value={values?.sapExternalCode}
                        onChange={handleChange}
                        disabled={edit == false}
                        fullWidth
                        required
                        select
                        SelectProps={{ IconComponent: ArrowDropDownIcon }}
                        error={!!errors.sapExternalCode} // Show error state
                        helperText={errors.sapExternalCode || ""} // Show error message
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "7px",
                            minHeight: "50px",
                          },
                          "& .MuiInputLabel-root": {
                            lineHeight: "1.2em",
                          },
                        }}
                        InputProps={{
                          style: { height: "50px" },
                        }}
                      >
                                  {region.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={2}>
                    <FormControl fullWidth>
                      <TextField
                        name="stateRegistrationNumber"
                        label="State Registration Number"
                        value={values?.sapExternalCode}
                        onChange={handleChange}
                        disabled={edit == false}
                        fullWidth
                        required
                        error={!!errors.sapExternalCode} // Show error state
                        helperText={errors.sapExternalCode || ""} // Show error message
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "7px",
                            minHeight: "50px",
                          },
                          "& .MuiInputLabel-root": {
                            lineHeight: "1.2em",
                          },
                        }}
                        InputProps={{
                          style: { height: "50px" },
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3.5}>
                    <FormControl fullWidth>
                      <TextField
                        name="transporterName"
                        label="Transporter Name"
                        value={values?.sapParameterName}
                        onChange={handleChange}
                        disabled={edit == false}
                        fullWidth
                        required
                        error={!!errors.sapParameterName} // Show error state
                        helperText={errors.sapParameterName || ""} // Show error message
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "7px",
                            minHeight: "50px",
                          },
                          "& .MuiInputLabel-root": {
                            lineHeight: "1.2em",
                          },
                        }}
                        InputProps={{
                          style: { height: "50px" },
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            )}

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={11.8}>
                <FormControl fullWidth>
                  <TextField
                    name="Route Details"
                    label="Route Details"
                    value={values?.sapDataType}
                    onChange={handleChange}
                    disabled={!edit}
                    fullWidth
                    error={!!errors.sapDataType} // Show error state
                    helperText={errors.sapDataType || ""} // Show error message
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "inherit", // Keeps border color consistent on hover
                        },
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 0)", // Default border color
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                        fontWeight: "600", // Makes label bold
                        color:"#5A646E"
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <TextField
                    name="buyingStation"
                    label="Buying Station"
                    value={values?.sapExternalCode}
                    onChange={handleChange}
                    disabled={edit == false}
                    fullWidth
                    required
                    error={!!errors.sapExternalCode} // Show error state
                    helperText={errors.sapExternalCode || ""} // Show error message
                    select
                    SelectProps={{ IconComponent: ArrowDropDownIcon }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  >
                        <MenuItem value="PL" disabled sx={{ display: "none" }}>
                  Select an option
                </MenuItem>
                <MenuItem value="RioNegro">Rio Negro</MenuItem>
                <MenuItem value="Papanduva">Papanduva</MenuItem>
                <MenuItem value="SantaCruz">GLT Santa Cruz do Sul</MenuItem>
                <MenuItem value="Imbituva">Imbituva</MenuItem>
                <MenuItem value="Unidade">Unidade Rio Negro</MenuItem>
                <MenuItem value="Ararangua">Ararangua</MenuItem>
                <MenuItem value="PousoRedondo">Pouso Redondo</MenuItem>
                </TextField>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={7.8}>
                <FormControl fullWidth>
                  <TextField
                    name="buyingStationRoute"
                    label="Buying Station-Route (Main Cities)"
                    value={values?.sapParameterName}
                    onChange={handleChange}
                    disabled={edit == false}
                    fullWidth
                    required
                    error={!!errors.sapParameterName} // Show error state
                    helperText={errors.sapParameterName || ""} // Show error message
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <TextField
                    name="supplyInvoicingUnit"
                    label="Supply Invoicing Unit"
                    value={values?.sapExternalCode}
                    onChange={handleChange}
                    disabled={edit == false}
                    fullWidth
                    required
                    error={!!errors.sapExternalCode} // Show error state
                    helperText={errors.sapExternalCode || ""} // Show error message
                    select
                    SelectProps={{ IconComponent: ArrowDropDownIcon }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  >
                                 <MenuItem value="PL" disabled sx={{ display: "none" }}>
                  Select an option
                </MenuItem>
                <MenuItem value="RioNegro">Rio Negro</MenuItem>
                <MenuItem value="Papanduva">Papanduva</MenuItem>
                <MenuItem value="SantaCruz">GLT Santa Cruz do Sul</MenuItem>
                <MenuItem value="Imbituva">Imbituva</MenuItem>
                <MenuItem value="Unidade">Unidade Rio Negro</MenuItem>
                <MenuItem value="Ararangua">Ararangua</MenuItem>
                <MenuItem value="PousoRedondo">Pouso Redondo</MenuItem>
                </TextField>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={7.8}>
                <FormControl fullWidth>
                  <TextField
                    name="supplyInvoicingUnitRoute"
                    label="Supply Invoicing Unit-Route (Main Cities)"
                    value={values?.sapParameterName}
                    onChange={handleChange}
                    disabled={edit == false}
                    fullWidth
                    required
                    error={!!errors.sapParameterName} // Show error state
                    helperText={errors.sapParameterName || ""} // Show error message
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          {/* Bank Information */}

          <Box
            sx={{
              marginTop: 2,
              bgcolor: "#EFEFEF",
              height: "106px",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <ArrowDropDownIcon sx={{ marginRight: 1 }} />
              <Typography>Bank Information</Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  <TextField
                    name="bankAccountType"
                    label="Account Type"
                    value={values?.sapDataType}
                    onChange={handleChange}
                    disabled={edit == false}
                    fullWidth
                    required
                    error={!!errors.sapDataType} // Show error state
                    helperText={errors.sapDataType || ""} // Show error message
                    select
                    SelectProps={{ IconComponent: ArrowDropDownIcon }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  >
                  <MenuItem value="Checking account">
                      Checking account
                    </MenuItem>
                    <MenuItem value="Savings Account">Savings Account</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                <TextField
                    name="bankCode"
                    label="Bank Code"
                    value={values.bankCode}
                    disabled={true} // This field should be read-only
                    fullWidth
                    required
                    error={!!errors.sapDataType} // Show error state
                    helperText={errors.sapDataType || ""} // Show error message
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  >
                    {bank.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2.9}>
                <FormControl fullWidth>
                  <TextField
                    name="bankRegistrationId"
                    label="Bank"
                    value={values?.bankRegistrationId}
                    onChange={handleChange}
                    disabled={edit == false}
                    fullWidth
                    required
                    error={!!errors.sapDataType} // Show error state
                    helperText={errors.sapDataType || ""} // Show error message
                    select
                    SelectProps={{ IconComponent: ArrowDropDownIcon }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  >
                    {bank.map((item, index) => (
                      <MenuItem   key={item.bankRegistrationId}
                        value={item.bankRegistrationId}>
                        {`${item.bankCode}-${item.bankName}`}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2.9}>
                <FormControl fullWidth>
                <TextField
                    name="bankBranchRegistrationId"
                    label="Branch Number"
                    value={values.bankBranchRegistrationId}
                    onChange={handleChange}
                    disabled={!values.bankRegistrationId || edit === false}
                    fullWidth
                    select
                    SelectProps={{ IconComponent: ArrowDropDownIcon }}
                    error={!!errors.sapDataType} // Show error state
                    helperText={errors.sapDataType || ""} // Show error message
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  >
                     {branches.map((branch) => (
                      <MenuItem
                        key={branch.bankBranchRegistrationId}
                        value={branch.bankBranchRegistrationId}
                      >
                        {`${branch.branchNumber} - ${branch.branchName}`}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  <TextField
                    name="accountNumber"
                    label="Account Number"
                    value={values?.sapDataType}
                    onChange={handleChange}
                    disabled={edit == false}
                    fullWidth
                    required
                    error={!!errors.sapDataType} // Show error state
                    helperText={errors.sapDataType || ""} // Show error message
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  >
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          {/* <hr style={{ marginTop: "200px" }} /> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between", // Ensures buttons are placed on opposite corners
              position: "fixed", // Fix the buttons in place
              width: "100%", // Make the Box take full width
              bottom: 0, // Place at the bottom of the screen
              padding: "1rem", // Add some padding
            }}
          >
            {/* Cancel Button - Left bottom corner */}
            <Button
              style={{
                backgroundColor: "#737D86",
                padding: "6px 16px",
                borderRadius: "4px",
                position: "fixed",
                left: "1rem",
                bottom: "1rem",
                marginBottom: "50px",
              }}
              variant="contained"
              onClick={edit == false ? handleDeleteConfirm : handleOpenModal} // Open the modal on click
              disabled={loading}
              startIcon={<img src={arrowLeft} alt="Arrow" />}
            >
              {loading && <CircularProgress size={20} />}
              {edit == false ? "Back" : "Cancel"}
            </Button>

            {/* Modal confirmation */}
            <Modal
              open={open}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "7px",
                  width: "585px",
                  margin: "auto",
                  mt: 25,
                  height: "200px",
                }}
              >
                <Box
                  sx={{
                    background: "#102A62",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                  }}
                >
                  <Typography
                    sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
                  >
                    Cancel Operation
                  </Typography>
                </Box>
                <Box sx={{ p: 2 }}>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, fontWeight: 400 }}
                  >
                    Are you sure you want to cancel this operation? All your
                    data will be lost.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                    mb: 1,
                  }}
                >
                  <Button
                    onClick={handleCloseModal}
                    sx={{
                      background: "#737D86",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#737D86" },
                    }}
                  >
                    No
                  </Button>
                  <Button
                    onClick={handleDeleteConfirm}
                    sx={{
                      background: "#004F9F",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#004F9F" },
                    }}
                  >
                    Yes
                  </Button>
                </Box>
              </Box>
            </Modal>

            {edit != false && (
              <Button
                sx={{
                  padding: "6px 16px",
                  borderRadius: "4px",
                  position: "fixed", // Fix the button
                  right: "1rem", // Aligns to the right bottom corner
                  bottom: "1.5rem", // Position it at the bottom
                  marginBottom: "40px",
                  backgroundColor: "#EF7D00",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#EF7D00",
                  },
                }}
                disabled={loading}
                startIcon={<img src={Save} alt="Right Arrow" />}
                onClick={!edit ? handleSubmit : handleUpdate}
                variant="contained"
              >
                {loading && <CircularProgress size={20} />}
                {"Save"}
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </Layout>
  );
}
