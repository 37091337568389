import React, { useState } from "react";
import "./NewTable.css";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Edit from "assets/EditNew.svg";
import Delete from "assets/DeleteNew.svg";
import { Box, Button, Modal, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { SnackbarContext } from "context/snackBarContext";
import FreightService from "services/FreightService";
import { DELETE_SUCCESS_MSG } from "utils/AgriErrorMsg"
import { useTranslation } from 'react-i18next';


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 580,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  // p: 4,
};

const NewTable = ({ freight, refreshData }) => {
  console.log("🚀 ~ NewTable ~ freight:", freight);
  const [data, setData] = useState([]);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  console.log("🚀 ~ NewTable ~ data:", data);
  // const [isAscending, setIsAscending] = useState(true);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const handleOpen = (id) => {
    setDeleteId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteId(null);
  };

  const handleDelete = async () => {
    try {
      await FreightService.deleteFreigth({ id: deleteId });
      console.log(`Manure with id ${deleteId} deleted successfully.`);
      setData(data.filter((item) => item.additionalFreightGroupId !== deleteId));
      handleClose();
      setSeverity("success");
      setMessage(DELETE_SUCCESS_MSG);
      setOpenAlert(true);
      refreshData();
    } catch (error) {
      setSeverity("error");
      setMessage(error);
      console.error(`Failed to delete manure with id ${deleteId}:`, error);
    }
  };

  useEffect(() => {
    if (freight) {
      setData(freight);
    }
  }, [freight]);

  // const handleSort = () => {
  //   const sortedData = [...data].sort((a, b) => {
  //     if (isAscending) {
  //       return a.status > b.status ? 1 : -1;
  //     } else {
  //       return a.status < b.status ? 1 : -1;
  //     }
  //   });

  //   setData(sortedData);
  //   setIsAscending(!isAscending);
  // };
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...data].sort((a, b) => {
      const aValue = a[key] ? String(a[key]).toLowerCase() : "";
      const bValue = b[key] ? String(b[key]).toLowerCase() : "";

      if (aValue < bValue) return direction === "asc" ? -1 : 1;
      if (aValue > bValue) return direction === "asc" ? 1 : -1;
      return 0;
    });
    console.log("orgData", data);
    console.log("sortData", sortedData);
    setData(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        return <ArrowUpwardIcon fontSize="small" />;
      } else if (sortConfig.direction === "desc") {
        return <ArrowDownwardIcon fontSize="small" />;
      }
    }
    return <ArrowDownwardIcon fontSize="small" />;
  };

  return (
    <div className="table-container">
      <table className="custom-table">
        <thead>
          <tr>
            <th
              onClick={() => handleSort("additionalFreightGroupDescription")}
              style={{
                cursor: "pointer",
                display: "flex",
                // justifyContent: "space-between",
              }}
            >
              Additional Freight Group Description {getSortIcon("additionalFreightGroupDescription")}
              {/* {isAscending === true ? (
                <SouthIcon sx={{ ml: 2, color: "#3B454F", fontSize: "18px" }} />
              ) : (
                <NorthIcon sx={{ ml: 2, color: "#3B454F", fontSize: "18px" }} />
              )} */}
            </th>
            <th
              onClick={() => handleSort("isActive")}
              style={{
                cursor: "pointer",
                display: "flex",
                // justifyContent: "space-between",
              }}
            >
              {t("status")} {getSortIcon("isActive")}
              {/* {isAscending === true ? (
                <SouthIcon sx={{ ml: 2, color: "#3B454F", fontSize: "18px" }} />
              ) : (
                <NorthIcon sx={{ ml: 2, color: "#3B454F", fontSize: "18px" }} />
              )} */}
            </th>

            <th className="actions-header">{t("actions")}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td
                style={{
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate("/viewFreightGroupRegistration", {
                    state: {
                      freightIdEdit: row.additionalFreightGroupId,
                      statusEdit: row.isActive,
                      createdByUserEdit: row.createdByUser,
                      modifiedByUserEdit: row.modifiedByUser,
                      freightNameEdit: row.additionalFreightGroupDescription,
                      details: true,
                    },
                  })
                }
              >
                {row.additionalFreightGroupDescription}
              </td>
              <td
                style={{
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate("/viewFreightGroupRegistration", {
                    state: {
                      freightEdit: row.additionalFreightGroupId,
                      statusEdit: row.isActive,
                      createdByUserEdit: row.createdByUser,
                      modifiedByUserEdit: row.modifiedByUser,
                      freightNameEdit: row.additionalFreightGroupDescription,
                      details: true,
                    },
                  })
                }
              >
                {row.isActive === true ? "Active" : "Inactive"}
              </td>
              <td className="actions-cell">
                <img
                  className="btn edit-btn"
                  onClick={() =>
                    navigate("/editFreightGroupRegistration", {
                      state: {
                        freightIdEdit: row.additionalFreightGroupId,
                        statusEdit: row.isActive,
                        createdByUserEdit: row.createdByUser,
                        modifiedByUserEdit: row.modifiedByUser,
                        freightNameEdit: row.additionalFreightGroupDescription,
                        edit: true,
                      },
                    })
                  }
                  src={Edit}
                  alt="Edit"
                />
                <img
                  className="btn delete-btn"
                  onClick={() => handleOpen(row.additionalFreightGroupId)}
                  src={Delete}
                  alt="Delete"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
            >
            {t("deleteModal.heading")}
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, fontWeight: 400 }}
            >
                        {t("deleteModal.sure")}

            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              mb: 1,
            }}
          >
            <Button
              onClick={handleClose}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": {
                  background: "#737D86",
                },
              }}
            >
              {t("no")}
            </Button>
            <Button
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": {
                  background: "#004F9F",
                },
              }}
              onClick={handleDelete}
            >
              {" "}
              {t("yes")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default NewTable;
