import {
  Typography,
  Box,
  Button,
  Grid,
  TextField,
  InputAdornment,
} from "@mui/material";
import Layout from "components/structure/Layout";
import React, { useEffect, useState, useContext, useCallback } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EquipmentTable from "./EquipmentTable";
import { useNavigate } from "react-router-dom";
import EquipmentService from "services/EquipmentService";
import debounce from "lodash.debounce";
import iconSearchs from "../../../../assets/iconSearchs.svg";
import { SnackbarContext } from "context/snackBarContext";
import { useTranslation } from "react-i18next";
import label from "../growingLabel.json";

function GetEquipmentRegistration() {
  const navigate = useNavigate();
  const [equipmentData, setEquipmentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [noRecordFound, setNoRecordFound] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const { t, i18n } = useTranslation();

  const callEquipmentAPI = (pageNumber, size) => {
    setLoading(true);
    EquipmentService.getEquipment({ pageNumber, size })
      .then((res) => {
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setLoading(false);
        setEquipmentData(res?.data.response.equipments);
        setTotalItems(res?.data?.response?.totalItems);
        setNoRecordFound(false);
      })
      .catch((error) => {
        if (error.response?.status === 404) {
          setNoRecordFound(true);
          setSeverity("error");
          setOpenAlert(true);
          setMessage(error.response.data.message);
        } else {
          setOpenAlert(true);
          setMessage(error.response.data.message);
        }
        setLoading(false);
      });
  };

  const refreshEquipmentData = () => {
    // Call the API again after an item is deleted
    const totalItemsOnPage = equipmentData?.length ?? 0;

    if (totalItemsOnPage === 1 && currentPage > 0) {
      // If we're on the last item of the page, move back a page if possible
      callEquipmentAPI(currentPage - 1, itemsPerPage);
    } else if (totalItemsOnPage === 1 && currentPage == 0) {
      setEquipmentData([]);
      setNoRecordFound(true);
    } else {
      // Otherwise, refresh the current page
      callEquipmentAPI(currentPage, itemsPerPage);
    }
  };

  const callSearchEquipmentAPI = (pageNumber, size, userInput) => {
    setLoading(true);
    EquipmentService.searchEquipment({ pageNumber, size, userInput })
      .then((res) => {
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setLoading(false);
        if (res?.data.response.equipments.length === 0) {
          setNoRecordFound(true);
        } else {
          setNoRecordFound(false);
        }
        setEquipmentData(res?.data.response.equipments);
        setTotalItems(res?.data?.response?.totalItems);
      })
      .catch((error) => {
        console.log("oppp", error.response.data.message);
        if (error.response?.status === 404) {
          setNoRecordFound(true);
          setSeverity("error");
          setOpenAlert(true);
          setMessage(error.response.data.message);
        } else {
          setOpenAlert(true);
          setMessage(error.response.data.message);
        }
        setLoading(false);
      });
  };

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
    debounceSearch(event.target.value);
  };

  const debounceSearch = useCallback(
    debounce((value) => {
      callSearchEquipmentAPI(0, itemsPerPage, value);
    }, 300),
    []
  );

  const paginate = (pageNumber, itemsPerPage) => {
    if (searchInput.trim() !== "") {
      callSearchEquipmentAPI(pageNumber - 1, itemsPerPage, searchInput);
    } else {
      callEquipmentAPI(pageNumber - 1, itemsPerPage);
    }
  };

  useEffect(() => {
    callEquipmentAPI(currentPage, itemsPerPage);
  }, []);

  return (
    <div>
      <Layout
        itemsPerPage={itemsPerPage}
        totalItems={totalItems}
        paginate={paginate}
        currentPage={currentPage}
        title={t("equipmentRegistration.pageTitle")}
      >
        <Box mt={4} mb={6}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            // mb={0.5}
          >
            <Grid item xs={4} md={4} lg={4} style={{ padding: "2px" }}>
              <TextField
                sx={{
                  width: "270px",
                  // height: "53px",
                  "& .MuiInputBase-input::placeholder": {
                    opacity: 1,
                  },
                }}
                size="small"
                name={"search"}
                className="farmerTextField"
                placeholder={t("search")}
                variant="outlined"
                value={searchInput}
                onChange={handleSearchChange}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={iconSearchs} alt="search-icon" />
                    </InputAdornment>
                  ),
                }}
              />
              {searchInput ? (
                <></>
              ) : (
                <div className="farmerNoFilter">
                  {t("equipmentRegistration.noFiltersApplied")}
                </div>
              )}
            </Grid>
            <Button
              sx={{
                borderRadius: 100,
                minWidth: 160,
                minHeight: 44,
                background: "#102A62",
                marginTop: { xs: 1 },
                marginLeft: { xs: 1 },
              }}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => navigate("/addEquipmentRegistration")}
            >
              {t("equipmentRegistration.addEquipment")}
            </Button>
          </Box>
          {/* <div className="farmerNoFilter" style={{ marginLeft: 4 }}>No filters applied</div> */}
          {/* {!loading && noRecordFound && (
            <Typography variant="h6" color="error" align="center">
              No Record found
            </Typography>
          )} */}
          {!loading && !noRecordFound && equipmentData?.length > 0 && (
            <EquipmentTable
              equipmentData={equipmentData}
              refreshData={refreshEquipmentData}
            />
          )}
        </Box>
      </Layout>
    </div>
  );
}

export default GetEquipmentRegistration;
