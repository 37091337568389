import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addAdult: [],
};

const adultsSlice = createSlice({
  name: "addAdult",
  initialState,
  reducers: {
    updateAdults(state, action) {
      if (Array.isArray(action.payload)) {
        action.payload.forEach((newAdult) => {
          const existingAdultIndex = state.addAdult.findIndex(
            (adult) => adult.propertyId === newAdult.propertyId
          );
          if (existingAdultIndex !== -1) {
            state.addAdult[existingAdultIndex] = newAdult;
          } else {
            state.addAdult.push(newAdult);
          }
        });
      } else {
        console.error("Payload for updateAdults should be an array of adult objects.");
      }
    },
    deleteAdult(state, action) {
      state.addAdult = state.addAdult.filter(
        (adult) => adult.propertyId !== action.payload
      );
    },
    clearAdults(state) {
      state.addAdult = [];
    },
  },
});

export const { updateAdults, deleteAdult, clearAdults } = adultsSlice.actions;
export default adultsSlice.reducer;
