import { httpGrow } from "../http-common";

const getTransporter = async (query) => {
  const response = await httpGrow.get(
    `/transporter?pageNumber=${query?.pageNumber}&pageSize=${query?.size}`
  );
  return response;
};

const getNationality = async () => {
  const response = await httpGrow.get(`/transporter/nationality-details`);
  return response;
};

const getMarital = async () => {
    const response = await httpGrow.get(`/transporter/marital-details`);
    return response;
  };

  const getRegion = async () => {
    const response = await httpGrow.get(`/transporter/regions`);
    return response;
  };

  const getFTResponsible = async () => {
    const response = await httpGrow.get(`/hierarchyLevelConfig/hierarchy-level-five-details`);
    return response;
  };
  const getTMResponsible = async () => {
    const response = await httpGrow.get(`/hierarchyLevelConfig/hierarchy-level-three-details`);
    return response;
  };

  const getBankDetails = async () => {
    const response = await httpGrow.get(
      `/banks`
    );
    return response;
  };
   
  const getBankBranchDetails = async (bankRegistrationId) => {
    const response = await httpGrow.get(
      `/banks/branches/${bankRegistrationId}`
    );
    return response;
  };

  const getCityDetails = async () => {
    const response = await httpGrow.get(
      `/city`
    );
    return response;
  };

// const searchTobacco = async (query) => {
//   const response = await httpGrow.get(
//     `/tobaccoCompaniesCompetitors/search?pageNumber=${query?.pageNumber}&pageSize=${
//       query?.size || 50
//     }&tobaccoCompanyName=${query?.tobaccoCompanyName || ""}&
//     tccCountryCode=${
//       query?.tccCountryCode || ""
//     }&region=${query?.region || ""}&status=${
//       query?.status !== undefined ? query.status : ""
//     }`
//   );
//   return response;
// };

const searchSoilParameter = async (queryString) => {
  const response = await httpGrow.get(
    `/soil-analysis-parameters/find${queryString}`
  );
  return response;
};
const deleteTransporter = (soilAnalysisParametersId) => {
  return httpGrow.delete(`/soil-analysis-parameters/deleteSoilAnalysisParameters/${soilAnalysisParametersId}`);
};
const TransporterGetService = {
  getTransporter,
  getNationality,
  getMarital,
  getRegion,
  getFTResponsible,
  getTMResponsible,
  getBankDetails,
  getBankBranchDetails,
  getCityDetails,
  searchSoilParameter,
  deleteTransporter
};

export default TransporterGetService;
