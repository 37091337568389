import * as React from "react";
import Box from "@mui/material/Box";
import { Button, MenuItem, Modal, TextField } from "@mui/material";
import PropertyRegisterationModal from "./PropertyRegisterationModal";
import "./FarmerAdd.css";
import DataTable from "../DataTable/DataTable";
import AdultRegistrationModal from "./MinorRegistrationModal";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { GridActionsCellItem } from "@mui/x-data-grid";
import iconEdit from "assets/iconEdit.svg";
import iconView from "assets/iconView.svg";
import iconDelete from "assets/iconDelete.svg";
import { useContext } from "react";
import { SnackbarContext } from "context/snackBarContext";
import { deleteAdult } from "slices/adultsSlice";
import { deleteDependant, updateDependant } from "slices/dependantSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import FarmerRegisterationService from "services/FarmerRegistrationService";
import { useEffect } from "react";
import { useCallback } from "react";
import isEqual from "lodash.isequal";
import { convertToDateString } from "utils/CommonFunction";
import arrowLeft from "assets/arrowLeft.svg";
import { clearGeneralInformation } from "slices/generalInformation";
import { clearAdults } from "slices/adultsSlice";
import { clearEnvironment } from "slices/environmentalSlice";
import { clearDependant } from "slices/dependantSlice";
import CustomButton from "utils/CustomButton";
import { clearTaxInformation } from "slices/taxInformationSlice";
import { clearProperty } from "slices/propertyAddSlice";


export default function MinorsComponent(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [editData, setEditData] = useState(null);

  const depedantInformation = useSelector(
    (state) => state?.dependant?.addDependant
  );

  const [values, setValues] = useState(depedantInformation || {});

  const handleDeleteMinor = (id) => {
    dispatch(deleteDependant(id));
  };

  console.log("dependant", depedantInformation);

  const getFarmerByIds = useCallback(async () => {
    try {
      const response = await FarmerRegisterationService.getFarmerById({ id });
      let fetchedValues =
        response.data?.response?.dependentRegistrationDetails ||
        depedantInformation;

      // Process the dateOfBirth field
      fetchedValues = fetchedValues.map((dependent) => ({
        ...dependent,
        attendSchool: dependent.attendSchool === true ? "Yes" : "No",
        dateOfBirth: convertToDateString(dependent.dateOfBirth),
      }));

      if (!isEqual(fetchedValues, values)) {
        setValues(fetchedValues);
        dispatch(updateDependant(fetchedValues));
      }
    } catch (error) {
      console.error("Error getting farmer by id:", error);
    }
  }, [id, values, dispatch]);

  useEffect(() => {
    if (id) {
      getFarmerByIds();
    }
  }, [id, getFarmerByIds]);

  const columns = [
    {
      field: "cyCropYear",
      headerName: "Crop Year",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "data-table-header",
    },
    // { field: 'region', headerName: 'Region', flex:1, headerAlign: 'center', align: 'center' },
    // { field: 'city', headerName: 'City', flex:1, headerAlign: 'center', align: 'center' },
    {
      field: "dependentName",
      headerName: "Name",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "dependentRelationshipType",
      headerName: "Relationship Type",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "attendSchool",
      headerName: "Attains School",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "schoolGrade",
      headerName: "School Grade",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "fatherName",
      headerName: "Father's Name",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "motherName",
      headerName: "Mother's Name",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "actions",
      type: "actions",
      // headerName: 'Actions',
      flex: 1,
      headerAlign: "center",
      align: "center",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<img src={iconEdit} height="30px" width="30px" alt="Edit" />}
          className="gridActionCell gridActionCellEdit"
          label="Edit"
          onClick={() => {
            setEditData(params.row);
            props.setRegisterationModalStatus(true);
          }}
          showInMenu
        />,
        // <GridActionsCellItem
        //   icon={<img src={iconEdit} height="30px" width="30px" />}
        //   className="gridActionCell gridActionCellEdit"
        //   label="Edit"
        //   onClick={() => alert(`Edit ${params.id}`)}
        //   showInMenu
        // />,
        <GridActionsCellItem
          icon={<img src={iconDelete} height="30px" width="30px" />}
          label="Delete"
          className="gridActionCell gridActionCellDelete"
          onClick={() => handleDeleteMinor(params.dependentId)}
          showInMenu
        />,
      ],
    },
  ];

  const handleAddMinor = () => {
    setEditData(null);
    props.setRegisterationModalStatus(true);
  };

  const handleSaveDetails = () => {
    if (depedantInformation.length > 0) {
      setSeverity("success");
      setMessage("Saved successfully");
      setOpenAlert(true);
    }
  };

  const location = useLocation();
  const { edit, details } = location.state || {};
  const navigate = useNavigate();
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const handleYes = () => {
    dispatch(clearGeneralInformation());
    dispatch(clearTaxInformation());
    dispatch(clearAdults());
    dispatch(clearProperty());
    dispatch(clearDependant());
    dispatch(clearEnvironment());
    navigate("/farmerRegistration")
  }

  const handleNo = () => setDeleteOpen(false);

  const styleBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30.5rem",
    bgcolor: "background.paper",
    height: "20.125rem",
    borderRadius: "0.625rem",
  };

  return (
    <Box
      sx={{
        minHeight: 180,
        flexGrow: 1,
        width: "100%",
        paddingBottom: "10rem",
        "& .MuiTextField-root": { m: 1, color: "red", width: "25ch" },
        "& .MuiFormLabel-root": {
          color: "rgba(16, 42, 98, 1)",
          "& .MuiFormLabel-asterisk": {
            color: "rgba(239, 0, 0, 1)",
            fontSize: "18px",
          },
        },
      }}
    >
      <div>
        {/* <TextField
                    select
                    label="Crop Year"
                    SelectProps={{
                        native: true,
                    }}
                    defaultValue="cropyear"
                    onChange={(e) => props.handleChangeMinor(e)}
                    name="cropyear"
                    error={props.minorFormValues?.cropyear.error}
                    helperText={props.minorFormValues.cropyear.error && props.minorFormValues.cropyear.errorMessage}
                    required
                    variant="outlined"
                >
                    {currencies.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </TextField>
                <TextField
                    select
                    label="Dependent"
                    SelectProps={{
                        native: true,
                    }}
                    defaultValue="dependent"
                    onChange={(e) => props.handleChangeMinor(e)}
                    name="dependent"
                    error={props.minorFormValues.dependent.error}
                    helperText={props.minorFormValues.dependent.error && props.minorFormValues.dependent.errorMessage}
                    required
                    variant="outlined"
                >
                    {currencies.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </TextField>
                <TextField
                    select
                    label="Study"
                    SelectProps={{
                        native: true,
                    }}
                    defaultValue="study"
                    onChange={(e) => props.handleChangeMinor(e)}
                    name="study"
                    error={props.minorFormValues.study.error}
                    helperText={props.minorFormValues.study.error && props.minorFormValues.study.errorMessage}
                    required
                    variant="outlined"
                >
                    {currencies.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </TextField>
                <TextField
                    select
                    label="Grade Level in school"
                    SelectProps={{
                        native: true,
                    }}
                    defaultValue="gradelevelinschool"
                    onChange={(e) => props.handleChangeMinor(e)}
                    name="gradelevelinschool"
                    error={props.minorFormValues.gradelevelinschool.error}
                    helperText={props.minorFormValues.gradelevelinschool.error && props.minorFormValues.gradelevelinschool.errorMessage}
                    required
                    variant="outlined"
                >
                    {currencies.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </TextField> */}
        {/* <TextField
                    value={props.minorFormValues.name.value}
                    onChange={(e) => props.handleChangeMinor(e)}
                    name="name"
                    error={props.minorFormValues.name.error}
                    helperText={props.minorFormValues.name.error && props.minorFormValues.name.errorMessage}
                    label="Name" required variant="outlined" /> */}
        {depedantInformation.length > 0 ? (
          <DataTable
            getRowId={(row) => row.dependentId || row.id}
            rows={depedantInformation}
            columns={columns}
          />
        ) : (
          ""
        )}
        <Box className="farmer-add-property" onClick={handleAddMinor}>
          + Add Minors.
        </Box>
        <AdultRegistrationModal
          editData={editData}
          addNewProperty={(formdata) => {
            props.handleAddProperty(formdata);
          }}
          open={props.registerationModalStatus}
          onClose={() => {
            props.setRegisterationModalStatus(false);
          }}
        />
        {/* <Button
          style={{
            float: "right",
            marginBottom: "10px",
            background: "#EF7D00",
          }}
          onClick={handleSaveDetails}
          variant="contained"
        >
          Save Changes
        </Button> */}
        {/* {edit && */}
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "10px" }}>
            <Button
              style={{
                marginBottom: "10px",
                marginRight: "10px",
                background: "#737D86",
              }}
              startIcon={<img src={arrowLeft} alt="Right Arrow" />}
              variant="contained"
              // onClick={() => navigate("/farmerRegistration")}
              onClick={() => setDeleteOpen(true)}
            >
              Cancel
            </Button>
            {/* <Button
              style={{
                marginBottom: "10px",
                background: "#EF7D00",
              }}
              type="submit"
              variant="contained"
            >
              Save Changes
            </Button> */}
          </div>
        {/* } */}
        {/* {!edit && <Button
          style={{
            float: "left",
            marginBottom: "10px",
            background: "#737D86",
          }}
          startIcon={<img src={arrowLeft} alt="Right Arrow" />}
          variant="contained"
          onClick={() => setDeleteOpen(true)}
        >
          Cancel
        </Button>} */}
      </div>
      <Modal
        open={deleteOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleBox}>
          <Box
            sx={{
              mt: 4,
              justifyContent: "center",
              display: "flex",
              gap: "1rem",
              flexDirection: "column",
              alignItems: "center",
            }}
          >

            <div>Are you sure!</div>
            <div>You want to Go Back</div>
            <div>Doing so will erase all the prefilled or filled data</div>

          </Box>
          <Box
            sx={{
              mt: 4,
              justifyContent: "center",
              display: "flex",
              gap: "1rem",
            }}
          >
            <CustomButton variant="cancel" label="No" onClick={handleNo} />

            <CustomButton
              color="#EF7D00"
              label="Yes"
              onClick={handleYes}
            />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
