import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  Switch,
  CircularProgress,
  Typography,
  Modal,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Radio,
  RadioGroup,
  Divider,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Layout from "components/structure/Layout";
import { soilParameterSchema } from "utils/validations";
import CuringService from "services/CuringService";
import CultureGroupService from "services/CultureGroup";
import { SnackbarContext } from "context/snackBarContext";
import arrowRight from "assets/arrowRight.svg";
import arrowLeft from "assets/arrowLeft.svg";
import RedSwitch from "utils/RedSwitch";
import PlantingService from "services/PlantingService";
import Save from "assets/SaveNew.svg";
import TobaccoService from "services/TobaccoService";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import Lable from "utils/lables.json";
import SoilParameterService from "services/SoilParameterService";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";

export default function Areas() {
  const navigate = useNavigate();
  const location = useLocation();
  const [areas, setareas] = useState([{ id: 1 }]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { edit } = location.state || {};

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  // Function when user confirms "Yes" on the modal
  const handleDeleteConfirm = () => {
    setOpen(false);
    navigate("/transporterRegistration");
  };

  const handleAddArea = () => {
    setareas([...areas, { id: areas.length + 1 }]);
  };

  const handleDeleteArea = (areaId) => {
    setareas(areas.filter((area) => area.id !== areaId));
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px 2px",
            background: "#FFFFFF",
            marginTop: 3,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "20px",
              color: "#5A646E",
            }}
          >
            Linked Areas
          </Typography>
          <Button
            variant="contained"
            onClick={handleAddArea}
            sx={{
              minWidth: 95,
              borderRadius: 1,
              background: "#004F9F",
              height: { xs: "40px", md: "44px" },
              marginLeft: { xs: 0, md: "auto" },
            }}
          >
            ADD NEW
          </Button>
        </Box>
        <Box
          id="divider"
          sx={{
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0px 20px 0px",
            gap: "20px",
            background: "#FFFFFF",
            borderBottom: "5px solid #004F9F",
          }}
        ></Box>
      </Box>
      {areas.map((area) => (
        <Box
          key={area.id}
          sx={{
            minHeight: "10%",
            marginTop: 5,
            flexGrow: 1,
            paddingBottom: "0.5rem",
            width: "100%",
            "& .MuiTextField-root": { m: 1 },
            "& .MuiFormLabel-root": {
              color: "rgba(16, 42, 98, 1)",
              "& .MuiFormLabel-asterisk": {
                color: "rgba(239, 0, 0, 1)",
                fontSize: "18px",
              },
            },
          }}
        >
          <form autoComplete="off" noValidate>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                bgcolor: "#EFEFEF",
                height: "120px",
                marginTop: "-25px",
                borderRadius: "5px",
                paddingRight: "10px",
              }}
            >
              <TextField
                name="Hierarchical Node"
                label="Hierarchical Node"
                variant="outlined"
                sx={{
                  width: "85%",
                  "& .MuiOutlinedInput-root": {
                    padding: "7px",
                    minHeight: "50px",
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1.2em",
                    marginLeft: "10px",
                  },
                }}
                InputProps={{
                  style: { height: "50px", marginLeft: "10px" },
                }}
              />
              {areas.length > 1 && (
                <img
                  src={_ButtonDelete_}
                  alt="Delete"
                  style={{ cursor: "pointer", marginRight: 5 }}
                  onClick={() => handleDeleteArea(area.id)}
                />
              )}
            </Box>

            <Divider
              sx={{
                position: "fixed",
                bottom: "90px",
                width: "97%",
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                position: "fixed",
                width: "100%",
                bottom: 0,
                padding: "0.5rem",
              }}
            >
              {/* Cancel Button - Left bottom corner */}
              <Button
                style={{
                  backgroundColor: "#737D86",
                  padding: "6px 16px",
                  borderRadius: "4px",
                  position: "fixed",
                  left: "1rem",
                  bottom: "1rem",
                  marginBottom: "20px",
                }}
                variant="contained"
                onClick={edit == false ? handleDeleteConfirm : handleOpenModal}
                disabled={loading}
                startIcon={<img src={arrowLeft} alt="Arrow" />}
              >
                {loading && <CircularProgress size={20} />}
                {edit == false ? "Back" : "Cancel"}
              </Button>

              {/* Center Buttons */}
              <Box
                sx={{
                  position: "fixed",
                  left: "50%",
                  bottom: "1rem",
                  transform: "translateX(-50%)",
                  display: "flex",
                  gap: "1rem",
                  marginBottom: "20px",
                }}
              >
                <Button
                  sx={{
                    minWidth: 95,
                    borderRadius: 1,
                    background: "#004F9F",
                    height: { xs: "40px", md: "44px" },
                    marginLeft: { xs: 0, md: "auto" },
                  }}
                  variant="contained"
                >
                  PREVIOUS
                </Button>
                <Button
                  sx={{
                    minWidth: 95,
                    borderRadius: 1,
                    background: "#004F9F",
                    height: { xs: "40px", md: "44px" },
                    marginLeft: { xs: 0, md: "auto" },
                  }}
                  variant="contained"
                >
                  NEXT
                </Button>
              </Box>

              {/* Save Button - Right bottom corner */}
              {edit != false && (
                <Button
                  sx={{
                    padding: "6px 16px",
                    borderRadius: "4px",
                    position: "fixed",
                    right: "1rem",
                    bottom: "1rem",
                    marginBottom: "20px",
                    backgroundColor: "#EF7D00",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#EF7D00",
                    },
                  }}
                  disabled={loading}
                  startIcon={<img src={Save} alt="Right Arrow" />}
                  variant="contained"
                >
                  {loading && <CircularProgress size={20} />}
                  {"Save"}
                </Button>
              )}
            </Box>
          </form>
        </Box>
      ))}
    </>
  );
}
