import {
  Autocomplete,
  FormControl,
  Grid,
  TextField,
  Typography,
  Box,
  Button,
  Modal,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import Divider from "components/common/GrowingDivider";
import Layout from "components/structure/Layout";
import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AdditionalFreightLimitsTable from "./AdditionalFreightLimitsTable";
import { useDropzone } from "react-dropzone";
import ByteSize_Upload from "assets/bytesize_upload.svg";
import { getBase64 } from "../../../../utils/CommonFunction";
import UserService from "services/UserService";
import AdditionalFreightLimitDistribution from "services/AdditionalFreightsLimitDistributionServices";
import SearchIcon from '../../../../assets/SearchIcon.svg';


const AdditionalFreightLimitsDistribution = () => {
  const { t } = useTranslation();
  const [isTopHierarchyNode, setIsTopHierarchyNode] = useState(false);
  const [cropSeason, setCropSeason] = useState("");
  const [cropSeasonId, setCropSeasonId] = useState("");
  const [cropSeasonOptions, setCropSeasonOptions] = useState([]);
  const [hierarchicalNode, setHierarchicalNode] = useState("");
  const [hierarchicalNodeId, setHierarchicalNodeId] = useState("");
  const [hierarchyOptions, setHierarchyOptions] = useState([]);
  const [showFiltersGrid, setShowFiltersGrid] = useState(true);
  const [manageApprovalEvidencesModal, setApprovalEvidencesModal] =
    useState(false);
  const inputFileRef = React.useRef();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [percentage, setPercentage] = React.useState(0);
  console.log("uploadedFiles", uploadedFiles);
  const [additionalFLD, setAdditionalFLD] = useState(null)
  const [childNodes, setChildNodes] = useState([])
  console.log("Child Nodes", childNodes)
  console.log("CSID", cropSeasonId)
  console.log("ihID", hierarchicalNodeId)
  console.log("Child Nodes: Hoptions", hierarchyOptions)

  const onBtnClick = () => {
    inputFileRef.current.click();
  };
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      console.log("event.target.files[0]::", file);
      if (file.type === "image/png" || file.type === "image/jpeg") {
        getBase64(file)
          .then((res) => {
            const new_file = {
              fileContent: res,
              fileName: file.name,
              mimeType: file.type,
              batOneId: "",
            };
            handleUpload(new_file);
          })
          .catch((err) => console.log(err));
      } else if (
        file.type === "application/vnd.ms-excel" ||
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/pdf" ||
        file.type === "application/msword" ||
        file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type === "application/vnd.ms-outlook" ||
        file.type === "message/rfc822"
      ) {
        const reader = new FileReader();
        reader.onload = () => {
          const fileUrl = reader.result;
          const base64Content = fileUrl.split(",")[1];
          const new_file = {
            fileContent: base64Content,
            fileName: file.name,
            mimeType: file.type,
            batOneId: "",
          };
          handleUpload(new_file);
        };
        reader.readAsDataURL(file);
      }
    });
  }, []);
  const handleFileUpload = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (file.type === "image/png" || file.type === "image/jpeg") {
      getBase64(file)
        .then((res) => {
          const new_file = {
            fileContent: res,
            fileName: file.name,
            mimeType: file.type,
            batOneId: "",
          };
          handleUpload(new_file);
        })
        .catch((err) => console.log(err));
    } else if (
      file.type === "application/vnd.ms-excel" ||
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/pdf" ||
      file.type === "application/msword" ||
      file.type ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      file.type === "application/vnd.ms-outlook" ||
      file.type === "message/rfc822"
    ) {
      const reader = new FileReader();
      reader.onload = () => {
        const fileUrl = reader.result;
        const base64Content = fileUrl.split(",")[1];
        const new_file = {
          fileContent: base64Content,
          fileName: file.name,
          mimeType: file.type,
          batOneId: "",
        };
        handleUpload(new_file);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleUpload = (file) => {
    setLoading(true);
    UserService.uploadUserFile(file, config)
      .then((response) => {
        let fileData = {
          fileUrl: response.data.response[file.fileName],
          fileName: file.fileName,
          country: "BRA",
          mimeType: file.mimeType,
        };
        setUploadedFiles((oldfiles) => [...oldfiles, fileData]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/vnd.ms-outlook": [".msg"],
      "message/rfc822": [".eml"],
    },
  });

  const config = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percent = Math.floor((loaded * 100) / total);
      if (percent <= 100) {
        setPercentage(percent); // hook to set the value of current level that needs to be passed to the progressbar
      }
    },
  };

  useEffect(() => {
    fetchInitialData();
    // handleViewHistory();
  }, []);

  useEffect(() => {
    if (cropSeasonId && hierarchicalNodeId) {
      console.log("INSIDE GLD EFFECT")
      fetchAdditionalFLD();
    } else {
      setAdditionalFLD(null);
      //   setSelectedPtdId(null); // Reset ptdId when filters change
    }
  }, [cropSeasonId, hierarchicalNodeId]);

  const fetchInitialData = async () => {
    try {
      const [cropSeasonResponse, hierarchyResponse] = await Promise.all([
        AdditionalFreightLimitDistribution.getCropSeasonAPI(),
        AdditionalFreightLimitDistribution.getHierarchyNodeAPI()
      ]);

      setCropSeasonOptions(cropSeasonResponse.data.response.map(item => ({
        label: item.cropYear.toString(),
        value: item.cropYear.toString(),
        cropSeasonId: item.cropYearUid,// Assuming there's an id field      
      })));

      // Flatten hierarchy tree for simplicity. You might want to implement a more sophisticated approach.
      const flattenHierarchy = (node, result = [], level = 0) => {
        result.push({
          label: node.locationName,
          value: node.id,
          level,
          tag: node.levelName,
          parentId: node.parentId || "parentNode"
        });
        node.childHierarchies.forEach(child => flattenHierarchy(child, result, level + 1));
        return result;
      };
      setHierarchyOptions(flattenHierarchy(hierarchyResponse.data.response));

    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  };

  const fetchAdditionalFLD = async () => {
    try {
      const AdditionalFLDresponse = await AdditionalFreightLimitDistribution.getAPI({
        cropYearUUID: cropSeasonId,
        ihId: hierarchicalNodeId
      });
      console.log("INSIDE GLD EFFECT", AdditionalFLDresponse.data.response)
      setAdditionalFLD(AdditionalFLDresponse.data.response)
    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  }

  const handleHierarchyChange = (event, newValue) => {
    if (newValue) {
      setHierarchicalNode(newValue.value);
      setHierarchicalNodeId(newValue.value);
      setIsTopHierarchyNode(newValue.level === 0);
      setChildNodes(
        hierarchyOptions.filter((node) =>
          node.level === newValue.level + 1
          &&
          node.parentId === newValue.value
        )
      )
      console.log('Selected node:', newValue);
      console.log('Node level:', newValue.level);
    } else {
      setHierarchicalNode("");
      setHierarchicalNodeId("");
      setIsTopHierarchyNode(false);
    }
  };
  console.log("isTopHierarchyNode", isTopHierarchyNode)
  const renderAdditionalFreightLimitsTable = () => {
    if (!cropSeasonId || !hierarchicalNodeId) {
      return null;
    }

    return (
      <AdditionalFreightLimitsTable
        data={additionalFLD}
        cropYearUUID={cropSeasonId}
        ihId={hierarchicalNodeId}
        // onSave={handleSaveProductionTargets}
        childNodes={childNodes}
        isTopHierarchyNode={isTopHierarchyNode}
        refreshData={fetchAdditionalFLD}
      />
    );
  };
  return (
    <>
      <Layout
        title={t("additionalFreightLimits.pageTitle")}
        background="#ffffff"
      >
        <Grid
          container
          id="mainfilter"
          spacing={2}
          sx={{
            background: "#EFEFEF",
            borderRadius: "5px",
            p: 2,
            maxWidth: "100%",
            margin: "auto",
            paddingBottom: "34px",
            marginBottom: "12px",
          }}
        >
          <Grid item xs={12} style={{ paddingTop: "0px", paddingLeft: "0px" }}>
            <Typography variant="h8" display="flex">
              <ArrowDropDownIcon />
              {t("additionalFreightLimits.mandatoryFilters")}
            </Typography>
          </Grid>

          <Grid item xs={4} style={{ paddingLeft: "0px" }}>
            <FormControl fullWidth>
              <Autocomplete
                value={cropSeasonOptions.find(option => option.value === cropSeason) || null}
                disableClearable={true}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setCropSeason(newValue.value);
                    setCropSeasonId(newValue.cropSeasonId); // This will trigger useEffect
                  } else {
                    setCropSeason("");
                    setCropSeasonId(""); // This will trigger useEffect
                  }
                }}
                // onInputChange={renderProductionTargetsTable()}
                options={cropSeasonOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("additionalFreightLimits.csSearchField")}
                    fullWidth
                    placeholder="Select an Option"
                    required={true}
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      "& .MuiInputBase-input::placeholder": {
                        opacity: 1,
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>

          {/* <Grid item xs={4}>
                    <FormControl fullWidth>
                        <Autocomplete
                            value={tobaccoTypeOptions.find(option => option.value === tobaccoType) || null}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    setTobaccoType(newValue.value);
                                    setTobaccoTypeId(newValue.tobaccoTypeId); // This will trigger useEffect
                                } else {
                                    setTobaccoType("");
                                    setTobaccoTypeId(""); // This will trigger useEffect
                                }
                            }}
                            // onInputChange={renderProductionTargetsTable()}
                            options={tobaccoTypeOptions}
                            renderInput={(params) => (
                                <TextField {...params} label={t('investmentGroupLimits.hnSearchField')} fullWidth />
                            )}
                        />
                    </FormControl>
                </Grid> */}

          <Grid item xs={4}>
            <FormControl fullWidth>
              <Autocomplete
                value={hierarchyOptions.find(option => option.value === hierarchicalNode) || null}
                disableClearable={true}
                onChange={handleHierarchyChange}
                options={hierarchyOptions}
                renderOption={(props, option) => (
                  <li {...props}>
                    <div>
                      <div style={{ fontSize: '16px' }}>
                        {option.label}
                      </div>
                      <div style={{ fontSize: '10px', color: '#5A646E' }}>
                        {option.tag}
                      </div>
                    </div>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("additionalFreightLimits.hnSearchField")}
                    fullWidth
                    placeholder="Select an Option"
                    required={true}
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      "& .MuiInputBase-input::placeholder": {
                        opacity: 1,
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={SearchIcon} alt="search" style={{ width: '20px', height: '20px', color: '#737D86' }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          {isTopHierarchyNode && (
            <Grid
              item
              xs={4}
              style={{
                ml: "auto",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {cropSeasonId && hierarchicalNodeId && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mr: 2, height: "50px" }}
                  onClick={() => setApprovalEvidencesModal(true)}
                >
                  APPROVAL EVIDENCES
                </Button>
              )}
            </Grid>
          )}

        </Grid>

        {cropSeasonId && hierarchicalNodeId && (
          <Divider
            title={t(
              "additionalFreightLimits.subordinateHierarchiesDistributionLimit"
            )}
          />
        )}
        {renderAdditionalFreightLimitsTable()}
      </Layout>
      <Modal
        open={manageApprovalEvidencesModal}
        onClose={() => setApprovalEvidencesModal(false)}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "529px",
            margin: "auto",
            mt: 2,
            maxHeight: "90vh", // Prevent modal from exceeding viewport height
            display: "flex",
            flexDirection: "column", // Ensures the layout respects natural flow
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              // height: "20vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
              color: "#fff",
              pt: 2,
              pb: 1,
            }}
          >
            {t("additionalFreightLimits.evidencesModalTitle")}
          </Box>
          <Box
            sx={{
              padding: "1rem",
            }}
          >
            <Typography
              sx={{
                textAlign: "left",
                fontSize: "10px !important",
                fontWeight: 600,
              }}
            >
              FILE LIST:
            </Typography>
            <Box
              sx={{
                marginLeft: 5,
                marginRight: 5,
                marginTop: 2,
                marginBottom: 2,
              }}
            >
              {uploadedFiles.map((file) => (
                <Typography
                  sx={{
                    textAlign: "left",
                    fontSize: "13px !important",
                  }}
                >
                  {file.fileName}
                </Typography>
              ))}
            </Box>
            <Box
              sx={{
                //   background: "#EFEFEF",
                borderRadius: "5px",
                borderColor: "grey",
                borderWidth: "1px",
                borderStyle: "solid",
                padding: "1rem",
                marginLeft: 5,
                marginRight: 5,
                marginTop: 2,
                marginBottom: 2,
              }}
            >
              <input
                style={{ display: "none" }}
                ref={inputFileRef}
                type="file"
                accept=".png, .jpeg, .jpg, .xls, .xlsx, .pdf, .doc, .docx, .msg, .eml"
                onChange={(e) => handleFileUpload(e)}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  padding: "1rem",
                }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                {loading ? (
                  <CircularProgress
                    sx={{ alignSelf: "center" }}
                  ></CircularProgress>
                ) : (
                  <>
                    <img
                      src={ByteSize_Upload}
                      style={{
                        height: "50px",
                        color: isDragActive ? "blue" : "red",
                      }}
                      alt="No Data"
                    />

                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "13px !important",
                        color: isDragActive ? "blue" : "black",
                      }}
                    >
                      <div>Drag and drop your file here</div>
                    </Typography>
                  </>
                )}
              </Box>
              <Box
                sx={{
                  background: "#EFEFEF",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  padding: "1rem",
                  marginLeft: 5,
                  marginRight: 5,
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "13px !important",
                    marginBottom: "0.5rem",
                  }}
                >
                  OR
                </Typography>
                <Button
                  style={{
                    height: "35px",
                    color: "white",
                    background: "#004F9F",
                    alignSelf: "center",
                  }}
                  variant="contained"
                  onClick={onBtnClick}
                >
                  Browse file
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: "auto",
              // background: "#fff", // Ensure it blends with the modal background
              padding: "1rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              //   gap: "60px",
              zIndex: 2, // Ensure it stays above the content
            }}
          >
            <Button
              onClick={() => setApprovalEvidencesModal(false)}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              {t("additionalFreightLimits.close")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AdditionalFreightLimitsDistribution;
