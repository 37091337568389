import * as React from "react";
import Box from "@mui/material/Box";
import { Button, CircularProgress, Modal,TextField,FormControl,FormControlLabel, FormLabel, Typography, Grid } from "@mui/material";
import arrowLeft from "assets/arrowLeft.svg";
import arrowRight from "assets/arrowRight.svg";
import * as Yup from "yup";
import { useState, useEffect, useContext } from "react";
import { varietySchema } from "utils/validations";
import RenderField from "utils/RenderField";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import { AuthData } from "auth/AuthWarpper";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import VarietyRegistrationService from "services/VarietyRegistrationService";
import Lable from "utils/lables.json";
import Save from "assets/SaveNew.svg";
import RedSwitch from "utils/RedSwitch";
import { environmentField } from "../Farmer_Management/Farmer_Management/FarmerAdd/FormConfig";
import SelectInput from "components/common/SelectInput";
import TextInput from "components/common/TextInput";
import Divider from "components/common/GrowingDivider";
import CountryFilter from "./CountryFilter";
import VarietyModal from "./VarietyModal";
import { useTranslation } from 'react-i18next';


export default function VarietyForm() {
  const { t } = useTranslation();
  console.log('VarietyForm component rendering');

  const location = useLocation();

  const navigate = useNavigate();
  const { user } = AuthData();

  const { vrVarietyId, edit } = location.state || {};

  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [values, setValues] = useState({
    ...location.state,
    cuCultureId: location.state?.cuCultureName || "",
    gtGeneticTypeId: location.state?.gtGeneticDescription || "",
    status: location.state?.status || false,
  });
  const [loading, setLoading] = useState(false)
  console.log("🚀 ~ VarietyForm ~ values:", values);
  const [errors, setErrors] = useState({});
  const [dateError, setDateError] = useState(false);
  const [options, setOptions] = useState([]);
  const [cultureTypeOptions, setCultureTypeOptions] = useState([]);
  console.log("🚀 ~ VarietyForm ~ varietyCultureTypes:", cultureTypeOptions);

  const [cultureOptions, setCultureOptions] = useState([]);
  const [geneticTypeOptions, setGeneticTypeOptions] = useState([]);
  const [isGeneticTypesLoading, setIsGeneticTypesLoading] = useState(false);
  const [selectedCultureId, setSelectedCultureId] = useState(null);

  const [vocVarietyOriginCountryId, setVocVarietyOriginCountryId] = useState("");
  const [voctVarietyOriginCultureTypeId, setVoctVarietyOriginCultureTypeId] = useState("");
  const [spacingRegistrationUid, setSpacingRegistrationUid] = useState("");
  const [lineSpacing, setLineSpacing] = useState(0);
  const [plantSpacing, setPlantSpacing] = useState(0);

  const [spacingDetails, setSpacingDetails] = useState({
    spacingRegistrationUid: '',
    lineSpacing: 0,
    plantSpacing: 0
  });

  const [spacingSelections, setSpacingSelections] = useState({});
  const [cultureTypeSelections, setCultureTypeSelections] = useState({});

  const [selectedCountries, setSelectedCountries] = useState([]);

  const [varietyDetails, setVarietyDetails] = useState(null);

  const currentDate = new Date().toISOString();
  const currentUser = "999100"; // Or get this from your auth context/service
  
  const [activeCountries, setActiveCountries] = useState([]);

  useEffect(() => {
    const active = Object.entries(selectedCountries || {})
      .filter(([key, isActive]) => isActive)
      .map(([key]) => key);
    setActiveCountries(active);
  }, [selectedCountries]);

  const fetchFormData = async () => {
    console.log('fetchFormData called');
    try {
      console.log('Fetching culture data...');
      const cultureResponse = await VarietyRegistrationService.getCultureData("BRA");
      console.log('Culture data response:', cultureResponse);
      
      if (!cultureResponse.data.response || !cultureResponse.data.response.Culture) {
        console.error("Unexpected API response structure:", cultureResponse);
        return;
      }

      const cultures = cultureResponse.data.response.Culture.map(culture => ({
        value: culture.cuCultureName,
        label: culture.cuCultureName,
        geneticTypes: culture.geneticTypes || [],
        cultureId: culture.cuCultureId
      }));
      console.log('Formatted culture options:', cultures);
      setCultureOptions(cultures);

      if (location?.state?.edit) {
        const geneticTypesResponse = await VarietyRegistrationService.getGeneticTypes();
        const options = geneticTypesResponse.data.response.map(type => ({
          value: type.gtGeneticDescription,
          label: type.gtGeneticDescription,
          geneticTypeId: type.gtGeneticTypeId
        }));
        setGeneticTypeOptions(options);
        
        setValues(prev => ({
          ...prev,
          cuCultureId: location.state.cuCultureName,
          gtGeneticTypeId: location.state.gtGeneticDescription
        }));
      }
    } catch (error) {
      console.error("Failed to fetch form data:", error);
      setMessage("Failed to load form data");
      setOpenAlert(true);
      setSeverity("error");
    }
  };

  useEffect(() => {
    console.log('VarietyForm useEffect triggered');
    fetchFormData();
  }, []);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        if (location?.state?.edit && location?.state?.vrVarietyId) {
          const response = await VarietyRegistrationService.getVarietyDetailsById(location.state.vrVarietyId);
          const details = response.data.response;
          console.log('Fetched variety details:', details); // Debug log

          // Store the details in state
          setVarietyDetails(details);

          // First fetch genetic types for the culture
          if (details.cuCultureId) {
            const geneticTypesResponse = await VarietyRegistrationService.getGeneticTypesByCulture(details.cuCultureId);
            const options = geneticTypesResponse.data.response.map(type => ({
              value: type.gtGeneticDescription,
              label: type.gtGeneticDescription,
              geneticTypeId: type.gtGeneticTypeId
            }));
            setGeneticTypeOptions(options);
          }

          // Fetch culture type options using the geneticTypeId
          if (details.gtGeneticTypeId) {
            const cultureTypeResponse = await VarietyRegistrationService.getCultureTypeByGeneticType(details.gtGeneticTypeId);
            const cultureTypeOptions = cultureTypeResponse.data.response.map(type => ({
              value: type.ctCultureTypeId,
              label: type.ctDescription
            }));
            setCultureTypeOptions(cultureTypeOptions);
          }

          // Initialize the state objects
          const initialCountries = {};
          const initialSpacings = {};
          const initialCultureTypes = {};

          // Process each country from the response
          if (details.varietyOriginCountriesResponseList) {
            details.varietyOriginCountriesResponseList.forEach(country => {
              const countryKey = `status${country.countryCode === 'BRA' ? 'Brasil' : 
                                         country.countryCode === 'MEX' ? 'Mexico' : 
                                         country.countryCode === 'BGD' ? 'Bangladesh' : 
                                         country.countryCode === 'PAK' ? 'Pakistan' : country.countryCode}`;

              initialCountries[countryKey] = country.isActive;

              if (country.varietyOriginCultureTypesResponseList?.length > 0) {
                initialCultureTypes[countryKey] = country.varietyOriginCultureTypesResponseList.map(type => ({
                  value: type.ctCultureTypeId,
                  label: type.ctDescription
                }));
              }

              if (country.varietyOriginSpacingResponseList?.length > 0) {
                initialSpacings[countryKey] = country.varietyOriginSpacingResponseList.map(spacing => ({
                  value: spacing.spacingRegistrationUid,
                  label: `${spacing.lineSpacing} x ${spacing.plantSpacing}`,
                  lineSpacing: spacing.lineSpacing,
                  plantSpacing: spacing.plantSpacing,
                  spacingRegistrationUid: spacing.spacingRegistrationUid
                }));
              }
            });
          }

          console.log('Processed initial values:', {
            countries: initialCountries,
            spacings: initialSpacings,
            cultureTypes: initialCultureTypes
          });

          setSelectedCountries(initialCountries);
          setSpacingSelections(initialSpacings);
          setCultureTypeSelections(initialCultureTypes);

          setValues(prev => ({
            ...prev,
            cuCultureId: details.cuCultureName,
            gtGeneticTypeId: details.gtGeneticDescription,
            gtGeneticDescription: details.gtGeneticDescription,
            geneticTypeId: details.gtGeneticTypeId,
            status: details.isActive,
            vrVariety: details.vrVariety
          }));

          const selectedCulture = cultureOptions.find(c => c.value === details.cuCultureName);
          if (selectedCulture) {
            setSelectedCultureId(selectedCulture.cultureId);
          }
        }
      } catch (error) {
        console.error("Failed to fetch variety details:", error);
        setMessage("Failed to load variety details");
        setOpenAlert(true);
        setSeverity("error");
      }
    };

    fetchInitialData();
  }, [location?.state?.vrVarietyId, cultureOptions]);

  // Helper function to convert country code to key
  const getCountryKeyFromCode = (code) => {
    const mapping = {
      'BRA': 'Brasil',
      'MEX': 'Mexico',
      'BGD': 'Bangladesh',
      'PAK': 'Pakistan'
    };
    return mapping[code] || code;
  };

  // Helper function to get country name
  const getCountryName = (countryCode) => {
    const countryMapping = {
      'BRA': 'Brasil (pt_BR)',
      'MEX': 'Mexico (es_MX)',
      'BGD': 'Bangladesh (bd_BD)',
      'PAK': 'Pakistan (en_PK)'
    };
    return countryMapping[countryCode] || countryCode;
  };

  const handleCultureChange = async (event) => {
    const selectedValue = event.target.value;
    const selectedCulture = cultureOptions.find(c => c.value === selectedValue);

    console.log("Selected Culture Value:", selectedValue);
    console.log("Selected Culture Object:", selectedCulture);

    setValues(prev => ({
      ...prev,
      cuCultureId: selectedValue,
      gtGeneticTypeId: '',
    }));

    if (selectedCulture) {
      setIsGeneticTypesLoading(true);
      try {
        const response = await VarietyRegistrationService.getGeneticTypesByCulture(selectedCulture.cultureId);
        const options = response.data.response.map(type => ({
          value: type.gtGeneticDescription,
          label: type.gtGeneticDescription,
          geneticTypeId: type.gtGeneticTypeId
        }));
        setGeneticTypeOptions(options);
      } catch (error) {
        console.error('Failed to fetch genetic types:', error);
        setMessage('Failed to load genetic types');
        setOpenAlert(true);
        setSeverity('error');
      } finally {
        setIsGeneticTypesLoading(false);
      }
    } else {
      setGeneticTypeOptions([]);
    }

    setSelectedCultureId(selectedCulture?.cultureId || null);
  };

  const handleGeneticTypeChange = async (event) => {
    const { value } = event.target;
    const selectedType = geneticTypeOptions.find(t => t.value === value);

    setValues(prev => ({
      ...prev,
      gtGeneticTypeId: value,
      gtGeneticDescription: selectedType?.label || ''
    }));

    // Fetch culture types for the selected genetic type
    if (selectedType?.geneticTypeId) {
      try {
        const response = await VarietyRegistrationService.getCultureTypeByGeneticType(selectedType.geneticTypeId);
        console.log('Culture types response:', response.data.response); // Debug log
        
        const options = response.data.response.map(type => ({
          value: type.ctCultureTypeId,
          label: type.ctDescription
        }));
        
        console.log('Formatted culture type options:', options); // Debug log
        setCultureTypeOptions(options);
      } catch (error) {
        console.error('Failed to fetch culture types:', error);
        setMessage('Failed to load culture types');
        setOpenAlert(true);
        setSeverity('error');
      }
    } else {
      setCultureTypeOptions([]);
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name === "cuCultureName") {
      const selectedCulture = options.find((option) => option.value === value);

      if (selectedCulture) {
        const newCultureTypes = selectedCulture.cuCultureTypes.map((type) => ({
          label: type.cultureType,
          value: type.cultureTypeId,
        }));

        setCultureTypeOptions(newCultureTypes);

        setValues((prevValues) => ({
          ...prevValues,
          cuCultureId: selectedCulture.cuCultureId,
          varietyCultureType: "",
        }));
      } else {
        setCultureTypeOptions([]);
        setValues((prevValues) => ({
          ...prevValues,
          cuCultureId: "",
          varietyCultureType: "",
        }));
      }
    }
  };

  const handleCultureTypeChange = (countryKey, selectedOptions) => {
    setCultureTypeSelections(prev => ({
      ...prev,
      [countryKey]: selectedOptions
    }));
  };

  const getCuCultureIdByName = (cuCultureName) => {
    const option = options.find((option) => option.value === cuCultureName);
    return option ? option.cuCultureId : "";
  };

  // useEffect(() => {
  //   fetchTobaccoGroup();
  // }, []);

  useEffect(() => {

    if (location.state) {
      const {
        vrVarietyId,
        cuCultureName,
        vrVariety,
        varietyCultureType,
        vrVarietyCultureTypeId,
        isActive,
      } = location.state;
      console.log("🚀 ~ useEffect ~ location.state:", location.state)
      setValues((prevValues) => ({
        ...prevValues,
        vrVarietyId: vrVarietyId || "",
        cuCultureName: cuCultureName || "",
        vrVariety: vrVariety || "",
        varietyCultureType: varietyCultureType || "",
        vrVarietyCultureTypeId: vrVarietyCultureTypeId || "",
        isActive: isActive || "",
      }));
    }
  }, [values?.curingCode, location.state]);

  const handleDateChange = (date, fieldName) => {
    if (!date || date === "Invalid Date") {
      setDateError(true);
      setValues((prevValues) => ({
        ...prevValues,
        [fieldName]: "",
      }));
      return;
    }

    setDateError(false);

    const dateValue = new Date(date);
    if (isNaN(dateValue.getTime())) {
      setDateError(true);
      setValues((prevValues) => ({
        ...prevValues,
        [fieldName]: "",
      }));
      return;
    }

    const year = dateValue.getFullYear();
    const month = String(dateValue.getMonth() + 1).padStart(2, "0");
    const day = String(dateValue.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setValues((prevValues) => ({
      ...prevValues,
      [fieldName]: formattedDate,
    }));
  };

  const handleSpacingListChange = (countryKey, spacingList) => {
    console.log('Received spacing list for country:', countryKey, spacingList); // Debug log
    
    // Validate the spacing list
    const validSpacingList = spacingList.map(spacing => ({
      ...spacing,
      spacingRegistrationUid: spacing.spacingRegistrationUid || spacing.value, // Fallback to value if uid is missing
      lineSpacing: parseFloat(spacing.lineSpacing),
      plantSpacing: parseFloat(spacing.plantSpacing)
    }));

    setSpacingSelections(prev => ({
      ...prev,
      [countryKey]: validSpacingList
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await varietySchema.validate(values, { abortEarly: false });
      setErrors({});

      // Get selected culture and genetic type
      const selectedCulture = cultureOptions.find(c => c.value === values.cuCultureId);
      const selectedGeneticType = geneticTypeOptions.find(t => t.value === values.gtGeneticTypeId);

      // Debug logs
      console.log('Form Values:', values);
      console.log('Selected Culture:', selectedCulture);
      console.log('Selected Genetic Type:', selectedGeneticType);
      console.log('Genetic Type Options:', geneticTypeOptions);
      console.log('Active Countries:', activeCountries);

      // Validate required selections
      if (!selectedCulture) {
        throw new Error('Please select a culture');
      }
      
      // Use either the selected genetic type or the stored genetic type ID
      const geneticTypeId = selectedGeneticType?.geneticTypeId || values.geneticTypeId;
      if (!geneticTypeId) {
        throw new Error('Please select a genetic type');
      }

      // Prepare the payload
      const payload = {
        isDelete: false,
        createdDateTimeUTC: location.state?.edit && varietyDetails ? varietyDetails.createdDateTimeUTC : currentDate,
        createdByUser: location.state?.edit && varietyDetails ? varietyDetails.createdByUser : currentUser,
        modifiedDateTimeUTC: currentDate,
        modifiedByUser: currentUser,
        vrVarietyId: values.vrVarietyId || "",
        vrVariety: values.vrVariety,
        cuCultureId: selectedCulture.cultureId,
        gtGeneticTypeId: geneticTypeId,
        countryId: "5d68bc83-3635-4a2b-a0df-1199b0b75c6e",
        countryCode: "BRA",
        languageId: "47c8bd0d-ac5f-4786-a639-9c6fad73ab1b",
        isActive: values.status,
        varietyOriginCountriesRequestList: activeCountries.map(countryKey => {
          const countrySpacings = spacingSelections[countryKey] || [];
          const countryCultureTypes = cultureTypeSelections[countryKey] || [];
          
          // Find the corresponding country details from the fetched variety details
          const countryDetails = location.state?.edit ? 
            varietyDetails.varietyOriginCountriesResponseList.find(
              c => c.countryCode === getCountryCode(countryKey)
            ) : null;

          return {
            isDelete: false,
            vocVarietyOriginCountryId: location.state?.edit ? countryDetails?.vocVarietyOriginCountryId || "" : "",
            vocOriginCountryId: getCountryId(countryKey),
            vocOriginCountryCode: getCountryCode(countryKey),
            varietyOriginCultureTypesRequestList: countryCultureTypes.map(type => ({
              isDelete: false,
              voctVarietyOriginCultureTypeId: countryDetails?.varietyOriginCultureTypesResponseList.find(
                ct => ct.ctCultureTypeId === type.value
              )?.voctVarietyOriginCultureTypeId || "",
              ctCultureTypeId: type.value,
              ctDescription: type.label,
              isActive: true
            })),
            varietyOriginSpacingRequestList: countrySpacings.map(spacing => {
              // Find the corresponding spacing details from the fetched variety details
              const existingSpacing = countryDetails?.varietyOriginSpacingResponseList.find(
                s => s.spacingRegistrationUid === spacing.spacingRegistrationUid
              );

              return {
                isDelete: false,
                vosVarietyOriginSpacingId: existingSpacing?.vosVarietyOriginSpacingId || "",
                spacingRegistrationUid: spacing.spacingRegistrationUid,
                lineSpacing: parseFloat(spacing.lineSpacing),
                plantSpacing: parseFloat(spacing.plantSpacing),
                isActive: true
              };
            }),
            isActive: true
          };
        })
      };

      console.log('Final payload:', payload);

      let response;
      if (location.state?.edit) {
        response = await VarietyRegistrationService.updateVariety(payload);
        setMessage("Variety updated successfully");
      } else {
        response = await VarietyRegistrationService.createVariety(payload);
        setMessage("Variety created successfully");
      }
      
      setOpenAlert(true);
      setSeverity("success");
      navigate("/variety");
    } catch (err) {
      console.error('Submission error:', err);
      setMessage(err.message || "An error occurred while submitting the form");
      setOpenAlert(true);
      setSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const handleClear = (field) => {
    setValues((prevValues) => ({
      ...prevValues,
      [field]: "",
    }));
  };

  const [open, setOpen] = useState(false);

  // Function to open the modal
  const handleOpenModal = () => {
    setOpen(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleDeleteConfirm = () => {
    setOpen(false);
    navigate("/variety");
  };

  // Function to handle country change
  const handleCountryChange = (countryKey, isChecked) => {
    setSelectedCountries(prev => ({
      ...prev,
      [countryKey]: isChecked
    }));
  };

  // Add helper functions for country mapping
  const getCountryId = (countryKey) => {
    const countryMapping = {
      statusBrasil: "5d68bc83-3635-4a2b-a0df-1199b0b75c6e",
      statusMexico: "f4e193c0-f031-48e6-bc38-3c2f6634d0ce",
      statusBangladesh: "2c2d16fb-2660-429a-be96-34d7104be88a",
      statusPakistan: "your-pakistan-id"
    };
    return countryMapping[countryKey] || "";
  };

  const getCountryCode = (countryKey) => {
    const countryMapping = {
      statusBrasil: "BRA",
      statusMexico: "MEX",
      statusBangladesh: "BGD",
      statusPakistan: "PAK"
    };
    return countryMapping[countryKey] || "";
  };

  const formattedCultureTypeOptions = cultureTypeOptions.map(option => ({
    value: option.id || option.value,  // adjust based on your API response
    label: option.name || option.label // adjust based on your API response
  }));

  return (
    <Layout title={t('VarietyRegistration.pageTitle')}>
      <Box sx={{ flexGrow: 1, width: "100%" }}>
        <form 
          autoComplete="off" 
          noValidate 
          onSubmit={handleSubmit} 
        >

      {/* Form Filter Grid */}
      <Grid
        container
        spacing={2}
        rowSpacing={0.1}
        sx={{ 
          background: "#EFEFEF",
          borderRadius: "10px",
          p: 2,
          maxWidth: "100%",
          margin: "auto",
          paddingBottom: "34px",
        }}
      >
        <Grid item xs={12} style={{ paddingTop: "0px", paddingLeft: "0px" }}>
          <Typography display="flex">
            <ArrowDropDownIcon sx={{ marginRight: 1, marginBottom: 1.1 }} />
            {t('VarietyRegistration.form.title')}
          </Typography>
        </Grid>

        {/* Culture Field */}
        <Grid item xs={3}>
          <SelectInput
            fullWidth
            label={t('VarietyRegistration.culture')}
            name="cuCultureId"
            value={values.cuCultureId || ""}
            onChange={handleCultureChange}
            error={Boolean(errors.cuCultureId)}
            helperText={errors.cuCultureId}
            options={cultureOptions}
            placeholder={t('VarietyRegistration.selectOption')}
          />
        </Grid>

        {/* Genetic Type Field */}
        <Grid item xs={3}>
          <SelectInput
            fullWidth
            label={t('VarietyRegistration.geneticType')}
            name="gtGeneticTypeId"
            value={values.gtGeneticTypeId || ""}
            onChange={handleGeneticTypeChange}
            error={Boolean(errors.gtGeneticTypeId)}
            helperText={errors.gtGeneticTypeId}
            options={geneticTypeOptions}
            placeholder={t('VarietyRegistration.selectOption')}
            disabled={isGeneticTypesLoading || !values.cuCultureId}
          />
        </Grid>

        <Grid item xs={3.8} display="flex">
          <TextInput
            fullWidth
            label={t('VarietyRegistration.form.varietyName')}
            name="vrVariety"
            value={values.vrVariety || ""}
            onChange={(e) => {
              // If the value is coming from the clear button, it will be an empty string
              if (e.target.value === "") {
                handleClear('vrVariety');
              } else {
                handleChange(e);
              }
            }}
            error={Boolean(errors.vrVariety)}
            helperText={errors.vrVariety}
            placeholder=""
            size="small"
          />
        </Grid>


        {/* Status Field */}
        <Grid item xs={2} display="flex" sx={{marginTop:"-10px"}}>
        <FormControl component="fieldset">
                <FormLabel
                  sx={{
                    color: "#0E2B63",
                    mb: 2,
                    ml: 0.5,
                    "&.Mui-focused": { color: "#0E2B63" },
                    "&:hover": { color: "#0E2B63" },
                  }}
                >
                  {t('VarietyRegistration.status')}
                </FormLabel>
                <Box sx={{ display: "flex", alignItems: "center", marginTop: "-10px" }}>
                  <Typography sx={{ mr: 3, ml: 3 }}>Inactive</Typography>
                  <FormControlLabel
                    control={
                      <RedSwitch
                        checked={values.status}
                        onChange={handleChange}
                        name="status"
                        disabled={edit === false}
                      />
                    }
                    label={<Typography sx={{ ml: 2 }}>Active</Typography>}
                    labelPlacement="end"
                  />
                </Box>
              </FormControl>
        </Grid>
      </Grid>

      {/*  Spacing */}
      <Box sx={{ m: '2rem' }} />

      {/* Divider */}
      <Divider title={t('VarietyRegistration.form.originEnabled')}/>

      {/*  Spacing */}
      <Box sx={{ m: '2rem' }} />

      {/* CountryFilter */}
      <CountryFilter 
        cultureId={selectedCultureId}
        onCountryChange={handleCountryChange}
        onSpacingChange={handleSpacingListChange}
        onCultureTypeChange={handleCultureTypeChange}
        cultureTypeOptions={formattedCultureTypeOptions}
        initialCountries={selectedCountries}
        initialSpacings={spacingSelections}
        initialCultureTypes={cultureTypeSelections}
      />
      

      {/* Action Buttons - Modified */}
      <Box sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: "4px",
        borderTop: '1px solid #E0E0E0',
        backgroundColor: '#F5F5F7',
        marginTop: "2rem",
        marginLeft: "-25px",
        marginRight: "-25px",
      }}>
        {/* Cancel Button */}
        <Button
          sx={{
            backgroundColor: "#737D86",
            padding: "6px 16px",
            borderRadius: "4px",
            marginLeft: "1rem",
            marginBottom: "24px",
            "&:hover": {
              backgroundColor: "#2C343B",
            },
          }}
          variant="contained"
          onClick={handleOpenModal}
          disabled={loading}
          startIcon={loading ? (
            <CircularProgress size={20} sx={{ color: 'white' }} />
          ) : (
            <img src={arrowLeft} alt="Arrow" />
          )}
        >
          {t('VarietyRegistration.form.cancel')}
        </Button>

        {/* Save Button */}
        <Button
          sx={{
            padding: "6px 16px",
            borderRadius: "4px",
            marginRight: "1rem",
            marginBottom: "24px",
            backgroundColor: "#EF7D00",
            color: "white",
            "&:hover": {
              backgroundColor: "#EF7D00",
            },
          }}
          disabled={loading}
          startIcon={loading ? (
            <CircularProgress size={20} sx={{ color: 'white' }} />
          ) : (
            <img src={Save} alt="Right Arrow" />
          )}
          variant="contained"
          type="submit"
        >
          {t('VarietyRegistration.form.save')}
        </Button>
      </Box>

      {/* Modal - Keep existing modal code */}
      <VarietyModal 
        open={open} 
        handleClose={handleCloseModal} 
        handleConfirm={handleDeleteConfirm} 
      />
      
    </form>
  </Box>
</Layout>
);
}
