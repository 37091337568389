import { format } from "date-fns";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  variant: "menu"
};

const options = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder"
];


const formatDate = (dateString) => {
  const date = new Date(dateString);
  return format(date, "dd/MM/yyyy");
};

const formatDateDetails = (dateString) => {
  if(!dateString) return
  const date = new Date(dateString);
  return format(date, "dd/MMM/yyyy HH:mm:ss");
};
function htmlToPlainText(html) {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || "";
}

export { MenuProps, options, formatDate ,formatDateDetails ,htmlToPlainText};
