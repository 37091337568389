import styled from "@emotion/styled";
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
} from "@mui/material";
import OptionsIcon from "../../assets/optionsIcon.svg";
import BellIcon from "../../assets/bellIcon.svg";
import LogoImg from "../../assets/bat.svg";
import MenuIcon from "../../assets/menuIcon.svg";
import { useNavigate } from "react-router-dom";
import React, { useContext, useState } from "react";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import BottomMenu from "./BottomMenu";
import "./Navbar.css";
import { UserContext } from "App";
import { AuthData } from "auth/AuthWarpper";

const Navbar = ({ title }) => {
  const [state, setState] = useState({
    left: false,
  });
  const { user, logout } = AuthData(); // Access login function from AuthData context

  const LoggedUser = useContext(UserContext);
  const menuItems = [
    { id: 1, name: "Crop Year Registration", link: "/cropYearRegistration" },
    { id: 2, name: "Farmer Registration", link: "/link2" },
    { id: 3, name: "Manage Profiles", link: "/manageProfiles" },
    { id: 4, name: "Season Registration", link: "/seasonRegistration" },
    { id: 5, name: "Crop Stage Registration", link: "/cropStage" },
    { id: 6, name: "Modules Registration", link: "/getmoduleregistration" },
    { id: 7, name: "Crop Stage Calander", link: "/getCropStageCalander" },
  ];

  console.log("user nav", user);
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const navigate = useNavigate();
  function handleLogout() {
    localStorage.setItem("isLoggedIn", false);
    navigate("/");
  }
  return (
    <>
      <AppBar elevation={0} sx={{ background: "#102A63" }}>
        <Toolbar className="toolBar">
          {/* <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="open drawer"
          sx={{ mr: 2 }}
          onClick={toggleDrawer("left", true)}
        >
          <img src={MenuIcon} alt="Menu Icon" />
        </IconButton>
        <Drawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
          sx={{ zIndex: 99 }}
        >
          <DrawerContainer>
            {menuItems.map((item) => {
              return (
                <React.Fragment key={item.id}>
                  <MenuLinks href={item.link}>{item.name}</MenuLinks>
                </React.Fragment>
              );
            })}
          </DrawerContainer>
        </Drawer> */}

          <NavbarContainer className="navBarContainer">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "30px",
              }}
            >
              <img src={OptionsIcon} alt="Options Icon" onClick={() => {}} />
              <Border />
              <Logo
                src={LogoImg}
                alt="Bat Logo"
                onClick={() => navigate("/")}
              />
            </div>

            {/* <BorderLeft />
            <BorderRight /> */}
            <MenuTitle className="menuTitle">{title}</MenuTitle>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "30px",
              }}
            >
              <Border />
              <img src={BellIcon} alt="Bell Icon" onClick={() => {}} />
              {user && (
                <div style={{ fontSize: "15px" }}>
                  <div> Hello, {user.userName}</div>
                  {/* <div>{user.profileName}</div> */}
                  <div> BAT {user.countryName}</div>
                </div>
              )}
            </div>
          </NavbarContainer>

          {/* <div style={{fontSize:"10px" ,width:"115px"}}>
            Hello, {LoggedUser.profileName} <br/>
            BAT {LoggedUser.countryName} {LoggedUser.countryCode} 
          </div> */}
          {/* {user && <LogoutButton onClick={logout}>Logout</LogoutButton>} */}
          {/* { <LogoutButton onClick={logout}>Logout</LogoutButton>} */}
        </Toolbar>
      </AppBar>

      <BottomMenu />
    </>
  );
};
const NavbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 1.5rem;
  // position: relative;
`;
const BorderLeft = styled.div`
  position: absolute;
  top: -0.92rem;
  left: -1rem;
  background: #ffff;
  height: 4rem;
  width: 1px;
`;
const BorderRight = styled.div`
  position: absolute;
  top: -0.92rem;
  right: -1rem;
  background: #ffff;
  height: 4rem;
  width: 1px;
`;
const Border = styled.div`
  // position: absolute;
  // top: -0.92rem;
  // right: -1rem;
  background: #ffff;
  height: 4rem;
  width: 1px;
`;
const DrawerContainer = styled.div`
  background: white;
  width: 14rem;
  color: black;
  padding: 6rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const MenuLinks = styled.a`
  text-decoration: none;
  text-align: left;
  border-bottom: 1px solid grey;
  width: 100%;
  padding: 0.5rem;
`;
const Logo = styled.img`
  width: 6rem;
  height: auto;
`;
const MenuTitle = styled.div`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
`;
const LogoutButton = styled.button`
  width: 5rem;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
`;
const MenuBottom = styled.div`
  position: sticky;
  top: 64px;
  z-index: 1000;
  background-color: whitesmoke;
`;
export default Navbar;
