// import { http } from "../http-common";
import { httpPOC ,http} from "../http-common";

const FetchAllUser = query => {
  return http.get('/user'+query);
};
const SaveUserPOC = data => {
  return httpPOC.post('/user', data);
};
const saveUser = data => {
  return http.post('/user', data);
};
const uploadUserFile = (data, config) => {
  return http.post('/storage', data ,config);
};
const deleteUserFile = (query) => {
  return http.put('/storage'+query);
};
const FetchSingleUser = id => {
  return http.get('/user/singleUser?userPrincipalName='+id);
};
const saveUserSingle = data => {
  return http.put('/user', data);
};
const fetchFile = (url, config) => {
  return http.get('/storage?fileUrl='+url, config);
};
const findUsers = (query) => {
  return http.get('/user/find'+query);
};

const Sso404 = query => {
  return http.get('/api/v1/auth/401');
};



const FindAdUser = query => {
  return http.get('/azureADUser?batOneId='+query);
};

const UserService = {
  saveUser,
  uploadUserFile,
  FetchAllUser,
  SaveUserPOC,
  deleteUserFile,
  FetchSingleUser,
  saveUserSingle,
  fetchFile,
  findUsers,
  FindAdUser,
  Sso404
};

export default UserService;

