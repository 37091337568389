export const freightField = [
  {
    name: "freight",
    label: "Additional Freight Group Description",
    type: "text",
    required: true,
    width: "80%"
  },
  {
    name: "status",
    label: "Status",
    type: "switch",
    required: true,
  },
];


export const editFreightField = [

  {
    name: "freight",
    label: "Additional Freight Group Description",
    type: "text",
    required: true,
    width: "80%"
  },
  {
    name: "status",
    label: "Status",
    type: "switch",
    required: true,
  },
];