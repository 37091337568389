import { http, httpGrow } from "../http-common";

const getIrregularity = () => {
  return httpGrow.get(
    `/irregularity-type-registration`
  );
};

const getUniqueCode = () => {
  return httpGrow.get(`/irregularity-type-registration/generateUniqueItrCode`);
};

const deleteIrregularity = (data) => {
  return httpGrow.delete(`/irregularity-type-registration/${data}`);
};

const updateIrregularity = (data) => {
  return httpGrow.put(`/irregularity-type-registration`, data);
};

const createIrregularity = (data) => {
  return httpGrow.post(
    `/irregularity-type-registration`,data
  );
};

const IrregularityService = {
  getIrregularity,
  getUniqueCode,
  deleteIrregularity,
  updateIrregularity,
  createIrregularity,
};

export default IrregularityService;
