import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  Switch,
  CircularProgress,
  Typography,
  Modal,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  IconButton,
  Chip,
  Divider,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { soilParameterSchema, transportControlDataSchema } from "utils/validations";
import CuringService from "services/CuringService";
import CultureGroupService from "services/CultureGroup";
import { SnackbarContext } from "context/snackBarContext";
import arrowRight from "assets/arrowRight.svg";
import arrowLeft from "assets/arrowLeft.svg";
import RedSwitch from "utils/RedSwitch";
import PlantingService from "services/PlantingService";
import Save from "assets/SaveNew.svg";
import TobaccoService from "services/TobaccoService";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import Lable from "utils/lables.json";
import SoilParameterService from "services/SoilParameterService";
import { useTranslation } from "react-i18next";
import TransporterRegistrationService from "services/TransporterRegistrationService";
import * as Yup from "yup";

const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result.split(",")[1];
      resolve(base64String);
    };
    reader.onerror = (error) => reject(error);
  });
};

export default function ControlData() {
  console.log("runninggggg");
  const navigate = useNavigate();

  const location = useLocation();
  console.log("vkkkk", location);
  const {
    sapAnalysisId,
    sapExternalCode,
    sapParameterName,
    sapAbbreviation,
    sapUom,
    sapDataType,
    sapStatus,
    countryId,
    languageId,
    isDelete,
  } = location?.state?.row || {};
  const { row, edit } = location.state || {};
  const [transporterData, setTransporterData] = useState(null);
  console.log("🚀 ~ ControlData ~ transporterData:", transporterData);

  //console.log("dhoni", tobaccoCompanyName, tccCountryCode, region);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [values, setValues] = useState({
    sapAnalysisId: sapAnalysisId,
    sapExternalCode: "",
    sapParameterName: transporterData?.statusReason || "", // Assuming this is the tray type description
    sapAbbreviation: sapAbbreviation || "", // Default to 0 if not provided
    sapUom: sapUom, // Default to 0 if not provided
    sapDataType: sapDataType,
    sapStatus: sapStatus === true ? true : false, // Default to false if not true
    countryId: countryId || "", // Set default empty string or any fallback value
    languageId: languageId || "", // Set default empty string or any fallback value
    isDelete: isDelete === true ? true : false, // Default to false if not true
  });
  console.log("🚀 ~ ControlData ~ values:", values);

  const [errors, setErrors] = useState({});
  console.log("🚀 ~ ControlData ~ errors:", errors);
  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [dataType, setDataType] = useState([]);
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const [fileBase64, setFileBase64] = useState("");

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click(); // Trigger file input click
  };
  const handleRemoveFile = (fileName) => {
    setFileNames((prevFileNames) =>
      prevFileNames.filter((name) => name !== fileName)
    );
  };

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    setFileNames(files.map((file) => file.name));

    if (files.length > 0) {
      try {
        const base64String = await fileToBase64(files[0]);
        setFileBase64(base64String);
      } catch (error) {
        console.error("Error converting file to base64:", error);
        setSeverity("error");
        setMessage("Error processing file");
        setOpenAlert(true);
      }
    }
  };
  // Function to open the modal
  const handleOpenModal = () => {
    setOpen(true);
  };

  const getdataTypee = async () => {
    try {
      const response = await SoilParameterService.getDataType();
      console.log("tys", response.data.response);
      const d = response.data.response.ASAPD;
      setDataType(d.map((e) => e.pickListDescription));
      // pickListDescription
      // return setValues(response.data.response.culture);
    } catch (error) {
      console.error("Error generating unique code:", error);
      return null;
    }
  };
  useEffect(() => {
    getdataTypee();
  }, []);

  const handleCloseModal = () => {
    setOpen(false);
  };

  // Function when user confirms "Yes" on the modal
  const handleDeleteConfirm = () => {
    setOpen(false);
    navigate("/transporterRegistration");
  };

  const handleChange = (event) => {
    // console.log("working");
    const { name, value, type, checked } = event.target;
    // console.log("WERTYUI",name, value, type, checked);
    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  useEffect(() => {
    if (transporterData) {
      setValues((prevValues) => ({
        ...prevValues,
        sapParameterName: transporterData.statusReason || "",
        sapExternalCode: transporterData.status || "",
      }));
    }
  }, [transporterData]);

  const fetchTransporterData = async () => {
    if (row?.transporterID) {
      try {
        const response = await TransporterRegistrationService.getTransporter(
          row.transporterID
        );
        const data = response.data.response;
        console.log("Fetched transporter data:", data);
        setTransporterData(data);

        // Update file names if there's an existing document
        if (data.attachedDocumentPath) {
          setFileNames(data.attachedDocumentPath.split(", ").filter(Boolean));
        }
      } catch (error) {
        console.error("Error fetching transporter data:", error);
        setSeverity("error");
        setMessage("Failed to fetch transporter details");
        setOpenAlert(true);
      }
    }
  };

  useEffect(() => {
    fetchTransporterData();
  }, [row?.transporterID]);

  const handleUpdate = async (event) => {
    event.preventDefault();

    try {
      await transportControlDataSchema.validate(values, { abortEarly: false });
      setErrors({});
      // Convert boolean strings to actual booleans
      const ciotRequirementBool = transporterData?.cioTRequirement === true;
      const tallVoucherBool = transporterData?.tallVoucher === true;

      // Format partners list if it exists
      const formattedPartners =
        transporterData?.partnerListList?.map((partner) => ({
          createdByUser: "Mayur",
          createdDateTimeUTC: new Date().toISOString(),
          modifiedByUser: "Mayur",
          modifiedDateTimeUTC: new Date().toISOString(),
          plName: partner.plName,
          plFiscalID: partner.plFiscalID,
          plCardId: partner.plCardId,
          plDOB: partner.plDOB,
          plEmail: partner.plEmail,
          plPhoneNumber: partner.plPhoneNumber,
        })) || [];

      const payload = {
        // Metadata
        createdDateTimeUTC: new Date().toISOString(),
        createdByUser: "Mayur",
        modifiedDateTimeUTC: new Date().toISOString(),
        modifiedByUser: "Mayur",

        // Control Data specific fields (from this component)
        status: values.sapExternalCode,
        statusReason: values.sapParameterName,
        attachedDocumentPath: fileNames.join(", "),
        fileUploadInfo: {
          fileName: fileNames[0] || "",
          base64FileContent: fileBase64 || "",
        },

        // Business Information
        businessType: transporterData.businessType,
        businessNumber: transporterData.businessNumber,
        transporterID: row.transporterID,
        transporterName: transporterData.transporterName,
        nationalityId: transporterData.nationalityId,
        maritalStatusId: transporterData.maritalStatusId,

        // Route Details
        buyingStation: transporterData.buyingStation,
        buyingStationRoute: transporterData.buyingStationRoute,
        supplyInvoicingUnit: transporterData.supplyInvoicingUnit,
        supplyInvoicingUnitRoute: transporterData.supplyInvoicingUnitRoute,

        // Bank Information
        bankAccountType: transporterData.bankAccountType,
        bankRegistrationId: transporterData.bankRegistrationId,
        bankBranchRegistrationID: transporterData.bankBranchRegistrationID,
        accountNumber: transporterData.accountNumber,

        // Responsible Users
        ftResponsibleUID: transporterData.ftResponsibleUID,
        tmResponsibleUID: transporterData.tmResponsibleUID,

        // Address Information
        address: transporterData.address,
        neighborhood: transporterData.neighborhood,
        postalCode: transporterData.postalCode,
        regionId: transporterData.regionId,

        // Company Information
        businessSize: transporterData.businessSize,
        cioTRequirement: ciotRequirementBool,
        companyEstablishmentDate: transporterData.companyEstablishmentDate,
        coupaRegisterNumber: transporterData.coupaRegisterNumber,
        emailForInvoices: transporterData.emailForInvoices,
        expirationDateCoupa: transporterData.expirationDateCoupa,
        externalCode: transporterData.externalCode,
        fieldTechnicianUser: transporterData.fieldTechnicianUser,

        // Tax and Legal Information
        icmsTaxPercentage: transporterData.icmsTaxPercentage || 0,
        legalName: transporterData.legalName,
        legalNatureCode: transporterData.legalNatureCode,
        mainEconomicActivityCode: transporterData.mainEconomicActivityCode,
        secondaryEconomicActivityCode:
          transporterData.secondaryEconomicActivityCode,
        sapVendorCode: transporterData.sapVendorCode,
        stateRegistrationNumber: transporterData.stateRegistrationNumber,
        tallVoucher: tallVoucherBool,
        taxation: transporterData.taxation,
        tradeName: transporterData.tradeName,
        transporterLicenseNumber: transporterData.transporterLicenseNumber,

        // Partners List
        partnerListList: formattedPartners,
      };
      setLoading(true);
      const response = await TransporterRegistrationService.updateTransporter(
        payload
      );
      setLoading(false);

      if (response.status === 200) {
        setSeverity("success");
        setMessage(t("snackbarMessage.updatedSuccessfully"));
        setOpenAlert(true);
        navigate("/transporterRegistration");
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        let newError = {};
        err?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setErrors(newError);
      } else {
        console.error("An error occurred while submitting the form:", err);
        setSeverity("error");
        setMessage(
          err?.response?.data?.message ||
            "An error occurred while submitting the form"
        );
        setOpenAlert(true);
      }
    }
  };

  return (
    <Box
      sx={{
        minHeight: "125vh",
        flexGrow: 1,
        paddingBottom: "10rem",
        width: "100%",
        "& .MuiTextField-root": { m: 1 },
        "& .MuiFormLabel-root": {
          color: "rgba(16, 42, 98, 1)",
          "& .MuiFormLabel-asterisk": {
            color: "rgba(239, 0, 0, 1)",
            fontSize: "18px",
          },
        },
      }}
    >
      <form autoComplete="off" noValidate>
        <Box
          sx={{
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "space-between",
            bgcolor: "#EFEFEF",
            height: "152px",
            marginTop: "15px",
            padding: "10px",
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  name="sapExternalCode"
                  label={t("TransportationRegistration.formLabels.status")}
                  value={values?.sapExternalCode}
                  onChange={handleChange}
                  disabled={edit == false}
                  fullWidth
                  required
                  select
                  SelectProps={{ IconComponent: ArrowDropDownIcon }}
                  error={!!errors.sapExternalCode} // Show error state
                  helperText={errors.sapExternalCode || " "} // Show error message
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                    
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                >
                  {["Active", "Inactive", "Pending", "Blocked"].map(
                    (item, index) => (
                      <MenuItem key={index} value={item}>
                        {item === "Active"
                          ? t("active")
                          : item === "Inactive"
                          ? t("inactive")
                          : t(
                              `TransportationRegistration.status.${item.toLowerCase()}`
                            )}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={8.8}>
              <FormControl fullWidth>
                <TextField
                  name="sapParameterName"
                  label={t(
                    "TransportationRegistration.formLabels.statusReason"
                  )}
                  value={values?.sapParameterName}
                  onChange={handleChange}
                  disabled={edit == false}
                  fullWidth
                  required
                  error={!!errors.sapParameterName} // Show error state
                  helperText={errors.sapParameterName || " "} // Show error message
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={8}>
              <FormControl fullWidth>
                <TextField
                  name="sapDataType"
                  label={t(
                    "TransportationRegistration.formLabels.attachDocument"
                  )}
                  value=""
                  disabled
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <Grid
                        container
                        spacing={1}
                        alignItems="center"
                        wrap="nowrap" // Prevents items from breaking into multiple rows
                        style={{
                          // maxHeight: "48px", // Adjust as needed
                          overflowX: "auto", // Allows horizontal scrolling if needed
                        }}
                      >
                        {fileNames.map((fileName, index) => (
                          <Grid item key={index}>
                            <Chip
                              label={fileName}
                              onDelete={() => handleRemoveFile(fileName)}
                              deleteIcon={<ClearIcon />}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    ),
                    style: { height: "50px" },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      // minHeight: "50px",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3.87}>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  borderRadius: 1,
                  background: "#004F9F",
                  height: { xs: "48px" },
                }}
                onClick={handleButtonClick} // Trigger file input on button click
              >
                {t("TransportationRegistration.buttons.uploadFiles")}
              </Button>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }} // Hide the input
                onChange={handleFileChange}
                multiple // Allow multiple file selection
              />
            </Grid>
          </Grid>
        </Box>

        <Divider
          sx={{
            position: "fixed",
            bottom: "82px",
            width: "97%",
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            position: "fixed",
            width: "96%",
            bottom: 6,
            padding: "0.5rem",
            backgroundColor: "white",
            zIndex: 999,
            // Set background color to white
          }}
        >
          {/* Cancel Button - Left bottom corner */}
          <Button
            style={{
              backgroundColor: "#737D86",
              padding: "6px 16px",
              borderRadius: "4px",

              marginBottom: "16px",
              height: "44px", // Match height
              marginLeft: "10px",
            }}
            variant="contained"
            onClick={edit == false ? handleDeleteConfirm : handleOpenModal}
            disabled={loading}
            startIcon={<img src={arrowLeft} alt="Arrow" />}
          >
            {loading && <CircularProgress size={20} />}
            {edit == false ? t("back") : t("cancel")}
          </Button>

          {/* Center Buttons */}

          <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                backgroundColor: "#fff",
                borderRadius: "7px",
                width: "585px",
                margin: "auto",
                mt: 25,
                height: "200px",
              }}
            >
              <Box
                sx={{
                  background: "#102A62",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderTopLeftRadius: "7px",
                  borderTopRightRadius: "7px",
                }}
              >
                <Typography
                  sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
                >
                  {t("cancelTitle")}
                </Typography>
              </Box>
              <Box sx={{ p: 2 }}>
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2, fontWeight: 400 }}
                >
                  {t("cancelMessage")}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                  mb: 1,
                }}
              >
                <Button
                  onClick={handleCloseModal}
                  sx={{
                    background: "#737D86",
                    width: "100px",
                    color: "#ffffff",
                    "&:hover": { background: "#737D86" },
                  }}
                >
                  {t("no")}
                </Button>
                <Button
                  onClick={handleDeleteConfirm}
                  sx={{
                    background: "#004F9F",
                    width: "100px",
                    color: "#ffffff",
                    "&:hover": { background: "#004F9F" },
                  }}
                >
                  {t("yes")}
                </Button>
              </Box>
            </Box>
          </Modal>

          {/* Save Button - Right bottom corner */}
          {edit != false && (
            <Button
              sx={{
                padding: "6px 16px",
                borderRadius: "4px",
                marginBottom: "16px",
                backgroundColor: "#EF7D00",
                color: "white",
                "&:hover": {
                  backgroundColor: "#EF7D00",
                },
                marginRight: "10px",
              }}
              onClick={handleUpdate} // Changed from implicit form submission
              disabled={loading}
              startIcon={<img src={Save} alt="Right Arrow" />}
              variant="contained"
            >
              {loading && <CircularProgress size={20} />}
              {t("save")}
            </Button>
          )}
        </Box>
      </form>
    </Box>
  );
}
