import { httpGrow } from "../http-common";

const getTobacco = async (query) => {
  const response = await httpGrow.get(
    `/tobaccoCompaniesCompetitors?pageNumber=${query?.pageNumber}&pageSize=${query?.size}`
  );
  return response;
};


const createTobacco = (data) => {
  return httpGrow.post(`/tobaccoCompaniesCompetitors`, data);
};

const updateTobacco = (data) => {
  return httpGrow.put(`tobaccoCompaniesCompetitors`, data);
};

const deleteTobacco = (tobaccoCompanyId) => {
  return httpGrow.delete(`/tobaccoCompaniesCompetitors/${tobaccoCompanyId}`);
};
// const searchTobacco = async (query) => {
//   const response = await httpGrow.get(
//     `/tobaccoCompaniesCompetitors/search?pageNumber=${query?.pageNumber}&pageSize=${
//       query?.size || 50
//     }&tobaccoCompanyName=${query?.tobaccoCompanyName || ""}&
//     tccCountryCode=${
//       query?.tccCountryCode || ""
//     }&region=${query?.region || ""}&status=${
//       query?.status !== undefined ? query.status : ""
//     }`
//   );
//   return response;
// };


const searchTobacco =async(queryString)=>{
  const response =await httpGrow.get(`/tobaccoCompaniesCompetitors/search${queryString}`)
  return response;
}

const getRegions =  () => {
  const response = httpGrow.get(
    `region`
  );
  return response;
};

const TobaccoService = {
  getTobacco,
  createTobacco,
  deleteTobacco,
  searchTobacco,
  updateTobacco,
  getRegions
};

export default TobaccoService;
