export const cultureFormConfig = [
  {
    name: "cuCultureCode",
    label: "Code",
    type: "text",
    required: true,
    disabled :true,
  },
  {
    name: "cuCultureName",
    label: "Culture",
    type: "text",
    required: true,
    // disabled :false,
  },
  {
    name: "cuStatus",
    label: "Status",
     type: "switch",
  },
]