
import { http } from "../http-common";

const getCroplist = (id) => {
  return http.get('/crop-stage');
};

const getmoduleregistration = (id) => {
  return http.get(`/module/${id}`);
};

const create = (data) => {
  return http.post("/module", data);
};

const updateModule = (data) => {
  return http.put(`/module`, data);
};
const updateSequence = (data) => {
  return http.put(`/module/sequence`, data);
};
const remove = (id) => {
  return http.delete(`/module/${id}`);
};

const ModulesRegistration = {
  getmoduleregistration,
  getCroplist,
  create,
  updateModule,
  updateSequence,
  remove,
};

export default ModulesRegistration;
