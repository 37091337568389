import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  CircularProgress,
  Grid,
  Typography,
  Modal,
  MenuItem,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Layout from "components/structure/Layout";
import CuringTypeService from "services/CuringTypeService"; // Assuming you have this service for curing types
import { SnackbarContext } from "context/snackBarContext";
import arrowLeft from "assets/arrowLeft.svg";
import RedSwitch from "utils/RedSwitch";
import Save from "assets/SaveNew.svg";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import CloseIcon from "@mui/icons-material/Close"; // <-- Import CloseIcon for the close button inside TextField
import { useTranslation } from "react-i18next";

export default function AddCuringUnit() {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    countryId,
    ctrId,
    cultureId,
    cultureName,
    curingUnitName,
    sqMeters,
    capacityPerSqMeter,
    capacity,
    noOfDockyards,
    details,
    curingType,
    isActive,
    isDelete,
    languageId,
    modifiedDateTimeUTC,
  } = location?.state?.row || {};
  const { edit } = location.state || {};
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [curingTypes, setCuringTypes] = useState(["Flue Cured", "Air Cured"]);

  const [values, setValues] = useState({
    ctrId: ctrId || "",
    curingUnitName:curingUnitName || "",
    curingType: curingType || "",
    sqMeters:sqMeters||"",
    capacity:capacity||"",
    capacityPerSqMeter:capacityPerSqMeter||"",
    noOfDockyards:noOfDockyards||"",
    cultureName: cultureName || "",
    cultureId: cultureId || "",
    isActive: isActive === true ? true : false,
    countryId: countryId || "9b72d29c-1555-480b-ac8b-3b10f383b5f6",
    languageId: languageId || "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleDeleteConfirm = () => {
    setOpen(false);
    navigate("/curingUnit");
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name === "cultureName") {
      const selectedCulture = curingTypes.find(
        (cultureType) => cultureType.cuCultureName === value
      );
      setValues((prevValues) => ({
        ...prevValues,
        cultureId: selectedCulture ? selectedCulture.cuCultureId : "",
        cultureName: value,
      }));
    }
  };

  // const CallCuringTypesApi = () => {
  //   CuringTypeService.getAllCultureNames()
  //     .then((res) => {
  //       const cultureList = res?.data?.response || [];
  //       setCultureNames(cultureList);
  //     })
  //     .catch((error) => {
  //       setMessage(error?.response?.data?.message || error?.message);
  //       setOpenAlert(true);
  //       setSeverity("error");
  //       setCultureNames([]);
  //     });
  // };

  useEffect(() => {
    // CallCultureNamesApi();
  }, []);

  const handleValidation = (schemaValidationErrors, curingTypeTrimmed) => {
    const specialCharPattern = /^[a-zA-Z0-9 ]+$/;

    if (!curingTypeTrimmed) {
      schemaValidationErrors.curingType = "Curing Type is required.";
    } else if (!specialCharPattern.test(curingTypeTrimmed)) {
      schemaValidationErrors.curingType = "No special characters allowed.";
    } else if (curingTypeTrimmed !== values?.curingType) {
      schemaValidationErrors.curingType =
        "No leading or trailing spaces allowed.";
    }

    if (!values.cultureName) {
      schemaValidationErrors.cultureName = "Culture Name is required.";
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const schemaValidationErrors = {};
    const specialCharPattern = /^[a-zA-Z0-9 ]+$/;
    const curingTypeTrimmed = values?.curingType?.trim();

    handleValidation(schemaValidationErrors, curingTypeTrimmed);

    if (Object.keys(schemaValidationErrors).length > 0) {
      setErrors(schemaValidationErrors);
      return;
    }

    const payload = {
      countryId: values.countryId,
      cultureId: values.cultureId,
      cultureName: values.cultureName,
      curingType: curingTypeTrimmed,
      isActive: values?.isActive,
      isDelete: false,
      languageId: values.languageId,
      modifiedDateTimeUTC: new Date().toISOString(),
    };

    try {
      setLoading(true);
      const response = await CuringTypeService.createCuringType({
        ...payload,
      });
      setLoading(false);

      if (response.status === 201) {
        setSeverity("success");
        setMessage("RECORD SAVED SUCCESSFULLY!");
        setOpenAlert(true);
        navigate("/curingUnit");
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 409) {
        setSeverity("error");
        setMessage(error.response.data.message);
        setOpenAlert(true);
      } else if (error.response && error.response.status === 500) {
        setSeverity("error");
        setMessage(
          `The ${values.curingType} already exists. Please provide a unique Curing Type Name`
        );
        setOpenAlert(true);
      } else {
        setSeverity("error");
        setMessage("Submission Failed");
        setOpenAlert(true);
      }
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    const schemaValidationErrors = {};
    const specialCharPattern = /^[a-zA-Z0-9 ]+$/;
    const curingTypeTrimmed = values?.curingType?.trim();

    handleValidation(schemaValidationErrors, curingTypeTrimmed);

    if (Object.keys(schemaValidationErrors, specialCharPattern).length > 0) {
      setErrors(schemaValidationErrors);
      return;
    }

    try {
      await schemaValidationErrors.validate(values, { abortEarly: false });
    } catch (err) {
      err?.inner?.forEach((error) => {
        schemaValidationErrors[error.path] = error.message;
      });
      setErrors(schemaValidationErrors);
    }

    if (Object.keys(schemaValidationErrors).length === 0) {
      const payload = {
        countryId: values.countryId,
        ctrId: values.ctrId,
        cultureId: values.cultureId,
        cultureName: values.cultureName,
        curingType: curingTypeTrimmed,
        isActive: values?.isActive,
        isDelete: false,
        languageId: values.languageId,
        modifiedDateTimeUTC: new Date().toISOString(),
      };
      try {
        setLoading(true);
        const response = await CuringTypeService.updateCuringType({
          ...payload,
        });
        setLoading(false);
        if (response.status === 201) {
          setSeverity("success");
          setMessage("RECORD SAVED SUCCESSFULLY!");
          setOpenAlert(true);
          navigate("/curingUnit");
        }
      } catch (error) {
        setLoading(false);
        if (error.response && error.response.status === 409) {
          setSeverity("error");
          setMessage(error.response.data.message);
          setOpenAlert(true);
        } else if (error.response && error.response.status === 500) {
          setSeverity("error");
          setMessage(
            `The ${values.curingType} already exists. Please provide a unique Curing Type Name`
          );
          setOpenAlert(true);
        } else {
          setSeverity("error");
          setMessage("Update failed");
          setOpenAlert(true);
        }
      }
    }
  };

  return (
    <Layout title={t("curingUnitRegistration.pageTitle")} background="#ffffff">
      <Box
        sx={{
          minHeight: "10%",
          flexGrow: 1,
          mt: "20px",
          paddingBottom: "10rem",
          width: "100%",
          "& .MuiTextField-root": { m: 1 },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
          },
        }}
      >
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box
            sx={{ padding: "1rem", paddingBottom:0 , bgcolor: "#EFEFEF", borderRadius: "5px" }}
          >
            <Grid container rowSpacing={0} columnSpacing={3}>
              {/* Curing Unit Name */}
              <Grid item xs={3}>
                <TextField
                  name="curingUnitName"
                  label={t("curingUnitRegistration.curingUnitName")}
                  value={values?.curingUnitName}
                  onChange={handleChange}
                  variant="outlined"
                  disabled={edit === false}
                  fullWidth
                  helperText={errors.curingType ? errors.curingType : " "}
                  error={!!errors.curingType}
                />
              </Grid>

              {/* Curing Type Dropdown */}
              <Grid item xs={3}>
                <TextField
                  select
                  name="curingType"
                  label={t("curingUnitRegistration.curingType")}
                  value={values.curingType}
                  onChange={handleChange}
                  fullWidth
                  helperText={errors.cultureName ? errors.cultureName : " "}
                  error={!!errors.cultureName}
                  InputProps={{
                    endAdornment: values.cultureName && (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => {
                            setValues({ ...values, cultureName: "" });
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value="" disabled>
                    <Typography>{t("selectoption")}</Typography>
                  </MenuItem>
                  {curingTypes.map((culture, index) => (
                    <MenuItem key={index} value={culture}>
                      {culture}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={3}>
                <TextField
                  name="sqMeters"
                  label={t("curingUnitRegistration.sqmeters")}
                  value={values?.sqMeters}
                  onChange={handleChange}
                  variant="outlined"
                  disabled={edit === false}
                  fullWidth
                  helperText={errors.curingType ? errors.curingType : " "}
                  error={!!errors.curingType}
                />
              </Grid>

              {/* Status Switch */}
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <FormLabel>{t("status")}</FormLabel>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ mr: 2 }}>{t("Inactive")}</Typography>
                    <FormControlLabel
                      control={
                        <RedSwitch
                          checked={values.isActive}
                          onChange={handleChange}
                          name="isActive"
                        />
                      }
                      label={<Typography paddingLeft="10px">{t("Active")}</Typography>}
                      labelPlacement="end"
                    />
                  </Box>
                </FormControl>
              </Grid>

              {/* Capacity */}
              <Grid item xs={3}>
                <TextField
                  name="capacity"
                  label={t("curingUnitRegistration.capacity")}
                  value={values?.capacity}
                  onChange={handleChange}
                  variant="outlined"
                  disabled={edit === false}
                  fullWidth
                  helperText={errors.curingType ? errors.curingType : " "}
                  error={!!errors.curingType}
                />
              </Grid>

              {/* Capacity per sqm */}
              <Grid item xs={3}>
                <TextField
                  name="capacityPerSqMeter"
                  label={t("curingUnitRegistration.capacitypersqm")}
                  value={values?.capacityPerSqMeter}
                  onChange={handleChange}
                  variant="outlined"
                  disabled={edit === false}
                  fullWidth
                  helperText={errors.curingType ? errors.curingType : " "}
                  error={!!errors.curingType}
                />
              </Grid>

              {/* Number of Dockyards */}
              <Grid item xs={3}>
                <TextField
                  name="noOfDockyards"
                  label={t("curingUnitRegistration.noOfDockyards")}
                  value={values?.noOfDockyards}
                  onChange={handleChange}
                  variant="outlined"
                  disabled={edit === false}
                  fullWidth
                  helperText={errors.curingType ? errors.curingType : " "}
                  error={!!errors.curingType}
                />
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              position: "fixed",
              width: "100%",
              bottom: 0,
              padding: "1rem",
            }}
          >
            <Button
              style={{
                backgroundColor: "#737D86",
                padding: "6px 16px",
                borderRadius: "4px",
                position: "fixed",
                left: "1rem",
                bottom: "1rem",
                marginBottom: "50px",
              }}
              variant="contained"
              onClick={handleOpenModal}
              disabled={loading}
              startIcon={<img src={arrowLeft} alt="Arrow" />}
            >
              {edit === false ? t("backButton") : t("cancel")}
            </Button>

            {loading && <CircularProgress size={20} />}

            <Modal
              open={open}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "7px",
                  width: "585px",
                  margin: "auto",
                  mt: 25,
                  height: "200px",
                }}
              >
                <Box
                  sx={{
                    background: "#102A62",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                  }}
                >
                  <Typography
                    sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
                  >
                    {t("curingUnitRegistration.cancelOperationTitle")}
                  </Typography>
                </Box>
                <Box sx={{ p: 2 }}>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, fontWeight: 400 }}
                  >
                    {t("curingUnitRegistration.cancelOperationMessage")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                    mb: 1,
                  }}
                >
                  <Button
                    onClick={handleCloseModal}
                    sx={{
                      background: "#737D86",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#737D86" },
                    }}
                  >
                    {t("no")}
                  </Button>
                  <Button
                    onClick={handleDeleteConfirm}
                    sx={{
                      background: "#004F9F",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#004F9F" },
                    }}
                  >
                    {t("yes")}
                  </Button>
                </Box>
              </Box>
            </Modal>

            {edit != false && (
              <Button
                sx={{
                  padding: "6px 16px",
                  borderRadius: "4px",
                  position: "fixed", // Fix the button
                  right: "1rem", // Aligns to the right bottom corner
                  bottom: "1.5rem", // Position it at the bottom
                  marginBottom: "40px",
                  backgroundColor: "#EF7D00",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#EF7D00",
                  },
                }}
                disabled={loading}
                startIcon={<img src={Save} alt="Right Arrow" />}
                onClick={!edit ? handleSubmit : handleUpdate}
                variant="contained"
              >
                {loading && <CircularProgress size={20} />}
                {t("save")}
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </Layout>
  );
}
