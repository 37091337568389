import { Box, Button } from "@mui/material";
import React, { useReducer, useState } from "react";
import AdService from "../../../services/AdService";
import Layout from "../../structure/Layout";


const AD = () => {
    const [response, setResponse] = useState()

    const callAPI1 = async (url) => {
        let response = await AdService.genericGet(url);
        console.log('res', response)
        try {
            if (url == '/me/photo/$value') {
                setResponse(response);

            } else {
                setResponse(response);
            }
        } catch (er) {
            setResponse(er);
        }

    }

    const callAPI = (url) => {
        AdService.genericGet(url).then((response) => {
            if (url == '/me/photo/$value') {
                setResponse(response);

            } else {
                setResponse(response);
            }
        }).catch((err) => {
            setResponse(err);

        })
        console.log('res', response)
    }

    return (
        <Layout title={"AD sample queries"}>
            <Box>
                <Button variant="contained" onClick={() => { callAPI('/me') }}>ME</Button>
                <Button variant="contained" onClick={() => { callAPI('/me/photo/$value') }}>My PHoto</Button>
                <Button variant="contained" onClick={() => { callAPI('me/manager') }}>My Manager</Button>
                <Button variant="contained" onClick={() => { callAPI('/me/drive/root/children') }}>My DRIve</Button>
                <Button variant="contained" onClick={() => { callAPI('/me') }}>ME</Button>
            </Box>
            <Box>
                {JSON.stringify(response)}
                <div id='adimage'>qwd</div>
            </Box>

        </Layout>

    )
}

export default AD;