import React, { useState, useEffect, useContext } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { Icon } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import arrowDown from "assets/arrowDown.svg";
import "components/pages/Growing/Farmer_Management/Farmer_Management/FarmerAdd/FarmerAdd.css";
import arrowLeft from "assets/arrowLeft.svg";
import arrowRight from "assets/arrowRight.svg";
import RenderField from "utils/RenderField";
import { SnackbarContext } from "context/snackBarContext";
// import { equipmentRegistrationFormConfig } from "./EquipmentRegistrationForm";
import Layout from "components/structure/Layout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import leftIcon from "assets/iconLeft.svg";
import * as Yup from "yup";
import EquipmentService from "services/EquipmentService";
import { equipmentSchema } from "utils/validations";
import { useTranslation } from "react-i18next";

export default function AddEquipmentRegistration(props) {
  const navigate = useNavigate();
  const params = useParams();
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const [errors, setErrors] = useState({ cultureTypes: [] });
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { equipmentId, equipmentCode, equipmentName } = location.state || {};
  console.log("Hiiiiiiiii", equipmentId, equipmentCode, equipmentName);

 const equipmentRegistrationFormConfig = [
    {
      name: "externalCode",
      label: t("equipmentRegistration.externalCode"),
      type: "code",
      required: true,
      disabled: true,
    },
    {
      name: "equipmentName",
      label: t("equipmentRegistration.equipment"),
      type: "text",
      required: true,
    },
  
]

  const [values, setValues] = useState({
    externalCode: equipmentCode,
    equipmentName: equipmentName,
    equipmentId: equipmentId,
  });

  console.log("CALIdation", values.externalCode);
  const getEquipmentUniqueCode = async () => {
    try {
      const response = await EquipmentService.getEquipmentUniqueCode();
      return response.data.response;
    } catch (error) {
      console.error("Error generating unique code:", error);
      return null;
    }
  };

  useEffect(() => {
    const setEquipmentCode = async () => {
      if (!equipmentCode) {
        const uniqueCode = await getEquipmentUniqueCode();
        setValues((prevValues) => ({
          ...prevValues,
          externalCode: uniqueCode,
        }));
      }
    };
    setEquipmentCode();
  }, [equipmentCode]);

   const equipmentSchema = Yup.object().shape({
    equipmentName: Yup
      .string()
      .required(t("equipmentRegistration.validations.eqiRequired"))
      .max(40, t("equipmentRegistration.validations.eqiMax"))
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        t("equipmentRegistration.validations.eqiSpecial")
      )
      .matches(
        /^\S.*\S$|^\S$/,
        t("equipmentRegistration.validations.eqiSpace")
      ),
  });
  

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   let schemaValidationErrors = {};
  //   try {
  //   } catch (err) {
  //     err?.inner?.forEach((error) => {
  //       schemaValidationErrors[error.path] = error.message;
  //     });
  //     setErrors(schemaValidationErrors);
  //   }

  //   if (Object.keys(schemaValidationErrors).length === 0) {
  //     const payload = {
  //       equipmentCode: values?.externalCode,
  //       equipmentName: values?.equipmentName,
  //       equipmentId: values?.equipmentId,
  //     };

  //     try {
  //       const response = await EquipmentService.createEquipment({ ...payload });
  //       if (response.status === 201) {
  //         setSeverity("success");
  //         setMessage(values.equipmentId ? t("snackbarMessage.editedSuccessfully") : t("snackbarMessage.savedSuccessfully"));
  //         setOpenAlert(true);
  //         navigate("/EquipmentRegistration");
  //       } else {
  //         throw new Error(t("snackbarMessage.submissionFailed"));
  //       }
  //     } catch (error) {
  //       console.error("Error during submission:", error);
  //       setSeverity("error");
  //       setMessage(t("snackbarMessage.submissionFailed"));
  //       setOpenAlert(true);
  //     }
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await equipmentSchema.validate(values, { abortEarly: false });
      setErrors({});
      const payload = {
        equipmentCode: values?.externalCode,
        equipmentName: values?.equipmentName,
        equipmentId: values?.equipmentId,
      };
      setLoading(true);
      const response = await EquipmentService.createEquipment({ ...payload });
      console.log("🚀 ~ handleSubmit ~ response:", response);
      setLoading(false);
      if (response.status === 201) {
        setSeverity("success");
        setMessage(
          values.equipmentId ? t("snackbarMessage.updatedSuccessfully") : t("snackbarMessage.successAdd")
        );
        setOpenAlert(true);
        navigate("/EquipmentRegistration");
        // setLoading(false);
      } else {
        console.error("Failed to submit form");
        setSeverity("error");
        setMessage(t("snackbarMessage.submissionFailed"));
        setOpenAlert(true);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        let newError = {};
        err?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setErrors(newError);
      } else {
        console.error("An error occurred while submitting the form:", err);
        setSeverity("error");
        setMessage("An error occurred while submitting the form");
        setOpenAlert(true);
      }
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleClear = (field) => {
    setValues((prevValues) => ({
      ...prevValues,
      [field]: "",
    }));
  };

  return (
    <Layout title={t("equipmentRegistration.addPageTitle")}>
      <Box
        sx={{
          minHeight: 180,
          flexGrow: 1,
          paddingBottom: "10rem",
          width: "100%",
          "& .MuiTextField-root": { m: 1, color: "red", width: "25ch" },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
          },
        }}
      >
        <Button
          style={{
            margin: "30px 0px 30px 10px",
            color: "#004F9F",
            borderColor: "#004F9F",
            "text-transform": "none"
          }}
          required
          variant="outlined"
          startIcon={
            <Icon>
              <img
                alt="edit"
                style={{ display: "flex", justifyContent: "center" }}
                width="100%"
                height="100%"
                src={leftIcon}
              />
            </Icon>
          }
          onClick={() => navigate("/EquipmentRegistration")}
        >
          {t("equipmentRegistration.backHome")}
        </Button>

        <form autoComplete="off" noValidate>
          <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<img src={arrowDown} alt="Down Arrow" />}
            defaultExpandIcon={<ChevronRightIcon />}
            defaultExpanded={["1", "4", "5"]}
          >
            {equipmentRegistrationFormConfig.slice(0, 19).map((field) => (
              <RenderField
                key={field.name}
                field={field}
                values={values}
                errors={errors}
                handleChange={handleChange}
                handleClear={handleClear}
                shrink={true}
              />
            ))}
          </TreeView>
        </form>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "2rem",
            // marginLeft: "500px",
            marginTop: "200px",
          }}
        >
          <Button
            style={{
              backgroundColor: "#737D86",
              padding: " 6px, 16px, 6px, 16px",
              borderRadius: " 4px",
            }}
            startIcon={<img src={arrowLeft} alt="Left Arrow" />}
            variant="contained"
            type="secondary"
            onClick={() => navigate("/EquipmentRegistration")}
            disabled={loading}
          >
            {t("cancel")}
          </Button>
          <Button
            sx={{
              backgroundColor: "#004F9F",
              padding: " 6px, 16px, 6px, 11px",
              borderRadius: " 4px",
              color: "white",
            }}
            endIcon={<img src={arrowRight} alt="Right Arrow" />}
            type="button"
            variant="contained"
            onClick={handleSubmit}
            disabled={loading || values.externalCode == undefined}
          >
            {loading && <CircularProgress size={20} />}
            {values.equipmentId ? t("update") : t("save")}{" "}
            {t("equipmentRegistration.addPageTitle")}
          </Button>
        </Box>
      </Box>
    </Layout>
  );
}
