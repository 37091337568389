import React, { useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";
import "./Curing.css";
import EditButton from "utils/EditButton";

const CultureTable = (props) => {
  const [curingData, setCuringData] = useState([]);

  useEffect(() => {
    setCuringData(props.curingData);
  }, [props.curingData]);
  

  return (
    <div className="table-container">
      <table className="full-width-table">
        {" "}
        {/* Add a class for styling */}
        <thead>
          <tr className="HierarchyHead">
            <th>Code</th>
            <th>Curing Unit Name</th>
            <th>Tobacco Group </th>
            <th>Capacity (Kg)</th>
            <th>Is New?</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {curingData?.map((row, index) => (
            <tr className="hierarchyColumn" key={index} style={{}}>
              <td style={{ textAlign: "center" }}>
                <Typography
                  sx={{
                    justifyContent: "left",
                    fontWeight: 600,
                    color: "#3B454F",
                  }}
                >
                  {row.curingCode}
                </Typography>
              </td>
              <td style={{ textAlign: "center" }}>
                <Box
                  sx={{
                    whiteSpace: "nowrap",
                    paddingLeft: "5px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontWeight: 600,
                    color: "#3B454F",
                  }}
                >
                  <span title={row.curingUnitName}>
                    {/* { > 20
                        ? row.hlcLevelName.substring(0, 20) + "..."
                        : row.hlcLevelName} */}
                    {row.curingUnitName}
                  </span>
                </Box>
              </td>
              <td style={{ textAlign: "center" }}>
                <div
                  className="hierarchyConfig"
                  style={{
                    fontWeight: 600,
                    color: "#3B454F",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {row.tobaccoGroup}
                </div>
              </td>
              <td
                style={{
                  textAlign: "center",
                  fontWeight: 600,
                  color: "#3B454F",
                }}
              >
                {row.capacity}
              </td>
              <td
                style={{
                  textAlign: "center",
                  fontWeight: 600,
                  color: "#3B454F",
                }}
              >
                {row.newCuringUnit}
              </td>
              <td
                style={{
                  textAlign: "center",
                  fontWeight: 600,
                  color: "#3B454F",
                }}
              >
                {row.status ? "Active" : "Inactive"}
              </td>
              <td
                style={{
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <EditButton
                    url="/addCuring"
                    state={{
                      curingId: row.curingId,
                      capacity: row.capacity,
                      curingCode: row.curingCode,
                      curingUnitName: row.curingUnitName,
                      linearMeter: row.linearMeter,
                      newCuringUnit:row.newCuringUnit,
                      status: row.status,
                      tobaccoGroup:row.tobaccoGroup,
                      edit: true,
                    }}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CultureTable;
