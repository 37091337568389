import * as React from "react";
import Box from "@mui/material/Box";
import { Button, CircularProgress, Modal, Typography } from "@mui/material";
import arrowLeft from "assets/arrowLeft.svg";
import arrowRight from "assets/arrowRight.svg";
import * as Yup from "yup";
import { useState } from "react";
import { varietySchema } from "utils/validations";
import RenderField from "utils/RenderField";
import { useLocation, useNavigate } from "react-router-dom";
import { varietyFields } from "./VarietyFormConfig";
import Layout from "components/structure/Layout";
import { useEffect } from "react";
import CuringService from "services/CuringService";
import { useContext } from "react";
import { SnackbarContext } from "context/snackBarContext";
import { AuthData } from "auth/AuthWarpper";
import SpacingServices from "services/SpacingServices";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import VarietyService from "services/VarietyService";
import Lable from "utils/lables.json";
import Save from "assets/SaveNew.svg";
import { environmentField } from "../Farmer_Management/Farmer_Management/FarmerAdd/FormConfig";



export default function VarietyForm() {
  const location = useLocation();

  const navigate = useNavigate();
  const { user } = AuthData();

  const { vrVarietyId, edit } = location.state || {};

  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [values, setValues] = useState([location.state]);
  const [loading, setLoading] = useState(false)
  console.log("🚀 ~ VarietyForm ~ values:", values);
  const [errors, setErrors] = useState({});
  const [dateError, setDateError] = useState(false);
  const [varietyField, setVarietyFields] = useState(varietyFields); // State for varietyField
  console.log("🚀 ~ VarietyForm ~ varietyField:", varietyField);
  const [options, setOptions] = useState([]);
  const [varietyCultureTypes, setVarietyCultureTypes] = useState([]);
  console.log("🚀 ~ VarietyForm ~ varietyCultureTypes:", varietyCultureTypes);

  // const handleChange = (event) => {
  //   const { name, value, type, checked } = event.target;

  //   setValues((prevValues) => ({
  //     ...prevValues,
  //     [name]: type === "checkbox" ? checked : value,
  //     ...(name === "cuCultureName" && { cuCultureId: getCuCultureIdByName(value) }),
  //   }));

  //   if (name === "cuCultureName") {
  //     const selectedCulture = options.find(option => option.value === value);
  //     if (selectedCulture) {
  //       const newCultureTypes = selectedCulture.cuCultureTypes.map(type => ({
  //         label: type.cultureType,
  //         value: type.cultureTypeId,
  //       }));
  //       setVarietyCultureTypes(newCultureTypes);
  //     }
  //   }
  // };

  // const handleChange = (event) => {
  //   const { name, value, type, checked } = event.target;

  //   // Update the values state
  //   setValues((prevValues) => ({
  //     ...prevValues,
  //     [name]: type === "checkbox" ? checked : value,
  //   }));

  //   if (name === "cuCultureName") {
  //     const selectedCulture = options.find(option => option.value === value);

  //     if (selectedCulture) {
  //       const newCultureTypes = selectedCulture.cuCultureTypes.map(type => ({
  //         label: type.cultureType,
  //         value: type.cultureType, // Use id for value to uniquely identify
  //       }));

  //       setVarietyFields((prevFields) =>
  //         prevFields.map((field) =>
  //           field.name === "varietyCultureType"
  //             ? { ...field, options: newCultureTypes }
  //             : field
  //         )
  //       );

  //       setValues((prevValues) => ({
  //         ...prevValues,
  //         cuCultureId: selectedCulture.cuCultureId,
  //         varietyCultureType: "", // Clear previous selection
  //       }));
  //     } else {
  //       setVarietyFields((prevFields) =>
  //         prevFields.map((field) =>
  //           field.name === "varietyCultureType"
  //             ? { ...field, options: [] }
  //             : field
  //         )
  //       );

  //       setValues((prevValues) => ({
  //         ...prevValues,
  //         cuCultureId: "",
  //         varietyCultureType: "",
  //       }));
  //     }
  //   }

  //   if (name === "varietyCultureType") {
  //     console.log("vClicked")
  //     const selectedCultureType = options
  //       .find(option => option.value === values.cuCultureName)?.cuCultureTypes
  //       .find(type => type.cultureType === value);
  //     console.log("🚀 ~ handleChange ~ selectedCultureType:", selectedCultureType)

  //     if (selectedCultureType) {
  //       setValues((prevValues) => ({
  //         ...prevValues,
  //         varietyCultureType: selectedCultureType.cultureType,
  //         varietyCultureTypeOptions: [{
  //           ctCultureTypeId: selectedCultureType.cultureTypeId,
  //           ctCultureType: selectedCultureType.cultureType,
  //         }]
  //       }));
  //     }
  //   }
  // };


  const fetchTobaccoGroup = async () => {
    try {
      const response = await VarietyService.getCultureData("BRA");
      const cuCultureName = response.data.response;

      const newOptions = [
        { value: "", label: "None", cuCultureId: "", cuCultureTypes: [] },
        ...cuCultureName.map((group) => ({
          value: group.cuCultureName,
          label: group.cuCultureName,
          cuCultureId: group.cuCultureId,
          cuCultureTypes: group.cuCultureTypes,
        })),
      ];

      setOptions(newOptions);

      setVarietyFields((prevFields) =>
        prevFields.map((field) =>
          field.name === "cuCultureName" ? { ...field, options: newOptions } : field
        )
      );

      if (location?.state?.cuCultureName || location?.state?.varietyCultureType) {
        const selectedCulture = newOptions.find(
          (option) => option.value === location.state.cuCultureName
        );

        if (selectedCulture) {
          setValues((prevValues) => ({
            ...prevValues,
            cuCultureName: selectedCulture.value,
            cuCultureId: selectedCulture.cuCultureId,
            varietyCultureTypeOptions: selectedCulture.cuCultureTypes,
          }));
          setVarietyFields((prevFields) =>
            prevFields.map((field) =>
              field.name === "varietyCultureType"
                ? { ...field, options: selectedCulture.cuCultureTypes.map(type => ({ label: type.cultureType, value: type.cultureTypeId })) }
                : field
            )
          );
        }
      } else {
        setValues((prevValues) => ({
          ...prevValues,
          cuCultureName: newOptions[0].value,
          cuCultureId: newOptions[0].cuCultureId,
          varietyCultureTypeOptions: newOptions[0].cuCultureTypes,
        }));
      }
    } catch (error) {
      console.error("Failed to fetch tobacco group:", error);
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name === "cuCultureName") {
      const selectedCulture = options.find((option) => option.value === value);

      if (selectedCulture) {
        const newCultureTypes = selectedCulture.cuCultureTypes.map((type) => ({
          label: type.cultureType,
          value: type.cultureTypeId,
        }));

        setVarietyFields((prevFields) =>
          prevFields.map((field) =>
            field.name === "varietyCultureType"
              ? { ...field, options: newCultureTypes }
              : field
          )
        );

        setValues((prevValues) => ({
          ...prevValues,
          cuCultureId: selectedCulture.cuCultureId,
          varietyCultureType: "", // Clear previous selection
        }));
      } else {
        setVarietyFields((prevFields) =>
          prevFields.map((field) =>
            field.name === "varietyCultureType"
              ? { ...field, options: [] }
              : field
          )
        );

        setValues((prevValues) => ({
          ...prevValues,
          cuCultureId: "",
          varietyCultureType: "",
        }));
      }
    }
  };



  // Ensure to set the selected value when a varietyCultureType is selected
  const handleCultureTypeChange = (event) => {
    const { name, value } = event.target;
    console.log("🚀 ~ handleCultureTypeChange ~ name:", name)

    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };


  const getCuCultureIdByName = (cuCultureName) => {
    const option = options.find((option) => option.value === cuCultureName);
    return option ? option.cuCultureId : "";
  };

  // const fetchTobaccoGroup = async () => {
  //   try {
  //     const response = await VarietyService.getCultureData("BRA");
  //     const cuCultureName = response.data.response;
  //     console.log("🚀 ~ fetchTobaccoGroup ~ cuCultureName:", cuCultureName);

  //     // Construct options array including initial empty option
  //     const newOptions = [
  //       { value: "", label: "None", cuCultureId: "", cuCultureTypes: [] },
  //       ...cuCultureName.map((group) => ({
  //         value: group.cuCultureName,
  //         label: group.cuCultureName,
  //         cuCultureId: group.cuCultureId,
  //         cuCultureTypes: group.cuCultureTypes,
  //       })),
  //     ];
  //     console.log("🚀 ~ fetchTobaccoGroup ~ options:", newOptions);

  //     setOptions(newOptions); // Update the options state

  //     // Update varietyField with dynamic options
  //     const updateVarietyFields = varietyFields.map((field) => {
  //       if (field.name === "cuCultureName") {
  //         return {
  //           ...field,
  //           options: newOptions,
  //         };
  //       }
  //       return field;
  //     });
  //     console.log(
  //       "🚀 ~ updateVarietyFields ~ updateVarietyFields:",
  //       updateVarietyFields
  //     );

  //     setVarietyFields(updateVarietyFields); // Update varietyField with dynamic options

  //     // Check if the value from navigation state matches any option
  //     if (location?.state?.cuCultureName || location?.state?.varietyCultureType) {
  //       const matchedOption = newOptions.find(
  //         (option) => option.value === location.state.cuCultureName
  //       );
  //       if (matchedOption) {
  //         setValues((prevValues) => ({
  //           ...prevValues,
  //           cuCultureName: matchedOption.value,
  //           cuCultureId: matchedOption.cuCultureId,
  //           varietyCultureTypeOptions: matchedOption.cuCultureTypes,
  //         }));
  //       } else {
  //         setValues((prevValues) => ({
  //           ...prevValues,
  //           cuCultureName: newOptions[0].value,
  //           cuCultureId: newOptions[0].cuCultureId,
  //           varietyCultureTypeOptions: newOptions[0].cuCultureTypes,
  //         }));
  //       }
  //     } else {
  //       // Set initial value for cuCultureName if not already set
  //       setValues((prevValues) => ({
  //         ...prevValues,
  //         cuCultureName:
  //           prevValues.cuCultureName ||
  //           (newOptions.length > 0 ? newOptions[0].value : ""),
  //         cuCultureId:
  //           prevValues.cuCultureId ||
  //           (newOptions.length > 0 ? newOptions[0].cuCultureId : ""),
  //         varietyCultureTypeOptions:
  //           (newOptions.length > 0 && newOptions[0].cuCultureTypes) || [],
  //       }));
  //     }
  //   } catch (error) {
  //     console.error("Failed to fetch tobacco group:", error);
  //   }
  // };

  useEffect(() => {
    fetchTobaccoGroup();
  }, []);

  useEffect(() => {

    if (location.state) {
      const {
        vrVarietyId,
        cuCultureName,
        vrVariety,
        varietyCultureType,
        vrVarietyCultureTypeId,
        isActive,
      } = location.state;
      console.log("🚀 ~ useEffect ~ ctCultureType:")
      setValues((prevValues) => ({
        ...prevValues,
        vrVarietyId: vrVarietyId || "",
        cuCultureName: cuCultureName || "",
        vrVariety: vrVariety || "",
        varietyCultureType: varietyCultureType || "",
        vrVarietyCultureTypeId: vrVarietyCultureTypeId || "",
        isActive: isActive || "",
      }));
    }
  }, [values?.curingCode, location.state]);

  const handleDateChange = (date, fieldName) => {
    if (!date || date === "Invalid Date") {
      setDateError(true);
      setValues((prevValues) => ({
        ...prevValues,
        [fieldName]: "",
      }));
      return;
    }

    setDateError(false);

    const dateValue = new Date(date);
    if (isNaN(dateValue.getTime())) {
      setDateError(true);
      setValues((prevValues) => ({
        ...prevValues,
        [fieldName]: "",
      }));
      return;
    }

    const year = dateValue.getFullYear();
    const month = String(dateValue.getMonth() + 1).padStart(2, "0");
    const day = String(dateValue.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setValues((prevValues) => ({
      ...prevValues,
      [fieldName]: formattedDate,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true)
    try {
      await varietySchema.validate(values, { abortEarly: false });
      setErrors({});
      const payload = {
        vrVarietyId: vrVarietyId,
        cuCultureName: values.cuCultureName,
        vrVariety: values.vrVariety,
        cuCultureId: values.cuCultureId,
        varietyCultureType: [
          {
            ctCultureTypeId: values.varietyCultureType,
            ctCultureType: "VA",
            isActive: values.isActive || false,
            countryCode: "BRA",
            //            languageId: "d5b014e0-f863-4bf7-8d3a-781ad9c5016f",
            languageId: "0a31e178-fdf9-4c8c-bd7b-447a788eada5",
            // languageId: "960d7994-5b31-4e5b-8d05-c6d58d918229",
            vrVarietyCultureTypeId: values.vrVarietyCultureTypeId
          },
        ],
        isActive: values.isActive || false,
        //  languageId: "d5b014e0-f863-4bf7-8d3a-781ad9c5016f",
        languageId: "0a31e178-fdf9-4c8c-bd7b-447a788eada5",

        //        languageId: "960d7994-5b31-4e5b-8d05-c6d58d918229",
        createdByUser: user?.batOneId || 323222,
        modifiedByUser: user?.batOneId || 323222,
        countryCode: user?.countryCode || "BRA",
      };
      const response = await VarietyService.createVariety(payload);
      if (response.status === 201) {
        navigate("/variety");
        setSeverity("success");
        setMessage("Added successfully");
        setOpenAlert(true);
        setLoading(false);
      } else {
        console.error("Failed to submit form");
        setSeverity("error");
        setMessage("Submission failed");
        setOpenAlert(true);
        setLoading(false);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        let newError = {};
        err?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setErrors(newError);
        setLoading(false);

      } else {
        console.error("An error occurred while submitting the form:", err);
        setSeverity("error");
        setMessage("An error occurred while submitting the form");
        setOpenAlert(true);
        setLoading(false);

      }
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    setLoading(true)
    try {
      await varietySchema.validate(values, { abortEarly: false });
      setErrors({});
      const payload = {
        vrVarietyId: vrVarietyId,
        cuCultureName: values.cuCultureName,
        vrVariety: values.vrVariety,
        cuCultureId: values.cuCultureId,
        varietyCultureType: [
          {
            ctCultureTypeId: values.varietyCultureType,
            ctCultureType: "VA",
            isActive: values.isActive || false,
            countryCode: "BRA",
            languageId: "d5b014e0-f863-4bf7-8d3a-781ad9c5016f",
            vrVarietyCultureTypeId: values.vrVarietyCultureTypeId
          },
        ],
        isActive: values.isActive || false,
        languageId: "d5b014e0-f863-4bf7-8d3a-781ad9c5016f",
        createdByUser: user?.batOneId || 323222,
        modifiedByUser: user?.batOneId || 323222,
        countryCode: user?.countryCode || "BRA",
      };
      const response = await VarietyService.updateVariety(payload);
      if (response.status === 200) {
        navigate("/variety");
        setSeverity("success");
        setMessage("Updated successfully");
        setOpenAlert(true);
        setLoading(false);
      } else {
        console.error("Failed to submit form");
        setSeverity("error");
        setMessage("Updation failed");
        setOpenAlert(true);
        setLoading(false);

      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        let newError = {};
        err?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setErrors(newError);
        setLoading(false);

      } else {
        console.error("An error occurred while submitting the form:", err);
        setSeverity("error");
        setMessage("Submission failed");
        setOpenAlert(true);
        setLoading(false);
      }
    }
  };

  const handleClear = (field) => {
    setValues((prevValues) => ({
      ...prevValues,
      [field]: "",
    }));
  };

  const [open, setOpen] = useState(false);

  // Function to open the modal
  const handleOpenModal = () => {
    setOpen(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleDeleteConfirm = () => {
    setOpen(false);
    navigate("/variety");
  };
  return (
    <Layout title="Variety Registration">
      <Box
        sx={{
          minHeight: "125vh",
          flexGrow: 1,
          paddingBottom: "10rem",
          width: "100%",
          "& .MuiTextField-root": { m: 1 },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
          },
        }}
      >
        <form
          autoComplete="off"
          noValidate
          onSubmit={edit ? handleUpdate : handleSubmit}
        >
          <Box sx={{ display: "flex" }}>
            <ArrowDropDownIcon sx={{ marginRight: 1 }} />
            <Typography>{Lable.fromLable}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              //justifyContent:"space-between",
              bgcolor: "#EFEFEF",
              height: "120px",
              marginTop: "-25px",
            }}
          >
            {varietyField.slice(0, 7).map((field) => (
              <RenderField
                key={field.name}
                field={field}
                values={values}
                errors={errors}
                handleChange={handleChange}
                handleDateChange={handleDateChange}
                handleClear={handleClear}
              />
            ))}
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "2rem",
              marginTop: "100px",
            }}
          >
            <Button
              style={{
                backgroundColor: "#737D86",
                padding: " 6px, 16px, 6px, 16px",
                borderRadius: " 4px",
              }}
              startIcon={<img src={arrowLeft} alt="Arrow" />}
              variant="contained"
              type="secondary"
              onClick={() => {
                navigate("/varietyList");
              }}
            >
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: "#004F9F",
                padding: " 6px, 16px, 6px, 11px",
                borderRadius: " 4px",
                color: "white",
              }}
              endIcon={<img src={arrowRight} alt="Right Arrow" />}
              type="submit"
              variant="contained"
              disabled={loading}
            >

              {loading && <CircularProgress size={20} />}

              {edit
                ? "Update Variety Registration"
                : "Save Variety Registration"}
            </Button>
          </Box> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between", // Ensures buttons are placed on opposite corners
              position: "fixed", // Fix the buttons in place
              width: "100%", // Make the Box take full width
              bottom: 0, // Place at the bottom of the screen
              padding: "1rem", // Add some padding
            }}
          >
            {/* Cancel Button - Left bottom corner */}
            <Button
              style={{
                backgroundColor: "#737D86",
                padding: "6px 16px",
                borderRadius: "4px",
                position: "fixed",
                left: "1rem",
                bottom: "1rem",
                marginBottom: "50px",
              }}
              variant="contained"
              onClick={handleOpenModal} // Open the modal on click
              disabled={loading}
              startIcon={<img src={arrowLeft} alt="Arrow" />}
            >
              {loading && <CircularProgress size={20} />}
              Cancel
            </Button>

            {/* Modal confirmation */}
            <Modal
              open={open}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "7px",
                  width: "585px",
                  margin: "auto",
                  mt: 25,
                  height: "200px",
                }}
              >
                <Box
                  sx={{
                    background: "#102A62",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                  }}
                >
                  <Typography
                    sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
                  >
                    Cancel Operation
                  </Typography>
                </Box>
                <Box sx={{ p: 2 }}>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, fontWeight: 400 }}
                  >
                    Are you sure you want to cancel this operation? All your
                    data will be lost.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                    mb: 1,
                  }}
                >
                  <Button
                    onClick={handleCloseModal}
                    sx={{
                      background: "#737D86",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#737D86" },
                    }}
                  >
                    No
                  </Button>
                  <Button
                    onClick={handleDeleteConfirm}
                    sx={{
                      background: "#004F9F",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#004F9F" },
                    }}
                  >
                    Yes
                  </Button>
                </Box>
              </Box>
            </Modal>

            {(
              <Button
                sx={{
                  padding: "6px 16px",
                  borderRadius: "4px",
                  position: "fixed", // Fix the button
                  right: "1rem", // Aligns to the right bottom corner
                  bottom: "1.5rem", // Position it at the bottom
                  marginBottom: "40px",
                  backgroundColor: "#EF7D00",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#EF7D00",
                  },
                }}
                disabled={loading}
                startIcon={<img src={Save} alt="Right Arrow" />}
                // onClick={handleSubmit}
                variant="contained"
                type="submit"
              >
                {loading && <CircularProgress size={20} />}
                {"Save"}
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </Layout>
  );
}
