import * as React from "react";
import Box from "@mui/material/Box";
import { Button, Modal, TextField } from "@mui/material";
import arrowLeft from "assets/arrowLeft.svg";
import arrowRight from "assets/arrowRight.svg";
import "./FarmerAdd.css";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useState } from "react";
import { clearEnvironment, updateEnvironment } from "slices/environmentalSlice";
import { environmentSchema } from "utils/validations";
import RenderField from "utils/RenderField";
import { environmentField } from "./FormConfig";
import { clearGeneralInformation } from "slices/generalInformation";
import { clearTaxInformation } from "slices/taxInformationSlice";
import { clearAdults } from "slices/adultsSlice";
import { clearProperty } from "slices/propertyAddSlice";
import { clearDependant } from "slices/dependantSlice";
import { updateFarmerObj } from "slices/farmerObjSlice";
import { useNavigate, useParams } from "react-router-dom";
import FarmerRegisterationService from "services/FarmerRegistrationService";
import { useContext } from "react";
import { SnackbarContext } from "context/snackBarContext";
import { useEffect } from "react";
import { convertToDateString } from "utils/CommonFunction";
import CustomButton from "utils/CustomButton";

export default function EnvironmentalLicense(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const environmentInformation = useSelector(
    (state) => state?.environment?.addEnvironment
  );
  const adultInformation = useSelector((state) => state?.adult?.addAdult);
  const propertyInformation = useSelector(
    (state) => state?.property?.propertyInformation
  );
  const taxInformation = useSelector((state) => state.tax.taxInformation);
  const generalInformation = useSelector(
    (state) => state?.farmers?.generalInformation
  );
  const depedantInformation = useSelector(
    (state) => state?.dependant?.addDependant
  );
  console.log(
    "🚀 ~ EnvironmentalLicense ~ taxInformation:",
    taxInformation.bankName
  );
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const [values, setValues] = useState({});
  console.log("🚀 ~ EnvironmentalLicense ~ values:", values);
  const [errors, setErrors] = useState({});
  const [dateError, setDateError] = useState(false);

  const [loading, setLoading] = useState(false);


  const getFarmerByIds = async () => {
    try {
      const response = await FarmerRegisterationService.getFarmerById({ id });
      console.log("API Response:", response.data.response);

      let environmentalLicenseDetails =
        response.data?.response?.environmentalLicenseDetails || {};

      if (environmentalLicenseDetails.environmentalLicenseExpirationDt) {
        environmentalLicenseDetails.environmentalLicenseExpirationDt =
          convertToDateString(
            environmentalLicenseDetails.environmentalLicenseExpirationDt
          );
      }
      if (environmentalLicenseDetails.elGrantExpirationDt) {
        environmentalLicenseDetails.elGrantExpirationDt = convertToDateString(
          environmentalLicenseDetails.elGrantExpirationDt
        );
      }

      return setValues(environmentalLicenseDetails);
    } catch (error) {
      console.error("Error getting farmer by code:", error);
    }
  };

  useEffect(() => {
    getFarmerByIds();
  }, [id]);

  console.log(
    "🚀 ~ GeneralInformation ~ environmentInformation:",
    environmentInformation
  );

  console.log("🚀 ~ GeneralInformation ~feeffe:", generalInformation.code);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleDateChange = (date, fieldName) => {
    if (!date || date === "Invalid Date") {
      setDateError(true);
      setValues((prevValues) => ({
        ...prevValues,
        [fieldName]: "",
      }));
      return;
    }

    setDateError(false);

    const dateValue = new Date(date);
    if (isNaN(dateValue.getTime())) {
      setDateError(true);
      setValues((prevValues) => ({
        ...prevValues,
        [fieldName]: "",
      }));
      return;
    }

    const year = dateValue.getFullYear();
    const month = String(dateValue.getMonth() + 1).padStart(2, "0");
    const day = String(dateValue.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setValues((prevValues) => ({
      ...prevValues,
      [fieldName]: formattedDate,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await environmentSchema.validate(values, { abortEarly: false });
      setErrors({});

      const {
        bankName,
        branchNumber,
        accountType,
        accountNumber,
        stateRegistrationNumber,
        city,
        ...otherValues
      } = taxInformation;

      const cleanedPartnersOnProperty = adultInformation.map(
        ({ id, ...rest }) => rest
      );
      const cleanedDependentRegistrationDetails = depedantInformation.map(
        ({ id, attendSchool, ...rest }) => ({
          ...rest,
          attendSchool: attendSchool === "Yes" ? true : false,
        })
      );
      const cleanedFarmerPropertyDetails = propertyInformation.map(
        ({ id, ...rest }) => rest
      );

      const bankDetails = {
        bankName,
        branchNumber,
        accountType,
        accountNumber,
        stateRegistrationNumber,
        city,
      };

      const taxFields = otherValues.taxFields;

      const updatedValues = {
        environmentalLicenseDetails: { ...values },
        bankDetails,
        userUid: "8EE20C75-DDAF-4E4B-AC3A-29917FDBEA41",
        // countryId: "5d68bc83-3635-4a2b-a0df-1199b0b75c6e",
        // languageId: "960d7994-5b31-4e5b-8d05-c6d58d918229",
        countryId: "06fde653-a2c4-43b7-879f-4525039d9148",
        languageId: "0a31e178-fdf9-4c8c-bd7b-447a788eada5",
        cropYearUid: "0388F9D6-08C4-42A6-A8A2-54838E09EC91",
        ...generalInformation,
        partnersOnProperty: cleanedPartnersOnProperty,
        dependentRegistrationDetails: cleanedDependentRegistrationDetails,
        farmerPropertyDetails: cleanedFarmerPropertyDetails,
        taxDetails: taxFields,
      };

      console.log("🚀 ~ handleSubmit ~ updatedValues:", updatedValues);
      setLoading(true);

      const response = await FarmerRegisterationService.create(updatedValues);

      if (response.status === 201) {
        dispatch(clearGeneralInformation());
        dispatch(clearTaxInformation());
        dispatch(clearAdults());
        dispatch(clearProperty());
        dispatch(clearDependant());
        dispatch(clearEnvironment());
        navigate("/farmerRegistration");
        setSeverity("success");
        setMessage("Saved successfully");
        setOpenAlert(true);
        setLoading(false);
      } else {
        console.error("Failed to submit form");
        setSeverity("error");
        setMessage("Submission failed");
        setOpenAlert(true);
        setLoading(false);

      }

      // dispatch(updateEnvironment(updatedValues));
      // dispatch(updateFarmerObj(updatedValues))
      // dispatch(clearGeneralInformation())
      // dispatch(clearTaxInformation())
      // dispatch(clearAdults())
      // dispatch(clearProperty())
      // dispatch(clearDependant())
      // dispatch(clearEnvironment())
      // navigate("/farmerRegistration")
    } catch (err) {
      const validationErrors = {};
      err?.inner?.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
    }
    finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await environmentSchema.validate(values, { abortEarly: false });
      setErrors({});
      const {
        bankName,
        branchNumber,
        accountType,
        accountNumber,
        stateRegistrationNumber,
        city,
        bankId,
        ...otherValues
      } = taxInformation;

      const cleanedPartnersOnProperty = adultInformation.map(
        ({ id, ...rest }) => rest
      );
      const cleanedDependentRegistrationDetails = depedantInformation.map(
        ({ id, attendSchool, ...rest }) => ({
          ...rest,
          attendSchool: attendSchool === "Yes" ? true : false,
        })
      );

      const cleanedFarmerPropertyDetails = propertyInformation.map(
        ({ id, ...rest }) => rest
      );

      const bankDetails = {
        bankName,
        branchNumber,
        accountType,
        accountNumber,
        stateRegistrationNumber,
        city,
        bankId,
      };

      const taxFields = otherValues.taxFields;

      const updatedValues = {
        environmentalLicenseDetails: { ...values },
        modifiedDateTimeUTC: new Date(),
        bankDetails,
        userUid: "8EE20C75-DDAF-4E4B-AC3A-29917FDBEA41",
        countryId: "5d68bc83-3635-4a2b-a0df-1199b0b75c6e",
        languageId: "960d7994-5b31-4e5b-8d05-c6d58d918229",
        cropYearUid: "0388F9D6-08C4-42A6-A8A2-54838E09EC91",
        ...generalInformation,
        partnersOnProperty: cleanedPartnersOnProperty,
        dependentRegistrationDetails: cleanedDependentRegistrationDetails,
        farmerPropertyDetails: cleanedFarmerPropertyDetails,
        taxDetails: taxFields,
      };

      console.log("🚀 ~ handleSubmit ~ updatedValues:", updatedValues);
      setLoading(true);

      const response = await FarmerRegisterationService.update(updatedValues);

      if (response.status === 200) {
        dispatch(clearGeneralInformation());
        dispatch(clearTaxInformation());
        dispatch(clearAdults());
        dispatch(clearProperty());
        dispatch(clearDependant());
        dispatch(clearEnvironment());
        navigate("/farmerRegistration");
        setSeverity("success");
        setMessage("Updated successfully");
        setOpenAlert(true);
        setLoading(false);
      } else {
        console.error("Failed to submit form");
        setSeverity("error");
        setMessage("Submission failed");
        setOpenAlert(true);
        setLoading(false);

      }

      // dispatch(updateEnvironment(updatedValues));
      // dispatch(updateFarmerObj(updatedValues))
      // dispatch(clearGeneralInformation())
      // dispatch(clearTaxInformation())
      // dispatch(clearAdults())
      // dispatch(clearProperty())
      // dispatch(clearDependant())
      // dispatch(clearEnvironment())
      // navigate("/farmerRegistration")
    } catch (err) {
      const validationErrors = {};
      err?.inner?.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
    }
    finally {
      setLoading(false); // Set loading to false when submission is complete
    }
  };

  const handleClear = (field) => {
    setValues((prevValues) => ({
      ...prevValues,
      [field]: "",
    }));
  };


  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const handleYes = () => {
    dispatch(clearGeneralInformation());
    dispatch(clearTaxInformation());
    dispatch(clearAdults());
    dispatch(clearProperty());
    dispatch(clearDependant());
    dispatch(clearEnvironment());
    navigate("/farmerRegistration")
  }

  const handleNo = () => setDeleteOpen(false);

  const styleBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30.5rem",
    bgcolor: "background.paper",
    height: "20.125rem",
    borderRadius: "0.625rem",
  };

  return (
    <Box
      sx={{
        minHeight: 180,
        flexGrow: 1,
        width: "100%",
        paddingBottom: "10rem",
        "& .MuiTextField-root": {
          m: 1,
          color: "green !important",
          width: "25ch",
        },
        "& .MuiFormLabel-root": {
          color: "rgba(16, 42, 98, 1)",
          "& .MuiFormLabel-asterisk": {
            color: "rgba(239, 0, 0, 1)",
            fontSize: "18px",
          },
          "& .Mui-focused": {
            color: "red !important",
          },
        },
      }}
    >
      <form
        autoComplete="off"
        noValidate
        onSubmit={!id ? handleSubmit : handleUpdate}
      >
        {environmentField.slice(0, 5).map((field) => (
          <RenderField
            key={field.name}
            field={field}
            values={values}
            errors={errors}
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            handleClear={handleClear}
          />
        ))}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "2rem",
            marginTop: "100px",
          }}
        >
          <Button
            style={{
              backgroundColor: "#737D86",
              padding: " 6px, 16px, 6px, 16px",
              borderRadius: " 4px",
            }}
            startIcon={<img src={arrowLeft} />}
            variant="contained"
            // type="secondary"
            onClick={() => setDeleteOpen(true)}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            sx={{
              backgroundColor: "#004F9F",
              padding: " 6px, 16px, 6px, 11px",
              borderRadius: " 4px",
              color: "white",
            }}
            endIcon={<img src={arrowRight} alt="Right Arrow" />}
            type="submit"
            variant="contained"
            disabled={
              (generalInformation.cpf === "" || taxInformation.bankName === "") && loading

            }
          >
            {!id ? "Save" : "Edit"} Farmer Registeration
          </Button>
        </Box>
      </form>
      <Modal
        open={deleteOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleBox}>
          <Box
            sx={{
              mt: 4,
              justifyContent: "center",
              display: "flex",
              gap: "1rem",
              flexDirection: "column",
              alignItems: "center",
            }}
          >

            <div>Are you sure!</div>
            <div>You want to Go Back</div>
            <div>Doing so will erase all the prefilled or filled data</div>

          </Box>
          <Box
            sx={{
              mt: 4,
              justifyContent: "center",
              display: "flex",
              gap: "1rem",
            }}
          >
            <CustomButton variant="cancel" label="No" onClick={handleNo} />

            <CustomButton
              color="#EF7D00"
              label="Yes"
              onClick={handleYes}
            />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
