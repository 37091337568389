import { http } from "../http-common";

const getCropSeasons = (query) => {
  return http.get(`/grCropSeason?pageNumber=${query.pageNumber}&pageSize=${query.size}`);
};


const getCropPhase = ({ id }) => {
  return http.get(`/cropPhase/${id}`);
};


//const getCropSeasonsBasedonId = (query) => {
//   return http.get(`/grCropSeason?cropSeasonId=${query.pageNumber}&pageSize=${query.size}`);
// };
const getCropSeasonsBasedonId = (query) => {

  const response = http.get(`/grCropSeason/${query.seasonId}`)

  return response
}

// const getCropSeasonsSearch = (query) => {
//   return http.get(`/grCropSeason?pageNumber=${query.pageNumber}&pageSize=${query.size}&cropYear=${query.cropYear}&status=${query.status}&cultureName=${query.cultureName}`);
// };


const getCropSeasonsSearch = ({ searchParams, pageNumber, size }) => {
  const params = {
    ...searchParams,
    pageNumber: pageNumber,
    pageSize: size,
  };
  console.log("Request URL:", http.getUri({ url: "/grCropSeason/search?", params }));
  return http.get("/grCropSeason/search?", { params });
};

const getCropSeasonsSearchWithoutPageLimit = (data) => {
  // const params = {
  //   cropYear: cropyear,
  // };
  //  console.log("Request URL:", http.getUri({ url: "/grCropSeason/search?", data }));

  return http.get("/grCropSeason/search?", data);
};

const createCropSeasonRegistration = (data) => {
  return http.post("/grCropSeason", data);
};

const updateCropSeasonRegistration = (data) => {
  return http.put(`/grCropSeason`, data);
};



const getCropYears = (data) => {
  return http.get(`/cropYears`, data);
};

const getLatestTree = () => {
  return http.get(`/importHierarchyVersion/tree`);
};

const makeDublicate = (query) => {
  return http.post(`/grCropSeason/duplicate?targetCropYear=${query.cropYear}&userName=${query.userName}`)
}




const CropSeasonService = {
  getCropSeasons,
  getCropSeasonsBasedonId,
  createCropSeasonRegistration,
  updateCropSeasonRegistration,
  getCropYears,
  getCropSeasonsSearch,
  getCropSeasonsSearchWithoutPageLimit,
  getLatestTree,
  getCropPhase,
  makeDublicate
};

export default CropSeasonService;
