import React, { useState, useEffect, useCallback, useContext } from "react";
import DataTable from "../DataTable/DataTable";
import { useSelector, useDispatch } from "react-redux";
import { GridActionsCellItem } from "@mui/x-data-grid";
import iconDelete from "assets/iconDelete.svg";
import {
  clearProperty,
  deleteProperty,
  updateProperty,
} from "slices/propertyAddSlice";
import PropertyRegisterationModal from "./PropertyRegisterationModal";
import { Box, Button, Modal } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FarmerRegisterationService from "services/FarmerRegistrationService";
import isEqual from "lodash.isequal";
import iconEdit from "assets/iconEdit.svg";
import { SnackbarContext } from "context/snackBarContext";
import arrowLeft from "assets/arrowLeft.svg";
import { clearGeneralInformation } from "slices/generalInformation";
import { clearTaxInformation } from "slices/taxInformationSlice";
import { clearAdults } from "slices/adultsSlice";
import { clearDependant } from "slices/dependantSlice";
import { clearEnvironment } from "slices/environmentalSlice";
import CustomButton from "utils/CustomButton";
import { useTranslation } from 'react-i18next';

const Properties = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const propertyInformation = useSelector(
    (state) => state?.property?.propertyInformation
  );

  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const [values, setValues] = useState(propertyInformation || []);

  const [modalValue, setModalValues] = useState([]);
  const [editData, setEditData] = useState(null);

  const handleDeleteProperty = (id) => {
    dispatch(deleteProperty(id));
  };

  const getFarmerByIds = useCallback(async () => {
    try {
      const response = await FarmerRegisterationService.getFarmerById({ id });
      const fetchedValues =
        response.data?.response?.farmerPropertyDetails || propertyInformation;

      // Only dispatch if the fetched values are different from the current state
      if (!isEqual(fetchedValues, values)) {
        setValues(fetchedValues);
        dispatch(updateProperty(fetchedValues));
        console.log("propertiesValues", fetchedValues, values);
      }
    } catch (error) {
      console.error("Error getting farmer by id:", error);
    }
  }, [id, values, dispatch]);

  useEffect(() => {
    if (id) {
      getFarmerByIds();
    }
  }, [id, getFarmerByIds]);

  const columns = [
    {
      field: "tenureType",
      headerName: "Tenure Type",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "data-table-header",
    },
    {
      field: "propertyType",
      headerName: "Property Type",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "data-table-header",
    },
    {
      field: "region",
      headerName: "Region",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "city",
      headerName: "City",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "haProperty",
      headerName: "HA Property",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "haExploited",
      headerName: "HA Exploited",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "estimatedValue",
      headerName: "Estimated Value",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "agriculturalLandTax",
      headerName: "Agricultural Land Tax",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "value",
      headerName: "Value",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "actions",
      type: "actions",
      flex: 1,
      headerAlign: "center",
      align: "center",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<img src={iconEdit} height="30px" width="30px" alt="Edit" />}
          className="gridActionCell gridActionCellEdit"
          label="Edit"
          onClick={() => {
            setEditData(params.row);
            props.setRegisterationModalStatus(true);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          icon={
            <img
              src={iconDelete}
              height="30px"
              width="30px"
              alt="Delete Icon"
            />
          }
          label="Delete"
          className="gridActionCell gridActionCellDelete"
          onClick={() => handleDeleteProperty(params.row.id)}
          showInMenu
        />,
      ],
    },
  ];

  const handleSaveDetails = () => {
    if (propertyInformation.length > 0) {
      setSeverity("success");
      setMessage(t("snackbarMessage.savedSuccessfully"));
      setOpenAlert(true);
    }
  };

  const handleAddProperty = () => {
    setEditData(null);
    props.setRegisterationModalStatus(true);
  };

  const location = useLocation();
  const { edit, details } = location.state || {};
  const navigate = useNavigate();
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const handleYes = () => {
    dispatch(clearGeneralInformation());
    dispatch(clearTaxInformation());
    dispatch(clearAdults());
    dispatch(clearProperty());
    dispatch(clearDependant());
    dispatch(clearEnvironment());
    navigate("/farmerRegistration");
  };

  const handleNo = () => setDeleteOpen(false);

  const styleBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30.5rem",
    bgcolor: "background.paper",
    height: "20.125rem",
    borderRadius: "0.625rem",
  };

  return (
    <div style={{ paddingBottom: "100px" }}>
      {propertyInformation.length > 0 ? (
        <DataTable
          getRowId={(row) => row.farmerPropertyLinkId || row.id}
          rows={propertyInformation}
          columns={columns}
        />
      ) : (
        ""
      )}
      <div
        className={
          propertyInformation?.length > 0
            ? "farmer-add-white-property"
            : "farmer-add-property"
        }
        onClick={handleAddProperty}
      >
        +Add Property
      </div>
      <PropertyRegisterationModal
        setModalValues={setModalValues}
        editData={editData}
        addNewProperty={(formdata) => {
          props.handleAddProperty(formdata);
        }}
        open={props.registerationModalStatus}
        onClose={() => {
          props.setRegisterationModalStatus(false);
        }}
      />
      {/* <Button
      onClick={handleSaveDetails}
        style={{
          float: "right",
          marginBottom: "100px",
          background: "#EF7D00",
          marginTop: "20px",
        }}
        type="submit"
        variant="contained"
      >
        Save Changes
      </Button> */}
      {/* {edit && */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <Button
          style={{
            marginBottom: "10px",
            marginRight: "10px",
            background: "#737D86",
          }}
          startIcon={<img src={arrowLeft} alt="Right Arrow" />}
          variant="contained"
          // onClick={() => navigate("/farmerRegistration")}
          onClick={() => setDeleteOpen(true)}
        >
          Cancel
        </Button>
        {/* <Button
            style={{
              marginBottom: "10px",
              background: "#EF7D00",
            }}
            type="submit"
            variant="contained"
          >
            Save Changes
          </Button> */}
      </div>
      {/* } */}
      {/* {!edit && <Button
        style={{
          float: "left",
          marginBottom: "10px",
          background: "#737D86",
        }}
        startIcon={<img src={arrowLeft} alt="Right Arrow" />}
        variant="contained"
        onClick={() => setDeleteOpen(true)}
      >
        Cancel
      </Button>} */}
      <Modal
        open={deleteOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleBox}>
          <Box
            sx={{
              mt: 4,
              justifyContent: "center",
              display: "flex",
              gap: "1rem",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>Are you sure!</div>
            <div>You want to Go Back</div>
            <div>Doing so will erase all the prefilled or filled data</div>
          </Box>
          <Box
            sx={{
              mt: 4,
              justifyContent: "center",
              display: "flex",
              gap: "1rem",
            }}
          >
            <CustomButton variant="cancel" label="No" onClick={handleNo} />

            <CustomButton color="#EF7D00" label="Yes" onClick={handleYes} />
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default Properties;
