// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SurityModal2Container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 20%; */
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 24;
    /* padding: 32px */
}

;

@media screen and (max-width:800px) {
    .SurityModalContainer {
        width: 40% !important;
        padding: 16px !important
    }
}`, "",{"version":3,"sources":["webpack://./src/utils/SurityModal2/SurityModal2.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,gBAAgB;IAChB,sBAAsB;IACtB,mBAAmB;IACnB,cAAc;IACd,kBAAkB;AACtB;;AAAA;;AAIA;IACI;QACI,qBAAqB;QACrB;IACJ;AACJ","sourcesContent":[".SurityModal2Container {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    /* width: 20%; */\n    background-color: #fff;\n    border-radius: 12px;\n    box-shadow: 24;\n    /* padding: 32px */\n}\n\n;\n\n@media screen and (max-width:800px) {\n    .SurityModalContainer {\n        width: 40% !important;\n        padding: 16px !important\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
