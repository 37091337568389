import React, { useEffect, useState, useCallback, useRef, useContext } from "react";
import Layout from "../../structure/Layout";
import styled from "@emotion/styled";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button, FormControl, TextField, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DataTable from "utils/DataTable/DataTable";
import "./Profiles.css";
import NoData from "assets/noData.svg";
import ProfileService from "services/ProfileService";
import EditButton from "utils/EditButton";
import { SnackbarContext } from "context/snackBarContext";
import debounce from "lodash.debounce";
import { useTranslation } from "react-i18next";

;
let searchQuerys = '';

const Profiles = () => {
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState();
  const [rowsData, setRowsData] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const { t, i18n } = useTranslation();

  const columns = [
    {
      field: "profileCode",
      headerName: t("manageProfiles.profileCode"),
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "profileName",
      headerName:  t("manageProfiles.profileName"),
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "attributes",
      headerName: t("manageProfiles.attributes"),
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "active",
      headerName: t("status"),
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "actions",
      type: t("actions"),
      headerName: "Actions",
      width: 250,
      headerAlign: "center",
      align: "center",
      getActions: (params) => {
        const state = {
          previousData: params.row,
          edit: true,
          id: params.id,
          attribute: params.attributes,
        };
        const url = `/editProfiles`;
        return [<EditButton state={state} url={url} />];
      },
    },
  ]

  const handleData = useCallback(async () => {
    try {
      let response;
      if (searchQuerys !== "") {
        response = await ProfileService.getProfilesSearchQuery(searchQuerys, paginationModel);
      } else {
        response = await ProfileService.getProfileList(paginationModel);
      }

      const responseData = response.data;
      const { totalItems, profiles } = responseData.response;
      setTotalRowCount(totalItems);
      setData(responseData);
      const rows = profiles.map((profile) => ({
        id: profile.profileId,
        profileCode: profile.profileCode,
        profileName: profile.profileName,
        attributes: profile.profileAttribute,
        active: profile.isActive ? "Active" : "Inactive",
        functionality: profile.functionality,
      }));
      setRowsData(rows);
    } catch (error) {
      setRowsData([]);
      setMessage(error?.response?.data?.message || error?.message);
      setSeverity("error");
      setOpenAlert(true);
      console.log(error);
    }
  }, [paginationModel]);

  const handleSearch = useCallback(
    async (query) => {
      try {
        const response = await ProfileService.getProfilesSearchQuery(query, paginationModel);
        const searchData = response.data;
        const { totalItems } = searchData.response;
        setTotalRowCount(totalItems);
        const rows = searchData.response.profiles.map((profile) => ({
          id: profile.profileId,
          profileCode: profile.profileCode,
          profileName: profile.profileName,
          attributes: profile.profileAttribute,
          active: profile.isActive ? "Active" : "Inactive",
          functionality: profile.functionality,
        }));
        setRowsData(rows);
      } catch (error) {
        setRowsData([]);
        setMessage(error?.response?.data?.message || error?.message);
        setSeverity("error");
        setOpenAlert(true);
        console.log(error);
      }
    },
    [paginationModel]
  );

  const debouncedSearch = useRef(
    debounce((query) => {
      if (query.trim().length >= 1) {
        handleSearch(query.trim());
      } else if (query.trim() === "") {
        handleData();
      }
    }, 600)
  ).current;

  const handleSearchChange = useCallback(
    (event) => {
      const query = event.target.value;
      setSearchQuery(query);
      searchQuerys = query;
      debouncedSearch(query);
    },
    [debouncedSearch]
  );

  useEffect(() => {
    handleData();
  }, [handleData, paginationModel.page]);

  return (
    <Layout title={t("manageProfiles.pageTitle")}>
      <ManageProfileHeading>
        <div style={{ color: "#737D86", fontSize: "1.5rem", fontWeight: "600" }}>
        {t("manageProfiles.headingGet")}
        </div>
      </ManageProfileHeading>
      <ManageProfileOptions>
        <div>
          <FormControl
            sx={{
              flex: 1,
              minHeight: 50,
            }}
          >
            <TextField
              placeholder={t("search")}
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </FormControl>
        </div>
        <div>
          <Button
            sx={{
              borderRadius: 100,
              minWidth: 95,
              minHeight: 44,
              background: "#102A62",
            }}
            variant="contained"
            startIcon={<AddCircleIcon />}
            onClick={() => navigate("/addProfiles")}
          >
            {t("add")}
          </Button>
        </div>
      </ManageProfileOptions>
      {data ? (
        <DataContainer>
          <DataTable
            totalRowCount={totalRowCount}
            data={data}
            rows={rowsData}
            columns={columns}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
          />
        </DataContainer>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={NoData} style={{ height: "100px" }} alt="No Data" />
          <p>  {t("noDataMessage")}</p>
        </Box>
      )}
    </Layout>
  );
};

const ManageProfileHeading = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ManageProfileOptions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const DataContainer = styled.div`
  margin-top: 2rem;
`;

export default Profiles;
