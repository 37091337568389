// GetPlantingRegistration.jsx
import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import PlantingTable from "./PlantingTable";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PlantingService from "services/PlantingService";
import debounce from "lodash/debounce";
import Lable from "utils/lables.json";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import label from "../growingLabel.json";

const GetPlantingRegistration = () => {
  const navigate = useNavigate();
  const [plantingData, setPlantingData] = useState([]);
  const [filplantingData, setfilPlantingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  // const [itemsPerPage, setItemsPerPage] = useState(5);
  const [itemsPerPage, setItemsPerPage] = useState(() => {
    return parseInt(localStorage.getItem("IPPPT")) || 5;
  });

  const [totalItems, setTotalItems] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [menu, setMenu] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [hasSearched, setHasSearched] = useState(false); // New state for tracking search

  console.log("statusVALUE", statusFilter);
  console.log("ITEMSPerPage", itemsPerPage);

  const debouncedSearch = useCallback(
    debounce((value) => {
      callSearchMenuPlantingAPI(0, itemsPerPage, value);
    }, 300),
    [itemsPerPage]
  );

  const handleSearchChange = (value) => {
    setSearchKeyword(value);
    setHasSearched(true);
    if (value) {
      // debouncedSearch(value);
      console.log("ISEMPTY1")
      callSearchPlantingAPI(0, itemsPerPage, value);
    }
    else if (statusFilter) {
      console.log("ISEMPTY2", value)
      const isActive =
        statusFilter === "Active" ? true : statusFilter === "Inactive" ? false : null;
      callSearchPlantingAPI(0, itemsPerPage, value, isActive)
    }
    else {
      console.log("ISEMPTY3")
      setSearchKeyword("");
      resetFilters();
    }
  };

  const handleStatusChange = (status) => {
    setStatusFilter(status);
    setCurrentPage(0);
    setHasSearched(true);
    if (status === "") {
      setStatusFilter("");
      resetFilters();
    } else {
      const isActive =
        status === "Active" ? true : status === "Inactive" ? false : null;
      callSearchPlantingAPI(0, itemsPerPage, searchKeyword, isActive);
    }
  };

  const resetFilters = () => {
    // setSearchKeyword("");
    // setStatusFilter("");
    setCurrentPage(0);
    setHasSearched(false);
    callPlantingAPI(0, itemsPerPage);
  };

  const callPlantingAPI = (pageNumber, size) => {
    setLoading(true);
    PlantingService.getPlanting({ pageNumber, size })
      .then((res) => {
        const plantingResults = res?.data?.response?.plantingTypes || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setPlantingData(plantingResults);
        setfilPlantingData(plantingResults);
        setTotalItems(res?.data?.response?.totalItems || 0);
        if (plantingResults.length === 0 && searchKeyword.length > 0) {
          setMessage(label.noDataMessage);
          // setOpenAlert(true);
          setSeverity("info");
        }
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        // setOpenAlert(true);
        setSeverity("error");
        setPlantingData([]);
        setfilPlantingData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getfullPlantingMenuAPI = () => {
    setLoading(true);
    PlantingService.getPlanting({ pageNumber: currentPage, size: 50 })
      .then((res) => {
        const plantingResults = res?.data?.response?.plantingTypes || [];
        const plantsmenu = plantingResults.map((e) => e.plantTypeDescription);
        setMenu(plantsmenu);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        // setOpenAlert(true);
        setSeverity("error");
        setPlantingData([]);
        setfilPlantingData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const callSearchPlantingAPI = (pageNumber, size, keyword, status) => {
    setLoading(true);
    console.log("statusOnSearch", status)
    let query = { pageNumber, size, keyword };
    if (status === true || status === false) {
      query.status = status;
    }
    PlantingService.searchPlanting(query)
      .then((res) => {
        const plantingResults = res?.data?.response?.plantingTypes || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setPlantingData(plantingResults);
        setfilPlantingData(plantingResults);
        setTotalItems(res?.data?.response?.totalItems || 0);
        if (plantingResults.length === 0 && searchKeyword.length > 0) {
          setMessage(label.noDataMessage);
          // setOpenAlert(true);
          setSeverity("info");
        }
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        // setOpenAlert(true);
        setSeverity("error");
        setPlantingData([]);
        setfilPlantingData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const callSearchMenuPlantingAPI = (pageNumber, size, keyword) => {
    setLoading(true);
    PlantingService.searchPlanting({ pageNumber, totalItems, keyword })
      .then((res) => {
        const plantingResults = res?.data?.response?.plantingTypes || [];
        const plantsmenu = plantingResults.map((e) => e.plantTypeDescription);
        setMenu(plantsmenu);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setPlantingData([]);
        setfilPlantingData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {

    if (searchKeyword == "" && statusFilter == "") {
      callPlantingAPI(currentPage, itemsPerPage);
    }
    else {
      const isActive =
        statusFilter === "Active" ? true : statusFilter === "Inactive" ? false : null;
      callSearchPlantingAPI(currentPage, itemsPerPage, searchKeyword, isActive)
    }
    if (currentPage <= 0) {
      getfullPlantingMenuAPI();
    }
  }, [currentPage, itemsPerPage]);

  const paginate = (pageNumber, itemsPerPage) => {
    localStorage.setItem("IPPPT", itemsPerPage);
    setCurrentPage(pageNumber - 1);
    if (searchKeyword || statusFilter) {
      const isActive =
        statusFilter === "Active" ? true : statusFilter === "Inactive" ? false : null;
      callSearchPlantingAPI(pageNumber - 1, itemsPerPage, searchKeyword, isActive);
    } else {
      callPlantingAPI(pageNumber - 1, itemsPerPage);
    }
  };

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={"Planting Type List"}
      background="#ffffff"
      useNewPagination={true}
    >
      <Grid
        container
        spacing={2}
        rowSpacing={0.1}
        sx={{ background: "#EFEFEF", borderRadius: "5px", padding: "1rem" }}
      >
        <Grid item xs={12}>
          <Typography display="flex">
            <ArrowDropDownIcon sx={{ marginRight: 1, marginBottom: 1.1 }} />
            {Lable.filtersLable}
          </Typography>
        </Grid>

        <Grid item xs={3} display="flex">
          <FormControl sx={{ mr: 2, flexGrow: 1, minHeight: 44 }}>
            <Autocomplete
              disablePortal
              value={searchKeyword}
              onChange={(event, newValue) => {
                handleSearchChange(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                // handleSearchChange(newInputValue);
                setSearchKeyword(newInputValue)
              }}
              options={menu}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="plantingType"
                  label="Planting Type"
                  fullWidth
                  placeholder="Select an option"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: searchKeyword ? params.InputProps.endAdornment : null,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    "& .MuiInputBase-input::placeholder": {
                      opacity: 1,
                      // fontStyle: 'italic',
                    },
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={3} display="flex" sx={{ mb: 1, minHeight: 44 }}>
          <FormControl sx={{ minWidth: "15rem" }}>
            <TextField
              select
              name="status"
              label="Status"
              InputLabelProps={{
                shrink: true,
              }}
              value={statusFilter || "pl"}
              onChange={(event) => handleStatusChange(event.target.value)}
            >
              <MenuItem value="pl">Select an option</MenuItem>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Inactive">Inactive</MenuItem>
            </TextField>
          </FormControl>
        </Grid>

        {/* Empty Grid item to fill the blank columns in grid */}
        <Grid item xs={4}></Grid>

        <Grid item xs={2} display="flex" justifyContent="flex-end">
          <Button
            sx={{
              borderRadius: 1,
              minWidth: 95,
              minHeight: 44,
              background: "#004F9F",
              mb: 1,
              mr: 2,
            }}
            variant="contained"
            startIcon={<AddCircleIcon />}
            onClick={() => navigate("/addplantingRegistration")}
          >
            ADD NEW
          </Button>
        </Grid>
      </Grid>

      <PlantingTable plantingData={filplantingData} />
    </Layout>
  );
};

export default GetPlantingRegistration;
