import FieldNotebookSupplyService from "services/FieldNotebookSupplyService";

export const fetchFieldNotebookSupplyTypes = async () => {
    try {
        const response = await FieldNotebookSupplyService.getFieldNotebookSupplyType();
        const fieldNotebookSupplyTypes = response?.data?.response?.map(
            (supplyType) => ({
                supplyTypeId: supplyType.supplyTypeId,
                supplyTypeDescription: supplyType.supplyTypeDescription,
                useNPK: supplyType.useNPK,
            })
        );
        return fieldNotebookSupplyTypes;
    } catch (error) {
        console.log("Error while fetching supply type: ", error);
        throw error;
    }
}

export const fetchFieldNotebookSupply = async (fieldNotebookSupplyId) => {
    try {
        const response = await FieldNotebookSupplyService.getFieldNotebookSupply(fieldNotebookSupplyId);
        const responseData = response?.data?.response;

        if (responseData) {
            return {
                supplyId: responseData.fieldNotebookSupplyId,
                externalCode: responseData.externalCode,
                supplyDescription: responseData.supplyDescription,
                uom: responseData.unitOfMeasure,
                supplyType: responseData.fieldNotebookSupplyTypeId,
                status: responseData.status,
                nitrogenPerc: responseData.nitrogenPerc,
                phosphorusPerc: responseData.phosphorunPerc,
                potassiumPerc: responseData.potassiumPerc,
                showNPK: false,
            }
        } else {
            throw Error("Field notebook supply not found!");
        }
    } catch (error) {
        console.log("Error while fetching supply details: ", error);
        throw error;
    }
}