import React, { useState, useEffect, useContext } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  TextField,
  FormLabel,
  CircularProgress,
} from "@mui/material";
import { Icon } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import arrowDown from "assets/arrowDown.svg";
import { FormControlLabel } from "@mui/material";
import { StyledTreeItem } from "utils/Styledtreeitem";
import "components/pages/Growing/Farmer_Management/Farmer_Management/FarmerAdd/FarmerAdd.css";
import arrowLeft from "assets/arrowLeft.svg";
import arrowRight from "assets/arrowRight.svg";
import RenderField from "utils/RenderField";
import { cultureSchema } from "utils/validations";
import deletebutton from "assets/deletebutton.svg";
import { SnackbarContext } from "context/snackBarContext";
import { cultureFormConfig } from "./CultureForm";
import Layout from "components/structure/Layout";
import RedSwitch from "utils/RedSwitch";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import leftIcon from "assets/iconLeft.svg";
import CultureService from "services/CultureService";
import CultureGroupService from "services/CultureGroup";

export default function AddCultureRegistration(props) {
  const location = useLocation();
  const { details } = location.state || {};
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  console.log("🚀 ~ GetCultureRegistration ~ id:", id);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [values, setValues] = useState({ cuStatus: false, cultureTypes: [] });
  console.log({ values });
  const [errors, setErrors] = useState({ cultureTypes: [] });
  console.log("errosss", errors);
  const [CultureGroupItems, setCultureGroupItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleCultureFieldChange = (index, event) => {
    const { name, value } = event.target;
    setValues((prevValues) => {
      const updatedCultureFields = [...prevValues.cultureTypes];
      updatedCultureFields[index] = {
        ...updatedCultureFields[index],
        [name]: value,
      };
      return {
        ...prevValues,
        cultureTypes: updatedCultureFields,
      };
    });
  };

  const getCultureUniqueCode = async () => {
    try {
      const response = await CultureService.getCultureUniqueCode();
      return response.data.response;
    } catch (error) {
      console.error("Error generating unique code:", error);
      return null;
    }
  };

  const getCultureUniqueId = async () => {
    try {
      const response = await CultureService.getCultureById({ id });
      if (id) {
        cultureFormConfig[1]["disabled"] = true;
      }
      return setValues(response.data.response.culture);
    } catch (error) {
      console.error("Error generating unique code:", error);
      return null;
    }
  };

  const getMenuCultureGroupApi = () => {
    console.log("called");
    setLoading(true);
    CultureGroupService.getMenuCultureGroup()
      .then((res) => {
        console.log(res.data.response);

        setCultureGroupItems(
          res.data.response.filter((item) => item.status === true)
        );
        setLoading(false);
      })
      .catch((error) => {
        // console.log("API NHI CHL RHI", error);
        // setLoading(false);
        // setMessage(error?.response?.data?.message || error?.message);
        // setOpenAlert(true);
        // setSeverity("error");
      });
  };

  useEffect(() => {
    const setCultureCode = async () => {
      const uniqueCode = await getCultureUniqueCode();
      setValues((prevValues) => ({
        ...prevValues,
        cuCultureCode: uniqueCode,
      }));
    };
    setCultureCode();
  }, []);

  useEffect(() => {
    getCultureUniqueId();
    getMenuCultureGroupApi();
  }, []);

  const handleStatusChange = (index, event) => {
    const { checked } = event.target;
    console.log("status", checked);
    setValues((prevValues) => {
      const updatedCultureFields = [...prevValues.cultureTypes];
      updatedCultureFields[index] = {
        ...updatedCultureFields[index],
        status: checked ? true : false,
      };
      return {
        ...prevValues,
        cultureTypes: updatedCultureFields,
      };
    });
  };

  const addTaxFieldHandler = () => {
    setValues((prevValues) => ({
      ...prevValues,
      cultureTypes: [
        ...prevValues.cultureTypes,
        {
          cultureType: "",
          description: "",
          cultureGroupId: "",
          status: false,
        },
      ],
    }));
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   let schemaValidationErrors = {};
  //   try {
  //     await cultureSchema.validate(values, { abortEarly: false });
  //     setErrors((prevErrors) => ({
  //       ...schemaValidationErrors,
  //     }));

  //   } catch (err) {
  //     let path1exist = false;
  //     let isUniqueCultureType = true;
  //     err?.inner?.forEach((error) => {
  //       const path = error.path.split(".");
  //       if (path.length == 1 && path[0] == 'cuCultureName') {
  //         path1exist = true
  //         schemaValidationErrors[path[0]] = error.message;
  //       }
  //       if (path[0] == 'cultureTypes' && error.message == "Culture type must be unique") {
  //         isUniqueCultureType = false
  //       }
  //       if (path.length > 1) {
  //         const index = path[0]
  //         const field = path[1];
  //         console.log('index', index)
  //         console.log('field', field)

  //         let a = index.split('[')
  //         let b = a[1].split(']')
  //         let newIndex = b[0]
  //         schemaValidationErrors.cultureTypes = schemaValidationErrors.cultureTypes || [];
  //         schemaValidationErrors.cultureTypes[newIndex] = schemaValidationErrors.cultureTypes[newIndex] || {};
  //         schemaValidationErrors.cultureTypes[newIndex][field] = error.message;
  //       }
  //     });
  //     setErrors((prevErrors) => ({
  //       ...schemaValidationErrors,
  //     }));
  //     if (!isUniqueCultureType) {
  //       setSeverity("error");
  //       setMessage("Culture type must be unique");
  //       setOpenAlert(true);
  //       return
  //     }
  //   }
  //   // Check for blank fields in cultureTypes
  //   // values.cultureTypes.forEach((culture, index) => {
  //   //   const blankFieldErrors = {};
  //   //   if (!culture.cultureType)
  //   //     blankFieldErrors.cultureType = "Culture Type is required";
  //   //   if (!culture.description)
  //   //     blankFieldErrors.description = "Description is required";
  //   //   if (!culture.cultureGroupId)
  //   //     blankFieldErrors.cultureGroupId = "Culture Group is required";
  //   //   if (Object.keys(blankFieldErrors).length > 0) {
  //   //     schemaValidationErrors.cultureTypes =
  //   //       schemaValidationErrors.cultureTypes || [];
  //   //     schemaValidationErrors.cultureTypes[index] = {
  //   //       ...schemaValidationErrors.cultureTypes[index],
  //   //       ...blankFieldErrors,
  //   //     };
  //   //   }
  //   // });

  //   if (Object.keys(schemaValidationErrors).length === 0) {
  //     // If no errors, proceed with form submission

  //     const {
  //       cuCultureCode,
  //       cuCultureName,
  //       cuStatus,
  //       cultureTypes,
  //       cultureGroupId,
  //     } = values;

  //     const payload = {
  //       cuCultureCode,
  //       cuCultureName,
  //       cuStatus,
  //       cuCultureTypeAmount: cultureTypes.length,
  //       cultureTypes,
  //       countryCode: "BRA",
  //       cuCultureDescription: "Demo Phase 1",
  //       // cultureGroupId:"group 1"
  //     };
  //     try {
  //       setLoading(true)
  //       const response = await CultureService.createCulture(payload);
  //       setLoading(false)
  //       if (response.status === 200) {
  //         setSeverity("success");
  //         setMessage("Added successfully");
  //         setOpenAlert(true);
  //         // setLoading(false);
  //         navigate("/cultureRegistration");
  //       }
  //     } catch (error) {
  //       setLoading(false)
  //       if (error.response && error.response.status === 409) {
  //         // console.log("error",error.response.data.message)
  //         setSeverity("error");
  //         setMessage(error.response.data.message);
  //         setOpenAlert(true);
  //         // navigate("/cultureRegistration");
  //       } else {
  //         console.error("Failed to submit form");
  //         setSeverity("error");
  //         setMessage("Submission failed");
  //         setOpenAlert(true);
  //       }
  //     }
  //   } else {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       ...schemaValidationErrors,
  //     }));
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let schemaValidationErrors = {};
    try {
      await cultureSchema.validate(values, { abortEarly: false });
      setErrors((prevErrors) => ({
        ...schemaValidationErrors,
      }));
    } catch (err) {
      let path1exist = false;
      let isUniqueDescription = true; // Changed variable name
      err?.inner?.forEach((error) => {
        const path = error.path.split(".");
        if (path.length === 1 && path[0] === "cuCultureName") {
          path1exist = true;
          schemaValidationErrors[path[0]] = error.message;
        }
        if (
          path[0] === "cultureTypes" &&
          error.message === "Description must be unique"
        ) {
          // Changed to "Description"
          isUniqueDescription = false; // Changed variable name
        }
        if (path.length > 1) {
          const index = path[0];
          const field = path[1];
          let a = index.split("[");
          let b = a[1].split("]");
          let newIndex = b[0];
          schemaValidationErrors.cultureTypes =
            schemaValidationErrors.cultureTypes || [];
          schemaValidationErrors.cultureTypes[newIndex] =
            schemaValidationErrors.cultureTypes[newIndex] || {};
          schemaValidationErrors.cultureTypes[newIndex][field] = error.message;
        }
      });
      setErrors((prevErrors) => ({
        ...schemaValidationErrors,
      }));
      if (!isUniqueDescription) {
        // Changed to "isUniqueDescription"
        setSeverity("error");
        setMessage("Description must be unique"); // Changed message
        setOpenAlert(true);
        return;
      }
    }

    if (Object.keys(schemaValidationErrors).length === 0) {
      // If no errors, proceed with form submission

      const {
        cuCultureCode,
        cuCultureName,
        cuStatus,
        cultureTypes,
        cultureGroupId,
      } = values;

      const payload = {
        cuCultureCode,
        cuCultureName,
        cuStatus,
        cuCultureTypeAmount: cultureTypes.length,
        cultureTypes,
        countryCode: "BRA",
        cuCultureDescription: "Demo Phase 1",
        // cultureGroupId:"group 1"
      };
      try {
        setLoading(true);
        const response = await CultureService.createCulture(payload);
        setLoading(false);
        if (response.status === 200) {
          setSeverity("success");
          setMessage("Added successfully");
          setOpenAlert(true);
          navigate("/cultureRegistration");
        }
      } catch (error) {
        setLoading(false);
        if (error.response && error.response.status === 409) {
          setSeverity("error");
          setMessage(error.response.data.message);
          setOpenAlert(true);
        } else {
          console.error("Failed to submit form");
          setSeverity("error");
          setMessage("Submission failed");
          setOpenAlert(true);
        }
      }
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...schemaValidationErrors,
      }));
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();

    let schemaValidationErrors = {};
    try {
      await cultureSchema.validate(values, { abortEarly: false });
      setErrors((prevErrors) => ({
        ...schemaValidationErrors,
      }));
    } catch (err) {
      let path1exist = false;
      let isUniqueDescription = true; // Updated variable name
      err?.inner?.forEach((error) => {
        const path = error.path.split(".");
        if (path.length === 1 && path[0] === "cuCultureName") {
          path1exist = true;
          schemaValidationErrors[path[0]] = error.message;
        }
        if (
          path[0] === "cultureTypes" &&
          error.message === "Description must be unique"
        ) {
          // Updated to "Description"
          isUniqueDescription = false; // Updated variable name
        }
        if (path.length > 1) {
          const index = path[0];
          const field = path[1];
          let a = index.split("[");
          let b = a[1].split("]");
          let newIndex = b[0];
          schemaValidationErrors.cultureTypes =
            schemaValidationErrors.cultureTypes || [];
          schemaValidationErrors.cultureTypes[newIndex] =
            schemaValidationErrors.cultureTypes[newIndex] || {};
          schemaValidationErrors.cultureTypes[newIndex][field] = error.message;
        }
      });
      setErrors((prevErrors) => ({
        ...schemaValidationErrors,
      }));
      if (!isUniqueDescription) {
        // Updated to "isUniqueDescription"
        setSeverity("error");
        setMessage("Description must be unique"); // Updated message
        setOpenAlert(true);
        return;
      }
    }

    if (Object.keys(schemaValidationErrors).length === 0) {
      // If no errors, proceed with form submission

      const {
        cuCultureCode,
        cuCultureName,
        cuStatus,
        cultureTypes,
        cultureGroupId,
      } = values;

      const payload = {
        cuCultureCode,
        cuCultureName,
        cuStatus,
        cuCultureTypeAmount: cultureTypes.length,
        cultureTypes,
        countryCode: "BRA",
        cuCultureDescription: "Demo Phase 1",
        cuCultureId: id,
        countryId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        languageId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        createdByUser: "string",
        modifiedByUser: "string",
      };
      console.log("payload", payload);
      // Check if description in culture type list remains the same as previous

      try {
        setLoading(true);
        const response = await CultureService.updateCulture(payload);
        setLoading(false);
        if (response.status === 200) {
          setSeverity("success");
          setMessage("Updated successfully");
          setOpenAlert(true);
          navigate("/cultureRegistration");
        }
      } catch (error) {
        setLoading(false);
        if (error.response && error.response.status === 409) {
          const errorMessage = error.response.data.message;
          setMessage(errorMessage);
          setOpenAlert(true);
        } else {
          console.error("Failed to submit form");
          setSeverity("error");
          setMessage("Update failed");
          setOpenAlert(true);
        }
      }
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...schemaValidationErrors,
      }));
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleDeleteTaxField = (index) => {
    setValues((prevValues) => {
      const updatedCultureFields = [...prevValues.cultureTypes];
      updatedCultureFields.splice(index, 1);
      return {
        ...prevValues,
        cultureTypes: updatedCultureFields,
      };
    });
  };

  const handleClear = (field) => {
    setValues((prevValues) => ({
      ...prevValues,
      [field]: "",
    }));
  };

  return (
    <Layout title={"Culture Registration "}>
      <Box
        sx={{
          minHeight: 180,
          flexGrow: 1,
          paddingBottom: "10rem",
          width: "100%",
          "& .MuiTextField-root": { m: 1, color: "red", width: "25ch" },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
          },
        }}
      >
        <Button
          style={{
            margin: "30px 0px 30px 10px",
            color: "#004F9F",
            borderColor: "#004F9F",
          }}
          required
          variant="outlined"
          startIcon={
            <Icon>
              <img
                alt="edit"
                style={{ display: "flex", justifyContent: "center" }}
                width="100%"
                height="100%"
                src={leftIcon}
              />
            </Icon>
          }
          onClick={(event) => navigate("/cultureRegistration")}
        >
          {" "}
          Back Home
        </Button>

        <form autoComplete="off" noValidate>
          <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<img src={arrowDown} alt="Down Arrow" />}
            defaultExpandIcon={<ChevronRightIcon />}
            defaultExpanded={["1", "4", "5"]}
          >
            {cultureFormConfig.slice(0, 19).map((field) => (
              <RenderField
                key={field.name}
                field={field}
                values={values}
                errors={errors}
                handleChange={handleChange}
                handleClear={handleClear}
                width={"344px"}
                details={details === true ? true : false}
              />
            ))}

            <StyledTreeItem
              nodeId="4"
              sx={{ mt: "2rem" }}
              labelText="Culture Type List"
            >
              <div className="farmer-add-tree-main">
                {values.cultureTypes?.map((field, index) => (
                  <div className="farmer-inner-field" key={index}>
                    <TextField
                      value={field.cultureType}
                      onChange={(e) => handleCultureFieldChange(index, e)}
                      name="cultureType"
                      error={!!errors?.cultureTypes?.[index]?.cultureType}
                      helperText={errors?.cultureTypes?.[index]?.cultureType}
                      label="Culture Type"
                      style={{ minWidth: 219 }}
                      required
                      variant="outlined"
                      disabled={details === true ? true : false}
                    />
                    <TextField
                      value={field.description}
                      onChange={(e) => handleCultureFieldChange(index, e)}
                      name="description"
                      error={!!errors?.cultureTypes?.[index]?.description}
                      helperText={errors?.cultureTypes?.[index]?.description}
                      label="Description"
                      style={{ minWidth: 350 }}
                      required
                      variant="outlined"
                      disabled={details === true ? true : false}
                    />
                    {/* <TextField
                      value={field.cultureGroupId}
                      onChange={(e) => handleCultureFieldChange(index, e)}
                      name="cultureGroupId"
                      error={!!errors?.cultureTypes?.[index]?.cultureGroupId}
                      helperText={errors?.cultureTypes?.[index]?.cultureGroupId}
                      label="Culture Group"
                      required
                      variant="outlined"
                    /> */}
                    <FormControl sx={{ minWidth: "18.75rem" }}>
                      <TextField
                        value={field.cultureGroupId}
                        onChange={(e) => handleCultureFieldChange(index, e)}
                        name="cultureGroupId"
                        error={!!errors?.cultureTypes?.[index]?.cultureGroupId}
                        helperText={
                          errors?.cultureTypes?.[index]?.cultureGroupId
                        }
                        // style={{ width: 377}}
                        label="Culture Group"
                        required
                        variant="outlined"
                        select
                        SelectProps={{ IconComponent: ArrowDropDownIcon }}
                        disabled={details === true ? true : false}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {CultureGroupItems.map((item, idx) => (
                          <MenuItem key={idx} value={item.cultureGroupId}>
                            {item.cultureGroupName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                    <Box
                      sx={{
                        ml: "38px",
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      <FormControl
                        component="fieldset"
                        disabled={details === true ? true : false}
                      >
                        <FormLabel
                          sx={{
                            color: "#0E2B63",
                            mb: 2,
                            ml: 0.00005,
                            "&.Mui-focused": { color: "#0E2B63" },
                            "&:hover": { color: "#0E2B63" },
                          }}
                        >
                          Status
                        </FormLabel>
                        <FormControlLabel
                          sx={{
                            ml: 0.00005,
                            display: "flex",
                            alignItems: "center",
                            gap: "0.0000001rem",
                          }}
                          control={
                            <RedSwitch
                              checked={field.status === true}
                              onChange={(e) => handleStatusChange(index, e)}
                              name="status"
                              color="primary"
                            />
                          }
                          label={
                            <Box sx={{ width: "5rem" }}>
                              {field.status == true ? "Active" : "Inactive"}
                            </Box>
                          }
                          labelPlacement="start"
                        />
                      </FormControl>
                    </Box>

                    {!id && (
                      <img
                        onClick={() => handleDeleteTaxField(index)}
                        src={deletebutton}
                        alt="Delete"
                        style={{ marginLeft: "4rem", marginTop: "1rem" }}
                      />
                    )}
                  </div>
                ))}

                {values.cultureTypes.length < 3 && (
                  <Box className="farmer-add-property">
                    <span onClick={addTaxFieldHandler}>
                      + Add culture type for this culture
                    </span>
                  </Box>
                )}

                {/* <Box
                  className="farmer-add-property"
                  onClick={addTaxFieldHandler}
                >
                  + Add culture type for this culture
                </Box> */}
              </div>
            </StyledTreeItem>
          </TreeView>
        </form>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "2rem",
            marginTop: "100px",
          }}
        >
          <Button
            style={{
              backgroundColor: "#737D86",
              padding: " 6px, 16px, 6px, 16px",
              borderRadius: " 4px",
            }}
            startIcon={<img src={arrowLeft} alt="Left Arrow" />}
            variant="contained"
            type="secondary"
            disabled={loading}
            onClick={() => navigate("/cultureRegistration")}
          >
            {loading && <CircularProgress size={20} />}
            Cancel
          </Button>
          {details === true ? (
            <></>
          ) : (
            <Button
              sx={{
                backgroundColor: "#004F9F",
                padding: " 6px, 16px, 6px, 11px",
                borderRadius: " 4px",
                color: "white",
              }}
              endIcon={<img src={arrowRight} alt="Right Arrow" />}
              type="button"
              variant="contained"
              onClick={id === undefined ? handleSubmit : handleUpdate}
              disabled={loading}
            >
              {loading && <CircularProgress size={20} />}
              {id === undefined ? "SAVE" : "UPDATE"} Culture Registration
            </Button>
          )}
        </Box>
      </Box>
    </Layout>
  );
}
