// import React, { useEffect, useState } from "react";
// import { Typography, Box, Button, Menu, MenuItem } from "@mui/material";
// import "./Expenses3rdParty.css";
// import EditButton from "../../../../assets/editButton.svg";
import { useNavigate } from "react-router-dom";
import "./SoilAnalysisParameter.css";
import React, { useContext, useEffect, useState } from "react";
import { Typography, Button, Modal, Box } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import _ButtonEdit_ from "assets/_ButtonEdit_.svg";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import OccurrenceTypeService from "services/OccurrenceTypeService";
import { SnackbarContext } from "context/snackBarContext";
import ExpensesTypeService from "services/ExpensesTypeService";
import { useTranslation } from 'react-i18next';

const Expenses3rdPartyTable = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [originalData, setOriginalData] = useState([]);
  const [expensesData, setExpensesData] = useState([]);

  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  const [open, setOpen] = useState(false); // Modal state
  const [deleteId, setDeleteId] = useState(null); // ID of the record to delete

  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  useEffect(() => {
    setExpensesData(props.expensesData);
    setOriginalData(props.expensesData);
  }, [props.expensesData]);

  const handleDeleteClick = (expensesId) => {
    setDeleteId(expensesId); // Store the ID of the record to delete
    setOpen(true); // Open the confirmation modal
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await ExpensesTypeService.deleteExpenses(deleteId); // Delete by stored ID
      if (response.status === 200) {
        setMessage("Deleted successfully");
        setSeverity("success");
        setOpenAlert(true);
        setExpensesData((prevData) =>
          prevData.filter(
            (item) => item.ewtpExpenseswith3rdPartiesId !== deleteId
          )
        );
      } else {
        setMessage("Failed to delete expenses type");
        setSeverity("error");
        setOpenAlert(true);
      }
    } catch (error) {
      setMessage("Error deleting expenses type");
      setSeverity("error");
      setOpenAlert(true);
      console.error("Error deleting expenses type:", error);
    } finally {
      setOpen(false); // Close the modal after deletion
    }
  };

  const handleClose = () => {
    setOpen(false); // Close the modal without deleting
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        direction = "desc";
      } else if (sortConfig.direction === "desc") {
        direction = null;
      }
    }
    setSortConfig({ key, direction });
    if (direction === null) {
      setExpensesData(originalData);
    } else {
      const sortedData = [...expensesData].sort((a, b) => {
        if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
        if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
        return 0;
      });
      setExpensesData(sortedData);
    }
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc")
        return <ArrowUpwardIcon fontSize="small" />;
      else if (sortConfig.direction === "desc")
        return <ArrowDownwardIcon fontSize="small" />;
    }
    return null;
  };

  return (
    <div className="table-container">
      <table className="full-width-table">
        <thead>
          <tr className="HierarchyHead">
            <th
              style={{
                textAlign: "left",
                paddingBottom: "1%",
                borderBottom: "5px solid #102A63",
                alignItems: "center",
              }}
            >
              Expenses with 3rd Parties Group Description
            </th>

            <th
              style={{
                textAlign: "left",
                paddingBottom: "1%",
                borderBottom: "5px solid #102A63",
                alignItems: "center",
                cursor: "pointer",
                justifyContent: "left",
              }}
              onClick={() => handleSort("tobaccoType")}
            >
              {getSortIcon("tobaccoType")} Tobacco Type
            </th>

            <th
              style={{
                textAlign: "left",
                paddingBottom: "1%",
                borderBottom: "5px solid #102A63",
                alignItems: "center",
                cursor: "pointer",
                justifyContent: "left",
              }}
              onClick={() => handleSort("status")}
            >
              {getSortIcon("status")} Status
            </th>

            <th
              style={{
                textAlign: "center",
                paddingBottom: "1%",
                paddingLeft: "7%",
                borderBottom: "5px solid #102A63",
                alignItems: "center",
                cursor: "pointer",
                justifyContent: "left",
              }}
            >
              Actions
            </th>
          </tr>
        </thead>

        <tbody>
          {expensesData?.map((item, index) => (
            <tr key={index} className="hierarchyColumn">
              <td
                onClick={() =>
                  navigate("/editExpenses", {
                    state: {
                      ewtpExpenseswith3rdPartiesDescription:
                        item.ewtpExpenseswith3rdPartiesDescription,
                      tobaccoType: item.tobaccoType,
                      cultureName: item.cultureName,
                      edit: false,
                      ewtpExpenseswith3rdPartiesId:
                        item.ewtpExpenseswith3rdPartiesId,
                      isActive: item.isActive,
                      tobaccoTypeId: item.tobaccoTypes,
                      investements: item.investements,
                    },
                  })
                }
                style={{ textAlign: "left" }}
              >
                <Typography
                  sx={{
                    justifyContent: "left",
                    fontWeight: 600,
                    color: "#3B454F",
                  }}
                >
                  {item.ewtpExpenseswith3rdPartiesDescription}
                </Typography>
              </td>

              <td
                onClick={() =>
                  navigate("/editExpenses", {
                    state: {
                      ewtpExpenseswith3rdPartiesDescription:
                        item.ewtpExpenseswith3rdPartiesDescription,
                      tobaccoType: item.tobaccoType,
                      cultureName: item.cultureName,
                      edit: false,
                      ewtpExpenseswith3rdPartiesId:
                        item.ewtpExpenseswith3rdPartiesId,
                      isActive: item.isActive,
                      tobaccoTypeId: item.tobaccoTypes,
                      investements: item.investements,
                    },
                  })
                }
                style={{ textAlign: "left" }}
              >
                <Typography
                  sx={{
                    justifyContent: "left",
                    fontWeight: 600,
                    color: "#3B454F",
                  }}
                >
                  {item?.tobaccoTypes.map((tobacco, index) => (
                    <span>
                      {tobacco.tobaccoType + "-" + tobacco.cultureName}
                      {index + 1 !== item.tobaccoTypes.length ? "," : ""}
                    </span>
                  ))}
                </Typography>
              </td>

              <td
                onClick={() =>
                  navigate("/editExpenses", {
                    state: {
                      ewtpExpenseswith3rdPartiesDescription:
                        item.ewtpExpenseswith3rdPartiesDescription,
                      tobaccoType: item.tobaccoType,
                      cultureName: item.cultureName,
                      edit: false,
                      ewtpExpenseswith3rdPartiesId:
                        item.ewtpExpenseswith3rdPartiesId,
                      isActive: item.isActive,
                      tobaccoTypeId: item.tobaccoTypes,
                      investements: item.investements,
                    },
                  })
                }
                style={{
                  textAlign: "left",
                  fontWeight: 600,
                  color: "#3B454F",
                }}
              >
                {item.isActive ? "Active" : "Inactive"}
              </td>
              <td>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    cursor: "pointer",
                    marginTop: "-25px",
                  }}
                >
                  <Button
                    style={{ marginTop: "15px", marginRight: "-15px" }}
                    onClick={() =>
                      navigate("/editExpenses", {
                        state: {
                          ewtpExpenseswith3rdPartiesDescription:
                            item.ewtpExpenseswith3rdPartiesDescription,
                          tobaccoType: item.tobaccoType,
                          cultureName: item.cultureName,
                          edit: true,
                          ewtpExpenseswith3rdPartiesId:
                            item.ewtpExpenseswith3rdPartiesId,
                          isActive: item.isActive,
                          tobaccoTypeId: item.tobaccoTypes,
                          investements: item.investements,
                        },
                      })
                    }
                  >
                    <img src={_ButtonEdit_} alt="Edit Button" />
                  </Button>

                  <Button
                    style={{ marginTop: "20px", marginRight: "5px" }}
                    onClick={() =>
                      handleDeleteClick(item.ewtpExpenseswith3rdPartiesId)
                    }
                  >
                    <div>
                      <img src={_ButtonDelete_} alt="Delete" />
                    </div>
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal for delete confirmation */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "520px",
            margin: "auto",
            mt: 25,
            height: "200px",
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
            >
              Delete Confirmation
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, fontWeight: 400 }}
            >
              Are you sure you want to delete this record? Deleted records
              cannot be recovered.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              mb: 1,
            }}
          >
            <Button
              onClick={handleClose}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              No
            </Button>
            <Button
              onClick={handleDeleteConfirm}
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#004F9F" },
              }}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

// }

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 5,
      width: "100%",
    }}
  />
);

export default Expenses3rdPartyTable;
