import { Box, Button, CircularProgress } from "@mui/material";
import Layout from "components/structure/Layout";
import arrowLeft from "assets/arrowLeft.svg";
import saveIcon from "assets/saveIcon.svg";
import * as Yup from "yup";
import { useState, useEffect, useContext } from "react";
import { curingSchema, spacingScheme } from "utils/validations";
import RenderField from "utils/RenderField";
import { useLocation, useNavigate } from "react-router-dom";
import CuringService from "services/CuringService";
import { SnackbarContext } from "context/snackBarContext";
import { AuthData } from "auth/AuthWarpper";
// import {
//   editSpacingField,
//   spacingField as initialSpacingField,
// } from "./SpacingFormConfig"; // Import initial spacingField
import SpacingServices from "services/SpacingServices";
import ExpandMoreIcon from "@mui/icons-material/ArrowDropDown";
import { useTranslation } from "react-i18next";

const AddSpacing = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { user } = AuthData();

  const initialSpacingField = [
    {
      name: "tobaccoType",
      label: t("spacingRegistration.tobaccoType"),
      type: "select",
      options: [],
      required: true,
    },
    {
      name: "unitOfMeasure",
      label: t("spacingRegistration.unitOfMeasure"),
      type: "select",
      options: [
        { value: "", label: "None" },
        { value: "Centimeter", label: t("spacingRegistration.centimeter") },
        { value: "Inches", label: t("spacingRegistration.inches") },
        { value: "Meters", label: t("spacingRegistration.meters") },
      ],
      required: true,
    },
    {
      name: "lineSpacing",
      label: t("spacingRegistration.lineSpacing"),
      type: "text",
      required: true,
    },
    {
      name: "plantSpacing",
      label: t("spacingRegistration.plantSpacing"),
      type: "text",
      required: true,
    },
    {
      name: "noOfPlants",
      label: t("spacingRegistration.numberOfPlants"),
      type: "number",
      unit: "K Plants/Ha",
      required: true,
    },
    {
      name: "status",
      label: t("spacingRegistration.status"),
      type: "switch",
    },
  ];

  const editSpacingField = [
    {
      name: "tobaccoType",
      label: t("spacingRegistration.tobaccoType"),
      type: "select",
      options: [],
      required: true,
      disabled: true,
    },
    {
      name: "unitOfMeasure",
      label: t("spacingRegistration.unitOfMeasure"),
      type: "select",
      options: [
        { value: "", label: "None" },
        { value: "Centimeter", label: t("spacingRegistration.centimeter") },
        { value: "Inches", label: t("spacingRegistration.inches") },
        { value: "Meters", label: t("spacingRegistration.meters") },
      ],
      required: true,
    },
    {
      name: "lineSpacing",
      label: t("spacingRegistration.lineSpacing"),
      type: "text",
      required: true,
    },
    {
      name: "plantSpacing",
      label: t("spacingRegistration.plantSpacing"),
      type: "text",
      required: true,
    },
    {
      name: "noOfPlants",
      label: t("spacingRegistration.numberOfPlants"),
      type: "number",
      unit: "K Plants/Ha",
      required: true,
    },
    {
      name: "status",
      label: t("spacingRegistration.status"),
      type: "switch",
    },
  ];

  const { edit, spaceRegistrationUid, details } = location.state || {};
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [values, setValues] = useState({});
  console.log("values", values);
  const [errors, setErrors] = useState({});
  const [dateError, setDateError] = useState(false);
  const [spacingField, setSpacingField] = useState(
    edit === true ? editSpacingField : initialSpacingField
  ); // State for spacingField
  console.log("🚀 ~ AddSpacing ~ spacingField:", spacingField);
  const [loading, setLoading] = useState(false);
  const fetchTobaccoGroup = async () => {
    try {
      const response = await SpacingServices.getTobbacoType();
      const tobaccoGroup = response.data.response;
      console.log("tobaccoGroup", tobaccoGroup);
      // Construct options array including initial empty option
      const options = [
        { value: "", label: "None" },
        ...tobaccoGroup.map((group) => ({
          value: group.tobaccoGroup,
          label: group.tobaccoGroup,
        })),
      ];

      // Update spacingField with dynamic options
      const updatedSpacingField = spacingField.map((field) => {
        if (field.name === "tobaccoType") {
          return {
            ...field,
            options: options,
          };
        }
        return field;
      });

      setSpacingField(updatedSpacingField); // Update spacingField with dynamic options

      // Check if the value from navigation state matches any option
      if (location.state?.tobaccoType) {
        const matchedOption = options.find(
          (option) => option.value === location.state.tobaccoType
        );
        if (matchedOption) {
          setValues((prevValues) => ({
            ...prevValues,
            tobaccoType: matchedOption.value,
          }));
        } else {
          setValues((prevValues) => ({
            ...prevValues,
            tobaccoType: options[0].value,
          }));
        }
      } else {
        // Set initial value for tobaccoType if not already set
        setValues((prevValues) => ({
          ...prevValues,
          tobaccoType:
            prevValues.tobaccoType ||
            (options.length > 0 ? options[0].value : ""),
        }));
      }
    } catch (error) {
      console.error("Failed to fetch tobacco group:", error);
    }
  };

  useEffect(() => {
    if (location.state) {
      const {
        noOfPlants,
        plantSpacing,
        tobaccoType,
        lineSpacing,
        unitOfMeasure,
        status,
      } = location.state;
      console.log("🚀 ~ useEffect ~ unitOfMeasure:", unitOfMeasure);

      setValues((prevValues) => ({
        ...prevValues,
        noOfPlants: noOfPlants || "",
        plantSpacing: plantSpacing || "",
        lineSpacing: lineSpacing || "",
        unitOfMeasure: unitOfMeasure || "",
        status: status || "",
      }));

      // Fetch tobacco group and match the tobaccoType
      fetchTobaccoGroup();
    } else {
      fetchTobaccoGroup();
    }
  }, [location.state]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await spacingScheme.validate(values, { abortEarly: false });
      setErrors({});
      console.log("🚀 ~ status:", user?.countryCode);
      const payload = {
        tobaccoType: values.tobaccoType,
        lineSpacing: parseFloat(values.lineSpacing),
        plantSpacing: parseFloat(values.plantSpacing),
        status: values.status,
        unitOfMeasure: values.unitOfMeasure,
        noOfPlants: values.noOfPlants,
        createdByUser: user?.batOneId || 323222,
        modifiedByUser: user?.batOneId || 323222,
        countryCode: user?.countryCode || "BRA",
      };
      setLoading(true);
      const response = await SpacingServices.create(payload);
      console.log("🚀 ~ handleSubmit ~ response:", response);
      setLoading(false);
      if (response.status === 201) {
        navigate("/spacingRegistration");
        setSeverity("success");
        setMessage("Added successfully");
        setOpenAlert(true);
        // setLoading(false);
      } else {
        console.error("Failed to submit form");
        setSeverity("error");
        setMessage("Submission failed");
        setOpenAlert(true);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      if (err.response && err.response.status === 409) {
        setSeverity("error");
        setMessage(
          `${
            err.response.data.message || "Spacing registration already exists"
          }`
        );
        setOpenAlert(true);
      } else if (err instanceof Yup.ValidationError) {
        let newError = {};
        err?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setErrors(newError);
      } else {
        console.error("An error occurred while submitting the form:", err);
        setSeverity("error");
        setMessage("An error occurred while submitting the form");
        setOpenAlert(true);
      }
    }
  };

  const handleUpdate = async (event) => {
    setLoading(true);

    event.preventDefault();
    try {
      await spacingScheme.validate(values, { abortEarly: false });
      setErrors({});
      const payload = {
        tobaccoType: values.tobaccoType,
        lineSpacing: parseFloat(values.lineSpacing),
        plantSpacing: parseFloat(values.plantSpacing),
        status: values.status == true ? true : false,
        unitOfMeasure: values.unitOfMeasure,
        noOfPlants: values.noOfPlants,
        createdByUser: user?.batOneId || 323222,
        modifiedByUser: user?.batOneId || 323222,
        countryCode: user?.countryCode || "BRA",
        spaceRegistrationUid: spaceRegistrationUid,
      };
      console.log("payloadUpdate", payload);
      setLoading(true);
      const response = await SpacingServices.update(payload);
      setLoading(false);
      if (response.status === 200) {
        navigate("/spacingRegistration");
        setSeverity("success");
        setMessage("Added successfully");
        setOpenAlert(true);
        setLoading(false);
      } else {
        console.error("Failed to submit form");
        setSeverity("error");
        setMessage("Submission failed");
        setOpenAlert(true);
      }
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        let newError = {};
        err?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setErrors(newError);
      } else {
        console.error("An error occurred while submitting the form:", err);
        setSeverity("error");
        setMessage("An error occurred while submitting the form");
        setOpenAlert(true);
      }
    }
  };

  const handleClear = (field) => {
    setValues((prevValues) => ({
      ...prevValues,
      [field]: "",
    }));
  };

  return (
    <Layout title="Spacing Registration">
      <Box
        sx={{
          minHeight: 180,
          flexGrow: 1,
          width: "100%",
          "& .MuiTextField-root": {
            m: 1,
            color: "green !important",
            width: "25ch",
          },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
            "& .Mui-focused": {
              color: "red !important",
            },
          },
        }}
      >
        <form
          autoComplete="off"
          noValidate
          onSubmit={edit ? handleUpdate : handleSubmit}
        >
          <Box
            sx={{
              flexGrow: 1,
              width: "100%",
              backgroundColor: "#f0f0f0",
              borderRadius: "8px",
              padding: "16px",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <ExpandMoreIcon sx={{ color: "#000", marginLeft: "8px" }} />
              {t("form")}
            </Box>

            <Box>
              {spacingField.slice(0, 7).map((field) => (
                <RenderField
                  key={field.name}
                  field={field}
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  handleClear={handleClear}
                  details={details}
                />
              ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "95%",
                position: "fixed",
                bottom: 100,
              }}
            >
              <Button
                style={{
                  backgroundColor: "#737D86",
                  padding: " 6px, 16px, 6px, 16px",
                  borderRadius: " 4px",
                }}
                startIcon={<img src={arrowLeft} alt="Arrow" />}
                variant="contained"
                type="secondary"
                onClick={() => {
                  navigate("/spacingRegistration");
                }}
                disabled={loading}
              >
                {loading && <CircularProgress size={20} />}
                {t("cancel")}
              </Button>
              <Button
                sx={{
                  backgroundColor: "#EF7D00",
                  padding: " 6px, 16px, 6px, 11px",
                  borderRadius: " 4px",
                  color: "white",
                }}
                disabled={details || loading}
                startIcon={<img src={saveIcon} alt="save icon" />}
                type="submit"
                variant="contained"
              >
                {loading && <CircularProgress size={20} />}
                {t("save")}
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Layout>
  );
};

export default AddSpacing;
