import { httpGrow } from "../http-common";

const getFurnace = async (query) => {
  const response = await httpGrow.get(
    `/furnace-type/list?pageNumber=${query?.pageNumber}&size=${query?.size}`
  );
  return response;
};


const createFurnace = (data) => {
  return httpGrow.post(`/furnace-type/save-furnace-types`, data);
};

const updateFurnace = (data) => {
  return httpGrow.put(`/furnace-type/update-furnace-types`, data);
};

const deleteFurnace = (furnaceTypeId) => {
  return httpGrow.delete(`/furnace-type/${furnaceTypeId}`);
};
// const searchTobacco = async (query) => {
//   const response = await httpGrow.get(
//     `/tobaccoCompaniesCompetitors/search?pageNumber=${query?.pageNumber}&pageSize=${
//       query?.size || 50
//     }&tobaccoCompanyName=${query?.tobaccoCompanyName || ""}&
//     tccCountryCode=${
//       query?.tccCountryCode || ""
//     }&region=${query?.region || ""}&status=${
//       query?.status !== undefined ? query.status : ""
//     }`
//   );
//   return response;
// };


const searchFurnace =async(queryString)=>{
  const response =await httpGrow.get(`/furnace-type/find${queryString}`)
  return response;
}

const FurnaceService = {
  getFurnace,
  createFurnace,
  updateFurnace,
  deleteFurnace,
  searchFurnace,
};

export default FurnaceService;
