export const validateForm = (formValues) => {
    let status = true;
    for (const [k, v] of Object.entries(formValues)) {
      if (v.error == true) {
        status = false;
        break;
      }
    }
    return status;
  }
  export const   getBase64 = async (file)=> {
    if(!file) return
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const base64 = reader.result.toString().replace(/^data:(.*,)?/, "");
        resolve(base64)
      }
      reader.onerror = reject
    })
  }

  export const validateEmail = (email) =>{
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
  

export const trimString = (str) => str.trim();

export function toBoolean(value) {
  if (value === "true") return true;
  if (value === "false") return false;
  return null; // or handle other cases as needed
}

export function toStringBoolean(value) {
  if (value === true) return "true";
  if (value === false) return "false";
  return ""; 
}

export function convertToDateString(isoString) {
  const date = new Date(isoString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); 
  const day = String(date.getDate()).padStart(2, '0');

  const dateString = `${year}-${month}-${day}`;

  return dateString;
}


