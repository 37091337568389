import React from "react";
import "./Task.css";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DragIcon from "assets/cross.svg";
import { useNavigate } from "react-router-dom";
import { useSensor, MouseSensor } from "@dnd-kit/core";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";

const Task = ({
  id,
  title,
  status,
  countryArr,
  code,
  compliance,
  modifiedTime,
  modifiedBy,
  sequence,
  countryArray,
}) => {
  console.log("🚀 ~ compliance:", compliance)
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });
  const navigate = useNavigate(); // Initialize useNavigate hook

  const editButtonSensor = useSensor(MouseSensor);

  const handleClick = () => {
    console.log("Editing task...");
    navigate(`/editCropStage/${id}`, {
      state: {
        id,
        title,
        status,
        countryArr,
        code,
        compliance,
        modifiedTime,
        modifiedBy,
        sequence,
        countryArray,
        edit: true,
      },
    });
  };

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };
  return (
    <div style={{ position: "relative" }}>
      <div style={style}>
        <div
          className="task"
          ref={setNodeRef}
          {...attributes}
          {...listeners}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <img src={DragIcon} alt="Drag Icon" />
            <div style={{ color: "#191E31", fontWeight: 600 }}>{title}</div>
          </div>
        </div>
        <div
          style={{ position: "absolute", top: "0.4rem", right: "1rem" }}
          {...editButtonSensor}
        >
          <Button
            variant="outlined"
            sx={{
              borderRadius: "2.116rem",
              backgroundColor: "#FEF4D5",
              borderColor: "#F2BB40",
              height:"2.125rem",
              width:"4.938rem",
              color: "#F2BB40",
              "&:hover": {
                backgroundColor: "#F2BB40",
                color: "#FEF4D5",
                borderColor: "#F2BB40",
              },
            }}
            startIcon={<EditIcon />}
            onClick={handleClick}
            draggable={false}
          >
            Edit
          </Button>
          {/* <img style={{ cursor: "pointer" }} src={editButton} alt="editButton"  /> */}
        </div>
      </div>
    </div>
  );
};

export default Task;
