import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import * as Yup from "yup";

import Box from "@mui/material/Box";
import {
    Button,
    TextField,
    MenuItem,
    FormControl,
    FormLabel,
    FormControlLabel,
    CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import closeIconWhite from "assets/closeIconWhite.svg";
import downloadIcon from "assets/downloadIcon.svg";
import arrowLeft from "assets/arrowLeft.svg";

import { SnackbarContext } from "context/snackBarContext";
import { AuthData } from "auth/AuthWarpper";
import Layout from "components/structure/Layout";
import RedSwitch from "utils/RedSwitch";
import { fieldNotebookSupplySchema } from "utils/validations";
import FieldNotebookSupplyService from "services/FieldNotebookSupplyService";
import { fetchLovDetails } from "utils/PickListHelper";
import {
    fetchFieldNotebookSupplyTypes,
    fetchFieldNotebookSupply,
} from "./util/FieldNotebookSupplyCommonUtil";
import {
    REGEX_NUMBER_WITH_TWO_DECIMAL_PLACESS,
    SUCCESS_SEVERITY_STATUS,
    ERROR_SEVERITY_STATUS,
    SAVE_BTN_LABEL,
    UPDATE_BTN_LABEL,
    CANCEL_BTN_LABEL,
    BACK_BTN_LABEL,
} from 'utils/AgriCommonConstants';
import {
    ONLY_NUMBER_AND_DECIMAL_ALLOWED,
    ADD_SUCCESS_MSG,
    UPDATE_SUCCESS_MSG,
    SUBMISSION_FAILED,
} from 'utils/AgriErrorMsg';
import {
    SUPPLY_DESCRIPTION_FIELD_NAME,
    UOM_FIELD_NAME,
    SUPPLY_TYPE_FIELD_NAME,
    STATUS_FIELD_NAME,
    NITROGEN_PERC_FIELD_NAME,
    PHOSPHORUS_PERC_FIELD_NAME,
    POTASSIUM_PERC_FIELD_NAME,
    SUPPLY_DESCRIPTION_FIELD_LABEL,
    UOM_FIELD_LABEL,
    SUPPLY_TYPE_FIELD_LABEL,
    STATUS_FIELD_LABEL,
    NITROGEN_PERC_FIELD_LABEL,
    PHOSPHORUS_PERC_FIELD_LABEL,
    POTASSIUM_PERC_FIELD_LABEL,
    ADD_AGRICULTURAL_SUPPLY_PAGE_HEADING,
    UOM_LOV,
    INACTIVE_FIELD_LABEL,
    ACTIVE_FIELD_LABEL,
    OCCURENCE_CATEGORY_LABEL,
    OCCURENCE_CATEGORY,
    OCCURENCE_LABEL,
    OCCURENCE,
} from "./util/FieldNotebookSupplyConstants";

export default function FieldNotebookSupplyForm(props) {
    const location = useLocation();
    const navigate = useNavigate();

    const { user } = AuthData();

    const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
    const [fieldNotebookSupplyFormData, setFieldNotebookSupplyFormData] =
        useState({
            supplyId: null,
            supplyDescription: "",
            uom: "",
            supplyType: null,
            status: false,
            nitrogenPerc: null,
            phosphorusPerc: null,
            potassiumPerc: null,
            showNPK: false,
        });
    const [errors, setErrors] = useState({});
    const [showNPK, setShowNPK] = useState(false);
    const [forPest, setForPest] = useState([]);
    const [fieldNotebookSupplyTypes, setFieldNotebookSupplyTypes] = useState([]);
    const [disableFields, setDisableFields] = useState(false);
    const [loading, setLoading] = useState(false);
    const [uomLovDetails, setUomLovDetails] = useState([]);

    useEffect(() => {
        const setSupplyTypes = async () => {
            try {
                const supplyTypes = await fetchFieldNotebookSupplyTypes();

                setFieldNotebookSupplyTypes(supplyTypes);
            } catch (error) {
                console.error("Failed to fetch supply types:", error);
            }
        };

        const fetchAndSetUomLovDetails = async () => {
            const fetchedLovDetails = await fetchLovDetails([UOM_LOV]);
            const uomLov = fetchedLovDetails.find(picklist => picklist.pickListCode === UOM_LOV)?.items || [];
            setUomLovDetails(uomLov);
        };

        setSupplyTypes();
        fetchAndSetUomLovDetails();
    }, []);

    useEffect(() => {
        const fetchAndSetFieldNotebookSupply = async (fieldNotebookSupplyId) => {
            const fieldNotebookSupplyDetails = await fetchFieldNotebookSupply(
                fieldNotebookSupplyId
            );
            let showNPK = false;
            const matchedSupplyType = fieldNotebookSupplyTypes.find(
                (supplyType) => supplyType.supplyTypeId === fieldNotebookSupplyDetails.supplyType
            );
            if (matchedSupplyType) {
                setShowNPK(matchedSupplyType.useNPK);
                showNPK = matchedSupplyType.useNPK;
                // setForPest([
                //     { occurenceCat: "", occurence: "" }
                // ]);
            }
            fieldNotebookSupplyDetails.showNPK = showNPK;
            setFieldNotebookSupplyFormData(fieldNotebookSupplyDetails);
        };

        if (location.state) {
            if (!location.state.edit) {
                setDisableFields(true);
            }
            if (fieldNotebookSupplyTypes && fieldNotebookSupplyTypes.length > 0) {
                fetchAndSetFieldNotebookSupply(location.state.id);
            }
        }
    }, [location.state, fieldNotebookSupplyTypes]);

    const modifyShowNPK = (value) => {
        let showNPK = false;
        const matchedSupplyType = fieldNotebookSupplyTypes.find(
            (supplyType) => supplyType.supplyTypeId === value
        );
        if (matchedSupplyType) {
            setShowNPK(matchedSupplyType.useNPK);
            showNPK = matchedSupplyType.useNPK;
        }
        return showNPK;
    };

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        if (name === SUPPLY_TYPE_FIELD_NAME) {
            let showNPK = modifyShowNPK(value);
            setFieldNotebookSupplyFormData((prevFormData) => ({
                ...prevFormData,
                [name]: type === "checkbox" ? checked : value,
                showNPK: showNPK,
            }));
        } if (name === NITROGEN_PERC_FIELD_NAME || name === PHOSPHORUS_PERC_FIELD_NAME || name === POTASSIUM_PERC_FIELD_NAME) {
            if (REGEX_NUMBER_WITH_TWO_DECIMAL_PLACESS.test(value)) {
                setErrors(prevErr => ({
                    ...prevErr,
                    [name]: ''
                }));
                setFieldNotebookSupplyFormData((prevFormData) => ({
                    ...prevFormData,
                    [name]: type === "checkbox" ? checked : value,
                }));
            } else {
                setErrors(prevErr => ({
                    ...prevErr,
                    [name]: ONLY_NUMBER_AND_DECIMAL_ALLOWED
                }));
            }
        } else {
            setFieldNotebookSupplyFormData((prevFormData) => ({
                ...prevFormData,
                [name]: type === "checkbox" ? checked : value,
            }));
        }
    };

    const handleClear = (field) => {
        setFieldNotebookSupplyFormData((prevFormData) => ({
            ...prevFormData,
            [field]: "",
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);
            await fieldNotebookSupplySchema.validate(fieldNotebookSupplyFormData, { abortEarly: false });
            setErrors({});
            const supplyRegistrationPayload = {
                fieldNotebookSupplyId: fieldNotebookSupplyFormData.supplyId,
                externalCode: fieldNotebookSupplyFormData.externalCode,
                supplyDescription: fieldNotebookSupplyFormData.supplyDescription,
                unitOfMeasure: fieldNotebookSupplyFormData.uom,
                nitrogenPerc: fieldNotebookSupplyFormData.nitrogenPerc,
                phosphorunPerc: fieldNotebookSupplyFormData.phosphorusPerc,
                potassiumPerc: fieldNotebookSupplyFormData.potassiumPerc,
                fieldNotebookSupplyTypeId: fieldNotebookSupplyFormData.supplyType,
                status: fieldNotebookSupplyFormData.status,
                createdByUser: user?.batOneId || 323222,
                modifiedByUser: user?.batOneId || 323222,
            };

            const response = await FieldNotebookSupplyService.saveFieldNotebookSupply(supplyRegistrationPayload);
            setLoading(false);
            if (response.status === 201) {
                navigate("/fieldNotebookSupplyRegistration");
                setSeverity(SUCCESS_SEVERITY_STATUS);
                setMessage(
                    fieldNotebookSupplyFormData.supplyId
                        ? UPDATE_SUCCESS_MSG
                        : ADD_SUCCESS_MSG
                );
                setOpenAlert(true);
            } else {
                console.error("Failed to submit supply details for field notebook!");
                const errorResponse = response?.data?.response;
                setSeverity(ERROR_SEVERITY_STATUS);
                setMessage(errorResponse ? errorResponse.message : SUBMISSION_FAILED);
                setOpenAlert(true);
            }
        } catch (err) {
            setLoading(false);
            if (err instanceof Yup.ValidationError) {
                let newError = {};
                err?.inner?.forEach((err) => {
                    newError[err.path] = err.message;
                });
                setErrors(newError);
            } else {
                const errorData = err.response.data;
                console.error("An error occurred while submitting supply details for field notebook:", err);
                setSeverity(ERROR_SEVERITY_STATUS);
                setMessage(errorData ? errorData.message : SUBMISSION_FAILED);
                setOpenAlert(true);
            }
        }
    };

    return (
        <Layout title={ADD_AGRICULTURAL_SUPPLY_PAGE_HEADING} >
            <Box
                sx={{
                    minHeight: 180,
                    flexGrow: 1,
                    width: "100%",
                    "& .MuiTextField-root": {
                        m: 1,
                        color: "green !important",
                        width: "25ch",
                    },
                    "& .MuiFormLabel-root": {
                        color: "rgba(16, 42, 98, 1)",
                        "& .MuiFormLabel-asterisk": {
                            color: "rgba(239, 0, 0, 1)",
                            fontSize: "18px",
                        },
                        "& .Mui-focused": {
                            color: "red !important",
                        },
                    },
                }}
            >
                <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "2rem",
                            marginBottom: "1.5rem",
                        }}
                    >
                        <Button
                            style={{
                                backgroundColor: "#737D86",
                                padding: " 6px, 16px, 6px, 16px",
                                borderRadius: 100,
                            }}
                            startIcon={
                                <img
                                    src={disableFields ? arrowLeft : closeIconWhite}
                                    alt="Close"
                                />
                            }
                            variant="contained"
                            type="secondary"
                            disabled={loading}
                            onClick={() => {
                                navigate("/fieldNotebookSupplyRegistration");
                            }}
                        >
                            {loading && <CircularProgress size={20} />}
                            {disableFields ? BACK_BTN_LABEL : CANCEL_BTN_LABEL}
                        </Button>
                        {!disableFields && (
                            <Button
                                sx={{
                                    backgroundColor: "#004F9F",
                                    padding: " 6px, 16px, 6px, 11px",
                                    borderRadius: 100,
                                    color: "white",
                                }}
                                startIcon={<img src={downloadIcon} alt="Download Icon" />}
                                type="submit"
                                variant="contained"
                                disabled={loading}
                            >
                                {loading && <CircularProgress size={20} />}
                                {location.state ? UPDATE_BTN_LABEL : SAVE_BTN_LABEL}
                            </Button>
                        )}
                    </Box>

                    <div id="fieldNotebookSupplyFields">
                        <div id="fieldNotebookSupplyBasicFields">
                            <TextField
                                key={SUPPLY_DESCRIPTION_FIELD_NAME}
                                value={fieldNotebookSupplyFormData.supplyDescription || ""}
                                onChange={handleChange}
                                name={SUPPLY_DESCRIPTION_FIELD_NAME}
                                error={!!errors.supplyDescription}
                                helperText={errors.supplyDescription}
                                margin="normal"
                                label={SUPPLY_DESCRIPTION_FIELD_LABEL}
                                required
                                variant="outlined"
                                disabled={disableFields}
                                style={{ width: "31%" }}
                                sx={{
                                    borderRadius: "2px",
                                    "& .MuiOutlinedInput-root": {
                                        "&.MuiOutlinedInput-root.Mui-disabled": {
                                            "& fieldset": {
                                                borderColor: "gray",
                                                borderWidth: "1px",
                                            },
                                            "&:hover fieldset": {
                                                borderColor: "gray",
                                                borderWidth: "1px",
                                            },
                                        },
                                    },
                                    "& .MuiInputLabel-root": {
                                        "&.Mui-disabled": {
                                            color: "#102a62",
                                        },
                                    },
                                }}
                            />

                            <TextField
                                key={UOM_FIELD_NAME}
                                select
                                label={UOM_FIELD_LABEL}
                                value={fieldNotebookSupplyFormData.uom || ""}
                                onChange={handleChange}
                                name={UOM_FIELD_NAME}
                                error={!!errors.uom}
                                helperText={errors.uom}
                                margin="normal"
                                variant="outlined"
                                required
                                disabled={disableFields}
                                style={{ width: "16%" }}
                                SelectProps={{
                                    IconComponent: ArrowDropDownIcon,
                                    MenuProps: {
                                        PaperProps: {
                                            style: {
                                                maxHeight: 300,
                                            },
                                        },
                                    },
                                }}
                                InputProps={{
                                    endAdornment: (!disableFields && fieldNotebookSupplyFormData.uom) && (
                                        <CloseIcon
                                            onClick={() => handleClear(UOM_FIELD_NAME)}
                                            style={{
                                                cursor: "pointer",
                                                marginLeft: "auto",
                                                paddingRight: "20px",
                                            }}
                                        />
                                    ),
                                }}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        "&.MuiOutlinedInput-root.Mui-disabled": {
                                            "& fieldset": {
                                                borderColor: "gray",
                                                borderWidth: "1px",
                                            },
                                            "&:hover fieldset": {
                                                borderColor: "gray",
                                                borderWidth: "1px",
                                            },
                                        },
                                    },
                                    "& .MuiInputLabel-root": {
                                        "&.Mui-disabled": {
                                            color: "#102a62",
                                        },
                                    },
                                }}
                            >
                                {uomLovDetails.map((uomLov) => (
                                    <MenuItem
                                        key={uomLov.pickListItemCode}
                                        value={uomLov.pickListItemCode}
                                    >
                                        {uomLov.pickListDescription + " (" + uomLov.pickListItemCode + ")"}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <TextField
                                key={SUPPLY_TYPE_FIELD_NAME}
                                select
                                label={SUPPLY_TYPE_FIELD_LABEL}
                                value={fieldNotebookSupplyFormData.supplyType || ""}
                                onChange={handleChange}
                                name={SUPPLY_TYPE_FIELD_NAME}
                                error={!!errors.supplyType}
                                helperText={errors.supplyType}
                                margin="normal"
                                variant="outlined"
                                required
                                disabled={disableFields}
                                style={{ width: "36%" }}
                                SelectProps={{
                                    IconComponent: ArrowDropDownIcon,
                                    MenuProps: {
                                        PaperProps: {
                                            style: {
                                                maxHeight: 300,
                                            },
                                        },
                                    },
                                }}
                                InputProps={{
                                    endAdornment: (!disableFields && fieldNotebookSupplyFormData.supplyType) && (
                                        <CloseIcon
                                            onClick={() => handleClear(SUPPLY_TYPE_FIELD_NAME)}
                                            style={{
                                                cursor: "pointer",
                                                marginLeft: "auto",
                                                paddingRight: "20px",
                                            }}
                                        />
                                    ),
                                }}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        "&.MuiOutlinedInput-root.Mui-disabled": {
                                            "& fieldset": {
                                                borderColor: "gray",
                                                borderWidth: "1px",
                                            },
                                            "&:hover fieldset": {
                                                borderColor: "gray",
                                                borderWidth: "1px",
                                            },
                                        },
                                    },
                                    "& .MuiInputLabel-root": {
                                        "&.Mui-disabled": {
                                            color: "#102a62",
                                        },
                                    },
                                }}
                            >
                                {fieldNotebookSupplyTypes.map((supplyType) => (
                                    <MenuItem
                                        key={supplyType.supplyTypeId}
                                        value={supplyType.supplyTypeId}
                                    >
                                        {supplyType.supplyTypeDescription}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <FormControl component="fieldset">
                                <FormLabel
                                    sx={{
                                        color: "#0E2B63",
                                        mb: 1.5,
                                        ml: 2,
                                        "&.Mui-focused": { color: "#0E2B63" },
                                        "&:hover": { color: "#0E2B63" },
                                    }}
                                >
                                    {STATUS_FIELD_LABEL}
                                </FormLabel>
                                <FormControlLabel
                                    key={STATUS_FIELD_NAME}
                                    control={
                                        <RedSwitch
                                            checked={fieldNotebookSupplyFormData.status}
                                            onChange={handleChange}
                                            disabled={disableFields}
                                            name={STATUS_FIELD_NAME}
                                            inputProps={{ "aria-label": "controlled" }}
                                        />
                                    }
                                    label={
                                        fieldNotebookSupplyFormData.status ? ACTIVE_FIELD_LABEL : INACTIVE_FIELD_LABEL
                                    }
                                    labelPlacement="start"
                                    sx={{ ml: 2 }}
                                />
                            </FormControl>
                        </div>

                        {showNPK && (
                            <div id="npkDetails">
                                <TextField
                                    key={NITROGEN_PERC_FIELD_NAME}
                                    value={fieldNotebookSupplyFormData.nitrogenPerc || ""}
                                    onChange={handleChange}
                                    name={NITROGEN_PERC_FIELD_NAME}
                                    error={!!errors.nitrogenPerc}
                                    helperText={errors.nitrogenPerc}
                                    margin="normal"
                                    label={NITROGEN_PERC_FIELD_LABEL}
                                    required
                                    variant="outlined"
                                    disabled={disableFields}
                                    style={{ width: "14%" }}
                                    sx={{ borderRadius: "2px" }}
                                />
                                <TextField
                                    key={PHOSPHORUS_PERC_FIELD_NAME}
                                    value={fieldNotebookSupplyFormData.phosphorusPerc || ""}
                                    onChange={handleChange}
                                    name={PHOSPHORUS_PERC_FIELD_NAME}
                                    error={!!errors.phosphorusPerc}
                                    helperText={errors.phosphorusPerc}
                                    margin="normal"
                                    label={PHOSPHORUS_PERC_FIELD_LABEL}
                                    required
                                    variant="outlined"
                                    disabled={disableFields}
                                    style={{ width: "14%" }}
                                    sx={{ borderRadius: "2px" }}
                                />
                                <TextField
                                    key={POTASSIUM_PERC_FIELD_NAME}
                                    value={fieldNotebookSupplyFormData.potassiumPerc || ""}
                                    onChange={handleChange}
                                    name={POTASSIUM_PERC_FIELD_NAME}
                                    error={!!errors.potassiumPerc}
                                    helperText={errors.potassiumPerc}
                                    margin="normal"
                                    label={POTASSIUM_PERC_FIELD_LABEL}
                                    required
                                    variant="outlined"
                                    disabled={disableFields}
                                    style={{ width: "14%" }}
                                    sx={{ borderRadius: "2px" }}
                                />
                            </div>
                        )}
                        {/* {forPest.length>0 && (
                            <div>
                                <TextField
                                    key={OCCURENCE_CATEGORY}
                                    // value={fieldNotebookSupplyFormData.nitrogenPerc || ""}
                                    onChange={handleChange}
                                    name={OCCURENCE_CATEGORY}
                                    // error={!!errors.nitrogenPerc}
                                    // helperText={errors.nitrogenPerc}
                                    margin="normal"
                                    label={OCCURENCE_CATEGORY_LABEL}
                                    required
                                    variant="outlined"
                                    disabled={disableFields}
                                    style={{ width: "14%" }}
                                    sx={{ borderRadius: "2px" }}
                                />
                                <TextField
                                    key={OCCURENCE}
                                    // value={fieldNotebookSupplyFormData.phosphorusPerc || ""}
                                    onChange={handleChange}
                                    name={OCCURENCE}
                                    // error={!!errors.phosphorusPerc}
                                    // helperText={errors.phosphorusPerc}
                                    margin="normal"
                                    label={OCCURENCE_LABEL}
                                    required
                                    variant="outlined"
                                    disabled={disableFields}
                                    style={{ width: "14%" }}
                                    sx={{ borderRadius: "2px" }}
                                />
                            </div>
                        )
                        } */}
                    </div>
                </form>
            </Box>
        </Layout>
    );
}
