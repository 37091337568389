import React, { useEffect } from "react";
import Gantt from "frappe-gantt";

const GanttChart = ({ tasks, onDateChange }) => {
  const handleDateChange = (task, start, end) => {
    if (onDateChange) {
      onDateChange(task, start, end);
    }
  };

  useEffect(() => {
    const ganttContainer = document.querySelector("#gantt");

    if (tasks && tasks.length > 0) {
      const validTasks = tasks.filter(
        (task) =>
          task.start &&
          task.end &&
          !isNaN(new Date(task.start)) &&
          !isNaN(new Date(task.end))
      );

      if (validTasks.length > 0) {
        if (ganttContainer) {
          ganttContainer.innerHTML = "";
        }

        const gantt = new Gantt("#gantt", validTasks, {
          view_mode: "Month",
          on_date_change: (task, start, end) => {
            handleDateChange(task, start, end);
          },
        });

        const observer = new ResizeObserver(() => {
          removeExistingTodayLine();
          addTodayLine(gantt, validTasks);
        });

        observer.observe(ganttContainer);

        setTimeout(() => {
          removeExistingTodayLine();
          addTodayLine(gantt, validTasks);
        }, 100);

        return () => {
          observer.disconnect();
        };
      }
    } else {
      if (ganttContainer) {
        ganttContainer.innerHTML = "";
      }
    }
  }, [tasks]);

  const removeExistingTodayLine = () => {
    document
      .querySelectorAll(".today-line, .today-text")
      .forEach((el) => el.remove());
  };

  const addTodayLine = (gantt, validTasks) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const svg = document.querySelector(".gantt .grid");
    if (!svg) return;

    const visibleStartDate = new Date(gantt.gantt_start);
    const visibleEndDate = new Date(gantt.gantt_end);

    if (today < visibleStartDate || today > visibleEndDate) return;

    const chartWidth = svg.getBoundingClientRect().width;
    const chartHeight = svg.getBoundingClientRect().height;
    const daysInView =
      (visibleEndDate - visibleStartDate) / (1000 * 60 * 60 * 24);
    const daysFromStart = (today - visibleStartDate) / (1000 * 60 * 60 * 24);
    const todayPosition = (daysFromStart / daysInView) * chartWidth - 18;

    // Vertical dotted line
    const line = document.createElementNS("http://www.w3.org/2000/svg", "line");
    line.setAttribute("x1", todayPosition);
    line.setAttribute("x2", todayPosition);
    line.setAttribute("y1", "0");
    line.setAttribute("y2", chartHeight - 20); // Stop above the text
    line.setAttribute("stroke", "black");
    line.setAttribute("stroke-dasharray", "4, 4");
    line.classList.add("today-line");

    // Centered "Today" text
    const text = document.createElementNS("http://www.w3.org/2000/svg", "text");
    text.setAttribute("x", todayPosition);
    text.setAttribute("y", chartHeight - 8);
    text.setAttribute("fill", "black");
    text.setAttribute("font-size", "12");
    text.setAttribute("text-anchor", "middle"); // Center the text
    text.classList.add("today-text");
    text.textContent = "Today";

    // Add elements
    svg.appendChild(line);
    svg.appendChild(text);
  };

  return <div id="gantt" />;
};

export default GanttChart;
