// TextInput.jsx
import * as React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


export default function TextInput({
  label = "Input",
  value,
  onChange,
  size = "medium",
  fullWidth = true,
  variant = "outlined",
  placeholder = "Enter text",
  type = "text", // Allow different input types
  disabled = false, // Add disabled prop with default false
  ...otherProps // Spread any other TextField props
}) {
  const handleClearInput = () => {
    // Create a synthetic event object that mimics a standard input change event
    const clearEvent = {
      target: { 
        value: "",
        name: otherProps.name // Preserve the name prop if it exists
      },
      preventDefault: () => {},
      stopPropagation: () => {}
    };
    
    // Call the onChange handler with the clear event
    onChange(clearEvent);
  };

  return (
    <TextField
      label={label}
      value={value}
      onChange={onChange}
      size={size}
      fullWidth={fullWidth}
      variant={variant}
      placeholder={placeholder}
      type={type}
      disabled={disabled} // Pass through disabled prop
      sx={{
        '& .MuiInputLabel-root': {
          color: 'gray',
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: 'gray',
        },
        '& .MuiInputBase-root': {
          height: '46px',
        },
      }}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        endAdornment: (
          value && !disabled && ( // Only show clear button if there's a value and not disabled
            <InputAdornment position="end">
              <IconButton
                aria-label="clear input"
                onClick={handleClearInput}
                edge="end"
                size="small"
                disabled={disabled} // Disable the icon button when TextField is disabled
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          )
        ),
        ...otherProps.InputProps, // allow overriding existing InputProps
      }}
      {...otherProps} // Spread remaining props like sx, helperText, error etc.
    />
  );
}