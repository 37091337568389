export const curingField = [
  {
    name: "curingCode",
    label: "Code",
    type: "text",
    required: true,
    disabled: true,
  },
  {
    name: "curingUnitName",
    label: "Description",
    type: "text",
    required: true,
  },
  {
    name: "tobaccoGroup",
    label: "Tobacco Group",
    type: "select",
    options: [
      { value: "", label: "None" },
      { value: "Flue Cured", label: "Flue Cured" },
      { value: "Air Cured", label: "Air Cured" },
    ],
    required: true,
  },
  {
    name: "linearMeter",
    label: "Linear Meter",
    type: "text",
    required: true,
  },
  {
    name: "capacity",
    label: "Capacity (kg)",
    type: "text",
    required: true,
  },
  {
    name: "newCuringUnit",
    label: "Is New?",
    type: "select",
    options: [
      { value: "", label: "None" },
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ],
    required: true,
  },
  {
    name: "status",
    label: "Status",
    type: "switch",
  },
];
