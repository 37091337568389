import React, { useContext, useEffect, useState } from "react";
import { Tab, Tabs, Typography } from "@mui/material";
import Layout from "../../../../components/structure/Layout";
import "./AgriSupply.css";
import AgriSupplyList from "./AgriSupplyList";
import AgriSupplyForm from "./AgriSupplyRegistartion";
import AsrService from "services/AsrService";
import { SnackbarContext } from "context/snackBarContext";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Typography component="div" style={{ padding: 8 }}>
          {children}
        </Typography>
      )}
    </div>
  );
}

function AgriSupplyContainer() {
  const [agriSupplyData, setAgriSupplyData] = useState([]);
  // const [agriSupplyData, setAgriSupplyData] = useState(
  //     [
  //         {
  //             "createdByUser": "Vishal",
  //             "modifiedDateTimeUTC": "2024-06-05T08:34:10.063Z",
  //             "modifiedByUser": "Vishal",
  //             "asrSupplyId": "0a00021b-8fae-1b0d-818f-aeabde7e0000",
  //             "asrSupplyName": "Plastic guide 250",
  //             "asrCode": 34124,
  //             "asrExternalCode": 11221133,
  //             "asrUnlockModality": "Normal",
  //             "asrUnitOfMeasurement": "KG",
  //             "asrTarget": "Yes",
  //             "asrRecommendationType": "Hectare(He)",
  //             "asrRecommendation": 0,
  //             "asrMaxPacking": 0,
  //             "asrSupplyType": "Urea",
  //             "asrThirdPartyAgriculturalSupply": false,
  //             "countryId": "5d68bc83-3635-4a2b-a0df-1199b0b75c6e",
  //             "languageId": "960d7994-5b31-4e5b-8d05-c6d58d918229"
  //         },
  //         {
  //             "createdByUser": "Vishal",
  //             "modifiedDateTimeUTC": "2024-06-05T07:48:21.487Z",
  //             "modifiedByUser": "Vishal",
  //             "asrSupplyId": "0a00021b-8fae-1b0d-818f-aead66440001",
  //             "asrSupplyName": "Urea",
  //             "asrCode": 34125,
  //             "asrExternalCode": 11221143,
  //             "asrUnlockModality": "Normal",
  //             "asrUnitOfMeasurement": "UN",
  //             "asrTarget": "Yes",
  //             "asrRecommendationType": "Hectare(He)",
  //             "asrRecommendation": 0,
  //             "asrMaxPacking": 0,
  //             "asrSupplyType": "Urea",
  //             "asrThirdPartyAgriculturalSupply": false,
  //             "countryId": "5d68bc83-3635-4a2b-a0df-1199b0b75c6e",
  //             "languageId": "960d7994-5b31-4e5b-8d05-c6d58d918229"
  //         },
  //         {
  //             "createdByUser": "Vishal",
  //             "modifiedDateTimeUTC": "2024-06-05T07:48:21.487Z",
  //             "modifiedByUser": "Vishal",
  //             "asrSupplyId": "0a00021b-8fae-1b0d-818f-aead66440001",
  //             "asrSupplyName": "Urea",
  //             "asrCode": 34125,
  //             "asrExternalCode": 11221143,
  //             "asrUnlockModality": "Normal",
  //             "asrUnitOfMeasurement": "UN",
  //             "asrTarget": "Yes",
  //             "asrRecommendationType": "Hectare(He)",
  //             "asrRecommendation": 0,
  //             "asrMaxPacking": 0,
  //             "asrSupplyType": "Urea",
  //             "asrThirdPartyAgriculturalSupply": false,
  //             "countryId": "5d68bc83-3635-4a2b-a0df-1199b0b75c6e",
  //             "languageId": "960d7994-5b31-4e5b-8d05-c6d58d918229"
  //         },
  //         {
  //             "createdByUser": "Vishal",
  //             "modifiedDateTimeUTC": "2024-06-05T07:48:21.487Z",
  //             "modifiedByUser": "Vishal",
  //             "asrSupplyId": "0a00021b-8fae-1b0d-818f-aead66440001",
  //             "asrSupplyName": "Urea",
  //             "asrCode": 34125,
  //             "asrExternalCode": 11221143,
  //             "asrUnlockModality": "Normal",
  //             "asrUnitOfMeasurement": "UN",
  //             "asrTarget": "Yes",
  //             "asrRecommendationType": "Hectare(He)",
  //             "asrRecommendation": 0,
  //             "asrMaxPacking": 0,
  //             "asrSupplyType": "Urea",
  //             "asrThirdPartyAgriculturalSupply": false,
  //             "countryId": "5d68bc83-3635-4a2b-a0df-1199b0b75c6e",
  //             "languageId": "960d7994-5b31-4e5b-8d05-c6d58d918229"
  //         },
  //         {
  //             "createdByUser": "Vishal",
  //             "modifiedDateTimeUTC": "2024-06-05T07:48:21.487Z",
  //             "modifiedByUser": "Vishal",
  //             "asrSupplyId": "0a00021b-8fae-1b0d-818f-aead66440001",
  //             "asrSupplyName": "Urea",
  //             "asrCode": 34125,
  //             "asrExternalCode": 11221143,
  //             "asrUnlockModality": "Normal",
  //             "asrUnitOfMeasurement": "UN",
  //             "asrTarget": "Yes",
  //             "asrRecommendationType": "Hectare(He)",
  //             "asrRecommendation": 0,
  //             "asrMaxPacking": 0,
  //             "asrSupplyType": "Urea",
  //             "asrThirdPartyAgriculturalSupply": false,
  //             "countryId": "5d68bc83-3635-4a2b-a0df-1199b0b75c6e",
  //             "languageId": "960d7994-5b31-4e5b-8d05-c6d58d918229"
  //         },
  //         {
  //             "createdByUser": "Vishal",
  //             "modifiedDateTimeUTC": "2024-06-05T07:48:21.487Z",
  //             "modifiedByUser": "Vishal",
  //             "asrSupplyId": "0a00021b-8fae-1b0d-818f-aead66440001",
  //             "asrSupplyName": "Urea",
  //             "asrCode": 34125,
  //             "asrExternalCode": 11221143,
  //             "asrUnlockModality": "Normal",
  //             "asrUnitOfMeasurement": "UN",
  //             "asrTarget": "Yes",
  //             "asrRecommendationType": "Hectare(He)",
  //             "asrRecommendation": 0,
  //             "asrMaxPacking": 0,
  //             "asrSupplyType": "Urea",
  //             "asrThirdPartyAgriculturalSupply": false,
  //             "countryId": "5d68bc83-3635-4a2b-a0df-1199b0b75c6e",
  //             "languageId": "960d7994-5b31-4e5b-8d05-c6d58d918229"
  //         },
  //         {
  //             "createdByUser": "Vishal",
  //             "modifiedDateTimeUTC": "2024-06-05T07:48:21.487Z",
  //             "modifiedByUser": "Vishal",
  //             "asrSupplyId": "0a00021b-8fae-1b0d-818f-aead66440001",
  //             "asrSupplyName": "Urea",
  //             "asrCode": 34125,
  //             "asrExternalCode": 11221143,
  //             "asrUnlockModality": "Normal",
  //             "asrUnitOfMeasurement": "UN",
  //             "asrTarget": "Yes",
  //             "asrRecommendationType": "Hectare(He)",
  //             "asrRecommendation": 0,
  //             "asrMaxPacking": 0,
  //             "asrSupplyType": "Urea",
  //             "asrThirdPartyAgriculturalSupply": false,
  //             "countryId": "5d68bc83-3635-4a2b-a0df-1199b0b75c6e",
  //             "languageId": "960d7994-5b31-4e5b-8d05-c6d58d918229"
  //         },
  //         {
  //             "createdByUser": "Vishal",
  //             "modifiedDateTimeUTC": "2024-06-05T07:48:21.487Z",
  //             "modifiedByUser": "Vishal",
  //             "asrSupplyId": "0a00021b-8fae-1b0d-818f-aead66440001",
  //             "asrSupplyName": "Urea",
  //             "asrCode": 34125,
  //             "asrExternalCode": 11221143,
  //             "asrUnlockModality": "Normal",
  //             "asrUnitOfMeasurement": "UN",
  //             "asrTarget": "Yes",
  //             "asrRecommendationType": "Hectare(He)",
  //             "asrRecommendation": 0,
  //             "asrMaxPacking": 0,
  //             "asrSupplyType": "Urea",
  //             "asrThirdPartyAgriculturalSupply": false,
  //             "countryId": "5d68bc83-3635-4a2b-a0df-1199b0b75c6e",
  //             "languageId": "960d7994-5b31-4e5b-8d05-c6d58d918229"
  //         }, {
  //             "createdByUser": "Vishal",
  //             "modifiedDateTimeUTC": "2024-06-05T07:48:21.487Z",
  //             "modifiedByUser": "Vishal",
  //             "asrSupplyId": "0a00021b-8fae-1b0d-818f-aead66440001",
  //             "asrSupplyName": "Urea",
  //             "asrCode": 34125,
  //             "asrExternalCode": 11221143,
  //             "asrUnlockModality": "Normal",
  //             "asrUnitOfMeasurement": "UN",
  //             "asrTarget": "Yes",
  //             "asrRecommendationType": "Hectare(He)",
  //             "asrRecommendation": 0,
  //             "asrMaxPacking": 0,
  //             "asrSupplyType": "Urea",
  //             "asrThirdPartyAgriculturalSupply": false,
  //             "countryId": "5d68bc83-3635-4a2b-a0df-1199b0b75c6e",
  //             "languageId": "960d7994-5b31-4e5b-8d05-c6d58d918229"
  //         }
  //     ]
  // );
  const [value, setValue] = React.useState(0);
  const [mode, setMode] = React.useState("Add");
  const [state, setState] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Number of items per page
  const [totalItems, setTotalItems] = useState(5); // Number of items per page
  const { setOpenAlert, setSeverity, setMessage, handleClose } =
    useContext(SnackbarContext);
  // const [queryData, setQueryData] = React.useState({});
  const [filtersApplied, setFiltersApplied] = React.useState(false);

  useEffect(() => {
    callAsrAPI(currentPage, itemsPerPage);
  }, []);

  const callAsrAPI = (pNumber, size) => {
    setLoading(true);
    AsrService.getAsr({ pageNumber: pNumber, size: size })
      .then((res) => {
        setLoading(false);
        setAgriSupplyData(res?.data?.response?.agriculturalSupplies);
        setCurrentPage(pNumber);
        setItemsPerPage(size);
        setTotalItems(res?.data?.response?.totalItems);
      })
      .catch((error) => {
        setAgriSupplyData([]);
        setLoading(false);
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (value == 1) {
      setMode("Add");
    }
  };
  const paginate = (pageNumber, itemsPerPage) => {
    callAsrAPI(pageNumber - 1, itemsPerPage);
  };
  let conditionalProps = {};
  if (value == 0) {
    conditionalProps.itemsPerPage = itemsPerPage;
    conditionalProps.totalItems = totalItems;
    conditionalProps.currentPage = currentPage;
    conditionalProps.paginate = paginate;
  }

  const changeTab = (data) => {
    setValue(data.tab);
    setMode(data.mode);
    setState(data.state);
    if (data.tab == 0) {
      callAsrAPI(currentPage, itemsPerPage);
    }
  };
  const setQueryData = (params) => {
    let allFalse = Object.values(params).every((v) => v === "");
    if (allFalse) {
      setFiltersApplied(false);
      callAsrAPI(currentPage, itemsPerPage);
    } else {
      setFiltersApplied(true);
      callAsrSearchAPI(params);
    }
  };
  const callAsrSearchAPI = (params) => {
    setLoading(true);
    let query = `search=${params.search}&target=${params.target}&thirdParty=${params.thirdParty}`;
    AsrService.searchAsr(query)
      .then((res) => {
        setLoading(false);
        setAgriSupplyData(res?.data?.response?.agriculturalSupplies);
        setCurrentPage(currentPage);
        setItemsPerPage(itemsPerPage);
        setTotalItems(res?.data?.response?.totalItems);
      })
      .catch((error) => {
        setAgriSupplyData([]);
        setLoading(false);
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };
  return (
    <Layout title={"Agricultural Supplies -2024"} {...conditionalProps}>
      <div className={"AgriculturalSupplyContainer"} style={{ flexGrow: 1 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          className="AgriSupplyTabs"
          sx={{
            "& .Mui-selected": {
              color: "#EF7D00 !important",
            },
            "& .MuiTabs-indicator": {
              backgroundColor: "#EF7D00 !important",
            },
            "& .MuiButtonBase-root": {
              display: "flex",
              justifyContent: "space-around",
              minHeight: "unset",
            },
            "& .MuiTabs-scroller": {
              padding: "10px",
            },
          }}
        >
          <Tab label="List" id="tab-0" />
          <Tab label="Registration" id="tab-1" />
          <Tab label="Unlock Supplies" id="tab-2" />
          <Tab label="Unlock Third-Party Supplies" id="tab-3" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <AgriSupplyList
            filtersApplied={filtersApplied}
            setQueryData={(data) => {
              setQueryData(data);
            }}
            agriSupplyData={agriSupplyData}
            changeTab={(data) => changeTab(data)}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AgriSupplyForm
            state={state}
            mode={mode}
            changeTab={(data) => changeTab(data)}
          />
        </TabPanel>
        <TabPanel value={value} index={2}></TabPanel>
        <TabPanel value={value} index={3}></TabPanel>
      </div>
    </Layout>
  );
}

export default AgriSupplyContainer;
