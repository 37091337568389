import { http } from "../http-common";

const getCountriesList = () => {
    return http.get(`/countries`);
  };



const FetchCountry = {
  getCountriesList
};

export default FetchCountry;
