import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Button, FormControl, MenuItem, Select, Grid, CircularProgress, Box, TextField } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import editButton from "../../../assets/editView.svg";
import Layout from "components/structure/Layout";
import { useLocation, useNavigate } from "react-router-dom";
import Service from "../../../services/CropStageCalander";
import CropStageCalendarService from "../../../services/CropStageCalander";
import { SnackbarContext } from "context/snackBarContext";
import { AuthData } from "auth/AuthWarpper";
import EditButton from "utils/EditButton";
import { formatDate } from "utils/utils";
import DataTable from "utils/DataTable/DataTable";
import './CropStageCalendar.css'
import SeasonRegistrationService from "services/SeasonRegistrationService";
import NoData from "assets/noData.svg"

const RegistrationOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Heading = styled.div`
  color: rgba(115, 125, 134, 1);
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
`;
const DataContainer = styled.div`
`;


const CropStageCalendar = () => {
  const location = useLocation();
  const { state } = location;
  const [tableVisible, setTableVisible] = useState(false);
  const [cropYear, setCropYear] = useState(state?.cropYear ? state.cropYear : "default");
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = React.useState(false);
  // const [totalRowCount, setTotalRowCount] = useState(0);
  // const [paginationModel, setPaginationModel] = useState({
  //   page: 0,
  //   pageSize: 10,
  // });
  const [cropYears, setCropYears] = useState([]);

  const navigate = useNavigate();
  const {
    severity,
    message,
    openAlert,
    setOpenAlert,
    setSeverity,
    setMessage,
  } = React.useContext(SnackbarContext);
  const { user } = AuthData()
  const columns = [
    {
      field: "countryName",
      headerName: "Country",
      // width: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "cropYear",
      headerName: "Crop Year",
      // width: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "seasonCode",
      headerName: "Season Code",
      // width: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "seasonName",
      headerName: "Season Name",
      // width: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "cropStageName",
      headerName: "Crop Stage",
      // width: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "StartFormatDate",
      headerName: "Start Date",
      // width: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "EndFormatDate",
      headerName: "End Date",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "activeInd",
      headerName: "Status",
      renderCell: (params) => (
        <span> {JSON.stringify(params?.row?.activeInd) == "true" ? "Active" : "Inactive"}</span>
      ),
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerAlign: "center",
      flex: 1,
      align: "center",
      getActions: (params) => [
        <EditButton state={params.row} url={"/editCropStageCalendar"} />
      ],
    },
  ];
  useEffect(() => {
    if (cropYear) fetchCropStageCalendar()
  }, [])

  useEffect(() => {
    SeasonRegistrationService.getCropYears()
      .then((response) => {
        const cropYearsFromApi = response.data.response.map(
          (item) => item.cropYear
        );
        setCropYears(cropYearsFromApi);
      })
      .catch((error) => console.error("Error fetching data:", error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCropStageCalendar = () => {
    if (cropYear == "default") return
    setLoading(true);
    setRows([])
    const query = `?cropYear=${cropYear}&countryCode=${user?.countryCode ? user?.countryCode : ""}`
    CropStageCalendarService.getCropStageCalendarList(query).then((res) => {
      setLoading(false);
      let data = res?.data?.response;
      let total = res?.data?.response?.totalItems
      data?.forEach((ele, index) => {
        ele.id = index;
        ele.StartFormatDate = formatDate(ele?.startDate)
        ele.EndFormatDate = formatDate(ele?.endDate)
      })
      setRows(data)
      // setTotalRowCount(total)
    }).catch((error) => {
      setLoading(false);

      setMessage(error?.response?.data?.message || error?.message)
      setOpenAlert(true)
      setSeverity("error")
    })
  }

  const handleEditClick = (selectedRow) => {
    navigate("/editCropStageCalendar", { state: selectedRow });
  };

  const handleChange = (event) => {
    setTableVisible(false);
    setCropYear(event.target.value);
  };

  const handleButtonClick = async () => {
    try {
      setLoading(true);

      const response = await Service?.getCropStageList(`cropYear=${cropYear}&countryCode=BRA`);
      if (response?.data) {
        setLoading(false);
        setRows(response.data.map(row => ({
          ...row,
          startDate: convertDateFormat(row.startDate),
          endDate: convertDateFormat(row.endDate),
        })));
        setTableVisible(true);

      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
    setTableVisible(true);
  };

  function convertDateFormat(dateString) {
    if (!dateString) {
      return '';
    }

    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date format: " + dateString);
    }

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
  }

  return (
    <Layout title="Crop Stage Calendar">
      <div className="CropStageCalendarContainer">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={8} sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ marginLeft: "1rem", marginTop: 2 }}>
              <Heading> Crop Stage Calendar</Heading>
            </Box>
          </Grid>
          {/* <Grid item xs={4}>
          <Box
            sx={{ display: "flex", alignItems: "center", marginLeft: "1rem" }}
          >
            <InputLabel sx={{ margin: 0 }} htmlFor="my-input">
              Last Modified By:
            </InputLabel>
            <Typography variant="body1">
              {" "}
              &nbsp;{state?.modifiedByUser}
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", marginLeft: "1rem" }}
          >
            <InputLabel sx={{ margin: 0 }} htmlFor="my-input">
              Last Modified Date:
            </InputLabel>
            <Typography variant="body1"> &nbsp;{formatDateDetails(state?.modifiedDateTimeUTC)}</Typography>
          </Box>
        </Grid> */}
        </Grid>
        <div>
          <>
            <RegistrationOptions>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6} xl={6}>
                  <FormControl sx={{ m: 1, minWidth: '200px' }}>
                    <TextField
                      select
                      value={cropYear}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    // label={year ? "" : "Crop Year"}
                    >
                      <MenuItem value={"default"}>Crop Year</MenuItem>
                      {cropYears.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>

                  <Button
                    sx={{
                      borderRadius: 4,
                      minWidth: 100,
                      minHeight: 50,
                      background: "#102A62",
                      marginTop: "13px",
                      marginLeft: "10px",
                    }}
                    variant="contained"
                    onClick={() => fetchCropStageCalendar()}
                    disabled={loading}
                  >
                    {loading && <CircularProgress size={20} />}
                    Get
                  </Button>
                </Grid>
              </Grid>
            </RegistrationOptions>
            {loading ? "" : <>
              {rows && rows.length > 0 ?
                <DataContainer>
                  <DataTable
                    rows={rows} columns={columns} />
                </DataContainer>
                :
                <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                  <img src={NoData} style={{ height: "100px" }} alt="No Data" />
                  <p>
                    No Data Available
                  </p>
                </Box>
              }
            </>
            }
          </>
        </div>
      </div>
    </Layout>
  );
};

export default CropStageCalendar;
