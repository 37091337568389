import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  CircularProgress,
  Typography,
  Modal,
  MenuItem,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import * as yup from "yup";
import Layout from "components/structure/Layout";
import {
  soilParameterSchema,
  transportAddSchema,
  transportAddSchemaLegal,
} from "utils/validations";
import { SnackbarContext } from "context/snackBarContext";
import arrowLeft from "assets/arrowLeft.svg";
import Save from "assets/SaveNew.svg";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import TransporterRegistrationService from "services/TransporterRegistrationService";
import { useTranslation } from "react-i18next";

export default function AddTransporterRegistration() {
  const navigate = useNavigate();
  const location = useLocation();
  const { countryId, languageId, isDelete } = location?.state?.row || {};
  const { edit } = location.state || {};
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [values, setValues] = useState({
    businessType: "Individual",
    countryId: countryId || "",
    languageId: languageId || "",
    isDelete: isDelete === true ? true : false,
    regionId: "",
    bankRegistrationId: "",
    bankCode: "",
    bankBranchRegistrationId: "",
  });
  console.log("🚀 ~ AddTransporterRegistration ~ values:", values);

  const [errors, setErrors] = useState({});
  console.log("err", errors);
  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [dataType, setDataType] = useState([]);
  const [open, setOpen] = useState(false);
  const [nationalities, setNationalities] = useState([]);
  const [maritalStatuses, setMaritalStatuses] = useState([]);
  const [regions, setRegions] = useState([]);
  const [banks, setBanks] = useState([]);
  const [branches, setBranches] = useState([]);
  const { t } = useTranslation();

  // Function to open the modal
  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  // Function when user confirms "Yes" on the modal
  const handleDeleteConfirm = () => {
    setOpen(false);
    navigate("/transporterRegistration");
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    setValues((prevValues) => {
      // If the changed field is bankRegistrationId
      if (name === "bankRegistrationId") {
        const selectedBank = banks.find(
          (bank) => bank.bankRegistrationId === value
        );
        // Fetch branches for the selected bank
        fetchBranchDetails(value); // Call the function to fetch branches
        return {
          ...prevValues,
          [name]: value,
          bankCode: selectedBank ? selectedBank.bankCode : "",
          bankBranchRegistrationId: "", // Reset branch selection when bank changes
        };
      }

      // For all other fields, update normally
      return {
        ...prevValues,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  // Add function to fetch branch details
  const fetchBranchDetails = async (bankRegistrationId) => {
    try {
      const response =
        await TransporterRegistrationService.getBankBranchDetails(
          bankRegistrationId
        );
      setBranches(response.data.response || []);
    } catch (error) {
      console.error("Error fetching branch details:", error);
      setMessage("Failed to fetch bank branch details");
      setSeverity("error");
      setOpenAlert(true);
      setBranches([]); // Reset branches on error
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch all dropdown data in parallel
        const [
          nationalityResponse,
          maritalStatusResponse,
          regionsResponse,
          banksResponse,
        ] = await Promise.all([
          TransporterRegistrationService.getNationality(),
          TransporterRegistrationService.getmaritalStatus(),
          TransporterRegistrationService.getRegions(),
          TransporterRegistrationService.getBankDetails(),
        ]);

        setNationalities(nationalityResponse.data.response || []);
        setMaritalStatuses(maritalStatusResponse.data.response || []);
        setRegions(regionsResponse.data.response || []);
        setBanks(banksResponse.data.response || []);
      } catch (error) {
        console.error("Error fetching data:", error);
        setMessage("Failed to fetch data");
        setSeverity("error");
        setOpenAlert(true);
      }
    };

    fetchData();
  }, []);

  // const handleSubmit = async () => {
  //   try {
  //     setLoading(true);

  //     // Find the selected region to get its state code
  //     const selectedRegion = regions.find(
  //       (region) => region.regionId === values.regionId
  //     );
  //     if (!selectedRegion) {
  //       throw new Error("Please select a valid region");
  //     }

  //     // Create payload based on business type
  //     const payload = {
  //       createdDateTimeUTC: new Date().toISOString(),
  //       createdByUser: "Mayur",
  //       modifiedDateTimeUTC: new Date().toISOString(),
  //       modifiedByUser: "Mayur",
  //       businessType: values.businessType === "Individual" ? "I" : "L",
  //       regionId: values.regionId,
  //       nationalityId: values.nationalityId,
  //       maritalStatusId: values.maritalStatusId,
  //       CityId: "36B02252-E534-4059-AA7E-197220870449",
  //       stateRegistrationType: selectedRegion.stateCode, // Using stateCode from the selected region
  //       ftResponsibleUID: "FCB16587-9B80-4104-8B76-27D6A19DDD48",
  //       tmResponsibleUID: "0AF4024D-8FC8-1E86-818F-C9563F3F0000",
  //       transporterName: values.transporterName,
  //       stateRegistrationNumber: values.stateRegistrationNumber,
  //       transporterLicenseNumber: values.transporterLicenseNumber,
  //       buyingStation: values.buyingStation,
  //       buyingStationRoute: values.buyingStationRoute,
  //       supplyInvoicingUnit: values.supplyInvoicingUnit,
  //       supplyInvoicingUnitRoute: values.supplyInvoicingUnitRoute,
  //       bankAccountType: values.bankAccountType,
  //       bankRegistrationId: values.bankRegistrationId,
  //       bankBranchRegistrationID: values.bankBranchRegistrationId,
  //       accountNumber: values.accountNumber,
  //     };

  //     // Add specific fields based on business type
  //     if (values.businessType === "Individual") {
  //       payload.fiscalID = values.fiscalID;
  //     } else {
  //       payload.businessNumber = values.businessNumber;
  //     }

  //     const response =
  //       await TransporterRegistrationService.createTransporterRegistration(
  //         payload
  //       );

  //     if (response.status === 200 || response.status === 201) {
  //       setMessage("Transporter registration created successfully");
  //       setSeverity("success");
  //       setOpenAlert(true);
  //       navigate("/transporterRegistration");
  //     }
  //   } catch (error) {
  //     console.error("Error creating transporter registration:", error);
  //     setMessage(error.message || "Failed to create transporter registration");
  //     setSeverity("error");
  //     setOpenAlert(true);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const transportAddSchema = yup.object().shape({
    fiscalID: yup.string().trim().required("Fiscal Id is required"),
    //   .matches(/^\d+$/, "Fiscal Id must contain only digits")
    //   .test("cpf-valid", "Invalid Fiscal Id", (value) => {
    //     if (!value) return false;

    //     const cleanCPF = value.replace(/\D/g, "");
    //     if (cleanCPF.length !== 11) return false;
    //     if (/^(\d)\1{10}$/.test(cleanCPF)) return false;

    //     let sum = 0;
    //     for (let i = 0; i < 9; i++) {
    //       sum += parseInt(cleanCPF.charAt(i)) * (10 - i);
    //     }
    //     let remainder = sum % 11;
    //     let firstCheckDigit = remainder < 2 ? 0 : 11 - remainder;

    //     sum = 0;
    //     for (let i = 0; i < 10; i++) {
    //       sum += parseInt(cleanCPF.charAt(i)) * (11 - i);
    //     }
    //     remainder = sum % 11;
    //     let secondCheckDigit = remainder < 2 ? 0 : 11 - remainder;

    //     return (
    //       firstCheckDigit === parseInt(cleanCPF.charAt(9)) &&
    //       secondCheckDigit === parseInt(cleanCPF.charAt(10))
    //     );
    //   }),

    transporterName: yup
      .string()
      .trim()
      .required("Transporter Name is required"),
    nationalityId: yup.string().trim().required("Nationality is required"),
    maritalStatusId: yup.string().trim().required("Marital is required"),
    regionId: yup.string().trim().required("Region is required"),
    stateRegistrationNumber: yup
      .string()
      .trim()
      .required("State Reg is required"),
    transporterLicenseNumber: yup
      .string()
      .trim()
      .matches(
        /^[A-Za-z0-9]+$/,
        "Transporter License Number can only contain alphanumeric characters"
      )
      .required("RNTRC is required"),
    buyingStation: yup.string().trim().required("Buying Station is required"),
    buyingStationRoute: yup
      .string()
      .trim()
      .required("Buying Station Route is required"),
    supplyInvoicingUnit: yup
      .string()
      .trim()
      .required("Supply Invoicing Unit is required"),
    supplyInvoicingUnitRoute: yup
      .string()
      .trim()
      .required("Supply Invoicing Unit Route is required"),
    bankAccountType: yup
      .string()
      .trim()
      .required("Bank Account Type is required"),
    accountNumber: yup
      .string()
      .trim()
      .matches(/^\d+$/, "Account Number must be a number")
      .required("Account Number is required"),
    bankRegistrationId: yup.string().trim().required("Bank is required"),
    bankBranchRegistrationId: yup
      .string()
      .trim()
      .required("Branch Number is required"),
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await transportAddSchema.validate(values, { abortEarly: false });
      setErrors({});
      const selectedRegion = regions.find(
        (region) => region.regionId === values.regionId
      );
      if (!selectedRegion) {
        throw new Error("Please select a valid region");
      }
      const payload = {
        createdDateTimeUTC: new Date().toISOString(),
        createdByUser: "Mayur",
        modifiedDateTimeUTC: new Date().toISOString(),
        modifiedByUser: "Mayur",
        businessType: values.businessType === "Individual" ? "I" : "L",
        regionId: values.regionId,
        nationalityId: values.nationalityId,
        maritalStatusId: values.maritalStatusId,
        CityId: "",
        stateRegistrationType: selectedRegion.stateCode, // Using stateCode from the selected region
        ftResponsibleUID: "",
        tmResponsibleUID: "",
        transporterName: values.transporterName,
        stateRegistrationNumber: values.stateRegistrationNumber,
        transporterLicenseNumber: values.transporterLicenseNumber,
        buyingStation: values.buyingStation,
        buyingStationRoute: values.buyingStationRoute,
        supplyInvoicingUnit: values.supplyInvoicingUnit,
        supplyInvoicingUnitRoute: values.supplyInvoicingUnitRoute,
        bankAccountType: values.bankAccountType,
        bankRegistrationId: values.bankRegistrationId,
        bankBranchRegistrationID: values.bankBranchRegistrationId,
        accountNumber: values.accountNumber,
      };

      if (values.businessType === "Individual") {
        payload.fiscalID = values.fiscalID;
      } else {
        payload.businessNumber = values.businessNumber;
      }
      setLoading(true);
      const response =
        await TransporterRegistrationService.createTransporterRegistration(
          payload
        );
      setLoading(false);
      if (response.status === 200 || response.status === 201) {
        setMessage("Transporter registration created successfully");
        setSeverity("success");
        setOpenAlert(true);
        navigate("/transporterRegistration");
      } else {
        console.error("Failed to submit form");
        setSeverity("error");
        setMessage(t("snackbarMessage.submissionFailed"));
        setOpenAlert(true);
      }
    } catch (err) {
      setLoading(false);
      if (err instanceof yup.ValidationError) {
        let newError = {};
        err?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setErrors(newError);
      } else {
        console.error("An error occurred while submitting the form:", err);
        setSeverity("error");
        setMessage(
          err?.response?.data?.message ||
            "An error occurred while submitting the form"
        );
        setOpenAlert(true);
      }
    }
  };

  const handleSubmitLegalEntity = async (event) => {
    event.preventDefault();
    try {
      await transportAddSchemaLegal.validate(values, { abortEarly: false });
      setErrors({});
      const selectedRegion = regions.find(
        (region) => region.regionId === values.regionId
      );
      if (!selectedRegion) {
        throw new Error("Please select a valid region");
      }
      const payload = {
        createdDateTimeUTC: new Date().toISOString(),
        createdByUser: "Mayur",
        modifiedDateTimeUTC: new Date().toISOString(),
        modifiedByUser: "Mayur",
        businessType: values.businessType === "Individual" ? "I" : "L",
        regionId: values.regionId,
        // nationalityId: values.nationalityId,
        // maritalStatusId: values.maritalStatusId,
        CityId: "",
        stateRegistrationType: selectedRegion.stateCode, // Using stateCode from the selected region
        ftResponsibleUID: "",
        tmResponsibleUID: "",
        transporterName: values.transporterName,
        stateRegistrationNumber: values.stateRegistrationNumber,
        transporterLicenseNumber: values.transporterLicenseNumber,
        buyingStation: values.buyingStation,
        buyingStationRoute: values.buyingStationRoute,
        supplyInvoicingUnit: values.supplyInvoicingUnit,
        supplyInvoicingUnitRoute: values.supplyInvoicingUnitRoute,
        bankAccountType: values.bankAccountType,
        bankRegistrationId: values.bankRegistrationId,
        bankBranchRegistrationID: values.bankBranchRegistrationId,
        accountNumber: values.accountNumber,
      };

      if (values.businessType === "Individual") {
        payload.fiscalID = values.fiscalID;
      } else {
        payload.businessNumber = values.businessNumber;
      }
      setLoading(true);
      const response =
        await TransporterRegistrationService.createTransporterRegistration(
          payload
        );
      setLoading(false);
      if (response.status === 200 || response.status === 201) {
        setMessage("Transporter registration created successfully");
        setSeverity("success");
        setOpenAlert(true);
        navigate("/transporterRegistration");
      } else {
        console.error("Failed to submit form");
        setSeverity("error");
        setMessage(t("snackbarMessage.submissionFailed"));
        setOpenAlert(true);
      }
    } catch (err) {
      setLoading(false);
      if (err instanceof yup.ValidationError) {
        let newError = {};
        err?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setErrors(newError);
      } else {
        console.error("An error occurred while submitting the form:", err);
        setSeverity("error");
        setMessage(
          err?.response?.data?.message ||
            "An error occurred while submitting the form"
        );
        setOpenAlert(true);
      }
    }
  };

  // Function to check if there are any errors in the first box fields
  const hasTransporterDataErrors = () => {
    const relevantFields = [
      "fiscalID",
      "transporterName",
      "nationalityId",
      "maritalStatusId",
      "regionId",
      "stateRegistrationNumber",
      "transporterLicenseNumber",
      "buyingStation",
      "buyingStationRoute",
      "supplyInvoicingUnit",
      "supplyInvoicingUnitRoute",
      "businessNumber",
    ];

    return relevantFields.some((field) => !!errors[field]);
  };

  // Function to check if there are any errors in the bank information fields
  const hasBankInfoErrors = () => {
    const relevantFields = [
      "bankAccountType",
      "bankRegistrationId",
      "bankBranchRegistrationId",
      "accountNumber",
    ];

    return relevantFields.some((field) => !!errors[field]);
  };

  return (
    <Layout
      title={t("TransportationRegistration.sections.title")}
      background="#ffffff"
    >
      <Box
        sx={{
          minHeight: "125vh",
          flexGrow: 1,
          position: "relative",
          paddingBottom: "10rem",
          width: "100%",
          "& .MuiTextField-root": { m: 1 },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
          },
        }}
      >
        <form autoComplete="off" noValidate>
          <Box
            sx={{
              bgcolor: "#EFEFEF",
              height: hasTransporterDataErrors() ? "550px" : "480px",
              padding: "10px",
              borderRadius: "5px",
              transition: "height 200ms ease-in-out",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <ArrowDropDownIcon sx={{ marginRight: 1 }} />
              <Typography>
                {t("TransportationRegistration.filters.transporterData")}
              </Typography>
            </Box>

            <Grid item xs={12} sm={6} md={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend" sx={{ marginLeft: 1 }}>
                  {t("TransportationRegistration.formLabels.businessType")}
                </FormLabel>
                <RadioGroup
                  row
                  sx={{ marginLeft: 1 }}
                  value={values.businessType}
                  defaultValue="Individual"
                >
                  <FormControlLabel
                    value="Individual"
                    onChange={handleChange}
                    name="businessType"
                    control={<Radio />}
                    label={t(
                      "TransportationRegistration.businessTypes.individual"
                    )}
                    checked={values.businessType === "Individual"}
                  />
                  <FormControlLabel
                    value="Legal Entity"
                    onChange={handleChange}
                    name="businessType"
                    control={<Radio />}
                    label={t(
                      "TransportationRegistration.businessTypes.legalEntity"
                    )}
                    checked={values.businessType === "Legal Entity"}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {values.businessType == "Individual" ? (
              <>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                      <TextField
                        name="fiscalID"
                        label={t(
                          "TransportationRegistration.formLabels.fiscalId"
                        )}
                        value={values?.fiscalID}
                        onChange={handleChange}
                        disabled={edit == false}
                        fullWidth
                        error={!!errors.fiscalID} // Show error state
                        helperText={errors.fiscalID || " "} // Show error message
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "7px",
                            minHeight: "50px",
                          },
                          "& .MuiInputLabel-root": {
                            lineHeight: "1.2em",
                          },
                          // "& .MuiFormHelperText-root": {
                          //   margin: 0,
                          //   height: errors.fiscalID ? "20px" : "0px",
                          //   opacity: errors.fiscalID ? 1 : 0,
                          //   transition: "all 200ms",
                          // },
                          // marginBottom: errors.fiscalID ? "20px" : "0px",
                          // transition: "margin 200ms",
                        }}
                        InputProps={{
                          style: { height: "50px" },
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={8.8}>
                    <FormControl fullWidth>
                      <TextField
                        name="transporterName"
                        label={t(
                          "TransportationRegistration.formLabels.transporterName"
                        )}
                        value={values?.sapParameterName}
                        onChange={handleChange}
                        disabled={edit == false}
                        fullWidth
                        error={!!errors.transporterName} // Show error state
                        helperText={errors.transporterName || " "} // Show error message
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "7px",
                            minHeight: "50px",
                          },
                          "& .MuiInputLabel-root": {
                            lineHeight: "1.2em",
                          },
                          // "& .MuiFormHelperText-root": {
                          //   margin: 0,
                          //   height: errors.transporterName ? "20px" : "0px",
                          //   opacity: errors.transporterName ? 1 : 0,
                          //   transition: "all 200ms",
                          // },
                          // marginBottom: errors.transporterName ? "20px" : "0px",
                          // transition: "margin 200ms",
                        }}
                        InputProps={{
                          style: { height: "50px" },
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                      <TextField
                        name="nationalityId"
                        label="Nationality"
                        value={values?.nationalityId || ""}
                        onChange={handleChange}
                        disabled={edit == false}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        error={!!errors.nationalityId}
                        helperText={errors.nationalityId || ""}
                        select
                        SelectProps={{
                          IconComponent: ArrowDropDownIcon,
                          displayEmpty: true, // Ensures the empty value is displayed
                          renderValue: (selected) =>
                            selected
                              ? nationalities.find(
                                  (n) => n.nationalityId === selected
                                )?.nationality
                              : "Select an Option",
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "7px",
                            minHeight: "50px",
                          },
                          "& .MuiInputLabel-root": {
                            lineHeight: "1.2em",
                          },
                          // "& .MuiFormHelperText-root": {
                          //   margin: 0,
                          //   height: errors.nationalityId ? "20px" : "0px",
                          //   opacity: errors.nationalityId ? 1 : 0,
                          //   transition: "all 200ms",
                          // },
                          // marginBottom: errors.nationalityId ? "20px" : "0px",
                          // transition: "margin 200ms",
                        }}
                        InputProps={{
                          style: { height: "50px" },
                        }}
                      >
                        {/* <MenuItem value="" disabled>
                          Select an Option
                        </MenuItem> */}
                        {nationalities.map((nationality) => (
                          <MenuItem
                            key={nationality.nationalityId}
                            value={nationality.nationalityId}
                          >
                            {nationality.nationality}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <FormControl fullWidth>
                      <TextField
                        name="maritalStatusId"
                        label={t(
                          "TransportationRegistration.formLabels.maritalStatus"
                        )}
                        value={values?.maritalStatusId}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                        disabled={edit == false}
                        fullWidth
                        error={!!errors.maritalStatusId}
                        helperText={errors.maritalStatusId || ""}
                        select
                        SelectProps={{
                          IconComponent: ArrowDropDownIcon,
                          displayEmpty: true, // Ensures the placeholder is displayed
                          renderValue: (selected) =>
                            selected
                              ? maritalStatuses.find((status) => status.maritalStatusId === selected)?.maritalStatus
                              : "Select an Option", // Show placeholder only in the field
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "7px",
                            minHeight: "50px",
                          },
                          "& .MuiInputLabel-root": {
                            lineHeight: "1.2em",
                          },
                          // "& .MuiFormHelperText-root": {
                          //   margin: 0,
                          //   height: errors.maritalStatusId ? "20px" : "0px",
                          //   opacity: errors.maritalStatusId ? 1 : 0,
                          //   transition: "all 200ms",
                          // },
                          // marginBottom: errors.maritalStatusId ? "20px" : "0px",
                          // transition: "margin 200ms",
                        }}
                        InputProps={{
                          style: { height: "50px" },
                        }}
                      >
                        {maritalStatuses.map((status) => (
                          <MenuItem
                            key={status.maritalStatusId}
                            value={status.maritalStatusId}
                          >
                            {status.maritalStatus}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <FormControl fullWidth>
                      <TextField
                        name="regionId"
                        label="Region"
                        value={values?.regionId}
                        onChange={handleChange}
                        disabled={edit == false}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        error={!!errors.regionId}
                        helperText={errors.regionId || ""}
                        select
                        SelectProps={{
                          IconComponent: ArrowDropDownIcon,
                          displayEmpty: true, // Ensures the placeholder is displayed
                          renderValue: (selected) =>
                            selected
                              ? regions.find((region) => region.regionId === selected)?.stateCode
                              : "Select an Option", // Show placeholder only in the field
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "7px",
                            minHeight: "50px",
                          },
                          "& .MuiInputLabel-root": {
                            lineHeight: "1.2em",
                          },
                          // "& .MuiFormHelperText-root": {
                          //   margin: 0,
                          //   height: errors.regionId ? "20px" : "0px",
                          //   opacity: errors.regionId ? 1 : 0,
                          //   transition: "all 200ms",
                          // },
                          // marginBottom: errors.regionId ? "20px" : "0px",
                          // transition: "margin 200ms",
                        }}
                        InputProps={{
                          style: { height: "50px" },
                        }}
                      >
                        {regions.map((region) => (
                          <MenuItem
                            key={region.regionId}
                            value={region.regionId}
                          >
                            {region.stateCode}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <FormControl fullWidth>
                      <TextField
                        name="stateRegistrationNumber"
                        label="State Registration Number"
                        value={values?.sapDataType}
                        onChange={handleChange}
                        disabled={edit == false}
                        fullWidth
                        error={!!errors.stateRegistrationNumber} // Show error state
                        helperText={errors.stateRegistrationNumber || ""} // Show error message
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "7px",
                            minHeight: "50px",
                          },
                          "& .MuiInputLabel-root": {
                            lineHeight: "1.2em",
                          },
                          // "& .MuiFormHelperText-root": {
                          //   margin: 0,
                          //   height: errors.stateRegistrationNumber
                          //     ? "20px"
                          //     : "0px",
                          //   opacity: errors.stateRegistrationNumber ? 1 : 0,
                          //   transition: "all 200ms",
                          // },
                          // marginBottom: errors.stateRegistrationNumber
                          //   ? "20px"
                          //   : "0px",
                          // transition: "margin 200ms",
                        }}
                        InputProps={{
                          style: { height: "50px" },
                        }}
                      >
                        {dataType.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2.8}>
                    <FormControl fullWidth>
                      <TextField
                        name="transporterLicenseNumber"
                        label="RNTRC - Transporter License Number"
                        value={values?.sapDataType}
                        onChange={handleChange}
                        disabled={edit == false}
                        fullWidth
                        error={!!errors.transporterLicenseNumber} // Show error state
                        helperText={errors.transporterLicenseNumber || ""} // Show error message
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "7px",
                            minHeight: "50px",
                          },
                          "& .MuiInputLabel-root": {
                            lineHeight: "1.2em",
                          },
                          // "& .MuiFormHelperText-root": {
                          //   margin: 0,
                          //   height: errors.transporterLicenseNumber
                          //     ? "20px"
                          //     : "0px",
                          //   opacity: errors.transporterLicenseNumber ? 1 : 0,
                          //   transition: "all 200ms",
                          // },
                          // marginBottom: errors.transporterLicenseNumber
                          //   ? "20px"
                          //   : "0px",
                          // transition: "margin 200ms",
                        }}
                        InputProps={{
                          style: { height: "50px" },
                        }}
                      >
                        {dataType.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                      <TextField
                        name="businessNumber"
                        label="Business Number"
                        value={values?.sapExternalCode}
                        onChange={handleChange}
                        disabled={edit == false}
                        fullWidth
                        error={!!errors.businessNumber} // Show error state
                        helperText={errors.businessNumber || ""} // Show error message
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "7px",
                            minHeight: "50px",
                          },
                          "& .MuiInputLabel-root": {
                            lineHeight: "1.2em",
                          },
                          // "& .MuiFormHelperText-root": {
                          //   margin: 0,
                          //   height: errors.businessNumber ? "20px" : "0px",
                          //   opacity: errors.businessNumber ? 1 : 0,
                          //   transition: "all 200ms",
                          // },
                          // marginBottom: errors.businessNumber ? "20px" : "0px",
                          // transition: "margin 200ms",
                        }}
                        InputProps={{
                          style: { height: "50px" },
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={8.8}>
                    <FormControl fullWidth>
                      <TextField
                        name="transporterName"
                        label={t(
                          "TransportationRegistration.formLabels.transporterName"
                        )}
                        value={values?.sapParameterName}
                        onChange={handleChange}
                        disabled={edit == false}
                        fullWidth
                        error={!!errors.transporterName} // Show error state
                        helperText={errors.transporterName || ""} // Show error message
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "7px",
                            minHeight: "50px",
                          },
                          "& .MuiInputLabel-root": {
                            lineHeight: "1.2em",
                          },
                          // "& .MuiFormHelperText-root": {
                          //   margin: 0,
                          //   height: errors.transporterName ? "20px" : "0px",
                          //   opacity: errors.transporterName ? 1 : 0,
                          //   transition: "all 200ms",
                          // },
                          // marginBottom: errors.transporterName ? "20px" : "0px",
                          // transition: "margin 200ms",
                        }}
                        InputProps={{
                          style: { height: "50px" },
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6} md={2}>
                    <FormControl fullWidth>
                      <TextField
                        name="regionId"
                        label="Region"
                        value={values?.regionId}
                        onChange={handleChange}
                        disabled={edit == false}
                        fullWidth
                        error={!!errors.regionId}
                        helperText={errors.regionId || ""}
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{
                          IconComponent: ArrowDropDownIcon,
                          displayEmpty: true, // Ensures the placeholder is displayed
                          renderValue: (selected) =>
                            selected
                              ? regions.find((region) => region.regionId === selected)?.stateCode
                              : "Select an Option", // Show placeholder only in the field
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "7px",
                            minHeight: "50px",
                          },
                          "& .MuiInputLabel-root": {
                            lineHeight: "1.2em",
                          },
                          // "& .MuiFormHelperText-root": {
                          //   margin: 0,
                          //   height: errors.regionId ? "20px" : "0px",
                          //   opacity: errors.regionId ? 1 : 0,
                          //   transition: "all 200ms",
                          // },
                          // marginBottom: errors.regionId ? "20px" : "0px",
                          // transition: "margin 200ms",
                        }}
                        InputProps={{
                          style: { height: "50px" },
                        }}
                      >
                        {regions.map((region) => (
                          <MenuItem
                            key={region.regionId}
                            value={region.regionId}
                          >
                            {region.stateCode}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                      <TextField
                        name="stateRegistrationNumber"
                        label="State Registration Number"
                        value={values?.sapExternalCode}
                        onChange={handleChange}
                        disabled={edit == false}
                        fullWidth
                        error={!!errors.stateRegistrationNumber} // Show error state
                        helperText={errors.stateRegistrationNumber || ""} // Show error message
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "7px",
                            minHeight: "50px",
                          },
                          "& .MuiInputLabel-root": {
                            lineHeight: "1.2em",
                          },
                          // "& .MuiFormHelperText-root": {
                          //   margin: 0,
                          //   height: errors.stateRegistrationNumber
                          //     ? "20px"
                          //     : "0px",
                          //   opacity: errors.stateRegistrationNumber ? 1 : 0,
                          //   transition: "all 200ms",
                          // },
                          // marginBottom: errors.stateRegistrationNumber
                          //   ? "20px"
                          //   : "0px",
                          // transition: "margin 200ms",
                        }}
                        InputProps={{
                          style: { height: "50px" },
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3.5}>
                    <FormControl fullWidth>
                      <TextField
                        name="transporterLicenseNumber"
                        label="RNTRC"
                        value={values?.sapParameterName}
                        onChange={handleChange}
                        disabled={edit == false}
                        fullWidth
                        error={!!errors.transporterLicenseNumber} // Show error state
                        helperText={errors.transporterLicenseNumber || ""} // Show error message
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "7px",
                            minHeight: "50px",
                          },
                          "& .MuiInputLabel-root": {
                            lineHeight: "1.2em",
                          },
                          // "& .MuiFormHelperText-root": {
                          //   margin: 0,
                          //   height: errors.transporterLicenseNumber
                          //     ? "20px"
                          //     : "0px",
                          //   opacity: errors.transporterLicenseNumber ? 1 : 0,
                          //   transition: "all 200ms",
                          // },
                          // marginBottom: errors.transporterLicenseNumber
                          //   ? "20px"
                          //   : "0px",
                          // transition: "margin 200ms",
                        }}
                        InputProps={{
                          style: { height: "50px" },
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            )}

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={11.8}>
                <FormControl fullWidth>
                  <TextField
                    name="Route Details"
                    label="Route Details"
                    value={values?.sapDataType}
                    onChange={handleChange}
                    disabled={!edit}
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none", // Removes the border
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none", // Ensures no border on hover
                        },
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                        fontWeight: "bold", // Makes label bold
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <TextField
                    name="buyingStation"
                    label="Buying Station - Route"
                    value={values?.sapDataType}
                    onChange={handleChange}
                    disabled={edit == false}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    error={!!errors.buyingStation} // Show error state
                    helperText={errors.buyingStation || ""} // Show error message
                    select
                    SelectProps={{
                      IconComponent: ArrowDropDownIcon,
                      displayEmpty: true, // Ensures the placeholder is displayed
                      renderValue: (selected) =>
                        selected ? selected : "Select an Option", // Show placeholder only in the field
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                      "& .MuiFormHelperText-root": {
                        margin: 0,
                        height: errors.buyingStation ? "0px" : "0px",
                        opacity: errors.buyingStation ? 1 : 0,
                        transition: "all 200ms",
                      },
                      marginBottom: errors.buyingStation ? "20px" : "0px",
                      transition: "margin 200ms",
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  >
                    <MenuItem value="Posto de Compra de Rio Negro">
                      Posto de Compra de Rio Negro
                    </MenuItem>
                    <MenuItem value="Posto de Compra de Papanduva">
                      Posto de Compra de Papanduva
                    </MenuItem>
                    <MenuItem value="GLT Santa Cruz do Sul">
                      GLT Santa Cruz do Sul
                    </MenuItem>
                    <MenuItem value="Posto de compra de Imbituva">
                      Posto de compra de Imbituva
                    </MenuItem>
                    <MenuItem value="Unidade Rio Negro">
                      Unidade Rio Negro
                    </MenuItem>
                    <MenuItem value="Ararangua">Ararangua</MenuItem>
                    <MenuItem value="Posto de compra de Pouso Redondo">
                      Posto de compra de Pouso Redondo
                    </MenuItem>
                  </TextField>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={7.8}>
                <FormControl fullWidth>
                  <TextField
                    name="buyingStationRoute"
                    label="Buying Station-Route (Main Cities)"
                    value={values?.sapParameterName}
                    onChange={handleChange}
                    disabled={edit == false}
                    fullWidth
                    error={!!errors.buyingStationRoute} // Show error state
                    helperText={errors.buyingStationRoute || ""} // Show error message
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                      "& .MuiFormHelperText-root": {
                        margin: 0,
                        height: errors.buyingStationRoute ? "0px" : "0px",
                        opacity: errors.buyingStationRoute ? 1 : 0,
                        transition: "all 200ms",
                      },
                      marginBottom: errors.buyingStationRoute ? "20px" : "0px",
                      transition: "margin 200ms",
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center" sx={{marginTop:'10px'}}>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <TextField
                    name="supplyInvoicingUnit"
                    label="Supply Invoicing Unit - Route"
                    value={values?.sapExternalCode}
                    onChange={handleChange}
                    disabled={edit == false}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    error={!!errors.supplyInvoicingUnit}
                    helperText={errors.supplyInvoicingUnit || ""}
                    select
                    SelectProps={{
                      IconComponent: ArrowDropDownIcon,
                      displayEmpty: true, // Ensures the placeholder is displayed
                      renderValue: (selected) =>
                        selected ? selected : "Select an Option", // Show placeholder only in the field
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                      "& .MuiFormHelperText-root": {
                        margin: 0,
                        height: errors.supplyInvoicingUnit ? "0px" : "0px",
                        opacity: errors.supplyInvoicingUnit ? 1 : 0,
                        transition: "all 200ms",
                      },
                      marginBottom: errors.supplyInvoicingUnit ? "20px" : "0px",
                      transition: "margin 200ms",
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  >
                    <MenuItem value="Posto de Compra de Rio Negro">
                      Posto de Compra de Rio Negro
                    </MenuItem>
                    <MenuItem value="Posto de Compra de Papanduva">
                      Posto de Compra de Papanduva
                    </MenuItem>
                    <MenuItem value="GLT Santa Cruz do Sul">
                      GLT Santa Cruz do Sul
                    </MenuItem>
                    <MenuItem value="Posto de compra de Imbituva">
                      Posto de compra de Imbituva
                    </MenuItem>
                    <MenuItem value="Unidade Rio Negro">
                      Unidade Rio Negro
                    </MenuItem>
                    <MenuItem value="Ararangua">Ararangua</MenuItem>
                    <MenuItem value="Posto de compra de Pouso Redondo">
                      Posto de compra de Pouso Redondo
                    </MenuItem>
                  </TextField>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={7.8}>
                <FormControl fullWidth>
                  <TextField
                    name="supplyInvoicingUnitRoute"
                    label="Supply Invoicing Unit-Route (Main Cities)"
                    value={values?.sapParameterName}
                    onChange={handleChange}
                    disabled={edit == false}
                    fullWidth
                    error={!!errors.supplyInvoicingUnitRoute} // Show error state
                    helperText={errors.supplyInvoicingUnitRoute || ""} // Show error message
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                      "& .MuiFormHelperText-root": {
                        margin: 0,
                        height: errors.supplyInvoicingUnitRoute
                          ? "0px"
                          : "0px",
                        opacity: errors.supplyInvoicingUnitRoute ? 1 : 0,
                        transition: "all 200ms",
                      },
                      marginBottom: errors.supplyInvoicingUnitRoute
                        ? "20px"
                        : "0px",
                      transition: "margin 200ms",
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          {/* Bank Information */}

          <Box
            sx={{
              bgcolor: "#EFEFEF",
              height: hasBankInfoErrors() ? "130px" : "100px",
              padding: "15px",
              borderRadius: "5px",
              transition: "height 200ms ease-in-out",
              marginTop:'20px'
            }}
          >
            <Box sx={{ display: "flex" }}>
              <ArrowDropDownIcon sx={{ marginRight: 1 }} />
              <Typography>
                {t("TransportationRegistration.sections.bankInformation")}
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  <TextField
                    name="bankAccountType"
                    label={t(
                      "TransportationRegistration.formLabels.accountType"
                    )}
                    value={values?.sapDataType}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                    disabled={edit == false}
                    fullWidth
                    error={!!errors.bankAccountType} // Show error state
                    helperText={errors.bankAccountType || ""} // Show error message
                    select
                    SelectProps={{
                      IconComponent: ArrowDropDownIcon,
                      displayEmpty: true, // Ensures placeholder is shown
                      renderValue: (selected) =>
                        selected ? selected : "Select an Option", // Show placeholder only in the field
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                      // "& .MuiFormHelperText-root": {
                      //   margin: 0,
                      //   height: errors.bankAccountType ? "20px" : "0px",
                      //   opacity: errors.bankAccountType ? 1 : 0,
                      //   transition: "all 200ms",
                      // },
                      // marginBottom: errors.bankAccountType ? "20px" : "0px",
                      // transition: "margin 200ms",
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  >
                    <MenuItem value="Checking account">
                      Checking account
                    </MenuItem>
                    <MenuItem value="Savings Account">Savings Account</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  <TextField
                    name="bankCode"
                    label={t("TransportationRegistration.formLabels.bankCode")}
                    value={values.bankCode}
                    disabled={true} // This field should be read-only
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" },
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2.9}>
                <FormControl fullWidth>
                  <TextField
                    name="bankRegistrationId"
                    label="Bank Name"
                    value={values?.bankRegistrationId}
                    onChange={handleChange}
                    disabled={edit == false}
                    fullWidth
                    error={!!errors.bankRegistrationId}
                    helperText={errors.bankRegistrationId || ""}
                    select
                    InputLabelProps={{ shrink: true }} // Keep label lifted up
                    SelectProps={{
                      IconComponent: ArrowDropDownIcon,
                      displayEmpty: true, // Ensures placeholder is shown
                      renderValue: (selected) =>
                        selected ? banks.find((bank) => bank.bankRegistrationId === selected)?.bankName : "Select an Option", // Show placeholder only in the field
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                      // "& .MuiFormHelperText-root": {
                      //   margin: 0,
                      //   height: errors.bankRegistrationId ? "20px" : "0px",
                      //   opacity: errors.bankRegistrationId ? 1 : 0,
                      //   transition: "all 200ms",
                      // },
                      // marginBottom: errors.bankRegistrationId ? "20px" : "0px",
                      // transition: "margin 200ms",
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  >
                    {banks.map((bank) => (
                      <MenuItem
                        key={bank.bankRegistrationId}
                        value={bank.bankRegistrationId}
                      >
                        {`${bank.bankCode} - ${bank.bankName}`}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2.9}>
                <FormControl fullWidth>
                  <TextField
                    name="bankBranchRegistrationId"
                    label="Branch Number"
                    value={values.bankBranchRegistrationId}
                    onChange={handleChange}
                    disabled={!values.bankRegistrationId || edit === false}
                    fullWidth
                    error={!!errors.bankBranchRegistrationId}
                    helperText={errors.bankBranchRegistrationId || ""}
                    select
                    InputLabelProps={{ shrink: true }} // Keeps label lifted up
                    SelectProps={{
                      IconComponent: ArrowDropDownIcon,
                      displayEmpty: true, // Ensures placeholder is shown
                      renderValue: (selected) =>
                        selected
                          ? branches.find(
                              (branch) => branch.bankBranchRegistrationId === selected
                            )?.branchName || selected
                          : "Select an Option", // Shows placeholder only in field
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                      // "& .MuiFormHelperText-root": {
                      //   margin: 0,
                      //   height: errors.bankBranchRegistrationId
                      //     ? "20px"
                      //     : "0px",
                      //   opacity: errors.bankBranchRegistrationId ? 1 : 0,
                      //   transition: "all 200ms",
                      // },
                      // marginBottom: errors.bankBranchRegistrationId
                      //   ? "20px"
                      //   : "0px",
                      // transition: "margin 200ms",
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  >
                    {branches.map((branch) => (
                      <MenuItem
                        key={branch.bankBranchRegistrationId}
                        value={branch.bankBranchRegistrationId}
                      >
                        {`${branch.branchNumber} - ${branch.branchName}`}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  <TextField
                    name="accountNumber"
                    label="Account Number"
                    value={values?.sapDataType}
                    onChange={handleChange}
                    disabled={edit == false}
                    fullWidth
                    error={!!errors.accountNumber} // Show error state
                    helperText={errors.accountNumber || ""} // Show error message
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                      // "& .MuiFormHelperText-root": {
                      //   margin: 0,
                      //   height: errors.accountNumber ? "20px" : "0px",
                      //   opacity: errors.accountNumber ? 1 : 0,
                      //   transition: "all 200ms",
                      // },
                      // marginBottom: errors.accountNumber ? "20px" : "0px",
                      // transition: "margin 200ms",
                    }}
                    InputProps={{
                      style: { height: "50px" },
                    }}
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between", // Ensures buttons are placed on opposite corners
              position: "fixed", // Fix the buttons in place
              width: "100%", // Make the Box take full width
              left: 0, // Ensure it starts from the left edge
              bottom: "19px", // Place at the bottom of the screen
              background: "white",
              zIndex: 999,
              padding: "0.5rem",
              borderTop: "1px solid var(--Gray-1, #E9EBEE)",
            }}
          >
            {/* Cancel Button - Left bottom corner */}
            <Button
              style={{
                backgroundColor: "#737D86",
                padding: "6px 16px",
                borderRadius: "4px",
                marginLeft: "30px",
              }}
              variant="contained"
              onClick={edit == false ? handleDeleteConfirm : handleOpenModal} // Open the modal on click
              disabled={loading}
              startIcon={<img src={arrowLeft} alt="Arrow" />}
            >
              {loading && <CircularProgress size={20} />}
              {edit == false ? t("back") : t("cancel")}
            </Button>

            {/* Modal confirmation */}
            <Modal
              open={open}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "7px",
                  width: "585px",
                  margin: "auto",
                  mt: 25,
                  height: "200px",
                }}
              >
                <Box
                  sx={{
                    background: "#102A62",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                  }}
                >
                  <Typography
                    sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
                  >
                    {t("cancelTitle")}
                  </Typography>
                </Box>
                <Box sx={{ p: 2 }}>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, fontWeight: 400 }}
                  >
                    {t("cancelMessage")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                    mb: 1,
                  }}
                >
                  <Button
                    onClick={handleCloseModal}
                    sx={{
                      background: "#737D86",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#737D86" },
                    }}
                  >
                    {t("no")}
                  </Button>
                  <Button
                    onClick={handleDeleteConfirm}
                    sx={{
                      background: "#004F9F",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#004F9F" },
                    }}
                  >
                    {t("yes")}
                  </Button>
                </Box>
              </Box>
            </Modal>

            {edit != false && (
              <Button
                sx={{
                  padding: "6px 16px",
                  borderRadius: "4px",
                  backgroundColor: "#EF7D00",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#EF7D00",
                  },
                  marginRight: "30px",
                }}
                disabled={loading}
                startIcon={<img src={Save} alt="Right Arrow" />}
                onClick={
                  values.businessType === "Individual"
                    ? handleSubmit
                    : handleSubmitLegalEntity
                }
                variant="contained"
              >
                {loading ? <CircularProgress size={20} /> : t("save")}
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </Layout>
  );
}
