// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userAddLable{
    display: flex;
    align-items: center;
    color: #737D86;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
}
.filename-card{
    background-color: white;
    border-radius: 10px;
    border:"1px solid black";
    padding:1rem;
    box-shadow: 0px 4px 4px 0px #00000040;
}
.userTopRight{
    font-family: 'Montserrat';
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0em;
    color: #737D86;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/User_Management/UserAdd/UserAdd.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,cAAc;IACd,yBAAyB;IACzB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;AACvB;AACA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,wBAAwB;IACxB,YAAY;IACZ,qCAAqC;AACzC;AACA;IACI,yBAAyB;IACzB,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,cAAc;AAClB","sourcesContent":[".userAddLable{\n    display: flex;\n    align-items: center;\n    color: #737D86;\n    font-family: 'Montserrat';\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 24px;\n    letter-spacing: 0em;\n}\n.filename-card{\n    background-color: white;\n    border-radius: 10px;\n    border:\"1px solid black\";\n    padding:1rem;\n    box-shadow: 0px 4px 4px 0px #00000040;\n}\n.userTopRight{\n    font-family: 'Montserrat';\n    font-size: 14px;\n    line-height: 20px;\n    letter-spacing: 0em;\n    color: #737D86;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
