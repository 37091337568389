import { httpGrow } from "../http-common";


const getInvestment = (query) => {
  return httpGrow.get(
    `/investment-group/list?pageNumber=${query.pageNumber}&size=${query.size}`
  );
};
const getTobaccoType = () => {
  return httpGrow.get(`/culture/culture-type/tobacco`);
};


const getInvestmentDropdown = () => {
  return httpGrow.get(
    `investment-group/investments`
  );
};

const updateInvestment = (data) => {
  return httpGrow.post(`/investment-group`, data);
};


const createInvestment = (data) => {
  return httpGrow.post(
    `/investment-group`, data
  );
};

const deleteInvestmentGroup = ({ id }) => {
  return httpGrow.delete(`/investment-group/deleteInvestmentGroup/${id}`)
}

// const searchCuringUnitName = ({
//   tobaccoGroup,
//   status,
//   isNew,
//   countryCode,
//   curingUnitName,
//   page,
//   size,
// }) => {
//   const params = {
//     tobaccoGroup,
//     status,
//     isNew,
//     countryCode,
//     curingUnitName,
//     pageNumber: page,
//     pageSize: size,
//   };
//   const filteredEntries = Object.entries(params).filter(([key, value]) => value != "");
//   let newParams = Object.fromEntries(filteredEntries);
//   newParams['pageNumber'] = params.pageNumber
//   return httpGrow.get('/curingunit/search', { params: newParams });
// };



const searchInvestmentData = (keyword) => {
  return httpGrow.get(`/investment-group/find?pageNumber=0&size=50&investmentGroupDescription=${keyword}`);
};


const searchInvestmentFull = () => {
  return httpGrow.get(`/investment-group/list?pageNumber=0&size=50`);
};

const getAllCuringData = (countryCode) => {
  return httpGrow.get(`curingunit/allCuringUnits?countryCode=${countryCode}`);
};

const filterInvestment = (tobaccoType) => {
  return httpGrow.get(`investment-group/find?pageNumber=0&size=50&tobaccoType=${tobaccoType} `);
};

const filterInvestmentStatus = (status) => {
  return httpGrow.get(`investment-group/find?pageNumber=0&size=50&isActive=${status} `);
};


const filterInvestmentTobaccoAndStatus = (tobacco, status) => {
  return httpGrow.get(`investment-group/find?pageNumber=0&size=50&tobaccoType=${tobacco}&isActive=${status} `);
};

// return httpGrow.get(`investment-group/find?pageNumber=0&size=50&investmentGroupDescription=${keyword}&tobaccoType=${tobacco}&isActive=${status} `);

const filterAll = (keyword, tobacco, status) => {
  let queryParams = [];

  if (keyword) queryParams.push(`investmentGroupDescription=${keyword}`);
  if (tobacco) queryParams.push(`tobaccoType=${tobacco}`);
  if (status !== undefined) queryParams.push(`isActive=${status}`);

  const queryString = queryParams.length ? `?pageNumber=0&size=50&${queryParams.join('&')}` : '?pageNumber=0&size=50';

  return httpGrow.get(`investment-group/find${queryString}`);
};


const filterInvestmentKeywordAndTobacco = (keyword, tobacco) => {
  return httpGrow.get(`investment-group/find?pageNumber=0&size=50&investmentGroupDescription=${keyword}&tobaccoType=${tobacco}`);
};

const filterInvestmentKeywordAndStatus = (keyword, status) => {
  return httpGrow.get(`investment-group/find?pageNumber=0&size=50&investmentGroupDescription=${keyword}&isActive=${status}`);
};


const filterInvestmentAutocomplete = (keyword) => {
  return httpGrow.get(`/investment-group/find?pageNumber=0&size=50&investmentGroupDescription=${keyword}`);
};

const searchInvestment = async (query) => {
  const params = {
    pageNumber: query.pageNumber,
    size: query.size,
    investmentGroupDescription : query.investmentGroupDescription,
    tobaccoType : query.tobaccoType,
    isActive : query.status == "Active" ? true : query.status == "Inactive" ? false : query.status
  };
  console.log("params",params)

  return httpGrow.get('/investment-group/find', {params});
  // const response = await httpGrow.get(
  //   `/investment-group/find?pageNumber=${query?.pageNumber}&size=${query?.size || 50
  //   }&investmentGroupDescription
  //   =${query?.investmentGroupDescription || ""}&tobaccoType
  //   =${query?.tobaccoType || ""
  //   }&isActive=${query?.status !== undefined ? query.status : ""
  //   }`
  // );
  // return response;
};

///investment-group/find?pageNumber=0&size=50&investmentGroupDescription=ks

const InvestmentService = {
  getInvestment,
  getTobaccoType,
  createInvestment,
  getAllCuringData,
  getInvestmentDropdown,
  searchInvestmentData,
  updateInvestment,
  deleteInvestmentGroup,
  searchInvestmentFull,
  filterInvestment,
  filterInvestmentAutocomplete,
  filterInvestmentStatus,
  filterInvestmentTobaccoAndStatus,
  filterInvestmentKeywordAndTobacco,
  filterInvestmentKeywordAndStatus,
  filterAll,
  searchInvestment
};
export default InvestmentService;

