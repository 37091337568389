import * as yup from "yup";
import { trimString } from "./CommonFunction";
import {
  FIELD_REQUIRED,
  PERCENTAGE_RANGE_ERROR,
  SPECIAL_CHARACTER_ERROR,
  TRAILING_SPACE_ERROR,
  CHARACTER_LIMIT_ERROR,
} from "utils/AgriErrorMsg";

export const generalInformationSchema = yup.object().shape({
  farmerCode: yup
    .string()
    .required("Code is required")
    .matches(/^\d+$/, "Farmer Code must be a number")
    .length(7, "Farmer Code must be 7 characters"),
    cpf: yup
    .string()
                  .required("CPF is required")
                  .test("cpf-length", "CPF must have exactly 11 digits", (value) => {
                    const cleanCPF = value?.replace(/\D/g, "") || "";
                    return cleanCPF.length === 11;
                  })
                  .test("cpf-valid", "Invalid CPF. CPF format is 000.000.000-00", function (value) {
                    const cleanCPF = value?.replace(/\D/g, "") || "";
    
                    // Bypass validation if the length is not 11
                    if (cleanCPF.length !== 11) return true;
    
                    if (/^(\d)\1{10}$/.test(cleanCPF)) return false;
    
                    let sum = 0;
                    for (let i = 0; i < 9; i++) {
                      sum += parseInt(cleanCPF.charAt(i)) * (10 - i);
                    }
                    let remainder = sum % 11;
                    let firstCheckDigit = remainder < 2 ? 0 : 11 - remainder;
    
                    sum = 0;
                    for (let i = 0; i < 10; i++) {
                      sum += parseInt(cleanCPF.charAt(i)) * (11 - i);
                    }
                    remainder = sum % 11;
                    let secondCheckDigit = remainder < 2 ? 0 : 11 - remainder;
    
                    return (
                      firstCheckDigit === parseInt(cleanCPF.charAt(9)) &&
                      secondCheckDigit === parseInt(cleanCPF.charAt(10))
                    );
                  }),
    // .string()
    // .required("CPF is required")
    // .matches(/^\d*$/, "CPF must contain only digits")
    // .test("cpf-valid", "Invalid CPF. CPF format is 000.000.000-00", (value) => {
    //   if (!value || value.trim() === "") return true; // Skip validation if empty
  
    //   // Remove non-numeric characters
    //   const cleanCPF = value.replace(/\D/g, "");
  
    //   // Ensure it has exactly 11 digits
    //   if (cleanCPF.length !== 11) return false;
  
    //   // Check for invalid sequences (like all digits the same)
    //   if (/^(\d)\1{10}$/.test(cleanCPF)) return false;
  
    //   // First check digit validation
    //   let sum = 0;
    //   for (let i = 0; i < 9; i++) {
    //     sum += parseInt(cleanCPF.charAt(i)) * (10 - i);
    //   }
    //   let remainder = sum % 11;
    //   let firstCheckDigit = remainder < 2 ? 0 : 11 - remainder;
  
    //   // Second check digit validation
    //   sum = 0;
    //   for (let i = 0; i < 10; i++) {
    //     sum += parseInt(cleanCPF.charAt(i)) * (11 - i);
    //   }
    //   remainder = sum % 11;
    //   let secondCheckDigit = remainder < 2 ? 0 : 11 - remainder;
  
    //   // Validate check digits
    //   return (
    //     firstCheckDigit === parseInt(cleanCPF.charAt(9)) &&
    //     secondCheckDigit === parseInt(cleanCPF.charAt(10))
    //   );
    // }),
  
  farmerFullName: yup
    .string()
    .required("Name is required")
    .matches(/^\S(?:.*\S)?$|^$/, "Name cannot contain leading or trailing spaces")
    .max(50, "Name must be less than 50 characters"),
  dateOfBirth: yup
    .string()
    .required("Date of Birth is required"),
  contactNumber: yup
    .string()
    .required("Telephone is required")
    .matches(/^\d*$/, "Telephone must be a whole number")
    .max(15, "Telephone must be less than 15 digits")
    .test(
      "not-all-zeros",
      "Telephone cannot consist of only zeroes",
      (value) => {
        if (!value) return true;  // If the value is empty, skip this check
        return !/^0+$/.test(value);  // Only validate if it's not all zeros
      }
    ),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required")
    .max(50, "Email must be less than 50 characters"),
  nationalId: yup
    .string()
    .required("ID Card is required")
    .matches(/^\d*$/, "ID Card must be a whole number")
    .max(20, "ID Card must be less than 20 characters"),
  issuingAuthority: yup
    .string()
    .required("Issuing authority is required")
    .max(20, "Issuing Authority must be less than 20 characters"),
  emissionDt: yup
    .string()
    .required("Emission Date is required"),
  gender: yup
    .string()
    .required("Gender is required"),
    // .oneOf(["Male", "Female"], "Invalid gender option"),
  nationality: yup
    .string()
    .required("Nationality  is required")
    .max(20,"Nationality can have at most 20 characters"),
  nationalityCountry: yup.string().required("Nationality Country is required").max(2,"Nationality Country must be less than 3 characters"),
  // .max(2, "Nationality Country code must be 2 characters"),
  region: yup.string().required("Region is required"),
  // .max(2, "Region code must be 2 characters"),
  city: yup
    .string()
    .required("City is required")
    .max(40, "City must be less than 40 characters"),
  locality: yup
    .string()
    .required("Locality is required")
    .max(20, "Locality must be less than 20 characters"),
  zipCode: yup
    .string()
    .required("Postal Code is required")
    .matches(/^\d*$/, "Postal Code must be a whole number")
    .max(8, "Postal Code must be less than 8 characters")
    .test(
      "not-all-zeros",
      "Postal Code cannot consist of only zeroes",
      (value) => {
        if (!value) return true;  // If the value is empty, skip this check
        return !/^0+$/.test(value);  // Only validate if it's not all zeros
      }
    ),
    residence: yup
    .string()
    .test(
      "no-leading-or-trailing-spaces",
      "Residence cannot contain leading or trailing spaces",
      (value) => !value || /^\S(?:.*\S)?$/.test(value)
    )
    .max(40, "Residence must be less than 40 characters"),

  dripIrrigation: yup
    .string()
    .required("Farmer Use Drip Irrigation is required"),
  externalCode: yup
  .string()
  .test(
    "is-numeric",
    "External Code must contain only numbers",
    (value) => !value || /^\d+$/.test(value)
  )
  .max(7, "External Code must be less than 8 digits"),
  farmerCountry: yup.string().required("Country is required"),
  // .max(2, "Country code must be 2 characters"),
  regionOfBirth: yup.string().required("Region of Birth is required"),
  // .max(2, "Region of Birth code must be 2 characters"),
  maritalStatus: yup
    .string()
    .required("Marital Status is required")
    .max(20, "Marital Status must be less than 20 characters"),
  spouseName: yup
    .string()
  .when("maritalStatus", {
    is: (maritalStatus) => maritalStatus === "Married",
    then: () => 
      yup.string()
    .required("Spouse Name is required")
    .matches(/^\S(?:.*\S)?$|^$/, "Spouse Name cannot contain leading or trailing spaces")
    .max(50, "Spouse Name must be less than 50 characters"),
    otherwise:() => yup.string(),
  }),
  fatherName: yup
    .string()
    .required("Father’s Name is required")
    .matches(/^\S(?:.*\S)?$|^$/, "Name cannot contain leading or trailing spaces")

    .max(50, "Father’s Name must be less than 50 characters"),
  motherName: yup
    .string()
    .required("Mother’s Name is required")
    .matches(/^\S(?:.*\S)?$|^$/, "Name cannot contain leading or trailing spaces")

    .max(50, "Mother’s Name must be less than 50 characters"),
  minorsUnder18: yup
    .string()
    .required("Minors under 18 on the property is required"),
  adultsOver18: yup
    .string()
    .required("Adults over 18 on the property is required"),
    latitude: yup
    .string()
    .test(
      "is-valid-range",
      "Latitude must be between -90 and 90",
      (value) => value === undefined || (value >= -90 && value <= 90)
    )
    .test(
      "max-decimal-places",
      "Latitude must have up to 6 decimal places",
      (value) => {
        if (value === undefined || value === null) return true; // Allow empty values
        const valueStr = value.toString();
        const decimalPart = valueStr.split(".")[1];
        return !decimalPart || decimalPart.length <= 6;
      }
    )
    .test(
      "valid-syntax",
      "Latitude must be a number",
      (value) => {
        if (!value) return true; 
        const regex = /^-?\d+(\.\d+)?$/;
        return regex.test(value);
      }
    ),
  longitude: yup
    .string()
    .test(
      "is-valid-range",
      "Longitude must be between -180 and 180",
      (value) => value === undefined || (value >= -180 && value <= 180)
    )
    .test(
      "max-decimal-places",
      "Longitude must have up to 6 decimal places",
      (value) => {
        if (value === undefined || value === null) return true; // Allow empty values
        const valueStr = value.toString();
        const decimalPart = valueStr.split(".")[1];
        return !decimalPart || decimalPart.length <= 6;
      }
    )
    .test(
      "valid-syntax",
      "Longitude must be a number",
      (value) => {
        if (!value) return true; 
        const regex = /^-?\d+(\.\d+)?$/;
        return regex.test(value);
      }
    ),
  altitude: yup
    .string()

    .test(
      "max-digits",
      "Altitude must not have more than 8 digits",
      (value) => {
        if (value === undefined || value === null) return true; // Allow empty values
        return value.toString().length <= 8;
      }
    )
    .test(
      "valid-syntax",
      "Altitude must be a number",
      (value) => {
        if (!value) return true; // Allow empty values
        const regex = /^-?\d+$/; 
        return regex.test(value); 
      }
    )
});

// export const taxInformationSchema = yup.object().shape({
//   bankName: yup.string().required("Bank Name is required"),
//   branchNumber: yup.string().required("Branch Number is required"),
//   accountType: yup.string().required("Account Type is required"),
//   accountNumber: yup.string().required("Account Number is required"),
//   stateRegistrationNumber: yup
//     .string()
//     .required("State Registration Number is required"),
//   city: yup.string().required("City is required"),
// })

export const taxInformationSchema = yup.object().shape({
  bankName: yup
    .string()
    .transform(trimString)
    .matches(/^[a-zA-Z\s]*$/, "Bank Name must be alphabetic")
    // .required("Bank Name is required")
    .max(50, "Bank Name cannot be longer than 50 characters"),
  // bankCode: yup.string()
  //   .matches(/^\d+$/, "Bank Code must be a number")
  //   .required("Bank Code is required"),
  branchNumber: yup
    .string()
    .matches(/^[0-9]*$/, "Branch Number must be a number")
    .test(
      "greater-than-zero",
      "Branch Number must be greater than 0",
      value => {
        if (!value) return true; // Skip the test if the value is empty
        return parseInt(value, 10) > 0;
      }
    )
    // .required("Branch Number is required")
    .max(12, "Branch Number cannot be longer than 12 digits"),
    // .test("is-greater-than-zero", "Branch Number must be greater than 0", (value) => {
    //   return value && parseInt(value, 10) > 0;
    // }),
  accountType: yup
  .string(),
  // .required("Account Type is required"),
  accountNumber: yup
    .string()
    .matches(/^[0-9]*$/, "Account Number must be a number")
    // .required("Account Number is required")
    .max(20, "Account Number cannot be longer than 20 digits")
    .test(
      "greater-than-zero",
      "Account Number must be greater than 0",
      value => {
        if (!value) return true; // Skip the test if the value is empty
        return parseInt(value, 10) > 0;
      }
    )
  // nfe: yup.boolean()
  //   .required("Nfe is required")
  //   .oneOf([true, false], "Invalid value for Nfe")
});

// export const propertyOwnedUrbanFullSchema = yup.object().shape({
//   region: yup.string().required("Region is required"),
//   locality: yup.string().required("Locality is required"),
//   city: yup.string().required("City is required"),
//   haProperty: yup.string().required("Ha Property is required"),
//   haExploited: yup.string().required("Ha Exploited is required"),
//   estimatedValue: yup.string().required("Estimated Value is required"),
//   agriculturalLandTax: yup
//     .string()
//     .required("Agricultural Land Tax is required"),
//   value: yup.string().required("Value is required"),
//   registryOfficeCity: yup.string().required("Registry Office City is required"),
//   registrationNumber: yup.string().required("Registry Number is required"),
//   book: yup.string().required("Book is required"),
//   page: yup.string().required("Page is required"),
//   registryOfficeName: yup.string().required("Registry Office Name is required"),
//   regDate: yup.string().required("Registration Date is required"),
//   personType: yup.string().required("Person Type is required"),
//   cpfNumber: yup.string().required("Cpf/Cnpj Number is required"),
//   name: yup.string().required("Name is required"),
//   hectares: yup.string().required("Hectares is required"),
// });
export const propertyLeasesSchema = 
  yup.object().shape({
    personType: yup
      .string()
      .required("Person Type is required")
      .oneOf(["Individual", "Company"], "Person Type must be either 'Individual' or 'Company'"),
      
      cpfNumber: yup
      .string()
      .required("Id Number is required")
      .when("personType", {
        is: (personType) => !personType, // Check if personType is empty
        then: () => yup.string().required("Id Number is required"), // Still required
        otherwise: () =>
          yup
            .string()
            .when("personType", {
              is: "Individual",
              then: () =>
                yup
                  .string()
                  .required("CPF is required")
                  .test("cpf-length", "CPF must have exactly 11 digits", (value) => {
                    const cleanCPF = value?.replace(/\D/g, "") || "";
                    return cleanCPF.length === 11;
                  })
                  .test("cpf-valid", "Invalid CPF. CPF format is 000.000.000-00", function (value) {
                    const cleanCPF = value?.replace(/\D/g, "") || "";
    
                    // Bypass validation if the length is not 11
                    if (cleanCPF.length !== 11) return true;
    
                    if (/^(\d)\1{10}$/.test(cleanCPF)) return false;
    
                    let sum = 0;
                    for (let i = 0; i < 9; i++) {
                      sum += parseInt(cleanCPF.charAt(i)) * (10 - i);
                    }
                    let remainder = sum % 11;
                    let firstCheckDigit = remainder < 2 ? 0 : 11 - remainder;
    
                    sum = 0;
                    for (let i = 0; i < 10; i++) {
                      sum += parseInt(cleanCPF.charAt(i)) * (11 - i);
                    }
                    remainder = sum % 11;
                    let secondCheckDigit = remainder < 2 ? 0 : 11 - remainder;
    
                    return (
                      firstCheckDigit === parseInt(cleanCPF.charAt(9)) &&
                      secondCheckDigit === parseInt(cleanCPF.charAt(10))
                    );
                  }),
              otherwise: () =>
                yup
                  .string()
                  .required("CNPJ is required")
                  .test("cnpj-length", "CNPJ must have exactly 14 digits", (value) => {
                    const cleanCNPJ = value?.replace(/\D/g, "") || "";
                    return cleanCNPJ.length === 14;
                  })
                  .test("cnpj-valid", "Invalid CNPJ. CNPJ format is 00.000.000/0000-00", function (value) {
                    const cleanCNPJ = value?.replace(/\D/g, "") || "";
                    if (cleanCNPJ.length !== 14) return true;

                    if (/^(\d)\1{13}$/.test(cleanCNPJ)) return false;

                    const calculateCheckDigit = (digits, weights) => {
                      const sum = digits.reduce((acc, digit, index) => acc + digit * weights[index], 0);
                      const remainder = sum % 11;
                      return remainder < 2 ? 0 : 11 - remainder;
                    };

                    const cnpjDigits = cleanCNPJ.split("").map(Number);

                    const firstTwelve = cnpjDigits.slice(0, 12);
                    const firstWeights = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
                    const firstCheckDigit = calculateCheckDigit(firstTwelve, firstWeights);

                    if (firstCheckDigit !== cnpjDigits[12]) return false;

                    const firstThirteen = cnpjDigits.slice(0, 13);
                    const secondWeights = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
                    const secondCheckDigit = calculateCheckDigit(firstThirteen, secondWeights);

                    return secondCheckDigit === cnpjDigits[13];
                  })
            
      })}),

    name: yup
      .string()
      .required("Name is required")
      .max(40, "Name can have up to 40 characters"),

    hectares: yup
      .string()
      .required("Hectares is required")
      .test("max-before-decimal", "Hectares can have up to 8 digits before the decimal", (value) => {
        const beforeDecimal = value.split('.')[0];
        return beforeDecimal.length <= 8;
      })
      .test("max-after-decimal", "Hectares can have up to 2 digits after the decimal", (value) => {
        const afterDecimal = value.split('.')[1];
        return !afterDecimal || afterDecimal.length <= 2;
      }),
  });
export const propertyOwnedUrbanFullSchema = yup.object().shape({
  region: yup
    .string()
    // .max(2, "Region must be at most 2 characters")
    .required("Region is required"),
  locality: yup.string().required("Locality is required"),
  city: yup
    .string()
    .max(40, "City must be at most 40 characters")
    .required("City is required"),
  haProperty: yup
    .string()
    .test(
      "is-numeric",
      "Ha Property must contain only numbers",
      (value) => /^[0-9]*\.?[0-9]*$/.test(value)
    )
    .test(
      "max-before-decimal",
      "Ha Property can have up to 8 digits before the decimal",
      (value) => {
        const beforeDecimal = value.split('.')[0];
        return beforeDecimal.length <= 8;
      }
    )
    .test(
      "max-after-decimal",
      "Ha Property can have up to 2 digits after the decimal",
      (value) => {
        const afterDecimal = value.split('.')[1];
        return !afterDecimal || afterDecimal.length <= 2;
      }
    )
    .required("Ha Property is required"),
  haExploited: yup
    .string()
    .test(
      "is-numeric",
      "Ha Exploited must contain only numbers",
      (value) => /^[0-9]*\.?[0-9]*$/.test(value)
    )
    .test(
      "max-before-decimal",
      "Ha Exploited can have up to 8 digits before the decimal",
      (value) => {
        const beforeDecimal = value.split('.')[0];
        return beforeDecimal.length <= 8;
      }
    )
    .test(
      "max-after-decimal",
      "Ha Exploited can have up to 2 digits after the decimal",
      (value) => {
        const afterDecimal = value.split('.')[1];
        return !afterDecimal || afterDecimal.length <= 2;
      }
    )
    .required("Ha Exploited is required"),
  estimatedValue: yup
    .string()
    .test(
      "is-numeric",
      "Estimated Value must contain only numbers",
      (value) => /^[0-9]*\.?[0-9]*$/.test(value)
    )
    .test(
      "max-before-decimal",
      "Estimated Value can have up to 8 digits before the decimal",
      (value) => {
        const beforeDecimal = value.split('.')[0];
        return beforeDecimal.length <= 8;
      }
    )
    .test(
      "max-after-decimal",
      "Estimated Value can have up to 2 digits after the decimal",
      (value) => {
        const afterDecimal = value.split('.')[1];
        return !afterDecimal || afterDecimal.length <= 2;
      }
    )
    .required("Estimated Value is required"),
  agriculturalLandTax: yup
    .string()
    .test(
      "is-numeric",
      "Agricultural Land Tax must contain only numbers",
      (value) => /^[0-9]*\.?[0-9]*$/.test(value)
    )    
    .test(
      "max-before-decimal",
      "Agricultural Land Tax can have up to 8 digits before the decimal",
      (value) => {
        const beforeDecimal = value.split('.')[0];
        return beforeDecimal.length <= 8;
      }
    )
    .test(
      "max-after-decimal",
      "Agricultural Land Tax can have up to 2 digits after the decimal",
      (value) => {
        const afterDecimal = value.split('.')[1];
        return !afterDecimal || afterDecimal.length <= 2;
      }
    )
    .required("Agricultural Land Tax is required"),
  value: yup
    .string()
    .typeError("Value must be a number")
    .test(
      "is-numeric",
      "Value must contain only numbers",
      (value) => /^[0-9]*\.?[0-9]*$/.test(value)
    )
    .test(
      "max-before-decimal",
      "Value can have up to 8 digits before the decimal",
      (value) => {
        const beforeDecimal = value.split('.')[0];
        return beforeDecimal.length <= 8;
      }
    )
    .test(
      "max-after-decimal",
      "Value can have up to 2 digits after the decimal",
      (value) => {
        const afterDecimal = value.split('.')[1];
        return !afterDecimal || afterDecimal.length <= 2;
      }
    )
    .required("Value is required"),
  registryOfficeCity: yup
    .string()
    // .max(10, "Registry Office City must be at most 10 characters")
    .required("Registry Office City is required"),
  registrationNumber: yup
    .string()
    .max(20, "Registration Number can have up to 20 characters")
    .test(
      "is-numeric",
      "Registration Number must contain only numbers",
      (value) => /^[0-9]*$/.test(value)
    )
    .required("Registration Number is required")
    .test(
      "not-all-zeros",
      "Registration Number cannot consist of only zeroes",
      (value) => {
        if (!value) return true;  // If the value is empty, skip this check
        return !/^0+$/.test(value);  // Only validate if it's not all zeros
      }
    ),
  book: yup
    .string()
    .max(20, "Book can have up to 20 characters")
    .required("Book is required"),
  page: yup
    .string()
    .max(10,"Page can have up to 10 characters")
    .required("Page is required"),
  registryOfficeName: yup
    .string()
    .max(50, "Registry Office Name can have up to 50 characters")
    .required("Registry Office Name is required"),
  regDate: yup.date().required("Registration Date is required"),
  // personType: yup.string().required("Person Type is required"),
  // cpfNumber: yup
  // .string()
  // .required("Id Number is required")
  // .when("personType", {
  //   is: (personType) => !personType, // Check if personType is empty
  //   then: () => yup.string().required("Id Number is required"), // Still required
  //   otherwise: () =>
  //     yup
  //       .string()
  //       .when("personType", {
  //         is: "Individual",
  //         then: () =>
  //           yup
  //             .string()
  //             .required("CPF is required")
  //             .test("cpf-length", "CPF must have exactly 11 digits", (value) => {
  //               const cleanCPF = value?.replace(/\D/g, "") || "";
  //               return cleanCPF.length === 11;
  //             })
  //             .test("cpf-valid", "Invalid CPF. CPF format is 000.000.000-00", function (value) {
  //               const cleanCPF = value?.replace(/\D/g, "") || "";

  //               // Bypass validation if the length is not 11
  //               if (cleanCPF.length !== 11) return true;

  //               if (/^(\d)\1{10}$/.test(cleanCPF)) return false;

  //               let sum = 0;
  //               for (let i = 0; i < 9; i++) {
  //                 sum += parseInt(cleanCPF.charAt(i)) * (10 - i);
  //               }
  //               let remainder = sum % 11;
  //               let firstCheckDigit = remainder < 2 ? 0 : 11 - remainder;

  //               sum = 0;
  //               for (let i = 0; i < 10; i++) {
  //                 sum += parseInt(cleanCPF.charAt(i)) * (11 - i);
  //               }
  //               remainder = sum % 11;
  //               let secondCheckDigit = remainder < 2 ? 0 : 11 - remainder;

  //               return (
  //                 firstCheckDigit === parseInt(cleanCPF.charAt(9)) &&
  //                 secondCheckDigit === parseInt(cleanCPF.charAt(10))
  //               );
  //             }),
  //         otherwise: () =>
  //           yup
  //             .string()
  //             .required("CNPJ is required")
  //             .test("cnpj-length", "CNPJ must have exactly 14 digits", (value) => {
  //               const cleanCNPJ = value?.replace(/\D/g, "") || "";
  //               return cleanCNPJ.length === 14;
  //             })
  //             .test("cnpj-valid", "Invalid CNPJ. CNPJ format is 00.000.000/0000-00", function (value) {
  //               const cleanCNPJ = value?.replace(/\D/g, "") || "";

  //               // Bypass validation if the length is not 14
  //               if (cleanCNPJ.length !== 14) return true;

  //               if (/^(\d)\1{13}$/.test(cleanCNPJ)) return false;

  //               let sum = 0;
  //               for (let i = 0; i < 12; i++) {
  //                 sum += parseInt(cleanCNPJ.charAt(i)) * (5 - (i % 8));
  //               }
  //               let remainder = sum % 11;
  //               let firstCheckDigit = remainder < 2 ? 0 : 11 - remainder;

  //               sum = 0;
  //               for (let i = 0; i < 13; i++) {
  //                 sum += parseInt(cleanCNPJ.charAt(i)) * (6 - (i % 8));
  //               }
  //               remainder = sum % 11;
  //               let secondCheckDigit = remainder < 2 ? 0 : 11 - remainder;

  //               return (
  //                 firstCheckDigit === parseInt(cleanCNPJ.charAt(12)) &&
  //                 secondCheckDigit === parseInt(cleanCNPJ.charAt(13))
  //               );
  //             }),
  //       }),
  // }),

  // name: yup.string().required("Name is required").max(40,"Name can have up to 40 characters"),
  // hectares: yup.string().required("Hectares is required")
  // .test(
  //   "is-numeric",
  //   "Hectares must contain only numbers",
  //   (value) => /^[0-9]*\.?[0-9]*$/.test(value)
  // )
  // .test(
  //   "max-before-decimal",
  //   "Hectares can have up to 8 digits before the decimal",
  //   (value) => {
  //     const beforeDecimal = value.split('.')[0];
  //     return beforeDecimal.length <= 8;
  //   }
  // )
  // .test(
  //   "max-after-decimal",
  //   "Hectares can have up to 2 digits after the decimal",
  //   (value) => {
  //     const afterDecimal = value.split('.')[1];
  //     return !afterDecimal || afterDecimal.length <= 2;
  //   }
  // ),
});

// export const propertyOwnedUrbanSchema = yup.object().shape({
//   region: yup.string().required("Region is required"),
//   locality: yup.string().required("Locality is required"),
//   city: yup.string().required("City is required"),
//   haProperty: yup.string().required("Ha Property is required"),
//   haExploited: yup.string().required("Ha Exploited is required"),
//   estimatedValue: yup.string().required("Estimated Value is required"),
//   agriculturalLandTax: yup
//     .string()
//     .required("Agricultural Land Tax is required"),
//   value: yup.string().required("Value is required"),
//   registryOfficeCity: yup.string().required("Registry Office City is required"),
//   registrationNumber: yup.string().required("Registry Number is required"),
//   book: yup.string().required("Book is required"),
//   page: yup.string().required("Page is required"),
//   registryOfficeName: yup.string().required("Registry Office Name is required"),
//   regDate: yup.string().required("Registration Date is required"),
// });

export const propertyOwnedUrbanSchema = yup.object().shape({
  region: yup
    .string()
    // .max(2, "Region must be at most 2 characters")
    .required("Region is required"),
  locality: yup.string().required("Locality is required"),
  city: yup
    .string()
    .max(40, "City must be at most 40 characters")
    .required("City is required"),
    haProperty: yup
    .string()
    .test(
      "is-numeric",
      "Ha Property must contain only numbers",
      (value) => /^[0-9]*\.?[0-9]*$/.test(value)
    )
    .test(
      "max-before-decimal",
      "Ha Property can have up to 8 digits before the decimal",
      (value) => {
        const beforeDecimal = value.split('.')[0];
        return beforeDecimal.length <= 8;
      }
    )
    .test(
      "max-after-decimal",
      "Ha Property can have up to 2 digits after the decimal",
      (value) => {
        const afterDecimal = value.split('.')[1];
        return !afterDecimal || afterDecimal.length <= 2;
      }
    )
    .required("Ha Property is required"),
  haExploited: yup
    .string()
    .test(
      "is-numeric",
      "Ha Exploited must contain only numbers",
      (value) => /^[0-9]*\.?[0-9]*$/.test(value)
    )
    .test(
      "max-before-decimal",
      "Ha Exploited can have up to 8 digits before the decimal",
      (value) => {
        const beforeDecimal = value.split('.')[0];
        return beforeDecimal.length <= 8;
      }
    )
    .test(
      "max-after-decimal",
      "Ha Exploited can have up to 2 digits after the decimal",
      (value) => {
        const afterDecimal = value.split('.')[1];
        return !afterDecimal || afterDecimal.length <= 2;
      }
    )
    .required("Ha Exploited is required"),
  estimatedValue: yup
    .string()
    .test(
      "is-numeric",
      "Estimated Value must contain only numbers",
      (value) => /^[0-9]*\.?[0-9]*$/.test(value)
    )
    .test(
      "max-before-decimal",
      "Estimated Value can have up to 8 digits before the decimal",
      (value) => {
        const beforeDecimal = value.split('.')[0];
        return beforeDecimal.length <= 8;
      }
    )
    .test(
      "max-after-decimal",
      "Estimated Value can have up to 2 digits after the decimal",
      (value) => {
        const afterDecimal = value.split('.')[1];
        return !afterDecimal || afterDecimal.length <= 2;
      }
    )
    .required("Estimated Value is required"),
  agriculturalLandTax: yup
    .string()
    .test(
      "is-numeric",
      "Agricultural Land Tax must contain only numbers",
      (value) => /^[0-9]*\.?[0-9]*$/.test(value)
    )    
    .test(
      "max-before-decimal",
      "Agricultural Land Tax can have up to 8 digits before the decimal",
      (value) => {
        const beforeDecimal = value.split('.')[0];
        return beforeDecimal.length <= 8;
      }
    )
    .test(
      "max-after-decimal",
      "Agricultural Land Tax can have up to 2 digits after the decimal",
      (value) => {
        const afterDecimal = value.split('.')[1];
        return !afterDecimal || afterDecimal.length <= 2;
      }
    )
    .required("Agricultural Land Tax is required"),
  value: yup
    .string()
    .typeError("Value must be a number")
    .test(
      "is-numeric",
      "Value must contain only numbers",
      (value) => /^[0-9]*\.?[0-9]*$/.test(value)
    )
    .test(
      "max-before-decimal",
      "Value can have up to 8 digits before the decimal",
      (value) => {
        const beforeDecimal = value.split('.')[0];
        return beforeDecimal.length <= 8;
      }
    )
    .test(
      "max-after-decimal",
      "Value can have up to 2 digits after the decimal",
      (value) => {
        const afterDecimal = value.split('.')[1];
        return !afterDecimal || afterDecimal.length <= 2;
      }
    )
    .required("Value is required"),
  registryOfficeCity: yup
    .string()
    // .max(10, "Registry Office City must be at most 10 characters")
    .required("Registry Office City is required"),
  registrationNumber: yup
    .string()
    .max(20, "Registration Number can have up to 20 characters")
    .test(
      "is-numeric",
      "Registration Number must contain only numbers",
      (value) => /^[0-9]*$/.test(value)
    )
    .required("Registration Number is required")
    .test(
      "not-all-zeros",
      "Registration Number cannot consist of only zeroes",
      (value) => {
        if (!value) return true;  // If the value is empty, skip this check
        return !/^0+$/.test(value);  // Only validate if it's not all zeros
      }
    ),
  book: yup
    .string()
    .max(20, "Book must be at most 20 characters")
    .required("Book is required"),
  page: yup
    .string()
    .max(10, "Page must be at most 10 characters")
    .required("Page is required"),
  registryOfficeName: yup
    .string()
    .max(50, "Registry Office Name can have up to 50 characters")
    .required("Registry Office Name is required"),
  regDate: yup.date().required("Registration Date is required"),
});

// export const propertyOwnedRuralSchema = yup.object().shape({
//   ruralEnvironmentalType: yup
//     .string()
//     .required("Rural Enviroment Registry is required"),
//   region: yup.string().required("Region is required"),
//   locality: yup.string().required("Locality is required"),
//   city: yup.string().required("City is required"),
//   haProperty: yup.string().required("Ha Property is required"),
//   haExploited: yup.string().required("Ha Exploited is required"),
//   estimatedValue: yup.string().required("Estimated Value is required"),
//   agriculturalLandTax: yup
//     .string()
//     .required("Agricultural Land Tax is required"),
//   value: yup.string().required("Value is required"),
//   registryOfficeCity: yup.string().required("Registry Office City is required"),
//   registrationNumber: yup.string().required("Registry Number is required"),
//   book: yup.string().required("Book is required"),
//   page: yup.string().required("Page is required"),
//   registryOfficeName: yup.string().required("Registry Office Name is required"),
//   regDate: yup.string().required("Registration Date is required"),
// });

export const propertyOwnedRuralSchema = yup.object().shape({
  ruralEnvironmentalType: yup
    .string()
    // .required("Rural Environmental Registry is required")
    .max(45, "Rural Environmental Registry must be at most 45 characters")
    ,
  region: yup
    .string()
    // .max(2, "Region must be at most 2 characters")
    .required("Region is required"),
  locality: yup.string().required("Locality is required"),
  city: yup
    .string()
    .required("City is required")
    .max(40, "City must be at most 40 characters")
    ,
    haProperty: yup
    .string()
    .test(
      "is-numeric",
      "Ha Property must contain only numbers",
      (value) => /^[0-9]*\.?[0-9]*$/.test(value)
    )
    .test(
      "max-before-decimal",
      "Ha Property can have up to 8 digits before the decimal",
      (value) => {
        const beforeDecimal = value.split('.')[0];
        return beforeDecimal.length <= 8;
      }
    )
    .test(
      "max-after-decimal",
      "Ha Property can have up to 2 digits after the decimal",
      (value) => {
        const afterDecimal = value.split('.')[1];
        return !afterDecimal || afterDecimal.length <= 2;
      }
    )
    .required("Ha Property is required"),
  haExploited: yup
    .string()
    .test(
      "is-numeric",
      "Ha Exploited must contain only numbers",
      (value) => /^[0-9]*\.?[0-9]*$/.test(value)
    )
    .test(
      "max-before-decimal",
      "Ha Exploited can have up to 8 digits before the decimal",
      (value) => {
        const beforeDecimal = value.split('.')[0];
        return beforeDecimal.length <= 8;
      }
    )
    .test(
      "max-after-decimal",
      "Ha Exploited can have up to 2 digits after the decimal",
      (value) => {
        const afterDecimal = value.split('.')[1];
        return !afterDecimal || afterDecimal.length <= 2;
      }
    )
    .required("Ha Exploited is required"),
  estimatedValue: yup
    .string()
    .test(
      "is-numeric",
      "Estimated Value must contain only numbers",
      (value) => /^[0-9]*\.?[0-9]*$/.test(value)
    )
    .test(
      "max-before-decimal",
      "Estimated Value can have up to 8 digits before the decimal",
      (value) => {
        const beforeDecimal = value.split('.')[0];
        return beforeDecimal.length <= 8;
      }
    )
    .test(
      "max-after-decimal",
      "Estimated Value can have up to 2 digits after the decimal",
      (value) => {
        const afterDecimal = value.split('.')[1];
        return !afterDecimal || afterDecimal.length <= 2;
      }
    )
    .required("Estimated Value is required"),
  agriculturalLandTax: yup
    .string()
    .test(
      "is-numeric",
      "Agricultural Land Tax must contain only numbers",
      (value) => /^[0-9]*\.?[0-9]*$/.test(value)
    )    
    .test(
      "max-before-decimal",
      "Agricultural Land Tax can have up to 8 digits before the decimal",
      (value) => {
        const beforeDecimal = value.split('.')[0];
        return beforeDecimal.length <= 8;
      }
    )
    .test(
      "max-after-decimal",
      "Agricultural Land Tax can have up to 2 digits after the decimal",
      (value) => {
        const afterDecimal = value.split('.')[1];
        return !afterDecimal || afterDecimal.length <= 2;
      }
    )
    .required("Agricultural Land Tax is required"),
  value: yup
    .string()
    .typeError("Value must be a number")
    .test(
      "is-numeric",
      "Value must contain only numbers",
      (value) => /^[0-9]*\.?[0-9]*$/.test(value)
    )
    .test(
      "max-before-decimal",
      "Value can have up to 8 digits before the decimal",
      (value) => {
        const beforeDecimal = value.split('.')[0];
        return beforeDecimal.length <= 8;
      }
    )
    .test(
      "max-after-decimal",
      "Value can have up to 2 digits after the decimal",
      (value) => {
        const afterDecimal = value.split('.')[1];
        return !afterDecimal || afterDecimal.length <= 2;
      }
    )
    .required("Value is required"),
  registryOfficeCity: yup
    .string()
    // .max(10, "Registry Office City must be at most 10 characters")
    .required("Registry Office City is required"),
  registrationNumber: yup
    .string()
    .required("Registration Number is required")
    .max(20, "Registration Number can have up to 20 characters")
    .test(
      "is-numeric",
      "Registration Number must contain only numbers",
      (value) => {
        if (!value) return true;  // If the value is empty, skip this check
        return !/^0+$/.test(value);  // Only validate if it's not all zeros
      }
    )
    .test(
      "not-all-zeros",
      "Registration Number cannot consist of only zeroes",
      (value) => {
        if (!value) return true;  // If the value is empty, skip this check
        return !/^0+$/.test(value);  // Only validate if it's not all zeros
      }
    ),
  book: yup
    .string()
    .required("Book is required")
    .max(20, "Book can have up to 20 characters")
    ,
  page: yup
    .string()
    .required("Page is required")
    .max(10, "Page can have up to characters")
    ,
  registryOfficeName: yup
    .string()
    .required("Registry Office Name is required")
    .max(50, "Registry Office Name can have up to 50 characters")
    ,
  regDate: yup.date().required("Registration Date is required"),
});

export const propertyOwnedRuralFullSchema = yup.object().shape({
  ruralEnvironmentalType: yup
    .string()
    // .required("Rural Enviroment Registry is required")
    .max(45, "Rural Environmental Registry must be at most 45 characters"),
  region: yup.string().required("Region is required"),
  locality: yup.string().required("Locality is required"),
  city: yup.string().required("City is required"),
  haProperty: yup
  .string()
  .test(
    "is-numeric",
    "Ha Property must contain only numbers",
    (value) => /^[0-9]*\.?[0-9]*$/.test(value)
  )
  .test(
    "max-before-decimal",
    "Ha Property can have up to 8 digits before the decimal",
    (value) => {
      const beforeDecimal = value.split('.')[0];
      return beforeDecimal.length <= 8;
    }
  )
  .test(
    "max-after-decimal",
    "Ha Property can have up to 2 digits after the decimal",
    (value) => {
      const afterDecimal = value.split('.')[1];
      return !afterDecimal || afterDecimal.length <= 2;
    }
  )
  .required("Ha Property is required"),
haExploited: yup
  .string()
  .test(
    "is-numeric",
    "Ha Exploited must contain only numbers",
    (value) => /^[0-9]*\.?[0-9]*$/.test(value)
  )
  .test(
    "max-before-decimal",
    "Ha Exploited can have up to 8 digits before the decimal",
    (value) => {
      const beforeDecimal = value.split('.')[0];
      return beforeDecimal.length <= 8;
    }
  )
  .test(
    "max-after-decimal",
    "Ha Exploited can have up to 2 digits after the decimal",
    (value) => {
      const afterDecimal = value.split('.')[1];
      return !afterDecimal || afterDecimal.length <= 2;
    }
  )
  .required("Ha Exploited is required"),
estimatedValue: yup
  .string()
  .test(
    "is-numeric",
    "Estimated Value must contain only numbers",
    (value) => /^[0-9]*\.?[0-9]*$/.test(value)
  )
  .test(
    "max-before-decimal",
    "Estimated Value can have up to 8 digits before the decimal",
    (value) => {
      const beforeDecimal = value.split('.')[0];
      return beforeDecimal.length <= 8;
    }
  )
  .test(
    "max-after-decimal",
    "Estimated Value can have up to 2 digits after the decimal",
    (value) => {
      const afterDecimal = value.split('.')[1];
      return !afterDecimal || afterDecimal.length <= 2;
    }
  )
  .required("Estimated Value is required"),
agriculturalLandTax: yup
  .string()
  .test(
    "is-numeric",
    "Agricultural Land Tax must contain only numbers",
    (value) => /^[0-9]*\.?[0-9]*$/.test(value)
  )    
  .test(
    "max-before-decimal",
    "Agricultural Land Tax can have up to 8 digits before the decimal",
    (value) => {
      const beforeDecimal = value.split('.')[0];
      return beforeDecimal.length <= 8;
    }
  )
  .test(
    "max-after-decimal",
    "Agricultural Land Tax can have up to 2 digits after the decimal",
    (value) => {
      const afterDecimal = value.split('.')[1];
      return !afterDecimal || afterDecimal.length <= 2;
    }
  )
  .required("Agricultural Land Tax is required"),
value: yup
  .string()
  .typeError("Value must be a number")
  .test(
    "is-numeric",
    "Value must contain only numbers",
    (value) => /^[0-9]*\.?[0-9]*$/.test(value)
  )
  .test(
    "max-before-decimal",
    "Value can have up to 8 digits before the decimal",
    (value) => {
      const beforeDecimal = value.split('.')[0];
      return beforeDecimal.length <= 8;
    }
  )
  .test(
    "max-after-decimal",
    "Value can have up to 2 digits after the decimal",
    (value) => {
      const afterDecimal = value.split('.')[1];
      return !afterDecimal || afterDecimal.length <= 2;
    }
  )
  .required("Value is required"),
  registryOfficeCity: yup.string().required("Registry Office City is required"),
  registrationNumber: yup.string().required("Registration Number is required")
  .max(20, "Registration Number can have up to 20 characters")
  .test(
    "is-numeric",
    "Registration Number must contain only numbers",
    (value) => /^[0-9]*$/.test(value)
  )
  .test(
    "not-all-zeros",
    "Registration Number cannot consist of only zeroes",
    (value) => {
      if (!value) return true;  // If the value is empty, skip this check
      return !/^0+$/.test(value);  // Only validate if it's not all zeros
    }
  ),
  book: yup.string().required("Book is required").max(20, "Book can have up to 20 characters"),
  page: yup.string().required("Page is required").max(10,"Page can have up to 10 characters"),
  registryOfficeName: yup.string().required("Registry Office Name is required").max(50, "Registry Office Name can have up to 50 characters"),
  regDate: yup.string().required("Registration Date is required"),
  // personType: yup.string().required("Person Type is required"),
  // cpfNumber: yup
  // .string()
  // .required("Id Number is required")
  // .when("personType", {
  //   is: (personType) => !personType, // Check if personType is empty
  //   then: () => yup.string().required("Id Number is required"), // Still required
  //   otherwise: () =>
  //     yup
  //       .string()
  //       .when("personType", {
  //         is: "Individual",
  //         then: () =>
  //           yup
  //             .string()
  //             .required("CPF is required")
  //             .test("cpf-length", "CPF must have exactly 11 digits", (value) => {
  //               const cleanCPF = value?.replace(/\D/g, "") || "";
  //               return cleanCPF.length === 11;
  //             })
  //             .test("cpf-valid", "Invalid CPF. CPF format is 000.000.000-00", function (value) {
  //               const cleanCPF = value?.replace(/\D/g, "") || "";

  //               // Bypass validation if the length is not 11
  //               if (cleanCPF.length !== 11) return true;

  //               if (/^(\d)\1{10}$/.test(cleanCPF)) return false;

  //               let sum = 0;
  //               for (let i = 0; i < 9; i++) {
  //                 sum += parseInt(cleanCPF.charAt(i)) * (10 - i);
  //               }
  //               let remainder = sum % 11;
  //               let firstCheckDigit = remainder < 2 ? 0 : 11 - remainder;

  //               sum = 0;
  //               for (let i = 0; i < 10; i++) {
  //                 sum += parseInt(cleanCPF.charAt(i)) * (11 - i);
  //               }
  //               remainder = sum % 11;
  //               let secondCheckDigit = remainder < 2 ? 0 : 11 - remainder;

  //               return (
  //                 firstCheckDigit === parseInt(cleanCPF.charAt(9)) &&
  //                 secondCheckDigit === parseInt(cleanCPF.charAt(10))
  //               );
  //             }),
  //         otherwise: () =>
  //           yup
  //             .string()
  //             .required("CNPJ is required")
  //             .test("cnpj-length", "CNPJ must have exactly 14 digits", (value) => {
  //               const cleanCNPJ = value?.replace(/\D/g, "") || "";
  //               return cleanCNPJ.length === 14;
  //             })
  //             .test("cnpj-valid", "Invalid CNPJ. CNPJ format is 00.000.000/0000-00", function (value) {
  //               const cleanCNPJ = value?.replace(/\D/g, "") || "";

  //               // Bypass validation if the length is not 14
  //               if (cleanCNPJ.length !== 14) return true;

  //               if (/^(\d)\1{13}$/.test(cleanCNPJ)) return false;

  //               let sum = 0;
  //               for (let i = 0; i < 12; i++) {
  //                 sum += parseInt(cleanCNPJ.charAt(i)) * (5 - (i % 8));
  //               }
  //               let remainder = sum % 11;
  //               let firstCheckDigit = remainder < 2 ? 0 : 11 - remainder;

  //               sum = 0;
  //               for (let i = 0; i < 13; i++) {
  //                 sum += parseInt(cleanCNPJ.charAt(i)) * (6 - (i % 8));
  //               }
  //               remainder = sum % 11;
  //               let secondCheckDigit = remainder < 2 ? 0 : 11 - remainder;

  //               return (
  //                 firstCheckDigit === parseInt(cleanCNPJ.charAt(12)) &&
  //                 secondCheckDigit === parseInt(cleanCNPJ.charAt(13))
  //               );
  //             }),
  //       }),
  // }),

  // name: yup.string().required("Name is required").max(40,"Name can have up to 40 characters"),
  // hectares: yup.string().required("Hectares is required")
  // .test(
  //   "is-numeric",
  //   "Hectares must contain only numbers",
  //   (value) => /^[0-9]*\.?[0-9]*$/.test(value)
  // )
  // .test(
  //   "max-before-decimal",
  //   "Hectares can have up to 8 digits before the decimal",
  //   (value) => {
  //     const beforeDecimal = value.split('.')[0];
  //     return beforeDecimal.length <= 8;
  //   }
  // )
  // .test(
  //   "max-after-decimal",
  //   "Hectares can have up to 2 digits after the decimal",
  //   (value) => {
  //     const afterDecimal = value.split('.')[1];
  //     return !afterDecimal || afterDecimal.length <= 2;
  //   }
  // ),
});

export const propertyLeasedRuralSchema = yup.object().shape({
  ruralEnvironmentalType: yup
    .string()
    // .required("Rural Enviroment Registry is required")
    .max(45, "Rural Environmental Registry must be at most 45 characters"),
  farmer: yup.string().required("Farmer is required"),
  property: yup.string().required("Property is required"),
  ha: yup.string().required("Hectares is required")
  .test(
    "is-numeric",
    "Hectares must contain only numbers",
    (value) => /^[0-9]*\.?[0-9]*$/.test(value)
  )
  .test(
    "max-before-decimal",
    "Hectares can have up to 8 digits before the decimal",
    (value) => {
      const beforeDecimal = value.split('.')[0];
      return beforeDecimal.length <= 8;
    }
  )
  .test(
    "max-after-decimal",
    "Hectares can have up to 2 digits after the decimal",
    (value) => {
      const afterDecimal = value.split('.')[1];
      return !afterDecimal || afterDecimal.length <= 2;
    }
  ),
});

export const propertyLeasedUrbanSchema = yup.object().shape({
  farmer: yup.string().required("Farmer is required"),
  property: yup.string().required("Property is required"),
  ha: yup.string().required("Hectares is required")
  .test(
    "is-numeric",
    "Hectares must contain only numbers",
    (value) => /^[0-9]*\.?[0-9]*$/.test(value)
  )
  .test(
    "max-before-decimal",
    "Hectares can have up to 8 digits before the decimal",
    (value) => {
      const beforeDecimal = value.split('.')[0];
      return beforeDecimal.length <= 8;
    }
  )
  .test(
    "max-after-decimal",
    "Hectares can have up to 2 digits after the decimal",
    (value) => {
      const afterDecimal = value.split('.')[1];
      return !afterDecimal || afterDecimal.length <= 2;
    }
  ),
});

export const dependantSchema = yup.object().shape({
  dependentName: yup
    .string()
    .required("Name is required")
    .matches(/^\S(?:.*\S)?$|^$/, "Name cannot contain leading or trailing spaces")
    .max(50, "Name must be at most 50 characters"),
  cyCropYear: yup.string().required("Crop Year is required"),
  dependentRelationshipType: yup
    .string()
    .required("Relationship is required"),
  dateOfBirth: yup.string().required("Date Of Birth is required"),
  schoolGrade: yup.string(),
  fatherName: yup
    .string()
    .required("Father Name is required")
    .matches(/^\S(?:.*\S)?$|^$/, "Name cannot contain leading or trailing spaces")
    .max(50, "Father name must be at most 50 characters"),
  motherName: yup
    .string()
    .required("Mother Name is required")
    .matches(/^\S(?:.*\S)?$|^$/, "Name cannot contain leading or trailing spaces")
    .max(50, "Mother name must be at most 50 characters"),
  attendSchool: yup.string().required("Attend School is required"),
  schoolGrade: yup
  .string()
  .when("attendSchool", {
    is: (attendSchool) => attendSchool === "Yes",
    then: () => yup.string().required("School Grade is required"),
    otherwise:() => yup.string(), // Optional if not "Yes"
  }),
});

export const adultSchema = yup.object().shape({
  partnerPropertyName: yup
    .string()
    .required("Name is required")
    .matches(/^\S(?:.*\S)?$|^$/, "Name cannot contain leading or trailing spaces")
    .max(50, "Name cannot contain more than 50 characters"),
  cpfNumber: yup
    .string()
    .required("CPF is required")
    .matches(/^\d+$/, "CPF must contain only digits")
    .test("cpf-valid", "Invalid CPF. CPF format is 000.000.000-00", (value) => {
      if (!value) return false;
      const cleanCPF = value.replace(/\D/g, "");
      if (cleanCPF.length !== 11) return false;
      if (/^(\d)\1{10}$/.test(cleanCPF)) return false;
      let sum = 0;
      for (let i = 0; i < 9; i++) {
        sum += parseInt(cleanCPF.charAt(i)) * (10 - i);
      }
      let remainder = sum % 11;
      let firstCheckDigit = remainder < 2 ? 0 : 11 - remainder;
      sum = 0;
      for (let i = 0; i < 10; i++) {
        sum += parseInt(cleanCPF.charAt(i)) * (11 - i);
      }
      remainder = sum % 11;
      let secondCheckDigit = remainder < 2 ? 0 : 11 - remainder;

      // Validate check digits
      return (
        firstCheckDigit === parseInt(cleanCPF.charAt(9)) &&
        secondCheckDigit === parseInt(cleanCPF.charAt(10))
      );
    }),
  regDt: yup.string().required("Registration Date is required"),
  relationshipType: yup.string().required("Relationship is required"),
});

export const environmentSchema = yup.object().shape({
  environmentalLicenseNumber: yup
    .string()
    .max(15, "Environmental License Number must be at most 15 characters")
    .matches(
      /^[a-zA-Z0-9]+$/,
      "Environmental License Number must contain only alphanumeric characters"
    )
    .required("Environmental License Number is required"),
  environmentalLicenseExpirationDt: yup
    .string()
    .required("Expiration Date is required"),
  elGrantExpirationDt: yup
    .string()
    .required("Grant Expiration Date is required"),
  grantNumber: yup
    .string()
    .max(15, "Grant Number must be at most 15 characters")
    .matches(
      /^[a-zA-Z0-9]+$/,
      "Grant Number must contain only alphanumeric characters"
    )
    .required("Grant Number is required"),
});

export const curingSchema = yup.object().shape({
  curingCode: yup.string().required("Curing Code is required"),
  curingUnitName: yup
    .string()
    .trim()
    .strict()
    .required("Curing Unit Name is required")
    .max(30, "Curing Unit Name must not be greater than 30 characters")
    .matches(
      /^\S.*\S$|^\S$/,
      "Curing Unit Name must not have leading or trailing spaces"
    ),
  tobaccoGroup: yup
    .string()
    .trim()
    .strict()
    .required("Tobacco Group is required"),
  linearMeter: yup
    .number()
    .typeError("Linear meter must be a number")
    .required("Linear meter is required")
    .positive("Linear meter must be a positive number")
    .min(1, "Linear meter must be at least 1")
    .max(2147483647, "Linear meter must be integer value"),
  capacity: yup
    .number()
    .typeError("Capacity must be a number")
    .required("Capacity is required")
    .positive("Capacity must be a positive number")
    .min(1, "Linear meter must be at least 1")
    .max(2147483647, "Linear capacity must be integer value"),
  newCuringUnit: yup
    .string()
    .trim()
    .strict()
    .required("New Curing Unit is required"),
});

export const spacingScheme = yup.object().shape({
  tobaccoType: yup
    .string()
    .trim()
    .strict()
    .required("Genetic Type is required"),
  unitOfMeasure: yup
    .string()
    .trim()
    .strict()
    .required("Unit Of Measure is required"),
  lineSpacing: yup
    .number()
    .typeError("Line Spacing must be a number")
    .required("Line Spacing  is required")
    .positive("Line Spacing  must be a positive number")
    .test(
      "max-decimals",
      "Line Spacing can have at most two decimal places",
      (value) => /^\d+(\.\d{1,2})?$/.test(value)
    ),
  plantSpacing: yup
    .number()
    .typeError("Plant Spacing must be a number")
    .required("Plant Spacing is required")
    .positive("Plant Spacing must be a positive number")
    .test(
      "max-decimals",
      "Line Spacing can have at most two decimal places",
      (value) => /^\d+(\.\d{1,2})?$/.test(value)
    )
    .test(
      "max-integer-digits",
      "Plant Spacing must not have more than 10 digits",
      (value) => {
        if (value) {
          const valueStr = value.toString();
          const [integerPart] = valueStr.split(".");
          if (integerPart.length > 10) {
            return false;
          }
        }
        return true;
      }
    ),
  noOfPlants: yup
    .number()
    .typeError("Number of Plants must be a number")
    .required("Number of Plants is required")
    .positive("Number of Plants must be a positive number")
    .integer("Number of Plants must be an integer"),
});

// {
//   "createdByUser": "string",
//   "modifiedDateTimeUTC": "2024-06-11T15:15:23.994Z",
//   "modifiedByUser": "string",
//   "asrSupplyId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//   "asrSupplyName": "string",
//   "asrCode": 0,
//   "asrExternalCode": 0,
//   "asrUnlockModality": "string",
//   "asrUnitOfMeasurement": "string",
//   "asrTarget": "string",
//   "asrRecommendationType": "string",
//   "asrRecommendation": 0,
//   "asrMaxPacking": 0,
//   "asrSupplyType": "string",
//   "asrThirdPartyAgriculturalSupply": true,
//   "countryId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//   "languageId": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
// }
export const asrSchema = yup.object().shape({
  asrSupplyName: yup.string().required("Supply Name is a required field"),
  asrCode: yup.string().required("Code is a required field"),
  asrExternalCode: yup.string().required("External Code is a required field"),
  asrUnlockModality: yup.string(),
  asrUnitOfMeasurement: yup.string(),
  asrTarget: yup.string(),
  asrRecommendationType: yup.string(),
  asrRecommendation: yup
    .number()
    .typeError("Recommendation must be a number")
    .test(
      "max-digits-before-decimal",
      "Recommendation must have at most 10 digits before the decimal",
      (val) => val === undefined || val.toString().split(".")[0].length <= 10
    )
    .test(
      "max-digits-after-decimal",
      "Recommendation must have at most 2 digits after the decimal",
      (val) =>
        val === undefined ||
        (val.toString().split(".")[1]
          ? val.toString().split(".")[1].length <= 2
          : true)
    )
    .test("max-total-length", "Recommendation is too large", (val) => {
      if (val === undefined) return true;
      return val.toString().length <= 13;
    }),
  asrMaxPacking: yup
    .number()
    .typeError("Max packing must be a number")
    .test(
      "max-digits-before-decimal",
      "Max packing must have at most 10 digits before the decimal",
      (val) => val === undefined || val.toString().split(".")[0].length <= 10
    )
    .test(
      "max-digits-after-decimal",
      "Max packing must have at most 2 digits after the decimal",
      (val) =>
        val === undefined ||
        (val.toString().split(".")[1]
          ? val.toString().split(".")[1].length <= 2
          : true)
    )
    .test("max-total-length", "Max packing is too large", (val) => {
      if (val === undefined) return true;
      return val.toString().length <= 13;
    }),
  asrSupplyType: yup.string(),
  asrThirdPartyAgriculturalSupply: yup.string(),
});

// export const cultureTypeSchema = yup.object().shape({
//   cultureGroupId: yup.string().required("Culture Group is required"),
//   cultureType: yup.string().required("Culture Type is required")
//                           .max(50, "Culture Type must be less than 50 characters")
//                           .matches(
//                                   /^\S.*\S$|^\S$/,
//                                   "Culture Type must not have leading or trailing spaces"
//                           ),
//   description: yup.string().required("Description is required")
//                            .max(255, "Description must be less than 255 characters")
//                            .matches(
//                                    /^\S.*\S$|^\S$/,
//                                    "Description must not have leading or trailing spaces"
//                            ),

// });

const uniqueDescriptions = (list) => {
  // const descriptions = list.map((item, index) => {
  //   if (item.cultureType != "") {
  //     return item.cultureType;
  //   } else {
  //     return index;
  //   }
  // });
  // const uniqueDescriptions = new Set(descriptions);
  // return uniqueDescriptions.size === descriptions.length;
  const functionalityNames = list.map((item, index) => {
    return item.functionalityName || index;
  });

  const uniqueFunctionalityNames = new Set(functionalityNames);
  return uniqueFunctionalityNames.size === functionalityNames.length;
};

// export const cultureSchema = yup.object().shape({
//   cuCultureCode: yup.string().required("Culture Code is required"),
//   cuCultureName: yup.string().required("Culture is required")
//     .max(50, "Culture must be less than 50 characters")
//     .matches(
//       /^[a-zA-Z0-9 ]*$/,
//       "Culture must not contain special characters"
//     )
//     .matches(
//       /^\S.*\S$|^\S$/,
//       "Culture must not have leading or trailing spaces"
//     ),
//   cultureTypes: yup.array().of(
//     yup.object().shape({
//       cultureType: yup.string().required('Culture Type is required'),
//       description: yup.string().required('Description is required'),
//       cultureGroupId: yup.string().required('Culture Group is required'),
//       status: yup.boolean(),
//     })
//   ).min(1, 'At least one culture type is required')
//     .test('unique-cultureType', 'Culture type must be unique', (cultureTypes) => uniqueDescriptions(cultureTypes)),
//   // cultureType: yup.string().required("Culture Type is required")
//   //                          .max(50, "Culture Type must be less than 50 characters")
//   //                          .matches(
//   //                           /^[a-zA-Z0-9 ]*$/,
//   //                           "Culture Type must not contain special characters"
//   //                         )
//   //                          .matches(
//   //                            /^\S.*\S$|^\S$/,
//   //                            "Culture Type must not have leading or trailing spaces"
//   //                          ),
//   // description: yup.string().required("Description is required")
//   //  .max(50, "Culture must be less than 50 characters")
//   //  .matches(
//   //   /^[a-zA-Z0-9 ]*$/,
//   //   "Culture must not contain special characters"
//   // )
//   //  .matches(
//   //    /^\S.*\S$|^\S$/,
//   //    "Culture must not have leading or trailing spaces"
//   //  ),

// });

const noSpacesOrSpecialChars = /^[a-zA-Z0-9 ]+$/;
const noLeadingOrTrailingSpaces = /^[^\s].*[^\s]$/;

export const cultureSchema = yup.object().shape({
  cuCultureCode: yup.number().required("Culture Code is required"),
  cuCultureName: yup
    .string()
    .required("Culture Name is required")
    .matches(noLeadingOrTrailingSpaces, "No leading or trailing spaces")
    .matches(noSpacesOrSpecialChars, "No Special Characters"),
  cuStatus: yup.boolean(),
  cultureTypes: yup
    .array()
    .of(
      yup.object().shape({
        cultureType: yup
          .string()
          // .required('Culture Type is required')
          // .matches(noLeadingOrTrailingSpaces, 'No leading or trailing spaces')
          // .matches(noSpacesOrSpecialChars, 'No Special Characters'),
          // .required('Culture Name is required')
          // .test('no-leading-or-trailing-spaces', 'No leading or trailing spaces', value => noLeadingOrTrailingSpaces.test(value || ''))
          // .test('no-special-characters', 'No Special Characters', value => noSpacesOrSpecialChars.test(value || '')),
          .required("Culture Type is required")
          .test(
            "no-leading-or-trailing-spaces",
            "No leading or trailing spaces",
            (value) =>
              value === undefined ||
              value === null ||
              value === "" ||
              noLeadingOrTrailingSpaces.test(value)
          )
          .test(
            "no-special-characters",
            "No Special Characters",
            (value) =>
              value === undefined ||
              value === null ||
              value === "" ||
              noSpacesOrSpecialChars.test(value)
          )
          .max(3, "Culture Type cannot be more than 3 characters"),

        description: yup
          .string()
          .required("Description is required")
          .max(20, "Max 20 characters allowed")
          // .matches(noLeadingOrTrailingSpaces, 'No leading or trailing spaces')
          // .matches(noSpacesOrSpecialChars, 'No Special Characters'),
          .test(
            "no-leading-or-trailing-spaces",
            "No leading or trailing spaces",
            (value) =>
              value === undefined ||
              value === null ||
              value === "" ||
              noLeadingOrTrailingSpaces.test(value)
          )
          .test(
            "no-special-characters",
            "No Special Characters",
            (value) =>
              value === undefined ||
              value === null ||
              value === "" ||
              noSpacesOrSpecialChars.test(value)
          ),

        cultureGroupId: yup.string().required("Culture Group is required"),
        status: yup.boolean(),
      })
    )
    .test("unique-description", "Description must be unique", function (value) {
      const descriptions = value.map((v) => v.description);
      return new Set(descriptions).size === descriptions.length;
    }),
});

export const varietySchema = yup.object().shape({
  vrVariety: yup.string().required("Variety Name is required"),
  cuCultureId: yup.string().required("Culture is required"),
  gtGeneticTypeId: yup.string().required("Genetic Type is required"),
  status: yup.boolean(),
  // Add other validations as needed
});


export const investmentSchema = yup.object().shape({
  igInvestmentGroupDescription: yup
    .string()
    .trim(
      "Investment Group Description must not contain leading or trailing spaces"
    )
    .strict()
    .required("Investment Group Description is required")
    .max(40, "Investment Group Description must be less than 40 characters"),
  newCuringUnit: yup
    .string()
    .trim()
    .strict()
    .required("Tobacco Type is required"),
});



export const expensesTypeSchema = yup.object().shape({
  expensesDescription: yup
    .string()
    .trim(
      "Expenses with 3rd Parties Group Description must not contain leading or trailing spaces"
    )
    .strict()
    .max(
      40,
      "Expenses with 3rd Parties Group Description must be less than 40 characters"
    )
    .required("Expenses with 3rd Parties Group Description is required"),
  tobaccoType: yup
    .array()
    .min(1, "At least one Tobacco Type must be selected")
    .required("At least one Tobacco Type must be selected")
    .nullable(),
  // .string()
  // .trim()
  // .required("Expenses with 3rd Parties Group Description is required")
  // .max(40, "Expenses with 3rd Parties Group Description must be less than 40 characters")
  // .matches(/^\S.*\S$|^\S$/, "Expenses with 3rd Parties Group Description must not contain leading or trailing spaces"),
  // .matches(
  //   /^[a-zA-Z0-9 ]*$/,
  //   "Expenses with 3rd Parties Group Description must not contain special characters"
  // ),
  // tobaccoType: yup
  //   .array("At least one Tobacco Type must be selected")
  //   .min(1, "At least one Tobacco Type must be selected")
  //   .required("Tobacco Type is required"),
});

export const furnaceSchema = yup.object().shape({
  ftFurnaceTypeName: yup
    .string()
    .trim("Furnace Type must not contain leading or trailing spaces")
    .strict()
    .required("Furnace Type is required")
    .max(49, "Furnace Type must be less than 50 characters"),
});


export const soilParameterSchema = yup.object().shape({
  sapExternalCode: yup
    .string()
    .trim("No leading or trailing spaces allowed")
    .strict()
    .required("External Field is required")
    .max(50, "Max 50 characters allowed."),

  sapParameterName: yup
    .string()
    .trim("No leading or trailing spaces allowed")
    .strict()
    .required("Parameter Name is required")
    .max(50, "Max 50 characters allowed."),
  sapAbbreviation: yup
    .string()
    .trim("No leading or trailing spaces allowed")
    .strict()
    .required("Abbreviation is required")
    .max(10, "Max 10 characters allowed."),
  sapUom: yup
    .string()
    .trim("No leading or trailing spaces allowed")
    .strict()
    .required("Unit of Measure is required")
    .max(10, "Max 10 characters allowed."),
  sapDataType: yup.string().required("Data Typ is required"),
});

export const transportAddSchema = yup.object().shape({
  fiscalID: yup.string().trim().required("Fiscal Id is required"),
  //   .matches(/^\d+$/, "Fiscal Id must contain only digits")
  //   .test("cpf-valid", "Invalid Fiscal Id", (value) => {
  //     if (!value) return false;

  //     const cleanCPF = value.replace(/\D/g, "");
  //     if (cleanCPF.length !== 11) return false;
  //     if (/^(\d)\1{10}$/.test(cleanCPF)) return false;

  //     let sum = 0;
  //     for (let i = 0; i < 9; i++) {
  //       sum += parseInt(cleanCPF.charAt(i)) * (10 - i);
  //     }
  //     let remainder = sum % 11;
  //     let firstCheckDigit = remainder < 2 ? 0 : 11 - remainder;

  //     sum = 0;
  //     for (let i = 0; i < 10; i++) {
  //       sum += parseInt(cleanCPF.charAt(i)) * (11 - i);
  //     }
  //     remainder = sum % 11;
  //     let secondCheckDigit = remainder < 2 ? 0 : 11 - remainder;

  //     return (
  //       firstCheckDigit === parseInt(cleanCPF.charAt(9)) &&
  //       secondCheckDigit === parseInt(cleanCPF.charAt(10))
  //     );
  //   }),

  transporterName: yup.string().trim().required("Transporter Name is required"),
  nationalityId: yup
    .string()
    .trim()
    .required("Nationality is required for Individuals"),
  maritalStatusId: yup.string().trim().required("Marital is required"),
  regionId: yup.string().trim().required("Region is required"),
  stateRegistrationNumber: yup
    .string()
    .trim()
    .required("State Registration Number is required"),
  transporterLicenseNumber: yup
    .string()
    .trim()
    .matches(
      /^[A-Za-z0-9]+$/,
      "Transporter License Number can only contain alphanumeric characters"
    )
    .required("Transporter License Number is required"),
  buyingStation: yup.string().trim().required("Buying Station is required"),
  buyingStationRoute: yup
    .string()
    .trim()
    .required("Buying Station Route is required"),
  supplyInvoicingUnit: yup
    .string()
    .trim()
    .required("Supply Invoicing Unit is required"),
  supplyInvoicingUnitRoute: yup
    .string()
    .trim()
    .required("Supply Invoicing Unit Route is required"),
  bankAccountType: yup
    .string()
    .trim()
    .required("Bank Account Type is required"),
  accountNumber: yup
    .string()
    .trim()
    .matches(/^\d+$/, "Account Number must be a number")
    .required("Account Number is required"),
  bankRegistrationId: yup.string().trim().required("Bank is required"),
  bankBranchRegistrationId: yup
    .string()
    .trim()
    .required("Branch Number is required"),
});

export const transportAddSchemaLegal = yup.object().shape({
  businessNumber: yup.string().trim().required("Business Number is required"),
  //   .matches(/^\d+$/, "Fiscal Id must contain only digits")
  //   .test("cpf-valid", "Invalid Fiscal Id", (value) => {
  //     if (!value) return false;

  //     const cleanCPF = value.replace(/\D/g, "");
  //     if (cleanCPF.length !== 11) return false;
  //     if (/^(\d)\1{10}$/.test(cleanCPF)) return false;

  //     let sum = 0;
  //     for (let i = 0; i < 9; i++) {
  //       sum += parseInt(cleanCPF.charAt(i)) * (10 - i);
  //     }
  //     let remainder = sum % 11;
  //     let firstCheckDigit = remainder < 2 ? 0 : 11 - remainder;

  //     sum = 0;
  //     for (let i = 0; i < 10; i++) {
  //       sum += parseInt(cleanCPF.charAt(i)) * (11 - i);
  //     }
  //     remainder = sum % 11;
  //     let secondCheckDigit = remainder < 2 ? 0 : 11 - remainder;

  //     return (
  //       firstCheckDigit === parseInt(cleanCPF.charAt(9)) &&
  //       secondCheckDigit === parseInt(cleanCPF.charAt(10))
  //     );
  //   }),

  transporterName: yup.string().trim().required("Transporter Name is required"),
  // nationalityId: yup.string().trim().required("Nationality is required for Individuals"),
  // maritalStatusId: yup.string().trim().required("Marital is required"),
  regionId: yup.string().trim().required("Region is required"),
  stateRegistrationNumber: yup
    .string()
    .trim()
    .required("State Registration Number is required"),
  transporterLicenseNumber: yup
    .string()
    .trim()
    .matches(
      /^[A-Za-z0-9]+$/,
      "Transporter License Number can only contain alphanumeric characters"
    )
    .required("Transporter License Number is required"),
  buyingStation: yup.string().trim().required("Buying Station is required"),
  buyingStationRoute: yup
    .string()
    .trim()
    .required("Buying Station Route is required"),
  supplyInvoicingUnit: yup
    .string()
    .trim()
    .required("Supply Invoicing Unit is required"),
  supplyInvoicingUnitRoute: yup
    .string()
    .trim()
    .required("Supply Invoicing Unit Route is required"),
  bankAccountType: yup
    .string()
    .trim()
    .required("Bank Account Type is required"),
  accountNumber: yup
    .string()
    .trim()
    .matches(/^\d+$/, "Account Number must be a number")
    .required("Account Number is required"),
  bankRegistrationId: yup.string().trim().required("Bank is required"),
  bankBranchRegistrationId: yup
    .string()
    .trim()
    .required("Branch Number is required"),
});

export const transportGeneralSchema = yup.object().shape({
  //   .matches(/^\d+$/, "Fiscal Id must contain only digits")
  //   .test("cpf-valid", "Invalid Fiscal Id", (value) => {
  //     if (!value) return false;

  //     const cleanCPF = value.replace(/\D/g, "");
  //     if (cleanCPF.length !== 11) return false;
  //     if (/^(\d)\1{10}$/.test(cleanCPF)) return false;

  //     let sum = 0;
  //     for (let i = 0; i < 9; i++) {
  //       sum += parseInt(cleanCPF.charAt(i)) * (10 - i);
  //     }
  //     let remainder = sum % 11;
  //     let firstCheckDigit = remainder < 2 ? 0 : 11 - remainder;

  //     sum = 0;
  //     for (let i = 0; i < 10; i++) {
  //       sum += parseInt(cleanCPF.charAt(i)) * (11 - i);
  //     }
  //     remainder = sum % 11;
  //     let secondCheckDigit = remainder < 2 ? 0 : 11 - remainder;

  //     return (
  //       firstCheckDigit === parseInt(cleanCPF.charAt(9)) &&
  //       secondCheckDigit === parseInt(cleanCPF.charAt(10))
  //     );
  //   }),

  transporterName: yup.string().trim().required("Transporter Name is required"),
  // nationalityId: yup.string().trim().required("Nationality is required for Individuals"),
  // maritalStatusId: yup.string().trim().required("Marital is required"),
  region: yup.string().trim().required("Region is required"),
  stateRegistrationNumber: yup
    .string()
    .trim()
    .required("State Registration Number is required"),
  // transporterLicenseNumber: yup.string()
  //   .trim()
  //   .matches(/^[A-Za-z0-9]+$/, "Transporter License Number can only contain alphanumeric characters")
  //   .required("Transporter License Number is required"),
  buyingStation: yup.string().trim().required("Buying Station is required"),
  buyingStationRoute: yup
    .string()
    .trim()
    .required("Buying Station Route is required"),
  supplyInvoicingUnit: yup
    .string()
    .trim()
    .required("Supply Invoicing Unit is required"),
  supplyInvoicingUnitRoute: yup
    .string()
    .trim()
    .required("Supply Invoicing Unit Route is required"),
  bankAccountType: yup
    .string()
    .trim()
    .required("Bank Account Type is required"),
  accountNumber: yup
    .string()
    .trim()
    .matches(/^\d+$/, "Account Number must be a number")
    .required("Account Number is required"),
  bankRegistrationId: yup.string().trim().required("Bank is required"),
  emailForInvoices: yup
    .string()
    .trim()
    .email("Invalid email address")
    .required("Email is required"),
});

export const transportControlDataSchema = yup.object().shape({
  sapExternalCode: yup.string().trim().required("Status is required"),
  sapParameterName: yup.string().trim().required("Status Reason is required"),
});
