import { http } from "../http-common";

const getProfileList = (pageModel) => {
  return http.get(
    `/profile/list?pageNumber=${pageModel.page}&size=${pageModel.pageSize}`
  );
};

const getFuntionalityOptions = () => {
  return http.get(`/functionalities`);
};

const createProfileRegistration = (data) => {
  return http.post("/profile", data);
};

const updateProfileRegistration = (data) => {
  return http.put(`/profile`, data);
};

const getProfilesSearchQuery = (query,pageModel) => {
  return http.get(
    `/profile/find?profileCode=${query}&profileName=${query}&pageNumber=${pageModel.page}&size=${pageModel.pageSize}`
  );
};

const ProfileService = {
  getProfileList,
  getFuntionalityOptions,
  createProfileRegistration,
  updateProfileRegistration,
  getProfilesSearchQuery,
};

export default ProfileService;
