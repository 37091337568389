import React, { useState } from "react";
import styled from "@emotion/styled";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CheckIcon from "@mui/icons-material/Check";
import Layout from "components/structure/Layout";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const options = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

const SaveCropYearRegistration = () => {
  const [selected, setSelected] = useState([]);

  const handleYearChange = (event) => {
    const value = event.target.value;
    // Allow only numerical characters and limit the input to 4 digits
    const sanitizedValue = value.replace(/\D/g, '').slice(0, 4);
    // Update state or do any other necessary operation with sanitizedValue
  };

const handleSelectAll = () => {
  setSelected(options);
};

const handleClearAll = () => {
  setSelected([]);
};

const handleChange = (event) => {
  const { value } = event.target;
  setSelected(value);
};

  

  return (
    <Layout>
      <Container>
        <FieldContainer>
          <TextField
            hiddenLabel
            type="number"
            
            id="filled-hidden-label-normal"
            defaultValue="2024"
            variant="filled"
            sx={{ maxWidth: 380, minWidth: 380 }}
            inputProps={{
                maxLength: 4,
                pattern: '[0-9]*', // Only allow numerical characters
                onInput: handleYearChange,
              }}
          />
          <FormControl sx={{ maxWidth: 380, minWidth: 380 }}>
            <InputLabel id="mutiple-select-label">
              Territory
            </InputLabel>
            <Select
              labelId="mutiple-select-label"
              multiple
              value={selected}
              input={<OutlinedInput label="Territory" />}
              onChange={handleChange}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
            
              {options.map((option) => (
                <MenuItem key={option} value={option}>
                  <ListItemIcon>
                    <Checkbox checked={selected.indexOf(option) > -1} />
                  </ListItemIcon>
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
                <SelectContainer>
                <Button
                  variant="contained"
                  onClick={handleSelectAll}
                  sx={buttonStyle}
                  disabled={selected.length === options.length}
                >
                  Select all
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleClearAll}
                  sx={buttonStyle}
                  disabled={selected.length === 0}
                >
                  Clear all
                </Button>
              </SelectContainer>
            </Select>
          </FormControl>
          <TextField
            sx={{ maxWidth: 380, minWidth: 380 }}
            id="outlined-basic"
            label="Parameter"
            variant="outlined"
          />
        </FieldContainer>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer sx={demoContainerStyle} components={["DatePicker"]}>
            <DatePicker label="Initial Date" />
          </DemoContainer>
          <DemoContainer sx={demoContainerStyle} components={["DatePicker"]}>
            <DatePicker label="End Date" />
          </DemoContainer>
        </LocalizationProvider>

        <FormGroup sx={formGroupStyle}>
          <div>Status</div>
          <FormControlLabel
            control={<Switch defaultChecked />}
            label="Inactive"
          />
        </FormGroup>
        <ButtonContainer>
          <Button
            sx={{
              borderRadius: 100,
              minWidth: 195,
              minHeight: 44,
              background: "#102A62",
            }}
            variant="contained"
            startIcon={<KeyboardBackspaceIcon />}
            // onClick={() => navigate("/saveCropYearRegistration")}
          >
            Cancel
          </Button>{" "}
          <Button
            sx={{
              borderRadius: 100,
              minWidth: 195,
              minHeight: 44,
              background: "#102A62",
              marginLeft: "1rem",
            }}
            variant="contained"
            startIcon={<CheckIcon />}
            // onClick={() => navigate("/saveCropYearRegistration")}
          >
            Save Crop Year Registration
          </Button>
        </ButtonContainer>
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FieldContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 75rem;
  margin-bottom: 2.5rem;
`;

const buttonStyle = {
  height: 33,
  width: 160,
  textTransform: "none",
  textDecoration: "underline",
};

const demoContainerStyle = {
  marginBottom: "2rem",
};

const formGroupStyle = {
  marginBottom: "2rem",
};

export default SaveCropYearRegistration;
