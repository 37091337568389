import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '1462px',
    // width: 'auto',
    maxWidth: '90vw',
    margin: 0,
    // overflowX: 'hidden', // Prevent horizontal scrollbar
  },
}));

const StyledDialogTitle = styled(DialogTitle)({
  backgroundColor: '#102A62',
  color: '#FFFFFF',
  fontFamily: 'Montserrat',
  fontWeight: 500,
  fontSize: '20px',
  padding: '15px 0',
  textAlign: 'center',
});

const StyledTableContainer = styled(TableContainer)({
  marginTop: '10px',
  // overflowX: 'auto', // Allow table to scroll horizontally if needed
});

const StyledTableCell = styled(TableCell)({
  fontFamily: 'Montserrat',
  fontSize: '20px',
  color: '#131619',
  whiteSpace: 'nowrap', // Prevent text wrapping in cells
});

const StyledTableRow = styled(TableRow)(({ theme, index }) => ({
  backgroundColor: index % 2 === 0 ? '#E9EBEE' : '#FFFFFF',
}));

const StyledButton = styled(Button)({
  fontFamily: 'Montserrat',
  fontWeight: 600,
  fontSize: '14px',
  textTransform: 'uppercase',
  padding: '13px 16px',
  width: '100px',
});

const StyledDialogActions = styled(DialogActions)({
  justifyContent: 'center',
  padding: '7px 0 20px 0',
});

const formatDateTime = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const day = date.getUTCDate().toString().padStart(2, '0');
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const year = date.getUTCFullYear();
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');

  return `${day}/${month}/${year} - ${hours}h${minutes}`;
};

const ConfirmationModal = ({ open, onClose, historyData }) => {
  const { t } = useTranslation();
  
  return (
    <StyledDialog open={open} onClose={onClose}>
      <StyledDialogTitle>{t('productionTargets.historyTitle')}</StyledDialogTitle>
      <DialogContent>
        <StyledTableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>{t('productionTargets.historyHectares')}</StyledTableCell>
                <StyledTableCell>{t('productionTargets.historyProductivity')}</StyledTableCell>
                <StyledTableCell>{t('productionTargets.historyContract')}</StyledTableCell>
                <StyledTableCell>{t('productionTargets.historyDateTime')}</StyledTableCell>
                <StyledTableCell>{t('productionTargets.historyUsername')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {historyData.map((row, index) => (
                <StyledTableRow key={index} index={index}>
                  <StyledTableCell>{row.hectaresTarget}</StyledTableCell>
                  <StyledTableCell>{row.productivityTarget}</StyledTableCell>
                  <StyledTableCell>{row.contractTarget}</StyledTableCell>
                  <StyledTableCell>{formatDateTime(row.modifiedDateTimeUTC)}</StyledTableCell>
                  <StyledTableCell>{row.createdByUser}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </DialogContent>
      <StyledDialogActions>
        <StyledButton
          variant="contained"
          onClick={onClose}
          style={{ backgroundColor: '#737D86', color: '#F8F8F8' }}
        >
          {t('productionTargets.close')}
        </StyledButton>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default ConfirmationModal;
