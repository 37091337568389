// TrackClicks.js

import { useEffect } from 'react';

const TrackClicks = ({ appInsights }) => {
    useEffect(() => {
        const trackClick = (event) => {
            appInsights.trackEvent({
                name: 'Click Event',
                properties: {
                    elementId: event.target.id,
                    elementText: event.target.innerText,
                    pagePath: window.location.pathname,
                },
            });
        };

        // Example: Track clicks on a specific button with id 'myButton'
        const button = document.getElementById('myButton');
        if (button) {
            button.addEventListener('click', trackClick);
        }

        return () => {
            if (button) {
                button.removeEventListener('click', trackClick);
            }
        };
    }, [appInsights]);

    return null; // or you can return a component if needed
};

export default TrackClicks;
