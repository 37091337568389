import { http } from "../http-common";

const getCropStageCalendarList = (query) => {
  return http.get(`/crop-stage-calendar`+query);
};


const updateCropStageCalendar = ( data) => {
  return http.put(`/crop-stage-calendar`, data);
};

const CropStageCalendarService = {
  getCropStageCalendarList,
  updateCropStageCalendar,
};

export default CropStageCalendarService;