import { useContext, useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import debounce from 'lodash/debounce';
//import styled from "@emotion/styled";
import {
    Button,
    FormControl,
    MenuItem,
    Grid,
    TextField,
    Box,
    InputAdornment,
    Typography,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import filter from "assets/filter.svg";
import iconSearchs from "assets/iconSearchs.svg";
import { SnackbarContext } from "context/snackBarContext";
import Layout from "components/structure/Layout";
import DataTable from "utils/DataTable";
import FieldNotebookSupplyService from "services/FieldNotebookSupplyService";
import { fieldNotebookSupplyColumns } from "./FieldNotebookSupplyTableColumns";
import { fetchFieldNotebookSupplyTypes } from "./util/FieldNotebookSupplyCommonUtil";
import { fetchLovDetails } from "utils/PickListHelper";
import {
    ADD_NEW_BTN_LABEL,
    ERROR_SEVERITY_STATUS
} from "utils/AgriCommonConstants";
import {
    STATUS_FILTER_FIELD_NAME,
    STATUS_FIELD_LABEL,
    SUPPLY_TYPE_FIELD_LABEL,
    SUPPLY_TYPE_FIELD_NAME,
    SUPPLY_TYPE_FILTER_FIELD_NAME,
    SEARCH_FILTER_PLACEHOLDER,
    SEARCH_FILTER_FIELD_NAME,
    LIST_AGRICULTURAL_SUPPLY_PAGE_HEADING,
    ACTIVE_FILTER_KEY,
    STATUS_LOV
} from "./util/FieldNotebookSupplyConstants";
import FieldNotebookNewTable from "./FieldNotebookNewTable";

// const DataContainer = styled.div`
//   margin-top: 2rem;
//   margin-bottom: 2rem;
// `;

const FieldNotebookSupplyRegistration = () => {
    const [fieldNotebookSupplies, setFieldNotebookSupplies] = useState([]);
    const [fieldNotebookSuppliesData, setFieldNotebookSuppliesData] = useState();
    const [loading, setLoading] = useState(false);
    const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
    const navigate = useNavigate();
    const [fieldNotebookSupplyTypes, setFieldNotebookSupplyTypes] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [totalItems, setTotalItems] = useState(5);
    const [filterCriteria, setFilterCriteria] = useState({
        searchBoxInput: "",
        supplyType: "",
        isActive: null,
    });
    const [searchKeyword, setSearchKeyword] = useState("");
    const [statusLovDetails, setStatusLovDetails] = useState([]);

    useEffect(() => {
        const fetchAndSetSupplyTypes = async () => {
            const fieldNotebookSupplyTypes = await fetchFieldNotebookSupplyTypes();
            setFieldNotebookSupplyTypes(fieldNotebookSupplyTypes);
        };

        const fetchAndSetStatusLovDetails = async () => {
            const fetchedLovDetails = await fetchLovDetails([STATUS_LOV]);
            const statusLov = fetchedLovDetails.find(picklist => picklist.pickListCode === STATUS_LOV)?.items || [];
            setStatusLovDetails(statusLov);
        };

        fetchAndSetSupplyTypes();
        fetchAndSetStatusLovDetails();
    }, []);

    useEffect(() => {
        fetchFieldNotebookSupplies(currentPage, itemsPerPage);
    }, [filterCriteria]);

    const fetchFieldNotebookSupplies = async (
        pageNumber = 0,
        size = itemsPerPage
    ) => {
        try {
            setLoading(true);
            const queryParams = {
                pageNumber: pageNumber,
                size: size,
                userInput: filterCriteria.searchBoxInput,
                supplyTypeId: filterCriteria.supplyType,
                isActive: filterCriteria.isActive ? (filterCriteria.isActive === '1' ? true : false) : null,
            };
            const response =
                await FieldNotebookSupplyService.getFieldNotebookSupplies(queryParams);
            const fieldNotebookSuppliesRows =
                response?.data?.response?.fieldNotebookSupplies.map(
                    (fieldNotebookSupply) => ({
                        id: fieldNotebookSupply.fieldNotebookSupplyId,
                        code: fieldNotebookSupply.externalCode,
                        description: fieldNotebookSupply.supplyDescription,
                        uom: fieldNotebookSupply.unitOfMeasure,
                        supplyType: fieldNotebookSupply.supplyTypeDescription,
                        status: fieldNotebookSupply.status ? "Active" : "Inactive",
                    })
                );
            setFieldNotebookSuppliesData(response?.data?.response?.fieldNotebookSupplies);
            setFieldNotebookSupplies(fieldNotebookSuppliesRows);
            setCurrentPage(pageNumber);
            setItemsPerPage(size);
            setTotalItems(response?.data?.response?.totalItems);
            setLoading(false);
        } catch (error) {
            if (error?.response?.status === 404) {
                setFieldNotebookSupplies([]);
                setFieldNotebookSuppliesData([])
            }
            setSeverity(ERROR_SEVERITY_STATUS);
            setLoading(false);
            setMessage(error.response.data.message)
            setOpenAlert(true);
        }
    };

    const handleCreateFieldNotebookSupply = async () => {
        navigate("/addAgriculturalSupplyRegistraion"); //use history
    };

    const handleSupplyTypeSelection = (event) => {
        setFilterCriteria((prevCriteria) => ({
            ...prevCriteria,
            supplyType: event.target.value,
        }));
    };

    const handleStatusSelection = (event) => {
        setFilterCriteria(prevCriteria => ({
            ...prevCriteria,
            isActive: event.target.value,
        }));
    };

    const handleSearchInputChange = (event) => {
        setSearchKeyword(event.target.value);
        debouncedSearch(event.target.value);
    };

    const debouncedSearch = useCallback(
        debounce((value) => setFilterCriteria(prevCriteria => ({
            ...prevCriteria,
            searchBoxInput: value,
        })), 500),
        []
    );

    const paginate = (pageNumber, itemsPerPage) => {
        fetchFieldNotebookSupplies(pageNumber - 1, itemsPerPage);
    };

    const resetFilterCriteria = (name) => {
        setFilterCriteria((prevCriteria) => ({
            ...prevCriteria,
            [name]: null,
        }));
        setCurrentPage(0);
    };

    const refreshData = () => {

        fetchFieldNotebookSupplies(currentPage, itemsPerPage)

    };

    console.log("DATACOM", fieldNotebookSuppliesData)
    return (
        <Layout
            title={LIST_AGRICULTURAL_SUPPLY_PAGE_HEADING}
            itemsPerPage={itemsPerPage}
            totalItems={totalItems}
            paginate={paginate}
            currentPage={currentPage}
        >
            <div>
                <>
                    <Box mt={2} sx={{ paddingBottom: "1rem", position: "relative" }}>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mb={2}
                            background="pink"
                        >
                            <Box display={"flex"} gap={4}>
                                <Grid item xs={12} sm={6} md={2}>
                                    <img src={filter} alt="Filter icon" />
                                </Grid>
                                <Grid item xs={12} sm={6} md={2}>
                                    <TextField
                                        id={SEARCH_FILTER_FIELD_NAME}
                                        autoComplete="off"
                                        name={SEARCH_FILTER_FIELD_NAME}
                                        placeholder={SEARCH_FILTER_PLACEHOLDER}
                                        value={searchKeyword}
                                        onChange={handleSearchInputChange}
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <img src={iconSearchs} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={2}>
                                    <FormControl sx={{ minWidth: "200px" }}>
                                        <TextField
                                            select
                                            id={SUPPLY_TYPE_FILTER_FIELD_NAME}
                                            name={SUPPLY_TYPE_FILTER_FIELD_NAME}
                                            label={SUPPLY_TYPE_FIELD_LABEL}
                                            value={filterCriteria.supplyType}
                                            onChange={handleSupplyTypeSelection}
                                            variant="outlined"
                                            SelectProps={{
                                                IconComponent: ArrowDropDownIcon,
                                                endAdornment: filterCriteria.supplyType && (
                                                    <CloseIcon
                                                        onClick={() => resetFilterCriteria(SUPPLY_TYPE_FIELD_NAME)}
                                                        style={{
                                                            cursor: "pointer",
                                                            marginRight: "12px",
                                                        }}
                                                    />
                                                ),
                                            }}
                                        >
                                            {fieldNotebookSupplyTypes.map((supplyType) => (
                                                <MenuItem
                                                    key={supplyType.supplyTypeId}
                                                    value={supplyType.supplyTypeId}
                                                >
                                                    {supplyType.supplyTypeDescription}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={2}>
                                    <FormControl sx={{ minWidth: "200px" }}>
                                        <TextField
                                            select
                                            id={STATUS_FILTER_FIELD_NAME}
                                            name={STATUS_FILTER_FIELD_NAME}
                                            label={STATUS_FIELD_LABEL}
                                            value={filterCriteria.isActive === null ? '' : filterCriteria.isActive}
                                            onChange={handleStatusSelection}
                                            variant="outlined"
                                            SelectProps={{
                                                IconComponent: ArrowDropDownIcon,
                                                endAdornment: filterCriteria.isActive !== null && (
                                                    <CloseIcon
                                                        onClick={() => resetFilterCriteria(ACTIVE_FILTER_KEY)}
                                                        style={{
                                                            cursor: "pointer",
                                                            marginRight: "12px",
                                                        }}
                                                    />
                                                ),
                                            }}
                                        >
                                            {statusLovDetails.map((statusLov) => (
                                                <MenuItem
                                                    key={"statusLov" + statusLov.pickListItemCode}
                                                    value={statusLov.pickListItemCode}
                                                >
                                                    {statusLov.pickListDescription}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </FormControl>
                                </Grid>
                            </Box>

                            <Box>
                                <Button
                                    sx={{
                                        borderRadius: 100,
                                        minWidth: 95,
                                        minHeight: 44,
                                        background: "#102A62",
                                        marginTop: { xs: 1 },
                                        marginLeft: { xs: 1 },
                                    }}
                                    variant="contained"
                                    startIcon={<AddCircleIcon />}
                                    onClick={handleCreateFieldNotebookSupply}
                                >
                                    {ADD_NEW_BTN_LABEL}
                                </Button>
                            </Box>
                        </Box>

                        {/* {fieldNotebookSuppliesData && (
                            // <DataContainer>
                            <DataTable
                                data={fieldNotebookSuppliesData}
                                rows={fieldNotebookSupplies}
                                columns={fieldNotebookSupplyColumns}
                            />
                            // </DataContainer>
                        )} */}
                        {!loading && fieldNotebookSuppliesData && fieldNotebookSuppliesData.length === 0 && (
                            <Typography variant="h6" align="center" color="textSecondary">
                                No Data Available
                            </Typography>
                        )}
                        {!loading && fieldNotebookSuppliesData && fieldNotebookSuppliesData.length > 0 && (
                            <FieldNotebookNewTable
                                // setSupplyTypenull={() => setSupplyData([])}
                                // callSupplyTypeListAPI={() => callSupplyTypeListAPI(currentPage, itemsPerPage)}
                                supplyData={fieldNotebookSuppliesData}
                                refreshData={refreshData}
                            />
                        )}
                    </Box>
                </>
            </div>
        </Layout>
    );
};

export default FieldNotebookSupplyRegistration;
