import React, { useContext, useEffect, useState } from "react";
import "./GanttChart.css";
import styled from "@emotion/styled";
import Layout from "components/structure/Layout";
import InputAdornment from "@mui/material/InputAdornment";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Grid,
  Box,
  TextField,
  FormHelperText,
  IconButton,
  CircularProgress,
  Checkbox,
  Popover,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import AddIcon from "@mui/icons-material/Add";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteIcon from "../../../../assets/roundDelete";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import PinkIosSwitch from "utils/PinkIosSwitch";
import { SnackbarContext } from "context/snackBarContext";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CultureService from "services/CultureService";
import CropSeasonService from "services/CropSeasonService";
import { formatDate } from "utils/utils";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import GSTC from "gantt-schedule-timeline-calendar";
import GanttChart from "./GanttChart";
import infoIcon from "../../../../assets/info.svg";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import RedSwitch from "utils/RedSwitch";
import { ADD_SUCCESS_MSG, UPDATE_SUCCESS_MSG } from "utils/AgriErrorMsg";

export const cropPhaseField = [
  {
    name: "cropPhaseName",
    label: "Crop Phase",
    type: "text",
    required: true,
  },

  {
    name: "cropPhaseStartDt",
    label: "Start Date",
    type: "date",
    required: true,
  },
  {
    name: "cropPhaseEndDt",
    label: "End Date",
    type: "date",
    required: true,
  },
];
const UnderLineText = styled.div`
  border: none;
  background: none;
  text-decoration: underline;
  cursor: pointer;
  margintop: "10px";
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
};
const Icon = styled.img`
  width: 40px; /* Set your icon size */
  height: 40px;
`;

const CropStageCalendar = () => {
  const location = useLocation();

  const {
    srCropYear,
    srCulture,
    status,
    seasonId,
    countryId,
    languageId,
    cropYearUid,
    hierachyId,
    hierachyName,
    year,
    cultureNameEdit,
    editSeason,
    editSeasonId,
    edit,
    details,
  } = location.state || {};
  console.log("🚀 ~ CropStageCalendar ~ editSeasonId:", editSeasonId);
  console.log("🚀 ~ CropStageCalendar ~ cultureNameEdit:", cultureNameEdit);
  console.log("year", year);
  const [loading, setLoading] = React.useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [cropYears, setCropYears] = useState([]);
  const [cultureData, setCultures] = useState([]);
  const [cropSeasons, setCropSeasons] = useState([]);
  const [cropSeasonsAll, setAllCropSeasons] = useState([]);
  const [selectedYearSeason, setYearSeason] = useState("");
  const [selectedYear, setYear] = useState("");
  const [cropPhaseDateCalendar, setCropPhaseDateCalendar] = useState([]);
  const [updateCalendar, setUpdateCalendar] = useState(false);

  const [cropPhaseList, setCropPhaseList] = useState([]);

  const [calendarRows, setCalendarRows] = useState([]);
  console.log(
    "🚀 ~ CropStageCalendar ~ cropPhaseDateCalendar:",
    cropPhaseDateCalendar
  );
  const [allHierarchies, setAllHierarchies] = useState([]);
  const [hierarchies, setHierarchies] = useState([]);
  const [selectedhierarchies, setSelectedHierarchies] = useState([]);

  const [selectedCropPhases, setSelectedCropPhases] = useState([]);
  const [cropPhases, setCropPhases] = useState([]);
  const [tobaccoType, setTobaccoType] = useState("");
  const [errors, setErrors] = useState({});
  const [showErrors, setShowErrors] = useState(false);
  const [switchValue, setSwitchValue] = useState(false);
  console.log("InitialCP", cropPhases)
  const [tabChangevalue, setTabChangeValue] = useState(0);
  const [values, setValues] = useState({});
  const [dateError, setDateError] = useState(false);
  const [allNodeData, setAllNodeData] = useState([]);
  const [hierachyIds, setHierarchyIds] = useState("");
  const [nodeLabel, setNodeLabel] = useState("");

  const [isHovered, setIsHovered] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [allTabData, setAllTabData] = useState([]);
  console.log("combinedArray===>", allTabData);
  const handleTabChange = (event, newValue) => {
    setTabChangeValue(newValue);
  };

  console.log("ALLHEIR", allHierarchies)

  const addTab = () => {
    setSelectedNode(null);
    setNodeLabel("");
    setAllTabData((data) => [...data, []]);
    setCropPhases([]);
    setSelectedCropPhases([]);
    setCropPhaseList([]);
  };
  // useEffect(() => {
  //   if (
  //     allTabData.length > 0 &&
  //     Array.isArray(allTabData[allTabData.length - 1]) &&
  //     allTabData[allTabData.length - 1].length === 0
  //   ) {
  //     // Execute your code here if the last element is an empty array
  //     console.log("Last element is an empty array!");

  //     // Clear cropPhases and selectedCropPhases
  //     setCropPhases([]);
  //     setSelectedCropPhases([]);
  //   }
  // }, [allTabData]);

  console.log("errors===>", errors);
  const [formData, setFormData] = useState({
    cultureName: cultureNameEdit || "",
    cyCropYear: year || "",
    cropYearUid: cropYearUid || "",
    season: editSeason || "",
    seasonId: seasonId || "",
    countryId: countryId || "",
    languageId: languageId || "",
    hierachyId: hierachyId || "",
    hierachyName: hierachyName || "",
    culture: cultureNameEdit || "",
  });

  console.log("seadon", formData);

  const navigate = useNavigate();
  console.log("selectedHierarches ", selectedhierarchies);
  useEffect(() => {
    // console.log("selectedCropPhases Data is ", selectedCropPhases);

    console.log("hierarchies data ", hierarchies);

    // var newCropPhase = cropPhases.filter(
    //   (cropPhase) => (cropPhase.startDate != null && cropPhase.endDate != null && cropPhase.cropPhaseName != "")
    // )
    // console.log("newCropPhase Data is ", newCropPhase)

    setAllTabData((data) => {
      var node = selectedNode;

      if (selectedNode === undefined || selectedNode === null) {
        if (
          allTabData &&
          allTabData.length > 0 &&
          allTabData[tabChangevalue]?.locationName
        ) {
          node = allTabData[tabChangevalue]?.locationName;
        }
      }
      const index = data.findIndex((tab) => tab.locationName === node);
      console.log("selectedNode", node);
      if (index !== -1) {
        const updatedData = [...data];
        const currentCropPhases = updatedData[index].cropPhases;
        const activeCropPhases = currentCropPhases.filter(
          (phase) => !phase.isDeleted
        );
        const newCropPhases = Array.isArray(cropPhases)
          ? cropPhases
          : [cropPhases];

        const updatedCropPhases = [...activeCropPhases];

        newCropPhases.forEach((newCropPhase) => {
          if (newCropPhase.endDate !== null) {
            const existingPhaseIndex = updatedCropPhases.findIndex(
              (phase) => phase.cropPhaseName === newCropPhase.cropPhaseName
            );

            if (existingPhaseIndex !== -1) {
              updatedCropPhases[existingPhaseIndex] = newCropPhase;
            } else {
              updatedCropPhases.push(newCropPhase);
            }
          }
        });

        updatedData[index] = {
          ...updatedData[index],
          cropPhases: updatedCropPhases,
        };

        console.log("updatedDataCrop", updatedData[index].cropPhases);
        return updatedData;
      } else {
        console.log("updatedDataCRopunchanged", data);
        return data;
      }
    });
    console.log("CROPPHASES1234", cropPhases);

    if (cropPhases != undefined && cropPhases.length > 0) {
      const length = cropPhases.length;
      if (length > 1) {
        var count = cropPhases.length - 1;
        var phase = cropPhases[count];
        console.log("Phase is ", phase);
        while (phase.isDeleted === true && count >= 0) {
          count--;
          if (count >= 0) {
            phase = cropPhases[count];
            console.log("Phase is inside loop ", phase);
          }
        }
        if (cropPhaseDateCalendar.length < cropPhases.length) {
          var newcount = cropPhases.length - 1;
          phase = cropPhases[newcount];
          console.log("Phase is cropPhaseDateCalendar ", phase);

          while (
            newcount >= 0 &&
            phase.isDeleted !== true &&
            (phase.startDate == null ||
              phase.endDate == null ||
              phase.cropPhaseName == "")
          ) {
            newcount--;
            phase = cropPhases[newcount];
            console.log("Phase is cropPhaseDateCalendar loop ", phase);
          }
        }
        console.log("phaseINFO", phase)
        if (
          phase.startDate != null &&
          phase.endDate != null &&
          phase.cropPhaseName != undefined &&
          phase.cropPhaseName != "" &&
          phase.cropPhaseName != null
          && phase.isDeleted !== true
        ) {
          //        setUpdateCalendar(false)
          // setCropPhaseDateCalendar(null)
          console.log("phaseINFOInside", cropPhases)
          const cropphases = cropPhases.filter(
            (cropPhase) => cropPhase.isDeleted !== true
          );
          const deletedcropphases = cropPhases.filter(
            (cropPhase) => cropPhase.isDeleted === true
          );
          var newSelectedCropPhases = selectedCropPhases;
          console.log("deletedcropphases ", newSelectedCropPhases);
          if (deletedcropphases != undefined && deletedcropphases.length > 0) {
            deletedcropphases.forEach((element, index) => {
              newSelectedCropPhases = newSelectedCropPhases.splice(index, 1);
            });
            setSelectedCropPhases((prevCropPhases) => [
              ...prevCropPhases,
              newSelectedCropPhases,
            ]);
          }

          setCropCalendar(newSelectedCropPhases, cropphases);
          console.log(
            "cropPhaseDateCalendar Length more ",
            cropPhaseDateCalendar
          );
          setUpdateCalendar(true);
        }
      } else if (length > 0) {
        var phase = cropPhases[0];

        console.log("Data is ", phase);

        console.log(
          "Data is ",
          phase.startDate,
          phase.endDate,
          phase.cropPhaseName
        );
        if (
          phase.startDate != null &&
          phase.endDate != null &&
          phase.cropPhaseName != undefined &&
          phase.cropPhaseName != "" &&
          phase.cropPhaseName != null &&
          phase.isDeleted != true
        ) {
          //  setCropPhaseDateCalendar(null)
          setCropCalendar(
            selectedCropPhases,
            cropPhases.filter((cropPhase) => cropPhase.isDeleted !== true)
          );
          setUpdateCalendar(true);
          console.log("cropPhaseDateCalendar Data is ", cropPhaseDateCalendar);
        }
      }
    }
  }, [cropPhases]);
  //  console.log("FormData", formData);

  // const handleAddRow = () => {
  //   //  setSubmoduleList([...submoduleList, { lineSpacing: "", plantSpacing: "" }]);
  //   if (cropPhases.size > 0)
  //     setCropPhases([
  //       ...cropPhases,
  //       { cropPhaseName: "", cropPhaseId: "", startDate: "", endDate: "" },
  //     ]);
  //   else
  //     setCropPhases([
  //       { cropPhaseName: "", cropPhaseId: "", startDate: "", endDate: "" },
  //     ]);
  // };
  console.log("🚀 ~ nodeIdALLHIERARCHIES:", allHierarchies);

  const validationSchema = Yup.object({
    lineSpacing: Yup.string()
      .required("Country is required")
      .max(50, "Country must be less than 50 characters"),
    // cyCropYear: Yup.number().required("Crop Year is required"),
    plantSpacing: Yup.string().required("Season Code is required"),
  });

  const formatPayload = (data) => {
    return {
      createdDateTimeUTC:
        allNodeData.createdDateTimeUTC || new Date().toISOString(),
      createdByUser: allNodeData.createdByUser || "RAY RAJU",
      modifiedDateTimeUTC:
        allNodeData.modifiedDateTimeUTC || new Date().toISOString(),
      modifiedByUser: allNodeData.modifiedByUser || "RAY RAJU",
      cropSeasonId: allNodeData.cropSeasonId, // This should be provided
      status: switchValue,
      season: allNodeData.season,
      isDeleted: allNodeData.isDeleted ?? false,
      countryCode: allNodeData.countryCode || "BRA",
      cropYear: allNodeData.cropYear || new Date().getFullYear(),
      cultureName: allNodeData.cultureName,
      cultureId: allNodeData.cultureId, // This should be provided
      csHierarchies: data?.map((hierarchy) => ({
        createdDateTimeUTC:
          hierarchy.createdDateTimeUTC || new Date().toISOString(),
        createdByUser: hierarchy.createdByUser || "RAY RAJU",
        modifiedDateTimeUTC:
          hierarchy.modifiedDateTimeUTC || new Date().toISOString(),
        modifiedByUser: hierarchy.modifiedByUser || "RAY RAJU",
        csHierarchyId: hierarchy.id, // This should be provided
        ihId: hierarchy.id, // This should be provided
        locationName: hierarchy.locationName,
        isDeleted: hierarchy.isDeleted ?? false,
        cropPhases: hierarchy?.cropPhases?.map((phase) => ({
          createdDateTimeUTC:
            phase.createdDateTimeUTC || new Date().toISOString(),
          createdByUser: phase.createdByUser || "RAY RAJU",
          modifiedDateTimeUTC:
            phase.modifiedDateTimeUTC || new Date().toISOString(),
          modifiedByUser: phase.modifiedByUser || "RAY RAJU",
          cropPhaseId: phase.cropPhaseId, // This should be provided
          cropPhaseName: phase.cropPhaseName,
          csPhaseId: phase.csPhaseId, // This should be provided
          startDate: phase.startDate,
          endDate: phase.endDate,
          isDeleted: phase.isDeleted ?? false,
          cpFunctionalities: phase?.cpFunctionalities?.map((func) => ({
            createdDateTimeUTC:
              func.createdDateTimeUTC || new Date().toISOString(),
            createdByUser: func.createdByUser || "RAY RAJU",
            modifiedDateTimeUTC:
              func.modifiedDateTimeUTC || new Date().toISOString(),
            modifiedByUser: func.modifiedByUser || "RAY RAJU",
            cpFunctionalityId: func.cpFunctionalityId, // This should be provided
            functionalityName: func.functionalityName, // This should be provided
            csFunctionalityId: func.csFunctionalityId, // This should be provided
            startDate: func.startDate,
            endDate: func.endDate,
            isDeleted: func.isDeleted ?? false,
          })),
        })),
      })),
    };
  };

  const formatPayloadPost = (data) => {
    return {
      createdDateTimeUTC: data.createdDateTimeUTC || new Date().toISOString(),
      createdByUser: data.createdByUser || "RAY RAJU",
      modifiedDateTimeUTC: data.modifiedDateTimeUTC || new Date().toISOString(),
      modifiedByUser: data.modifiedByUser || "RAY RAJU",
      cropSeasonId: data.cropSeasonId, // This should be provided
      status: data.status ?? false,
      season: data.season,
      isDeleted: data.isDeleted ?? false,
      countryCode: data.countryCode || "BRA",
      cropYear: data.cropYear || new Date().getFullYear(),
      cultureName: data.cultureName,
      cultureId: data.cultureId, // This should be provided
      csHierarchies: data?.csHierarchies?.map((hierarchy) => ({
        createdDateTimeUTC:
          hierarchy.createdDateTimeUTC || new Date().toISOString(),
        createdByUser: hierarchy.createdByUser || "RAY RAJU",
        modifiedDateTimeUTC:
          hierarchy.modifiedDateTimeUTC || new Date().toISOString(),
        modifiedByUser: hierarchy.modifiedByUser || "RAY RAJU",
        csHierarchyId: hierarchy.csHierarchyId, // This should be provided
        ihId: hierarchy.ihId, // This should be provided
        locationName: hierarchy.locationName || "Brazil-updt",
        isDeleted: hierarchy.isDeleted ?? false,
        cropPhases: cropPhases.map((phase) => ({
          createdDateTimeUTC:
            phase.createdDateTimeUTC || new Date().toISOString(),
          createdByUser: phase.createdByUser || "RAY RAJU",
          modifiedDateTimeUTC:
            phase.modifiedDateTimeUTC || new Date().toISOString(),
          modifiedByUser: phase.modifiedByUser || "RAY RAJU",
          cropPhaseId: phase.cropPhaseId, // This should be provided
          cropPhaseName: phase.cropPhaseName,
          csPhaseId: phase.csPhaseId, // This should be provided
          startDate: phase.startDate,
          endDate: phase.endDate,
          isDeleted: phase.isDeleted ?? false,
          cpFunctionalities: phase?.cpFunctionalities?.map((func) => ({
            createdDateTimeUTC:
              func.createdDateTimeUTC || new Date().toISOString(),
            createdByUser: func.createdByUser || "RAY RAJU",
            modifiedDateTimeUTC:
              func.modifiedDateTimeUTC || new Date().toISOString(),
            modifiedByUser: func.modifiedByUser || "RAY RAJU",
            cpFunctionalityId: func.cpFunctionalityId, // This should be provided
            functionalityName: func.functionalityName, // This should be provided
            csFunctionalityId: func.csFunctionalityId, // This should be provided
            startDate: func.startDate,
            endDate: func.endDate,
            isDeleted: func.isDeleted ?? false,
          })),
        })),
      })),
    };
  };

  // const formattedData = formatPayload(allNodeData);
  // console.log("🚀 ~ CropStageCalendar ~ formattedData:", formattedData);

  const handleRegistrationClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      createdDateTimeUTC: new Date().toISOString(),
      createdByUser: "RAY RAJU",
      modifiedDateTimeUTC: new Date().toISOString(),
      modifiedByUser: "RAY RAJU",
      status: switchValue,
      season: formData.season,
      isDeleted: false,
      countryCode: "BRA",
      cropYear: formData.cyCropYear || new Date().getFullYear(),
      cultureId: formData.cultureName, // This should be provided from culture Drop Down
      csHierarchies: allTabData.map((hierachy) => ({
        createdDateTimeUTC: new Date().toISOString(),
        createdByUser: "RAY RAJU",
        modifiedDateTimeUTC: new Date().toISOString(),
        modifiedByUser: "RAY RAJU",
        ihId: hierachy.id, // This should be provided from Hierarchy Tree
        isDeleted: false,
        cropPhases: hierachy?.cropPhases.map((phase) => ({
          createdDateTimeUTC:
            phase.createdDateTimeUTC || new Date().toISOString(),
          createdByUser: phase.createdByUser || "RAY RAJU",
          modifiedDateTimeUTC:
            phase.modifiedDateTimeUTC || new Date().toISOString(),
          modifiedByUser: phase.modifiedByUser || "RAY RAJU",
          cropPhaseId: phase.cropPhaseId, // This should be provided
          startDate: phase.startDate,
          endDate: phase.endDate,
          isDeleted: phase.isDeleted ?? false,
          cpFunctionalities: phase.cpFunctionalities?.map((func) => ({
            createdDateTimeUTC:
              func.createdDateTimeUTC || new Date().toISOString(),
            createdByUser: func.createdByUser || "RAY RAJU",
            modifiedDateTimeUTC:
              func.modifiedDateTimeUTC || new Date().toISOString(),
            modifiedByUser: func.modifiedByUser || "RAY RAJU",
            cpFunctionalityId: func.cpFunctionalityId, // This should be provided
            startDate: func.startDate || phase.startDate,
            endDate: func.endDate || phase.endDate,
            isDeleted: func.isDeleted ?? false,
          })),
        })),
        // startDate: hierarchy.startDate,
        // endDate: hierarchy.endDate,
      })),
    };

    try {
      setErrors({});
      console.log("payload sent", payload);
      // await validationSchema.validate(formData, { abortEarly: false });

      // const formattedData = formatPayloadPost(payload);
      // formattedData

      // Make API call to create crop season registration
      const response = await CropSeasonService.createCropSeasonRegistration(
        payload
      );
      if (response?.data) {
        setLoading(false);

        setSeverity("success");
        setMessage(ADD_SUCCESS_MSG);
        setOpenAlert(true);
        navigate("/cropSeasonRegistration");
      }
      // } catch (error) {
      //   if (error?.response?.status === 400) {
      //     setOpenAlert(true);
      //     setSeverity("error");
      //     setMessage("An error occurred while submitting the form");
      //     setLoading(false);
      //   }
      //   if (error?.response?.status === 500) {
      //     setLoading(false);
      //     setOpenAlert(true);
      //     setSeverity("error");
      //     setMessage("An error occurred while submitting the form");
      //   }
      // }
    } catch (error) {
      setLoading(false);

      let newError = {};
      if (error?.name && error.name === "ValidationError") {
        error?.inner?.forEach((err) => {
          newError[err.path] = err.message;
          setErrors(newError || error?.message);
          return;
        });
      } else {
        setLoading(false);

        setSeverity("error");
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
      }
    }
  };

  const handleEditRegistration = async () => {
    try {
      setErrors({});

      const formattedNewData = formatPayload(
        allTabData.slice(allNodeData.csHierarchies.length)
      );
      allNodeData.csHierarchies = allTabData
        .slice(0, allNodeData.csHierarchies.length)
        .concat(formattedNewData.csHierarchies);
      // const responseOld = await CropSeasonService.createCropSeasonRegistration(
      //   formattedOldData
      // );

      console.log("formatted Data", formattedNewData.csHierarchies);
      console.log("formatted Data Tab", allTabData);
      console.log("formatted Data Node", allNodeData);
      const response = await CropSeasonService.updateCropSeasonRegistration(
        allNodeData
      );

      if (response?.data) {
        setLoading(false);
        setSeverity("success");
        setMessage(UPDATE_SUCCESS_MSG);
        setOpenAlert(true);
        navigate("/cropSeasonRegistration");
      }
    } catch (error) {
      console.error("Error:", error);
      if (error?.response?.status === 400) {
        setOpenAlert(true);
        setSeverity("error");
        setMessage("An error occurred while submitting the form");
        setLoading(false);
      }
      if (error?.response?.status === 500) {
        setLoading(false);
        setOpenAlert(true);
        setSeverity("error");
        setMessage("An error occurred while submitting the form");
      }
    }
  };

  useEffect(() => {
    CropSeasonService.getCropYears()
      .then((response) => {
        setCropYears(response.data.response);
        if (edit || details) {
          setYear(year);
          console.log("Edit Status is ", status);
          setSwitchValue(status);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleCropYear = (event) => {
    const selectedYear = cropYears.find(
      (year) => year.cropYear === event.target.value
    );

    const selectedSeasons = cropSeasonsAll.filter(
      (season) => season.cropYear === event.target.value
    );

    setCropSeasons(selectedSeasons);
    setYear(event.target.value);
    setFormData({
      ...formData,
      cyCropYear: event.target.value,
      cropYearUid: selectedYear.cropYearUid,
    });
  };

  useEffect(() => {
    CultureService.getMenuItems()
      .then((response) => {
        console.log("Culture Data:", response.data);
        // Extract cuCultureName and cuCultureId from response
        const cultureFromApi = response.data.response.map((item) => ({
          cuCultureName: item.cuCultureName,
          cuCultureId: item.cuCultureId,
        }));
        setCultures(cultureFromApi);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const [menuItems, setMenuItems] = useState([]);
  console.log("🚀 ~ menuItems:", menuItems);

  const handleCulture = async (event) => {
    const { name, value } = event.target;
    console.log("cadwdw", name);
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const response = await CropSeasonService.getCropPhase({ id: value });
      console.log("🚀 ~ handleCulture ~ response:", response.data.response);
      // setFormData({
      //   ...formData,
      //   [name]: data, // Assuming the API response should be assigned to the form field
      // });

      // setCropPhases(response.data.response)

      const items = response.data.response.map((cropPhase) => ({
        value: cropPhase.cropPhaseId,
        label: cropPhase.cropPhaseName,
        cpFunctionalities: cropPhase.cpFunctionalities,
      }));
      setMenuItems(items);

      console.log("API response data:", items);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await CropSeasonService.getCropPhase({
          id: cultureNameEdit,
        });
        console.log("🚀 ~ fetchData ~ response:", response);
        console.log("🚀 ~ handleCulture ~ response:", response.data.response);

        // Assuming you might want to set formData or other state here
        // setFormData({
        //   ...formData,
        //   [name]: response.data.response, // Uncomment and modify as needed
        // });

        // setCropPhases(response.data.response) // Uncomment and modify as needed

        const items = response.data.response.map((cropPhase) => ({
          value: cropPhase.cropPhaseId,
          label: cropPhase.cropPhaseName,
          cpFunctionalities: cropPhase.cpFunctionalities,
        }));
        setMenuItems(items);

        console.log("API response data:", items);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    CropSeasonService.getCropSeasonsSearchWithoutPageLimit()
      .then((res) => {
        const rows = res?.data?.response?.cropSeason.map((cropseason) => ({
          season: cropseason.season,
          cropYear: cropseason.cropYear,
          cultureName: cropseason.cultureName,
          cultureId: cropseason.cultureId,
          seasonId: cropseason.cropSeasonId,
          countryCode: cropseason.countryCode,
          status: cropseason.status ? "Active" : "InActive",
        }));
        setLoading(false);
        setAllCropSeasons(rows);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    const fetchAllTabData = async () => {
      if (allTabData.length === 0 && menuItems.length > 0) {
        try {
          const response = await CropSeasonService.getCropSeasonsBasedonId({
            seasonId: editSeasonId,
          });
          console.log("incoming response", response.data.response);
          const cropPhases =
            response.data.response.csHierarchies[0]?.cropPhases || [];
          console.log("🚀 ~ handleNodeSelect ~ cropPhases:", cropPhases);
          setAllNodeData(response.data.response);
          setAllTabData(response.data.response.csHierarchies);

          setCropPhases(cropPhases);
          setSelectedCropPhases([]);
          cropPhases.forEach((element) => {
            const selectedCropPhase = menuItems.find(
              (phase) => phase.value === element.cropPhaseId
            );
            setSelectedCropPhases([selectedCropPhase]);
          });

          if (response?.data?.response?.csHierarchies?.length > 0) {
            setNodeLabel(
              response?.data?.response?.csHierarchies[0].locationName
            );
          }
        } catch (error) {
          console.error("There was an error!", error);
        }
      }
    };

    fetchAllTabData();
  }, [menuItems]);

  // useEffect(() => {
  //   if (edit) {
  //     setYear(year)
  //     CropSeasonService.getCropSeasonsBasedonId({
  //       seasonId: editSeasonId,
  //     })
  //       .then((res) => {
  //         setAllNodeData(res?.data.response)

  //         const rows = res?.data?.response?.csHierarchies.map((csHierarchy) => ({
  //           hierarchyId: csHierarchy.csHierarchyId,
  //           ihId: csHierarchy.ihId,
  //           locationName: csHierarchy.locationName,
  //           cropPhases: csHierarchy.cropPhases,
  //         }));
  //         console.log("response for Edit", res.data.response);
  //         // setLoading(false);
  //         // setCropPhases();
  //         //       setAllHierarchies(rows);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  // }, [])
  const handleSeasonChange = (event) => {
    // const selectedSeason = cropSeasons.find(
    //   (season) => season.season === event.target.value
    // );
    setYearSeason(selectedYear + "/" + event.target.value);

    setFormData({
      ...formData,
      season: event.target.value,
      // seasonId: selectedSeason.seasonId,
    });

    // CropSeasonService.getCropSeasonsBasedonId({
    //   seasonId: selectedSeason.seasonId,
    // })
    //   .then((res) => {
    //     const rows = res?.data?.response?.csHierarchies.map((csHierarchy) => ({
    //       hierarchyId: csHierarchy.csHierarchyId,
    //       ihId: csHierarchy.ihId,
    //       locationName: csHierarchy.locationName,
    //       cropPhases: csHierarchy.cropPhases,
    //     }));
    //     console.log("rdwdws", res.data.response);
    //     setLoading(false);
    //     setCropPhases();
    //     //       setAllHierarchies(rows);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };
  console.log("cropPhases Data is ", cropPhases);
  const handleEndDateChange = (newDate, identifier) => {
    if (!newDate || isNaN(new Date(newDate).getTime())) {
      // Handle invalid date logic here, e.g., setting an error state
      return;
    }

    // Convert the newValue to YYYY-MM-DD format
    const dateValue = new Date(newDate);
    const year = dateValue.getFullYear();
    const month = String(dateValue.getMonth() + 1).padStart(2, "0");
    const day = String(dateValue.getDate()).padStart(2, "0");
    //    const formattedDate = `${year}-${month}-${day}`; // Store as YYYY-MM-DD
    const formattedDates = `${year}-${month}-${day}`; // Store as YYYY-MM-DD

    var date = new Date(formattedDates);
    const formattedDate = date.toISOString();

    console.log("End Date change " + formattedDate);

    setCropPhases((prevPhases) =>
      prevPhases.map((phase, index) =>
        phase.cropPhaseId === identifier || index === identifier
          ? { ...phase, endDate: formattedDate, isDeleted: false }
          : phase
      )
    );
    //    setCropPhaseDateCalendar()
    //    console.log("cropPhaseDateCalendar " + cropPhaseDateCalendar.length)
  };

  const setCropCalendar = (selectedCropPhases, cropPhases) => {
    if (
      selectedCropPhases != null &&
      selectedCropPhases != undefined &&
      cropPhases != null
    ) {
      setCropPhaseDateCalendar(() =>
        selectedCropPhases.map((phase, index) =>
          phase != null
            ? {
              ...phase,
              id: index + 1,
              label: phase.label,
              rowId: index + 1,
              time: {
                start: GSTC.api
                  .date(cropPhases[index]?.startDate)
                  .startOf("day")
                  .valueOf(),
                end: GSTC.api
                  .date(cropPhases[index]?.endDate)
                  .endOf("day")
                  .valueOf(),
              },
            }
            : phase
        )
      );
      setCropPhaseList(() =>
        selectedCropPhases.map((phase, index) =>
          phase != null
            ? {
              ...phase,
              id: index + 1,
              name: phase.label
                ? phase.label
                : cropPhases[index]?.cropPhaseName,
              start: cropPhases[index]?.startDate
                ? cropPhases[index]?.startDate.split("T")[0]
                : "",
              end: cropPhases[index]?.endDate
                ? cropPhases[index]?.endDate.split("T")[0]
                : "",
              progress: 100,
              custom_class: "bar-colour",
            }
            : phase
        )
      );

      setCalendarRows(() =>
        selectedCropPhases.map((phase, index) =>
          phase != null
            ? {
              ...phase,
              id: index + 1,
              label: phase.label,
            }
            : phase
        )
      );
    }
  };
  //   const handleHierarchicalNodeChange = (event) => {
  //     const selectedHierarchy = allHierarchies.find(
  //       (hierachy) => hierachy.locationName === event.target.value
  //     );

  //  //   setSelectedHierarchy(selectedHierarchy);

  //     console.log("Selected Hierarchy " + selectedHierarchy);

  //     setCropPhases(selectedHierarchy.cropPhases);
  //     setFormData({
  //       ...formData,
  //       hierachyId: selectedHierarchy.hierarchyId,
  //       hierachyName: event.target.value,
  //     });
  //   };

  const handleDateChange = (date, fieldName) => {
    if (!date || date === "Invalid Date") {
      setDateError(true);
      setValues((prevValues) => ({
        ...prevValues,
        [fieldName]: "",
      }));
      return;
    }

    setDateError(false);

    const dateValue = new Date(date);
    if (isNaN(dateValue.getTime())) {
      setDateError(true);
      setValues((prevValues) => ({
        ...prevValues,
        [fieldName]: "",
      }));
      return;
    }

    const year = dateValue.getFullYear();
    const month = String(dateValue.getMonth() + 1).padStart(2, "0");
    const day = String(dateValue.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setValues((prevValues) => ({
      ...prevValues,
      [fieldName]: formattedDate,
    }));
  };

  useEffect(() => {
    const getTree = async () => {
      try {
        const response = await CropSeasonService.getLatestTree();
        console.log("🚀 ~ handleDelete ~ response:", response.data.response);
        if (response.status === 200) {
          setAllHierarchies([response.data.response]);
        }
      } catch (error) {
        console.error("There was an error!", error);
      }
    };

    getTree();
  }, []);
  const handleSwitchChange = (event) => {
    const newValue = event?.target?.checked;
    setSwitchValue(newValue);
  };

  const rowData = [
    {
      label: "Funcionality",
      startDate: "Start Date",
      endDate: "End Date",
    },
    {
      label: "Farmer Registration",
      startDate: "01-08-2023",
      endDate: "01-08-2024",
    },
    {
      label: "Contract Registration",
      startDate: "01-08-2024",
      endDate: "01-08-2025",
    },
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState(tobaccoType);
  console.log("🚀 ~ selected:", selected);
  // const handleToggle = (event, nodeIds) => {
  //   setExpanded(nodeIds);
  // };

  const handleToggle = (event, nodeIds) => {
    event.stopPropagation(); // Prevent the event from propagating
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeId) => {
    event.stopPropagation(); // Prevent the event from propagating
    setSelected(nodeId);
    setTobaccoType(nodeId); // Update the value of tobaccoType
    // setAnchorEl(null); // Close the dropdown
  };

  // const handleSelect = (event, nodeId) => {
  //   setSelected(nodeId);
  //   setTobaccoType(nodeId); // Update the value of tobaccoType
  //   setAnchorEl(null); // Close the dropdown
  // };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const renderTreeItems = (nodes) => (
  //   <TreeItem
  //     key={nodes.id}
  //     nodeId={nodes.id}
  //     label={nodes.locationName}
  //     // Ensure that expand/collapse icons are visible
  //     collapseIcon={<ExpandMoreIcon />}
  //     expandIcon={<ChevronRightIcon />}
  //   >
  //      <Checkbox
  //           style={{ marginRight: 8 }}
  //         />
  //     {Array.isArray(nodes.childHierarchies) &&
  //     nodes.childHierarchies.length > 0
  //       ? nodes.childHierarchies.map((node) => renderTreeItems(node))
  //       : null}
  //   </TreeItem>
  // );

  const renderTreeItems = (nodes) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      onClick={() => {
        if (
          !allTabData
            .slice(0, -1)
            .some((tab) => tab.locationName === nodes?.locationName)
        ) {
          handleNodeSelect(nodes);
        }
      }}
      label={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: "600",
            fontSize: "1rem",
          }}
        >
          <Checkbox
            style={{ marginRight: 8 }}
            checked={
              // checkedChildNode.length > 0 &&
              // checkedChildNode.indexOf(nodes) != -1
              // checkedChildNode != null && checkedChildNode == nodes
              edit
                ? allTabData.some(
                  (tab) => tab.locationName === nodes.locationName
                ) || selectedNode == nodes.locationName
                : allTabData
                  .slice(0, -1)
                  .some((tab) => tab.locationName === nodes.locationName) ||
                selectedNode == nodes.locationName
            }
            onChange={() => handleCheckboxChangeTree(nodes)}
            disabled={
              (edit
                ? allTabData.some(
                  (tab) => tab.locationName === nodes.locationName
                )
                : allTabData
                  .slice(0, -1)
                  .some((tab) => tab.locationName === nodes.locationName)) &&
              selectedNode !== nodes.locationName
            }
          />
          {console.log("nodes2", nodes)}
          <span>{nodes.locationName}</span>
        </div>
      }
      collapseIcon={<ExpandMoreIcon />}
      expandIcon={<ChevronRightIcon />}
    >
      {Array.isArray(nodes.childHierarchies) &&
        nodes.childHierarchies.length > 0
        ? nodes.childHierarchies.map((node) => renderTreeItems(node))
        : null}
    </TreeItem>
  );

  const open = Boolean(anchorEl);
  const [checkedNodeId, setCheckedNodeId] = useState(null);
  const [checkedNodeLocationName, setCheckedNodeLocationName] = useState(null);

  const [checkedChildNode, setCheckedChildNode] = useState(null);

  console.log("🚀 ~ checkedChildNode:", checkedChildNode);

  const handleCheckboxChange = (nodeId, locationName) => {
    // Set the clicked nodeId as checked and uncheck all others

    if (checkedNodeId == null) {
      console.log("🚀 ~ checkedNode:", locationName);
      setCheckedNodeId(nodeId);
      setCheckedNodeLocationName(locationName);
      setSelectedNode(locationName);
      setCheckedChildNode(null);
    } else {
      setCheckedNodeId(null);
      setCheckedNodeLocationName(null);
    }

    // handleCheckboxChangeTree(node)
  };

  // const handleCheckboxChangeTree = (node) => {
  //   // Set the clicked nodeId as checked and uncheck all others
  //   console.log("🚀 ~ handleCheckboxChangeTree:", node);
  //   var index = checkedChildNode.indexOf(node);
  //   if (index > -1) {
  //     // only splice array when item is found
  //     checkedChildNode.splice(index, 1); // 2nd parameter means remove one item only
  //   } else {
  //     setCheckedChildNode((nodes) => [...nodes, node]);
  //   }
  // };
  const handleCheckboxChangeTree = (node) => {
    // Set the clicked nodeId as checked and uncheck all others
    // console.log("🚀 ~ handleCheckboxChangeTree:", node);
    // var index = checkedChildNode.indexOf(node);
    // if (index > -1) {
    //   // only splice array when item is found
    //   checkedChildNode.splice(index, 1); // 2nd parameter means remove one item only
    // } else {
    const enrichedHierarchy = {
      ...node,
      cropPhases: [],
    };
    setAllTabData((data) => {
      if (data.length === 0) {
        return [enrichedHierarchy];
      }
      const updatedData = [...data];
      updatedData[updatedData.length - 1] = enrichedHierarchy;
      return updatedData;
    });
    setCheckedChildNode(node);
    setCheckedNodeId(null);
    setCheckedNodeLocationName(null);
    setSelectedNode(node.locationName);
    setCropPhases([]);
    setSelectedCropPhases([]);
    setCropPhaseList([]);
    // }
  };
  const [levelList, setLevelList] = useState([]);

  const [nextLevelName, setNextLevelName] = useState("");
  const [newLevelId, setNewLevelId] = useState("");

  const handleNodeSelect = async (item) => {
    console.log("node item", item);
    setNodeLabel(item?.locationName);
    setHierarchyIds(item.id);
    setHierarchies([...hierarchies, item]);
    const matchingLevel = levelList.find(
      (level) => level.hlcId === item.levelId
    );

    if (matchingLevel) {
      // Get the hlcLevelOrder of the matching level
      const currentLevelOrder = matchingLevel.hlcLevelOrder;

      // Find the next level based on hlcLevelOrder
      const nextLevel = levelList.find(
        (level) => level.hlcLevelOrder === currentLevelOrder + 1
      );

      if (nextLevel) {
        console.log("Next level object:", nextLevel);
        setNextLevelName(nextLevel?.hlcLevelName);
        setNewLevelId(nextLevel?.hlcId);
      } else {
        console.log("No next level found.");
      }
    } else {
      console.log("No matching level found.");
    }
    console.log("node is clicked", item);
    setSelectedHierarchies([item]);

    // setSelectedNodeData(item);
    // setParentId(item?.childHierarchies[0]?.parentId || item?.id);
    // setLevelId(item?.childHierarchies[0]?.levelId);
    // setUserValue(item?.linkedBackOfficeUsers);
    // setFarmerValue(item?.linkedFarmers);

    console.log("node is clicked", item.id);
    const id = item.id;
    console.log("🚀 ~ handleNodeSelect ~ id:", id);
    // if (edit || details) {

    // }
    //setSelectedNodeData(item);
    // setUserValue(item.linkedBackOfficeUsers);
    // setFarmerValue(item.linkedFarmers);
  };

  const handleCropPhaseChange = (e, identifier) => {
    const { value } = e.target;
    console.log("selectedCropPhase", identifier);
    // Find the selected crop phase data from the provided list
    const selectedCropPhase = menuItems.find((phase) => phase.value === value);
    console.log("handleCROpPhases", cropPhases)
    console.log("handleCROp", identifier)
    setCropPhases((prevPhases) =>
      prevPhases.map((phase, index) =>
        phase.cropPhaseId === identifier
          //  || index === identifier
          ? {
            ...phase,
            isDeleted: false,
            cropPhaseName: selectedCropPhase ? selectedCropPhase.label : "",
            cropPhaseId: value,
            cpFunctionalities: selectedCropPhase
              ? selectedCropPhase.cpFunctionalities
              : [],
          }
          : phase
      )
    );

    // setCropPhases((prePhases) => [
    //   ...prePhases,

    // ]);

    setSelectedCropPhases((prevCropPhases) => [
      ...prevCropPhases,
      selectedCropPhase,
    ]);
  };
  console.log("selectedCropPhase 2", cropPhases);
  const handleStartDateChange = (newDate, identifier) => {
    if (!newDate || isNaN(new Date(newDate).getTime())) {
      // Handle invalid date logic here, e.g., setting an error state
      return;
    }

    // Convert the selected date to YYYY-MM-DD format for storing
    const dateValue = new Date(newDate);
    const year = dateValue.getFullYear();
    const month = String(dateValue.getMonth() + 1).padStart(2, "0");
    const day = String(dateValue.getDate()).padStart(2, "0");
    const formattedDates = `${year}-${month}-${day}`; // Store as YYYY-MM-DD

    var date = new Date(formattedDates);
    const formattedDate = date.toISOString();
    console.log("Identifier", identifier)
    console.log("Identifier1", cropPhases)
    setCropPhases((prevPhases) =>
      prevPhases.map((phase, index) =>
        phase.cropPhaseId === identifier || index === identifier
          ? { ...phase, startDate: formattedDate, isDeleted: false }
          : phase
      )
    );
  };

  // const handleStartDateChange = (newDate, identifier) => {
  //   setCropPhases((prevPhases) =>
  //     prevPhases.map((phase, index) =>
  //       phase.cropPhaseId === identifier || index === identifier
  //         ? { ...phase, startDate: newDate }
  //         : phase
  //     )
  //   );
  // };

  // const handleEndDateChange = (newDate, identifier) => {
  //   setCropPhases((prevPhases) =>
  //     prevPhases.map((phase, index) =>
  //       phase.cropPhaseId === identifier || index === identifier
  //         ? { ...phase, endDate: newDate }
  //         : phase
  //     )
  //   );
  // };

  const formatDateToDisplay = (date) => {
    console.log("date", date);
    if (!date) return "";
    const [year, month, day] = date.split("T")[0].split("-");
    return `${day}-${month}-${year}`; // Convert YYYY-MM-DD to DD/MM/YYYY
  };

  useEffect(() => {
    setSelectedNode(allTabData[tabChangevalue]?.locationName);
    if (allTabData[tabChangevalue]?.cropPhases?.length > 0) {
      setCropPhases([]);
      setSelectedCropPhases([]);
      setCropPhaseList([]);
      allTabData[tabChangevalue].cropPhases.forEach((phase) => {
        setCropPhases((prevCropPhases) => [...prevCropPhases, phase]);
        setSelectedCropPhases((prevCropPhases) => [...prevCropPhases, phase]);
      });
    }
  }, [tabChangevalue]);

  const handleAddRow = () => {
    const newCropPhase = {
      cropPhaseName: "",
      startDate: null,
      endDate: null,
      isDeleted: false,
      cpFunctionalities: [], // Assuming functionalities can be added later
    };
    console.log("cropPHASES000", cropPhases)
    setCropPhases((prevCropPhases) => [...prevCropPhases, newCropPhase]);
    // if(cropPhases.length>0)
    //setUpdateCalendar(true)
  };

  // const handleDelete = (cropPhaseId) => {
  //   setCropPhases(
  //     (prevPhases) =>
  //       prevPhases
  //         .map((phase) => {
  //           if (phase.cropPhaseId === cropPhaseId) {
  //             if ("isDeleted" in phase) {
  //               // If isDeleted is present, set it to true
  //               return { ...phase, isDeleted: true };
  //             } else {
  //               // If isDeleted is not present, remove the phase by returning null
  //               return null;
  //             }
  //           }
  //           return phase;
  //         })
  //         .filter(Boolean) // Filter out any null values to remove the record
  //   );
  // };

  const handleDelete = (identifier) => {
    setUpdateCalendar(false);
    setCropPhases(
      (prevPhases) =>
        prevPhases
          .map((phase, index) => {
            // Check if the identifier is a cropPhaseId or an index
            if (phase.cropPhaseId === identifier || index === identifier) {
              if ("isDeleted" in phase) {
                // If isDeleted is present, set it to true
                return { ...phase, isDeleted: true };
              } else {
                // If isDeleted is not present, remove the phase by returning null
                console.log("insideCP", identifier);
                return null;
              }
            }
            return phase;
          })
          .filter(Boolean) // Filter out any null values to remove the record
    );
    setAllTabData((data) => {
      const updatedCropPhases = data[tabChangevalue].cropPhases.filter(
        (phase, index) =>
          !(phase.cropPhaseId === identifier || index === identifier)
      );
      console.log("moiUpdate", updatedCropPhases);
      return [
        ...data.slice(0, tabChangevalue), // Keep previous tabs unchanged
        {
          ...data[tabChangevalue], // Keep other properties of the current tab
          cropPhases: updatedCropPhases,
        },
        ...data.slice(tabChangevalue + 1), // Keep remaining tabs unchanged
      ];
    });
    //    setCropCalendar(selectedCropPhases, cropPhases)
    // setUpdateCalendar(true)
  };

  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <Layout title="Crop Season  Registration">
      <div style={{ marginTop: "1rem" }}>
        <Grid container spacing={2} columns={{ xs: 12, sm: 8, md: 12 }}>
          <FormControl
            sx={{
              width: { xs: "100%", md: 222 },
              minHeight: 50,
              marginRight: { xs: 0, md: 2 },
              marginLeft: "1rem",
            }}
          >
            <TextField
              select
              id="cultureName"
              labelId="cy-select-label"
              label={"Culture"}
              required
              value={formData.cultureName}
              name="cultureName"
              disabled={edit || details}
              onChange={(e) => handleCulture(e)}
              error={!!errors.culture}
            >
              {cultureData.map((culture) => (
                <MenuItem key={culture} value={culture.cuCultureId}>
                  {culture.cuCultureName}
                </MenuItem>
              ))}
            </TextField>
            <FormHelperText error={true}>{errors.cyCropYear}</FormHelperText>
          </FormControl>
          <FormControl
            sx={{
              width: { xs: "100%", md: 222 },
              minHeight: 50,
              marginRight: { xs: 0, md: 2 },
            }}
          >
            <TextField
              select
              id="cyCropYear"
              labelId="cyCropYear-label"
              label={"Year"}
              required
              value={formData.cyCropYear}
              name="cyCropYear"
              disabled={edit || details}
              onChange={(e) => handleCropYear(e)}
              error={!!errors.cyCropYear}
            >
              {cropYears.map((year) => (
                <MenuItem key={year.cropYearUid} value={year.cropYear}>
                  {year.cropYear}
                </MenuItem>
              ))}
            </TextField>
            <FormHelperText error={true}>{errors.tobaccoType}</FormHelperText>
          </FormControl>
          <FormControl
            sx={{
              width: { xs: "100%", md: 222 },
              minHeight: 50,
              marginRight: { xs: 0, md: 2 },
            }}
          >
            <TextField
              select
              id="season"
              labelId="tobacco-select-label"
              label={"Seasons"}
              value={formData.season}
              name="season"
              disabled={edit || details}
              onChange={(e) => handleSeasonChange(e)}
              error={!!errors.tobaccoType}
            >
              <MenuItem key="1" value="1">
                1
              </MenuItem>
              <MenuItem key="2" value="2">
                2
              </MenuItem>
            </TextField>
            <FormHelperText error={true}>{errors.tobaccoType}</FormHelperText>
          </FormControl>
          <TextField
            required
            id="filled-required"
            label=""
            defaultValue="Crop Seasons"
            value={
              editSeason
                ? formData.cyCropYear + "/" + editSeason
                : selectedYearSeason
            }
            variant="filled"
            disabled={true}
            sx={{ width: "20%", marginRight: { xs: 0, md: 2 } }}
          />
          <Box
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <Box sx={{ marginLeft: "1rem", paddingRight: "10px" }}>
              <InputLabel htmlFor="my-input">
                Status
                <br />
                {switchValue ? "Active" : "Inactive"}
              </InputLabel>
            </Box>
            <PinkIosSwitch
              checked={switchValue}
              onChange={handleSwitchChange}
            /> */}
            <FormControl component="fieldset">
              <FormLabel
                sx={{
                  color: "#0E2B63",
                  mb: 1.5,
                  ml: 0.5,
                  // mr:17,
                  // bgcolor:"red",
                  "&.Mui-focused": { color: "#0E2B63" },
                  "&:hover": { color: "#0E2B63" },
                  fontWeight: "500"
                }}
              >
                Status
              </FormLabel>
              <FormControlLabel
                sx={{
                  ml: 0.5,
                  display: "flex",
                  alignItems: "center",
                  gap: "0.4rem",
                }}
                control={
                  <RedSwitch
                    sx={{
                      marginRight: "11px",
                    }}
                    checked={switchValue}
                    onChange={handleSwitchChange}
                    name="status"
                    color="primary"
                  // disabled={details || edit === false}
                  />
                }
                label={
                  <Box sx={{ width: "3rem", mr: "5px", fontWeight: "500" }}>
                    {switchValue ? "Active" : "Inactive"}
                  </Box>
                }
                labelPlacement="start"
              />
            </FormControl>
          </Box>
        </Grid>

        <Grid container item xs={12} md={12} xl={12} marginTop={"2rem"}>
          <Grid container item xs={12} md={12} xl={12}>
            <ButtonContainer>
              <Grid container item xs={12} md={12} xl={12}>
                <ButtonContainer>
                  <div>
                    <div>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          width: "100%",
                        }}
                      >
                        <Tabs
                          value={tabChangevalue}
                          onChange={handleTabChange}
                          variant="scrollable"
                          scrollButtons="auto"
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            flexDirection: "row",
                            // width: "45vw",
                            "& .MuiTabs-indicator": {
                              display: "none",
                            },
                          }}
                        >
                          {/* {selectedNode != null && (
                            <Tab
                              sx={{
                                background: "rgba(233, 235, 238, 1)",
                                width: 200,
                                fontWeight: 600,
                                color: "rgba(172, 180, 186, 1)",
                                "&.Mui-selected": {
                                  color: "rgba(59, 69, 79, 1)",
                                  background: "#DCE1E6",
                                },
                                borderTopLeftRadius: "6px",
                                borderTopRightRadius: "6px",
                              }}
                              label={
                                selectedNode != null
                                  ? selectedNode
                                  : nodelist[0].locationName
                              }
                            />
                          )} */}
                          {
                            // allTabData != [] &&
                            // checkedChildNode.map((node) => (
                            allTabData.map((item, index) => {
                              console.log("Location Name:", item.locationName);
                              return (
                                <Tab
                                  sx={{
                                    background: "rgba(233, 235, 238, 1)",
                                    width: 200,
                                    fontWeight: 600,
                                    color: "rgba(172, 180, 186, 1)",
                                    "&.Mui-selected": {
                                      color: "rgba(59, 69, 79, 1)",
                                      background: "#DCE1E6",
                                    },
                                    borderTopLeftRadius: "6px",
                                    borderTopRightRadius: "6px",
                                    marginLeft: index > 0 ? "8px" : "0",
                                  }}
                                  label={
                                    // selectedNode != null &&
                                    // index == tabChangevalue
                                    //   ? selectedNode
                                    //   : item.locationName
                                    item.locationName == undefined
                                      ? selectedNode
                                      : item.locationName
                                  }
                                />
                              );
                            })
                            // ))
                          }
                          {(selectedNode != null || allTabData.length > 0) && (
                            <Tab
                              icon={
                                <AddIcon
                                  sx={{
                                    color: "#5A646E",
                                    fontSize: 30,
                                  }}
                                />
                              }
                              sx={{
                                background: "rgba(233, 235, 238, 1)",
                                borderTopLeftRadius: "6px",
                                borderTopRightRadius: "6px",
                                marginLeft: "8px",
                                minWidth: 20,
                              }}
                              onClick={addTab}
                            />
                          )}
                          {/* */}
                          {/* <Tab
                          sx={{
                            background: "rgba(233, 235, 238, 1)",
                            width: 200,
                            flexGrow: 1,
                            fontWeight: 600,
                            color: "rgba(172, 180, 186, 1)",
                            "&.Mui-selected": {
                              color: "rgba(59, 69, 79, 1)",
                              background: "#DCE1E6",
                            },
                            borderTopLeftRadius: "6px",
                            borderTopRightRadius: "6px",
                          }}
                          label="South Region | North Region"
                        /> */}
                        </Tabs>
                      </Box>
                    </div>
                    <Box
                      sx={{
                        background: "#DCE1E6",
                        minWidth: "95vw",
                        display: "flex",
                        height: "860px",
                        borderBottomLeftRadius: "6px",
                        borderBottomRightRadius: "6px",
                        borderTopRightRadius: "6px",
                        position: "relative",
                        marginBottom: "100px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                          minWidth: "40%",
                          background: "#DCE1E6",
                          borderBottomLeftRadius: "6px",
                        }}
                      >
                        {/* {tabChangevalue != 0 && ( */}
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "1rem",
                            height: "20px",
                            marginLeft: "20px",
                          }}
                        >
                          <FormControl
                            sx={{
                              width: { xs: "100%", md: 350 },
                              minHeight: 50,
                              marginRight: { xs: 0, md: 2 },
                            }}
                          >
                            <TextField
                              // select
                              id="hierarchy"
                              labelId="tobacco-select-label"
                              // label={
                              //   allTabData[tabChangevalue]
                              //     ? allTabData[tabChangevalue]?.locationName
                              //       ? allTabData[tabChangevalue]?.locationName
                              //       : selectedNode
                              //     : selectedNode
                              //       ? selectedNode
                              //       : "Hierarchical Node"
                              // }
                              label={
                                "Hierarchical Node"
                              }
                              value={allTabData[tabChangevalue]
                                ? allTabData[tabChangevalue]?.locationName
                                  ? allTabData[tabChangevalue]?.locationName
                                  : selectedNode
                                : selectedNode
                                  ? selectedNode
                                  : ""}
                              error={!!errors.tobaccoType}
                              sx={{
                                height: "100px",
                                input: { cursor: 'pointer' }
                              }}
                              onClick={handleClick}
                              InputLabelProps={{ shrink: true }}
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onMouseDown={(event) => event.stopPropagation()}
                                      onClick={isOpen ? handleClose : handleClick}
                                      sx={{
                                        "&:hover": { backgroundColor: "transparent" }
                                      }}
                                    >
                                      {isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />

                            <Popover
                              open={open}
                              anchorEl={anchorEl}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              disableAutoFocus
                              disableEnforceFocus
                            >
                              {allHierarchies !== [] && allHierarchies?.map(
                                (hierarchy, index) => {
                                  const nodeId = index;

                                  return (
                                    <TreeView
                                      aria-label="file system navigator"
                                      defaultCollapseIcon={
                                        <ExpandMoreIcon
                                          style={{ color: "#102A62" }}
                                        />
                                      }
                                      defaultExpandIcon={
                                        <ChevronRightIcon
                                          style={{ color: "#102A62" }}
                                        />
                                      }
                                      // defaultExpanded={[
                                      //   "5",
                                      //   ...hierarchy.importHierarchyDto.childHierarchies.map(
                                      //     (child, idx) => `5-${idx}`
                                      //   ),
                                      // ]}
                                      sx={{
                                        maxHeight: 300,
                                        flexGrow: 1,
                                        maxWidth: 400,
                                        overflowY: "auto",
                                        width: 318,
                                        padding: 2,
                                        paddingBottom: "100px",
                                      }}
                                    >
                                      {/* <TreeItem
                                        nodeId={`node-${hierarchy.id}`}
                                        // onClick={() =>
                                        //   handleNodeSelect(
                                        //     hierarchy.importHierarchyTreeDto
                                        //   )
                                        // }
                                        onClick={() => {
                                          if (
                                            !allTabData
                                              .slice(0, -1)
                                              .some(
                                                (tab) =>
                                                  tab.locationName ===
                                                  allHierarchies?.locationName
                                              )
                                          ) {
                                            handleNodeSelect(allHierarchies);
                                          }
                                        }}
                                        label={
                                          <span
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              fontWeight: "600",
                                              fontSize: "1rem",
                                            }}
                                          >
                                            <Checkbox
                                              style={{ marginRight: 8 }}
                                              checked={
                                                edit
                                                  ? allTabData.some(
                                                    (tab) =>
                                                      tab.locationName ===
                                                      allHierarchies?.locationName
                                                  ) ||
                                                  selectedNode ==
                                                  allHierarchies?.locationName
                                                  : allTabData
                                                    .slice(0, -1)
                                                    .some(
                                                      (tab) =>
                                                        tab.locationName ===
                                                        allHierarchies?.locationName
                                                    ) ||
                                                  selectedNode ==
                                                  allHierarchies?.locationName
                                              }
                                              onChange={() =>
                                                handleCheckboxChange(
                                                  nodeId,
                                                  allHierarchies?.locationName
                                                )
                                              }
                                              disabled={
                                                (edit
                                                  ? allTabData.some(
                                                    (tab) =>
                                                      tab.locationName ===
                                                      allHierarchies?.locationName
                                                  )
                                                  : allTabData
                                                    .slice(0, -1)
                                                    .some(
                                                      (tab) =>
                                                        tab.locationName ===
                                                        allHierarchies?.locationName
                                                    )) &&
                                                selectedNode !==
                                                allHierarchies?.locationName
                                              }
                                            />
                                            {allHierarchies?.locationName}
                                          </span>
                                        }
                                        defaultExpanded={true}
                                      >
                                        {renderTreeItems(hierarchy)}
                                      </TreeItem> */}
                                      {renderTreeItems(hierarchy)}
                                    </TreeView>
                                  );
                                }
                              )}
                            </Popover>
                            {/* <TextField
                              select
                              id="hierarchy"
                              labelId="tobacco-select-label"
                              label={"Hierachical Node"}
                              value={formData.hierachyName}
                              name="hierarchy"
                              onChange={handleHierarchicalNodeChange}
                              error={!!errors.tobaccoType}
                              sx={{ height: "100px" }}
                            >
                              {allHierarchies != null && allHierarchies.map((hierachy) => (
                                <MenuItem key={hierachy.ihId} value={hierachy.locationName}>
                                  {hierachy.locationName}
                                </MenuItem>
                              ))}
                            </TextField> */}
                            <FormHelperText error={true}>
                              {errors.tobaccoType}
                            </FormHelperText>
                          </FormControl>

                          <div
                            style={{
                              position: "relative",
                              display: "inline-block",
                              marginTop: "0.8rem",
                            }}
                          >
                            <span
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                            >
                              <Icon
                                src={infoIcon}
                                alt="My Icon"
                                style={{ width: "30px", height: "30px" }}
                              />
                            </span>

                            {isHovered && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "-8px",
                                  left: "40px",
                                  backgroundColor: "#E9EBEE",
                                  color: "#3B454F",
                                  padding: "10px",
                                  border: "1px solid #ACB4BA",
                                  whiteSpace: "normal",
                                  fontSize: "10px",
                                  minWidth: "350px",
                                  wordBreak: "break-word",
                                  overflowWrap: "break-word",
                                }}
                              >
                                The active calendar will be closest to the
                                user's hierarchy node. Please make sure there is
                                a calendar for the root node.
                              </div>
                            )}
                          </div>
                        </Box>
                        {/* )} */}
                        <div
                          style={{
                            marginTop: "50.0px",
                            gap: "1px",
                            marginBottom: "10px",
                            // background: "pink",
                          }}
                        >
                          {/* </div> */}
                          {/* <Box>
                              {cropPhases != null &&
                                cropPhases
                                  .filter(
                                    (cropPhase) => cropPhase.isDeleted !== true
                                  )
                                  .map((cropPhase, index) => (
                                    <div>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <IconButton
                                          key={cropPhase.index}
                                          id={"delete-" + cropPhase.index}
                                          onClick={() =>
                                            handleDelete(cropPhase.cropPhaseId)
                                          }
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                        <IconButton
                                          onClick={() => toggleExpand(index)}
                                        >
                                          <ExpandMoreIcon />
                                        </IconButton>
                                        <TextField
                                          required
                                          id={cropPhase.cropPhaseId}
                                          variant="outlined"
                                          label=""
                                          sx={{ minWidth: 250 }}
                                          value={cropPhase.cropPhaseName}
                                          name="cropPhase"
                                          onChange={(e) =>
                                            handleCropPhaseChange(
                                              e,
                                              cropPhase.cropPhaseId
                                            )
                                          }
                                          defaultValue=""
                                        />
                                        <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                        >
                                          <Grid container>
                                            <FormControl
                                              sx={{
                                                minWidth: "270px",
                                                margin: "5px 0px 5px 20px",
                                              }}
                                            >
                                              <DatePicker
                                                sx={{ width: 270 }}
                                                label="Start Date"
                                                format="DD/MM/YYYY"
                                                value={dayjs(
                                                  cropPhase.startDate
                                                )}
                                                onChange={(e) =>
                                                  handleStartDateChange(
                                                    e,
                                                    cropPhase.cropPhaseId
                                                  )
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    error={dateError}
                                                    helperText={
                                                      dateError === true &&
                                                      "Please select a start date"
                                                    }
                                                  />
                                                )}
                                              />
                                            </FormControl>
                                          </Grid>
                                        </LocalizationProvider>
                                        <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                        >
                                          <Grid container>
                                            <FormControl
                                              sx={{
                                                minWidth: "270px",
                                                margin: "5px 0px 5px 20px",
                                              }}
                                            >
                                              <DatePicker
                                                sx={{ width: 270, mr: "1rem" }}
                                                label="End Date"
                                                format="DD/MM/YYYY"
                                                value={dayjs(cropPhase.endDate)}
                                                onChange={(e) =>
                                                  handleEndDateChange(
                                                    e,
                                                    cropPhase.cropPhaseId
                                                  )
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    error={dateError}
                                                    helperText={
                                                      dateError === true &&
                                                      "Please select a start date"
                                                    }
                                                  />
                                                )}
                                              />
                                            </FormControl>
                                          </Grid>
                                        </LocalizationProvider>
                                      </Box>
                                      {expandedIndex === index && (
                                        <Box
                                          sx={{
                                            marginTop: 2,
                                            ml: "6rem",
                                            marginBottom: "20px",
                                          }}
                                        >
                                          <TableContainer component={Paper}>
                                            <Table>
                                              <TableHead>
                                                <TableRow>
                                                  <TableCell>
                                                    Functionality
                                                  </TableCell>
                                                  <TableCell>
                                                    Start Date
                                                  </TableCell>
                                                  <TableCell>
                                                    End Date
                                                  </TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {cropPhase.cpFunctionalities &&
                                                  cropPhase.cpFunctionalities.map(
                                                    (func, funcIndex) => (
                                                      <TableRow
                                                        sx={{
                                                          backgroundColor:
                                                            funcIndex % 2 === 0
                                                              ? "#E9EBEE"
                                                              : "#ffffff",
                                                          "&:not(:last-child)":
                                                          {
                                                            borderBottom:
                                                              "1px solid #e0e0e0",
                                                          },
                                                        }}
                                                        key={funcIndex}
                                                      >
                                                        <TableCell>
                                                          {
                                                            func.functionalityName
                                                          }
                                                        </TableCell>
                                                        <TableCell>
                                                          {dayjs(
                                                            func.startDate
                                                          ).format(
                                                            "DD/MM/YYYY"
                                                          )}
                                                        </TableCell>
                                                        <TableCell>
                                                          {dayjs(
                                                            func.endDate
                                                          ).format(
                                                            "DD/MM/YYYY"
                                                          )}
                                                        </TableCell>
                                                      </TableRow>
                                                    )
                                                  )}
                                              </TableBody>
                                            </Table>
                                          </TableContainer>
                                        </Box>
                                      )}
                                    </div>
                                  ))}
                            </Box> */}
                          <Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-around",
                                mb: 2
                              }}
                            >
                              <Typography>Crop Phase</Typography>
                              <Typography>Start Date</Typography>
                              <Typography>End Date</Typography>
                            </Box>
                            {cropPhases != null &&
                              cropPhases
                                .filter(
                                  (cropPhase) => cropPhase.isDeleted !== true
                                )
                                .map((cropPhase, index) => (
                                  <div key={cropPhase.cropPhaseId || index}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <IconButton
                                        id={"delete-" + index}
                                        onClick={() =>
                                          handleDelete(
                                            cropPhase.cropPhaseId
                                            || index
                                          )
                                        }
                                      >
                                        <DeleteIcon />
                                      </IconButton>

                                      <IconButton
                                        onClick={() => toggleExpand(index)}
                                      >
                                        {expandedIndex === index ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                                        {/* <ExpandMoreIcon /> */}
                                      </IconButton>
                                      <TextField
                                        select
                                        // required
                                        id={cropPhase.cropPhaseId}
                                        variant="outlined"
                                        label="Crop Phase"
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ minWidth: 250 }}
                                        value={cropPhase.cropPhaseId}
                                        disabled={
                                          !!cropPhase.cropPhaseId || details
                                        }
                                        name="cropPhase"
                                        onChange={(e) =>
                                          handleCropPhaseChange(
                                            e,
                                            cropPhase.cropPhaseId
                                            //  ||
                                            //  index
                                          )
                                        }
                                        defaultValue=""
                                      >
                                        {menuItems.map((item) => (
                                          <MenuItem
                                            key={item.value}
                                            value={item.value}
                                          >
                                            {item.label}
                                          </MenuItem>
                                        ))}
                                      </TextField>

                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <Grid container>
                                          <FormControl
                                            sx={{
                                              minWidth: "270px",
                                              margin: "5px 0px 5px 20px",
                                            }}
                                          >
                                            <DatePicker
                                              sx={{ width: 270 }}
                                              label="Start Date"
                                              format="DD/MM/YYYY"
                                              disabled={details}
                                              maxDate={dayjs(cropPhase.endDate)}
                                              value={dayjs(cropPhase.startDate)}
                                              onChange={(newDate) =>
                                                handleStartDateChange(
                                                  newDate,
                                                  cropPhase.cropPhaseId
                                                  //  || index
                                                )
                                              }
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  variant="outlined"
                                                  error={dateError}
                                                  disabled={details}
                                                  helperText={
                                                    dateError === true &&
                                                    "Please select a start date"
                                                  }
                                                />
                                              )}
                                            />
                                          </FormControl>
                                        </Grid>
                                      </LocalizationProvider>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <Grid container>
                                          <FormControl
                                            sx={{
                                              minWidth: "270px",
                                              margin: "5px 0px 5px 20px",
                                            }}
                                          >
                                            <DatePicker
                                              sx={{ width: 270, mr: "1rem" }}
                                              label="End Date"
                                              format="DD/MM/YYYY"
                                              disabled={details}
                                              value={dayjs(cropPhase.endDate)}
                                              minDate={dayjs(cropPhase.startDate)}
                                              onChange={(newDate) =>
                                                handleEndDateChange(
                                                  newDate,
                                                  cropPhase.cropPhaseId || index
                                                )
                                              }
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  variant="outlined"
                                                  error={dateError}
                                                  disabled={details}
                                                  helperText={
                                                    dateError === true &&
                                                    "Please select a start date"
                                                  }
                                                />
                                              )}
                                            />
                                          </FormControl>
                                        </Grid>
                                      </LocalizationProvider>
                                    </Box>
                                    {expandedIndex === index && (
                                      <Box
                                        sx={{
                                          marginTop: 2,
                                          ml: "6rem",
                                          marginBottom: "20px",
                                        }}
                                      >
                                        <TableContainer component={Paper}>
                                          <Table>
                                            <TableHead>
                                              <TableRow>
                                                <TableCell>
                                                  Functionality
                                                </TableCell>
                                                <TableCell>
                                                  Start Date
                                                </TableCell>
                                                <TableCell>End Date</TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {cropPhase.cpFunctionalities &&
                                                cropPhase.cpFunctionalities.map(
                                                  (func, funcIndex) => (
                                                    <TableRow
                                                      sx={{
                                                        backgroundColor:
                                                          funcIndex % 2 === 0
                                                            ? "#E9EBEE"
                                                            : "#ffffff",
                                                        "&:not(:last-child)": {
                                                          borderBottom:
                                                            "1px solid #e0e0e0",
                                                        },
                                                      }}
                                                      key={funcIndex}
                                                    >
                                                      <TableCell>
                                                        {func.functionalityName}
                                                      </TableCell>
                                                      <TableCell>
                                                        {dayjs(
                                                          func.startDate
                                                        ).format("DD/MM/YYYY")}
                                                      </TableCell>
                                                      <TableCell>
                                                        {dayjs(
                                                          func.endDate
                                                        ).format("DD/MM/YYYY")}
                                                      </TableCell>
                                                    </TableRow>
                                                  )
                                                )}
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      </Box>
                                    )}
                                  </div>
                                ))}
                          </Box>

                          {/* <Box>2</Box> */}
                        </div>
                        {console.log("dateError", dateError)}
                        {!details && (
                          <Button
                            sx={{
                              borderRadius: 50,
                              background: "#004F9F",
                              marginTop: 3,
                              marginLeft: 12,
                              width: 200,
                            }}
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={handleAddRow}
                            // disabled={tobaccoType === ""}
                            disabled={
                              (cropPhases[cropPhases.length - 1]?.startDate ===
                                null ||
                                cropPhases[cropPhases.length - 1]?.endDate ===
                                null) &&
                              cropPhases[cropPhases.length - 1]?.isDeleted ===
                              false
                            }
                          >
                            Add Crop Phase
                          </Button>
                        )}
                      </div>
                      <Box
                        sx={{
                          marginTop: 13,
                          marginLeft: "20px",
                          width: "500px",
                        }}
                      >
                        Calendar
                        <div width="100%">
                          {/* <TimelineChart /> */}
                          {/* {(selectedYear != "" && cropPhaseDateCalendar.length > 0) && */}
                          {/* {updateCalendar &&
                            <Timeline year={selectedYear} cropPhaseDate={cropPhaseDateCalendar} rows={calendarRows} />
                          } */}
                          {/* {updateCalendar && <TimelineChart />} */}
                          {updateCalendar && (
                            <GanttChart tasks={cropPhaseList} />
                          )}
                        </div>
                      </Box>
                      <Grid
                        style={{
                          display: "flex",
                          height: 200,
                          justifyContent: "center",
                          alingItems: "center",
                          position: "absolute",
                          bottom: 0,
                          left: "50%",
                          transform: "translateX(-50%)",
                          width: "100%",
                          padding: "0 16px",
                          boxSizing: "border-box",
                        }}
                        item
                        xs={12}
                        md={6}
                        xl={6}
                      >
                        <div>
                          <Button
                            sx={{
                              borderRadius: 1,
                              background: "#737D86",
                              marginTop: 3,
                              marginLeft: 1,
                              marginRight: 3,
                            }}
                            variant="contained"
                            disable
                            startIcon={<KeyboardBackspaceIcon />}
                            onClick={() => navigate("/cropSeasonRegistration")}
                          >
                            CANCEL
                          </Button>
                          {!details && (
                            <Button
                              sx={{
                                borderRadius: 1,
                                background: "#004F9F",
                                marginTop: 3,
                                marginLeft: 1,
                                height: { xs: "auto", md: "auto" },
                              }}
                              variant="contained"
                              onClick={
                                edit
                                  ? handleEditRegistration
                                  : handleRegistrationClick
                              }
                              disable={loading}
                              endIcon={<ArrowRightAltIcon />}
                            >
                              {loading && <CircularProgress size={20} />}

                              {edit
                                ? "Update CROP SEASON REGISTRATION"
                                : "SAVE CROP SEASON REGISTRATION"}
                            </Button>
                          )}
                        </div>
                      </Grid>
                    </Box>
                    {/* )} */}
                  </div>
                </ButtonContainer>
              </Grid>
            </ButtonContainer>
          </Grid>
        </Grid>

        <Grid
          style={{ justifyContent: "flex-end" }}
          item
          xs={12}
          md={12}
          xl={12}
        >
          {/* <div>
            <Button
              sx={{
                borderRadius: 50,
                background: "#004F9F",
                marginTop: 3,
                marginLeft: 1,
              }}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleAddRow}
              // disabled={tobaccoType === ""}
            >
              Add Crop Phase
            </Button>
          </div> */}
        </Grid>
      </div>
    </Layout>
  );
};

export default CropStageCalendar;
