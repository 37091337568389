import React, { useContext, useEffect, useReducer, useState } from "react";
import styled from "@emotion/styled";
import BatLogo from "../../../assets/bat.png";
import { AuthData } from "auth/AuthWarpper";
import { useNavigate } from "react-router";
import { TextField, Box, CircularProgress, Typography } from "@mui/material";
import { SnackbarContext } from "context/snackBarContext";
import { Button } from "@mui/material";

const Login = (props) => {
  const [batOneID, setbatOneID] = useState("")
  const [password, setPassword] = useState("")




  const { login, loading, user } = AuthData(); // Access login function from AuthData context
  const navigate = useNavigate();
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);


  const handleLogin = () => {
    if (batOneID == "") {
      setSeverity("error");
      setMessage("BAT one ID required");
      setOpenAlert(true);
    }
    else if (password == "") {
      setSeverity("error");
      setMessage("Password is required");
      setOpenAlert(true);
    }
    else if (password != batOneID) {
      setSeverity("error");
      setMessage("Incorrect password");
      setOpenAlert(true);
    }
    else {
      login(batOneID)
    }
  }


    return (
      <LoginContainer>
        <MainContainer>
          <ImageContainer>
            <Logo src={BatLogo} alt="Bat Logo" />
          </ImageContainer>
          <FormContainer>
            <Typography sx={{ color: "#0E2B63", textAlign: "center", fontWeight: 600, fontSize: "14px" }} >Welcome</Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Box sx={{ marginTop: "20px" }}>
                <Typography sx={{ color: "#0E2B63", textAlign: "left", fontWeight: 600, fontSize: "16px" }} >BAT One ID</Typography>
                <TextField
                  fullWidth
                  value={batOneID}
                  onChange={(e) => setbatOneID(e.target.value)}
                  name="batOneId"
                  placeholder={"BatOneId"} required variant="outlined" />
              </Box>
              <Box>
                <Typography sx={{ color: "#0E2B63", textAlign: "left", fontWeight: 600, fontSize: "16px" }} >Password</Typography>
                <TextField
                  fullWidth
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  name="password"
                  type="password"
                  placeholder={"Password"} required variant="outlined" />
              </Box>
              <Button
                style={{ backgroundColor: '#54688F', color: 'white', fontSize: "16px" }}
                size="large"
                variant="contained"
                onClick={handleLogin}>
                {loading && <CircularProgress size={20} />}
                Enter</Button>
            </Box>
          </FormContainer>
        </MainContainer>
      </LoginContainer>
    );
  };
  const FormContainer = styled.div`

  background-color: white;
  margin-top: 10%;
  padding:20px;
  border-radius:10px;
  width:90%
`;

  const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #012169;
  color: #fff;
  height: 100vh;
`;

  const MainContainer = styled.div`
  width: 23rem;
  height: 30rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:20px
`;

  const ImageContainer = styled.div`
  margin-top: 2rem;
`;

  const Logo = styled.img`
  width: 150px;
  height: auto;
`;

  const SustainabilityText = styled.div`
  margin-top: 1rem;
  font-size: 1.2rem;
`;

  const WelcomeText = styled.div`
  margin-top: 1rem;
  font-size: 1.2rem;
  position: absolute;
  top: 0;
  font-weight: bold;
`;

  const Form = styled.form`
  border-radius: 5px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffff;
  color: #012169;
  margin-top: 1rem;
  height: 20rem;
  position: relative;
`;

  const FormGroup = styled.div`
  margin-bottom: 20px;
  width: 80%;
`;

  const Label = styled.label`
  margin-bottom: 5px;
`;

  const Input = styled.input`
  padding: 8px;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 100%;
`;

  // const Button = styled.button`
  //   width: 50%;
  //   padding: 10px;
  //   background-color: #007bff;
  //   color: #fff;
  //   border: none;
  //   border-radius: 3px;
  //   cursor: pointer;
  // `;

  const Error = styled.div`
  color: red;
  position: absolute;
  bottom: 3rem;
`;

  export default Login;
