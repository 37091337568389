import {
  Autocomplete,
  Box,
  Button,
  debounce,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Layout from "components/structure/Layout";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React, { useCallback, useContext, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import GreenManureService from "services/GreenManureService";
import { useEffect } from "react";
import Lable from "utils/lables.json";
import InvestmentTable from "./FundingTable";
import InvestmentService from "services/InvestmentService";
import FundingService from "services/FundingService";
import { SnackbarContext } from "context/snackBarContext";
import label from "../growingLabel.json";

const FundingGroupRegistration = () => {
  const navigate = useNavigate();
  const [greenData, setGreenData] = useState([]);
  console.log("🚀 ~ InvestmentsGroupRegistration ~ greenData:", greenData);
  const [tobaccoType, setTobaccoType] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  // const [itemsPerPage, setItemsPerPage] = useState(7);
  const [itemsPerPage, setItemsPerPage] = useState(() => {
    return parseInt(localStorage.getItem("IPPFG")) || 5;
  });
  const [totalItems, setTotalItems] = useState(5); // Number of items per page
  const [manureOptions, setManureOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [status, setStatus] = useState("");

  console.log(
    "🚀 ~ InvestmentsGroupRegistration ~ manureOptions:",
    manureOptions
  );
  const [options, setOptions] = useState([]);
  console.log("🚀 ~ AddInvestment ~ options:", options);

  useEffect(() => {
    const fetchTobaccoTypes = async () => {
      try {
        const response = await InvestmentService.getTobaccoType();
        console.log("🚀 ~ fetchTobaccoTypes ~ response:", response);
        const options = response.data.response.map((item) => ({
          value: item.id, // Adjust according to your response structure
          label: `${item.cultureType} - ${item.tobaccoGroup}`, // Adjust according to your response structure
        }));
        console.log("🚀 ~ options ~ options:", options);

        setOptions(options);
      } catch (error) {
        console.error("Error fetching tobacco types:", error);
      }
    };

    fetchTobaccoTypes();
  }, []);



  const fetchManureDataFull = async () => {
    try {
      const response = await FundingService.searchFundingFull();
      const data = response?.data?.response?.fundingGroups;
      setManureOptions(data);
    } catch (error) {
      console.error("Error fetching Funding data:", error?.response?.data?.message);
      setMessage(error?.response?.data?.message || error?.message);
      setOpenAlert(true);
      setSeverity("error");
    }
  };


  const fetchManureData = async (keyword) => {
    try {
      const response = await FundingService.searchFundingData(
        keyword
      );
      const data = response?.data?.response?.fundingGroups;
      setManureOptions(data);
    } catch (error) {
      console.error("Error fetching manure data:", error);
      setManureOptions([]); // Clear options on error
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (keyword) {
  //     setLoading(true);
  //     const fetchManureData = async () => {
  //       try {
  //         const response = await FundingService.searchFundingData(
  //           keyword
  //         );
  //         const data = response?.data?.response?.fundingGroups;
  //         setManureOptions(data);
  //       } catch (error) {
  //         console.error("Error fetching manure data:", error);
  //         setManureOptions([]); // Clear options on error
  //       } finally {
  //         setLoading(false);
  //       }
  //     };
  //     fetchManureData();
  //   } else {
  //     fetchManureDataFull();
  //     // setManureOptions([]); // Clear options if no keyword
  //   }
  // }, [keyword]);

  const callManureApi = (pNumber, size) => {
    // setLoading(true);
    // setRecordsError(false);
    FundingService.getFunding({ pageNumber: pNumber, size: size })
      .then((res) => {
        // setLoading(false);
        // setRecordsError(false);
        setGreenData(res?.data?.response?.fundingGroups);
        setItemsPerPage(size);
        setTotalItems(res?.data?.response?.totalItems);
        setCurrentPage(pNumber);
      })
      .catch((error) => {
        console.log("Error", error?.response?.data?.message)
        setMessage(error?.response?.data?.message || error?.message);
        // setOpenAlert(true);
        // setSeverity("error");
      });
  };

  const filterManure = async (status) => {
    try {
      const response = await GreenManureService.filterManure(status);
      const data = response?.data?.response?.greenManures;
      setGreenData(data);
      setTotalItems(response?.data?.response?.totalItems);

      console.log(response.data); // Handle the API response
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  const filterInvestmentKeywordAndTobacco = async (keyword, tobacco) => {
    try {
      const response =
        await FundingService.filterInvestmentKeywordAndTobacco(
          keyword,
          tobacco
        );
      const data = response?.data?.response?.fundingGroups;
      setGreenData(data);
      setTotalItems(response?.data?.response?.totalItems);
      console.log(response.data); // Handle the API response
    } catch (error) {
      setGreenData([]);
      console.error("Error fetching data:", error);
    }
  };

  const filterInvestment = async (status) => {
    try {
      const response = await FundingService.filterInvestment(status);
      const data = response?.data?.response?.fundingGroups;
      setGreenData(data);
      setTotalItems(response?.data?.response?.totalItems);

      console.log(response.data); // Handle the API response
    } catch (error) {
      setGreenData([]);
      console.error("Error fetching data:", error);
    }
  };


  const handleTobaccoTypeChange = (e) => {
    const value = e.target.value;
    console.log("🚀 ~ handleTobaccoTypeChange ~ value:", value);
    setTobaccoType(value);

    console.log("keyword inside", value)
    if (keyword) {
      filterInvestmentKeywordAndTobacco(keyword, value);
    } else {
      filterInvestment(value == "pl" ? "" : value);
    }
  };
  // const clearFilter = async () => {
  //   setTobaccoType("");
  //   if (tobaccoType && keyword) {
  //     filterAll(keyword, tobaccoType);
  //   } else if (keyword) {
  //     fetchManureData(keyword);
  //   } else {
  //     callManureApi(currentPage, itemsPerPage);
  //   }
  // };

  // const filterManureAutocomplete = async (keyword) => {
  //   try {
  //     const response = await GreenManureService.filterManureAutocomplete(
  //       keyword
  //     );
  //     const data = response?.data?.response?.greenManures;
  //     setTobaccoType("");
  //     setGreenData(data);
  //   } catch (error) {
  //     console.error("Error fetching manure data:", error);
  //     return [];
  //   }
  // };




  const filterAll = async (keyword, tobacco, status) => {
    try {
      const response = await FundingService.filterAll(
        keyword,
        tobacco,
        status
      );
      const data = response?.data?.response?.fundingGroups;
      setGreenData(data);
      setTotalItems(response?.data?.response?.totalItems);
      console.log(response.data); // Handle the API response
    } catch (error) {
      setGreenData([]);
      console.error("Error fetching data:", error);
    }
  };

  const filterInvestmentKeywordAndStatus = async (keyword, status) => {
    try {
      const response = await FundingService.filterInvestmentKeywordAndStatus(
        keyword,
        status
      );
      const data = response?.data?.response?.fundingGroups;
      setGreenData(data);
      setTotalItems(response?.data?.response?.totalItems);
      console.log(response.data); // Handle the API response
    } catch (error) {
      setGreenData([]);
      console.error("Error fetching data:", error);
    }
  };

  const filterInvestmentTobaccoAndStatus = async (tobacco, status) => {
    try {
      const response = await FundingService.filterInvestmentTobaccoAndStatus(
        tobacco,
        status
      );
      const data = response?.data?.response?.fundingGroups;
      setGreenData(data);
      setTotalItems(response?.data?.response?.totalItems);
      console.log(response.data); // Handle the API response
    } catch (error) {
      setGreenData([]);
      console.error("Error fetching data:", error);
    }
  };

  const filterInvestmentStatus = async (status) => {
    try {
      const response = await FundingService.filterInvestmentStatus(status);
      const data = response?.data?.response?.fundingGroups;
      setGreenData(data);
      setTotalItems(response?.data?.response?.totalItems);

      console.log(response.data); // Handle the API response
    } catch (error) {
      setGreenData([]);
      console.error("Error fetching data:", error);
    }
  };

  const handleStatusChange = (status) => {

    setStatus(status === "Active" ? "Active" : status === "Inactive" ? "Inactive" : null); // Update the status filter
    setCurrentPage(0);
    const isActive =
      status === "Active" ? true : status === "Inactive" ? false : null;

    callSearchFundingAPI(
      0,
      itemsPerPage,
      keyword,
      tobaccoType,
      isActive
    );
    // Call based on tobacco type and status if tobaccoType is selected
    // if (tobaccoType && keyword) {
    //   if (value === "Active") {
    //     filterAll(keyword, tobaccoType, true);
    //   } else if (value === "Inactive") {
    //     filterAll(keyword, tobaccoType, false);
    //   } else {
    //     filterAll(keyword, tobaccoType, "");
    //   }
    // } else if (keyword) {
    //   if (value === "Active") {
    //     filterInvestmentKeywordAndStatus(keyword, true);
    //   } else if (value === "Inactive") {
    //     filterInvestmentKeywordAndStatus(keyword, false);
    //   } else {
    //     filterInvestmentKeywordAndStatus(keyword, "");
    //   }
    // } else if (tobaccoType) {
    //   if (value === "Active") {
    //     filterInvestmentTobaccoAndStatus(tobaccoType, true);
    //   } else if (value === "Inactive") {
    //     filterInvestmentTobaccoAndStatus(tobaccoType, false);
    //   } else {
    //     filterInvestmentTobaccoAndStatus(tobaccoType, "");
    //   }
    // } else {
    //   if (value === "Active") {
    //     filterInvestmentStatus(true);
    //   } else if (value === "Inactive") {
    //     filterInvestmentStatus(false);
    //   } else filterInvestmentStatus("")
    // }
  };


  // const clearFilterStatus = async () => {
  //   setStatus("");
  //   if (tobaccoType && keyword) {
  //     filterAll(keyword, tobaccoType);
  //   } else {
  //     callManureApi(currentPage, itemsPerPage);
  //   }
  // };

  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const callSearchFundingAPI = (pageNumber, size, Keyword, tobaccoType, status) => {
    setLoading(true);
    // setKeyword(Keyword);
    console.log("STATUSFUND", status)
    let query = {
      pageNumber,
      size,
      keyword: Keyword,
      tobaccoType: tobaccoType,
    };

    // Only add the status filter if it's defined (true or false)
    if (status !== null && status !== undefined) {
      query.status = status;
    }


    FundingService.searchFunding(query)
      .then((res) => {
        const Results = res?.data?.response?.fundingGroups || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setGreenData(Results); // Update trayData
        // setFilteredInvestmentData(Results); // Update filtered data
        setTotalItems(res?.data?.response?.totalItems || 0);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setGreenData([]);
        // setFilteredInvestmentData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    callManureApi(currentPage, itemsPerPage);
  }, []);

  const paginate = (pageNumber, itemsPerPage) => {
    // callManureApi(pageNumber - 1, itemsPerPage);
    localStorage.setItem("IPPFG", itemsPerPage);
    setCurrentPage(pageNumber - 1);
    if (keyword || status || tobaccoType) {
      const isActive =
        status === "Active" ? true : status === "Inactive" ? false : null;
      callSearchFundingAPI(pageNumber - 1, itemsPerPage, keyword, tobaccoType, isActive);
    } else {
      callManureApi(pageNumber - 1, itemsPerPage);
    }
  };

  const refreshData = () => {
    const totalItemsOnPage = greenData?.length ?? 0;

    if (keyword === "" && status === "" && tobaccoType == "") {
      console.log("NO FILTERS APPLIED")
      if (totalItemsOnPage === 1 && currentPage > 0) {
        callManureApi(currentPage - 1, itemsPerPage);
      } else if (totalItemsOnPage === 1 && currentPage == 0) {
        setGreenData([]);
      } else {
        callManureApi(currentPage, itemsPerPage);
      }
    }
    else {
      const isActive =
        status === "Active" ? true : status === "Inactive" ? false : null;
      if (totalItemsOnPage === 1 && currentPage > 0) {
        callSearchFundingAPI(currentPage - 1, itemsPerPage, keyword, tobaccoType, isActive);
      } else if (totalItemsOnPage === 1 && currentPage == 0) {
        setGreenData([]);
        callSearchFundingAPI(currentPage, itemsPerPage, keyword, tobaccoType, isActive);
      } else {
        callSearchFundingAPI(currentPage, itemsPerPage, keyword, tobaccoType, isActive);
      }
    }
  };


  const handleSearchChange = (event) => {
    const { value } = event.target;
    setKeyword(value);
    debouncedSearch(value, tobaccoType, status);
  };

  const debouncedSearch = useCallback(
    debounce((value, tobaccoType, status) => {
      console.log("StatusInside", status);
      callSearchFundingAPI(currentPage, itemsPerPage, value, tobaccoType, status)
    }, 300),
    [itemsPerPage]
  );

  return (
    <Layout
      background="#ffffff"
      title="Funding Group Registration List"
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      useNewPagination={true}
    >
      <Grid
        container
        xs={12}
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          // padding: "1rem",
          padding: "8px",
          height: "130px",
        }}
      >
        <Grid item xs={12} sx={{ display: "flex" }}>
          <ArrowDropDownIcon sx={{ marginRight: 1 }} />
          <Typography>{Lable.filtersLable}</Typography>
        </Grid>

        {/* <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}> */}
        <Grid container spacing={2}>
          {/* <Grid item xs={8} display="flex" justifyContent="space-between"> */}
          <Grid item xs={12} sm={6} md={10}>
            <Grid container spacing={2}>
              {/* <Box> */}
              {/* <FormControl sx={{ width: "35vw", flexGrow: 1 }}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={manureOptions}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "2px 8px", // Adjust padding for the input content
                    minHeight: "50px", // Adjust the overall height
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1.2em", // Adjust label spacing if needed
                  },
                }}
                InputProps={{
                  style: { height: "60%" }, // Directly setting the height
                }}
                getOptionLabel={(option) => option.fundingGroupDescription}
                onChange={(event, newValue) => {
                  if (newValue) {
                    // const result =
                    //   await FundingService.filterFundingAutocomplete(
                    //     newValue.fundingGroupDescription
                    //   );
                    // const data = result?.data?.response?.fundingGroups;
                    // console.log("🚀 ~ onChange={ ~ data:", data);
                    // // setTobaccoType("");
                    // setGreenData(data);
                    // setTotalItems(result?.data?.response?.totalItems);
                    // if (result) {
                    //   console.log("API response:", result);
                    // }
                    setKeyword(newValue.fundingGroupDescription);
                    callSearchFundingAPI(
                      0,
                      itemsPerPage,
                      newValue.fundingGroupDescription,
                      tobaccoType,
                      status
                    )
                  } else {
                    // // When the clear button (DeleteIcon) is clicked
                    // console.log("delete"); // Log "delete"
                    // // setTobaccoType("");
                    // callManureApi(currentPage, itemsPerPage);
                    setKeyword("");
                    callSearchFundingAPI(0, itemsPerPage, "", tobaccoType, status);
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  setKeyword(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Funding Group Description"
                    InputLabelProps={{ shrink: true }}
                    placeholder="-"
                    sx={{
                      '& .MuiInputBase-input::placeholder': {
                        opacity: 1,
                      },
                    }}
                  />
                )}
              />
            </FormControl> */}
              <Grid item xs={12} sm={5}>
                <TextField
                  autoComplete="off"
                  name="description"
                  label="Funding Group Description"
                  value={keyword}
                  onChange={handleSearchChange}
                  placeholder="-"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    width: "100%",
                    height: "46px",
                    '& .MuiInputBase-input::placeholder': {
                      opacity: 1,
                    },
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                      // width: "10rem",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                  }}
                ></TextField>
              </Grid>
              {/* </Box> */}

              {/* <Box> */}
              <Grid item xs={12} sm={4}>
                <FormControl
                  sx={{ width: "100%" }}
                >
                  <TextField
                    select
                    id="tobaccoType"
                    labelId="tobacco-select-label"
                    label={"Tobacco Type"}
                    value={tobaccoType || "pl"}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px", // Adjust padding for the input content
                        minHeight: "50px", // Adjust the overall height
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em", // Adjust label spacing if needed
                      },
                    }}
                    onChange={(event) => {
                      const newValue = event.target.value;
                      if (newValue) {
                        setTobaccoType(newValue);
                        callSearchFundingAPI(
                          0,
                          itemsPerPage,
                          keyword,
                          newValue,
                          status
                        )
                      } else {
                        // // When the clear button (DeleteIcon) is clicked
                        // console.log("delete"); // Log "delete"
                        // // setTobaccoType("");
                        // callManureApi(currentPage, itemsPerPage);
                        setTobaccoType("");
                        callSearchFundingAPI(0, itemsPerPage, keyword, "", status);
                      }
                    }
                      // handleTobaccoTypeChange
                    }
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: { height: "50px" },
                      endAdornment: tobaccoType && (
                        <InputAdornment position="end">
                          <ClearIcon
                            onClick={
                              () => {
                                setTobaccoType("")
                                callSearchFundingAPI(0, itemsPerPage, keyword, "", status);
                              }
                              // clearFilter
                            }
                            style={{
                              width: "18px",
                              cursor: "pointer",
                              marginRight: "20px",
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem value="pl">{Lable.selectanOption}</MenuItem>
                    {options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              {/* </Box> */}

              {/* <Box> */}
              <Grid item xs={12} sm={3}>
                <FormControl
                  sx={{ width: "100%" }}
                >
                  <TextField
                    select
                    id="tobaccoType"
                    name="status"
                    labelId="tobacco-select-label"
                    label="Status"
                    value={status || "pl"}
                    onChange={(event) => handleStatusChange(event.target.value)}
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                        // width: "10rem",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    }}
                    InputProps={{
                      endAdornment: status && status != "pl" && (
                        <InputAdornment position="end">
                          <ClearIcon
                            onClick={() => handleStatusChange("")}
                            style={{
                              width: "18px",
                              cursor: "pointer",
                              marginRight: "20px",
                            }}
                          />
                        </InputAdornment>
                      ),
                      style: { height: "50px" },
                    }}
                  >
                    <MenuItem value="pl">{Lable.selectanOption}</MenuItem>
                    <MenuItem value="Active">{Lable.activeLable}</MenuItem>
                    <MenuItem value="Inactive">{Lable.inactiveLable}</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              {/* </Box> */}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} md={2} textAlign={"right"}>

            {/* <Box> */}
            <Button
              sx={{
                borderRadius: 1,
                minWidth: 95,
                // minHeight: 44,
                height: "60%",
                background: "#004F9F",
                // marginTop: { xs: 1 },
                // marginLeft: { xs: 1 },
                "&:hover": {
                  backgroundColor: "#102A62",
                },
              }}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => navigate("/addFunding")}
            >
              {Lable.addNewButton}
            </Button>
            {/* </Box> */}
          </Grid>
        </Grid>
      </Grid>
      <Box>
        {
          // !loading &&
          //  greenData &&
          greenData.length === 0 && (
            <Typography variant="h6" align="center" color="textSecondary">
              {label.noDataMessage}
            </Typography>
          )}
        {
          // !loading &&
          //  greenData &&
          greenData.length > 0 && (
            <InvestmentTable
              greenManure={greenData}
              refreshData={refreshData}
            />
          )}
      </Box>
    </Layout>
  );
};

export default FundingGroupRegistration;
