import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  FormControl,
  MenuItem,
  Typography,
  Grid,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Layout from "components/structure/Layout";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { SnackbarContext } from "context/snackBarContext";
import Lable from "utils/lables.json";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import PaymentPolicyTable from "./PaymentPolicyTable";
import PaymentService from "services/PaymentPolicyService";
import debounce from "lodash/debounce";
import CloseIcon from "@mui/icons-material/Close";
import label from "../growingLabel.json";


const PaymentPolicies = () => {
  const navigate = useNavigate();
  const [paymentData, setPaymentData] = useState([]);
  const [filPaymentData, setFilPaymentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  // const [itemsPerPage, setItemsPerPage] = useState(5);
  const [itemsPerPage, setItemsPerPage] = useState(() => {
    return parseInt(localStorage.getItem("IPPPP")) || 5;
  });
  const [totalItems, setTotalItems] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [menu, setMenu] = useState([]);
  const [dropdownSearch, setDropdownSearch] = useState("");
  const [filterVal, setFilterVal] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [searchParams, setSearchParams] = useState({ description: "" });
  const [isActiveStatus, setIsActiveStatus] = useState(null);

  const handleSearchParamsChange = (event) => {
    const { name, value } = event.target;
    setSearchParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }));
    setSearchKeyword(value);
    // Call the search API with the updated value
    // if (value) {
    // callSearchPaymentAPI(0, itemsPerPage, value, isActiveStatus);
    debouncedSearch(value, isActiveStatus);
    // } else {
    //   console.log("CALLEDPAYMENTAPI")
    //   callPaymentAPI(0, itemsPerPage); // Reset to default data when input is cleared
    // }
  };

  const handleClearStatus = () => {
    setStatusFilter("");
    setIsActiveStatus("")
    setCurrentPage(0); // Reset to the first page when the status changes

    callSearchPaymentAPI(0, itemsPerPage, searchKeyword);
  };
  console.log("STATUSVALUE", statusFilter, isActiveStatus)

  const debouncedSearch = useCallback(
    debounce((value, status) => {
      console.log("Egwgeg");
      // callSearchMenuPaymentAPI(0, itemsPerPage, value); // Reset to page 0 for new search
      callSearchPaymentAPI(0, itemsPerPage, value, status);
    }, 300),
    [itemsPerPage]
  );

  const handleSearchChange = (value) => {
    console.log("cllll");
    // const value = event.target.value;
    setSearchKeyword(value);

    if (value) {
      debouncedSearch(value); // Perform search with the entered keyword
    } else {
      setCurrentPage(0);
      callPaymentAPI(0, itemsPerPage); // Reset pagination and fetch default data
    }
  };

  const handleStatusChange = (status) => {
    setStatusFilter(status); // Update the status filter
    setCurrentPage(0); // Reset to the first page when the status changes

    const isActive =
      status === "Active" ? true : status === "Inactive" ? false : null;
    console.log("acitve", isActive);
    setIsActiveStatus(
      status === "Active" ? true : status === "Inactive" ? false : null
    );
    callSearchPaymentAPI(0, itemsPerPage, searchKeyword, isActive); // Call the API with status filter
  };

  const callPaymentAPI = (pageNumber, size) => {
    console.log("call [ayment API:");
    setLoading(true);
    PaymentService.getPayment({ pageNumber, size })
      .then((res) => {
        console.log("res:: in 92", res);
        const paymentResults = res?.data?.response?.paymentPolicy || [];
        console.log("result", paymentResults);
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setPaymentData(paymentResults);
        console.log("paymentResults::", paymentResults);
        setFilPaymentData(paymentResults);
        setTotalItems(res?.data?.response?.totalItems || 0);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setPaymentData([]);
        setFilPaymentData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getFullPaymentMenuAPI = () => {
    console.log("asdfghjkl");
    setLoading(true);
    PaymentService.getPayment({ pageNumber: currentPage, size: 50 })
      .then((res) => {
        const paymentResults = res?.data?.response?.paymentTypes || [];
        const paymentMenu = paymentResults.map((e) => e.paymentTypeDescription);
        setMenu(paymentMenu);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setPaymentData([]);
        setFilPaymentData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const callSearchPaymentAPI = (pageNumber, size, keyword, status) => {
    setLoading(true);

    let query = {
      pageNumber,
      size,
      keyword,
    };

    if (status === true || status === false) {
      query.status = status;
    }

    PaymentService.searchPayment(query) // Replace with the payment search service
      .then((res) => {
        const paymentResults = res?.data?.response?.paymentPolicy || [];
        console.log("paymentResulsssts::", paymentResults);
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setPaymentData(paymentResults); // Update paymentData
        setFilPaymentData(paymentResults); // Update filtered data (if you have a filter)
        setTotalItems(res?.data?.response?.totalItems || 0);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setPaymentData([]); // Set to empty array on error
        setFilPaymentData([]); // Clear filtered data on error
        setTotalItems(0); // Reset total items
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const callSearchMenuPaymentAPI = (pageNumber, size, keyword) => {
    setLoading(true);
    PaymentService.searchPayment({ pageNumber, totalItems: size, keyword }) // Use the payment service and adjust query
      .then((res) => {
        const paymentResults = res?.data?.response?.paymentTypes || [];

        const paymentsMenu = paymentResults.map((e) => e.paymentDescription); // Mapping to payment descriptions
        console.log("menu", paymentsMenu);
        setMenu(paymentsMenu); // Update menu with payment descriptions
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setPaymentData([]); // Set to empty array on error for payment
        setFilPaymentData([]); // Clear filtered payment data on error
        setTotalItems(0); // Reset total items
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (searchKeyword == "" && statusFilter == "") {
      console.log("cp", currentPage);
      callPaymentAPI(currentPage, itemsPerPage);
    }
  }, [currentPage, itemsPerPage]);

  const paginate = (pageNumber, itemsPerPage) => {
    localStorage.setItem("IPPPP", itemsPerPage);
    setCurrentPage(pageNumber - 1);
    if (searchKeyword || statusFilter) {
      const isActive =
        statusFilter === "Active" ? true : statusFilter === "Inactive" ? false : null;
      callSearchPaymentAPI(pageNumber - 1, itemsPerPage, searchKeyword, isActive);
    } else {
      callPaymentAPI(pageNumber - 1, itemsPerPage);
    }
  };

  const refreshData = () => {
    // Call the API again after an item is deleted
    const totalItemsOnPage = filPaymentData?.length ?? 0;

    if (searchKeyword === "" && statusFilter === "") {
      console.log("NO FILTERS APPLIED")
      if (totalItemsOnPage === 1 && currentPage > 0) {
        // If we're on the last item of the page, move back a page if possible
        callPaymentAPI(currentPage - 1, itemsPerPage);
      } else if (totalItemsOnPage === 1 && currentPage == 0) {
        setFilPaymentData([]);
      } else {
        // Otherwise, refresh the current page
        callPaymentAPI(currentPage, itemsPerPage);
      }
    }
    else {
      const isActive =
        statusFilter === "Active" ? true : statusFilter === "Inactive" ? false : null;
      if (totalItemsOnPage === 1 && currentPage > 0) {
        callSearchPaymentAPI(currentPage - 1, itemsPerPage, searchKeyword, isActive);
      } else if (totalItemsOnPage === 1 && currentPage == 0) {
        setFilPaymentData([]);
        callSearchPaymentAPI(currentPage, itemsPerPage, searchKeyword, isActive);
      } else {
        callSearchPaymentAPI(currentPage, itemsPerPage, searchKeyword, isActive);
      }
    }
  };
  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={"Payment Policies Registration List"}
      background="#ffffff"
      useNewPagination={true}
    >
      <Box
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          padding: "1rem",
          height: "110px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <ArrowDropDownIcon sx={{ marginRight: 1, marginBottom: 1.1 }} />
          <Typography>{Lable.filtersLable}</Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={10}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="description"
                  label="Payment Policy Description"
                  placeholder="-"
                  value={searchParams.description}
                  onChange={handleSearchParamsChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "7px", // Adjust padding for the input content
                      minHeight: "50px", // Adjust the overall height
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em", // Adjust label spacing if needed
                    },
                    "& .MuiInputBase-input::placeholder": {
                      opacity: 1,
                    },
                    width: "100%",
                  }}
                  InputProps={{
                    style: { height: "50px" },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  autoComplete="off"
                />
              </Grid>
              {/* <Grid item xs={12} sm={3}>
                <FormControl sx={{ width: { xs: "100%" }, minHeight: 50 }}>
                  <TextField
                    select
                    name="status"
                    label="Status"
                    value={statusFilter} // Bind to the state
                    onChange={(event) => {
                      handleStatusChange(event.target.value); // Call status change handler
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px", // Adjust padding for the input content
                        minHeight: "50px", // Adjust the overall height
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em", // Adjust label spacing if needed
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" }, // Directly setting the height
                    }}
                  >
                    <MenuItem value="">
                      <em>Select an option</em>
                    </MenuItem>
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Inactive">Inactive</MenuItem>
                  </TextField>
                </FormControl>
              </Grid> */}
              <Grid item xs={12} sm={3}>
                <FormControl sx={{ width: { xs: "100%" }, minHeight: 50 }}>
                  <TextField
                    select
                    name="status"
                    label="Status"
                    value={statusFilter || "pl"}
                    onChange={(event) => handleStatusChange(event.target.value)}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "7px",
                        minHeight: "50px",
                      },
                      "& .MuiInputLabel-root": {
                        lineHeight: "1.2em",
                      },
                    }}
                    InputProps={{
                      style: { height: "50px" },
                      endAdornment: statusFilter && (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={handleClearStatus}
                            style={{ marginRight: "15px" }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem value="pl">Select an option</MenuItem>
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Inactive">Inactive</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={2} textAlign={"right"}>
            <Button
              sx={{
                borderRadius: 1,
                minWidth: 95,
                minHeight: 44,
                background: "#004F9F",
              }}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => navigate("/addPaymentPolicies")} // Adjust for payment registration navigation
            >
              ADD NEW
            </Button>
          </Grid>
        </Grid>
      </Box>


      {/* Render table header if no data is found */}
      {/* <PaymentPolicyTable
        setCulturenull={() => setPaymentData([])} // Update to payment data
        callCultureAPI={() => callPaymentAPI(currentPage, itemsPerPage)} // Call payment API
        paymentData={filPaymentData} // Update filtered payment data
        refreshData={refreshData}
      /> */}

      <Box>
        {!loading && filPaymentData && filPaymentData.length === 0 && (
          <Typography variant="h6" align="center" color="textSecondary">
            {label.noDataMessage}
          </Typography>
        )}

        {!loading && filPaymentData && filPaymentData.length > 0 && (
          <PaymentPolicyTable
            setCulturenull={() => setPaymentData([])} // Update to payment data
            callCultureAPI={() => callPaymentAPI(currentPage, itemsPerPage)} // Call payment API
            paymentData={filPaymentData} // Update filtered payment data
            refreshData={refreshData}
          />
        )}
      </Box>
    </Layout>
  );
};

export default PaymentPolicies;
