import React from "react";
import "./Column.css";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import Task from "components/Task/Task";

const Column = ({ tasks, countryArray }) => {
  console.log("ssssc", tasks);
  return (
    <div className="column">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "1rem 2rem 1rem 3rem",
          color: "#737D86",
          fontWeight: 500,
        }}
      >
        <div>Crop Stage</div>
        <div>Action</div>
      </div>
      <SortableContext items={tasks} strategy={verticalListSortingStrategy}>
        {tasks.map((task) => {
          return (
            <Task
              id={task.id}
              title={task.title}
              key={task.id}
              status={task.status}
              countryArr={task.countryDetail}
              compliance={task.visitCompliance}
              code={task.countryCode}
              modifiedTime={task.modifiedDateTimeUTC}
              modifiedBy={task.modifiedByUser}
              sequence={task.sequence}
              countryArray={countryArray}
            />
          );
        })}
      </SortableContext>
    </div>
  );
};

export default Column;
