import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import { Box, TextField,Checkbox } from "@mui/material";
import Service from "../../../services/ModulesRegistrationServices";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Layout from "components/structure/Layout";
import React, { useState } from "react";
import { FormHelperText, Grid } from "@mui/material";
import Switch from "@mui/material/Switch";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import SubModuleIcon from "../../../assets/submoduleIcon";
import DeleteIcon from "../../../assets/deleteIcon";

import * as Yup from "yup";

const Heading = styled.div`
  color: rgba(115, 125, 134, 1);
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
`;

const PaperContainer = styled(Paper)`
  padding: 1rem 1rem;
  border-radius: 10px;
  background-color: white;
  height: auto;
  margintop: 20px;
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 2rem 0;
`;

const SubModuleContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  height: 100px;
`;

const Line = styled.div`
  border-bottom: 1px solid lightgray; 
`;

const HeaderBody = styled.div`
  height: 150px;
`;

const mLevelDesc = "main";
const createdByUser = "testuser";
const modifiedByUser = "test_user";
const sub = 'sub';

const AddRegistration = () => {
  const [isSubmodulesVisble, setSubmodulesVisble] = useState(false);
  const [formData, setFormData] = useState({
    moduleName: "",
    profileName: "",
    cropStage: "",
    selected: [],
    isActive: false,
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const [submoduleList, setSubmoduleList] = useState([
    {
      id: 0,
      displayLine: false,
      mName: "",
      mLevelId: 1,
      mLevelDesc: sub,
      createdByUser: createdByUser,
      modifiedByUser: modifiedByUser,
    },
  ]);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setSubmoduleList((prevSubmoduleList) => {
      const updatedSubmoduleList = [...prevSubmoduleList];
      updatedSubmoduleList[index] = {
        ...updatedSubmoduleList[index],
        [name]: value,
        mName: value,
      };
      return updatedSubmoduleList;
    });
  };

  const addSubmodule = () => {
    setSubmodulesVisble(true);
    const lastSubmodule = submoduleList[submoduleList?.length - 1];
    const newName = lastSubmodule ? lastSubmodule?.mName : "";

    if (submoduleList.length === 1 && submoduleList[0].id === 0) {
      setSubmoduleList([
        {
          id: 1,
          displayLine: true,
          mName: newName,
          mLevelId: 1,
          mLevelDesc: mLevelDesc,
          createdByUser: createdByUser,
          modifiedByUser: modifiedByUser,
        },
      ]);
    } else {
      setSubmoduleList((prevSubmoduleList) => {
        return prevSubmoduleList.concat({
          id: prevSubmoduleList?.length + 1,
          displayLine: prevSubmoduleList?.length > 0,
          mName: newName,
          mLevelId: prevSubmoduleList?.length + 1,
          mLevelDesc: sub,
          createdByUser: createdByUser,
          modifiedByUser:modifiedByUser,
        });
      });
    }
  };

  const deleteSubmodule = (id) => {
    setSubmoduleList(
      submoduleList?.filter((submodule) => submodule?.id !== id)
    );
  };

  const validationSchema = Yup.object({
    moduleName: Yup.string()
      .required("Profile Code is required")
      .max(50, "Profile Code must be less than 50 characters"),
    profileName: Yup.string()
      .required("Profile Name is required")
      .max(50, "Profile Name must be less than 50 characters"),
    cropStage: Yup.string().required("cropStage is required"),
    selected: Yup.array()
      .min(1, "At least one functionality must be selected")
      .required("Functionality is required"),
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setErrors({});
      await validationSchema.validate(formData, { abortEarly: false });
    } catch (error) {
      let newError = {};

      error.inner.forEach((err) => {
        newError[err.path] = err.message;
      });
      setErrors(newError);
    }
  };

  const handleCreate = async () => {
    try {
      const postData = {
        mName: formData?.moduleName,
        mLevelId: 1,
        mLevelDesc: "main",
        createdByUser: "testuser",
        modifiedByUser: "test_user",
        subModule: submoduleList,
      };

      const response = await Service.create(postData);
      if (response?.data) {
        navigate("/getmoduleregistration");
      }
    } catch (error) {
      let newError = {};

      error?.inner?.forEach((err) => {
        newError[err.path] = err.message;
      });

      setErrors(newError);
    }
  };
  return (
    <Layout title="Farmer’s Sustainability Management Portal">
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Heading>Add Crop Stage</Heading>
        </Grid>
        <Grid item xs={2}>
          {/* <Button
            sx={{
              borderRadius: 100,
              minWidth: 195,
              minHeight: 44,
              background: "#102A62",
            }}
            variant="contained"
            startIcon={<AddCircleIcon />}
            onClick={() => {
              addSubmodule();
            }}
          >
            Add Sub Modules
          </Button> */}
        </Grid>
      </Grid>

      <PaperContainer>
        <HeaderBody>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid
                item
                md={5}
                xs={12}
                sx={{
                  display: { md: "flex", sx: "block" },
                  alignItems: "center",
                }}
              >
                <InputLabel sx={{ width: "10rem" }} htmlFor="my-input">
                  Crop Stage Name
                </InputLabel>
                <Box>
                  <TextField
                    sx={{ width: { xs: "100%", md: "14rem" } }}
                    id="my-input"
                    aria-describedby="my-helper-text"
                    label="Crop Stage Name"
                    value={formData.moduleName}
                    name="moduleName"
                    onChange={handleChange}
                  />
                  {errors.moduleName && (
                    <FormHelperText error={true}>
                      {errors.moduleName}
                    </FormHelperText>
                  )}
                </Box>
              </Grid>
              <Grid
                item
                md={5}
                xs={12}
                sx={{
                  display: { md: "flex", xs: "block" },
                  alignItems: "center",
                }}
              >
                <InputLabel sx={{ width: "10rem" }} htmlFor="my-input">
              Country
                </InputLabel>
                <FormControl
                  sx={{ width: { xs: "100%", md: 222 }, minHeight: 50 }}
                >
                  <Select
                    value={formData.cropStage}
                    name="cropStage"
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "cropStages" }}
                    renderValue={(selected) =>
                      selected ? selected : <em>Country Name</em>
                    }
                    required
                  >
                    <MenuItem value={"Post Contract"}>Post Contract</MenuItem>
                    <MenuItem value={"Harversting"}>Harversting</MenuItem>
                    <MenuItem value={"Seedbed"}>Seedbed</MenuItem>
                  </Select>
                  <FormHelperText error={true}>
                    {errors.cropStage}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid
                item
                md={2}
                xs={12}
                sx={{
                  display: { md: "flex", xs: "none" },
                  alignItems: "center",
                  flexDirection: "end",
                }}
              >
                <Box sx={{ marginLeft: "1rem" }}>
                  <InputLabel htmlFor="my-input">Status</InputLabel>
                </Box>
                <Switch defaultChecked color="warning" />
                <Box sx={{ marginLeft: "1rem" }}>
                  <InputLabel sx={{ width: "10rem" }} htmlFor="my-input">
                    Inactive
                  </InputLabel>
                </Box>
              </Grid>
              <Grid
                item
                md={2}
                xs={12}
                sx={{
                  display: { md: "flex", xs: "none" },
                  alignItems: "center",
                }}
              >
                <Box sx={{ marginLeft: "1rem" }}>
                  <InputLabel htmlFor="my-input">Visit Complaince</InputLabel>
                </Box>
                <Checkbox /* Add Checkbox props here */ />

              </Grid>
            </Grid>
          </form>
        </HeaderBody>

        {isSubmodulesVisble &&
          submoduleList?.map((submodule, index) => (
            <div key={index}>
              {submodule.displayLine && <Line />}
              <SubModuleContainer>
                <Grid container spacing={2}>
                  <Grid
                    item
                    md={5}
                    xs={12}
                    sx={{
                      display: { md: "flex", sx: "block" },
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ marginLeft: "1rem" }}>
                      <SubModuleIcon />
                    </Box>
                    <Box sx={{ marginLeft: "1rem" }}>
                      <InputLabel
                        sx={{ width: "10rem" }}
                        htmlFor={`submodule-name-${index}`}
                      >
                        Sub Module Name
                      </InputLabel>
                    </Box>
                    <Box sx={{ marginLeft: "1rem" }}>
                      <TextField
                        sx={{ width: { xs: "100%", md: "14rem" } }}
                        id={`submodule-name-${index}`}
                        aria-describedby={`submodule-name-helper-${index}`}
                        label={submodule.newName ? "" : "Type here..."}
                        value={submodule.newName}
                        name={`submodule-name-${index}`} 
                        onChange={(e) => handleChange(e, index)} 
                      />
                      {errors[`submodule-name-${index}`] && (
                        <FormHelperText
                          error={true}
                          id={`submodule-name-helper-${index}`}
                        >
                          {errors[`submodule-name-${index}`]}
                        </FormHelperText>
                      )}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    md={5}
                    xs={12}
                    sx={{
                      display: { md: "flex", xs: "block" },
                      alignItems: "center",
                    }}
                  ></Grid>

                  <Grid
                    item
                    md={2}
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Box onClick={() => deleteSubmodule(submodule.id)}>
                      <DeleteIcon />
                    </Box>
                  </Grid>
                </Grid>
              </SubModuleContainer>
            </div>
          ))}

        <ButtonContainer>
          <iconDelete />
          <Button
            sx={{
              borderRadius: 2,
              minWidth: 130,
              minHeight: 54,
              background: "#737D86",
            }}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            sx={{
              borderRadius: 2,
              minWidth: 130,
              minHeight: 54,
              background: "#102A62",
            }}
            variant="contained"
            onClick={handleCreate}
          >
            Submit
          </Button>
        </ButtonContainer>
      </PaperContainer>
    </Layout>
  );
};
export default AddRegistration;
