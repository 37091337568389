import React, { useContext, useEffect, useState } from "react";
import { Typography, Box, Button } from "@mui/material";
import "./HierarchyTree.css";
import EditButton from "utils/EditButton";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useHistory, useNavigate } from "react-router-dom";
import moreActions from "assets/moreActions.svg";
import HierarchyService from "services/GrowingHierarchyService";
import { SnackbarContext } from "context/snackBarContext";
import iconEdit from "assets/iconEdit.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import editIcon from "../../../../assets/edit.svg";
import SupplyTypeService from "services/SupplyTypeService";
import PublishedWithChangesTwoToneIcon from "@mui/icons-material/PublishedWithChangesTwoTone";
import UnpublishedTwoToneIcon from "@mui/icons-material/UnpublishedTwoTone";
import { useTranslation } from 'react-i18next';

const Table = (props) => {
  const { t } = useTranslation();
  const [hierarchyData, setHierarchyData] = useState([]);
  const [menuIndex, setMenuIndex] = useState(null);
  console.log("🚀 ~ Table ~ hierarchyData:", hierarchyData);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = React.useState({});

  const [supplyData, setSupplyData] = useState([]);
  console.log("🚀 ~ SupplyTable ~ supplyData:", supplyData);
  const [originalData, setOriginalData] = useState([]);
  useEffect(() => {
    setSupplyData(props.supplyData);
    setOriginalData(props.supplyData);
  }, [props.supplyData]);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setMenuIndex(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setMenuIndex(null);
  };

  const handleMenuClose = ({ action, data }) => {
    if (!action || action == undefined) return;
    setSelectedRow(data);
    if (action == "details") {
      navigate("/viewSupplyType", {
        state: {
          description: data.supplyTypeDescription,
          useNpkEdit: data.useNPK ? "Yes" : "No",
          status: data.isActive,
          edit: false,
          details: true,
          id: data.supplyTypeId,
          forPest: data.forPestDisease ? "Yes" : "No",
        },
      });
    } else if (action == "edit") {
      navigate("/editSupplyType", {
        state: {
          description: data.supplyTypeDescription,
          status: data.isActive,
          edit: true,
          useNpkEdit: data.useNPK ? "Yes" : "No", //data.useNPK,
          id: data.supplyTypeId,
          forPest: data.forPestDisease ? "Yes" : "No",
          //          forPest: data.forPestDisease
        },
      });
    } else if (action === "activate") {
      // Call handleUpdate to update the status to 'active'
      handleUpdate("active", data);
    } else if (action === "deactivate") {
      handleUpdate("inactive", data);
    }
    setAnchorEl(null);
  };

  const handleUpdate = async (status, data) => {
    const payload = {
      supplyTypeDescription: data.supplyTypeDescription,
      useNPK: data.useNPK,
      forPestDisease: data.forPestDisease,
      isActive: status === "active" ? true : false,
      createdByUser: "abc",
      createdDateTimeUTC: new Date(),
      countryCode: "BRA",
      supplyTypeId: data.supplyTypeId,
    };
    try {
      const response = await SupplyTypeService.updateSupply({ ...payload });
      if (response.status === 200) {
        setSeverity("success");
        setMessage(`Status updated to ${status} successfully`);
        setOpenAlert(true);
        props.refreshData();
      }
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message;
        setMessage(errorMessage);
        setOpenAlert(true);
      } else {
        console.error("Failed to submit form");
        setSeverity("error");
        setMessage(t("snackbarMessage.updateFailed"));
        setOpenAlert(true);
      }
    }
  };

  const handleEdit = () => {
    handleClose();
  };

  const handleDelete = () => {
    // Add your delete logic here
    console.log("Delete action");
    handleClose();
  };

  const handleDuplicate = () => {
    // Add your duplicate logic here
    console.log("Duplicate action");
    handleClose();
  };

  useEffect(() => {
    setHierarchyData(props.hierarchyData);
  }, [props.hierarchyData]);

  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  // const handleSort = (key) => {
  //   let direction = "asc";
  //   if (sortConfig.key === key) {
  //     if (sortConfig.direction === "asc") {
  //       direction = "desc";
  //     } else if (sortConfig.direction === "desc") {
  //       direction = null;
  //     }
  //   }
  //   setSortConfig({ key, direction });
  //   if (direction === null) {
  //     setSupplyData(originalData);
  //   } else {
  //     const sortedData = [...props.supplyData].sort((a, b) => {
  //       if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
  //       if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
  //       return 0;
  //     });
  //     setSupplyData(sortedData);
  //   }
  // };
  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...props.supplyData].sort((a, b) => {
      const aValue = a[key] ? String(a[key]).toLowerCase() : "";
      const bValue = b[key] ? String(b[key]).toLowerCase() : "";

      if (aValue < bValue) return direction === "asc" ? -1 : 1;
      if (aValue > bValue) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setSupplyData(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") return <NorthIcon fontSize="small" />;
      else if (sortConfig.direction === "desc")
        return <SouthIcon fontSize="small" />;
    }
    return <SouthIcon fontSize="small" />;
  };

  return (
    <div className="table-container">
      <table className="full-width-table">
        {" "}
        {/* Add a class for styling */}
        <thead>
          <tr className="HierarchyHead">
            <th
              onClick={() => handleSort("supplyTypeCode")}
              style={{
                cursor: "pointer",
                fontWeight: 400,
                fontSize: "16px",
              }}
            >
            {t("supplyType.table.headers.Code")}
              <span
                style={{
                  marginLeft: "5px",
                }}
              >
                {getSortIcon("supplyTypeCode")}
              </span>
            </th>
            <th
              onClick={() => handleSort("supplyTypeDescription")}
              style={{ cursor: "pointer", fontWeight: 400, fontSize: "16px" }}
            >
              {t("supplyType.table.headers.Description")}
              <span
                style={{
                  marginLeft: "5px",
                }}
              >
                {getSortIcon("supplyTypeDescription")}
              </span>
            </th>
            <th
              onClick={() => handleSort("useNPK")}
              style={{ cursor: "pointer", fontWeight: 400, fontSize: "16px" }}
            >
              {t("supplyType.table.headers.UseNPK")}
              <span
                style={{
                  marginLeft: "5px",
                }}
              >
                {getSortIcon("useNPK")}
              </span>
            </th>
            <th
              onClick={() => handleSort("forPestDisease")}
              style={{ cursor: "pointer", fontWeight: 400, fontSize: "16px" }}
            >
              {t("supplyType.table.headers.Pest/Disease")}
              <span
                style={{
                  marginLeft: "5px",
                }}
              >
                {getSortIcon("forPestDisease")}
              </span>
            </th>
            <th
              onClick={() => handleSort("isActive")}
              style={{ cursor: "pointer", fontWeight: 400, fontSize: "16px" }}
            >
              {t("supplyType.table.headers.Status")}
              <span
                style={{
                  marginLeft: "5px",
                }}
              >
                {" "}
                {getSortIcon("isActive")}
              </span>
            </th>
            <th style={{ fontWeight: 400, fontSize: "16px" }}>
            {t("supplyType.table.headers.Actions")}
            </th>
          </tr>
        </thead>
        <tbody>
          {supplyData?.map((row, index) => (
            <tr className="hierarchyColumn" key={index} style={{}}>
              <td
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate("/viewSupplyType", {
                    state: {
                      description: row.supplyTypeDescription,
                      useNpkEdit: row.useNPK ? "Yes" : "No",
                      status: row.isActive,
                      edit: false,
                      details: true,
                      id: row.supplyTypeId,
                      forPest: row.forPestDisease ? "Yes" : "No",
                    },
                  })
                }
              >
                <Typography
                  sx={{
                    justifyContent: "left",
                    fontWeight: 600,
                    color: "#3B454F",
                  }}
                >
                  {row.supplyTypeCode}
                </Typography>
              </td>
              <td
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate("/viewSupplyType", {
                    state: {
                      description: row.supplyTypeDescription,
                      useNpkEdit: row.useNPK ? "Yes" : "No",
                      status: row.isActive,
                      edit: false,
                      details: true,
                      id: row.supplyTypeId,
                      forPest: row.forPestDisease ? "Yes" : "No",
                    },
                  })
                }
              >
                <Box
                  sx={{
                    whiteSpace: "nowrap",
                    paddingLeft: "5px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontWeight: 600,
                    color: "#3B454F",
                  }}
                >
                  <span title={row.supplyTypeDescription}>
                    {row.supplyTypeDescription.length > 20
                      ? row.supplyTypeDescription.slice(0, 20) + "..."
                      : row.supplyTypeDescription}
                  </span>
                </Box>
              </td>
              <td
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate("/viewSupplyType", {
                    state: {
                      description: row.supplyTypeDescription,
                      useNpkEdit: row.useNPK ? "Yes" : "No",
                      status: row.isActive,
                      edit: false,
                      details: true,
                      id: row.supplyTypeId,
                      forPest: row.forPestDisease ? "Yes" : "No",
                    },
                  })
                }
              >
                <div
                  className="hierarchyConfig"
                  style={{
                    fontWeight: 600,
                    color: "#3B454F",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span title={row.useNPK}>{row.useNPK ? "Yes" : "No"}</span>
                </div>
              </td>
              <td
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate("/viewSupplyType", {
                    state: {
                      description: row.supplyTypeDescription,
                      useNpkEdit: row.useNPK ? "Yes" : "No",
                      status: row.isActive,
                      edit: false,
                      details: true,
                      id: row.supplyTypeId,
                      forPest: row.forPestDisease ? "Yes" : "No",
                    },
                  })
                }
              >
                <div
                  className="hierarchyConfig"
                  style={{
                    fontWeight: 600,
                    color: "#3B454F",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span title={row.forPestDisease}>
                    {row.forPestDisease ? "Yes" : "No"}
                  </span>
                </div>
              </td>
              <td
                style={{
                  textAlign: "center",
                  fontWeight: 600,
                  color: "#3B454F",
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate("/viewSupplyType", {
                    state: {
                      description: row.supplyTypeDescription,
                      useNpkEdit: row.useNPK ? "Yes" : "No",
                      status: row.isActive,
                      edit: false,
                      details: true,
                      id: row.supplyTypeId,
                      forPest: row.forPestDisease ? "Yes" : "No",
                    },
                  })
                }
              >
                {row.isActive === true ? "Active" : "Inactive"}
              </td>
              <td
                style={{
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      borderRadius: "2.116rem",
                      backgroundColor: "#FEF4D5",
                      borderColor: "#F2BB40",
                      height: "2.8rem",
                      width: "2.8rem",
                      color: "#F2BB40",
                      minWidth: "2.8rem",
                      "&:hover": {
                        backgroundColor: "#F2BB40",
                        color: "#FEF4D5",
                        borderColor: "#F2BB40",
                      },
                    }}
                    onClick={() =>
                      navigate("/editSupplyType", {
                        state: {
                          description: row.supplyTypeDescription,
                          status: row.isActive,
                          edit: true,
                          useNpkEdit: row.useNPK ? "Yes" : "No", //data.useNPK,
                          id: row.supplyTypeId,
                          forPest: row.forPestDisease ? "Yes" : "No",
                          //          forPest: data.forPestDisease
                        },
                      })
                    }
                  >
                    <img src={editIcon} alt="Edit Button" />
                  </Button>
                  <Button
                    variant="outlined"
                    className="moreButton"
                    style={{ marginLeft: "10px" }}
                    aria-label="more"
                    aria-controls={
                      menuIndex === index ? "long-menu" : undefined
                    }
                    aria-haspopup="true"
                    onClick={(event) => handleClick(event, index)}
                  >
                    <div>
                      <img src={moreActions} alt="more-actions" />
                    </div>
                    <div style={{ height: "14px" }}>More</div>
                  </Button>
                  <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={menuIndex === index}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        handleMenuClose({ action: "edit", data: row, tab: 1 });
                        handleClose();
                      }}
                    >
                      <Button
                        variant="outlined"
                        sx={{
                          borderRadius: "2.116rem",
                          backgroundColor: "#FEF4D5",
                          borderColor: "#F2BB40",
                          height: "3.125rem",
                          width: "7.938rem",
                          color: "#F2BB40",
                          "&:hover": {
                            backgroundColor: "#F2BB40",
                            color: "#FEF4D5",
                            borderColor: "#F2BB40",
                          },
                        }}
                        onClick={() =>
                          handleMenuClose({ action: "edit", data: row, tab: 1 })
                        }
                        startIcon={<EditIcon />}
                      >
                        Edit
                      </Button>
                      {/* <ListItemText primary="Edit" /> */}
                    </MenuItem>
                    <MenuItem
                      onClick={async () => {
                        try {
                          const response =
                            await HierarchyService.getDuplicateHierarchyList(
                              row?.ihvId
                            );
                          setSeverity("success");
                          setMessage("Duplicate Added successfully");
                          setOpenAlert(true);
                          props.fetchData();
                          handleClose();
                        } catch (error) {
                          console.error("Error calling API:", error);
                        }
                      }}
                    >
                      {/* <Button
                        variant="outlined"
                        sx={{
                          borderRadius: "2.116rem",
                          backgroundColor: "#FEF4D5",
                          borderColor: "#F2BB40",
                          height: "3.125rem",
                          width: "7.938rem",
                          color: "#F2BB40",
                          "&:hover": {
                            backgroundColor: "#F2BB40",
                            color: "#FEF4D5",
                            borderColor: "#F2BB40",
                          },
                        }}
                        startIcon={<FileCopyIcon />}
                      >
                        Duplicate
                      </Button> */}
                      <Button
                        variant="outlined"
                        sx={{
                          borderRadius: "2.116rem",
                          backgroundColor: "#D4EDDA",
                          borderColor: "#28A745",
                          height: "3.125rem",
                          width: "7.938rem",
                          color: "#28A745",
                          "&:hover": {
                            backgroundColor: "#28A745",
                            color: "#D4EDDA",
                            borderColor: "#28A745",
                          },
                        }}
                        startIcon={<VisibilityIcon />}
                        onClick={() => {
                          handleMenuClose({
                            action: "details",
                            data: row,
                            tab: 1,
                          });
                          handleClose();
                        }}
                      >
                        View
                      </Button>
                    </MenuItem>
                    {row.isActive === false && (
                      <MenuItem
                        // sx={{
                        //   border: '1px solid #50AF47 !important',
                        //   display: "flex",
                        //   justifyContent: "space-around",
                        //   cursor: "ponter"
                        // }}
                        onClick={() => {
                          handleMenuClose({
                            action: "activate",
                            data: row,
                            tab: 1,
                          });
                          handleClose();
                        }}
                      >
                        {/* <div>Activate</div> */}
                        <Button
                          variant="outlined"
                          sx={{
                            borderRadius: "2.116rem",
                            backgroundColor: "#D4EDDA",
                            borderColor: "#28A745",
                            height: "3.125rem",
                            width: "7.938rem",
                            color: "#28A745",
                            "&:hover": {
                              backgroundColor: "#28A745",
                              color: "#D4EDDA",
                              borderColor: "#28A745",
                            },
                          }}
                          startIcon={<PublishedWithChangesTwoToneIcon />}
                        >
                          Activate
                        </Button>
                      </MenuItem>
                    )}
                    {row.isActive === true && (
                      <MenuItem
                        // sx={{ border: '1px solid #50AF47 !important', display: "flex", justifyContent: "space-around", cursor: "ponter" }}
                        onClick={() => {
                          handleMenuClose({
                            action: "deactivate",
                            data: row,
                            tab: 1,
                          });
                          handleClose();
                        }}
                      >
                        {/* <div>Deactivate</div> */}
                        <Button
                          variant="outlined"
                          sx={{
                            borderRadius: "2.116rem",
                            backgroundColor: "#F4D5D5",
                            borderColor: "#EF0000",
                            height: "3.125rem",
                            width: "7.938rem",
                            color: "#EF0000",
                            "&:hover": {
                              backgroundColor: "#EF0000",
                              color: "#F4D5D5",
                              borderColor: "#EF0000",
                            },
                          }}
                          startIcon={<UnpublishedTwoToneIcon />}
                        >
                          Deactivate
                        </Button>
                      </MenuItem>
                    )}
                  </Menu>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
