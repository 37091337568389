import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addEnvironment: {},
};

const environmentSlice = createSlice({
  name: "addEnvironment",
  initialState,
  reducers: {
    updateEnvironment(state, action) {
      state.addEnvironment = {
        ...state.addEnvironment,
        ...action.payload,
      };
    },
    clearEnvironment(state) {
      state.addEnvironment = {};
    },
  },
});

export const { updateEnvironment, clearEnvironment } = environmentSlice.actions;
export default environmentSlice.reducer;
