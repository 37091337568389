import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import leftIcon from "../../../assets/left-icon-blue.svg";
import rightIcon from "../../../assets/right-icon-blue.svg";

const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage }) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleClick = (pageNumber) => {
    if (pageNumber < 1) return;
    paginate(pageNumber, itemsPerPage);
  };
  const handleResize = (itemsPerPage) => {
    if (itemsPerPage < 1) return;
    paginate(currentPage + 1, itemsPerPage);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: { xs: "column", sm: "column", md: "row" },
        paddingTop: "10px",
        background: "white",
        padding: "10px 30px",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontSize: "20px",
            fontWeight: 500,
            lineHeight: "30px",
          }}
        >
          RECORDS PER PAGE
        </Typography>
        <Box sx={{ display: "flex", paddingLeft: "10px" }}>
          <RemoveIcon
            onClick={() => {
              handleResize(itemsPerPage - 1);
            }}
            style={{
              backgroundColor: "#102A63",
              color: "white",
              cursor: "pointer",
            }}
          />
          {/* <Typography sx={{ paddingLeft: "5px" }}> {itemsPerPage}</Typography> */}
          <Typography sx={{ paddingLeft: "5px", textDecoration: "underline", textUnderlineOffset: "4px" }}>
            {itemsPerPage < 10 ? `0${itemsPerPage}` : itemsPerPage}
          </Typography>
          {currentPage + 1 !== pageNumbers.length && (
            <AddIcon
              onClick={() => {
                handleResize(itemsPerPage + 1);
              }}
              style={{
                backgroundColor: "#102A63",
                color: "white",
                marginLeft: "5px",
                cursor: "pointer",
              }}
            />
          )}

        </Box>
      </Box>
      <Box sx={{ display: "flex" }}>
        <img
          src={leftIcon}
          alt="Left Icon"
          onClick={() => {
            handleClick(currentPage);
          }}
          style={{
            paddingBottom: "5px",
            paddingRight: "5px",
            cursor: "pointer",
          }}
        />
        <Typography>Page</Typography>
        <Typography sx={{ paddingLeft: "5px" }}> {(currentPage + 1) < 10 ? `0${(currentPage + 1)}` : (currentPage + 1)}</Typography>
        <Typography sx={{ paddingLeft: "5px" }}> of</Typography>
        <Typography sx={{ paddingLeft: "5px" }}>
          {" "}
          {pageNumbers.length < 10 ? `0${pageNumbers.length}` : pageNumbers.length}
        </Typography>

        {currentPage + 1 === pageNumbers.length}
        {currentPage + 1 !== pageNumbers.length && (
          <img
            src={rightIcon}
            alt="Right Icon"
            onClick={() => {
              handleClick(currentPage + 2);
            }}
            style={{
              paddingBottom: "5px",
              paddingLeft: "5px",
              cursor: "pointer",
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default Pagination;
