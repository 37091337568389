import React, { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Layout from "components/structure/Layout";
import { AuthData } from "auth/AuthWarpper";
import { SnackbarContext } from "context/snackBarContext";
import CultureTable from "../Curing/CuringTable";
import IosShareIcon from "@mui/icons-material/IosShare";
import { json2csv } from "json-2-csv";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CuringService from "services/CuringService";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { debounce } from "lodash";
import filter from "assets/filter.svg";
import label from "../growingLabel.json";


const Curing = () => {
  const navigate = useNavigate();
  const { user } = AuthData();
  const [curingData, setCuringData] = useState(null);
  const [allCuringData, setAllCuringData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [searchParams, setSearchParams] = useState({
    isNew: "",
    curingUnitName: "",
    tobaccoGroup: "",
    status: "",
    countryCode: user ? user.countryCode : "BRA",
  });

  const [descSearch, setDescSearch] = useState({ curingUnitName: "" });
  console.log("🚀 ~ Curing ~ descSearch:", curingData);

  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(3); // Number of items per page
  const [totalItems, setTotalItems] = useState(5); // Number of items per page
  const [recordsError, setRecordsError] = useState(false);

  const callCuringApi = (pNumber, size) => {
    setLoading(true);
    setRecordsError(false);

    CuringService.getCuring({ pageNumber: pNumber, size: size })
      .then((res) => {
        setCurrentPage(pNumber);
        setItemsPerPage(size);
        setLoading(false);
        setRecordsError(false);
        setCuringData(res?.data?.response?.curingUnit);
        setTotalItems(res?.data?.response?.totalItems);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  const paginate = (pageNumber, itemsPerPage) => {
    callCuringApi(pageNumber - 1, itemsPerPage);
  };

  useEffect(() => {
    callCuringApi(currentPage, itemsPerPage);
  }, []);

  const handleChangeDescription = (event) => {
    const { value } = event.target;
    setDescSearch({ curingUnitName: value });
  };

  const handleSearch = useCallback(
    debounce(() => {
      const cleanedParams = cleanSearchParams(searchParams);

      if (
        Object.keys(cleanedParams).length === 1 &&
        cleanedParams.countryCode
      ) {
        callCuringApi(currentPage, itemsPerPage);
        return;
      }

      CuringService.searchCuring({
        page: currentPage,
        size: itemsPerPage,
        searchParams: cleanedParams,
      })
        .then((res) => {
          setCurrentPage(res?.data?.response?.currentPage);
          setTotalItems(res?.data?.response?.totalItems);
          setCuringData(res?.data?.response?.curingUnits);
          setRecordsError(false);

          if (res.status === 404) {
            setCurrentPage(0);
          }
        })
        .catch((error) => {
          setRecordsError(true);
          console.log(error);
          if (error?.response?.status === 404) {
            setCurrentPage(0);
          }
        });
    }, 500),
    [searchParams, currentPage, itemsPerPage]
  );

  // const handleSearchDesc = useCallback(
  //   debounce(() => {
  //     if (descSearch.curingUnitName === "") {
  //       callCuringApi(currentPage, itemsPerPage);
  //       return;
  //     }

  //     CuringService.searchCuringUnitName({
  //       curingUnitName: descSearch.curingUnitName,
  //       page: currentPage,
  //       size: itemsPerPage,
  //     })
  //       .then((res) => {
  //         setCurrentPage(res?.data?.response?.currentPage);
  //         setTotalItems(res?.data?.response?.totalItems);
  //         setCuringData(res?.data?.response?.curingUnits);
  //         setRecordsError(false);
  //       })
  //       .catch((error) => {
  //         setRecordsError(true);
  //         console.log(error);
  //       });
  //   }, 500),
  //   [descSearch.curingUnitName, currentPage, itemsPerPage]
  // );

  // useEffect(() => {
  //   handleSearchDesc();
  //   return () => {
  //     handleSearchDesc.cancel();
  //   };
  // }, [descSearch.curingUnitName, currentPage, itemsPerPage, handleSearchDesc]);

  useEffect(() => {
    if (Object.keys(cleanSearchParams(searchParams)).length > 1) {
      handleSearch();
    } else {
      callCuringApi(currentPage, itemsPerPage);
    }

    return () => {
      handleSearch.cancel();
    };
  }, [searchParams, currentPage, itemsPerPage, handleSearch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await CuringService.getAllCuringData(
          user ? user.countryCode : "BRA"
        );
        setAllCuringData(response.data.response);
      } catch (error) {
        console.error("There was an error!", error);
      }
    };

    fetchData();
  }, []);

  const handleDownload = useCallback(() => {
    const timestamp = new Date()
      .toISOString()
      .replace(/[-:]/g, "")
      .replace("T", "_")
      .split(".")[0];

    const cd = json2csv(allCuringData, { excelBOM: true });

    const blob = new Blob([cd], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `curingUnitdata_${timestamp}.csv`);

    document.body.appendChild(link);

    link.addEventListener("unload", () => {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    });

    link.click();
  }, [allCuringData]);

  const debouncedHandleDownload = useCallback(debounce(handleDownload, 2000), [
    handleDownload,
  ]);

  const cleanSearchParams = (params) => {
    const cleanedParams = { ...params };
    Object.keys(cleanedParams).forEach((key) => {
      if (cleanedParams[key] === "") {
        delete cleanedParams[key];
      }
    });
    return cleanedParams;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };

  const handleClear = (name) => {
    setSearchParams({
      ...searchParams,
      [name]: "",
    });
  };

  return (
    <Layout
      title={"Curing Unit Registration"}
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      useNewPagination={true}
    >
      <Box mt={2} sx={{ paddingBottom: "1rem", position: "relative" }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          background="pink"
        >
          <Box display={"flex"} gap={4}>
            <Grid item xs={12} sm={6} md={2}>
              <img src={filter} alt="Filter icon" />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <FormControl sx={{ minWidth: "200px" }}>
                <TextField
                  name="curingUnitName"
                  label="Description"
                  value={searchParams.curingUnitName}
                  onChange={handleChange}
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <FormControl sx={{ minWidth: "200px" }}>
                <TextField
                  select
                  name="tobaccoGroup"
                  label={"Tobacco Group"}
                  value={searchParams.tobaccoGroup}
                  onChange={handleChange}
                  variant="outlined"
                  SelectProps={{
                    IconComponent: ArrowDropDownIcon,
                    endAdornment: searchParams.tobaccoGroup && (
                      <CloseIcon
                        onClick={() => handleClear("tobaccoGroup")}
                        style={{
                          cursor: "pointer",
                          marginRight: "12px",
                        }}
                      />
                    ),
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="Flue Cured">Flue Cured</MenuItem>
                  <MenuItem value="Air Cured">Air Cured</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <FormControl sx={{ minWidth: "200px" }}>
                <TextField
                  select
                  name="isNew"
                  value={searchParams.isNew}
                  onChange={handleChange}
                  label="New Curing Unit"
                  SelectProps={{
                    IconComponent: ArrowDropDownIcon,
                    endAdornment: searchParams.isNew && (
                      <CloseIcon
                        onClick={() => handleClear("isNew")}
                        style={{
                          cursor: "pointer",
                          marginRight: "12px",
                        }}
                      />
                    ),
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <FormControl sx={{ minWidth: "200px" }}>
                <TextField
                  select
                  name="status"
                  value={searchParams.status}
                  onChange={handleChange}
                  label="Status"
                  SelectProps={{
                    IconComponent: ArrowDropDownIcon,
                    endAdornment: (searchParams.status === true ||
                      searchParams.status === false) && (
                        <CloseIcon
                          onClick={() => handleClear("status")}
                          style={{
                            cursor: "pointer",
                            marginLeft: "auto",
                            paddingRight: "20px",
                          }}
                        />
                      ),
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
          </Box>
          <Box>
            <Button
              sx={{
                borderRadius: 100,
                minWidth: 95,
                minHeight: 44,
                background: "#102A62",
                marginTop: { xs: 1 },
                marginLeft: { xs: 1 },
              }}
              variant="contained"
              startIcon={<IosShareIcon />}
              onClick={debouncedHandleDownload}
            >
              Export
            </Button>

            <Button
              sx={{
                borderRadius: 100,
                minWidth: 95,
                minHeight: 44,
                background: "#102A62",
                marginTop: { xs: 1 },
                marginLeft: { xs: 1 },
              }}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => navigate("/addCuring")}
            >
              Add New
            </Button>
          </Box>
        </Box>
        {curingData?.length > 0 && !recordsError ? (
          <CultureTable curingData={curingData} />
        ) : (
          <div>{label.noDataMessage}</div>
        )}
      </Box>
    </Layout>
  );
};

export default Curing;
