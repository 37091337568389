export const fundingField = [
  {
    name: "igInvestmentGroupDescription",
    label: "Funding Group Description",
    type: "text",
    width: "60%",
    required: true,
  },
  {
    name: "newCuringUnit",
    label: "Tobacco Type",
    type: "select",
    required: true,
    options: [],
  },
  {
    name: "status",
    label: "Status",
    required: true,
    type: "switch",
  },
];


export const editFundingField = [

  {
    name: "igInvestmentGroupDescription",
    label: "Investment Group Description",
    type: "text",
    width: "60%",
    required: true,
  },
  {
    name: "newCuringUnit",
    label: "Tobacco Type",
    type: "select",
    options: [],
    required: true,
  },
  {
    name: "status",
    label: "Status",
    type: "switch",
  },
];