export const equipmentRegistrationFormConfig = [
    {
      name: "externalCode",
      label: "External Code",
      type: "code",
      required: true,
      disabled: true,
    },
    {
      name: "equipmentName",
      label: "Equipment",
      type: "text",
      required: true,
    },
  
]