import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SubModuleIcon from "../../../assets/submoduleIcon";
import DeleteIcon from "../../../assets/deleteIcon";
import FormHelperText from "@mui/material/FormHelperText";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import Layout from "components/structure/Layout";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Service from "../../../services/ModulesRegistrationServices";
import { SnackbarContext } from "context/snackBarContext";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import './Module.css'
import * as Yup from "yup";
import PinkIosSwitch from "utils/PinkIosSwitch";
import RedSwitch from "utils/RedSwitch";
import { AuthData } from "auth/AuthWarpper";
import { formatDateDetails } from "utils/utils";
import CheckboxUntick from "../../../assets/CheckboxUntick.svg"
import CheckboxTick from "../../../assets/CheckboxTick.svg"

const Heading = styled.div`
  color: rgba(115, 125, 134, 1);
  font-size: 1.25rem;
  font-weight: 600;
`;

const PaperContainer = styled(Paper)`
  padding: 1rem 1rem;
  border-radius: 10px;
  background-color: white;
  height: auto;
  margin-top: 20px;
`;
const DataContainer = styled.div`
  margin-top: 2rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 2rem 0;
`;


const Line = styled.div`
  border-bottom: 1px solid lightgray;
`;

const HeaderBody = styled.div`
  height: auto;
`;

const EditRegistration = () => {
  const { user } = AuthData();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { setOpenAlert, setSeverity, setMessage, handleClose } =
    useContext(SnackbarContext);
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = useState({
    id: 1,
    mName: state?.item?.mName,
    cropStageName: state?.cropStage,
    submName: ""
  });
  const [errors, setErrors] = useState({});
  const [subModules, setSubModules] = useState(() => {
    return (state?.item?.subModule || [])?.map((subModule, index) => ({
      ...subModule,
      id: index + 1,
    }));
  });

  const [switchValue, setSwitchValue] = useState(state?.item?.mStatus);
  const modifiedByUser = user?.modifiedByUser;
  const dateTimeString = "2024-04-07T07:04:48.063785Z";
  const dateTime = new Date(dateTimeString);
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = dateTime.toLocaleDateString("en-US", options);
  const timeOptions = { hour: "numeric", minute: "numeric", second: "numeric" };
  const formattedTime = dateTime.toLocaleTimeString("en-US", timeOptions);
  const formattedDateTime = `${formattedDate} ${formattedTime}`;

  const handleChange = (e, fieldName) => {
    setLoading(false);
    const { value, checked } = e.target;
    const val = fieldName === "isActive" ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: val,
    }));
  };

  const handleSwitchChange = (event) => {
    const newValue = event?.target?.checked;
    setSwitchValue(newValue);
  };
  const handleSubmoduleChange = (index, e) => {
    const updatedSubModules = [...subModules];
    updatedSubModules[index] = {
      ...updatedSubModules[index],
      mName: e.target.value,
    };
    setSubModules(updatedSubModules);
  };

  useEffect(() => {
    if (subModules) {
    }
  }, [subModules]);

  const handleAddSubModule = () => {
    // if (subModules && subModules.length > 0 && subModules[subModules.length - 1].mName == "") {
    //   setMessage("Please provide name to last submodule")
    //   setOpenAlert(true)
    //   setSeverity("error")
    //   return
    // }

    const currentDateTimeUTC = new Date().toISOString();
    const newSubModule = {
      createdDateTimeUTC: currentDateTimeUTC,
      mId: "",
      mName: "",
      mLevelId: "",
      mLevelDesc: "sub",
      mMappingId: state?.item?.mMappingId || null,
      createdByUser: user ? user.userName : "",
      modifiedByUser: user ? user.userName : "",
      cropStageUUID: state?.item?.cropStageUUID || "",
      subModule: null,
      sequence: subModules?.length+1,
      mStatus:false
    };
    let allSubModules = [...subModules, newSubModule];
    setSubModules(allSubModules)
  };

  const handleDeleteSubModule = (index, status) => {
    let allsubmodule = JSON.parse(JSON.stringify(subModules))
    allsubmodule[index]['mStatus'] = status;
    setSubModules(allsubmodule)
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setErrors({});
      await validationSchema.validate(formData, { abortEarly: false });
    } catch (error) {
      let newError = {};

      error.inner.forEach((err) => {
        newError[err.path] = err.message;
      });
      setErrors(newError);
    }
  };

  const validationSchema = Yup.object({
    // mName: Yup.string().required("Module Name is required"),
    mName: Yup.string().trim()
      .matches(
        /^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/,
        "Speacial characters or extra spaces not allowed"
      )
      .strict(true)
      .min(1, 'Module name needs to be at least 1 char')
      .max(60, 'Module name cannot exceed 60 char').required("Module Name  is required"),
    cropStageName: Yup.string().required("Crop Stage Name is required"),
  });

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      setErrors({});
      await validationSchema.validate(formData, { abortEarly: false });
      const payload =
      {
        subModule: subModules,
        mName: formData?.mName ? formData?.mName : state?.item?.mName,
        mStatus: switchValue,
        mLevelId: 0,
        mLevelDesc: "main",
        mMappingId: state?.item?.mId,
        modifiedByUser: user ? user.userName : "",
        cropStageUUID: state?.item?.cropStageUUID,
        sequence: state?.item?.sequence,
        mId: state?.item?.mId,
      }

      let subModuleError = false;
      let newError = {};

      subModules.forEach((ele, index) => {
        let subName = `submodule-name-${index}`;

        let regex = /^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/
        let valid = true
        if(ele?.mName){
         valid = regex.test(ele.mName)
        }
        if (ele?.mName == "" || ele?.mName == undefined ) {
          newError[subName] = "Submodule name cannot be blank";
          subModuleError = true
        }
        if (!valid) {
          newError[subName] = "Speacial characters or extra spaces not allowed";
          subModuleError = true
        }
      })
      if (subModuleError) {
        setErrors(newError);
        return
      }
      setLoading(true);
      const response = await Service.updateModule(payload);
      if (response?.data) {
        setLoading(false);
        setSeverity("success");
        setMessage("Updated successfully");
        setOpenAlert(true);
        navigate("/getmoduleregistration", { state: { selectedCropStage: state?.cropStage, cropStageUUID: state?.item?.cropStageUUID } })

      }
    } catch (error) {
      let newError = {};
      setLoading(false)
      error?.inner?.forEach((err) => {
        newError[err.path] = err.message;
      });
      if (!newError) {
        setSeverity("error");
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
      }
      setErrors(newError);
    }
  };
  const onDragEnd = (result) => {
    if (!result.destination) {
      return; // Item dropped outside the list
    }
    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    const updatedSubmoduleList = Array.from(subModules);
    const [removed] = updatedSubmoduleList.splice(startIndex, 1);
    updatedSubmoduleList.splice(endIndex, 0, removed);

    updatedSubmoduleList.forEach((ele, index) => {
      ele.sequence = index + 1
    })
    setSubModules(updatedSubmoduleList);
  };

  const handleCancel = () => {
    subModules.forEach((ele) => {
      if (ele.mName == '') {
        let mId = ele?.mId
        Service.remove(mId).then((res) => {
        }).catch((error) => {
          console.log(error)
        })
      }
    });
    navigate("/getmoduleregistration", { state: { selectedCropStage: state?.cropStage, cropStageUUID: state?.item?.cropStageUUID } })
  }

  return (
    <Layout title="Edit Module Setup">
      <div className="moduleContainer">
        <Grid container >
          <Grid
            item
            xs={12}
            sm={9}
            md={6}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box
              sx={{
                marginLeft: { xs: "1rem", sm: 0 },
                marginTop: { xs: 2, sm: 0 },
              }}
            >
              <Heading>Edit Module Setup</Heading>
            </Box>
            <Box
              sx={{ marginLeft: "10px", marginRight: { xs: "1rem", sm: "2rem" } }}
            >
              <Button
                sx={{
                  borderRadius: 100,
                  background: "#102A62",
                }}
                variant="contained"
                disabled={loading}
                startIcon={<AddCircleIcon />}
                onClick={handleAddSubModule}
              >   {loading && <CircularProgress size={20} />}
                Add Sub Modules
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3} md={6} sx={{ paddingTop: { xs: "1rem" } }}>
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", flexDirection: "column", marginLeft: "1rem" }}
            >
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <InputLabel sx={{ margin: 0 }} htmlFor="my-input">
                  Last Modified By:
                </InputLabel>
                <Typography variant="body1"> &nbsp;{state?.item?.modifiedByUser || ""}</Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <InputLabel sx={{ margin: 0 }} htmlFor="my-input">
                  Last Modified Date:
                </InputLabel>
                <Typography variant="body1">
                  {" "}
                  &nbsp;{formatDateDetails(state?.item?.modifiedDateTimeUTC)}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <PaperContainer>
          <HeaderBody>
            <form autoComplete="off" onSubmit={handleSubmit}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid
                  item
                  lg={4}
                  md={4}
                  xs={4}
                  sx={{
                    display: { md: "flex", sx: "block" },
                    alignItems: "center",
                  }}
                >
                  <InputLabel required sx={{ width: "10rem" }} htmlFor="my-input">
                    Module Name
                  </InputLabel>
                  <Box>
                    <div style={{ position: "relative" }}>
                      <TextField
                        inputProps={{ maxLength: 60 }}
                        sx={{ width: { xs: "100%", md: "14rem" } }}
                        value={formData?.mName}
                        name="mName"
                        onChange={(e) => handleChange(e, "mName")}
                        placeholder={formData.mName ? "" : "Type here..."}
                        required variant="outlined"
                      />
                      {errors.mName && (
                        <Box sx={{ position: "absolute", marginLeft: "", marginTop: "0.5rem" }}>
                          <FormHelperText error={true}>
                            {errors.mName}
                          </FormHelperText>
                        </Box>
                      )}
                      {/* {errors.mName && (
                      <FormHelperText error={true}>{errors.mName}</FormHelperText>
                    )} */}
                    </div>
                  </Box>
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  xs={4}
                  sx={{
                    display: { md: "flex", xs: "block" },
                    alignItems: "center",
                  }}
                >
                  <InputLabel required sx={{ width: "10rem" }} htmlFor="my-input">
                    Crop Stage Name
                  </InputLabel>
                  <Box>
                    <TextField
                      sx={{ width: { xs: "100%" } }}
                      id="crop-stage-input"
                      aria-describedby="crop-stage-helper-text"
                      label={state?.cropStage ? "" : "Type here..."}
                      value={formData.cropStageName || state?.cropStage}
                      name="cropStageName"
                      disabled
                      onChange={(e) => handleChange(e, "cropStageName")}
                    />
                    {errors.cropStageName && (
                      <FormHelperText error={true}>
                        {errors.cropStageName}
                      </FormHelperText>
                    )}
                  </Box>
                </Grid>
                <Grid
                  item
                  md={4}
                  lg={4}
                  xs={4}
                  sx={{
                    display: { md: "flex", xs: "block" },
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "row", md: "row" },
                      alignItems: { xs: "flex-start", md: "space-between" },
                      justifyContent: { xs: "flex-start", md: "space-between" },
                    }}
                  >
                    <Box sx={{ marginBottom: { xs: "1rem", md: 0 },marginTop:"5px"}}>
                      <InputLabel>Active</InputLabel>
                    </Box>
                    <Box sx={{marginLeft:"2rem"}}>
                    <Checkbox name="active"
                      checked={switchValue}
                      onChange={handleSwitchChange}
                      icon={<img src={CheckboxUntick} />}
                      checkedIcon={<img src={CheckboxTick} />}
                    />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </HeaderBody>

          <Box sx={{ marginTop: 5 }}>
          </Box>
          <DataContainer>
            <DragDropContext onDragEnd={onDragEnd}>
              {subModules &&
                subModules?.map((submodule, index) => (
                  <Droppable
                    key={submodule.id}
                    droppableId={`submodule-${submodule.mId}`}
                  >
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <Draggable
                          key={submodule.id}
                          draggableId={`submodule-${submodule.mId}`}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <div style={{ marginTop: "20px" }}>
                                {<Line style={{ marginTop: "50px" }} />}
                                <ButtonContainer>
                                  <Grid container spacing={2}>
                                    <Grid
                                      item
                                      md={5}
                                      xs={12}
                                      sx={{
                                        display: { md: "flex", sx: "block" },
                                        alignItems: "center",
                                        marginTop: "20px",
                                      }}
                                    >
                                      <Box sx={{ marginLeft: "1rem" }}>
                                        <SubModuleIcon />
                                      </Box>
                                      <Box sx={{ marginLeft: "1rem" }}>
                                        <InputLabel
                                          sx={{ width: "10rem" }}
                                          htmlFor={`submodule-name-${index}`}
                                        >
                                          Sub Module Name
                                        </InputLabel>
                                      </Box>
                                      <Box sx={{ marginLeft: "1rem" }}>
                                        <TextField
                                          inputProps={{ maxLength: 60 }}
                                          sx={{ width: { xs: "100%", md: 300 } }} // Set width to 300 for md and 100% for xs
                                          // id={`sub-module-${submodule.id}`}
                                          aria-describedby={`sub-module-helper-${submodule.id}`}
                                          placeholder={
                                            formData?.submName === ""
                                              ? "Type here..."
                                              : ""
                                          }
                                          variant="outlined"
                                          value={
                                            formData?.submName || submodule?.mName
                                          }
                                          name={`sub-module-${submodule.id}`}
                                          onChange={(e) =>
                                            handleSubmoduleChange(index, e)
                                          }
                                          error={
                                            !!errors[
                                            `sub-module-${submodule?.id}`
                                            ]
                                          }
                                        />
                                        {errors[`submodule-name-${index}`] && (
                                          <FormHelperText
                                            error={true}
                                            id={`submodule-name-helper-${index}`}
                                          >
                                            {errors[`submodule-name-${index}`]}
                                          </FormHelperText>
                                        )}
                                      </Box>
                                    </Grid>
                                    <Grid
                                      item
                                      md={5}
                                      xs={12}
                                      sx={{
                                        display: { md: "flex", xs: "block" },
                                        alignItems: "center",
                                      }}
                                    ></Grid>
                                    <Grid
                                      item
                                      md={2}
                                      xs={12}
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <InputLabel
                                        sx={{ width: "10rem" }}
                                      >
                                        Active
                                      </InputLabel>
                                      <Checkbox name="active"
                                        checked={submodule?.mStatus}
                                        onChange={(e) => handleDeleteSubModule(index, e.target.checked)}
                                        icon={<img src={CheckboxUntick} />}
                                        checkedIcon={<img src={CheckboxTick} />}
                                      />
                                    </Grid>
                                  </Grid>
                                </ButtonContainer>
                              </div>
                            </div>
                          )}
                        </Draggable>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                ))}
            </DragDropContext>
          </DataContainer>
          <Box sx={{ height: "100px" }} />
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 12, sm: 8, md: 12 }}
            sx={{ justifyContent: "center", marginTop: 10 }}
          >
            <ButtonContainer>
              <Button
                sx={{
                  borderRadius: 2,
                  background: "#737D86",
                  width: { xs: "100%", md: "auto" },
                }}
                variant="contained"
                disabled={loading}
                onClick={() =>
                  handleCancel()
                }

              >
                {loading && <CircularProgress size={20} />}
                Cancel
              </Button>
              <Button
                sx={{
                  borderRadius: 2,
                  background: "#102A62",
                  width: { xs: "100%", md: "auto" },
                }}
                variant="contained"
                onClick={handleUpdate}
                disabled={loading}
              >
                {loading && <CircularProgress size={20} />}
                Update
              </Button>
            </ButtonContainer>
          </Grid>
        </PaperContainer>

      </div>
    </Layout>
  );
};

export default EditRegistration;
