import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Box from "@mui/material/Box";
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  Switch,
  CircularProgress,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { cultureGroupSchema } from "utils/validations";
import CuringService from "services/CuringService";
import CultureGroupService from "services/CultureGroup";
import { SnackbarContext } from "context/snackBarContext";
import arrowRight from "assets/arrowRight.svg";
import arrowLeft from "assets/arrowLeft.svg";
import RedSwitch from "utils/RedSwitch";
import { useTranslation } from 'react-i18next';


export default function AddNewCultureGroup() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const location = useLocation();
  // console.log(location)
  const { description, status, edit, details, id } = location.state || {};
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [values, setValues] = useState({
    cultureGroupDescription: description || "",
    status: status === true ? true : false
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);


   const cultureGroupSchema = yup.object().shape({
    cultureGroupDescription: yup
      .string()
      .required(t("cultureGroupRegistration.validations.cultureReq"))
      .max(40, t("cultureGroupRegistration.validations.cultureMax"))
      .matches(/^[a-zA-Z0-9 ]*$/, t("cultureGroupRegistration.validations.cultureSpe"))
      .matches(
        /^\S.*\S$|^\S$/,
        t("cultureGroupRegistration.validations.cultureSpace")
      ),
  });


  const handleUpdate = async (event) => {
    event.preventDefault();
    let schemaValidationErrors = {};
    try {
      await cultureGroupSchema.validate(values, { abortEarly: false });
    } catch (err) {
      err?.inner?.forEach((error) => {
        schemaValidationErrors[error.path] = error.message;
      });
      setErrors(schemaValidationErrors);
    }
    if (Object.keys(schemaValidationErrors).length === 0) {
      const payload = {
        cultureGroupName: values.cultureGroupDescription,
        status: values.status,
        countryCode: "BRA",
        cultureGroupId: id
      };
      try {
        setLoading(true)
        const response = await CultureGroupService.updateCultureGroup({ ...payload });
        setLoading(false)
        if (response.status === 200) {
          setSeverity("success");
          setMessage(t("snackbarMessage.updatedSuccessfully"));
          setOpenAlert(true);
          navigate("/cultureGroup");
        }
      }
      catch (error) {
        setLoading(false)
        if (error.response) {
          const errorMessage = error.response.data.message;
          setMessage(errorMessage)
          setOpenAlert(true);
        } else {
          console.error("Failed to submit form");
          setSeverity("error");
          setMessage(t("snackbarMessage.updateFailed"));
          setOpenAlert(true);
        }
      }
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let schemaValidationErrors = {};
    try {
      await cultureGroupSchema.validate(values, { abortEarly: false });
    } catch (err) {
      err?.inner?.forEach((error) => {
        schemaValidationErrors[error.path] = error.message;
      });
      setErrors(schemaValidationErrors);
    }

    if (Object.keys(schemaValidationErrors).length === 0) {
      const payload = {
        cultureGroupName: values.cultureGroupDescription,
        status: values.status,
        countryCode: "BRA",
      };
      try {
        setLoading(true)
        const response = await CultureGroupService.createCultureGroup({ ...payload });
        setLoading(false)
        if (response.status === 201) {
          setSeverity("success");
          setMessage(t("snackbarMessage.successAdd"));
          setOpenAlert(true);
          navigate("/cultureGroup");
        }
      }
      catch (error) {
        setLoading(false)
        if (error.response && error.response.status === 409) {
          // console.log("error",error.response.data.message)
          setSeverity("error");
          setMessage(error.response.data.message);
          setOpenAlert(true);
          // navigate("/cultureRegistration");
        } else {
          console.error("Failed to submit form");
          setSeverity("error");
          setMessage(t("snackbarMessage.submissionFailed"));
          setOpenAlert(true);
        }
      }
    }
  };

  return (
    <Layout
      title={t("cultureGroupRegistration.addPageTitle")}
    >

      <Box
        sx={{
          minHeight: "125vh",
          flexGrow: 1,
          paddingBottom: "10rem",
          width: "100%",
          "& .MuiTextField-root": { m: 1 },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
          },
        }}
      >
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              name="cultureGroupDescription"
              label={t("cultureGroupRegistration.cultureGroupDescription")}
              value={values.cultureGroupDescription}
              onChange={handleChange}
              error={!!errors.cultureGroupDescription}
              helperText={errors.cultureGroupDescription}
              required
              variant="outlined"
              disabled={details}
              sx={{ mt: 2, width: "100rem", height: "3.5rem" }}
            />
            <Box
              sx={{
                ml: "38px",
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <FormControl component="fieldset">
                <FormLabel
                  sx={{
                    color: "#0E2B63",
                    mb: 2,
                    ml: 2,
                    "&.Mui-focused": { color: "#0E2B63" },
                    "&:hover": { color: "#0E2B63" },
                  }}
                >
                  {t("status")}
                </FormLabel>
                <FormControlLabel
                  sx={{
                    ml: 2,
                    display: "flex",
                    alignItems: "center",
                    gap: "0.4rem",
                  }}
                  control={
                    <RedSwitch
                      checked={values.status}
                      onChange={handleChange}
                      name="status"
                      color="primary"
                      disabled={details}
                    />
                  }
                  label={
                    <Box sx={{ width: "3rem" }}>
                      {values.status === true ? t("active") : t("inactive")}
                    </Box>
                  }
                  labelPlacement="start"
                />
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "2rem",
              marginTop: "12.313rem",
              //   marginLeft :"44.063rem"
            }}
          >
            <Button
              style={{
                backgroundColor: "#737D86",
                padding: "6px 16px",
                borderRadius: "4px",
              }}
              variant="contained"
              onClick={() => navigate("/cultureGroup")}
              disabled={loading}
              startIcon={<img src={arrowLeft} alt="Arrow" />}
            >
              {loading && <CircularProgress size={20} />}
              {t("cancel")}
            </Button>
            {!details && ( // Hide save button if location.state is present
              <Button
                sx={{
                  backgroundColor: "#004F9F",
                  padding: "6px 16px",
                  borderRadius: "4px",
                  color: "white",
                }}
                type="submit"
                variant="contained"
                endIcon={<img src={arrowRight} alt="Right Arrow" />}
                onClick={!edit ? handleSubmit : handleUpdate}
                disabled={loading}
              >
                {loading && <CircularProgress size={20} />}
                {!edit ? t("save") : t("update")}
                {" "}
                {t("cultureGroupRegistration.addPageTitle")}
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </Layout>
  );
}
