
import { httpGrow } from "../http-common";

const getExpensesTypeList = (query) => {
  return httpGrow.get(
    `/expense-with-3rd-parties/list?pageNumber=${query.pageNumber}&size=${query.size}`
  );
};

const getTobbacoType = () => {
  return httpGrow.get(`/culture/culture-type/tobacco`);
};

const getInvestments = () => {
  return httpGrow.get(`/investment-group/investments`);
};

const createExpenseswith3rdParty = (data) => {
  return httpGrow.post(
    `/expense-with-3rd-parties`,data
  );
};  

const deleteExpenses = (data) => {
  return httpGrow.delete(`/expense-with-3rd-parties/deleteExpenseswith3rdPartiesGroup/${data}`);
};

const searchExpenses = (queryParams) => {
  // const params = {
  //   pageNumber: queryParams.pageNumber,
  //   size: queryParams.size,
  //   expenseswith3rdPartiesGroupDescription : queryParams.expenseswith3rdPartiesGroupDescription,
  //   tobaccoType : queryParams.tobaccoTypeId,
  //   isActive : queryParams.status == "Active" ? true : queryParams.status == "Inactive" ? false : queryParams.status
  // };
  // console.log("params",params)

  // return httpGrow.get('/expense-with-3rd-parties/find', {params});

  const params = new URLSearchParams();
  
  params.append('pageNumber', queryParams.pageNumber);
  params.append('size', queryParams.size);

  if (queryParams.expenseswith3rdPartiesGroupDescription) {
    params.append('expenseswith3rdPartiesGroupDescription', queryParams.expenseswith3rdPartiesGroupDescription);
  }

  if (Array.isArray(queryParams.tobaccoTypeId)) {
    queryParams.tobaccoTypeId.forEach(typeId => {
      params.append('tobaccoTypes', typeId);
    });
  }

  if (queryParams.status === "Active") {
    params.append('isActive', true);
  } else if (queryParams.status === "Inactive") {
    params.append('isActive', false);
  } else if (queryParams.status) {
    params.append('isActive', queryParams.status);
  }

  console.log("params", params.toString());

  return httpGrow.get(`/expense-with-3rd-parties/find?${params.toString()}`);
};


const ExpensesTypeService = {
    getExpensesTypeList,
    getTobbacoType,
    getInvestments,
    searchExpenses,
    createExpenseswith3rdParty,
    deleteExpenses
  };
  

export default ExpensesTypeService

