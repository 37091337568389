import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker,  useMapEvent } from 'react-leaflet';
import axios from 'axios';
import markerIcon from 'assets/Marker.svg'
import L from 'leaflet';
import 'leaflet/dist/leaflet.css'; // Ensur
const MapLeaflet = (props) => {
  const [coordinates, setCoordinates] = useState(null);
  const [altitude, setAltitude] = useState(null);

  const MapClickHandler = () => {
    useMapEvent('click', (event) => {
      const { lat, lng } = event.latlng;
      getAltitude(lat, lng)
      setCoordinates({ lat, lon: lng });
    });
    return null;
  };

  const getAltitude = async (lt, ln) => {
    try {
      const response = await axios.post('https://api.open-elevation.com/api/v1/lookup', {
        locations: [
          { latitude: lt, longitude: ln }
        ]
      });
      setAltitude(response.data.results[0].elevation);
      let obj = { latitude: lt, longitude: ln, altitude: response.data.results[0].elevation }
      props.setCoordinatesParent(obj);
    } catch (error) {
      console.error('Error fetching altitude:', error);
    }
  };
  const customIcon = L.icon({
    iconUrl: markerIcon, // Replace with your icon URL
    iconSize: [25, 41], // Size of the icon
    iconAnchor: [12, 41], // Point of the icon which will correspond to marker's location
    popupAnchor: [1, -34], // Point from which the popup should open relative to the iconAnchor
  });
  return (
    <div>
      <MapContainer center={[51.505, -0.09]} zoom={13}
        style={{ height: '50vh', width: '100%' }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <MapClickHandler />
        {coordinates && (
          <Marker position={[coordinates.lat, coordinates.lon]} icon={customIcon}>
          </Marker>
        )}
      </MapContainer>
    </div>
  );
};

export default MapLeaflet;
