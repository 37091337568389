import { httpGrow } from "../http-common";

const getAll = ({batId,pageNumber,size}) => {
  return httpGrow.get(`/farmer?batOneId=${batId || 85145788}&pageNumber=${pageNumber}&pageSize=${size}`);
};

const getFarmerById = ({id}) => {
  return httpGrow.get(`/farmer/${id}`);
};

const create = data => {
  return httpGrow.post("/farmer", data);
};

const update = data => {
  return httpGrow.put(`/farmer`, data);
};

const remove = id => {
  return httpGrow.delete(`/farmer/${id}`);
};

const removeAll = () => {
  return httpGrow.delete(`/farmer`);
};

const getFarmerUniqueCode = () => {
  return httpGrow.get(`/farmer/generate-unique-code`);
};

const FarmerRegisterationService = {
  getAll,
  getFarmerById,
  create,
  update,
  remove,
  removeAll,
  getFarmerUniqueCode
};

export default FarmerRegisterationService;