import React, { useEffect, useState, useContext } from "react";
import { Typography, Box, Modal, Switch, Button } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import "./GrowingBaseTable.css";
import { useNavigate } from "react-router-dom";
import { SnackbarContext } from "context/snackBarContext";
import RedSwitch from "utils/RedSwitch";
import CityConfigurationService from "services/CityConfigurationService";
import { use } from "i18next";
import { useTranslation } from "react-i18next";


const CityConfigurationTable = ({ TableData: initialData }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [data, setData] = useState(initialData);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [open, setOpen] = useState(false);
  const [confirmSwitchOpen, setConfirmSwitchOpen] = useState(false);
  const [currentSwitchId, setCurrentSwitchId] = useState(null);

  useEffect(() => setData(initialData), [initialData]);

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
    setData((prevData) =>
      [...prevData].sort((a, b) => {
        if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
        if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
        return 0;
      })
    );
  };

  const getSortIcon = (key) =>
    sortConfig.key === key ? (
      sortConfig.direction === "asc" ? (
        <ArrowUpwardIcon fontSize="small" />
      ) : (
        <ArrowDownwardIcon fontSize="small" />
      )
    ) : (
      <ArrowDownwardIcon fontSize="small" />
    );

  const handleSwitchChange = async (id, useThisRecord) => {
    if (useThisRecord === true) {
      setCurrentSwitchId(id);
      setConfirmSwitchOpen(true);
      return;
    }
    else{
      try{
        const itemToUpdate = data.find((item) => item.cityFldTecConfId === id);
  
        if (itemToUpdate) {
          const updatedRecord = {
            ...itemToUpdate,
            useThisRecord: !itemToUpdate.useThisRecord,
          };
          const updatedData = data.map((item) =>
            item.cityFldTecConfId === id ? updatedRecord : item
          );
          setData(updatedData);

          await CityConfigurationService.updateAPI(updatedRecord);
        }
      } catch (error) {
        console.error("Error updating record:", error);
        setMessage(t("errorUpdatingRecord"));
        setSeverity("error");
        setOpenAlert(true);
      } finally {
        setConfirmSwitchOpen(false);
        setCurrentSwitchId(null);
      }
    }
  };

  const handleConfirmSwitchChange = async (id) => {
    try {
      const itemToUpdate = data.find((item) => item.cityFldTecConfId === id);

      if (itemToUpdate) {
        const updatedRecord = {
          ...itemToUpdate,
          useThisRecord: !itemToUpdate.useThisRecord,
        };
        const updatedData = data.map((item) =>
          item.cityFldTecConfId === id ? updatedRecord : item
        );
        setData(updatedData);

        await CityConfigurationService.updateAPI(updatedRecord);
      }
    } catch (error) {
      console.error("Error updating record:", error);
    } finally {
      setConfirmSwitchOpen(false);
      setCurrentSwitchId(null);
    }
  };

  return (
    <div className="table-container">
      <table className="gw-custom-table">
        <thead>
          <tr>
            <th style={{ maxWidth: "10vw" }}>{t("useThisRecord")}</th>
            <th
              style={{ minWidth: "20vw" }}
              onClick={() => handleSort("cityName")}
            >
              {t("city")}
              {getSortIcon("cityName")}
            </th>
            <th
              style={{ minWidth: "15vw" }}
              onClick={() => handleSort("stateCode")}
            >
              {t("region")}
              {getSortIcon("stateCode")}
            </th>
            <th
              style={{ minWidth: "15vw" }}
              onClick={() => handleSort("postalCode")}
            >
              {t("postalCode")}
              {getSortIcon("postalCode")}
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.cityFldTecConfId}>
              <td style={{ maxWidth: "10vw" }}>
                <Box sx={{ mr: "6px" }}>{t("no")}</Box>
                <RedSwitch
                  checked={row.useThisRecord}
                  onChange={() =>
                    handleSwitchChange(row.locFldTecConfId, row.useThisRecord)
                  }
                  color="primary"
                />
                <Box sx={{ ml: "6px" }}>{t("yes")}</Box>
              </td>
              <td style={{ minWidth: "20vw" }}>{row.cityName}</td>
              <td style={{ minWidth: "15vw" }}>{row.stateCode}</td>
              <td style={{ minWidth: "15vw" }}>{row.postalCode}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        open={confirmSwitchOpen}
        onClose={() => setConfirmSwitchOpen(false)}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "560px",
            margin: "auto",
            mt: 25,
            height: "200px",
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
              color: "#fff",
            }}
          >
            {t("alert")}
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography
              variant="body2"
              sx={{ whiteSpace: "pre-wrap", width: "550px", overflowX: "auto" }}
            >
              {t("areYouSure")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "60px",
              pt: 4,
            }}
          >
            <Button
              onClick={() => setConfirmSwitchOpen(false)}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              {t("no")}
            </Button>

            <Button
              onClick={() => handleConfirmSwitchChange(currentSwitchId)}
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#004F9F" },
              }}
            >
              {t("yes")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default CityConfigurationTable;
