// AddSoilAnalysisApplicationPhase.jsx
import React, { useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  CircularProgress,
  Typography,
  Modal,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import arrowRight from "assets/arrowRight.svg";
import arrowLeft from "assets/arrowLeft.svg";
import RedSwitch from "utils/RedSwitch";
import SoilAnalysisApplicationPhaseService from "services/SoilAnalysisApplicationPhaseService";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import Save from "assets/SaveNew.svg";
import { id } from "date-fns/locale";
import { useTranslation } from 'react-i18next';

export default function AddSoilAnalysisApplicationPhase() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const { t } = useTranslation();

  // Get the row data if it exists, otherwise use default values
  const { applicationPhase, status, applicationId } = location?.state?.row || {};
  
  // Get edit state from location state
  const edit = location?.state?.edit;

  const [values, setValues] = useState({
    itemId: applicationId || "",
    discription: applicationPhase || "",
    status: status === undefined ? false : status, // Default to true for new items
  });

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({
    discription: '',
  });

  // Function to handle changes in the form fields
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    
    // Add character limit check for discription
    if (name === 'discription' && value.length > 40) {
      setErrors(prev => ({
        ...prev,
        discription: t('soilAnalysisApplicationPhase.messages.maxCharLimit')
      }));
      return;
    }

    setValues((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));

    // Clear the error if the input is within the limit
    if (name === 'discription') {
      setErrors(prev => ({
        ...prev,
        discription: ''
      }));
    }
  };

  // Function to handle form submission for both edit and add
  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Create a new errors object
    const newErrors = {
      discription: !values.discription.trim() 
        ? t('soilAnalysisApplicationPhase.messages.phaseRequired') 
        : (values.discription.length > 40 
          ? t('soilAnalysisApplicationPhase.messages.maxCharLimit') 
          : ''),
    };
    
    // Set errors
    setErrors(newErrors);

    // Check if there are any errors
    if (newErrors.discription) {
      return;
    }

    const trimmedDesc = values.discription.trim();
    if (!trimmedDesc) {
      setMessage(t('soilAnalysisApplicationPhase.messages.invalidPhase'));
      setSeverity("error");
      setOpenAlert(true);
      return;
    }

    // Set the payload based on whether we're editing or creating
    const payload = {
      applicationPhase: trimmedDesc,
      status: values.status,
      createdDateTimeUTC: new Date().toISOString(),
      createdByUser: "prathamesh",
      modifiedDateTimeUTC: new Date().toISOString(),
      modifiedByUser: "prathamesh",
      isDelete: false,
      countryCode: "bra",
    };

    // Only add applicationId if we're editing
    if (applicationId) {
      payload.applicationId = values.itemId;
    }

    // Call the API for create or update
    try {
      setLoading(true);
      const service = applicationId
        ? SoilAnalysisApplicationPhaseService.updateAPI
        : SoilAnalysisApplicationPhaseService.createAPI;
      const response = await service(payload);
      setLoading(false);

      // Handle the response if successful
      if (response.status === 200) {
        setSeverity("success");
        setMessage(
          applicationId 
            ? t('soilAnalysisApplicationPhase.messages.updateSuccess') 
            : t('soilAnalysisApplicationPhase.messages.addSuccess')
        );
        setOpenAlert(true);
        navigate("/soilAnalysisApplicationPhase");
      }
    }

    // Handle the error responses
    catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 500) {
        setSeverity("error");
        setMessage(t('soilAnalysisApplicationPhase.messages.duplicatePhase', { phase: values.discription }));
        setOpenAlert(true);
      } else {
        setLoading(false);
        setSeverity("error");
        setMessage(error.response?.data?.message || "Operation failed");
        setOpenAlert(true);
      }
    };
  };

  return (
    <Layout
      title={edit === false ? t('soilAnalysisApplicationPhase.title2') : 
            applicationId ? t('soilAnalysisApplicationPhase.title2') : 
            t('soilAnalysisApplicationPhase.title2')}
      background="#ffffff"
    >
      <Box
        sx={{
          minHeight: "10vh",
          flexGrow: 1,
          paddingBottom: "10rem",
          width: "100%",
          "& .MuiTextField-root": { m: 1 },

        }}
      >
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box sx={{ display: "flex" }}>
            <ArrowDropDownIcon sx={{ marginRight: 1 }} />
            <Typography>{t('soilAnalysisApplicationPhase.form')}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              bgcolor: "#EFEFEF",
              height: "120px",
              marginTop: "-25px",
            }}
          >
            <TextField
              name="discription"
              label={t('soilAnalysisApplicationPhase.applicationPhase')}
              value={values.discription}
              onChange={handleChange}
              variant="outlined"
              disabled={!edit}
              required
              placeholder="-"
              error={!!errors.discription}
              helperText={errors.discription}
              InputLabelProps={{ 
                shrink: true,
                sx: {
                  lineHeight: "1.2em",
                  color: !edit ? 'rgba(0, 0, 0, 0.38)' : undefined
                }
              }}
              sx={{
                width: "80%",
                "& .MuiOutlinedInput-root": {
                  padding: "7px",
                  minHeight: "50px",
                  "&.Mui-disabled": {
                    "& fieldset": {
                      borderColor: "rgba(0, 0, 0, 0)",
                    },
                    "&:hover fieldset": {
                      borderColor: "rgba(0, 0, 0, 0)",
                    },
                  },
                },
                "& .MuiInputBase-input::placeholder": {
                  opacity: 1,
                },
                "& .MuiFormHelperText-root": {
                  position: "absolute",
                  bottom: "-30px",
                }
              }}
              InputProps={{ 
                style: { height: "50px" },
                inputProps: { 
                  maxLength: 40,
                  readOnly: !edit
                }
              }}
            />
            <Box
              sx={{
                ml: 0.5,
                display: "flex",
                alignItems: "center",
                gap: "0.4rem",
              }}
            >
              <FormControl component="fieldset">
                <FormLabel
                  sx={{
                    color: "#0E2B63",
                    "&.Mui-focused": { color: "#0E2B63" },
                    "&:hover": { color: "#0E2B63" },
                  }}
                >
                  {t('status')}
                  {/* <span style={{ color: "red" }}> *</span> */}
                </FormLabel>

                {/* Status Switch */}
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ mr: 3, ml: 3 }}>{t('inactive')}</Typography>
                  <FormControlLabel
                    control={
                      <RedSwitch
                        checked={values.status}
                        onChange={handleChange}
                        name="status"
                        disabled={!edit}
                      />
                    }
                    label={<Typography sx={{ ml: 2 }}>{t('active')}</Typography>}
                    labelPlacement="end"
                  />
                </Box>
              </FormControl>
            </Box>
          </Box>

          
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              position: "fixed",
              width: "100%",
              bottom: 0,
              padding: "1rem",
            }}
          >
            <Button
              style={{
                backgroundColor: "#737D86",
                padding: "6px 16px",
                borderRadius: "4px",
                position: "fixed",
                left: "1rem",
                bottom: "1.5rem"
              }}
              variant="contained"
              onClick={() => {
                // Only open modal if in edit mode
                if (edit) {
                  setOpen(true);
                } else {
                  // If in view mode, directly navigate back
                  navigate("/soilAnalysisApplicationPhase");
                }
              }}
              disabled={loading}
              startIcon={<img src={arrowLeft} alt="Arrow" />}
            >
              {t('cancel')}
            </Button>
            {edit && (
              <Button
                sx={{
                  padding: "6px 16px",
                  borderRadius: "4px",
                  position: "fixed",
                  right: "1rem",
                  bottom: "1.5rem",
                  backgroundColor: "#EF7D00",
                  color: "white",
                  "&:hover": { backgroundColor: "#EF7D00" },
                }}
                disabled={loading}
                startIcon={<img src={Save} alt="Right Arrow" />}
                onClick={handleSubmit}
                variant="contained"
              >
                {loading && <CircularProgress size={20} />}
                {t('save')}
              </Button>
            )}
          </Box>
        </form>
      </Box>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "585px",
            margin: "auto",
            mt: 25,
            height: "200px",
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
            >
              {t('cancelTitle')}
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography sx={{ mt: 2, fontWeight: 400 }}>
              {t('cancelMessage')}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              mb: 1,
            }}
          >
            <Button
              onClick={() => setOpen(false)}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              {t('no')}
            </Button>
            <Button
              onClick={() => navigate("/soilAnalysisApplicationPhase")}
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#004F9F" },
              }}
            >
              {t('yes')}
            </Button>
          </Box>
        </Box>
      </Modal>

    </Layout>
  );
}
