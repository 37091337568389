import { httpdev } from "../http-common";

const get = (query) => {
  return httpdev.get(
    `spacing-registration?countryCode=BRA&pageNumber=${query.pageNumber}&pageSize=${query.size}`
  );
};

const create = (data) => {
  return httpdev.post("/spacing-registration", data);
};

const update = (data) => {
  return httpdev.put(`/spacing-registration`, data);
};

const remove = (data) => {
  return httpdev.delete(`/spacing-registration/${data}`);
};

const tobaccoType = (data) => {
  return httpdev.get(
    `/spacing-registration/cultureTypes?countryCode=BRA`,
    data
  );
};

const getTobbacoGroup = () => {
  return httpdev.get(`/culture/culture-group/tobacco`);
};

const getTobbacoType = () => {
  return httpdev.get(`/culture/culture-type/tobacco`);
};

const deleteSubModules = (data) => {
  return httpdev.delete(
    `/spacing-registration/spacingRegistrationDetails/${data}`
  );
};

const filterByTobaccoType = ({
  countryCode,
  tobaccoType,
  pageNumber,
  pageSize,
}) => {
  return httpdev.get(
    `spacing-registration/filter-by-tobacco-type?countryCode=${countryCode}&tobaccoType=${tobaccoType}&pageNumber=${pageNumber}&pageSize=${pageSize}`
  );
};

const filter = ({ countryCode, searchParams, page, size }) => {
  if (searchParams.status && searchParams.status === "Active") {
    searchParams.status = true;
  } else if (searchParams.status && searchParams.status === "Inactive") {
    searchParams.status = false;
  }
  console.log("pageDetails", page + "-" + size);
  const params = {
    ...searchParams,
    pageNumber: page,
    pageSize: size,
    countryCode: countryCode,
  };
  console.log(
    "Request URL:",
    httpdev.getUri({
      url: "spacing-registration/filter-by-tobacco-type?",
      params,
    })
  );
  return httpdev.get("spacing-registration/filter-by-tobacco-type?", {
    params,
  });
};
const SpacingServices = {
  get,
  create,
  update,
  remove,
  deleteSubModules,
  tobaccoType,
  getTobbacoGroup,
  filterByTobaccoType,
  getTobbacoType,
  filter,
};

export default SpacingServices;
