import {
  Autocomplete,
  Box,
  Button,
  debounce,
  FormControl,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Layout from "components/structure/Layout";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React, { useCallback, useContext, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import NewTable from "./NewTable";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Lable from "utils/lables.json";
import FreightService from "services/FreightService";
import { SnackbarContext } from "context/snackBarContext";
import label from "../growingLabel.json";
import { useTranslation } from 'react-i18next';



const AddtionalFreight = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [freightData, setFreightData] = useState([]);
  console.log("🚀 ~ AddtionalFreight ~ freightData:", freightData);
  const [tobaccoType, setTobaccoType] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  // const [itemsPerPage, setItemsPerPage] = useState(5);
  const [itemsPerPage, setItemsPerPage] = useState(() => {
    return parseInt(localStorage.getItem("IPPAF")) || 5;
  });

  const [totalItems, setTotalItems] = useState(0); // Number of items per page
  const [manureOptions, setManureOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  console.log("🚀 ~ AddtionalFreight ~ manureOptions:", manureOptions);

  const paginate = (pageNumber, itemsPerPage) => {
    // callManureApi(pageNumber - 1, itemsPerPage);
    localStorage.setItem("IPPAF", itemsPerPage);
    setCurrentPage(pageNumber - 1);
    if (keyword || tobaccoType) {
      // const isActive =
      // tobaccoType === "Active" ? true : tobaccoType === "Inactive" ? false : null;
      callSearchFreightAPI(pageNumber - 1, itemsPerPage, keyword, tobaccoType);
    } else {
      callManureApi(pageNumber - 1, itemsPerPage);
    }
  };

  const fetchManureDataFull = async () => {
    try {
      const response = await FreightService.searchFreightFullData();
      const data = response?.data?.response?.additionalFreight;
      setManureOptions(data);
    } catch (error) {
      console.error("Error fetching Additional Freight data:", error);
      setMessage(error?.response?.data?.message || error?.message);
      setOpenAlert(true)
      setSeverity("error");
    }
  };

  // useEffect(() => {
  //   if (keyword) {
  //     setLoading(true);
  //     const fetchManureData = async () => {
  //       try {
  //         const response = await FreightService.searchFreightData(keyword);
  //         const data = response?.data?.response?.additionalFreight;
  //         setManureOptions(data);
  //       } catch (error) {
  //         console.error("Error fetching Additional Freight data:", error);
  //         setManureOptions([]); // Clear options on error
  //         setMessage(error?.response?.data?.message || error?.message);
  //         setOpenAlert(true)
  //         setSeverity("error");
  //       } finally {
  //         setLoading(false);
  //       }
  //     };
  //     fetchManureData();
  //   } else {
  //     fetchManureDataFull();
  //     // setManureOptions([]); // Clear options if no keyword
  //   }
  // }, [keyword]);

  const callManureApi = (pNumber, size) => {
    // setLoading(true);
    // setRecordsError(false);
    FreightService.getFreight({ pageNumber: pNumber, size: size })
      .then((res) => {
        // setLoading(false);
        // setRecordsError(false);
        setFreightData(res?.data?.response?.additionalFreight);
        setItemsPerPage(size);
        setTotalItems(res?.data?.response?.totalItems);
        setCurrentPage(pNumber);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  const filterManure = async (status) => {
    try {
      const response = await FreightService.filterFreigth(keyword, status);
      const data = response?.data?.response?.additionalFreight;
      setFreightData(data);
      setTotalItems(response?.data?.response?.totalItems);

      console.log(response.data); // Handle the API response
    } catch (error) {
      setFreightData([]);

      console.error("Error fetching data:", error);
    }
  };

  const handleTobaccoTypeChange = (e) => {
    const value = e.target.value;
    setTobaccoType(value);

    if (value === "") {
      setTobaccoType("");
      // resetFilters();
    } else {
      // const isActive =
      // value === "Active" ? true : value === "Inactive" ? false : null;
      callSearchFreightAPI(0, itemsPerPage, keyword, value);
    }
    // if (value === "Active") {
    //   filterManure(true);
    // } else if (value === "Inactive") {
    //   filterManure(false);
    // }
  };

  const clearFilter = async () => {
    setTobaccoType("");
    if (keyword) {
      const fetchManureData = async () => {
        try {
          const response = await FreightService.searchFreightData(keyword);
          const data = response?.data?.response?.additionalFreight;
          setManureOptions(data);
          setFreightData(data);
          setTotalItems(response?.data?.response?.totalItems);
        } catch (error) {
          console.error("Error fetching Additional Freight data:", error);
          setManureOptions([]); // Clear options on error
          //          setMessage(error?.response?.data?.message || error?.message);
        } finally {
          setLoading(false);
        }
      };
      fetchManureData()
    } else {
      callManureApi(currentPage, itemsPerPage);
    }
  };


  const callSearchFreightAPI = (pageNumber, size, keyword, status) => {
    setLoading(true);
    console.log("statusOnSearch", status)
    const isActive =
      status === "Active" ? true : status === "Inactive" ? false : null;
    let query = { pageNumber, size, keyword };
    if (isActive === true || isActive === false) {
      query.status = isActive;
    }
    // console.log("statusOnSearch", query)
    FreightService.filterFreigth(query)
      .then((res) => {
        const results = res?.data?.response?.additionalFreight || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setFreightData(results);
        setTotalItems(res?.data?.response?.totalItems || 0);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setFreightData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // const filterManureAutocomplete = async (keyword) => {
  //   try {
  //     const response = await GreenManureService.filterManureAutocomplete(
  //       keyword
  //     );
  //     const data = response?.data?.response?.greenManures;
  //     setTobaccoType("");
  //     setGreenData(data);
  //   } catch (error) {
  //     console.error("Error fetching manure data:", error);
  //     return [];
  //   }
  // };

  useEffect(() => {
    callManureApi(currentPage, itemsPerPage);
  }, []);

  const refreshFreightData = () => {
    // Call the API again after an item is deleted
    const totalItemsOnPage = freightData?.length ?? 0;

    if (keyword === "" && tobaccoType === "") {
      if (totalItemsOnPage === 1 && currentPage > 0) {
        // If we're on the last item of the page, move back a page if possible
        callManureApi(currentPage - 1, itemsPerPage);
      } else if (totalItemsOnPage === 1 && currentPage == 0) {
        setFreightData([]);
        // setNoRecordFound(true);
      } else {
        // Otherwise, refresh the current page
        callManureApi(currentPage, itemsPerPage);
      }
    }
    else {
      if (totalItemsOnPage === 1 && currentPage > 0) {
        callSearchFreightAPI(currentPage - 1, itemsPerPage, keyword, tobaccoType);
      } else if (totalItemsOnPage === 1 && currentPage == 0) {
        setFreightData([]);
        // setNoRecordFound(true);
      } else {
        callSearchFreightAPI(currentPage, itemsPerPage, keyword, tobaccoType);
      }
    }

  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setKeyword(value);
    debouncedSearch(value, tobaccoType);
  };

  const debouncedSearch = useCallback(
    debounce((value, tobaccoType) => {
      console.log("StatusInside", tobaccoType);
      callSearchFreightAPI(currentPage, itemsPerPage, value, tobaccoType)
    }, 300),
    [itemsPerPage]
  );
  console.log("StatusTpe", tobaccoType);
  return (
    <Layout
      background="#ffffff"
      title="Additional Freight Group Registration List"
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      useNewPagination={true}
      currentPage={currentPage}
    >
      <Box sx={{ background: "#EFEFEF", borderRadius: "5px", padding: "1rem", paddingBottom: "2rem" }}>
        <Box sx={{ display: "flex" }}>
          <ArrowDropDownIcon sx={{ marginRight: 1 }} />
          <Typography>{t("filtersLable")}</Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
          <Box sx={{ display: "flex", }}>
            {/* <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={manureOptions}
              getOptionLabel={(option) => option.additionalFreightGroupDescription}
              onChange={async (event, newValue) => {
                if (newValue) {
                  callSearchFreightAPI(currentPage, itemsPerPage, newValue.additionalFreightGroupDescription, tobaccoType)
                } else {
                  console.log("delete");
                  callSearchFreightAPI(currentPage, itemsPerPage, "", tobaccoType)
                }
              }}
              sx={{ width: 600 }}
              onInputChange={(event, newInputValue) => {
                setKeyword(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Additional Freight Group Description"
                  InputLabelProps={{ shrink: true }}
                  placeholder="-"
                  sx={{
                    '& .MuiInputBase-input::placeholder': {
                      opacity: 1,
                    },
                  }}
                />
              )}
            /> */}
            <TextField
              autoComplete="off"
              name="description"
              label="Additional Freight Group Description"
              value={keyword}
              onChange={handleSearchChange}
              placeholder="-"
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                width: 600,
                height: "46px",
                '& .MuiInputBase-input::placeholder': {
                  opacity: 1,
                },
              }}
            ></TextField>
            <FormControl
              sx={{ width: { xs: "100%", md: 222 }, minHeight: 50, ml: 3 }}
            >
              <TextField
                select
                id="tobaccoType"
                labelId="tobacco-select-label"
                label={t("status")}
                value={tobaccoType || "pl"}
                onChange={handleTobaccoTypeChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: tobaccoType && (
                    <InputAdornment position="end">
                      <ClearIcon
                        onClick={clearFilter}
                        sx={{ mr: 2, cursor: "pointer" }}
                      />
                    </InputAdornment>
                  ),
                }}
              >
                <MenuItem value="pl">{t("selectoption")}</MenuItem>
                <MenuItem value="Active">{t("active")}</MenuItem>
                <MenuItem value="Inactive">{t("inactive")}</MenuItem>
              </TextField>
            </FormControl>
          </Box>
          <Box>
            <Button
              sx={{
                borderRadius: 1,
                minWidth: 95,
                minHeight: 44,
                background: "#102A62",
                marginTop: { xs: 1 },
                marginLeft: { xs: 1 },
              }}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => navigate("/addFreightGroupRegistration")}
            >
              {t("addNew")}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box>
        {freightData.length > 0 ? (
          <NewTable
            freight={freightData}
            refreshData={refreshFreightData}
          />
        ) : (
          <Typography variant="h6" align="center" color="textSecondary">
            {t("noDataMessage")}
          </Typography>
          // <Box
          //   sx={{
          //     display: "flex",
          //     justifyContent: "center",
          //     height: "40vh",
          //     alignItems: "center"
          //   }}
          // >
          //   <Box>{label.noDataMessage}</Box>
          // </Box>
        )}
      </Box>
    </Layout>
  );
};

export default AddtionalFreight;
