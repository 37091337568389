import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import Tabs from "@mui/material/Tabs";
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  Switch,
  CircularProgress,
  Typography,
  Modal,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  IconButton,
  Chip,
  Tab,
} from "@mui/material";
import Layout from "components/structure/Layout";
import {
  soilParameterSchema,
  transportVehiclesSchema,
} from "utils/validations";
import CuringService from "services/CuringService";
import CultureGroupService from "services/CultureGroup";
import TransporterGetService from "services/TransporterGetService";
import { SnackbarContext } from "context/snackBarContext";
import arrowRight from "assets/arrowRight.svg";
import arrowLeft from "assets/arrowLeft.svg";
import RedSwitch from "utils/RedSwitch";
import PlantingService from "services/PlantingService";
import Save from "assets/SaveNew.svg";
import TobaccoService from "services/TobaccoService";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import Lable from "utils/lables.json";
import SoilParameterService from "services/SoilParameterService";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import { useTranslation } from "react-i18next";
import TransporterRegistrationService from "services/TransporterRegistrationService";
import * as Yup from "yup";

const VEHICLE_TYPES = [
  "Cavalo",
  "Carreta",
  "Dolly",
  "Truck",
  "Bitruck",
  "Caminhoneta",
];

export default function Vehichles() {
  const navigate = useNavigate();

  const location = useLocation();

  const { row, edit } = location.state || {};

  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [dataType, setDataType] = useState([]);
  const [region, setRegion] = useState([]);
  // const [values, setValues] = useState({})

  const [open, setOpen] = useState(false);
  const [fileNames, setFileNames] = useState([]);

  const fileInputRef = useRef(null);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const getRegionList = async () => {
    try {
      const response = await TransporterGetService.getRegion();
      const d = response.data.response;
      // Store the full region objects instead of just stateCode
      setRegion(d);
    } catch (error) {
      console.error("Error fetching regions:", error);
      return null;
    }
  };
  useEffect(() => {
    getRegionList();
  }, []);

  useEffect(() => {
    console.log("sdfghn", dataType);
  });
  // Function to close the modal
  const handleCloseModal = () => {
    setOpen(false);
  };

  // Function when user confirms "Yes" on the modal
  const handleDeleteConfirm = () => {
    setOpen(false);
    navigate("/transporterRegistration");
  };

  const [rows, setRows] = useState([
    {
      id: Date.now(),
      licensePlate: "",
      regionId: "",
      vehicleType: "",
      vehicleAxles: "",
      vehicleCapacity: "",
      vehicleEnvironmentalAgency: false, // Match the property name used in the form
      leasedVehicle: false, // Match the property name used in the form
      vechiclesListId: "",
    },
  ]);
  console.log("🚀 ~ Vehichles ~ rows:", rows);

  const handleChange = (event, rowId) => {
    const { name, value, type, checked } = event.target;

    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === rowId
          ? {
              ...row,
              // Fix the property names to match the state structure
              [name]:
                type === "checkbox"
                  ? name === "leased"
                    ? checked
                    : checked // Handle both leased and environmentalAgency
                  : value,
            }
          : row
      )
    );
  };

  const addRow = () => {
    console.log("roww", rows);
    setRows((prevRows) => [
      ...prevRows,
      {
        id: Date.now(),
        licensePlate: "",
        regionId: "",
        vehicleType: "",
        vehicleAxles: "",
        vehicleCapacity: "",
        environmentalAgency: false,
        leased: false,
        vechilesListId: "",
      },
    ]);
  };

  const deleteRow = async (id) => {
    try {
      setLoading(true);
      const response = await TransporterRegistrationService.deleteVehicle(id);
      if (response.status === 200) {
        setMessage("vehicles deleted sucessfully");
        setSeverity("success");
        setOpenAlert(true);
        setRows((prevData) =>
          prevData.filter((item) => item.vechiclesListId !== id)
        );
        //props.refreshData()
      } else {
        setMessage("Failed to delete Tobacco Company");
        setSeverity("error");
        setOpenAlert(true);
      }
    } catch (error) {
      setMessage("Error deleting tray type");
      setSeverity("error");
      setOpenAlert(true);
      console.error("Error deleting tray type:", error);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  const [transporterData, setTransporterData] = useState(null);

  // const fetchTransporterData = async () => {
  //   if (location?.state?.row?.transporterID) {
  //     try {
  //       const response = await TransporterRegistrationService.getTransporter(location.state.row.transporterID);
  //       const data = response.data.response;
  //       console.log("Fetched transporter data:", data);
  //       setTransporterData(data);
  //     } catch (error) {
  //       console.error("Error fetching transporter data:", error);
  //       setSeverity("error");
  //       setMessage("Failed to fetch transporter details");
  //       setOpenAlert(true);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (transporterData) {
  //     setValues((prevValues) => ({
  //       ...prevValues,
  //       sapParameterName: transporterData.statusReason || "",
  //       sapExternalCode: transporterData.status || "",
  //     }));
  //   }
  // }, [transporterData]);

  const fetchTransporterData = async () => {
    if (row?.transporterID) {
      try {
        const response = await TransporterRegistrationService.getTransporter(
          row.transporterID
        );
        const data = response.data.response;
        console.log("Fetched transporter data:", data);
        setTransporterData(data);
        console.log("vehh", data.vehicleList);
        setRows(data?.vehicleList);

        // Update file names if there's an existing document
        // if (data.attachedDocumentPath) {
        //   setFileNames(data.attachedDocumentPath.split(", ").filter(Boolean));
        // }
      } catch (error) {
        console.error("Error fetching transporter data:", error);
        setSeverity("error");
        setMessage("Failed to fetch transporter details");
        setOpenAlert(true);
      }
    }
  };

  useEffect(() => {
    fetchTransporterData();
  }, [location?.state?.row?.transporterID]);

  const handleUpdate = async (event) => {
    event.preventDefault();

    try {
      // await transportVehiclesSchema.validate(rows, { abortEarly: false });
      setErrors({});
      setLoading(true);

      // Convert boolean strings to actual booleans
      const ciotRequirementBool = transporterData?.cioTRequirement === true;
      const tallVoucherBool = transporterData?.tallVoucher === true;

      // Format partners list if it exists
      const formattedPartners =
        transporterData?.partnerListList?.map((partner) => ({
          createdByUser: "Mayur",
          createdDateTimeUTC: new Date().toISOString(),
          modifiedByUser: "Mayur",
          modifiedDateTimeUTC: new Date().toISOString(),
          plName: partner.plName,
          plFiscalID: partner.plFiscalID,
          plCardId: partner.plCardId,
          plDOB: partner.plDOB,
          plEmail: partner.plEmail,
          plPhoneNumber: partner.plPhoneNumber,
        })) || [];

      // Transform rows data into the required vehicles format
      const vehiclesPayload = rows.map((row) => ({
        createdByUser: "Mayur",
        createdDateTimeUTC: new Date().toISOString(),
        modifiedByUser: "Mayur",
        modifiedDateTimeUTC: new Date().toISOString(),
        licensePlate: row.licensePlate,
        vehicleType: row.vehicleType,
        regionId: row.regionId,
        leasedVehicle: row.leased,
        vehicleAxles: Number(row.vehicleAxles),
        vehicleCapacity: Number(row.vehicleCapacity),
        vehicleEnvironmentalAgency: row.environmentalAgency,
        vechiclesListId: row.vechiclesListId,
      }));

      // Combine all data into final payload
      const payload = {
        // Metadata
        createdDateTimeUTC: new Date().toISOString(),
        createdByUser: "Mayur",
        modifiedDateTimeUTC: new Date().toISOString(),
        modifiedByUser: "Mayur",

        fiscalID: transporterData.fiscalID,

        // Business Information
        businessType: transporterData.businessType,
        businessNumber: transporterData.businessNumber,
        transporterID: location.state.row.transporterID,
        transporterName: transporterData.transporterName,
        nationalityId: transporterData.nationalityId,
        maritalStatusId: transporterData.maritalStatusId,

        // Route Details
        buyingStation: transporterData.buyingStation,
        buyingStationRoute: transporterData.buyingStationRoute,
        supplyInvoicingUnit: transporterData.supplyInvoicingUnit,
        supplyInvoicingUnitRoute: transporterData.supplyInvoicingUnitRoute,

        // Bank Information
        bankAccountType: transporterData.bankAccountType,
        bankRegistrationId: transporterData.bankRegistrationId,
        bankBranchRegistrationID: transporterData.bankBranchRegistrationID,
        accountNumber: transporterData.accountNumber,

        // Responsible Users
        ftResponsibleUID: transporterData.ftResponsibleUID,
        tmResponsibleUID: transporterData.tmResponsibleUID,

        // Address Information
        address: transporterData.address,
        neighborhood: transporterData.neighborhood,
        postalCode: transporterData.postalCode,
        regionId: transporterData.regionId,

        // Company Information
        businessSize: transporterData.businessSize,
        cioTRequirement: ciotRequirementBool,
        companyEstablishmentDate: transporterData.companyEstablishmentDate,
        coupaRegisterNumber: transporterData.coupaRegisterNumber,
        emailForInvoices: transporterData.emailForInvoices,
        expirationDateCoupa: transporterData.expirationDateCoupa,
        externalCode: transporterData.externalCode,
        fieldTechnicianUser: transporterData.fieldTechnicianUser,

        // Tax and Legal Information
        icmsTaxPercentage: transporterData.icmsTaxPercentage || 0,
        legalName: transporterData.legalName,
        legalNatureCode: transporterData.legalNatureCode,
        mainEconomicActivityCode: transporterData.mainEconomicActivityCode,
        secondaryEconomicActivityCode:
          transporterData.secondaryEconomicActivityCode,
        sapVendorCode: transporterData.sapVendorCode,
        stateRegistrationNumber: transporterData.stateRegistrationNumber,
        tallVoucher: tallVoucherBool,
        taxation: transporterData.taxation,
        tradeName: transporterData.tradeName,
        transporterLicenseNumber: transporterData.transporterLicenseNumber,

        // Status from control data (if available)
        status: transporterData.status,
        statusReason: transporterData.statusReason,
        attachedDocumentPath: transporterData.attachedDocumentPath,

        // Partners List
        partnerListList: formattedPartners,

        // Vehicles List (from current component)
        vehicleList: vehiclesPayload,
      };

      const response = await TransporterRegistrationService.updateTransporter(
        payload
      );

      setLoading(false);
      setSeverity("success");
      setMessage("Vehicles updated successfully");
      setOpenAlert(true);
      navigate("/transporterRegistration");
    } catch (err) {
      console.log("ERR AA RHA HAI");
      if (err instanceof Yup.ValidationError) {
        let newError = {};
        err?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        console.log("ERR AA RHA HAI", newError);
        setErrors(newError);
      } else {
        console.error("An error occurred while submitting the form:", err);
        setSeverity("error");
        setMessage(
          err?.response?.data?.message ||
            "An error occurred while submitting the form"
        );
        setOpenAlert(true);
      }
    }
  };

  const { t } = useTranslation();

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        sx={{
          borderBottom: "5px solid #004F9F",
          mt: "15px",
          px: 1, // Add horizontal padding to match other components
          width: "auto", // Allow container to respect padding
          mx: 1, // Add horizontal margin to match other components
        }}
      >
        <Grid>
          <Typography sx={{ color: "#5A646E", fontWeight: "600" }}>
            {t("TransportationRegistration.sections.vehicleList")}
          </Typography>
        </Grid>
        <Grid>
          {edit && (
            <Button
              variant="contained"
              sx={{
                minWidth: 95,
                borderRadius: 1,
                background: "#004F9F",
                height: { xs: "40px", md: "44px" },
                marginLeft: { xs: 0, md: "auto" },
                mb: "5px",
              }}
              onClick={addRow}
            >
              {t("TransportationRegistration.buttons.addNew")}
            </Button>
          )}
        </Grid>
      </Grid>

      <Box
        sx={{
          maxHeight: 115,
          flexGrow: 1,
          paddingBottom: "10rem",
          width: "98%",
          "& .MuiTextField-root": { m: 1 },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
          },
        }}
      >
        <form autoComplete="off" noValidate>
          <Box
            sx={{
              bgcolor: "#EFEFEF",
              minHeight: "86px",
              marginTop: "25px",
              padding: 2,
              width: "100%",
            }}
          >
            {rows.map((row) => (
              <Grid
                container
                alignItems="center"
                key={row.id}
                sx={{
                  mb: rows.length > 1 ? 2 : 0,
                  display: "flex",
                  flexWrap: "nowrap",
                  gap: "16px",
                }}
              >
                {/* License Plate */}
                <Grid item sx={{ flex: "1 1 0" }}>
                  <FormControl fullWidth>
                    <TextField
                      name="licensePlate"
                      label={t(
                        "TransportationRegistration.formLabels.licensePlate"
                      )}
                      value={row.licensePlate}
                      onChange={(e) => handleChange(e, row.id)}
                      disabled={edit === false}
                      error={!!errors[`licensePlate-${row.id}`]}
                      helperText={errors[`licensePlate-${row.id}`] || ""}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-disabled": {
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 0)", // Default disabled border color
                              "&:hover": {
                                borderColor: "rgba(0, 0, 0, 0)", // Prevent hover effect
                              },
                            },
                          },
                        }
                      }}
                    />
                  </FormControl>
                </Grid>

                {/* Region */}
                <Grid item sx={{ flex: "1 1 0" }}>
                  <FormControl fullWidth>
                    <TextField
                      name="regionId"
                      label={t("TransportationRegistration.formLabels.region")}
                      value={row.regionId}
                      error={!!errors.regionId} // Show error state
                      helperText={errors.regionId || ""} // Show error message
                      onChange={(e) => handleChange(e, row.id)}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-disabled": {
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 0)", // Default disabled border color
                              "&:hover": {
                                borderColor: "rgba(0, 0, 0, 0)", // Prevent hover effect
                              },
                            },
                          },
                        }
                      }}
                      select
                      InputLabelProps={{ shrink: true }} // Keeps label lifted up
                      SelectProps={{
                        displayEmpty: true, // Ensures placeholder is visible
                        renderValue: (selected) =>
                          selected
                            ? region.find((r) => r.regionId === selected)
                                ?.stateCode
                            : "Select an Option",
                      }}
                      disabled={edit === false}
                    >
                      {region.map((regionItem) => (
                        <MenuItem
                          key={regionItem.regionId}
                          value={regionItem.regionId}
                        >
                          {regionItem.stateCode}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>

                {/* Vehicle Type */}
                <Grid item sx={{ flex: "1 1 0" }}>
                  <FormControl fullWidth>
                    <TextField
                      name="vehicleType"
                      label={t("TransportationRegistration.formLabels.type")}
                      value={row.vehicleType}
                      onChange={(e) => handleChange(e, row.id)}
                      select
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-disabled": {
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 0)", // Default disabled border color
                              "&:hover": {
                                borderColor: "rgba(0, 0, 0, 0)", // Prevent hover effect
                              },
                            },
                          },
                        }
                      }}
                      InputLabelProps={{ shrink: true }} // Keeps label lifted up
                      SelectProps={{
                        displayEmpty: true, // Ensures placeholder is visible
                        renderValue: (selected) =>
                          selected ? selected : "Select an Option",
                      }}
                      disabled={edit === false}
                    >
                      {VEHICLE_TYPES.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>

                {/* Axles */}
                <Grid item sx={{ flex: "1 1 0" }}>
                  <FormControl fullWidth>
                    <TextField
                      name="vehicleAxles"
                      label={t("TransportationRegistration.formLabels.axles")}
                      value={row.vehicleAxles}
                      onChange={(e) => handleChange(e, row.id)}
                      disabled={edit === false}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-disabled": {
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 0)", // Default disabled border color
                              "&:hover": {
                                borderColor: "rgba(0, 0, 0, 0)", // Prevent hover effect
                              },
                            },
                          },
                        }
                      }}
                    />
                  </FormControl>
                </Grid>

                {/* Capacity */}
                <Grid item sx={{ flex: "1 1 0" }}>
                  <FormControl fullWidth>
                    <TextField
                      name="vehicleCapacity"
                      label={t(
                        "TransportationRegistration.formLabels.capacity"
                      )}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-disabled": {
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 0)", // Default disabled border color
                              "&:hover": {
                                borderColor: "rgba(0, 0, 0, 0)", // Prevent hover effect
                              },
                            },
                          },
                        }
                      }}
                      value={row.vehicleCapacity}
                      // onChange={(e) => handleChange(e,(row.id?row.id:row.vechiclesListId))}
                      onChange={(e) =>
                        handleChange(e, row.id, row.vechiclesListId)
                      }
                      disabled={edit === false}
                    />
                  </FormControl>
                </Grid>

                {/* Environmental Agency */}
                <Grid item sx={{ flex: "1.2 1 0" }}>
                  <FormControl
                    component="fieldset"
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <FormLabel
                      sx={{
                        color: "#0E2B63",
                        mb: 1,
                        "&.Mui-focused": { color: "#0E2B63" },
                        "&:hover": { color: "#0E2B63" },
                        textAlign: "center", // Center the label text
                      }}
                    >
                      {t(
                        "TransportationRegistration.formLabels.environmentalAgency"
                      )}
                    </FormLabel>
                    <FormControlLabel
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center", // Center the switch and label
                        margin: 0, // Remove default margin
                        "& .MuiFormControlLabel-label": {
                          flex: "0 0 auto", // Prevent label from stretching
                        },
                      }}
                      control={
                        <RedSwitch
                          checked={row.vehicleEnvironmentalAgency}
                          onChange={(e) => handleChange(e, row.id)}
                          name="vehicleEnvironmentalAgency"
                          disabled={edit === false}
                        />
                      }
                      label={
                        row.vehicleEnvironmentalAgency ? t("yes") : t("no")
                      }
                      labelPlacement="start"
                    />
                  </FormControl>
                </Grid>

                {/* Leased */}
                <Grid item sx={{ flex: "1 1 0" }}>
                  <FormControl
                    component="fieldset"
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <FormLabel
                      sx={{
                        color: "#0E2B63",
                        mb: 1,
                        "&.Mui-focused": { color: "#0E2B63" },
                        "&:hover": { color: "#0E2B63" },
                        textAlign: "center", // Center the label text
                      }}
                    >
                      {t("TransportationRegistration.formLabels.leased")}
                    </FormLabel>
                    <FormControlLabel
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center", // Center the switch and label
                        margin: 0, // Remove default margin
                        "& .MuiFormControlLabel-label": {
                          flex: "0 0 auto", // Prevent label from stretching
                        },
                      }}
                      control={
                        <RedSwitch
                          checked={row.leasedVehicle}
                          onChange={(e) => handleChange(e, row.id)}
                          name="leasedVehicle"
                          disabled={edit === false}
                        />
                      }
                      label={row.leased ? t("yes") : t("no")}
                      labelPlacement="start"
                    />
                  </FormControl>
                </Grid>

                {/* Delete Button */}
                {rows.length > 1 && (
                  <Grid item sx={{ flex: "0 0 auto" }}>
                    <IconButton
                      onClick={() => deleteRow(row.vechiclesListId)}
                      sx={{
                        padding: "4px",
                        marginTop: "8px",
                      }}
                    >
                      <img
                        src={_ButtonDelete_}
                        alt="Delete"
                        style={{
                          width: "24px",
                          height: "24px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            ))}
          </Box>

          <Divider
            sx={{
              position: "fixed",
              bottom: "82px",
              width: "97%",
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              position: "fixed",
              width: "96%",
              bottom: 6,
              padding: "0.5rem",
              backgroundColor: "white", // Set background color to white
            }}
          >
            {/* Cancel Button - Left bottom corner */}
            <Button
              style={{
                backgroundColor: "#737D86",
                padding: "6px 16px",
                borderRadius: "4px",
                marginBottom: "16px",
                height: "44px",
                marginLeft: "10px",
              }}
              variant="contained"
              onClick={edit == false ? handleDeleteConfirm : handleOpenModal}
              disabled={loading}
              startIcon={<img src={arrowLeft} alt="Arrow" />}
            >
              {loading && <CircularProgress size={20} />}
              {edit == false ? t("back") : t("cancel")}
            </Button>

            {/* Center Buttons */}

            <Modal
              open={open}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "7px",
                  width: "585px",
                  margin: "auto",
                  mt: 25,
                  height: "200px",
                }}
              >
                <Box
                  sx={{
                    background: "#102A62",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                  }}
                >
                  <Typography
                    sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
                  >
                    {t("cancelTitle")}
                  </Typography>
                </Box>
                <Box sx={{ p: 2 }}>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, fontWeight: 400 }}
                  >
                    {t("cancelMessage")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                    mb: 1,
                  }}
                >
                  <Button
                    onClick={handleCloseModal}
                    sx={{
                      background: "#737D86",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#737D86" },
                    }}
                  >
                    {t("no")}
                  </Button>
                  <Button
                    onClick={handleDeleteConfirm}
                    sx={{
                      background: "#004F9F",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#004F9F" },
                    }}
                  >
                    {t("yes")}
                  </Button>
                </Box>
              </Box>
            </Modal>

            {/* Save Button - Right bottom corner */}
            {edit !== false && (
              <Button
                sx={{
                  padding: "6px 16px",
                  borderRadius: "4px",
                  marginBottom: "16px",
                  backgroundColor: "#EF7D00",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#EF7D00",
                  },
                  marginRight: "10px",
                }}
                onClick={handleUpdate}
                disabled={loading}
                startIcon={<img src={Save} alt="Right Arrow" />}
                variant="contained"
              >
                {loading && <CircularProgress size={20} />}
                {t("save")}
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </>
    // </Layout>
  );
}
