import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CustomButton from 'utils/CustomButton';
import { Input } from '@mui/base';
import { TextField } from '@mui/material';
import { SnackbarContext } from 'context/snackBarContext';
import { useTranslation } from "react-i18next";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "60%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function HierarchyModal(props) {
    const [loading, setLoading] = React.useState(false);
    const { t } = useTranslation();

    const {
        setOpenAlert,
        setSeverity,
        setMessage,
    } = React.useContext(SnackbarContext);

    const onNext = () => {
        if (props.string == "") {
            setMessage("Please enter level name to proceed")
            setOpenAlert(true)
            setSeverity("error")
        } else {
            props.onNext()
        }
    }

   


    return (
        <div>
            <Modal
                open={props.open}
                onClose={() => { props.onClose() }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t("hierarchyConfiguration.pleaseEnterValueName")}
                    </Typography>
                    <TextField
                        fullWidth
                        inputProps={{ maxLength: 60 }}
                        value={props.string}
                        onChange={(e) => { props.onLevelNameInput(e.target.value) }}
                        name="levelName"
                        placeholder={t("hierarchyConfiguration.valueNamePlaceholderText")} variant="outlined" />
                    <Box
                        sx={{
                            mt: 4,
                            justifyContent: "flex-end",
                            display: "flex",
                            gap: "1rem",
                        }}
                    >
                        <CustomButton loading={props.loading} variant="cancel" onClick={() => { props.onClose() }} label={t("cancel")} />
                        <CustomButton loading={props.loading} onClick={() => { onNext() }} label={t("save")} />
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
