import Navbar from "components/navbar/Navbar";
import Layout from "components/structure/Layout";
import React from "react";
import { PieChart, } from '@mui/x-charts/PieChart';
import { Box, Button, Card, CardActions, CardContent, Grid, Stack, Typography } from "@mui/material";
import { BarChart } from '@mui/x-charts/BarChart';
import { useMsal } from '@azure/msal-react';
import axios from 'axios'
import { useLocation } from "react-router-dom";
const Home = () => {
  const pieParams = { height: 200, margin: { right: 5 } };
  const palette = ['red', 'blue', 'green'];
  const location = useLocation();
  // const { instance, inProgress, accounts, logger } = useMsal();

  // const initializeSignIn = () => {
  //   instance.loginRedirect();

  // };

  // const test = (async () => {
  //   const request = {
  //     scopes: [],
  //     account: accounts[0],

  //   };
  //   const authResult = await instance.acquireTokenSilent(request)
  //   console.log('authResult', authResult)
  // })

  // if (accounts && accounts.length > 0) {
  //   test()
  // }

 const callTestAPI = () =>{
  let url = 'http://20.13.207.26/a360bs/user?pageNumber=0&size=10';
  axios.get(url).then((res)=>{
    console.log('res' ,res)
  }).catch((error)=>{
    console.log(error)
  })
 }

 console.log('Current Path:', window.location);


  return (
    <Layout title={'Growing'} style={{ marginBottom: "100px", marginTop: "64px" }}>
      <h2 style={{textAlign:'center'}}>Dashboard </h2>
      {/* <Button onClick={callTestAPI}>Call user API after sso login test</Button>
      <Button onClick={callTestAPI}>Call user API after sso login test</Button> */}

      {/* <div onClick={initializeSignIn}>Sign in</div> */}
      {/* <Typography>Dashboard for Brazil Crop Year : CY-2023</Typography>
      <div onClick={initializeSignIn}>Sign in</div>
      <Stack direction="row" width="100%" textAlign="center" spacing={2}>
      </Stack>
      <Grid container sx={{ marginBottom: "100px" }}>
        <Grid item md={3} xs={12} sx={{ padding: "0px 10px", marginTop: "20px" }}>
          <Card>
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Prompt Action Opened Per Status
              </Typography>
              <Box flexGrow={1}>
                <BarChart
                  series={[
                    { data: [3, 4, 1, 6, 5], stack: 'A', label: 'Series A1' },
                    { data: [4, 3, 1, 5, 8], stack: 'A', label: 'Series A2' },
                  ]}
                  height={200}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3} xs={12} sx={{ padding: "0px 10px", marginTop: "20px" }}>
          <Card>
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Prompt Action Opened Per Status
              </Typography>
              <Box flexGrow={1}>

                <PieChart
                  colors={palette}
                  series={[{ data: [{ value: 10 }, { value: 15 }, { value: 20 }] }]}
                  {...pieParams}
                />

              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3} xs={12} sx={{ padding: "0px 10px", marginTop: "20px" }}>
          <Card>
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Farmers with PPE for Harvest
              </Typography>
              <Box flexGrow={1}>

                <PieChart
                  series={[{ data: [{ value: 10 }, { value: 15 }, { value: 20 }] }]}
                  {...pieParams}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3} xs={12} sx={{ padding: "0px 10px", marginTop: "20px" }}>
          <Card>
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Farmers No Children Working on Farms
              </Typography>
              <Box flexGrow={1}>
                <BarChart
                  series={[
                    { data: [3, 4, 1, 6, 5], stack: 'A', label: 'Series A1' },
                    { data: [4, 3, 1, 5, 8], stack: 'A', label: 'Series A2' },
                  ]}
                  height={200}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3} xs={12} sx={{ padding: "0px 10px", marginTop: "20px" }}>
          <Card>
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Farmers Prompt Action Regarding Child
              </Typography>
              <Box flexGrow={1}>

                <PieChart
                  series={[
                    { data: [{ value: 10, color: 'orange' }, { value: 15 }, { value: 20 }] },
                  ]}
                  {...pieParams}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3} xs={12} sx={{ padding: "0px 10px", marginTop: "20px" }}>
          <Card>
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Devices Synchronised in Last 5 Days
              </Typography>
              <Box flexGrow={1}>
                <BarChart
                  series={[
                    { data: [3, 4, 1, 6, 5], stack: 'A', label: 'Series A1' },
                    { data: [4, 3, 1, 5, 8], stack: 'A', label: 'Series A2' },
                  ]}
                  height={200}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3} xs={12} sx={{ padding: "0px 10px", marginTop: "20px" }}>
          <Card>
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Farmers Monitoring Completed
              </Typography>
              <Box flexGrow={1}>

                <PieChart
                  series={[{ data: [{ value: 10 }, { value: 15 }, { value: 20 }] }]}
                  {...pieParams}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3} xs={12} sx={{ padding: "0px 10px", marginTop: "20px" }}>
          <Card>
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Sustainable Wood Source
              </Typography>
              <Box flexGrow={1}>
                <BarChart
                  series={[
                    { data: [3, 4, 1, 6, 5], stack: 'A', label: 'Series A1' },
                    { data: [4, 3, 1, 5, 8], stack: 'A', label: 'Series A2' },
                  ]}
                  height={200}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid> */}

    </Layout>
  );
};



export default Home;
