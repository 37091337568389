export const varietyFields = [
 
  {
    name: "cuCultureName",
    label: "Culture",
    type: "select",
    options: [],
    required: true,
  },
  {
    name: "varietyCultureType",
    label: "Culture Type",
    type: "select",
    options: [],
    required: true,
  },
  {
    name: "vrVariety",
    label: "Variety",
    type: "text",
    required: true,
  },
  {
    name: "spacing",
    label: "Spacing",
    type: "select",
    options: [],
    required: false
  },
  {
    name: "isActive",
    label: "Status",
    type: "switch",
  },
];

