// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detailsText {
    font-size: 0.875rem;
    font-weight: 500;
}

.spanDetailsValue {
    font-weight: 600;
    color: #191E31;
    padding-left: 2px;
}



.GridItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 1.25rem;
    color: rgba(115, 125, 134, 1);
    margin-bottom: 1rem;
}

.InputLabel {
    color:#737D86;
    font-size: 1rem;
    font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/CropStage/CropStage.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,iBAAiB;AACrB;;;;AAIA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,gBAAgB;IAChB,kBAAkB;IAClB,6BAA6B;IAC7B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".detailsText {\n    font-size: 0.875rem;\n    font-weight: 500;\n}\n\n.spanDetailsValue {\n    font-weight: 600;\n    color: #191E31;\n    padding-left: 2px;\n}\n\n\n\n.GridItem {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    font-weight: 600;\n    font-size: 1.25rem;\n    color: rgba(115, 125, 134, 1);\n    margin-bottom: 1rem;\n}\n\n.InputLabel {\n    color:#737D86;\n    font-size: 1rem;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
