// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width:800px) {
    .AgriSupplyTabs {
        overflow: scroll;
    }
}

div:has(.AgriculturalSupplyContainer) {
  padding: 0px !important;
}

.table-row td {
    /* background-color: white;
    margin-bottom: 10px;
    box-shadow: 2px 2px 10px 2px #ACB4BA4D;
    padding: 0px 10px;
    border-radius: 30px;
    margin-top: 80px;

    min-height: 62px; */
  }




.agri-supply-table {
    width: 100%;
    border-spacing: 0 20px;
}

.agri-supply-table td {
  /* width: 20%; */
  padding: 10px 8px;
  background-color: white;
  /* border-radius: 10px; */
}
.agri-supply-table tr {
 border-radius: 10px;
}

.table-container {
  display: flexbox;
  flex-direction: column;
}
.btns-filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Growing/AgricultureSuppliesManagement/AgriSupply.css"],"names":[],"mappings":"AAAA;IACI;QACI,gBAAgB;IACpB;AACJ;;AAEA;EACE,uBAAuB;AACzB;;AAEA;IACI;;;;;;;uBAOmB;EACrB;;;;;AAKF;IACI,WAAW;IACX,sBAAsB;AAC1B;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,uBAAuB;EACvB,yBAAyB;AAC3B;AACA;CACC,mBAAmB;AACpB;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;AACxB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;AACrB","sourcesContent":["@media screen and (max-width:800px) {\n    .AgriSupplyTabs {\n        overflow: scroll;\n    }\n}\n\ndiv:has(.AgriculturalSupplyContainer) {\n  padding: 0px !important;\n}\n\n.table-row td {\n    /* background-color: white;\n    margin-bottom: 10px;\n    box-shadow: 2px 2px 10px 2px #ACB4BA4D;\n    padding: 0px 10px;\n    border-radius: 30px;\n    margin-top: 80px;\n\n    min-height: 62px; */\n  }\n\n\n\n\n.agri-supply-table {\n    width: 100%;\n    border-spacing: 0 20px;\n}\n\n.agri-supply-table td {\n  /* width: 20%; */\n  padding: 10px 8px;\n  background-color: white;\n  /* border-radius: 10px; */\n}\n.agri-supply-table tr {\n border-radius: 10px;\n}\n\n.table-container {\n  display: flexbox;\n  flex-direction: column;\n}\n.btns-filters {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
