export const investmentGroupField = [
  {
    name: "igInvestmentGroupDescription",
    label: "Investment Group Description",
    type: "text",
    width: "60%",
    required: true,
  },
  {
    name: "newCuringUnit",
    label: "Tobacco Type",
    type: "select",
    options: [],
    required: true
  },
  {
    name: "status",
    label: "Status",
    type: "switch",
  },
];


export const editInvestmentGroupField = [

  {
    name: "igInvestmentGroupDescription",
    label: "Investment Group Description",
    type: "text",
    width: "60%",
    required: true,
  },
  {
    name: "newCuringUnit",
    label: "Tobacco Type",
    type: "select",
    options: [],
    required: true,
  },
  {
    name: "status",
    label: "Status",
    type: "switch",
  },
];