import React, { useEffect, useState } from "react";
import { Typography, Box, Button, Menu, MenuItem } from "@mui/material";
import "./Supply.css";
import EditButton from "../../../../assets/editButton.svg";
import { useNavigate } from "react-router-dom";
import moreActions from "../../../../assets/moreActions.svg";
import iconView from "assets/iconView.svg";

const SupplyTable = (props) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [supplyData, setSupplyData] = useState([]);
  console.log("🚀 ~ SupplyTable ~ supplyData:", supplyData);

  useEffect(() => {
    setSupplyData(props.supplyData);
  }, [props.supplyData]);

  const [selectedRow, setSelectedRow] = React.useState({});

  const handleMenuClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(data);
  };

  const handleMenuClose = ({ action, data }) => {
    if (!action || action == undefined) return;
    console.log("action", action);
    setSelectedRow(data);
    if (action == "details") {
      navigate("/viewSupplyType", {
        state: {
          description: data.supplyTypeDescription,
          status: data.isActive,
          edit: false,
          details: true,
          id: data.supplyTypeId,
          forPest: data.forPestDisease,
        },
      });
    } else {
      navigate("/editSupplyType", {
        state: {
          description: data.supplyTypeDescription,
          status: data.isActive,
          edit: true,
          id: data.supplyTypeId,
          forPest: data.forPestDisease,
        },
      });
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    setSupplyData(props.supplyData);
  }, [props.supplyData]);

  return (
    <div className="table-container">
      <table className="full-width-table">
        {" "}
        <thead>
          <tr className="HierarchyHead">
            <th>Code</th>
            <th>Description</th>
            <th>Use NPK</th>
            <th>For Pest/Disease</th>
            <th>Status</th>
            <th></th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {supplyData?.map((row, index) => (
            <tr className="hierarchyColumn" key={index} style={{}}>
              <td style={{ textAlign: "center" }}>
                <Typography
                  sx={{
                    justifyContent: "left",
                    fontWeight: 600,
                    color: "#3B454F",
                  }}
                >
                  {row.supplyTypeCode}
                </Typography>
              </td>
              <td style={{ textAlign: "center" }}>
                <Box
                  sx={{
                    whiteSpace: "nowrap",
                    paddingLeft: "5px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontWeight: 600,
                    color: "#3B454F",
                  }}
                >
                  <span title={row.supplyTypeDescription}>
                    {row.supplyTypeDescription}
                  </span>
                </Box>
              </td>

              <td style={{ textAlign: "center" }}>
                <Box
                  sx={{
                    whiteSpace: "nowrap",
                    paddingLeft: "5px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontWeight: 600,
                    color: "#3B454F",
                  }}
                >
                  <span title={row.useNPK}>{row.useNPK ? "Yes" : "No"}</span>
                </Box>
              </td>

              <td style={{ textAlign: "center" }}>
                <Box
                  sx={{
                    whiteSpace: "nowrap",
                    paddingLeft: "5px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontWeight: 600,
                    color: "#3B454F",
                  }}
                >
                  <span title={row.forPestDisease}>
                    {row.forPestDisease ? "Yes" : "No"}
                  </span>
                </Box>
              </td>

              <td
                style={{
                  textAlign: "center",
                  fontWeight: 600,
                  color: "#3B454F",
                }}
              >
                {row.isActive ? "Active" : "Inactive"}
              </td>
              <td
                style={{
                  textAlign: "center",
                }}
              ></td>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingRight: "20px",
                  cursor: "pointer",
                }}
              >
                <img
                  style={{ maxHeight: "40px" }}
                  src={EditButton}
                  alt="Edit Button"
                  onClick={() =>
                    handleMenuClose({ action: "edit", data: row, tab: 1 })
                  }
                />
                <Button
                  variant="outlined"
                  className="moreButton"
                  style={{ marginLeft: "10px", maxHeight: "40px" }}
                  onClick={(e) => handleMenuClick(e, row)}
                >
                  <div>
                    <img src={moreActions} alt="more-actions" />
                  </div>{" "}
                  <div style={{ height: "14px" }}>More</div>
                </Button>

                <div key={index}>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() =>
                      handleMenuClose({ action: "Add", data: row, tab: 0 })
                    }
                    key={index}
                  >
                    <div className="">
                      <MenuItem
                        className="gridActionCell"
                        sx={{
                          border: "1px solid #50AF47 !important",
                          display: "flex",
                          justifyContent: "space-around",
                          cursor: "ponter",
                        }}
                        onClick={() =>
                          handleMenuClose({
                            action: "details",
                            data: selectedRow,
                            tab: 1,
                          })
                        }
                      >
                        <img src={iconView} height="30px" width="30px" />
                        <div>View</div>
                      </MenuItem>
                    </div>
                  </Menu>
                </div>
              </div>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SupplyTable;
