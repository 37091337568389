import React, { useContext, useState } from 'react'
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { SnackbarContext } from '../context/snackBarContext';

const CustomSnackbar = () => {
    // Contexts
    const { openAlert, severity, message, handleClose } = useContext(SnackbarContext)
    const [vertical, setVertical] = useState("top")
    const [horizontal, setHorizontal] = useState("Center")
    return (
        <div
            style={{ width: "100%" }}
        >
            <Snackbar anchorOrigin={{ vertical, horizontal }} open={openAlert} autoHideDuration={4000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default CustomSnackbar

