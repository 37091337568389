import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Layout from "components/structure/Layout";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React, { useState, useContext } from "react";
import { SnackbarContext } from "context/snackBarContext";
import ClearIcon from "@mui/icons-material/Clear";
import NewTable from "./NewTable";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import GreenManureService from "services/GreenManureService";
import { useEffect } from "react";
import Lable from "utils/lables.json";
import label from "../growingLabel.json";


const GreenManure = () => {
  const navigate = useNavigate();
  const [greenData, setGreenData] = useState([]);
  console.log("🚀 ~ GreenManure ~ greenData:", greenData);
  const [tobaccoType, setTobaccoType] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  // const [itemsPerPage, setItemsPerPage] = useState(7);
  const [itemsPerPage, setItemsPerPage] = useState(() => {
    return parseInt(localStorage.getItem("IPPGM")) || 5;
  });
  const [totalItems, setTotalItems] = useState(0); // Number of items per page
  const [manureOptions, setManureOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  console.log("🚀 ~ GreenManure ~ manureOptions:", manureOptions);

  const paginate = (pageNumber, itemsPerPage) => {
    localStorage.setItem("IPPGM", itemsPerPage);
    setCurrentPage(pageNumber - 1);
    if (searchKeyword || statusFilter) {
      const isActive =
        statusFilter === "Active"
          ? true
          : statusFilter === "Inactive"
            ? false
            : null;
      callSearchAPI(pageNumber - 1, itemsPerPage, searchKeyword, isActive);
    } else {
      callManureApi(pageNumber - 1, itemsPerPage);
    }
  };

  const fetchManureDataFull = async () => {
    try {
      const response = await GreenManureService.searchManureDataFull();
      const data = response?.data?.response?.greenManures;
      setManureOptions(data);
    } catch (error) {
      console.error("Error fetching manure data:", error);
    }
  };

  // useEffect(() => {
  //   if (keyword) {
  //     setLoading(true);
  //     const fetchManureData = async () => {
  //       try {
  //         const response = await GreenManureService.searchManureData(keyword);
  //         const data = response?.data?.response?.greenManures;
  //         setManureOptions(data);
  //       } catch (error) {
  //         console.error("Error fetching manure data:", error);
  //         setManureOptions([]); // Clear options on error
  //       } finally {
  //         setLoading(false);
  //       }
  //     };
  //     fetchManureData();
  //   } else {
  //     fetchManureDataFull();
  //     // setManureOptions([]); // Clear options if no keyword
  //   }
  // }, [keyword]);

  const refreshPage = () => {
    const totalItemsOnPage = greenData?.length ?? 0;

    if (totalItemsOnPage === 1 && currentPage > 0) {
      // If we're on the last item of the page, move back a page if possible
      callManureApi(currentPage - 1, itemsPerPage);
    } else if (totalItemsOnPage === 1 && currentPage == 0) {
      setGreenData([]);
      // setNoRecordFound(true);
    } else {
      // Otherwise, refresh the current page
      callManureApi(currentPage, itemsPerPage);
    }
  };
  const callManureApi = (pNumber, size) => {
    // setLoading(true);
    // setRecordsError(false);
    GreenManureService.getGreenManure({ pageNumber: pNumber, size: size })
      .then((res) => {
        // setLoading(false);
        // setRecordsError(false);
        setGreenData(res?.data?.response?.greenManures);
        setItemsPerPage(size);
        setTotalItems(res?.data?.response?.totalItems);
        setCurrentPage(pNumber);
      })
      .catch((error) => {
        // setMessage(error?.response?.data?.message || error?.message);
        // setOpenAlert(true);
        // setSeverity("error");
      });
  };

  // const filterManure = async (status) => {
  //   try {
  //     const response = await GreenManureService.filterManure(keyword, status);
  //     const data = response?.data?.response?.greenManures;
  //     setGreenData(data);
  //     setTotalItems(response?.data?.response?.totalItems);

  //     console.log(response.data); // Handle the API response
  //   } catch (error) {
  //     setGreenData([]);

  //     console.error("Error fetching data:", error);
  //   }
  // };

  // const handleTobaccoTypeChange = (e) => {
  //   const value = e.target.value;
  //   setTobaccoType(value);

  //   if (value === "Active") {
  //     filterManure(true);
  //   } else if (value === "Inactive") {
  //     filterManure(false);
  //   }
  // };

  // const clearFilter = async () => {
  //   setTobaccoType("");
  //   if (keyword) {
  //     const fetchManureData = async () => {
  //       try {
  //         const response = await GreenManureService.searchManureData(keyword);
  //         const data = response?.data?.response?.greenManures;
  //         setManureOptions(data);
  //         setGreenData(data);
  //         setTotalItems(response?.data?.response?.totalItems);
  //       } catch (error) {
  //         console.error("Error fetching manure data:", error);
  //         setManureOptions([]); // Clear options on error
  //       } finally {
  //         setLoading(false);
  //       }
  //     };
  //     fetchManureData();
  //   } else {
  //     callManureApi(currentPage, itemsPerPage);
  //   }
  // };

  // const filterManureAutocomplete = async (keyword) => {
  //   try {
  //     const response = await GreenManureService.filterManureAutocomplete(
  //       keyword
  //     );
  //     const data = response?.data?.response?.greenManures;
  //     setTobaccoType("");
  //     setGreenData(data);
  //   } catch (error) {
  //     console.error("Error fetching manure data:", error);
  //     return [];
  //   }
  // };
  const handleSearchChange = (value) => {
    setSearchKeyword(value);
    if (value) {
      callSearchAPI(0, itemsPerPage, value);
    } else if (statusFilter) {
      const isActive =
        statusFilter === "Active"
          ? true
          : statusFilter === "Inactive"
            ? false
            : null;
      callSearchAPI(0, itemsPerPage, value, isActive);
    } else {
      setSearchKeyword("");
      resetFilters();
    }
  };
  const callSearchAPI = (pageNumber, size, keyword, status) => {
    setLoading(true);
    let query = { pageNumber, size, keyword };
    if (status === true || status === false) {
      query.status = status;
    }
    GreenManureService.filterManure(query)
      .then((res) => {
        const results = res?.data?.response?.greenManures || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setGreenData(results);
        setManureOptions(results);
        setTotalItems(res?.data?.response?.totalItems || 0);
        if (results.length === 0 && searchKeyword.length > 0) {
          setMessage(label.noDataMessage);
          setSeverity("info");
        }
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setSeverity("error");
        setGreenData([]);
        setManureOptions([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleStatusChange = (status) => {
    setStatusFilter(status);
    setCurrentPage(0);
    if (status === "") {
      setStatusFilter("");
      if (searchKeyword === "") {
        resetFilters();
      } else {
        callSearchAPI(currentPage, itemsPerPage, searchKeyword);
      }
    } else {
      const isActive =
        status === "Active" ? true : status === "Inactive" ? false : null;
      callSearchAPI(0, itemsPerPage, searchKeyword, isActive);
    }
  };
  const resetFilters = () => {
    fetchManureDataFull();
    setCurrentPage(0);
    callManureApi(0, itemsPerPage);
  };
  useEffect(() => {
    if (searchKeyword == "" && statusFilter == "") {
      callManureApi(currentPage, itemsPerPage);
    } else {
      const isActive =
        statusFilter === "Active"
          ? true
          : statusFilter === "Inactive"
            ? false
            : null;
      callSearchAPI(currentPage, itemsPerPage, searchKeyword, isActive);
    }
    if (currentPage <= 0) {
      fetchManureDataFull();
    }
  }, [currentPage, itemsPerPage]);
  // useEffect(() => {
  //   callManureApi(currentPage, itemsPerPage);
  // }, []);

  return (
    <Layout
      background="#ffffff"
      title="Green Manure List"
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      useNewPagination={true}
    >
      <Box
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          padding: "1rem",
          paddingBottom: "2rem",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <ArrowDropDownIcon sx={{ marginRight: 1 }} />
          <Typography>{Lable.filtersLable}</Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
          <Box sx={{ display: "flex" }}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={manureOptions}
              getOptionLabel={(option) => option.greenManureName}
              onChange={(event, newValue) => {
                handleSearchChange(newValue?.greenManureName);
              }}
              // {async (event, newValue) => {
              //   if (newValue) {
              //     const result =
              //       await GreenManureService.filterManureAutocomplete(
              //         newValue.greenManureName
              //       );
              //     const data = result?.data?.response?.greenManures;
              //     console.log("🚀 ~ onChange={ ~ data:", data);
              //     setTobaccoType("");
              //     setGreenData(data);
              //     setTotalItems(result?.data?.response?.totalItems);
              //     if (result) {
              //       console.log("API response:", result);
              //     }
              //   } else {
              //     // When the clear button (DeleteIcon) is clicked
              //     console.log("delete"); // Log "delete"
              //     setTobaccoType("");
              //     callManureApi(currentPage, itemsPerPage);
              //   }
              // }}
              sx={{ width: 300 }}
              onInputChange={(event, newInputValue) => {
                // handleSearchChange(newInputValue);
                setSearchKeyword(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Green Manure"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
            <FormControl
              sx={{ width: { xs: "100%", md: 222 }, minHeight: 50, ml: 3 }}
            >
              <TextField
                select
                id="tobaccoType"
                labelId="tobacco-select-label"
                label={"Status"}
                value={statusFilter || "PL"}
                onChange={(event) => handleStatusChange(event.target.value)}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: statusFilter && (
                    <InputAdornment position="end">
                      <ClearIcon
                        onClick={(event) => handleStatusChange("")}
                        sx={{ mr: 2, cursor: "pointer" }}
                      />
                    </InputAdornment>
                  ),
                }}
              >
                {/* <MenuItem value="">{Lable.statusPlaceholder}</MenuItem> */}
                <MenuItem value="PL" disabled>
                  Select an option
                </MenuItem>
                <MenuItem value="Active">{Lable.activeLable}</MenuItem>
                <MenuItem value="Inactive">{Lable.inactiveLable}</MenuItem>
              </TextField>
            </FormControl>
          </Box>
          <Box>
            <Button
              sx={{
                borderRadius: 1,
                minWidth: 95,
                minHeight: 44,
                background: "#102A62",
                marginTop: { xs: 1 },
                marginLeft: { xs: 1 },
              }}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => navigate("/addGreenManure")}
            >
              {Lable.addNewButton}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box>
        {greenData.length > 0 ? (
          <NewTable greenManure={greenData} callManureApi={refreshPage} />
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              height: "40vh",
              alignItems: "center",
            }}
          >
            <Box>{label.noDataMessage}</Box>
          </Box>
        )}
      </Box>
    </Layout>
  );
};

export default GreenManure;
