import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import Layout from "components/structure/Layout";
import arrowLeft from "assets/arrowLeft.svg";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import {
  freigthSchema,
} from "utils/validations";
import RenderField from "utils/RenderField";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { SnackbarContext } from "context/snackBarContext";
import { AuthData } from "auth/AuthWarpper";
import {
  editFreightField,
  freightField as initialFreightField,
} from "./FreightFormConfig"; // Import initial spacingField
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Save from "assets/SaveNew.svg";
import Lable from "utils/lables.json";
import FreightService from "services/FreightService";
import {
  ADD_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
} from 'utils/AgriErrorMsg';
import { useTranslation } from 'react-i18next';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 580,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  // p: 4,
};

const AddFreight = () => {

   const initialFreightField = [
    {
      name: "freight",
      label: "Additional Freight Group Description",
      type: "text",
      required: true,
      width: "80%"
    },
    {
      name: "status",
      label: "Status",
      type: "switch",
      required: true,
    },
  ];
  
  
   const editFreightField = [
  
    {
      name: "freight",
      label: "Additional Freight Group Description",
      type: "text",
      required: true,
      width: "80%"
    },
    {
      name: "status",
      label: "Status",
      type: "switch",
      required: true,
    },
  ];
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = AuthData();

  const {
    freightIdEdit,
    statusEdit,
    createdByUserEdit,
    modifiedByUserEdit,
    freightNameEdit,
    edit,
    details,
  } = location.state || {};
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [freightField, setFreightField] = useState(
    edit === true ? editFreightField : initialFreightField
  ); // State for spacingField
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // const fetchTobaccoGroup = async () => {
  //   try {
  //     const response = await SpacingServices.getTobbacoType();
  //     const tobaccoGroup = response.data.response;

  //     // Construct options array including initial empty option
  //     const options = [
  //       { value: "", label: "None" },
  //       ...tobaccoGroup.map((group) => ({
  //         value: group.tobaccoGroup,
  //         label: group.tobaccoGroup,
  //       })),
  //     ];

  //     // Update spacingField with dynamic options
  //     const updatedSpacingField = spacingField.map((field) => {
  //       if (field.name === "tobaccoType") {
  //         return {
  //           ...field,
  //           options: options,
  //         };
  //       }
  //       return field;
  //     });

  //     setSpacingField(updatedSpacingField); // Update spacingField with dynamic options

  //     // Check if the value from navigation state matches any option
  //     if (location.state?.tobaccoType) {
  //       const matchedOption = options.find(
  //         (option) => option.value === location.state.tobaccoType
  //       );
  //       if (matchedOption) {
  //         setValues((prevValues) => ({
  //           ...prevValues,
  //           tobaccoType: matchedOption.value,
  //         }));
  //       } else {
  //         setValues((prevValues) => ({
  //           ...prevValues,
  //           tobaccoType: options[0].value,
  //         }));
  //       }
  //     } else {
  //       // Set initial value for tobaccoType if not already set
  //       setValues((prevValues) => ({
  //         ...prevValues,
  //         tobaccoType:
  //           prevValues.tobaccoType ||
  //           (options.length > 0 ? options[0].value : ""),
  //       }));
  //     }
  //   } catch (error) {
  //     console.error("Failed to fetch tobacco group:", error);
  //   }
  // };

  useEffect(() => {
    if (location.state) {
      const {
        freightIdEdit,
        statusEdit,
        createdByUserEdit,
        modifiedByUserEdit,
        freightNameEdit,
        edit,
      } = location.state;
      console.log("🚀 ~ useEffect ~ unitOfMeasure:");

      setValues((prevValues) => ({
        ...prevValues,
        freight: freightNameEdit || "",
        status: statusEdit || "",
      }));

      // Fetch tobacco group and match the tobaccoType
    }
  }, [location.state]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

   const freigthSchema = Yup.object().shape({
    freight: Yup
      .string()
      .trim(
        "Additional Freight Group Description must not contain leading or trailing spaces"
      )
      .strict()
      .max(
        40,
        "Additional Freight Group Description must be less than 40 characters"
      )
      .required("Additional Freight Group Description is required"),
    // .matches(
    //   /^[a-zA-Z0-9 ]*$/,
    //   "Additional Freight Group Description must not contain special characters"
    // )
  });

  const handleSubmit = async (event) => {
    console.log("clicked");
    event.preventDefault();
    setLoading(true);

    try {
      await freigthSchema.validate(values, { abortEarly: false });
      setErrors({});
      const payload = {
        additionalFreightGroupDescription: values.freight,
        isActive: values.status || false,
        createdByUser: user?.batOneId || 323222,
        modifiedByUser: user?.batOneId || 323222,
        createdDateTimeUTC: new Date().toISOString(),
        modifiedDateTimeUTC: new Date().toISOString(),
        isDelete: false,
        countryCode: "BRA",
      };
      setLoading(true);
      const response = await FreightService.createFreight(payload);
      console.log("🚀 ~ handleSubmit ~ response:", response);
      setLoading(false);
      if (response.status === 200) {
        navigate("/additionalFreightGroupList");
        setSeverity("success");
        setMessage(ADD_SUCCESS_MSG);
        setOpenAlert(true);
        // setLoading(false);
      } else if (response.status === 409) {
        setSeverity("error");
        setMessage(response?.data?.message);
        setOpenAlert(true);
        setLoading(false);
      } else {
        console.error("Failed to submit form");
        setSeverity("error");
        setMessage(t("snackbarMessage.submissionFailed"));
        setOpenAlert(true);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        let newError = {};
        err?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setErrors(newError);
      } else {
        console.error("An error occurred while submitting the form:", err);
        setSeverity("error");
        setMessage(
          err?.response?.data?.message ||
          "An error occurred while submitting the form:"
        );
        setOpenAlert(true);
      }
    }
  };

  const handleUpdate = async (event) => {
    setLoading(true);

    event.preventDefault();
    try {
      await freigthSchema.validate(values, { abortEarly: false });
      setErrors({});
      const payload = {
        additionalFreightGroupDescription: values.freight,
        isActive: values.status || false,
        createdByUser: createdByUserEdit || 323222,
        modifiedByUser: user?.batOneId || 323222,
        createdDateTimeUTC: new Date().toISOString(),
        modifiedDateTimeUTC: new Date().toISOString(),
        additionalFreightGroupId: freightIdEdit,
        isDelete: false,
        countryCode: "BRA",
      };
      setLoading(true);
      const response = await FreightService.updateFreight(payload);
      setLoading(false);
      if (response.status === 200) {
        navigate("/additionalFreightGroupList");
        setSeverity("success");
        setMessage(UPDATE_SUCCESS_MSG);
        setOpenAlert(true);
        setLoading(false);
      } else if (response.status === 409) {
        console.error("Failed to submit form");
        setSeverity("error");
        setMessage(t("snackbarMessage.submissionFailed"));
        setOpenAlert(true);
        setLoading(false);
      } else {
        console.error("Failed to submit form");
        setSeverity("error");
        setMessage(t("snackbarMessage.submissionFailed"));
        setOpenAlert(true);
      }
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        let newError = {};
        err?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setErrors(newError);
      } else {
        console.error("An error occurred while submitting the form:", err);
        setSeverity("error");
        setMessage("An error occurred while submitting the form");
        setOpenAlert(true);
      }
    }
  };

  const handleClear = (field) => {
    setValues((prevValues) => ({
      ...prevValues,
      [field]: "",
    }));
  };

  return (
    <Box
      sx={{
        position: "relative",
        background: "pink",
        height: "100%",
      }}
    >
      <Layout
        background="#ffffff"
        title="Additional Freight Group Registration"
        cancel={true}
      >
        <Box
          sx={{
            minHeight: 115,
            flexGrow: 1,
            width: "100%",
            "& .MuiTextField-root": {
              m: 1,
              color: "green !important",
              width: "25ch",
            },
            "& .MuiFormLabel-root": {
              color: "rgba(16, 42, 98, 1)",
              "& .MuiFormLabel-asterisk": {
                color: "rgba(239, 0, 0, 1)",
                fontSize: "18px",
              },
              "& .Mui-focused": {
                color: "red !important",
              },
            },
          }}
        >
          <Box
            sx={{
              background: "#EFEFEF",
              borderRadius: "5px",
              padding: "0.5rem",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <ArrowDropDownIcon sx={{ marginRight: 1 }} />
              <Typography>{t("form")}</Typography>
            </Box>

            <form
              autoComplete="off"
              noValidate
              onSubmit={edit ? handleUpdate : handleSubmit}
            >
              {freightField.slice(0, 2).map((field) => (
                <RenderField
                  key={field.name}
                  field={field}
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  handleClear={handleClear}
                  width={"80%"}
                  shrink={true}
                  details={details}
                />
              ))}
            </form>
          </Box>
        </Box>
        <Box sx={{ position: "absolute", bottom: "70px", width: "97%" }}>
          <Box
            sx={{
              display: "flex",
              alingItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button
              style={{
                backgroundColor: "#737D86",
                padding: " 6px, 16px, 6px, 16px",
                borderRadius: " 4px",
              }}
              startIcon={<img src={arrowLeft} alt="Arrow" />}
              variant="contained"
              type="secondary"
              onClick={() => details == true ? navigate("/additionalFreightGroupList") : handleOpen()}
              disabled={loading}
            >
              {loading && <CircularProgress size={20} />}
              {t("cancel")}
            </Button>
            {!details && (
              <Button
                sx={{
                  backgroundColor: "#EF7D00",
                  padding: " 6px, 16px, 6px, 11px",
                  borderRadius: " 4px",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#EF7D00",
                  },
                }}
                disabled={loading}
                startIcon={<img src={Save} alt="Right Arrow" />}
                onClick={edit ? handleUpdate : handleSubmit}
                variant="contained"
              >
                {loading && <CircularProgress size={20} />}
                {/* {edit ? "Update" : "Save"} */}
                {t("save")}
              </Button>
            )}
          </Box>
        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              sx={{
                background: "#102A62",
                height: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
              >
                {t("cancelModal.heading")}
              </Typography>
            </Box>
            <Box sx={{ p: 2 }}>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, fontWeight: 400 }}
              >
                               {t("cancelModal.sure")}

              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
                mb: 1,
              }}
            >
              <Button
                onClick={handleClose}
                sx={{
                  background: "#737D86",
                  width: "100px",
                  color: "#ffffff",
                  "&:hover": {
                    background: "#737D86",
                  },
                }}
              >
                {t("no")}
              </Button>
              <Button
                sx={{
                  background: "#004F9F",
                  width: "100px",
                  color: "#ffffff",
                  "&:hover": {
                    background: "#004F9F",
                  },
                }}
                onClick={() => navigate("/additionalFreightGroupList")}
              >
                {" "}
                {t("yes")}
              </Button>
            </Box>
          </Box>
        </Modal>
      </Layout>
    </Box>
  );
};

export default AddFreight;
