import { styled } from "@mui/material/styles";
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  IconButton,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
  Modal,
  Divider,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "context/snackBarContext";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import SearchTextInput from "components/common/SearchTextInput";
import Save from "assets/SaveNew.svg";
import InvestmentGroupLimitsService from "services/InvestmentGroupLimitsService";
import { debounce } from "lodash";


const StyledTableContainer = styled(TableContainer)({
  boxShadow: "none",
  // border: "1px solid #E5E7EB",
  borderRadius: "8px",
  maxWidth: "1880px",
  margin: "0 auto",
  overflowX: "auto", // Enable horizontal scrolling
});

const StyledTable = styled(Table)({
  borderCollapse: "separate",
  borderSpacing: 0,
  minWidth: "100%", // Ensure table spans horizontally
  borderCollapse: 'separate',
  borderSpacing: '0px 4px'
});

const HeaderCell = styled(TableCell)({
  backgroundColor: "#E9EBEE",
  color: "#3B454F",
  fontFamily: "Montserrat",
  fontWeight: 600,
  fontSize: "20px",
  textTransform: "uppercase",
  textAlign: "center",
  padding: "13px 20px",
  borderBottom: "none",
  borderTopLeftRadius: "8px", // Rounded top-left edge
  borderTopRightRadius: "8px", // Rounded top-right edge
  overflow: "hidden", // Ensures the rounded edges are respected
});

const SecondaryHeaderCell = styled(TableCell)({
  backgroundColor: "#E9EBEE",
  color: "#3B454F",
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "20px",
  textAlign: "center",
  padding: "13px 20px",
  borderBottom: "none",
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  overflow: "hidden",

});

const SubHeaderCell = styled(TableCell)({
  backgroundColor: "#FFFFFF",
  color: "#3B454F",
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "16px",
  padding: "13px 20px",
  border: "1px solid #E9EBEE",
  borderRadius: "8px", // Rounded top-left edge
  overflow: "hidden",
  "&:last-child": {
    borderRight: "none",
  },
});

const DataCell = styled(TableCell)({
  fontFamily: "Montserrat",
  fontWeight: 400,
  fontSize: "15px",
  color: "#3B454F",
  padding: "13px 20px",
  backgroundColor: "#EFEFEF",
  textAlign: "center",
  // borderBottom: "1px solid #E9EBEE",
  // borderRight: "1px solid #E9EBEE",
  "&:last-child": {
    borderRight: "none",
  },
});

const TotalCell = styled(TableCell)({
  backgroundColor: "#E9EBEE",
  color: "#000000",
  fontFamily: "Montserrat",
  fontWeight: 700,
  fontSize: "16px",
  padding: "13px 20px",
  borderBottom: "none",
  textAlign: "center",
});

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    padding: "13px 5px",
    fontFamily: "Montserrat",
    fontSize: "15px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
});

const InvestmentGroupTable = ({
  data,
  cropYearUUID,
  ihId,
  onSave,
  childNodes,
  isTopHierarchyNode,
  refreshData
}) => {
  const [tableData, setTableData] = useState([]);
  // console.log("TABLE Data", tableData)
  console.log("INITIAL Data", data, isTopHierarchyNode)

  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const { t, i18n } = useTranslation();
  const columns = ["Distributed", "Consumed", "Balance"];
  const headers = ["Investment VA", "Investment AM", "Investment BY"];
  const [showColumns, setShowColumns] = useState({
    distributed: true,
    consumed: true,
    balance: true,
  })
  console.log("ColumnsState", showColumns)
  const [minColumnModel, setMinColumnModel] = useState(false)

  const handleColumnChange = (key, isChecked) => {
    const uncheckedCount = Object.values(showColumns).filter((value) => !value).length;

    if (!isChecked && uncheckedCount >= 2) {
      console.log("ALL 3 FALSE")
      setMinColumnModel(true);
      return;
    }
    setShowColumns((prev) => ({
      ...prev,
      [key]: isChecked,
    }));
    setMinColumnModel(false);
  };

  const [manageItemsModal, setManageItemsModal] = useState(false)
  const [removeInvestmentModal, setRemoveInvestmentModal] = useState(false)
  const [removedInvestTableId, setRemovedInvestmentTableId] = useState()
  const [investmentList, setInvestmentList] = useState([])
  const [investmentListSearch, setInvestmentListSearch] = useState("")
  const [investmentHeader, setInvestmentHeader] = useState([])
  const [investmentTotal, setInvestmentTotal] = useState([])
  console.log("InvestmentHeader", investmentHeader)
  const handleInvestmentListChange = (row, isChecked) => {
    if (!isChecked) { // for false i.e unchecked
      setRemovedInvestmentTableId(row.igInvestmentGroupId)
      setRemoveInvestmentModal(true)
    }
    else { // for true i.e checked
      setInvestmentList((prevList) =>
        prevList.map((item) =>
          item.igInvestmentGroupId === row.igInvestmentGroupId
            ? { ...item, inHeaderList: true } // Add inHeaderList: true for the matching item
            : item // Leave the rest unchanged
        )
      );
      setInvestmentHeader((prevHeader) => [...prevHeader, { ...row, isHeader: true }])
    }
  }

  const handleInvestmentHeaderChange = (selectedHeader) => {
    setInvestmentHeader((prevHeader) =>
      prevHeader.map((header) =>
        // Check if the header's description matches the selectedHeader's description
        header.igInvestmentGroupDescription === selectedHeader.igInvestmentGroupDescription
          ? { ...header, isHeader: !selectedHeader.isHeader } // Toggle the isHeader value
          : header // Leave the other headers unchanged
      )
    );
  };

  const handleRemoveInvestmentfromModal = async () => {
    console.log("INVDELETE ID", removedInvestTableId)
    let invDeletePermanent = false
    data.forEach((item) => {
      if (item.investmentId === removedInvestTableId) {
        invDeletePermanent = true
      }
    })
    if (invDeletePermanent) {
      try {
        const response = await InvestmentGroupLimitsService.deleteInvestmentDistributionAPI({
          investmentGroupID: removedInvestTableId,
          cropYearUUID: cropYearUUID, // Ensure this variable is defined and accessible
        });

        if (response?.status === 200) {
          setInvestmentList((prevList) =>
            prevList.map((item) =>
              item.igInvestmentGroupId === removedInvestTableId
                ? { ...item, inHeaderList: false } // Add inHeaderList: true for the matching item
                : item // Leave the rest unchanged
            )
          );
          setInvestmentHeader((prevHeader) =>
            prevHeader.filter((item) => item.igInvestmentGroupId !== removedInvestTableId)
          );
          refreshData()
        }

      } catch (error) {
        console.error("Error deleting data:", error);
        setMessage(error.message || "Error deleteing Investment");
        setSeverity("error");
        setOpenAlert(true);
      }
    } else {
      setInvestmentList((prevList) =>
        prevList.map((item) =>
          item.igInvestmentGroupId === removedInvestTableId
            ? { ...item, inHeaderList: false } // Add inHeaderList: true for the matching item
            : item // Leave the rest unchanged
        )
      );
      setInvestmentHeader((prevHeader) =>
        prevHeader.filter((item) => item.igInvestmentGroupId !== removedInvestTableId)
      );
    }
  }

  const handleTargetChange = (inputValue, ihId, description, rowIndex) => {
    console.log("INPUT VALUE", typeof inputValue)
    setTableData((prev) => {
      const updatedData = [...prev];
      const targetRow = updatedData.find(
        (row) => row.ihId === ihId && row.investmentDescription === description
      );
      if (targetRow) {
        targetRow.target = inputValue; // Update target value as a string
      }
      return updatedData;
    });

  };

  const fetchInvestmentGroupList = async (keyword) => {
    try {
      const InvGroupResponse = await InvestmentGroupLimitsService.getInvestmentGroupListAPI({
        investmentGroupDescription: keyword
      });

      const updatedInvestmentGroups = InvGroupResponse.data.response.investmentGroups.map((group) => ({
        ...group,
        inHeaderList: group.isActive,
      }));
      console.log("searchValueRRR", updatedInvestmentGroups)
      setInvestmentList(updatedInvestmentGroups)
    } catch (error) {
      console.error("Error fetching investment group data:", error);
    }
  }

  // const debouncedFetchInvestmentGroupList = debounce((value) => {
  //   console.log("searchValue2", value)
  //   fetchInvestmentGroupList();
  // }, 15);

  const debouncedFetchInvestmentGroupList = useCallback(
    debounce((value) => {
      console.log("searchValue2", value)
      fetchInvestmentGroupList(value);
    }, 300),
    [investmentList]
  );

  const handleInvestmentListSearch = (event) => {
    const { value } = event.target;
    console.log("searchValue1", value)
    setInvestmentListSearch(value);
    debouncedFetchInvestmentGroupList(value);
  };

  const fetchInvestmentHeader = async () => {
    try {
      const InvGroupResponse = await InvestmentGroupLimitsService.getInvestmentGroupListAPI({
        investmentGroupDescription: investmentListSearch
      });

      const IsActiveInvGroups = InvGroupResponse.data.response.investmentGroups.filter(investment =>
        investment.isActive === true
      );

      const updatedIsActiveInvGroups = IsActiveInvGroups.map((group) => ({
        ...group,
        isHeader: group.isActive,
      }));

      // const updatedIsActiveInvGroupsTotal = InvGroupResponse.data.response.investmentGroups.map((group) => ({
      //   ...group,
      //   isHeader: group.isActive,
      //   total: 0
      // }));
      setInvestmentHeader(updatedIsActiveInvGroups)
      // setInvestmentTotal(updatedIsActiveInvGroupsTotal)
    } catch (error) {
      console.error("Error fetching investment header data:", error);
    }
  }
  console.log("HEADER", investmentHeader)
  useEffect(() => {
    console.log("INSIDE HEADER EFFECT", data)
    // if (data) {
    // setTableData(data.targetDistributionDtoList || []);
    // calculateTotals(data.targetDistributionDtoList || []);

    fetchInvestmentGroupList("")
    fetchInvestmentHeader()
    // }
    // else {
    // setInvestmentList([])
    // setInvestmentHeader([])
    // }
  }, [data]);

  useEffect(() => {
    if (childNodes && investmentList && investmentHeader) {
      // Initialize initialData as an empty array if data is not provided or empty
      let initialData = data && data.length > 0 ? [...data] : [];

      childNodes.forEach((child) => {
        const childExists = initialData.some((item) => item.ihId === child.value);
        if (!childExists) {
          investmentHeader.forEach((investment) => {
            const investmentExists = initialData.some(
              (item) =>
                item.ihId === child.value &&
                item.investmentDescription === investment.igInvestmentGroupDescription
            );

            if (!investmentExists) {
              initialData.push({
                ihId: child.value,
                investmentDescription: investment.igInvestmentGroupDescription,
                investmentId: investment.igInvestmentGroupId,
                target: 0,
                distributed: 0,
                consumed: 0,
                balance: 0,
                createdDateTimeUTC: new Date().toISOString(),
                modifiedDateTimeUTC: new Date().toISOString(),
                createdByUser: 'defaultUser',
                modifiedByUser: 'defaultUser',
                distributionId: 'defaultId',
                isDelete: false
              });
            }
          });
        }
        else {
          investmentHeader.forEach((investment) => {
            const investmentExists = initialData.some(
              (item) =>
                item.ihId === child.value &&
                item.investmentDescription === investment.igInvestmentGroupDescription
            );

            if (!investmentExists) {
              initialData.push({
                ihId: child.value,
                investmentDescription: investment.igInvestmentGroupDescription,
                investmentId: investment.igInvestmentGroupId,
                target: 0,
                distributed: 0,
                consumed: 0,
                balance: 0,
                createdDateTimeUTC: new Date().toISOString(),
                modifiedDateTimeUTC: new Date().toISOString(),
                createdByUser: 'defaultUser',
                modifiedByUser: 'defaultUser',
                distributionId: 'defaultId',
                isDelete: false
              });
            }
          });
        }
      });

      console.log("TABLE Data", initialData, "   ", initialData.length);
      setTableData(initialData);
    }
  }, [data, childNodes, investmentList]);

  const calculateInvestmentTotals = (tableData) => {
    const totals = tableData.reduce((acc, group) => {
      const existingGroup = acc.find(
        (item) => item.investmentDescription === group.investmentDescription
      );

      if (existingGroup) {
        // If the group with the same investmentDescription exists, update totals
        existingGroup.totalTarget = Math.round((existingGroup.totalTarget + group.target) * 100) / 100;
        existingGroup.totalDistributed = Math.round((existingGroup.totalDistributed + group.distributed) * 100) / 100;
        existingGroup.totalConsumed = Math.round((existingGroup.totalConsumed + group.consumed) * 100) / 100;
        existingGroup.totalBalance = Math.round((existingGroup.totalBalance + group.balance) * 100) / 100;
        // existingGroup.available = (existingGroup.totalTarget + group.target) - (existingGroup.totalDistributed + group.distributed);
        existingGroup.available = Math.round((existingGroup.totalTarget - existingGroup.totalDistributed) * 100) / 100;

      } else {
        // If it's a new investmentDescription, create a new entry
        acc.push({
          investmentDescription: group.investmentDescription,
          totalTarget: group.target,
          totalDistributed: group.distributed,
          totalConsumed: group.consumed,
          totalBalance: group.balance,
          available: (group.target - group.distributed)
        });
      }

      return acc;
    }, []);

    return totals;
  };

  useEffect(() => {
    if (tableData) {
      const updatedTotals = calculateInvestmentTotals(tableData);
      setInvestmentTotal(updatedTotals);
    }
  }, [tableData])

  const handleSave = async () => {
    // setLoading(true);
    try {
      // if (tableData) {
      //   // console.error('Missing production target ID');
      //   // setMessage("Missing production target ID");
      //   // setSeverity("error");
      //   // setOpenAlert(true);
      //   return;
      // }

      const currentDateTime = new Date().toISOString();
      const currentUser = "currentUser"; // Replace with actual user info



      // Calculate totals for the main payload
      // const totals = tableData.reduce((acc, row) => ({
      //   contractTarget: acc.contractTarget + (parseFloat(row.contractTarget) || 0),
      //   productivityTarget: acc.productivityTarget + (parseFloat(row.productivityTarget) || 0),
      //   hectaresTarget: acc.hectaresTarget + (parseFloat(row.hectaresTarget) || 0),
      // }), {
      //   contractTarget: 0,
      //   productivityTarget: 0,
      //   hectaresTarget: 0
      // });

      // Construct the final payload
      const payload = tableData.map((item) => ({
        createdDateTimeUTC: item.distributionId === "defaultId" ? currentDateTime : item.createdDateTimeUTC,
        createdByUser: item.distributionId === "defaultId" ? currentUser : item.createdDateTimeUTC,
        modifiedDateTimeUTC: currentDateTime,
        modifiedByUser: currentUser,
        distributionId: item.distributionId === "defaultId" ? "" : item.distributionId,
        ihId: item.ihId,
        cropYearId: cropYearUUID,
        investmentId: item.investmentId,
        // investmentDescription: item.investmentDescription || "",
        target: item.target,
        distributed: item.distributed,
        consumed: item.consumed,
        balance: item.balance,
        isDelete: item.isDelete
      }));

      // Debug log
      console.log('Sending payload to API:', payload);

      // Make the API call
      await InvestmentGroupLimitsService.putInvestmentGLDAPI(payload);

      setMessage("Investments GLD saved successfully");
      setSeverity("success");
      setOpenAlert(true);

    } catch (error) {
      console.error("Error saving data:", error);
      setMessage(error.message || "Error saving production targets");
      setSeverity("error");
      setOpenAlert(true);
    } finally {
      // setLoading(false);
    }
  };


  return (
    <>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {/* First Grid Item */}
        <Grid item xs={4}>
          <Box sx={{ backgroundColor: "#EFEFEF", borderRadius: "8px" }}>
            <Box sx={{ display: "flex" }}>
              <ArrowDropDownIcon sx={{ marginBottom: 1.1 }} />
              <Typography>{t("investmentGroupLimits.showColumns")}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                ml: 1,
                overflowX: "auto", // Enables horizontal scrolling
                whiteSpace: "nowrap", // Prevents wrapping of child elements
              }}
            >
              {/* {columns.map((column) => (
                <Box key={column}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showColumns[column.toLowerCase()]}
                        onChange={(e) => handleColumnChange(column.toLowerCase(), e.target.checked)}
                      />
                    }
                    label={column}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "16px",
                        fontWeight: 400,
                        whiteSpace: "normal", // Allows wrapping of text
                        wordBreak: "break-word", // Ensures long words break correctly
                        overflow: "hidden",
                      },
                    }}
                  />
                </Box>
              ))} */}
              {
                columns.map((column) => {
                  const isDisabled =
                    Object.values(showColumns).filter((value) => value).length < 2 && showColumns[column.toLowerCase()];

                  return (
                    <Box key={column}>
                      <FormControlLabel
                        control={
                          <Tooltip
                            title={isDisabled ? t("investmentGroupLimits.columnTooltip") : ""}
                            arrow
                            disableInteractive
                          >
                            <span>
                              <Checkbox
                                disabled={isDisabled}
                                checked={showColumns[column.toLowerCase()]}
                                onChange={(e) => handleColumnChange(column.toLowerCase(), e.target.checked)}
                              />
                            </span>
                          </Tooltip>
                        }
                        label={column}
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontSize: "16px",
                            fontWeight: 400,
                            // whiteSpace: "normal", // Allows wrapping of text
                            // wordBreak: "break-word", // Ensures long words break correctly
                            // overflow: "hidden",
                          },
                        }}
                      />
                    </Box>
                  );
                })
              }
            </Box>
          </Box>
        </Grid>

        {/* Second Grid Item */}
        <Grid item xs={8}>
          <Box sx={{ backgroundColor: "#EFEFEF", borderRadius: "8px" }}>
            <Box sx={{ display: "flex" }}>
              <ArrowDropDownIcon sx={{ marginBottom: 1.1 }} />
              <Typography>{t("investmentGroupLimits.showHeaders")}</Typography>
            </Box>
            <Box sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              width: "100%"
            }}>
              {/* Header checkboxes */}
              <Box sx={{
                flex: 1,
                overflowX: "auto",
                display: "flex",
                gap: 1,
                ml: 1,
              }}>
                {investmentHeader && investmentHeader.length > 0 && (
                  investmentHeader.map((header) => {
                    // Calculate the count of selected headers
                    const selectedHeadersCount = investmentHeader.filter((h) => h.isHeader).length;

                    // Determine if the checkbox should be disabled
                    const isDisabled = selectedHeadersCount < 2 && header.isHeader;

                    return (
                      <Box
                        item
                        key={header.igInvestmentGroupId}
                        sx={{
                          flexShrink: 0, // Prevents items from shrinking
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Tooltip
                              title={isDisabled ? t("investmentGroupLimits.headerTooltip") : ""}
                              arrow
                            >
                              <span> {/* Wrapper for Tooltip to work on disabled elements */}
                                <Checkbox
                                  checked={header.isHeader}
                                  disabled={isDisabled}
                                  onChange={() => handleInvestmentHeaderChange(header)}
                                />
                              </span>
                            </Tooltip>
                          }
                          label={header.igInvestmentGroupDescription}
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "16px",
                              fontWeight: 400,
                            },
                          }}
                        />
                      </Box>
                    );
                  })
                )}

              </Box>
              {/* Manage Items button */}
              {isTopHierarchyNode && (
                <Box
                  sx={{
                    width: "30%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mr: 2 }}
                    onClick={() => setManageItemsModal(true)}
                  >
                    Manage Items
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 10, mt: 1 }}>
        <Grid item xs={4}>
          <StyledTableContainer component={Paper}>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <SubHeaderCell colSpan={4} style={{ backgroundColor: "transparent", border: "none", padding: "25px" }}></SubHeaderCell>
                </TableRow>
                <TableRow>
                  <SubHeaderCell colSpan={4} style={{ backgroundColor: "transparent", border: "none", padding: "26px" }}></SubHeaderCell>
                </TableRow>
                <TableRow>
                  <SecondaryHeaderCell colSpan={4}>{t('investmentGroupLimits.hnSearchField')}</SecondaryHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {childNodes.map((row, index) => (
                  <TableRow key={index}>
                    <DataCell colSpan={4}>{row.label}</DataCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TotalCell colSpan={4}>Totals</TotalCell>
                </TableRow>
              </TableBody>
            </StyledTable>
          </StyledTableContainer>
        </Grid>
        {/* {(isTopHierarchyNode || data.length > 0) && ( */}
          <Grid item xs={8}>
            <StyledTableContainer component={Paper}>
              <StyledTable>
                <TableHead>
                  <TableRow>
                    {investmentHeader.filter((header) => header.isHeader === true).map((row, index) => (
                      <HeaderCell colSpan={Math.max(
                        2,
                        1 + Object.values(showColumns).filter(Boolean).length
                      )}>{row.igInvestmentGroupDescription}</HeaderCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    {investmentHeader.filter((header) => header.isHeader === true).map((header) => (
                      investmentTotal.filter((item) => item.investmentDescription === header.igInvestmentGroupDescription).map((investment) => (
                        <SubHeaderCell colSpan={Math.max(
                          2,
                          1 + Object.values(showColumns).filter(Boolean).length
                        )}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%", // Full width for inner content
                            }}
                          >
                            {t("investmentGroupLimits.available/target")}
                            <span>
                              {/* {"0 / "} {totals.investments.reduce((sum, inv) => sum + inv.target, 0).toLocaleString()} */}
                              <>{investment.available} / {investment.totalTarget}</>
                            </span>
                          </Box>
                        </SubHeaderCell>
                      ))

                    ))}
                  </TableRow>
                  <TableRow>
                    {investmentHeader.filter((header) => header.isHeader === true).map((row, index) => (
                      ["Target", "Distributed", "Consumed", "Balance"]
                        .filter((header) => {
                          const key = header.toLowerCase(); // Convert to lowercase to match state keys
                          return key === "target" || showColumns[key]; // Always show 'Target', filter others based on state
                        })
                        .map((header) => (
                          <SecondaryHeaderCell
                            key={header}
                            style={{
                              borderLeft: header === "Target" ? "none" : "5px solid white",
                              borderRight: "5px solid white",
                            }}
                          >
                            {header}
                          </SecondaryHeaderCell>
                        ))
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {childNodes.map((child) => (
                    <TableRow key={child.value}>
                      {investmentHeader.filter((header) => header.isHeader === true).map((header) => {
                        const rows = tableData.filter((data) => data.ihId === child.value && data.investmentDescription === header.igInvestmentGroupDescription);

                        return rows.map((row, rowIndex) => {
                          // console.log("ROWDATA", row); // Moved outside JSX for debugging

                          return ["target", "distributed", "consumed", "balance"].map((key, i) => {

                            const value = row[key]; // Access the corresponding value

                            if (key === "target") {
                              // Render "target" as a TextField
                              return (
                                // <DataCell >
                                <StyledTextField
                                  type="number"
                                  key={`${rowIndex}-${i}`}
                                  fullWidth
                                  variant="outlined"
                                  value={value}
                                  sx={{
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                      display: "none",
                                    }
                                  }}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    // Validate and handle changes for "target"
                                    if (/^\d{0,10}(\.\d{0,2})?$/.test(inputValue)) {
                                      handleTargetChange(parseFloat(inputValue) || 0, child.value, header.igInvestmentGroupDescription); // Update the "target" value
                                    }
                                  }}
                                />
                                // </DataCell>
                              );
                            } else {
                              // Render other values as plain text
                              if (!showColumns[key]) return null;
                              return (
                                <DataCell key={`${rowIndex}-${i}`}>
                                  {value?.toLocaleString()}
                                </DataCell>
                              );
                            }
                          });
                        });
                      })}
                    </TableRow>
                  ))}
                  <TableRow>
                    {investmentHeader.filter((header) => header.isHeader === true).map((item) => (
                      investmentTotal.filter((total) => total.investmentDescription === item.igInvestmentGroupDescription).map((itemTotal) => (
                        <>
                          <TotalCell>
                            {itemTotal.totalTarget}
                          </TotalCell>
                          {showColumns.distributed && (
                            <TotalCell>
                              {itemTotal.totalDistributed}
                            </TotalCell>
                          )}
                          {showColumns.consumed && (
                            <TotalCell>
                              {itemTotal.totalConsumed}
                            </TotalCell>
                          )}
                          {showColumns.balance && (
                            <TotalCell>
                              {itemTotal.totalBalance}
                            </TotalCell>
                          )}
                        </>

                      ))
                    ))}
                    {/* {investmentHeader.map((header) => (
                    tableData.filter((data) => data.investmentDescription === header.igInvestmentGroupDescription).map((item) => (
                      
                    ))
                  ))

                  } */}
                    {/* {totals.investments.map((investment, i) =>
                    Object.entries(investment)
                      .filter(([key]) => key === "target" || showColumns[key.toLowerCase()])
                      .map(([key, value], j) => (
                        <TotalCell key={`${i}-${j}`}>
                          {key === "target" ? parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ""}
                        </TotalCell>
                      ))
                  )} */}
                  </TableRow>
                </TableBody>
              </StyledTable>
            </StyledTableContainer>
          </Grid>
        {/* )} */}

      </Grid>
      <Divider
        sx={{
          width: "100%", // Ensures the divider spans the full width
          marginBottom: 1, // Adds spacing between the divider and the button
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        <Button
          sx={{
            padding: "6px 16px",
            borderRadius: "4px",
            // right: "1rem", // Aligns to the right bottom corner
            // bottom: "1rem", // Position it at the bottom
            marginBottom: 4,
            backgroundColor: "#EF7D00",
            color: "white",
            "&:hover": {
              backgroundColor: "#EF7D00",
            },
          }}
          startIcon={<img src={Save} alt="Right Arrow" />}
          onClick={handleSave}
          variant="contained"
        >
          {t("save")}
        </Button>
      </Box>
      <Modal
        open={manageItemsModal}
        onClose={() => setManageItemsModal(false)}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "929px",
            margin: "auto",
            mt: 2,
            maxHeight: "90vh", // Prevent modal from exceeding viewport height
            display: "flex",
            flexDirection: "column", // Ensures the layout respects natural flow
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              // height: "20vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
              color: "#fff",
              pt: 2,
              pb: 1
            }}
          >
            {t("investmentGroupLimits.modalTitle")}
          </Box>
          <Box
            sx={{
              background: "#EFEFEF",
              borderRadius: "10px",
              padding: "1rem",
              height: "110px",
              margin: 2
            }}
          >
            <Box sx={{ display: "flex" }}>
              <ArrowDropDownIcon sx={{ marginRight: 1, marginBottom: 1.1 }} />
              <Typography>{t("filtersLable")}</Typography>
            </Box>
            <TextField
              label={t("investmentGroupLimits.description")}
              value={investmentListSearch}
              onChange={handleInvestmentListSearch}
              size="medium"
              fullWidth={true}
              variant="outlined"
              type="text"
              placeholder="-"
              InputLabelProps={{ shrink: true }}
              sx={{
                '& .MuiInputBase-input::placeholder': {
                  opacity: 1,
                },
              }}
            />

          </Box>
          <Box
            sx={{
              flex: 1, // Allows the scrollable area to take up remaining space
              overflowY: "auto", // Enables scrolling  // Enable vertical scrolling
              overflowX: "hidden", // Prevent horizontal scrolling
              padding: "1rem",
              margin: 1,
            }}
          >
            {investmentList && investmentList.length > 0 ? (
              investmentList.map((row) => (
                <Box
                  item
                  key={row.igInvestmentGroupId}
                  sx={{
                    background: "#EFEFEF",
                    borderRadius: "10px",
                    padding: "1rem",
                    margin: 1,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={row.inHeaderList}
                        onChange={() =>
                          handleInvestmentListChange(
                            row,
                            !row.inHeaderList,
                          )
                        }
                      />
                    }
                    label={row.igInvestmentGroupDescription}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "16px",
                        fontWeight: 400,
                      },
                    }}
                  />
                </Box>
              ))
            ) : (
              <Typography>{t("investmentGroupLimits.noData")}</Typography>
            )}
          </Box>
          <Box
            sx={{
              position: "sticky", // Make it stick within the modal
              bottom: 0, // Stick to the bottom of the container
              // background: "#fff", // Ensure it blends with the modal background
              padding: "1rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "60px",
              zIndex: 2, // Ensure it stays above the content
            }}
          >
            <Button
              onClick={() => setManageItemsModal(false)}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              {t("investmentGroupLimits.close")}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={removeInvestmentModal}
        onClose={() => setRemoveInvestmentModal(false)}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "560px",
            margin: "auto",
            mt: 25,
            height: "200px",
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
              color: "#fff",
            }}
          >
            {t("investmentGroupLimits.removeItemConfirm")}
          </Box>
          {/* <Box sx={{ p: 2 }}>
              <Typography variant="body2">
              Are you sure you don't want to use this record? By selecting 'Yes',    
                you will no longer see this option in your forms.
              </Typography>
            </Box> */}
          <Box sx={{ p: 2 }}>
            <Typography
              variant="body2"
              sx={{ whiteSpace: "pre-wrap", width: "550px", overflowX: "auto" }}
            >
              {t("investmentGroupLimits.removeMessage")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "60px",
              pt: 4,
            }}
          >
            <Button
              onClick={() => setRemoveInvestmentModal(false)}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              {t("no")}
            </Button>

            <Button
              onClick={() => {
                handleRemoveInvestmentfromModal()
                setRemoveInvestmentModal(false)
              }}
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#004F9F" },
              }}
            >
              {t("yes")}
            </Button>
            {/* </Box> */}
          </Box>
        </Box>
      </Modal>
    </>

  );
};
export default InvestmentGroupTable