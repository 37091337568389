import * as React from "react";
import Box from "@mui/material/Box";
import { Button, Modal } from "@mui/material";
import "./FarmerAdd.css";
import DataTable from "../DataTable/DataTable";
import AdultsModal from "./AdultsModal";
import { useDispatch, useSelector } from "react-redux";
import { GridActionsCellItem } from "@mui/x-data-grid";
import iconDelete from "assets/iconDelete.svg";
import { deleteAdult, updateAdults } from "slices/adultsSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FarmerRegisterationService from "services/FarmerRegistrationService";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import isEqual from "lodash.isequal";
import { convertToDateString } from "utils/CommonFunction";
import { useContext } from "react";
import { SnackbarContext } from "context/snackBarContext";
import iconEdit from "assets/iconEdit.svg";
import arrowLeft from "assets/arrowLeft.svg";
import { clearGeneralInformation } from "slices/generalInformation";
import { clearAdults } from "slices/adultsSlice";
import { clearEnvironment } from "slices/environmentalSlice";
import { clearDependant } from "slices/dependantSlice";
import CustomButton from "utils/CustomButton";
import { clearTaxInformation } from "slices/taxInformationSlice";
import { clearProperty } from "slices/propertyAddSlice";
import { useTranslation } from 'react-i18next';

export default function Adults(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const params = useParams();
  const { id } = params;
  const adultInformation = useSelector((state) => state?.adult?.addAdult);
  console.log("🚀 ~ Adults ~ adultInformation:", adultInformation);
  const [values, setValues] = useState(adultInformation || {});
  console.log("adult", values);
  const handleDeleteAdult = (id) => {
    console.log("handleDeleteAdult", id);
    dispatch(deleteAdult(id));
  };
  const [editData, setEditData] = useState(null);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const columns = [
    {
      field: "partnerPropertyName",
      headerName: "Name",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "data-table-header",
    },
    // { field: 'region', headerName: 'Region', flex:1, headerAlign: 'center', align: 'center' },
    // { field: 'city', headerName: 'City', flex:1, headerAlign: 'center', align: 'center' },
    {
      field: "cpfNumber",
      headerName: "CPF",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "relationshipType",
      headerName: "Relationship",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "regDt",
      headerName: "Date of Inclusion",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const [year, month, day] = params.value.split("-");
        return `${day}-${month}-${year}`;
      },
    },
    {
      field: "actions",
      type: "actions",
      // headerName: 'Actions',
      flex: 1,
      headerAlign: "center",
      align: "center",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<img src={iconEdit} height="30px" width="30px" alt="Edit" />}
          className="gridActionCell gridActionCellEdit"
          label="Edit"
          onClick={() => {
            setEditData(params.row);
            props.setAdultModalStatus(true);
          }}
          showInMenu
        />,
        // <GridActionsCellItem
        //   icon={<img src={iconView} height="30px" width="30px" />}
        //   label="Details"
        //   className="gridActionCell gridActionCellDetails"
        //   onClick={() => alert(`Details ${params.id}`)}
        //   showInMenu
        // />,
        // <GridActionsCellItem
        //   icon={<img src={iconEdit} height="30px" width="30px" />}
        //   className="gridActionCell gridActionCellEdit"
        //   label="Edit"
        //   onClick={() => alert(`Edit ${params.id}`)}
        //   showInMenu
        // />,
        <GridActionsCellItem
          icon={
            <img
              src={iconDelete}
              height="30px"
              width="30px"
              alt="Delete Icon"
            />
          }
          label="Delete"
          className="gridActionCell gridActionCellDelete"
          onClick={() => handleDeleteAdult(params.id)}
          showInMenu
        />,
      ],
    },
  ];

  const getFarmerByIds = useCallback(async () => {
    try {
      const response = await FarmerRegisterationService.getFarmerById({ id });
      let fetchedValues =
        response.data?.response?.partnersOnProperty || adultInformation;

      fetchedValues = fetchedValues.map((partner) => ({
        ...partner,
        regDt: convertToDateString(partner.regDt),
      }));

      if (!isEqual(fetchedValues, values)) {
        setValues(fetchedValues);
        dispatch(updateAdults(fetchedValues));
      }
    } catch (error) {
      console.error("Error getting farmer by id:", error);
    }
  }, [id, values, dispatch]);

  useEffect(() => {
    if (id) {
      getFarmerByIds();
    }
  }, [id, getFarmerByIds]);

  const handleSaveDetails = () => {
    if (adultInformation.length > 0) {
      setSeverity("success");
      setMessage(t("snackbarMessage.savedSuccessfully"));
      setOpenAlert(true);
    }
  };

  const handleAddAdult = () => {
    setEditData(null);
    props.setAdultModalStatus(true);
  };

  const location = useLocation();
  const { edit, details } = location.state || {};
  const navigate = useNavigate();
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const handleYes = () => {
    dispatch(clearGeneralInformation());
    dispatch(clearTaxInformation());
    dispatch(clearAdults());
    dispatch(clearProperty());
    dispatch(clearDependant());
    dispatch(clearEnvironment());
    navigate("/farmerRegistration");
  };

  const handleNo = () => setDeleteOpen(false);

  const styleBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30.5rem",
    bgcolor: "background.paper",
    height: "20.125rem",
    borderRadius: "0.625rem",
  };

  return (
    <Box
      sx={{
        minHeight: 180,
        flexGrow: 1,
        width: "100%",
        paddingBottom: "10rem",
        "& .MuiTextField-root": { m: 1, color: "red", width: "25ch" },
        "& .MuiFormLabel-root": {
          color: "rgba(16, 42, 98, 1)",
          "& .MuiFormLabel-asterisk": {
            color: "rgba(239, 0, 0, 1)",
            fontSize: "18px",
          },
        },
      }}
    >
      <div>
        {adultInformation.length > 0 ? (
          <DataTable
            getRowId={(row) => row.propertyId || row.id}
            rows={adultInformation}
            columns={columns}
          />
        ) : (
          ""
        )}
        <Box className="farmer-add-property" onClick={handleAddAdult}>
          + Add Adults.
        </Box>
        {/* <Button
          style={{
            float: "right",
            marginBottom: "40px",
            background: "#EF7D00",
          }}
          onClick={handleSaveDetails}
          variant="contained"
        >
          Save Changes
        </Button> */}
        {/* {edit && */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Button
            style={{
              marginBottom: "10px",
              marginRight: "10px",
              background: "#737D86",
            }}
            startIcon={<img src={arrowLeft} alt="Right Arrow" />}
            variant="contained"
            // onClick={() => navigate("/farmerRegistration")}
            onClick={() => setDeleteOpen(true)}
          >
            Cancel
          </Button>
          {/* <Button
              style={{
                marginBottom: "10px",
                background: "#EF7D00",
              }}
              type="submit"
              variant="contained"
            >
              Save Changes
            </Button> */}
        </div>
        {/* } */}
        {/* {!edit && <Button
          style={{
            float: "left",
            marginBottom: "10px",
            background: "#737D86",
          }}
          startIcon={<img src={arrowLeft} alt="Right Arrow" />}
          variant="contained"
          onClick={() => setDeleteOpen(true)}
        >
          Cancel
        </Button>} */}
        <AdultsModal
          addNewAdult={(formdata) => {
            props.handleAddAdult(formdata);
          }}
          editData={editData}
          open={props.adultModalStatus}
          onClose={() => {
            props.setAdultModalStatus(false);
          }}
        />
      </div>
      <Modal
        open={deleteOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleBox}>
          <Box
            sx={{
              mt: 4,
              justifyContent: "center",
              display: "flex",
              gap: "1rem",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>Are you sure!</div>
            <div>You want to Go Back</div>
            <div>Doing so will erase all the prefilled or filled data</div>
          </Box>
          <Box
            sx={{
              mt: 4,
              justifyContent: "center",
              display: "flex",
              gap: "1rem",
            }}
          >
            <CustomButton variant="cancel" label="No" onClick={handleNo} />

            <CustomButton color="#EF7D00" label="Yes" onClick={handleYes} />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
