import React, { useEffect, useState, useContext } from "react";
import { Typography, Button, Menu, MenuItem, Modal, Box } from "@mui/material";
import EditButton from "../../../../assets/editButton.svg";
import iconView from "../../../../assets/iconView.svg";
import { useNavigate } from "react-router-dom";
import DeleteEquip from "assets/DeleteEquip.svg";
import EquipmentService from "services/EquipmentService";
import { SnackbarContext } from "context/snackBarContext";

const EquipmentTable = (props) => {
  const {setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [equipmentData, setEquipmentData] = useState([]);

  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleOpen = (eqipment) => {
    setDeleteId(eqipment.equipmentId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteEquipment = () => {
    setLoading(true);
    EquipmentService.deleteEquipment(deleteId)
      .then((res) => {
        setLoading(false);
        setEquipmentData((prevData) => prevData.filter((data) => data.equipmentId !== deleteId));
        setMessage("Equipment deleted successfully.");
        setSeverity("success");
        setOpenAlert(true);
        props.refreshData();
      })
      .catch((error) => {
        setLoading(false);
        setMessage(error?.response?.data?.message || error?.message);
        setSeverity("error");
        setOpenAlert(true);
      });
  };

  useEffect(() => {
    setEquipmentData(props.equipmentData);
  }, [props.equipmentData]);

  const handleMenuClose = ({ action, data }) => {
    if (!action || action === undefined) return;
    navigate('/editEquipmentRegistration', {
      state: {
        equipmentName: data.equipmentName,
        equipmentCode: data.equipmentCode,
        edit: true,
        equipmentId: data.equipmentId
      }
    });
    setAnchorEl(null);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 580,
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  return (
    <div className="table-container">
      <table className="full-width-table">
        <thead>
          <tr className="HierarchyHead">
            <th style={{ width: "70%", textAlign: "left", paddingLeft: "10%" }}>Equipment </th>
            <th style={{ width: "30%", textAlign: "left" }}>External Code</th>
            <th style={{ width: "20%", textAlign: "right", paddingRight: "5%" }}>Actions</th>
          </tr>
        </thead>

        <tbody>
          {equipmentData?.map((item, index) => (
            <tr key={index} className="hierarchyColumn">
              <td>
                <Typography
                  sx={{
                    width: "50%",
                    paddingLeft: "16%",
                    fontWeight: 600,
                    color: "#3B454F",
                    fontSize: "16px !important"
                    // fontSize:"500%"
                  }}
                >
                  {item.equipmentName}
                </Typography>
              </td>

              <td
                style={{
                  width: "20%",
                  textAlign: "left",
                  fontWeight: 600,
                  color: "#3B454F",
                }}
              >
                {item.equipmentCode}
              </td>
              <td
                style={{
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "right",
                    paddingRight: "20px",
                    cursor: "pointer",
                  }}
                >
                  <div style={{ display: 'flex', gap: '1em' }}>
                  <img
                    src={EditButton}
                    alt="Edit Button"
                    onClick={() => handleMenuClose({ action: 'edit', data: item })}
                  />
                  <img
                    src={DeleteEquip}
                    alt="Delete"
                    onClick={() => handleOpen(item)}
                  />
                  </div>                  
                  <div>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                    >
                      <div>
                        <MenuItem
                          className="gridActionCell"
                          sx={{
                            border: '1px solid #50AF47 !important',
                            display: "flex",
                            justifyContent: "space-around",
                            cursor: "pointer"
                          }}
                        >
                          <img src={iconView} height="30px" width="30px" />
                          <div>Details</div>
                        </MenuItem>
                      </div>
                    </Menu>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}>
              Delete Confirmation
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography sx={{ mt: 2, fontWeight: 400 }}>
              Are you sure you want to delete this record? Deleted records cannot be recovered.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "20px", mb: 1 }}>
            <Button
              onClick={handleClose}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": {
                  background: "#737D86",
                },
              }}
            >
              No
            </Button>
            <Button
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": {
                  background: "#004F9F",
                },
              }}
              onClick={handleDeleteEquipment}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default EquipmentTable;
