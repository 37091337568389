import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import Layout from "components/structure/Layout";
import arrowLeft from "assets/arrowLeft.svg";
import arrowRight from "assets/arrowRight.svg";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import {
  curingSchema,
  greenManureSchema,
  investmentSchema,
  spacingScheme,
} from "utils/validations";
import RenderField from "utils/RenderField";
import { useLocation, useNavigate } from "react-router-dom";
import CuringService from "services/CuringService";
import { useContext } from "react";
import { SnackbarContext } from "context/snackBarContext";
import { AuthData } from "auth/AuthWarpper";
import {
  editInvestmentGroupField,
  investmentGroupField as initialInvestmentGroupField,
} from "./InvestmentGroupFormConfig"; // Import initial spacingField
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Save from "assets/SaveNew.svg";
import Lable from "utils/lables.json";
import InvestmentService from "services/InvestmentService";
import Delete from "assets/DeleteNew.svg";
import { ADD_SUCCESS_MSG, UPDATE_SUCCESS_MSG } from "utils/AgriErrorMsg";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 580,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  // p: 4,
};

const AddInvestment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = AuthData();

  const {
    investmentGroupIdEdit,
    statusEdit,
    createdByUserEdit,
    modifiedByUserEdit,
    investmentGroupDescriptionEdit,
    investmentsEdit,
    edit,
    details,
  } = location.state || {};
  console.log("🚀 ~ AddInvestment ~ investmentsEdit:", investmentsEdit);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [values, setValues] = useState({});
  console.log("🚀 ~ AddInvestment ~ values:", values);
  const [errors, setErrors] = useState({});
  const [dateError, setDateError] = useState(false);
  const [investmentGroupField, setInvestmentGroupField] = useState(
    edit === true ? editInvestmentGroupField : initialInvestmentGroupField
  ); // State for spacingField
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [options, setOptions] = useState([]);
  console.log("🚀 ~ AddInvestment ~ options:", options);
  console.log("DETAILS->", details, "EDIT->", edit);

  useEffect(() => {
    const fetchTobaccoTypes = async () => {
      try {
        const response = await InvestmentService.getTobaccoType();
        console.log("🚀 ~ fetchTobaccoTypes ~ response:", response);
        const options = response.data.response.map((item) => ({
          value: item.id, // Adjust according to your response structure
          label: `${item.cultureType} - ${item.tobaccoGroup}`, // Adjust according to your response structure
        }));
        console.log("🚀 ~ options ~ options:", options);

        // Update the spacingField with the fetched options
        setInvestmentGroupField((prevFields) => {
          const updatedFields = prevFields.map((field) => {
            if (field.name === "newCuringUnit") {
              return { ...field, options }; // Update the options for the tobacco type
            }
            return field;
          });
          return updatedFields;
        });
      } catch (error) {
        console.error("Error fetching tobacco types:", error);
      }
    };

    fetchTobaccoTypes();
  }, []);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await InvestmentService.getInvestmentDropdown();
        const formattedOptions = response.data.response.map((item) => ({
          value: item.inInvestmentId, // Adjust based on your response structure
          label: item.inInvestmentName, // Adjust based on your response structure
        }));
        setOptions(formattedOptions);
      } catch (error) {
        console.error("Error fetching options:", error);
      } finally {
        // setLoadingOptions(false);
      }
    };

    fetchOptions();
  }, []);

  // const fetchTobaccoGroup = async () => {
  //   try {
  //     const response = await SpacingServices.getTobbacoType();
  //     const tobaccoGroup = response.data.response;

  //     // Construct options array including initial empty option
  //     const options = [
  //       { value: "", label: "None" },
  //       ...tobaccoGroup.map((group) => ({
  //         value: group.tobaccoGroup,
  //         label: group.tobaccoGroup,
  //       })),
  //     ];

  //     // Update spacingField with dynamic options
  //     const updatedSpacingField = spacingField.map((field) => {
  //       if (field.name === "tobaccoType") {
  //         return {
  //           ...field,
  //           options: options,
  //         };
  //       }
  //       return field;
  //     });

  //     setSpacingField(updatedSpacingField); // Update spacingField with dynamic options

  //     // Check if the value from navigation state matches any option
  //     if (location.state?.tobaccoType) {
  //       const matchedOption = options.find(
  //         (option) => option.value === location.state.tobaccoType
  //       );
  //       if (matchedOption) {
  //         setValues((prevValues) => ({
  //           ...prevValues,
  //           tobaccoType: matchedOption.value,
  //         }));
  //       } else {
  //         setValues((prevValues) => ({
  //           ...prevValues,
  //           tobaccoType: options[0].value,
  //         }));
  //       }
  //     } else {
  //       // Set initial value for tobaccoType if not already set
  //       setValues((prevValues) => ({
  //         ...prevValues,
  //         tobaccoType:
  //           prevValues.tobaccoType ||
  //           (options.length > 0 ? options[0].value : ""),
  //       }));
  //     }
  //   } catch (error) {
  //     console.error("Failed to fetch tobacco group:", error);
  //   }
  // };

  useEffect(() => {
    if (location.state) {
      const {
        investmentGroupIdEdit,
        statusEdit,
        createdByUserEdit,
        modifiedByUserEdit,
        investmentGroupDescriptionEdit,
        investmentsEdit,
        tobaccoTypeEdit,
        details,
      } = location.state;
      console.log("location.state:", location.state);

      setValues((prevValues) => ({
        ...prevValues,
        igInvestmentGroupDescription: investmentGroupDescriptionEdit || "",
        status: statusEdit || "",
        newCuringUnit: tobaccoTypeEdit || "",
      }));

      if (Array.isArray(investmentsEdit)) {
        const updatedFields = investmentsEdit.map((investment) => ({
          id: investment.igInvstgroupinvstId, // Use a unique identifier
          value: investment.inInvestmentId || "", // Initialize with the investment name
          isDelete: investment.isDelete,
          isNew: false,
          show: true,
          orgValue: investment.inInvestmentId || "",
        }));
        setTextFields(updatedFields);
      }
      console.log("updated values", textFields.investmentBoxes);

      //       setTextFields((prevValues) => ({
      //         ...prevValues,

      // inInvestmentId

      //       }))

      // Fetch tobacco group and match the tobaccoType
    }
  }, [location.state]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  console.log("VALUES", values);

  const handleSubmit = async (event) => {
    console.log("clicked");
    event.preventDefault();
    setLoading(true);

    try {
      await investmentSchema.validate(values, { abortEarly: false });
      setErrors({});

      let arr = [];
      textFields.forEach(function (investment) {
        if (investment.value != "") {
          arr.push(investment);
        }
      });
      console.log("INVESTMENTS", textFields);
      console.log("payload inv", arr);
      const payload = {
        igInvestmentGroupDescription:
          values.igInvestmentGroupDescription.trim(),
        tobaccoTypeId: values.newCuringUnit,
        // investments: textFields.map((item) => ({
        //   inInvestmentId: item.value,
        //   isDelete: 0,
        // })),
        investments: arr.map((item) => ({
          inInvestmentId: item.value,
          isDelete: item.isDelete,
        })),
        igExternalcode: "1234578",
        isActive: values.status ? 1 : 0,
        // countryId: "5D68BC83-3635-4A2B-A0DF-1199B0B75C6E",
        countryId: "06fde653-a2c4-43b7-879f-4525039d9148",
        languageId: "0a31e178-fdf9-4c8c-bd7b-447a788eada5",
        //        languageId: "960D7994-5B31-4E5B-8D05-C6D58D918229",
        isDelete: 0,
        // status: values.status || false,
        createdByUser: user?.batOneId || 323222,
        modifiedByUser: user?.batOneId || 323222,
        createdDateTimeUTC: new Date().toISOString(),
        modifiedDateTimeUTC: new Date().toISOString(),
      };
      setLoading(true);
      const response = await InvestmentService.createInvestment(payload);
      console.log("response from api", response);
      setLoading(false);
      if (response.status === 201) {
        navigate("/investmentsRegistration");
        setSeverity("success");
        setMessage(ADD_SUCCESS_MSG);
        setOpenAlert(true);
        // setLoading(false);
      } else {
        console.error("Failed to submit form");
        setSeverity("error");
        setMessage("Submission failed");
        setOpenAlert(true);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        let newError = {};
        err?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setErrors(newError);
      } else {
        console.error(
          "Record already exist with same description:",
          err?.response?.data?.message
        );
        setSeverity("error");
        setMessage(err?.response?.data?.message);
        setOpenAlert(true);
      }
    }
  };

  const handleUpdate = async (event) => {
    setLoading(true);

    event.preventDefault();
    try {
      await investmentSchema.validate(values, { abortEarly: false });
      setErrors({});

      let arr = [];
      textFields.forEach(function (investment) {
        if (investment.isNew) {
          if (investment.value != "") {
            arr.push(investment);
          }
        }
        else {
          arr.push(investment);
        }

      });

      const payload = {
        igInvestmentGroupId: investmentGroupIdEdit,
        igInvestmentGroupDescription:
          values.igInvestmentGroupDescription.trim(),
        tobaccoTypeId: values.newCuringUnit,
        // investments: textFields.map((item) => ({
        //   inInvestmentId: item.value,
        //   isDelete: 0,
        // })),
        investments: arr.map((item) => (
          item.isNew
            ? {
              inInvestmentId: item.value,
              isDelete: item.isDelete,
            } :
            {
              inInvestmentId: item.orgValue,
              igInvstgroupinvstId: item.id,
              //     igInvstgroupinvstId: item.groupInvestId,
              isDelete: item.isDelete,
            }
        )),
        igExternalcode: "1234578",
        isActive: values.status ? 1 : 0,
        //        countryId: "5D68BC83-3635-4A2B-A0DF-1199B0B75C6E",
        countryId: "06fde653-a2c4-43b7-879f-4525039d9148",
        languageId: "0a31e178-fdf9-4c8c-bd7b-447a788eada5",
        //        languageId: "960D7994-5B31-4E5B-8D05-C6D58D918229",
        isDelete: false,
        // status: values.status || false,
        createdByUser: user?.batOneId || 323222,
        modifiedByUser: user?.batOneId || 323222,
        createdDateTimeUTC: new Date().toISOString(),
        modifiedDateTimeUTC: new Date().toISOString(),
      };
      setLoading(true);
      const response = await InvestmentService.updateInvestment(payload);
      setLoading(false);
      if (response.status === 201) {
        navigate("/investmentsRegistration");
        setSeverity("success");
        setMessage(UPDATE_SUCCESS_MSG);
        setOpenAlert(true);
        setLoading(false);
      } else {
        console.error("Failed to submit form");
        setSeverity("error");
        setMessage("Submission failed");
        setOpenAlert(true);
      }
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        let newError = {};
        err?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setErrors(newError);
      } else {
        console.error(
          "An error occurred while submitting the form:",
          err?.response?.data?.message
        );
        setSeverity("error");
        setMessage(err?.response?.data?.message);
        setOpenAlert(true);
      }
    }
  };

  const handleClear = (field) => {
    setValues((prevValues) => ({
      ...prevValues,
      [field]: "",
    }));
  };

  const [textFields, setTextFields] = useState([]);
  console.log("🚀 ~ AddInvestment ~ textFields:", textFields);

  const handleAddField = () => {
    setTextFields([...textFields, { id: Date.now(), value: "", isDelete: false, isNew: true, show: true }]); // Add new text field
  };

  // const handleChangeNewField = (id, value) => {
  //   setTextFields((prevFields) =>
  //     prevFields.map((field) =>
  //      (field.id === id ? { ...field, value } : field)
  //      )
  //   );
  // };

  const handleChangeNewField = (id, newValue, isNew) => {
    if (isNew) {

      setTextFields((prevFields) =>
        prevFields.map((field) =>
          field.id === id
            ? { ...field, value: newValue }
            : field
        )
      );
    } else {
      setTextFields((prevFields) =>
        prevFields.map((field) =>
          field.id === id
            ? { ...field, value: newValue, orgValue: newValue !== "" ? newValue : field.value, isDelete: newValue === "" }
            : field
        )
      );
    }
  };

  const handleDeleteField = (field) => {
    if (field.isNew) {
      setTextFields((prevFields) =>
        prevFields.filter((f) => f.id !== field.id)
      );
    }
    else {
      setTextFields((prevFields) =>
        prevFields.map((oldField) =>
          oldField.id === field.id
            ? { ...oldField, isDelete: true, show: false }
            : oldField
        )
      );
    }
    // Remove the field by id
    setDeleteOpen(false)
  };
  console.log("INVTEXTFIELDS", textFields)

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteField, setDeleteField] = useState();
  const handleDeleteClose = () => {
    setDeleteOpen(false); // Close the modal without deleting
  };

  return (
    <Box
      sx={{
        position: "relative",
        background: "pink",
        height: "100%",
      }}
    >
      <Layout
        background="#ffffff"
        title="Investment Group Registration"
        cancel={true}
      >
        <Box sx={{ paddingBottom: "40px" }}>
          <Box
            sx={{
              maxHeight: 115,
              flexGrow: 1,
              width: "100%",
              "& .MuiTextField-root": {
                m: 1,
                color: "green !important",
                width: "25ch",
              },
              "& .MuiFormLabel-root": {
                color: "rgba(16, 42, 98, 1)",
                "& .MuiFormLabel-asterisk": {
                  color: "rgba(239, 0, 0, 1)",
                  fontSize: "18px",
                },
                "& .Mui-focused": {
                  color: "red !important",
                },
              },
            }}
          >
            <Box>
              <Box
                sx={{
                  background: "#EFEFEF",
                  borderRadius: "5px",
                  padding: "0.5rem",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <ArrowDropDownIcon sx={{ marginRight: 1 }} />
                  <Typography>{Lable.fromLable}</Typography>
                </Box>

                <form
                  autoComplete="off"
                  noValidate
                  onSubmit={edit ? handleUpdate : handleSubmit}
                >
                  {investmentGroupField.slice(0, 4).map((field) => (
                    <RenderField
                      key={field.name}
                      field={field}
                      values={values}
                      errors={errors}
                      handleChange={handleChange}
                      handleClear={handleClear}
                      width={field.width}
                      shrink={true}
                      details={details}
                    />
                  ))}
                </form>
              </Box>
            </Box>
          </Box>
          {/* {textFields.length <= 2 && ( */}
          <Box
            sx={{
              position: "absolute",
              bottom: "70px",
              width: "97%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alingItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button
                sx={{
                  backgroundColor: "#737D86",
                  padding: " 6px, 16px, 6px, 11px",
                  borderRadius: " 4px",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#5C646C",
                  },
                }}
                startIcon={<img src={arrowLeft} alt="Arrow" />}
                variant="contained"
                type="secondary"
                onClick={() =>
                  details === true
                    ? navigate("/investmentsRegistration")
                    : handleOpen()
                }
                disabled={loading}
              >
                {loading && <CircularProgress size={20} />}
                {Lable.cancleButton}
              </Button>
              {!details && (
                <Button
                  sx={{
                    backgroundColor: "#EF7D00",
                    padding: " 6px, 16px, 6px, 11px",
                    borderRadius: " 4px",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#D16600",
                    },
                  }}
                  disabled={loading}
                  startIcon={<img src={Save} alt="Right Arrow" />}
                  onClick={edit ? handleUpdate : handleSubmit}
                  variant="contained"
                >
                  {loading && <CircularProgress size={20} />}
                  {/* {edit ? "Update" : "Save"} */}
                  {"Save"}
                </Button>
              )}
            </Box>
          </Box>
          {/* )} */}
          <Box sx={{}}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 1,
                mt: 1,
                borderBottom: "4px solid #00409F",
              }}
            >
              <Typography>Investments</Typography>
              {!details && (
                <Button
                  variant="contained"
                  onClick={handleAddField}
                  sx={{
                    borderRadius: 1,
                    minWidth: 95,
                    minHeight: 44,
                    background: "#004F9F",
                    marginTop: { xs: 1 },
                    marginLeft: { xs: 1 },
                    mb: 0.5,
                    "&:hover": {
                      backgroundColor: "#102A62",
                    },
                  }}
                >
                  Add New
                </Button>
              )}
            </Box>
            <Box sx={{ marginBottom: "100px" }}>
              {textFields.map((field) => (
                field.show && (
                  <Box
                    key={field.id}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: "4px",
                      boxShadow:
                        "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
                      padding: "5px 1rem 5px 1rem",
                      margin: "12px 0px 10px 0px",
                      background: " #EFEFEF",
                    }}
                  >
                    {/* <TextField
                    select
                    value={field.value}
                    onChange={(e) =>
                      handleChangeNewField(field.id, e.target.value)
                    }
                    variant="outlined"
                    sx={{
                      width: "40%",
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-disabled": {
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 0)",
                          },
                          "&:hover fieldset": {
                            borderColor: "rgba(0, 0, 0, 0)",
                          },
                        },
                      },
                    }}
                    margin="normal"
                    label={"Investment"}
                    InputLabelProps={{ shrink: true }}
                    disabled={details}
                  >
                    {options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField> */}
                    <TextField
                      select
                      value={field.value || "PL"}
                      onChange={(e) =>
                        handleChangeNewField(field.id, e.target.value, field.isNew)
                      }
                      variant="outlined"
                      sx={{
                        width: "40%",
                        ".MuiOutlinedInput-root.Mui-disabled": {
                          "& fieldset": {
                            borderColor: "gray",
                            borderWidth: "1px",
                          },
                          "&:hover fieldset": {
                            borderColor: "gray",
                            borderWidth: "1px",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          "&.Mui-disabled": {
                            color: "#102a62",
                          },
                        },
                      }}
                      margin="normal"
                      label={"Investment"}
                      InputLabelProps={{ shrink: true }}
                      disabled={details}
                      SelectProps={{
                        displayEmpty: true,
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: 300,
                            },
                          },
                        },
                      }}
                      InputProps={{
                        // endAdornment: field.value && (
                        //   <InputAdornment position="end">
                        //     <CloseIcon
                        //       onClick={() =>
                        //         !details && handleChangeNewField(field.id, "", field.isNew)
                        //       }
                        //       style={{
                        //         cursor: details == true ? "default" : "pointer",
                        //         marginLeft: "8px",
                        //         marginRight: "15px",
                        //       }}
                        //     />
                        //   </InputAdornment>
                        // ),
                      }}
                    >
                      <MenuItem value="PL" disabled sx={{ display: "none" }}>
                        Select an option
                      </MenuItem>
                      {options.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          sx={{
                            backgroundColor:
                              option.label === field.value
                                ? "#D3D3D3 !important"
                                : "transparent !important",
                            "&:hover": {
                              backgroundColor: "#D3D3D3 !important",
                            },
                          }}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>

                    {!details && (
                      <img
                        onClick={
                          // () => handleDeleteField(field)
                          () => {
                            setDeleteField(field)
                            setDeleteOpen(true)
                          }
                        }
                        style={{ marginLeft: "10px" }}
                        src={Delete}
                      />
                    )}
                  </Box>
                )
              ))}
              {/* {textFields.length >= 3 && (
                <Box
                  sx={{
                    width: "100%",
                    marginBottom: "100px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alingItems: "center",
                      justifyContent: "space-between",
                      position: "fixed"
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#737D86",
                        padding: " 6px, 16px, 6px, 16px",
                        borderRadius: " 4px",
                      }}
                      startIcon={<img src={arrowLeft} alt="Arrow" />}
                      variant="contained"
                      type="secondary"
                      onClick={handleOpen}
                      disabled={loading}
                    >
                      {loading && <CircularProgress size={20} />}
                      {Lable.cancleButton}
                    </Button>
                    {!details && (
                      <Button
                        sx={{
                          backgroundColor: "#EF7D00",
                          padding: " 6px, 16px, 6px, 11px",
                          borderRadius: " 4px",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#EF7D00",
                          },
                        }}
                        disabled={loading}
                        startIcon={<img src={Save} alt="Right Arrow" />}
                        onClick={edit ? handleUpdate : handleSubmit}
                        variant="contained"
                      >
                        {loading && <CircularProgress size={20} />}
                        {edit ? "Update" : "Save"}
                      </Button>
                    )}
                  </Box>
                </Box>
              )} */}
            </Box>
          </Box>
          <Modal
            open={deleteOpen}
            onClose={handleDeleteClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                backgroundColor: "#fff",
                borderRadius: "7px",
                width: "520px",
                margin: "auto",
                mt: 25,
                height: "200px",
              }}
            >
              <Box
                sx={{
                  background: "#102A62",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderTopLeftRadius: "7px",
                  borderTopRightRadius: "7px",
                }}
              >
                <Typography
                  sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
                >
                  Delete Confirmation
                </Typography>
              </Box>
              <Box sx={{
                p: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2, fontWeight: 400 }}
                >
                  Are you sure you want to delete this record?
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                  mb: 1,
                }}
              >
                <Button
                  onClick={handleDeleteClose}
                  sx={{
                    background: "#737D86",
                    width: "100px",
                    color: "#ffffff",
                    "&:hover": { background: "#737D86" },
                  }}
                >
                  No
                </Button>
                <Button
                  onClick={() => handleDeleteField(deleteField)}
                  sx={{
                    background: "#004F9F",
                    width: "100px",
                    color: "#ffffff",
                    "&:hover": { background: "#004F9F" },
                  }}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Modal>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Box
                sx={{
                  background: "#102A62",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
                >
                  {Lable.cancleOperationLable}
                </Typography>
              </Box>
              <Box sx={{ p: 2 }}>
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2, fontWeight: 400 }}
                >
                  {Lable.cancleConfermationLable}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                  mb: 1,
                }}
              >
                <Button
                  onClick={handleClose}
                  sx={{
                    background: "#737D86",
                    width: "100px",
                    color: "#ffffff",
                    "&:hover": {
                      background: "#5C646C",
                    },
                  }}
                >
                  {Lable.noButton}
                </Button>
                <Button
                  sx={{
                    background: "#004F9F",
                    width: "100px",
                    color: "#ffffff",
                    "&:hover": {
                      background: "#003D7A",
                    },
                  }}
                  onClick={() => navigate("/investmentsRegistration")}
                >
                  {" "}
                  {Lable.yesButton}
                </Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      </Layout>
    </Box>
  );
};

export default AddInvestment;
