import React from 'react';

const ExportIcon = (props) => {
  return (
    <svg
                      width="77"
                      height="35"
                      viewBox="0 0 77 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="34.684"
                        height="34.684"
                        rx="10"
                        fill="white"
                      />
                      <path
                        d="M6.9375 6.93677H12.3287C11.582 7.65399 10.8365 8.37013 10.0288 9.14403C11.8894 10.9883 13.6116 12.6964 15.3425 14.4109C15.0123 14.7227 14.6994 15.0181 14.3997 15.3004C12.7243 13.6185 11.0152 11.9018 9.26031 10.1403C8.48313 10.9709 7.78335 11.7175 7.08356 12.4653C7.03451 12.4293 6.98655 12.3944 6.9375 12.3585V6.93677Z"
                        fill="#0F2B63"
                      />
                      <path
                        d="M14.2743 19.2539C14.682 19.6441 14.9937 19.9428 15.2989 20.2371C13.5953 21.9299 11.8763 23.6379 10.0789 25.4223C10.9073 26.2256 11.6485 26.945 12.4747 27.7472H6.9375V22.3005C7.6569 23.046 8.3556 23.7698 9.06301 24.5045C10.867 22.6863 12.5826 20.9576 14.2732 19.2539H14.2743Z"
                        fill="#0F2B63"
                      />
                      <path
                        d="M24.5674 9.28031C23.7172 8.45081 22.9924 7.7434 22.1836 6.95532H27.6914V12.3574C26.9774 11.6151 26.2722 10.8815 25.5637 10.1447C23.7358 11.9835 22.0266 13.7046 20.2837 15.4585C19.9251 15.0737 19.6308 14.7576 19.3594 14.4666C21.062 12.7716 22.7776 11.0636 24.5685 9.2814L24.5674 9.28031Z"
                        fill="#0F2B63"
                      />
                      <path
                        d="M24.6019 25.5367C22.7347 23.6782 21.0266 21.9789 19.3164 20.2774C19.6314 19.9569 19.9432 19.6397 20.2691 19.3083C21.9695 21.0142 23.6721 22.7212 25.3954 24.4499C26.13 23.6967 26.8429 22.9653 27.5569 22.2339C27.6016 22.2644 27.6452 22.2961 27.6898 22.3266V27.7461H22.2213C23.0322 26.994 23.7811 26.2986 24.6029 25.5367H24.6019Z"
                        fill="#0F2B63"
                      />
                      <rect
                        x="42"
                        width="34.684"
                        height="34.684"
                        rx="10"
                        fill="white"
                      />
                      <path
                        d="M61.3136 15.4111V10.0132C62.0478 10.7812 62.7505 11.5176 63.4531 12.2529C65.28 10.4173 66.9871 8.70156 68.7432 6.93677C69.0983 7.35073 69.3892 7.68844 69.7105 8.0621C68.0579 9.70379 66.3389 11.4098 64.6155 13.1212C65.3802 13.8609 66.1036 14.5613 66.8269 15.2607C66.7943 15.3108 66.7616 15.3609 66.7289 15.41H61.3125L61.3136 15.4111Z"
                        fill="#0F2B63"
                      />
                      <path
                        d="M51.9492 15.3032C52.6769 14.6081 53.4046 13.912 54.1639 13.1876C52.4002 11.4369 50.6801 9.72988 48.9414 8.00322C49.2944 7.65353 49.6016 7.3485 49.8935 7.05981C51.6169 8.79193 53.3251 10.5088 55.0517 12.2442C55.8546 11.426 56.5725 10.694 57.2904 9.96192C57.3296 9.9848 57.3688 10.0066 57.4081 10.0295V15.4273H52.0385C52.0091 15.3859 51.9786 15.3445 51.9492 15.3032Z"
                        fill="#0F2B63"
                      />
                      <path
                        d="M57.4203 24.9005C56.6349 24.077 55.9278 23.3351 55.1718 22.5431C53.3874 24.3352 51.6793 26.052 49.9918 27.7471C49.6672 27.4312 49.36 27.1305 49.043 26.8211C50.7293 25.15 52.4462 23.4473 54.2241 21.6858C53.4092 20.908 52.6728 20.2053 51.9375 19.5038C51.968 19.4645 51.9985 19.4242 52.029 19.385H57.4203V24.9005Z"
                        fill="#0F2B63"
                      />
                      <path
                        d="M64.4915 21.4909C66.3652 23.3559 68.0854 25.0663 69.8295 26.8016C69.4623 27.1535 69.1497 27.4542 68.8545 27.7374C67.1529 26.0162 65.4512 24.295 63.6592 22.4833C62.8542 23.3516 62.1515 24.1087 61.4499 24.8647L61.3203 24.7634V19.3862H66.7356C65.9927 20.0834 65.2628 20.7676 64.4915 21.4909Z"
                        fill="#0F2B63"
                      />
                    </svg>
  );
};

export default ExportIcon;