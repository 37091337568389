import { httpGrow } from "../http-common";

const getCropSeasonAPI = async () => {
    const response = await httpGrow.get('/cropYears');
    return response;
};
const getHierarchyNodeAPI = async () => {
  const response = await httpGrow.get('/importHierarchyVersion/tree');
  return response;
};

const getGeneticType = () => {
  return httpGrow.get(
    `/geneticType/genetic-type-cultureName/Tobacco`
  );
};

const getVarietyType = (geneticId) => {
  return httpGrow.get(
    `/variety/variety-type-geneticId/${geneticId}`
  );
};

const getVarietySeedsDataAPI = async (query) => {
  const { cropYearId, geneticTypeId,  importVersionId} = query;
  const response = await httpGrow.get(`/variety-targets/`, {
    params: {
      cropYearId,
      geneticTypeId,
      importVersionId
    },
  });
  return response;

};
const getAPI = async (query) => {
  const { cropYearUUID, tobaccoTypeUUID, ihId } = query;
  const response = await httpGrow.get(`/productionTarget/search`, {
      params: {
          cropYearUUID,
          tobaccoTypeUUID,
          ihId
      }
  });
  return response;
};

const updateVarietySeedsTargets = async (data) => {
  const response = await httpGrow.put(`/variety-targets/save`, data);
  return response;
};

const VarietySeedService = {
  getCropSeasonAPI,
  getHierarchyNodeAPI,
  getGeneticType,
  getVarietyType,
  getVarietySeedsDataAPI,
  getAPI,
  updateVarietySeedsTargets
};
export default VarietySeedService;
