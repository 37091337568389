import React, { useContext, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { SnackbarContext } from "context/snackBarContext";
import icondeleteround from "../../../assets/iconEdit.svg";
import "./Hierarchy.css";
import { AuthData } from "auth/AuthWarpper";
import { useTranslation } from "react-i18next";

const TargetList = (props) => {
  const [targetData, settargetData] = useState([]);
  const { t } = useTranslation();
  const { setOpenAlert, setSeverity, setMessage, handleClose } =
    useContext(SnackbarContext);

  useEffect(() => {
    settargetData(props.targetData);
  }, [props.targetData]);

  return (
    <>
      <div className="table-container">
        <table className="full-width-table">
          <thead>
            <tr className="HierarchyHead">
              <th>{t("hierarchyConfiguration.targetFeature")}</th>
              <th>{t("hierarchyConfiguration.unitOfMeasure")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {targetData &&
              targetData?.targetHLC?.map((row, index) => (
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <Typography sx={{ justifyContent: "left" }}>
                      {row?.trgFeature}
                    </Typography>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Typography sx={{ justifyContent: "left" }}>
                      {row?.unitOfMeasure}
                    </Typography>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div onClick={() => props.onEditTargetHlc(row)}>
                      <img
                        style={{
                          marginRight: "20px",
                          maxWidth: "30px",
                          cursor: "pointer",
                        }}
                        src={icondeleteround}
                      />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TargetList;
