// import React, { useEffect, useState } from "react";
// import { Typography, Box, Button, Menu, MenuItem } from "@mui/material";
// import "./Expenses3rdParty.css";
// import EditButton from "../../../../assets/editButton.svg";
import { useNavigate } from "react-router-dom";

import React, { useContext, useEffect, useState } from "react";
import { Typography, Button, Modal, Box, Tooltip } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import _ButtonEdit_ from "assets/_ButtonEdit_.svg";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import OccurrenceTypeService from "services/OccurrenceTypeService";
import { SnackbarContext } from "context/snackBarContext";
import ExpensesTypeService from "services/ExpensesTypeService";

const Expenses3rdPartyTable = (props) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [originalData, setOriginalData] = useState([]);
  const [expensesData, setExpensesData] = useState([]);

  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  const [open, setOpen] = useState(false); // Modal state
  const [deleteId, setDeleteId] = useState(null); // ID of the record to delete

  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  useEffect(() => {
    setExpensesData(props.expensesData);
    setOriginalData(props.expensesData);
  }, [props.expensesData]);

  const handleDeleteClick = (expensesId) => {
    setDeleteId(expensesId); // Store the ID of the record to delete
    setOpen(true); // Open the confirmation modal
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await ExpensesTypeService.deleteExpenses(deleteId); // Delete by stored ID
      if (response.status === 200) {
        setMessage("Deleted successfully");
        setSeverity("success");
        setOpenAlert(true);
        setExpensesData((prevData) =>
          prevData.filter(
            (item) => item.ewtpExpenseswith3rdPartiesId !== deleteId
          )
        );
        props.refreshData()
      } else {
        setMessage("Failed to delete expenses type");
        setSeverity("error");
        setOpenAlert(true);
      }
    } catch (error) {
      setMessage("Error deleting expenses type");
      setSeverity("error");
      setOpenAlert(true);
      console.error("Error deleting expenses type:", error);
    } finally {
      setOpen(false); // Close the modal after deletion
    }
  };

  const handleClose = () => {
    setOpen(false); // Close the modal without deleting
  };

  // const handleSort = (key) => {
  //   let direction = "asc";
  //   if (sortConfig.key === key) {
  //     if (sortConfig.direction === "asc") {
  //       direction = "desc";
  //     } else if (sortConfig.direction === "desc") {
  //       direction = null;
  //     }
  //   }
  //   setSortConfig({ key, direction });
  //   if (direction === null) {
  //     setExpensesData(originalData);
  //   } else {
  //     const sortedData = [...expensesData].sort((a, b) => {
  //       if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
  //       if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
  //       return 0;
  //     });
  //     setExpensesData(sortedData);
  //   }
  // };

  // const getSortIcon = (key) => {
  //   if (sortConfig.key === key) {
  //     if (sortConfig.direction === "asc")
  //       return <ArrowUpwardIcon fontSize="small" />;
  //     else if (sortConfig.direction === "desc")
  //       return <ArrowDownwardIcon fontSize="small" />;
  //   }
  //   return null;
  // };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...expensesData].sort((a, b) => {
      if (key === "tobaccoType") {
        const aTobaccoType = a.tobaccoTypes[0]?.tobaccoType || ""; // Sort by the first tobaccoType in the array
        const bTobaccoType = b.tobaccoTypes[0]?.tobaccoType || "";

        if (aTobaccoType < bTobaccoType) return direction === "asc" ? -1 : 1;
        if (aTobaccoType > bTobaccoType) return direction === "asc" ? 1 : -1;
        return 0;
      }


      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setExpensesData(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        return <ArrowUpwardIcon fontSize="small" />;
      } else if (sortConfig.direction === "desc") {
        return <ArrowDownwardIcon fontSize="small" />;
      }
    }
    // Default icon (ArrowDownward) when no sorting is applied
    return <ArrowDownwardIcon fontSize="small" />;
  };

  return (
    <div className="table-container">
      <table className="custom-table">
        <thead>
          <tr>
            <th
              onClick={() => handleSort("ewtpExpenseswith3rdPartiesDescription")}
              style={{
                cursor: "pointer",
                display: "flex",
              }}
            >
              Expenses with 3rd Parties Group Description {getSortIcon("ewtpExpenseswith3rdPartiesDescription")}
            </th>

            <th
              style={{
                cursor: "pointer",
                display: "flex",
              }}
              onClick={() => handleSort("tobaccoType")}
            >
              Tobacco Type {getSortIcon("tobaccoType")}
            </th>

            <th
              style={{
                cursor: "pointer",
                display: "flex",
              }}
              onClick={() => handleSort("isActive")}
            >
              Status {getSortIcon("isActive")}
            </th>

            <th className="actions-header">Actions</th>
          </tr>
        </thead>

        <tbody>
          {expensesData?.map((item, index) => (
            <tr key={index}>
              <td
                onClick={() =>
                  navigate("/viewExpenses", {
                    state: {
                      ewtpExpenseswith3rdPartiesDescription:
                        item.ewtpExpenseswith3rdPartiesDescription,
                      tobaccoType: item.tobaccoType,
                      cultureName: item.cultureName,
                      edit: false,
                      details: true,
                      ewtpExpenseswith3rdPartiesId:
                        item.ewtpExpenseswith3rdPartiesId,
                      isActive: item.isActive,
                      tobaccoTypeId: item.tobaccoTypes,
                      investements: item.investements,
                    },
                  })
                }
                style={{ cursor: "pointer" }}
              >
                <Typography
                  sx={{
                    justifyContent: "left",
                    fontWeight: 600,
                    color: "#3B454F",
                  }}
                >
                  {item.ewtpExpenseswith3rdPartiesDescription}
                </Typography>
              </td>

              <td
                onClick={() =>
                  navigate("/viewExpenses", {
                    state: {
                      ewtpExpenseswith3rdPartiesDescription:
                        item.ewtpExpenseswith3rdPartiesDescription,
                      tobaccoType: item.tobaccoType,
                      cultureName: item.cultureName,
                      edit: false,
                      details: true,
                      ewtpExpenseswith3rdPartiesId:
                        item.ewtpExpenseswith3rdPartiesId,
                      isActive: item.isActive,
                      tobaccoTypeId: item.tobaccoTypes,
                      investements: item.investements,
                    },
                  })
                }
                style={{ cursor: "pointer" }}
              >
                {item?.tobaccoTypes.map((tobacco, index) => (
                  <span key={index}>
                    {tobacco.tobaccoType}
                    {index + 1 !== item.tobaccoTypes.length ? "," : ""}
                  </span>
                ))}
              </td>

              <td
                onClick={() =>
                  navigate("/viewExpenses", {
                    state: {
                      ewtpExpenseswith3rdPartiesDescription:
                        item.ewtpExpenseswith3rdPartiesDescription,
                      tobaccoType: item.tobaccoType,
                      cultureName: item.cultureName,
                      edit: false,
                      details: true,
                      ewtpExpenseswith3rdPartiesId:
                        item.ewtpExpenseswith3rdPartiesId,
                      isActive: item.isActive,
                      tobaccoTypeId: item.tobaccoTypes,
                      investements: item.investements,
                    },
                  })
                }
                style={{ cursor: "pointer" }}
              >
                {item.isActive ? "Active" : "Inactive"}
              </td>
              <td className="actions-cell">
                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    cursor: "pointer",
                    marginTop: "-25px",
                  }}
                > */}
                <Tooltip
                  title="EDIT"
                  placement="top"
                  arrow
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -5],
                        },
                      },
                    ],
                  }}
                >

                  <img
                    src={_ButtonEdit_}
                    className="btn edit-btn"
                    onClick={() =>
                      navigate("/editExpenses", {
                        state: {
                          ewtpExpenseswith3rdPartiesDescription:
                            item.ewtpExpenseswith3rdPartiesDescription,
                          tobaccoType: item.tobaccoType,
                          cultureName: item.cultureName,
                          edit: true,
                          ewtpExpenseswith3rdPartiesId:
                            item.ewtpExpenseswith3rdPartiesId,
                          isActive: item.isActive,
                          tobaccoTypeId: item.tobaccoTypes,
                          investements: item.investements,
                        },
                      })
                    }
                    alt="Edit Button" />
                </Tooltip>
                <Tooltip
                  title="DELETE"
                  placement="top"
                  arrow
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -5],
                        },
                      },
                    ],
                  }}
                >
                  <img
                    src={_ButtonDelete_}
                    className="btn delete-btn"
                    onClick={() =>
                      handleDeleteClick(item.ewtpExpenseswith3rdPartiesId)
                    }
                    alt="Delete" />
                </Tooltip>
                {/* </div> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal for delete confirmation */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "520px",
            margin: "auto",
            mt: 25,
            height: "200px",
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
            >
              Delete Confirmation
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, fontWeight: 400 }}
            >
              Are you sure you want to delete this record? Deleted records
              cannot be recovered.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              mb: 1,
            }}
          >
            <Button
              onClick={handleClose}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              No
            </Button>
            <Button
              onClick={handleDeleteConfirm}
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#004F9F" },
              }}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

// }

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 5,
      width: "100%",
    }}
  />
);

export default Expenses3rdPartyTable;
