import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
  Box,
  CircularProgress,
  Checkbox,
  InputLabel,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { List, ListItem, ListItemText } from "@mui/material";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Service from "../../../services/ModulesRegistrationServices";
import EditPencil from "../../../assets/editPencil";
import ArrowIcon from "../../../assets/ArrowIcon";
import Layout from "components/structure/Layout";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import { SnackbarContext } from "context/snackBarContext";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import SubModuleIcon from "../../../assets/submoduleIcon";
import ExpandAll from "../../../assets/ExpandAll.svg"
import ExpandAllBlue from "../../../assets/ExpandAllBlue.svg"
import CollapseAll from "../../../assets/CollapseAll.svg"
import CollapseAllBlue from "../../../assets/CollapseAllBlue.svg"
import CheckboxUntick from "../../../assets/CheckboxUntick.svg"
import CheckboxTick from "../../../assets/CheckboxTick.svg"
import './Module.css'
import EditButton from "utils/EditButton";
import { AuthData } from "auth/AuthWarpper";
import { useTranslation } from 'react-i18next';


const RegistrationOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderContainer = styled.div`
display: "flex", height: "10px", marginTop: 5
`;
const CustomBox = styled(Box)`
  width: 100%;
  margin-top: 20px;
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;
const ListBody = styled.div`
  height: auto;
  overflow-y: auto;
  background-color: white;
  border-radius: 20px;
  border: 1px solid #d8d8d8;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Heading = styled.div`
  color: rgba(115, 125, 134, 1);
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
`;

let noDataHide = false;
const CropStageCalander = (props) => {
  const [getCropList, setCropList] = useState([]);
  const [selectedCropStage, setSelectedCropStage] = useState(" ");
  const [clickCollapse, setClickCollapse] = useState(false);
  const [clickExpandable, setClickExpandable] = useState(false);
  const { user } = AuthData();
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState([]);
  const [getregistration, setGetregistration] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { setOpenAlert, setSeverity, setMessage, handleClose } =
    useContext(SnackbarContext);


  const handleAccordionChange = (index) => {
    const newExpanded = [...expanded];
    if (newExpanded.includes(index)) {
      const indexToRemove = newExpanded.indexOf(index);
      newExpanded.splice(indexToRemove, 1);
    } else {
      newExpanded.push(index);
    }
    setExpanded(newExpanded);
  };

  const handleExpandAll = () => {
    const allIndexes = getregistration.map((_, index) => index);
    setExpanded(allIndexes);
  };

  const handleCollapseAll = () => {
    setExpanded([]);
  };
  const [cropStageUUID, setSetcropStageUUID] = useState("");
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    const selectedCrop = getCropList?.find(
      (crop) => crop.cropStageName === selectedValue
    );

    setSelectedCropStage(selectedValue);
    setSetcropStageUUID(selectedCrop?.cropStageUid);
  };

  const handleButtonClick = async (id) => {
    if (selectedCropStage == "Default") return
    if (!noDataHide) {
      setGetregistration([]);
    }
    try {
      setLoading(true);
      const response = await Service.getmoduleregistration(id);
      if (noDataHide) {
        setMessage(t("snackbarMessage.updatedSuccessfully"))
        setOpenAlert(true)
        setSeverity("success")
      }
      noDataHide = false
      if (response) {
        let items = response?.data?.response
        let sorted = items.sort(function (a, b) {
          return (a.sequence - b.sequence);
        });
        setGetregistration(sorted);
        setLoading(false);
      }
    } catch (error) {
      noDataHide = false
      setOpenAlert(true);
      setSeverity("error");
      setMessage(error?.response?.data?.message || error?.message);
      setLoading(false);
    }
  };

  const handleNavigateToEdit = (item) => {
    if (item && selectedCropStage) {
      navigate("/editmoduleregistration", {
        state: { item, cropStage: selectedCropStage },
      });
    }
  };

  useEffect(() => {
    fetchModules()
  }, []);

  const fetchModules = async () => {
    try {
      const response = await Service.getCroplist();
      setCropList(response?.data?.response);

      if (location?.state?.cropStageUUID) {
        setSelectedCropStage(location?.state?.selectedCropStage ? location?.state?.selectedCropStage : response?.data?.response[0]?.cropStageName);
        setSetcropStageUUID(location?.state?.cropStageUUID ? location?.state?.cropStageUUID : response?.data?.response[0]?.cropStageUid);
        handleButtonClick(location?.state?.cropStageUUID ? location?.state?.cropStageUUID : response?.data?.response[0]?.cropStageUid)
      } else {
        setSelectedCropStage("Default")
      }
    } catch (error) {
      setOpenAlert(true);
      setSeverity("error");
      setMessage(error?.response?.data?.message || error?.message);
    }
  }
  const onDragEnd = (result) => {
    if (!result.destination) {
      return; // Item dropped outside the list
    }
    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    let oldModuleList = JSON.parse(JSON.stringify(getregistration))
    const updatedmoduleList = Array.from(getregistration);
    const [removed] = updatedmoduleList.splice(startIndex, 1);
    updatedmoduleList.splice(endIndex, 0, removed);
    updatedmoduleList.forEach((ele, index) => {
      ele.sequence = index + 1
    })
    setGetregistration(updatedmoduleList)
    setLoading(true)
    Service.updateSequence(updatedmoduleList)
      .then(() => {
        setLoading(false)
        setMessage("Sequence updated")
        setOpenAlert(true)
        setSeverity("success")
      }).catch((error) => {
        setLoading(false)
        setMessage(error?.response?.data?.message || error?.message)
        setOpenAlert(true)
        setSeverity("error")
        setGetregistration(oldModuleList)
      })
  };

  const handleExpand = () => {
    setClickExpandable(true)
    setClickCollapse(false)
    handleExpandAll()
  }
  const handleCollapse = () => {
    setClickExpandable(false)
    setClickCollapse(true)
    handleCollapseAll()
  }


  const handleSwitchChange = (e, module, subIndex) => {
    let parsedModule = JSON.parse(JSON.stringify(module))
    parsedModule.subModule[subIndex].mStatus = e.target.checked
    parsedModule.subModule[subIndex].modifiedByUser = user?.userName
    setLoading(true)
    Service.updateModule(parsedModule).then((res) => {
      setLoading(false);
      noDataHide = true
      handleButtonClick(cropStageUUID)

    }).catch((error) => {
      setLoading(false)
      setMessage(error?.response?.data?.message || error?.message)
      setOpenAlert(true)
      setSeverity("error")
    })
  }
  return (
    <Layout title="Module Setup List">
      <div className="moduleContainer">
        <HeaderContainer>
          <Heading>Module Setup List</Heading>
        </HeaderContainer>
        <RegistrationOptions>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 12, sm: 8, md: 12 }}
          >
            <Grid item xs={12} sm={4} md={4}>
              <ButtonContainer
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", gap: 2 }}>
                  <div className={`collapseImageContainer ${clickExpandable ? 'clicked' : ''}`} onClick={() => handleExpand()}>
                    <img className="CollapseImage1" src={ExpandAll} />
                    <img className="CollapseImage2" src={ExpandAllBlue} />
                  </div>
                  <div className={`collapseImageContainer1 ${clickCollapse ? 'clicked' : ''}`} onClick={() => handleCollapse()}>
                    <img className="CollapseImage11" src={CollapseAll} />
                    <img className="CollapseImage22" src={CollapseAllBlue} />
                  </div>

                </Box>

                {/* <ArrowIcon /> */}
                <FormControl
                  sx={{ width: { xs: "100%", md: 450 }, minHeight: 50 }}
                >
                  <Select
                    value={selectedCropStage}
                    onChange={(e) => handleChange(e)}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="Default">Select Crop Stage</MenuItem>
                    {getCropList?.map((crop, index) => (
                      <MenuItem key={index} value={crop?.cropStageName}>
                        {crop?.cropStageName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Box sx={{ marginTop: "0px", width: "100%" }}>
                  <Button
                    sx={{
                      borderRadius: 4,
                      width: "50%",
                      height: 50,
                      background: "#102A62",
                    }}
                    variant="contained"
                    onClick={() => handleButtonClick(cropStageUUID)}
                    disabled={loading}
                  >
                    {loading && <CircularProgress size={10} />}
                    Get
                  </Button>
                </Box>
              </ButtonContainer>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  sx={{
                    borderRadius: 100,
                    minWidth: 100,
                    minHeight: 44,
                    background: "#102A62",
                    marginTop: { xs: "20px", md: 0 },
                  }}
                  variant="contained"
                  startIcon={<AddCircleIcon />}
                  onClick={() => navigate("/addmoduleregistration")}
                >
                  Add
                </Button>
              </Box>
            </Grid>
          </Grid>
        </RegistrationOptions>
        <DragDropContext onDragEnd={onDragEnd}>
          {getregistration &&
            getregistration?.map((module, index) => (
              <Droppable
                key={module.mId}
                droppableId={`module-${module.mId}`}
              >
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <Draggable
                      key={module.mId}
                      draggableId={`module-${module.mId}`}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <div className="" key={index}>
                            <CustomBox>
                              <Accordion
                                expanded={expanded.includes(index)}
                                onChange={() => handleAccordionChange(index)}
                                sx={{
                                  borderRadius: "150px",
                                  "& .MuiAccordionSummary-root.Mui-expanded": {
                                    borderRadius: "150px",
                                  },
                                }}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls={`panel${index}-content`}
                                  id={`panel${index}-header`}
                                  sx={{
                                    borderRadius: "150px",
                                    "&.Mui-expanded": {
                                      borderRadius: "150px",
                                    },
                                  }}
                                >
                                  <Grid
                                    container
                                    spacing={{ xs: 2, md: 3 }}
                                    columns={{ xs: 4, sm: 8, md: 12 }}
                                  >
                                    <Grid
                                      item
                                      xs={10}
                                      sm={8}
                                      md={8}
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                      }}
                                    >
                                      <>
                                        <Box sx={{ paddingRight: "10px" }}>
                                          <SubModuleIcon />
                                        </Box>
                                        <Typography sx={{ marginY: "auto" }}>
                                          {module.mName}
                                        </Typography>
                                      </>

                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={4}
                                      md={4}
                                      sx={{
                                        display: "flex",
                                        justifyContent: { xs: "flex-start", md: "flex-end" },
                                        alignItems: "center",
                                      }}
                                    >
                                      <EditButton state={{ item: module, cropStage: selectedCropStage }} url={"/editmoduleregistration"} />
                                    </Grid>
                                  </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <ListBody>
                                    {module.subModule?.map((subItem, subIndex) => (
                                      <div key={subIndex}>
                                        <List sx={{ paddingBottom: "0px" }}>
                                          <ListItem>
                                            <ListItemText primary={subItem.mName} />
                                            <InputLabel sx={{ marginRight: "2rem" }}>
                                              Active
                                            </InputLabel>
                                            <Checkbox name="active"
                                              checked={subItem?.mStatus}
                                              onChange={(e) => handleSwitchChange(e, module, subIndex)}
                                              icon={<img src={CheckboxUntick} />}
                                              checkedIcon={<img src={CheckboxTick} />}
                                            />
                                          </ListItem>
                                          <Divider component="li" />
                                        </List>
                                      </div>
                                    ))}
                                  </ListBody>
                                </AccordionDetails>
                              </Accordion>
                            </CustomBox>
                          </div>
                        </div>
                      )}
                    </Draggable>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}
        </DragDropContext>
      </div>
    </Layout>
  );
};

export default CropStageCalander;
