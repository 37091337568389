import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SupplyTable from "./SupplyTable";
import SupplyTypeService from "services/SupplyTypeService";
import { httpGrow } from "http-common";
import Table from "./Table";
import { debounce } from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import { tr } from "date-fns/locale";
import { useTranslation } from 'react-i18next';


const SupplyTypeList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [supplyData, setSupplyData] = useState(null);
  console.log("🚀 ~ SupplyTypeList ~ supplyData:", supplyData);
  // const [supplyDropdownData, setSupplyDropdownData] = useState(null);

  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);

  const [dropDownItems, setDropDownItems] = useState([]);

  const [searchParams, setSearchParams] = useState({
    description: "",
    useNpk: "",
    status: "",
  });

  var isApiCalled = 0;

  // const [descSearch, setDescSearch] = useState({ description: "" });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
    console.log("searching parameters", searchParams);

    handleSearchApi();
  };

  const callSupplyTypeListAPI = (pageNumber, size) => {
    setLoading(true);
    SupplyTypeService.getSupplyList({ pageNumber, size })
      .then((res) => {
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setLoading(false);
        setSupplyData(res?.data?.response.supplyTypes);
        setTotalItems(res?.data?.response?.totalItems);
      })
      .catch((error) => {
        setLoading(false);
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  const callSupplyTypeDropdownListAPI = (pageNumber, size) => {
    setLoading(true);
    SupplyTypeService.getSupplyDropdownList()
      .then((res) => {
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setLoading(false);
        setDropDownItems(res?.data?.response);
        // setTotalItems(res?.data?.response?.totalItems);
      })
      .catch((error) => {
        setLoading(false);
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  const handleSearchApi = useCallback(
    debounce((pNumber, size) => {
      const cleanedParams = cleanSearchParams(searchParams);
      SupplyTypeService.searchSupplyType({
        pageNumber: pNumber,
        pageSize: size,
        keyword: cleanedParams.description,
        isUseNPK: cleanedParams.useNpk,
        isActive: cleanedParams.status,
      })
        .then((res) => {
          setCurrentPage(pNumber);
          setItemsPerPage(size);
          setLoading(false);
          setTotalItems(res?.data?.response?.totalItems);
          setSupplyData(res?.data?.response?.supplyTypes);
        })
        .catch((error) => {
          setLoading(false);
          setMessage(error?.response?.data?.message || error?.message);
          setOpenAlert(true);
          setSeverity("error");
          setSupplyData([]);
        });
    }, 500),
    [searchParams]
  );

  const cleanSearchParams = (params) => {
    const cleanedParams = { ...params };
    Object.keys(cleanedParams).forEach((key) => {
      if (cleanedParams[key] === "") {
        delete cleanedParams[key];
      }
    });
    return cleanedParams;
  };

  const handleClear = (name) => {
    setSearchParams({
      ...searchParams,
      [name]: "",
    });
  };

  // const paginate = (pageNumber, itemsPerPage) => {
  //   callSupplyTypeListAPI(pageNumber - 1, itemsPerPage);
  // };
  const paginate = (pageNumber, itemsPerPage) => {
    console.log("searchParams", searchParams);
    setCurrentPage(pageNumber - 1);
    if (
      searchParams.description ||
      searchParams.status ||
      searchParams.useNpk
    ) {
      handleSearchApi(pageNumber - 1, itemsPerPage);
    } else {
      callSupplyTypeListAPI(pageNumber - 1, itemsPerPage);
    }
  };

  useEffect(() => {
    callSupplyTypeDropdownListAPI();
    callSupplyTypeListAPI(currentPage, itemsPerPage);
  }, []);

  useEffect(() => {
    if (Object.keys(cleanSearchParams(searchParams)).length > 0) {
      isApiCalled = 1;
      handleSearchApi(currentPage, itemsPerPage);
      console.log("api call times if", isApiCalled);
    } else if (Object.keys(cleanSearchParams(searchParams)).length == 0) {
      callSupplyTypeListAPI(currentPage, itemsPerPage);
      console.log(
        "api call times else if",
        Object.keys(cleanSearchParams(searchParams)).length,
        isApiCalled
      );
    } else {
    }

    return () => {
      handleSearchApi.cancel();
    };
  }, [searchParams]);

  const refreshData = () => {
    // Call the API again after an item is deleted
    const totalItemsOnPage = supplyData?.length ?? 0;
    if (
      searchParams.description === "" &&
      searchParams.status === "" &&
      searchParams.useNpk === ""
    ) {
      callSupplyTypeListAPI(currentPage, itemsPerPage);
    } else {
      handleSearchApi(currentPage, itemsPerPage);
    }
  };

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      currentPage={currentPage}
      paginate={paginate}
      title={t("supplyType.pageTitle")}
    >
      <Box mt={4} mb={6}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Box display={"flex"} gap={4}>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                select
                name="description"
                label={t("supplyType.filterLabels.description")}
                value={searchParams.description}
                onChange={handleChange}
                sx={{ width: "16.75rem" }}
                SelectProps={{
                  IconComponent: ArrowDropDownIcon,
                  endAdornment: searchParams.description && (
                    <CloseIcon
                      onClick={() => handleClear(t("supplyType.filterLabels.description"))}
                      style={{
                        cursor: "pointer",
                        marginRight: "12px",
                      }}
                    />
                  ),
                }}
              >
                {/* <MenuItem onClick={()=>  callSupplyTypeListAPI(currentPage, itemsPerPage)} value="">
                <em>None</em>
              </MenuItem> */}
                {dropDownItems.map((item, index) => (
                  <MenuItem key={index} value={item.supplyTypeDescription}>
                    {item.supplyTypeDescription}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <FormControl sx={{ minWidth: "200px" }}>
                <TextField
                  select
                  name="useNpk"
                  label={"Is Fertilizer"}
                  value={searchParams.useNpk}
                  onChange={handleChange}
                  variant="outlined"
                  SelectProps={{
                    IconComponent: ArrowDropDownIcon,
                    endAdornment: searchParams.useNpk && (
                      <CloseIcon
                        onClick={() => handleClear("useNpk")}
                        style={{
                          cursor: "pointer",
                          marginRight: "12px",
                        }}
                      />
                    ),
                  }}
                >
                  <MenuItem value={"Yes"}> {t("yes")} </MenuItem>
                  <MenuItem value={"No"}> {t("no")}</MenuItem>
                </TextField>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <FormControl sx={{ minWidth: "200px" }}>
                <TextField
                  select
                  name="status"
                  onChange={handleChange}
                  label={t("status")}
                  value={searchParams.status}
                  SelectProps={{
                    IconComponent: ArrowDropDownIcon,
                    endAdornment: searchParams.status && (
                      <CloseIcon
                        onClick={() => handleClear("status")}
                        style={{
                          cursor: "pointer",
                          marginRight: "12px",
                        }}
                      />
                    ),
                  }}
                >
                  <MenuItem value="">
                    {/* <em>None</em> */}
                    {t("selectoption")}
                  </MenuItem>
                  <MenuItem value={"Active"}>{t("active")}</MenuItem>
                  <MenuItem value={"Inactive"}>{t("inactive")}</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
          </Box>

          <Button
            sx={{
              borderRadius: 100,
              minWidth: 95,
              minHeight: 44,
              background: "#102A62",
              marginTop: { xs: 1 },
              marginLeft: { xs: 1 },
            }}
            variant="contained"
            startIcon={<AddCircleIcon />}
            onClick={() => navigate("/addSupplyType")}
          >
            {t("supplyType.buttons.addNew")}
          </Button>
        </Box>
        {!loading && supplyData && supplyData.length === 0 && (
          <Typography variant="h6" align="center" color="textSecondary">
          {t("noDataMessage")}
          </Typography>
        )}
        {!loading && supplyData && supplyData.length > 0 && (
          <Table
            setSupplyTypenull={() => setSupplyData([])}
            // callSupplyTypeListAPI={() => callSupplyTypeListAPI(currentPage, itemsPerPage)}
            supplyData={supplyData}
            refreshData={refreshData}
          />
        )}
      </Box>
    </Layout>
  );
};

export default SupplyTypeList;
