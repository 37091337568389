export const asrField = [
  {
    name: "asrSupplyName",
    label: "Agricultural Supply",
    type: "select",
    options: [
      { value: "", label: "None" },
      { value: "Fertiizer", label: "Fertilizer" },
      { value: "Seed", label: "Seedd" },
    ],
    required: true,
    disabled: false,
  },
  {
    name: "asrCode",
    label: "Code",
    type: "text",
    required: true,
    disabled: false,
  },
  {
    name: "asrExternalCode",
    label: "External Code",
    type: "text",
    required: true,
    disabled: false,
  },
  {
    name: "asrUnlockModality",
    label: "Release Modality",
    type: "select",
    options: [
      { value: "", label: "None" },
      { value: "Normal", label: "Normal" },
      {
        value: "Additional without increasing area",
        label: "Additional without increasing area",
      },
      { value: "Replenishment", label: "Replenishment" },
    ],
    required: false,
    disabled: false,
  },
  {
    name: "asrUnitOfMeasurement",
    label: "Unit of measurement",
    type: "select",
    options: [
      { value: "", label: "None" },
      { value: "UN", label: "UN" },
      { value: "PAC", label: "PAC" },
      { value: "L", label: "L" },
      { value: "KG", label: "KG" },
      { value: "ROL", label: "ROL" },
      { value: "PAR", label: "PAR" },
    ],
    required: false,
    disabled: false,
  },
  {
    name: "asrTarget",
    label: "Target",
    type: "select",
    options: [
      { value: "", label: "None" },
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ],
    required: false,
    disabled: false,
  },
  {
    name: "asrRecommendationType",
    label: "Recommendation type",
    type: "select",
    options: [
      { value: "", label: "None" },
      { value: "Hectares", label: "Hectares" },
      { value: "Tillage", label: "Tillage" },
      { value: "Curing Unit", label: "Curing Unit" },
      { value: "Thousand Feet", label: "Thousand Feet" },
      { value: "Tons", label: "Tons" },
      { value: " Thousand Feet Float", label: " Thousand Feet Float" },
    ],
    required: false,
    disabled: false,
  },
  {
    name: "asrRecommendation",
    label: "Recommendation",
    type: "text",
    required: false,
    disabled: false,
  },
  {
    name: "asrMaxPacking",
    label: "Max packing",
    type: "text",
    required: false,
    disabled: false,
  },
  {
    name: "asrSupplyType",
    label: "Supply Type",
    type: "select",
    options: [
      { value: "", label: "None" },
      { value: "pest1", label: "Pest1" },
      { value: "pest2", label: "Pest2" },
    ],
    required: false,
    disabled: false,
  },
  {
    name: "asrThirdPartyAgriculturalSupply",
    label: "Third-Party Agricultural Supply",
    type: "switch-two-label",
    required: false,
    disabled: false,
  },
];
