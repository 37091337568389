import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import arrowDown from "assets/arrowDown.svg";
import { Button, Checkbox, FormControlLabel, Modal, TextField } from "@mui/material";
import { StyledTreeItem } from "utils/Styledtreeitem";
import PropertyRegisterationModal from "./PropertyRegisterationModal";
import DataTable from "../DataTable/DataTable";
import "./FarmerAdd.css";
import {
  updateTaxInformation,
  addTaxField,
  updateTaxField,
  clearTaxInformation,
} from "slices/taxInformationSlice";
import RenderField from "utils/RenderField";
import { taxFormConfig } from "./FormConfig";
import { taxInformationSchema } from "utils/validations";
import iconDelete from "assets/iconDelete.svg";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { clearProperty, deleteProperty } from "slices/propertyAddSlice";
import iconDeleteRound from "assets/iconDeleteRound.svg";
import { SnackbarContext } from "context/snackBarContext";
import FarmerRegisterationService from "services/FarmerRegistrationService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PickListService from "services/PickListService";
import arrowLeft from "assets/arrowLeft.svg";
import { clearGeneralInformation } from "slices/generalInformation";
import { clearAdults } from "slices/adultsSlice";
import { clearEnvironment } from "slices/environmentalSlice";
import { clearDependant } from "slices/dependantSlice";
import CustomButton from "utils/CustomButton";

export default function TaxInformation(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;
  const taxInformation = useSelector((state) => state.tax.taxInformation);
  const propertyInformation = useSelector(
    (state) => state?.property?.propertyInformation
  );
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const [values, setValues] = useState(() => ({
    ...taxInformation,
    taxFields: taxInformation.taxFields || [],
  }));

  console.log("🚀 ~ const[values,setValues]=useState ~ values:", values)


  const [modalValue, setModalValues] = useState([]);
  const [errors, setErrors] = useState({});
  const [dateError, setDateError] = useState(false);
  const [picklistData, setPicklistData] = useState([]);
  const [taxForm, setTaxFormConfig] = useState(taxFormConfig);
  console.log("🚀 ~ TaxInformation ~ taxForm:", taxForm)

  console.log("🚀 ~ TaxInformation ~ picklistData:", picklistData)

  useEffect(() => {
    if (taxInformation) {
      setValues((prevValues) => ({
        ...prevValues,
        ...taxInformation,
        taxFields: taxInformation.taxFields || prevValues.taxFields,
      }));
    }
  }, [taxInformation]);

  useEffect(() => {
    const fetchPicklistData = async () => {
      try {
        const response = await PickListService.picklist({ pickListCodes: ["FRGINFBNK1"] });
        const picklistResponse = response.data.response;

        const groupedPicklistData = Object.keys(picklistResponse).map((pickListCode) => ({
          pickListCode,
          items: picklistResponse[pickListCode].map(item => ({
            pickListItemCode: item.pickListItemCode,
            pickListDescription: item.pickListDescription,
          })),
        }));

        setPicklistData(groupedPicklistData);

        const accountTypeOptions = groupedPicklistData.find(picklist => picklist.pickListCode === "FRGINFBNK1")?.items.map(item => ({
          value: item.pickListItemCode,
          label: item.pickListDescription,
        })) || [];

        setTaxFormConfig(prevConfig => prevConfig.map(field =>
          field.name === "accountType" ? { ...field, options: [{ value: "", label: "None" }, ...accountTypeOptions] } : field
        ));

      } catch (error) {
        console.error("Error fetching picklist data:", error);
      }
    };

    fetchPicklistData();
  }, []);



  useEffect(() => {
    const getFarmerByIds = async () => {
      try {
        const response = await FarmerRegisterationService.getFarmerById({ id });
        const bank = response?.data?.response?.bankDetails || {};
        console.log("🚀 ~ getFarmerByIds ~ bank:", bank);
        console.log("API Response:", response.data.response);

        const newValues = {
          taxFields: response?.data?.response?.taxDetails || [],
          ...bank,
        };

        setValues(newValues);
        dispatch(updateTaxInformation(newValues));
      } catch (error) {
        console.error("Error getting farmer by id:", error);
      }
    };

    if (id && values.bankName === "") {
      getFarmerByIds();
    }
  }, [id, dispatch]);


  const handleTaxFieldChange = (index, event) => {
    const { name, value, type, checked } = event.target;
    setValues((prevValues) => {
      const updatedTaxFields = [...prevValues.taxFields];
      updatedTaxFields[index] = {
        ...updatedTaxFields[index],
        [name]: type === "checkbox" ? checked : value,
      };
      return {
        ...prevValues,
        taxFields: updatedTaxFields,
      };
    });

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (name === 'stateRegistrationNumber' && value) {
        updatedErrors.taxFields = updatedErrors.taxFields || [];
        updatedErrors.taxFields[index] = updatedErrors.taxFields[index] || {};
        updatedErrors.taxFields[index].stateRegistrationNumber = "";
      }
      if (name === 'city' && value) {
        updatedErrors.taxFields = updatedErrors.taxFields || [];
        updatedErrors.taxFields[index] = updatedErrors.taxFields[index] || {};
        updatedErrors.taxFields[index].city = "";
      }
      return updatedErrors;
    });
  };

  const validateFields = () => {
    const newErrors = values.taxFields.map((field) => ({
      stateRegistrationNumber: field.stateRegistrationNumber
        ? field.stateRegistrationNumber.length > 20
          ? "State Registration Number should not exceed 20 digits"
          : ""
        : "State Registration Number is required",
      city: field.city ? "" : "City is required",
    }));

    const hasFieldErrors = newErrors.some(
      (error) => error.stateRegistrationNumber || error.city
    );

    setErrors((prevErrors) => ({
      ...prevErrors,
      taxFields: newErrors,
    }));

    return hasFieldErrors ? newErrors : {};
  };


  const addTaxFieldHandler = () => {
    setValues((prevValues) => ({
      ...prevValues,
      taxFields: [
        ...prevValues.taxFields,
        { stateRegistrationNumber: "", city: "", nfe: false },
      ],
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const fieldValidationErrors = validateFields();

    let schemaValidationErrors = {};
    try {
      await taxInformationSchema.validate(values, { abortEarly: false });
    } catch (err) {
      err?.inner?.forEach((error) => {
        schemaValidationErrors[error.path] = error.message;
      });

      setErrors((prevErrors) => ({
        ...prevErrors,
        ...schemaValidationErrors,
      }));
    }

    const hasErrors =
      Object.values(fieldValidationErrors).some((fieldErrors) =>
        Object.values(fieldErrors).some((error) => error)
      ) || Object.values(schemaValidationErrors).some((error) => error);

    if (!hasErrors) {
      setErrors({});
      dispatch(updateTaxInformation(values));
      setSeverity("success");
      setMessage("Saved successfully");
      setOpenAlert(true);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleDeleteTaxField = (index) => {
    setValues((prevValues) => {
      const updatedTaxFields = [...prevValues.taxFields];
      updatedTaxFields.splice(index, 1);
      return {
        ...prevValues,
        taxFields: updatedTaxFields,
      };
    });
  };

  const handleDateChange = (date, fieldName) => {
    if (!date || date === "Invalid Date") {
      setDateError(true);
      setValues((prevValues) => ({
        ...prevValues,
        [fieldName]: "",
      }));
      return;
    }

    setDateError(false);

    const dateValue = new Date(date);
    if (isNaN(dateValue.getTime())) {
      setDateError(true);
      setValues((prevValues) => ({
        ...prevValues,
        [fieldName]: "",
      }));
      return;
    }

    const year = dateValue.getFullYear();
    const month = String(dateValue.getMonth() + 1).padStart(2, "0");
    const day = String(dateValue.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setValues((prevValues) => ({
      ...prevValues,
      [fieldName]: formattedDate,
    }));
  };

  const handleClear = (field) => {
    setValues((prevValues) => ({
      ...prevValues,
      [field]: "",
    }));
  };

  const navigate = useNavigate();
  const location = useLocation();
  const { edit, details } = location.state || {};
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const handleYes = () => {
    dispatch(clearGeneralInformation());
    dispatch(clearTaxInformation());
    dispatch(clearAdults());
    dispatch(clearProperty());
    dispatch(clearDependant());
    dispatch(clearEnvironment());
    navigate("/farmerRegistration")
  }

  const handleNo = () => setDeleteOpen(false);

  const styleBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30.5rem",
    bgcolor: "background.paper",
    height: "20.125rem",
    borderRadius: "0.625rem",
  };
  return (
    <Box
      sx={{
        minHeight: 180,
        flexGrow: 1,
        paddingBottom: "10rem",
        width: "100%",
        "& .MuiTextField-root": { m: 1, color: "red", width: "25ch" },
        "& .MuiFormLabel-root": {
          color: "rgba(16, 42, 98, 1)",
          "& .MuiFormLabel-asterisk": {
            color: "rgba(239, 0, 0, 1)",
            fontSize: "18px",
          },
        },
      }}
    >
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <TreeView
          aria-label="file system navigator"
          defaultCollapseIcon={<img src={arrowDown} alt="Down Arrow" />}
          defaultExpandIcon={<ChevronRightIcon />}
          defaultExpanded={["1", "4", "5"]}
        >
          <StyledTreeItem nodeId="1" labelText="Bank/Region Registration">
            {taxForm.slice(0, 5).map((field) => (
              <RenderField
                key={field.name}
                field={field}
                values={values}
                errors={errors}
                handleChange={handleChange}
                handleDateChange={handleDateChange}
                handleClear={handleClear}
              />
            ))}
          </StyledTreeItem>
          <StyledTreeItem nodeId="4" labelText="Tax Information">
            <div className="farmer-add-tree-main">
              {values.taxFields.map((field, index) => (
                <div className="farmer-inner-field" key={index}>
                  <div className="text-field-wrapper">
                    <TextField
                      value={field.stateRegistrationNumber}
                      onChange={(e) => handleTaxFieldChange(index, e)}
                      name="stateRegistrationNumber"
                      type="number"
                      error={
                        !!errors?.taxFields?.[index]?.stateRegistrationNumber
                      }
                      helperText={
                        errors?.taxFields?.[index]?.stateRegistrationNumber
                      }
                      label="State Registration Number"
                      required
                      variant="outlined"
                    />
                    <TextField
                      value={field.city}
                      onChange={(e) => handleTaxFieldChange(index, e)}
                      name="city"
                      error={!!errors?.taxFields?.[index]?.city}
                      helperText={errors?.taxFields?.[index]?.city}
                      label="City"
                      required
                      variant="outlined"
                    />
                  </div>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.nfe}
                        onChange={(e) => handleTaxFieldChange(index, e)}
                        name="nfe"
                      />
                    }
                    label="NFE"
                  />
                  <img
                    onClick={() => handleDeleteTaxField(index)}
                    src={iconDeleteRound}
                    alt="Delete"
                  />
                </div>
              ))}
              <Box className="farmer-add-property" onClick={addTaxFieldHandler}>
                +Add State Registration
              </Box>
            </div>
          </StyledTreeItem>

          {/* {edit && */}
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "10px" }}>
              <Button
                style={{
                  marginBottom: "10px",
                  marginRight: "10px",
                  background: "#737D86",
                }}
                startIcon={<img src={arrowLeft} alt="Right Arrow" />}
                variant="contained"
                // onClick={() => navigate("/farmerRegistration")}
                onClick={() => setDeleteOpen(true)}
              >
                Cancel
              </Button>
              <Button
                style={{
                  marginBottom: "10px",
                  background: "#EF7D00",
                }}
                type="submit"
                variant="contained"
              >
                Save Changes
              </Button>
            </div>
          {/* } */}
          {/* {!edit && <Button
            style={{
              float: "left",
              marginBottom: "10px",
              background: "#737D86",
            }}
            startIcon={<img src={arrowLeft} alt="Right Arrow" />}
            variant="contained"
            onClick={() => setDeleteOpen(true)}
          >
            Cancel
          </Button>} */}
        </TreeView>
      </form>
      <Modal
        open={deleteOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleBox}>
          <Box
            sx={{
              mt: 4,
              justifyContent: "center",
              display: "flex",
              gap: "1rem",
              flexDirection: "column",
              alignItems: "center",
            }}
          >

            <div>Are you sure!</div>
            <div>You want to Go Back</div>
            <div>Doing so will erase all the prefilled or filled data</div>

          </Box>
          <Box
            sx={{
              mt: 4,
              justifyContent: "center",
              display: "flex",
              gap: "1rem",
            }}
          >
            <CustomButton variant="cancel" label="No" onClick={handleNo} />

            <CustomButton
              color="#EF7D00"
              label="Yes"
              onClick={handleYes}
            />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
