import React, { useContext, useEffect, useState } from "react";
import { Typography, Box, Button, Menu, MenuItem } from "@mui/material";
import "./CultureGroup.css";
import EditButton from "../../../../assets/editButton.svg";
import moreActions from "../../../../assets/moreActions.svg";
import { useNavigate } from "react-router-dom";
import iconView from "assets/iconView.svg";
import CultureGroupService from "services/CultureGroup";
import { SnackbarContext } from "context/snackBarContext";
import { useTranslation } from 'react-i18next';

const CultureGroupTable = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [cultureData, setCultureData] = useState([]);

  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setCultureData(props.cultureGroupData);
  }, [props.cultureGroupData]);

  const [selectedRow, setSelectedRow] = React.useState({});
  const handleMenuClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(data)
  };

  const handleUpdate = async (status, id) => {
    const payload = {
      cultureGroupName: selectedRow.cultureGroupName, // Assuming the name is part of the selectedRow
      status: status === 'active' ? true : false, // Update status based on the action ('active' or 'inactive')
      countryCode: "BRA",
      cultureGroupId: id
    };

    try {
      setLoading(true);
      const response = await CultureGroupService.updateCultureGroup({ ...payload });
      setLoading(false);
      if (response.status === 200) {
        setSeverity("success");
        setMessage(`Status updated to ${status} successfully`);
        setOpenAlert(true);
        props.refreshData();
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        const errorMessage = error.response.data.message;
        setMessage(errorMessage);
        setOpenAlert(true);
      } else {
        console.error("Failed to submit form");
        setSeverity("error");
        setMessage(t("snackbarMessage.updateFailed"));
        setOpenAlert(true);
      }
    }
  };

  const handleMenuClose = ({ action, data }) => {
    if (!action || action == undefined) return
    setSelectedRow(data)
    if (action == 'details') {
      navigate('/viewcultureGroup', {
        state: {
          description: data.cultureGroupName
          , status: data.status, edit: false, details: true
        }
      });
    } else if (action == 'edit') {
      navigate('/editcultureGroup', {
        state: {
          description: data.cultureGroupName
          , status: data.status, edit: true, id: data.cultureGroupId
        }
      });
    } else if (action === 'activate') {
      // Call handleUpdate to update the status to 'active'
      handleUpdate('active', data.cultureGroupId);
    } else if (action === 'deactivate') {
      handleUpdate('inactive', data.cultureGroupId);
    }
    setAnchorEl(null);
  };

  return (
    <div className="table-container">
      <table className="full-width-table">
        {" "}
        <thead>
          <tr className="HierarchyHead">
            <th>{t("code")} </th>
            <th>{t("description")}</th>
            <th>{t("status")}</th>
            <th>{t("actions")}</th>
          </tr>
        </thead>
        <tbody>
          {cultureData?.map((item, index) => (
            <tr key={index} className="hierarchyColumn" style={{}}>
              <td style={{ textAlign: "center" }}>
                <Typography
                  sx={{
                    justifyContent: "center",
                    fontWeight: 600,
                    color: "#3B454F",
                  }}
                >
                  {item.cultureCode}
                </Typography>
              </td>
              <td style={{ textAlign: "center", minWidth: "35vw" }}>
                <Box
                  sx={{
                    whiteSpace: "nowrap",
                    paddingLeft: "5px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontWeight: 600,
                    color: "#3B454F",
                  }}
                >
                  <span title="title">

                    {item.cultureGroupName}
                  </span>
                </Box>
              </td>

              <td
                style={{
                  textAlign: "center",
                  fontWeight: 600,
                  color: "#3B454F",
                }}
              >
                {item.status === true ? "Active" : "Inactive"}
              </td>
              <td
                style={{
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingRight: "20px",
                    cursor: "pointer",
                  }}
                >
                  <img
                    style={{ maxHeight: '40px' }}
                    src={EditButton}
                    alt="Edit Button"
                    onClick={() => handleMenuClose({ action: 'edit', data: item, tab: 1 })}
                  />
                  <Button
                    variant="outlined"
                    className="moreButton"
                    style={{ marginLeft: "10px", maxHeight: "40px" }}
                    onClick={(e) => handleMenuClick(e, item)}
                  >
                    <div>
                      <img src={moreActions} alt="more-actions" />
                    </div>{" "}
                    <div style={{ height: "14px" }}>More</div>
                  </Button>
                  <div key={index}>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={() => handleMenuClose({ action: 'Add', data: item, tab: 0 })}
                      key={index}
                    >
                      <div className="" >
                        <MenuItem className="gridActionCell" sx={{ border: '1px solid #50AF47 !important', display: "flex", justifyContent: "space-around", cursor: "ponter" }}
                          onClick={() => handleMenuClose({ action: 'details', data: selectedRow, tab: 1 })}
                        >
                          <img src={iconView} height="30px" width="30px" />
                          <div>View</div>
                        </MenuItem>
                        {selectedRow.status === false && (
                          <MenuItem className="gridActionCell" sx={{ border: '1px solid #50AF47 !important', display: "flex", justifyContent: "space-around", cursor: "ponter" }}
                            onClick={() => handleMenuClose({ action: 'activate', data: selectedRow, tab: 1 })}
                          >
                            <div>Activate</div>
                          </MenuItem>
                        )}
                        {selectedRow.status === true && (
                          <MenuItem className="gridActionCell" sx={{ border: '1px solid #50AF47 !important', display: "flex", justifyContent: "space-around", cursor: "ponter" }}
                            onClick={() => handleMenuClose({ action: 'deactivate', data: selectedRow, tab: 1 })}
                          >
                            <div>Deactivate</div>
                          </MenuItem>
                        )}
                      </div>
                    </Menu>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CultureGroupTable;
