import { http} from "../http-common";

const FetchAllHierarchy = query => {
  return http.get(`/hierarchyLevelConfig/list?pageNumber=${query.pageNumber}&size=${query.size}`);
};
const addLevel = data => {
  return http.post('/hierarchyLevelConfig',data);
};
const updateData = data => {
  return http.put('/hierarchyLevelConfig', data);
};
const deleteLevel = id => {
  return http.delete('/hierarchyLevelConfig/'+id);
};
const updateSequence = (data) => {
  return http.put(`/hierarchyLevelConfig/sequence?oldSequence=${data.old}&newSequence=${data.new}`);
};
const FetchAllTarget = query => {
  return http.get(`/hierarchyLevelConfig/targets?pageNumber=${query.pageNumber}&size=${query.size}`);
};

const GetHierarchyTargetHlc = query => {
  return http.get(`/hierarchyLevelConfig/targetHlc?targetFeatureUUID=${query.id}&pageNumber=${query.pageNumber}&size=${query.size}`);
};

const updateDataHlc = data => {
  return http.put('/hierarchyLevelConfig/targetHlc', [data]);
};
const HierarchyService = {

  FetchAllHierarchy,
  addLevel,
  updateData,
  deleteLevel,
  updateSequence,
  FetchAllTarget,
  GetHierarchyTargetHlc,
  updateDataHlc
};

export default HierarchyService;

