import React, { useContext } from "react";
import {
  Box,
  Typography,
  Button,
  Modal,
  TextField,
  FormControl,
  CircularProgress,
  FormLabel,
  FormControlLabel,
} from "@mui/material";
import Layout from "components/structure/Layout";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import arrowLeft from "assets/arrowLeft.svg";
import Save from "assets/SaveNew.svg";
import Lable from "utils/lables.json";
import { Label } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import RedSwitch from "utils/RedSwitch";
import PaymentService from "services/PaymentPolicyService";
import { SnackbarContext } from "context/snackBarContext";
import { ADD_SUCCESS_MSG, UPDATE_SUCCESS_MSG } from "utils/AgriErrorMsg";
import { useTranslation } from 'react-i18next';

const AddPaymentPolicies = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { edit } = location.state || {};
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const { PPDescription, status, details, paymentPolicyId } =
    location?.state?.row || {};

  const [values, setValues] = useState({
    paymentDescription: PPDescription || "",
    status: status === true ? true : false,
  });

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleDeleteConfirm = () => {
    setOpen(false);
    navigate("/paymentPolicies");
  };

  const handleChange = (event) => {
    console.log("type");
    const { name, value, type, checked } = event.target;

    console.log("tete", name, value, type, checked);
    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  useEffect(() => {
    console.log("location:", location.state);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    let schemaValidationErrors = {};

    const specialCharPattern = /^[a-zA-Z0-9 ]+$/; // Allow only letters, numbers, and spaces

    // Trim the input to check for leading/trailing spaces
    const paymentTypeDescTrimmed = values?.paymentDescription;

    if (!paymentTypeDescTrimmed) {
      schemaValidationErrors.paymentDescription = "Payment Policy is required.";
    }
    // else if (!specialCharPattern.test(paymentTypeDescTrimmed)) {
    //     schemaValidationErrors.paymentDescription =
    //         "No special characters allowed.";
    // }
    else if (paymentTypeDescTrimmed?.trim() !== values?.paymentDescription) {
      schemaValidationErrors.paymentDescription =
        "Payment Policy Description must not contain leading or trailing spaces";
    } else if (paymentTypeDescTrimmed.length > 40) {
      schemaValidationErrors.paymentDescription =
        "Maximum 40 characters allowed.";
    }

    if (Object.keys(schemaValidationErrors).length > 0) {
      setErrors(schemaValidationErrors);
      return;
    }

    // Prepare the payload with the required structure
    const payload = {
      PPDescription: paymentTypeDescTrimmed,
      status: values?.status,
      createdDateTimeUTC: new Date().toISOString(),
      createdByUser: "string",
      modifiedDateTimeUTC: new Date().toISOString(),
      modifiedByUser: "string",
      countryCode: "BRA",
    };

    try {
      setLoading(true);
      const response = await PaymentService.createPayment({ ...payload });
      setLoading(false);

      if (response.status === 201) {
        setSeverity("success");
        setMessage(ADD_SUCCESS_MSG);
        setOpenAlert(true);
        navigate("/paymentPolicies");
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 409) {
        setSeverity("error");
        setMessage(error.response.data.message);
        setOpenAlert(true);
      } else {
        setSeverity("error");
        setMessage(t("snackbarMessage.submissionFailed"));
        setOpenAlert(true);
      }
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    let schemaValidationErrors = {};

    const paymentTypeDescTrimmed = values?.paymentDescription;

    if (!paymentTypeDescTrimmed) {
      schemaValidationErrors.paymentDescription = "Payment Policy is required.";
    }
    else if (paymentTypeDescTrimmed?.trim() !== values?.paymentDescription) {
      schemaValidationErrors.paymentDescription =
        "Payment Policy Description must not contain leading or trailing spaces";
    } else if (paymentTypeDescTrimmed.length > 40) {
      schemaValidationErrors.paymentDescription =
        "Maximum 40 characters allowed.";
    }
    if (Object.keys(schemaValidationErrors).length > 0) {
      setErrors(schemaValidationErrors);
      return;
    }


    if (Object.keys(schemaValidationErrors).length === 0) {
      const payload = {
        PPDescription: values?.paymentDescription,
        status: values?.status,
        createdDateTimeUTC:
          location?.state?.row?.createdDateTimeUTC || new Date().toISOString(),
        createdByUser: location?.state?.row?.createdByUser || "string",
        modifiedDateTimeUTC: new Date().toISOString(),
        modifiedByUser: "string",
        paymentPolicyId: paymentPolicyId,
        countryCode: "BRA",
      };

      try {
        setLoading(true);
        const response = await PaymentService.updatePayment({ ...payload });
        setLoading(false);
        if (response.status === 200) {
          setSeverity("success");
          setMessage(UPDATE_SUCCESS_MSG);
          setOpenAlert(true);
          navigate("/paymentPolicies");
        }
      } catch (error) {
        setLoading(false);
        if (error.response && error.response.status === 409) {
          setSeverity("error");
          setMessage(error.response.data.message);
          setOpenAlert(true);
        } else {
          setSeverity("error");
          setMessage(t("snackbarMessage.updateFailed"));
          setOpenAlert(true);
        }
      }
    }
  };

  return (
    <Layout title={"Payment Policies Registration "} background="#ffffff">
      <Box
        sx={{
          minHeight: "125vh",
          flexGrow: 1,
          paddingBottom: "10rem",
          width: "100%",
          "& .MuiTextField-root": { m: 1 },
          "& .MuiFormLabel-root": {
            color: "rgba(16, 42, 98, 1)",
            "& .MuiFormLabel-asterisk": {
              color: "rgba(239, 0, 0, 1)",
              fontSize: "18px",
            },
          },
        }}
      >
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box sx={{ display: "flex" }}>
            <ArrowDropDownIcon sx={{ marginRight: 1 }} />
            <Typography>{t("form")}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              bgcolor: "#EFEFEF",
              height: "120px",
              marginTop: "-25px",
              borderRadius: "5px",
            }}
          >
            <TextField
              name="paymentDescription"
              label="Payment Policy Description"
              value={values?.paymentDescription}
              placeholder="-"
              required
              error={!!errors.paymentDescription}
              helperText={errors.paymentDescription}
              onChange={handleChange}
              variant="outlined"
              disabled={edit === false}
              sx={{
                width: "80%",
                "& .MuiOutlinedInput-root": {
                  padding: "7px",
                  minHeight: "50px",
                  "&.Mui-disabled": {
                    "& fieldset": {
                      borderColor: "rgba(0, 0, 0, 0)", // Keeps the default disabled border color
                    },
                    "&:hover fieldset": {
                      borderColor: "rgba(0, 0, 0, 0)", // Prevents border from changing color on hover when disabled
                    },
                  },
                },
                "& .MuiInputLabel-root": {
                  lineHeight: "1.2em",
                },
                "& .MuiInputBase-input::placeholder": {
                  opacity: 1,
                },
                "& .MuiFormHelperText-root": {
                  position: "absolute",
                  bottom: "-30px",
                },
              }}
              InputProps={{
                style: { height: "50px" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Box
              sx={{
                ml: "15px",
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <FormControl component="fieldset">
                <FormLabel
                  sx={{
                    color: "#5A646E !important",
                    mb: 2,
                    ml: 0.5,
                    // mr:17,
                    // bgcolor:"red",
                    fontWeight: 500,
                    "&.Mui-focused": { color: "#5A646E" },
                    "&:hover": { color: "#5A646E" },
                  }}
                >
                  {t("status")}
                  <span style={{ color: "red" }}> *</span>
                </FormLabel>
                <FormControlLabel
                  sx={{
                    ml: 0.5,
                    display: "flex",
                    alignItems: "center",
                    gap: "0.4rem",
                  }}
                  control={
                    <RedSwitch
                      sx={{
                        marginRight: "11px",
                      }}
                      checked={values.status}
                      onChange={handleChange}
                      name="status"
                      color="primary"
                      disabled={details || edit === false}
                    />
                  }
                  label={
                    <Box sx={{ width: "3rem", mr: "5px", fontWeight: "500", color: "#737D86" }}>
                      {values.status === true ? t("active") : t("inactive")}
                    </Box>
                  }
                  labelPlacement="start"
                />
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              position: "fixed",
              width: "100%",
              bottom: 0,
              padding: "1rem",
            }}
          >
            <Button
              style={{
                backgroundColor: "#737D86",
                padding: "6px 16px",
                borderRadius: "4px",
                position: "fixed",
                left: "1rem",
                bottom: "1rem",
                marginBottom: "50px",
              }}
              variant="contained"
              onClick={edit == false ? handleDeleteConfirm : handleOpenModal}
              disabled={loading}
              startIcon={<img src={arrowLeft} alt="Arrow" />}
            >
              {loading && <CircularProgress size={20} />}
              {t("cancel")}
            </Button>
            <Modal
              open={open}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "7px",
                  width: "585px",
                  margin: "auto",
                  mt: 25,
                  height: "200px",
                }}
              >
                <Box
                  sx={{
                    background: "#102A62",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                  }}
                >
                  <Typography
                    sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
                  >
                    {t("cancelModal.heading")}
                  </Typography>
                </Box>
                <Box sx={{ p: 2 }}>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, fontWeight: 400 }}
                  >
                                        {t("cancelModal.sure")}

                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                    mb: 1,
                  }}
                >
                  <Button
                    onClick={handleCloseModal}
                    sx={{
                      background: "#737D86",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#737D86" },
                    }}
                  >
                    {t("no")}
                  </Button>
                  <Button
                    onClick={handleDeleteConfirm}
                    sx={{
                      background: "#004F9F",
                      width: "100px",
                      color: "#ffffff",
                      "&:hover": { background: "#004F9F" },
                    }}
                  >
                                        {t("yes")}

                  </Button>
                </Box>
              </Box>
            </Modal>
            {edit != false && (
              <Button
                sx={{
                  padding: "6px 16px",
                  borderRadius: "4px",
                  position: "fixed", // Fix the button
                  right: "1rem", // Aligns to the right bottom corner
                  bottom: "1.5rem", // Position it at the bottom
                  marginBottom: "40px",
                  backgroundColor: "#EF7D00",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#EF7D00",
                  },
                }}
                disabled={loading}
                startIcon={<img src={Save} alt="Right Arrow" />}
                onClick={!edit ? handleSubmit : handleUpdate}
                variant="contained"
              >
                {loading && <CircularProgress size={20} />}
                {t("save")}
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </Layout>
  );
};

export default AddPaymentPolicies;
