import { http } from "../http-common";

const getCountryList = () => {
  return http.get(`/countries`);
};

const createCropRegistration = (data) => {
  return http.post("/crop-stage", data);
};

const updateCropStageRegistration = (data) => {
  return http.put(`/crop-stage`, data);
};

const getCropStage = () => {
  return http.get(`/crop-stage`);
};

const changeSequence = (data) => {
  return http.put(`/crop-stage/sequence`,data);
};

const CropStageService = {
  getCountryList,
  createCropRegistration,
  updateCropStageRegistration,
  getCropStage,
  changeSequence
};

export default CropStageService;
