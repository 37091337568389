import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import TobaccoTable from "./TobaccoTable";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TobaccoService from "services/TobaccoService";
import debounce from "lodash/debounce";
import Lable from "utils/lables.json";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";


const GetTobaccoCompetitorsRegistration = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [tobaccoData, setTobaccoData] = useState([]);
  const [filteredTobaccoData, setFilteredTobaccoData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  // const [itemsPerPage, setItemsPerPage] = useState(5);
  const [itemsPerPage, setItemsPerPage] = useState(() => {
    return parseInt(localStorage.getItem("IPPTC")) || 5;
  });
  const [totalItems, setTotalItems] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [loss, setLossKeyword] = useState("");
  const [cellKeyword, setCellKeyword] = useState("");
  const [focused, setFocused] = useState(false);
  const [menu, setMenu] = useState([]);
  const [countryMenu, setCountryMenu] = useState([]);
  const [regionMenu, setRegionMenu] = useState([]);
  const [statusFilter, setStatusFilter] = useState("pl");

  const debouncedSearch = useCallback(
    debounce((value) => {
      //callSearchPlantingAPI(0, itemsPerPage, value); // Reset to page 0 for new search
    }, 300),
    [itemsPerPage]
  );

  const handleSearchChange = (value) => {
    console.log("cllll");
    setSearchKeyword(value);
  };
  const handleLossChange = (value) => {
    console.log("cllll");
    setLossKeyword(value);
  };
  const handleCellsChange = (value) => {
    console.log("cllll");
    setCellKeyword(value);
  };
  const handleStatusChange = (status) => {
    setStatusFilter(status); // Update the status filter
    setCurrentPage(0); // Reset to the first page when the status changes

    const isActive =
      status === "Active" ? true : status === "Inactive" ? false : null;

    callSearchTobaccoAPI(
      0,
      itemsPerPage,
      searchKeyword,
      cellKeyword,
      loss,
      isActive
    );
  };

  const callTobaccoAPI = (pageNumber, size) => {
    console.log("7654kjhgf", pageNumber, size);
    setLoading(true);
    TobaccoService.getTobacco({ pageNumber, size })
      .then((res) => {
        const tobaccoResults = res?.data?.response?.tobaccoCompaniesCompetitors || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setTobaccoData(tobaccoResults);
        setFilteredTobaccoData(tobaccoResults);
        setTotalItems(res?.data?.response?.totalItems || 0);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setTobaccoData([]);
        setFilteredTobaccoData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getfullTobaccoMenuAPI = () => {
    setLoading(true);
    TobaccoService.getTobacco({ pageNumber: currentPage, size: 50 })
      .then((res) => {
        console.log("ress", res);
        const tobaccoResults = res?.data?.response?.tobaccoCompaniesCompetitors || [];
        // const countryCode = tobaccoResults.map((e) => e.tccCountryCode || []);
        const countryCode = [...new Set(tobaccoResults.map((e) => e.tccCountryCode || []))];
        const menudes = tobaccoResults.map((e) => e.tobaccoCompanyName || []);
        // const region = tobaccoResults.map((e) => e.region || []);
        const region = [...new Set(tobaccoResults.map((e) => e.region || []))];
        setMenu(menudes);
        setCountryMenu(countryCode);
        setRegionMenu(region);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setTobaccoData([]);
        setFilteredTobaccoData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const callSearchTobaccoAPI = (pageNumber, size, Keyword, cnt, reg, status) => {
    setLoading(true);
    setSearchKeyword(Keyword);

    let query = {
      pageNumber,
      size,
      tobaccoCompanyName: Keyword,
      tccCountryCode: cnt,
      region: reg,
    };

    const params = [];

    // Conditionally add query parameters if they exist in 'query'
    if (query?.pageNumber !== undefined) {
      params.push(`pageNumber=${query.pageNumber}`);
    }
    if (query?.size !== undefined) {
      params.push(`pageSize=${query.size}`);
    }
    if (query?.tobaccoCompanyName) {
      params.push(`tobaccoCompanyName=${query.tobaccoCompanyName}`);
    }
    if (query?.tccCountryCode) {
      params.push(`tccCountryCode=${query.tccCountryCode}`);
    }
    if (query?.region) {
      params.push(`region=${query.region}`);
    }
    if (status !== null && status !== undefined) {
      params.push(`status=${status}`);
    }

    // Join the parameters into a single query string
    const queryString = params.length ? `?${params.join("&")}` : "";


    // Only add the status filter if it's defined (true or false)
    // if (status !== null && status !== undefined) {
    //   query.status = status;
    // }

    TobaccoService.searchTobacco(queryString)
      .then((res) => {
        const Results = res?.data?.response?.tobaccoCompaniesCompetitors || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setTobaccoData(Results);
        setFilteredTobaccoData(Results);
        setTotalItems(res?.data?.response?.totalItems || 0);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setTobaccoData([]);
        setFilteredTobaccoData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // callTobaccoAPI(currentPage, itemsPerPage); // Fetch default data
    console.log("cp", currentPage);
    if (searchKeyword == "" && statusFilter == "" && cellKeyword == "" && loss == "") {
      callTobaccoAPI(currentPage, itemsPerPage);
    }
    else {
      const isActive =
        statusFilter === "Active" ? true : statusFilter === "Inactive" ? false : null;
      callSearchTobaccoAPI(currentPage, itemsPerPage, searchKeyword, cellKeyword, loss, isActive)
    }
    if (currentPage <= 0) {
      getfullTobaccoMenuAPI();
    }
  }, [currentPage, itemsPerPage]);

  const paginate = (pageNumber, itemsPerPage) => {
    // setCurrentPage(pageNumber - 1); // Adjust for 0-based index
    // if (searchKeyword) {
    //   callSearchTobaccoAPI(pageNumber - 1, itemsPerPage, searchKeyword); // Fetch with search keyword
    // } else {
    //   callTobaccoAPI(pageNumber - 1, itemsPerPage); // Fetch default data
    // }
    localStorage.setItem("IPPTC", itemsPerPage);
    setCurrentPage(pageNumber - 1);
    if (searchKeyword || statusFilter || cellKeyword || loss) {
      const isActive =
        statusFilter === "Active" ? true : statusFilter === "Inactive" ? false : null;
      // console.log("SEARCH-",searchKeyword,"country-",cellKeyword,"region-",loss,"status-",isActive)
      callSearchTobaccoAPI(pageNumber - 1, itemsPerPage, searchKeyword, cellKeyword, loss, isActive);
    } else {
      callTobaccoAPI(pageNumber - 1, itemsPerPage);
    }
  };

  const refreshData = () => {
    // Call the API again after an item is deleted
    const totalItemsOnPage = filteredTobaccoData?.length ?? 0;
    const isActive =
      statusFilter === "Active" ? true : statusFilter === "Inactive" ? false : null;
    if (searchKeyword === "" && isActive === null && cellKeyword == "" && loss == "") {
      console.log("NO FILTERS APPLIED")
      if (totalItemsOnPage === 1 && currentPage > 0) {
        // If we're on the last item of the page, move back a page if possible
        callTobaccoAPI(currentPage - 1, itemsPerPage);
      } else if (totalItemsOnPage === 1 && currentPage == 0) {
        setFilteredTobaccoData([]);
      } else {
        // Otherwise, refresh the current page
        callTobaccoAPI(currentPage, itemsPerPage);
      }
    }
    else {
      console.log("FILTERS APPLIED", searchKeyword, statusFilter, cellKeyword, loss)
      if (totalItemsOnPage === 1 && currentPage > 0) {
        callSearchTobaccoAPI(currentPage - 1, itemsPerPage, searchKeyword, cellKeyword, loss, isActive);
      } else if (totalItemsOnPage === 1 && currentPage == 0) {
        setFilteredTobaccoData([]);
        callSearchTobaccoAPI(currentPage, itemsPerPage, searchKeyword, cellKeyword, loss, isActive);
      } else {
        callSearchTobaccoAPI(currentPage, itemsPerPage, searchKeyword, cellKeyword, loss, isActive);
      }
    }
  };

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={t("tobaccoCompetitors.pageTitle")}
      background="#ffffff"
      useNewPagination={true}
    >
      <Box
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          padding: "1rem",
          height: "110px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <ArrowDropDownIcon sx={{ marginRight: 1, marginBottom: 1.1 }} />
          <Typography>{t("filtersLable")}</Typography>
        </Box>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "80%", display: "flex", gap: "14px" }}>
            {/* 
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        > */}
            <FormControl sx={{ minWidth: "22rem" }}>
              <Autocomplete
                disablePortal
                onChange={(event, newValue) => {
                  if (newValue) {
                    setSearchKeyword(newValue);
                    callSearchTobaccoAPI(
                      0,
                      itemsPerPage,
                      newValue,
                      cellKeyword,
                      loss
                    );
                  } else {
                    setSearchKeyword("");
                    callSearchTobaccoAPI(0, itemsPerPage, "", cellKeyword, loss);
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  if (newInputValue) handleSearchChange(newInputValue);
                  else getfullTobaccoMenuAPI();
                }}
                options={menu}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "7px", // Adjust padding for the input content
                    minHeight: "50px", // Adjust the overall height
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1.2em", // Adjust label spacing if needed
                  },
                }}
                InputProps={{
                  style: { height: "50px" }, // Directly setting the height
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="companyName"
                    label={t("tobaccoCompetitors.comapanyName")}
                    fullWidth
                  />
                )}
              />
            </FormControl>

            <FormControl sx={{ minWidth: "12rem" }}>
              <Autocomplete
                disablePortal
                onChange={(event, newValue) => {
                  console.log("called change", newValue);
                  if (newValue) {
                    setCellKeyword(newValue);
                    callSearchTobaccoAPI(
                      0,
                      itemsPerPage,
                      searchKeyword,
                      newValue,
                      loss
                    );
                  } else {
                    setCellKeyword("");
                    callSearchTobaccoAPI(0, itemsPerPage, searchKeyword, "", loss);
                    // callTrayAPI(currentPage, itemsPerPage);
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  if (newInputValue) handleCellsChange(newInputValue);
                  else getfullTobaccoMenuAPI();
                }}
                options={countryMenu}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "7px", // Adjust padding for the input content
                    minHeight: "50px", // Adjust the overall height
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1.2em", // Adjust label spacing if needed
                  },
                }}
                InputProps={{
                  style: { height: "50px" }, // Directly setting the height
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="country"
                    label={t("tobaccoCompetitors.country")}
                    fullWidth
                    placeholder={t("selectoption")}
                    InputLabelProps={{
                      shrink: true
                    }}
                    sx={{
                      '& .MuiInputBase-input::placeholder': {
                        opacity: 1,
                        textOverflow: 'ellipsis',
                      },
                    }}
                  />
                )}
              />
            </FormControl>

            <FormControl sx={{ minWidth: "10rem" }}>
              <Autocomplete
                disablePortal
                onChange={(event, newValue) => {
                  // console.log("called change", newValue);
                  if (newValue) {
                    setLossKeyword(newValue);
                    callSearchTobaccoAPI(
                      0,
                      itemsPerPage,
                      searchKeyword,
                      cellKeyword,
                      newValue
                    );
                  } else {
                    setLossKeyword("");
                    callSearchTobaccoAPI(
                      0,
                      itemsPerPage,
                      searchKeyword,
                      cellKeyword,
                      ""
                    );
                    // callTrayAPI(currentPage, itemsPerPage);
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  if (newInputValue) handleLossChange(newInputValue);
                  else getfullTobaccoMenuAPI();
                }}
                options={regionMenu}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "7px", // Adjust padding for the input content
                    minHeight: "50px", // Adjust the overall height
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1.2em", // Adjust label spacing if needed
                  },
                }}
                InputProps={{
                  style: { height: "50px" }, // Directly setting the height
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="region"
                    label={t("tobaccoCompetitors.region")}
                    fullWidth
                    placeholder={t("selectoption")}
                    InputLabelProps={{
                      shrink: true
                    }}
                    sx={{
                      '& .MuiInputBase-input::placeholder': {
                        opacity: 1,
                      },
                    }}
                  />
                )}
              />
            </FormControl>

            <FormControl sx={{ minWidth: "7rem", marginRight: "70px" }}>
              <TextField
                select
                name="status"
                label={t("status")}
                value={statusFilter || "pl"}
                onChange={(event) => handleStatusChange(event.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "7px",
                    minHeight: "50px",
                    width: "10rem",
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1.2em",
                  },
                }}
                InputProps={{
                  style: { height: "50px" },
                }}
                SelectProps={{
                  endAdornment: statusFilter !== "pl" && (
                    <CloseIcon
                      onClick={() => handleStatusChange("pl")}
                      style={{
                        width: "18px",
                        cursor: "pointer",
                        marginRight: "15px",
                      }}
                    />
                  ),
                }}

              >
                <MenuItem value="pl">{t("selectoption")}</MenuItem>
                {/* <MenuItem value="">
                  <em>None</em>
                </MenuItem> */}
                <MenuItem value="Active">{t("active")}</MenuItem>
                <MenuItem value="Inactive">{t("inactive")}</MenuItem>
              </TextField>
            </FormControl>

            {/* </Box> */}
          </div>
          <div>
            <Button
              sx={{
                borderRadius: 1,
                minWidth: 95,
                minHeight: 44,
                background: "#004F9F",
                //marginRight: "10px",
                //marginLeft:"20px"
              }}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => navigate("/addtobaccoCompetitorsRegistration")}
            >
              {t("addNew")}
            </Button>
          </div>
        </div>
      </Box>

      {filteredTobaccoData.length === 0 ? (
        <Typography variant="h6" align="center" color="textSecondary">
          {t("noDataAvailable")}
        </Typography>
      ) : (
        < TobaccoTable
          tobaccoData={filteredTobaccoData}
          refreshData={refreshData}
        />
      )}
    </Layout>
  );
};

export default GetTobaccoCompetitorsRegistration;
