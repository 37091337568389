import { httpGrow } from "../http-common";
const getCuring = (query) => {
  return httpGrow.get(
    `/curingunit?pageNumber=${query.pageNumber}&pageSize=${query.size}&countryCode=BRA`
  );
};
const getCuringUniqueCode = () => {
  return httpGrow.get(`/curingunit/CuringCode`);
};

const getCuringById = ({ id }) => {
  return httpGrow.get(
    `culture/{cuCultureId}?cuCultureId=${id}&pageNumber=0&pageSize=10`
  );
};

const updateCuring = (data) => {
  return httpGrow.put(`/curingunit`, data);
};

const createCuring = (data) => {
  return httpGrow.post(`/curingunit`, data);
};
const searchCuring = ({ searchParams, page, size }) => {
  const params = {
    ...searchParams,
    pageNumber: page,
    pageSize: size,
  };
  // const filteredEntries = Object.entries(params).filter(([key, value]) => value != "");
  // let newParams = Object.fromEntries(filteredEntries);
  // newParams['pageNumber'] = params.pageNumber
  return httpGrow.get("/curingunit/search?", { params });
};
const searchCuringUnitName = ({
  tobaccoGroup,
  status,
  isNew,
  countryCode,
  curingUnitName,
  page,
  size,
}) => {
  const params = {
    tobaccoGroup,
    status,
    isNew,
    countryCode,
    curingUnitName,
    pageNumber: page,
    pageSize: size,
  };
  const filteredEntries = Object.entries(params).filter(
    ([key, value]) => value != ""
  );
  let newParams = Object.fromEntries(filteredEntries);
  newParams["pageNumber"] = params.pageNumber;
  return httpGrow.get("/curingunit/search", { params: newParams });
};
const getAllCuringData = (countryCode) => {
  return httpGrow.get(`curingunit/allCuringUnits?countryCode=${countryCode}`);
};

const CuringService = {
  getCuring,
  getCuringUniqueCode,
  createCuring,
  getAllCuringData,
  getCuringById,
  searchCuringUnitName,
  updateCuring,
  searchCuring,
};
export default CuringService;
