import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  Switch,
  CircularProgress,
  Typography,
  Modal,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Radio,
  RadioGroup,
  Divider,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Layout from "components/structure/Layout";
import { soilParameterSchema } from "utils/validations";
import CuringService from "services/CuringService";
import CultureGroupService from "services/CultureGroup";
import { SnackbarContext } from "context/snackBarContext";
import arrowRight from "assets/arrowRight.svg";
import arrowLeft from "assets/arrowLeft.svg";
import RedSwitch from "utils/RedSwitch";
import PlantingService from "services/PlantingService";
import Save from "assets/SaveNew.svg";
import TobaccoService from "services/TobaccoService";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import Lable from "utils/lables.json";
import SoilParameterService from "services/SoilParameterService";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import { useTranslation } from "react-i18next";
import TransporterGetService from "services/TransporterGetService";
import TransporterRegistrationService from "services/TransporterRegistrationService";

export default function Areas() {
  const navigate = useNavigate();
  const location = useLocation();
  const [areas, setareas] = useState([
    {
      id: Date.now(),
      uID: "",
      linkedAreasId: "",
      name: "",
    },
  ]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hierarchicalNode, setHierarchicalNode] = useState([]);

  const { row, edit } = location.state || {};
  console.log("hierarchicalNode", row);

  const { t } = useTranslation();
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [transporterData, setTransporterData] = useState(null);

  const [rows, setRows] = useState([
    {
      id: Date.now(),
      licensePlate: "",
      regionId: "",
      vehicleType: "",
      vehicleAxles: "",
      vehicleCapacity: "",
      vehicleEnvironmentalAgency: false,
      leasedVehicle: false,
      vechiclesListId: "",
    },
  ]);

  useEffect(() => {
    if (transporterData?.linkedAreasList) {
      console.log("Linked Areas Data:", transporterData.linkedAreasList);
      console.log("Hierarchical Node Data:", hierarchicalNode);

      const addNameToAreas = (areasData) => {
        return areasData.map((area) => ({
          id: area.linkedAreasId || Date.now(),
          linkedAreasId: area.linkedAreasId || "",
          uID: area.uID || "",
          name:
            hierarchicalNode.find((node) => node.userId === area.uID)
              ?.userName || "",
        }));
      };

      const updatedAreas = addNameToAreas(transporterData.linkedAreasList);
      console.log("Updated Areas:", updatedAreas);
      setareas(updatedAreas);
    }
  }, [transporterData, hierarchicalNode]);

  useEffect(() => {
    console.log("aaaaaaaaaaaa");
    fetchTransporterData();
  }, [location?.state?.row?.transporterID]);

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  // Function when user confirms "Yes" on the modal
  const handleDeleteConfirm = () => {
    setOpen(false);
    navigate("/transporterRegistration");
  };

  const handleChange = (event, rowId, realID) => {
    const selectedUserId = event.target.value;
    const selectedNode = hierarchicalNode.find(node => node.userId === selectedUserId);
    
    setareas((prev) =>
      prev.map((row) =>
        (realID ? row.linkedAreasId === realID : row.id === rowId)
          ? {
              ...row,
              uID: selectedUserId,
              name: selectedNode?.userName || ""
            }
          : row
      )
    );
  };

  const handleAddArea = () => {
    setareas([...areas, { id: areas.length + 1 }]);
  };

  const handleDeleteArea = (areaId) => {
    setareas(areas.filter((area) => area.id !== areaId));
  };

  const getHierarchicalNodes = async () => {
    try {
      const response = await TransporterGetService.getFTResponsible();
      console.log("responseft", response);
      // Transform the object into an array
      const hierarchicalData = Object.values(response.data.response).map(item => ({
        userId: item.userId,
        userName: item.userName
      }));
      setHierarchicalNode(hierarchicalData);
    } catch (error) {
      console.error("Error generating unique code:", error);
      return null;
    }
  };

  useEffect(() => {
    getHierarchicalNodes();
  }, []);

  const handleUpdate = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      console.log("aa rha hai");
      // Convert boolean strings to actual booleans
      const ciotRequirementBool = transporterData?.cioTRequirement === true;
      const tallVoucherBool = transporterData?.tallVoucher === true;

      // Format partners list if it exists
      const formattedPartners =
        transporterData?.partnerListList?.map((partner) => ({
          createdByUser: "Mayur",
          createdDateTimeUTC: new Date().toISOString(),
          modifiedByUser: "Mayur",
          modifiedDateTimeUTC: new Date().toISOString(),
          plName: partner.plName,
          plFiscalID: partner.plFiscalID,
          plCardId: partner.plCardId,
          plDOB: partner.plDOB,
          plEmail: partner.plEmail,
          plPhoneNumber: partner.plPhoneNumber,
        })) || [];

      // Transform rows data into the required vehicles format
      const vehiclesPayload = rows.map((row) => ({
        createdByUser: "Mayur",
        createdDateTimeUTC: new Date().toISOString(),
        modifiedByUser: "Mayur",
        modifiedDateTimeUTC: new Date().toISOString(),
        licensePlate: row.licensePlate,
        vehicleType: row.vehicleType,
        regionId: row.regionId,
        leasedVehicle: row.leased,
        vehicleAxles: Number(row.vehicleAxles),
        vehicleCapacity: Number(row.vehicleCapacity),
        vehicleEnvironmentalAgency: row.environmentalAgency,
        vechiclesListId: row.vechiclesListId,
      }));
      const Dummyarea = {
        createdByUser: "RAMESH",
        createdDateTimeUTC: "2024-10-24T12:00:31.410Z",
        modifiedByUser: "RAMESH",
        modifiedDateTimeUTC: "2024-10-24T12:00:31.410Z",
      };

      const createAreaPayload = () => {
        const payload = areas.map((area) => {
          const basePayload = {
            ...Dummyarea,
            uID: area.uID,
          };

          // Only add linkedAreasId if it exists (for existing areas)
          if (area.linkedAreasId) {
            return {
              ...basePayload,
              linkedAreasId: area.linkedAreasId
            };
          }

          // For new areas, return payload without linkedAreasId
          return basePayload;
        });
        
        return payload;
      };

      // Example Usage
      const Areapayload = createAreaPayload();
      // const

      // Combine all data into final payload
      const payload = {
        // Metadata
        createdDateTimeUTC: new Date().toISOString(),
        createdByUser: "Mayur",
        modifiedDateTimeUTC: new Date().toISOString(),
        modifiedByUser: "Mayur",

        fiscalID: transporterData.fiscalID,

        // Business Information
        businessType: transporterData.businessType,
        businessNumber: transporterData.businessNumber,
        transporterID: location.state.row.transporterID,
        transporterName: transporterData.transporterName,
        nationalityId: transporterData.nationalityId,
        maritalStatusId: transporterData.maritalStatusId,

        // Route Details
        buyingStation: transporterData.buyingStation,
        buyingStationRoute: transporterData.buyingStationRoute,
        supplyInvoicingUnit: transporterData.supplyInvoicingUnit,
        supplyInvoicingUnitRoute: transporterData.supplyInvoicingUnitRoute,

        // Bank Information
        bankAccountType: transporterData.bankAccountType,
        bankRegistrationId: transporterData.bankRegistrationId,
        bankBranchRegistrationID: transporterData.bankBranchRegistrationID,
        accountNumber: transporterData.accountNumber,

        // Responsible Users
        ftResponsibleUID: transporterData.ftResponsibleUID,
        tmResponsibleUID: transporterData.tmResponsibleUID,

        // Address Information
        address: transporterData.address,
        neighborhood: transporterData.neighborhood,
        postalCode: transporterData.postalCode,
        regionId: transporterData.regionId,

        // Company Information
        businessSize: transporterData.businessSize,
        cioTRequirement: ciotRequirementBool,
        companyEstablishmentDate: transporterData.companyEstablishmentDate,
        coupaRegisterNumber: transporterData.coupaRegisterNumber,
        emailForInvoices: transporterData.emailForInvoices,
        expirationDateCoupa: transporterData.expirationDateCoupa,
        externalCode: transporterData.externalCode,
        fieldTechnicianUser: transporterData.fieldTechnicianUser,

        // Tax and Legal Information
        icmsTaxPercentage: transporterData.icmsTaxPercentage || 0,
        legalName: transporterData.legalName,
        legalNatureCode: transporterData.legalNatureCode,
        mainEconomicActivityCode: transporterData.mainEconomicActivityCode,
        secondaryEconomicActivityCode:
          transporterData.secondaryEconomicActivityCode,
        sapVendorCode: transporterData.sapVendorCode,
        stateRegistrationNumber: transporterData.stateRegistrationNumber,
        tallVoucher: tallVoucherBool,
        taxation: transporterData.taxation,
        tradeName: transporterData.tradeName,
        transporterLicenseNumber: transporterData.transporterLicenseNumber,

        // Status from control data (if available)
        status: transporterData.status,
        statusReason: transporterData.statusReason,
        attachedDocumentPath: transporterData.attachedDocumentPath,

        // Partners List
        partnerListList: formattedPartners,

        // Vehicles List (from current component)
        vehicleList: vehiclesPayload,
        linkedAreasList: Areapayload,
      };

      const response = await TransporterRegistrationService.updateTransporter(
        payload
      );

      setLoading(false);
      setSeverity("success");
      setMessage("Vehicles updated successfully");
      setOpenAlert(true);
      navigate("/transporterRegistration");
    } catch (error) {
      setLoading(false);
      setSeverity("error");
      setMessage(error.response?.data?.message || t("snackbarMessage.updateFailed"));
      setOpenAlert(true);
      console.error("Failed to update vehicles:", error);
    }
  };

  const fetchTransporterData = async () => {
    if (row?.transporterID) {
      try {
        const response = await TransporterRegistrationService.getTransporter(row.transporterID);
        const data = response.data.response;
        console.log("Fetched transporter data:", data);
        setTransporterData(data);
        
        if (data?.vehicleList && data.vehicleList.length > 0) {
          const formattedVehicles = data.vehicleList.map(vehicle => ({
            id: Date.now(),
            licensePlate: vehicle.licensePlate || "",
            regionId: vehicle.regionId || "",
            vehicleType: vehicle.vehicleType || "",
            vehicleAxles: vehicle.vehicleAxles || "",
            vehicleCapacity: vehicle.vehicleCapacity || "",
            vehicleEnvironmentalAgency: vehicle.vehicleEnvironmentalAgency || false,
            leasedVehicle: vehicle.leasedVehicle || false,
            vechiclesListId: vehicle.vechiclesListId || ""
          }));
          setRows(formattedVehicles);
        }

        if (data?.linkedAreasList) {
          console.log("Linked Areas Data:", data.linkedAreasList);
          console.log("Hierarchical Node Data:", hierarchicalNode);

          const addNameToAreas = (areasData) => {
            return areasData.map((area) => ({
              id: area.linkedAreasId || Date.now(),
              linkedAreasId: area.linkedAreasId || "",
              uID: area.uID || "",
              name: hierarchicalNode.find((node) => node.userId === area.uID)?.userName || "",
            }));
          };

          const updatedAreas = addNameToAreas(data.linkedAreasList);
          console.log("Updated Areas:", updatedAreas);
          setareas(updatedAreas);
        }
      } catch (error) {
        console.error("Error fetching transporter data:", error);
        setSeverity("error");
        setMessage("Failed to fetch transporter details");
        setOpenAlert(true);
      }
    }
  };

  useEffect(() => {
    fetchTransporterData();
  }, [location?.state?.row?.transporterID]);

  console.log("area", areas);

  //   useEffect(() => {
  // // Update the state with the processed areas
  //     console.log("fds",areas);
  //   }, [areas]);

  return (
    <Box sx={{paddingBottom:"100px"}}>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px 2px",
            background: "#FFFFFF",
            marginTop: 3,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "20px",
              color: "#5A646E",
              marginLeft: "10px",
            }}
          >
            {t("TransportationRegistration.sections.linkedAreas")}
          </Typography>
          {edit &&
          <Button
            variant="contained"
            onClick={handleAddArea}
            sx={{
              minWidth: 95,
              borderRadius: 1,
              background: "#004F9F",
              height: { xs: "40px", md: "44px" },
              marginLeft: { xs: 0, md: "auto" },
              marginRight: "10px",
            }}
          >
            {t("TransportationRegistration.buttons.addNew")}
          </Button>
          }
        </Box>
        <Box
          id="divider"
          sx={{
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0px 20px 0px",
            gap: "20px",
            background: "#FFFFFF",
            borderBottom: "5px solid #004F9F",
          }}
        ></Box>
      </Box>
      {areas.map((area) => (
        <Box
          key={area.id}
          sx={{
            minHeight: "10%",
            marginTop: 5,
            flexGrow: 1,
            paddingBottom: "0.5rem",
            width: "100%",
            "& .MuiTextField-root": { m: 1 },
            "& .MuiFormLabel-root": {
              color: "rgba(16, 42, 98, 1)",
              "& .MuiFormLabel-asterisk": {
                color: "rgba(239, 0, 0, 1)",
                fontSize: "18px",
              },
            },
          }}
        >
          <form autoComplete="off" noValidate>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                bgcolor: "#EFEFEF",
                height: "120px",
                marginTop: "-25px",
                borderRadius: "5px",
                paddingRight: "10px",
              }}
            >
              <TextField
                name="uID"
                label={t(
                  "TransportationRegistration.formLabels.hierarchicalNode"
                )}
                variant="outlined"
                select
                SelectProps={{ IconComponent: ArrowDropDownIcon }}
                onChange={(e) => handleChange(e, area.id, area.linkedAreasId)}
                value={area.uID || ""}
                sx={{
                  width: "85%",
                  "& .MuiOutlinedInput-root": {
                    padding: "7px",
                    minHeight: "50px",
                      "&.Mui-disabled": {
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 0)", // Default disabled border color
                          "&:hover": {
                            borderColor: "rgba(0, 0, 0, 0)", // Prevent hover effect
                          },
                        },
                      },
                    
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1.2em",
                    marginLeft: "10px",
                  },
                }}
              >
                {hierarchicalNode.map((item) => (
                  <MenuItem key={item.userId} value={item.userId}>
                    {item.userName}
                  </MenuItem>
                ))}
              </TextField>
              {areas.length > 1 && (
                <img
                  src={_ButtonDelete_}
                  alt="Delete"
                  style={{ cursor: "pointer", marginRight: 5 }}
                  onClick={() => handleDeleteArea(area.id)}
                />
              )}
            </Box>

            <Divider
              sx={{
                position: "fixed",
                bottom: "82px",
                width: "97%",
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                position: "fixed",
                width: "96%",
                bottom: 6,
                padding: "0.5rem",
                background:"white",
                zIndex:999
              }}
            >
              {/* Cancel Button - Left bottom corner */}
              <Button
                style={{
                  backgroundColor: "#737D86",
                  padding: "6px 16px",
                  borderRadius: "4px",
                  marginBottom: "16px",
                  height: "44px",
                  marginLeft: "10px",
                }}
                variant="contained"
                onClick={edit == false ? handleDeleteConfirm : handleOpenModal}
                disabled={loading}
                startIcon={<img src={arrowLeft} alt="Arrow" />}
              >
                {loading && <CircularProgress size={20} />}
                {edit == false ? t("back") : t("cancel")}
              </Button>


              <Modal
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "7px",
                    width: "585px",
                    margin: "auto",
                    mt: 25,
                    height: "200px",
                  }}
                >
                  <Box
                    sx={{
                      background: "#102A62",
                      height: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderTopLeftRadius: "7px",
                      borderTopRightRadius: "7px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#ffffff",
                        fontSize: "20px",
                        fontWeight: 500,
                      }}
                    >
                      {t("cancelTitle")}
                    </Typography>
                  </Box>
                  <Box sx={{ p: 2 }}>
                    <Typography
                      id="modal-modal-description"
                      sx={{ mt: 2, fontWeight: 400 }}
                    >
                      {t("cancelMessage")}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "20px",
                      mb: 1,
                    }}
                  >
                    <Button
                      onClick={handleCloseModal}
                      sx={{
                        background: "#737D86",
                        width: "100px",
                        color: "#ffffff",
                        "&:hover": { background: "#737D86" },
                      }}
                    >
                      {t("no")}
                    </Button>
                    <Button
                      onClick={handleDeleteConfirm}
                      sx={{
                        background: "#004F9F",
                        width: "100px",
                        color: "#ffffff",
                        "&:hover": { background: "#004F9F" },
                      }}
                    >
                      {t("yes")}
                    </Button>
                  </Box>
                </Box>
              </Modal>

              {/* Save Button - Right bottom corner */}
              {edit != false && (
                <Button
                  sx={{
                    padding: "6px 16px",
                    borderRadius: "4px",
                    marginBottom: "16px",
                    backgroundColor: "#EF7D00",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#EF7D00",
                    },
                    marginRight: "10px",
                  }}
                  disabled={loading}
                  startIcon={<img src={Save} alt="Right Arrow" />}
                  variant="contained"
                  onClick={handleUpdate}
                >
                  {loading && <CircularProgress size={20} />}
                  {t("save")}
                </Button>
              )}
            </Box>
          </form>
        </Box>
      ))}
    </Box>
  );
}
