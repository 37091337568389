import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import { addDependentField, addPropertyField } from "./FormConfig";
import { useState } from "react";
import RenderField from "utils/RenderField";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { dependantSchema, propertySchema } from "utils/validations";
import { updateProperty } from "slices/propertyAddSlice";
import { TreeView } from "@mui/x-tree-view";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import arrowDown from "assets/arrowDown.svg";
import { StyledTreeItem } from "utils/Styledtreeitem";
import { v4 as uuidv4 } from "uuid";
import { updateDependant } from "slices/dependantSlice";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "90%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "#f8f8f8",
  border: "none",
  overflow: "none",
};

export default function MinorRegistrationModal(props) {
  const dispatch = useDispatch();
  const depedantInformation = useSelector(
    (state) => state?.dependant?.addDependant
  );
  const [values, setValues] = useState({});
  console.log("🚀 ~ PropertyRegisterationModal ~ values:", values);
  const [errors, setErrors] = useState({});
  console.log("🚀 ~ MinorRegistrationModal ~ errors:", errors)
  const [dateError, setDateError] = useState(false);
  const [id, setId] = React.useState("");
  const [edit, setEdit] = useState(false);
  console.log("🚀 ~ MinorRegistrationModal ~ edit:", edit);
  console.log(
    "🚀 ~ GeneralInformation ~ depedantInformation:",
    depedantInformation
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    if (!date || date === "Invalid Date") {
      setDateError(true);
      setValues((prevValues) => ({
        ...prevValues,
        dateOfBirth: "",
      }));
      return;
    }

    setDateError(false);

    const dateValue = new Date(date);
    const year = dateValue.getFullYear();
    const month = String(dateValue.getMonth() + 1).padStart(2, "0");
    const day = String(dateValue.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setValues((prevValues) => ({
      ...prevValues,
      dateOfBirth: formattedDate,
    }));
  };

  useEffect(() => {
    if (props.editData) {
      setValues(props.editData);
      setEdit(true);
    } else {
      setValues({});
      setEdit(false);
    }
  }, [props.editData]);

  useEffect(() => {
    setId(uuidv4());
  }, [props.open]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await dependantSchema.validate(values, { abortEarly: false });
      setErrors({});
      const {
        cyCropYear,
        dependentName,
        dateOfBirth,
        dependentRelationshipType,
        fatherName,
        motherName,
        attendSchool,
        schoolGrade,
        dependentId,
      } = values;
      dispatch(
        updateDependant([
          {
            id,
            dependentId,
            cyCropYear,
            dependentName,
            dateOfBirth,
            dependentRelationshipType,
            fatherName,
            motherName,
            attendSchool,
            schoolGrade,
          },
        ])
      );
      setValues({});
      props.onClose();
      console.log("dispatch Successfully");
    } catch (err) {
      const validationErrors = {};
      err?.inner?.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
    }
  };

  const handleClear = (field) => {
    setValues((prevValues) => ({
      ...prevValues,
      [field]: "",
    }));
  };

  const handleCloseModal = () => {
    setEdit(false);
    setValues({});
    props.onClose();
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => props.onClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            className="property-modal-heading"
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Dependent Registration
          </Typography>
          <Box
            sx={{
              minHeight: 180,
              marginLeft: "0px",
              width: "100%",
              "& .MuiTextField-root": { m: 1, color: "red", width: "25ch" },
              "& .MuiFormLabel-root": {
                color: "rgba(16, 42, 98, 1)",
                "& .MuiFormLabel-asterisk": {
                  color: "rgba(239, 0, 0, 1)",
                  fontSize: "18px",
                },
              },
            }}
          >
            <form
              style={{ padding: "38px" }}
              autoComplete="off"
              noValidate
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              {addDependentField.slice(0, 8).map((field) => (
                <RenderField
                  key={field.name}
                  field={field}
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  handleDateChange={handleDateChange}
                  handleClear={handleClear}
                />
              ))}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                  paddingBottom: "1rem",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#737D86",
                    padding: " 6px, 16px, 6px, 16px",
                    borderRadius: " 4px",
                    top: "25px",
                    left: "20px",
                  }}
                  variant="contained"
                  type="secondary"
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    backgroundColor: "#004F9F",
                    top: "25px",
                    left: "20px",
                  }}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  {!edit ? "Save" : "Edit"} Dependent Registration
                </Button>
              </div>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
