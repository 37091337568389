import { httpGrow } from "../http-common";

const getCuring = async (query) => {
    const response = await httpGrow.get(`/curing-device/list?pageNumber=${query?.pageNumber}&size=${query?.size}`)
    return response;
}

const createCuringDevice = (data) => {
    return httpGrow.post(
        `/curing-device/save-curing-device`, data
    );
};

const updateCuringDevice = (data) => {
    return httpGrow.put(`curing-device/update-curing-device`, data);
};

const searchCuring = async (query) => {
    const response = await httpGrow.get(
        `/curing-device/find?pageNumber=${query?.pageNumber}&size=${query?.size || 50}&cdCuringDeviceName=${query?.keyword || ''}&isActive=${query?.status !== undefined ? query.status : ''}`
    );
    return response;
};

const deleteCuringDevice = (curingDeviceId) => {
    return httpGrow.delete(`/curing-device/${curingDeviceId}`);
  };

const PaymentService = {
    getCuring,
    createCuringDevice,
    updateCuringDevice,
    searchCuring,
    deleteCuringDevice
}

export default PaymentService;