import axios from "axios";
import { DndContext, closestCorners } from "@dnd-kit/core";
import { Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Layout from "components/structure/Layout";
import React, { useContext, useEffect, useState } from "react";
import Column from "utils/Column";
import { useNavigate } from "react-router-dom";
import CropStageService from "services/CropStageService";
import { SnackbarContext } from "context/snackBarContext";

const CropStage = () => {
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const countryArray = tasks.map((coun) => coun.countryDetail);
  const { setOpenAlert, setSeverity, setMessage, handleClose } =
    useContext(SnackbarContext);

  const handleGetCropStages = async () => {
    try {
      setLoading(true);
      const response = await CropStageService.getCropStage();
      setTasks(
        response.data.response.map((cropStage) => ({
          id: cropStage.cropStageUid,
          title: cropStage.cropStageName,
          status: cropStage.isActive,
          countryDetail: cropStage.cropStageCountryDetail,
          modifiedByUser: cropStage.modifiedByUser,
          modifiedDateTimeUTC: cropStage.modifiedDateTimeUTC,
          sequence: cropStage.sequenceId,
          visitCompliance: cropStage.visitCompliance,
        }))
      );
    } catch (error) {
      console.error("Failed to fetch crop stages:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetCropStages();
  }, []);

  const handleDragEnd = async (e) => {
    const { active, over } = e;
    console.log("🚀 ~ handleDragEnd ~ over:", over);
    console.log("🚀 ~ handleDragEnd ~ active:", active);

    if (active?.id === over?.id) return;

    const activeTask = tasks.find((task) => task?.id === active?.id);
    const overTask = tasks.find((task) => task?.id === over?.id);

    const updatedTasks = tasks.map((task) => {
      if (task?.id === active?.id) {
        return { ...task, sequence: overTask?.sequence };
      }
      if (task?.id === over?.id) {
        return { ...task, sequence: activeTask?.sequence };
      }
      return task;
    });

    setTasks(updatedTasks.sort((a, b) => a.sequence - b.sequence));

    try {
      await CropStageService.changeSequence(
        updatedTasks.map((task) => ({
          cropStageUid: task.id,
          sequenceId: task.sequence,
          modifiedByUser: task.modifiedByUser,
        }))
      );
      console.log("Sequence updated successfully.");
    } catch (error) {
      setSeverity("error");
      setMessage("An error occured");
      setOpenAlert(true);
      console.error("Failed to update sequence:", error);
    }
  };

  return (
    <Layout title={"Farmer’s Sustainability Management Portal"}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <div
          style={{
            fontWeight: 600,
            fontSize: "1.25rem",
            color: "rgba(115, 125, 134, 1)",
          }}
        >
          Crop Stage List
        </div>

        <div>
          <Button
            sx={{
              borderRadius: 100,
              minWidth: 95,
              minHeight: 44,
              background: "#102A62",
            }}
            variant="contained"
            startIcon={<AddCircleIcon />}
            onClick={() => navigate("/addCropStage")}
          >
            Add
          </Button>
        </div>
      </div>
      {tasks.length !== 0 && (
        <DndContext
          onDragEnd={handleDragEnd}
          collisionDetection={closestCorners}
        >
          <Column tasks={tasks} countryArray={countryArray} />
        </DndContext>
      )}
    </Layout>
  );
};

export default CropStage;
