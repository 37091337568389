// import React, { useEffect, useState } from "react";
// import { Typography, Box, Button, Menu, MenuItem } from "@mui/material";
// import "./Occurrence.css";
// import EditButton from "../../../../assets/editButton.svg";
import { useNavigate } from "react-router-dom";
// import moreActions from "../../../../assets/moreActions.svg";
// import iconView from "assets/iconView.svg";

import React, { useContext, useEffect, useState } from "react";
import { Typography, Button, Modal, Box, Tooltip } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
// import "./Occurrence.css";
import _ButtonEdit_ from "assets/_ButtonEdit_.svg";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import OccurrenceTypeService from "services/OccurrenceTypeService";
import { SnackbarContext } from "context/snackBarContext";

const OccurrenceTable = (props) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [originalData, setOriginalData] = useState([]);
  const [occurrenceData, setOccurrenceData] = useState([]);

  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  const [open, setOpen] = useState(false); // Modal state
  const [deleteId, setDeleteId] = useState(null); // ID of the record to delete

  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  useEffect(() => {
    setOccurrenceData(props.occurrenceData);
    setOriginalData(props.occurrenceData);
  }, [props.occurrenceData]);

  const handleDeleteClick = (occurrenceId) => {
    setDeleteId(occurrenceId); // Store the ID of the record to delete
    setOpen(true); // Open the confirmation modal
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await OccurrenceTypeService.deleteOccurrece(deleteId); // Delete by stored ID
      if (response.status === 200) {
        setMessage("Deleted successfully");
        setSeverity("success");
        setOpenAlert(true);
        setOccurrenceData((prevData) =>
          prevData.filter((item) => item.occurrenceId !== deleteId)
        );
        props.callOccuranceApi();
      } else {
        setMessage("Failed to delete occurrence type");
        setSeverity("error");
        setOpenAlert(true);
      }
    } catch (error) {
      setMessage("Error deleting occurrence type");
      setSeverity("error");
      setOpenAlert(true);
      console.error("Error deleting occurrence type:", error);
    } finally {
      setOpen(false); // Close the modal after deletion
    }
  };

  const goToView = () => {
    console.log("goToView::");
  };

  const handleClose = () => {
    setOpen(false); // Close the modal without deleting
  };

  const handleSort = (key) => {
    console.log("occurrenceData::", occurrenceData);
    let direction = "asc";
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        direction = "desc";
      } else if (sortConfig.direction === "desc") {
        direction = null;
      }
    }
    setSortConfig({ key, direction });
    if (direction === null) {
      setOccurrenceData(originalData);
    } else {
      const sortedData = [...occurrenceData].sort((a, b) => {
        if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
        if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
        return 0;
      });
      setOccurrenceData(sortedData);
    }
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc")
        return <ArrowUpwardIcon fontSize="small" />;
      else if (sortConfig.direction === "desc")
        return <ArrowDownwardIcon fontSize="small" />;
    }
    return <ArrowDownwardIcon fontSize="small" />;
  };

  return (
    <div className="table-container">
      <table className="custom-table">
        <thead>
          <tr>
            <th
              style={{
                cursor: "pointer",
                display: "flex",
              }}
              onClick={() => handleSort("occurrenceTypeDescription")}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <span>Field Occurrence Type Description</span>
                <span
                  style={{
                    marginTop: "5px",
                    marginLeft: "5px",
                  }}
                >
                  {getSortIcon("occurrenceTypeDescription")}
                </span>
              </div>
            </th>

            <th
              style={{
                cursor: "pointer",
                display: "flex",
              }}
              onClick={() => handleSort("occurrenceCategory")}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <span>Occurrence Category</span>
                <span
                  style={{
                    marginTop: "5px",
                    marginLeft: "5px",
                  }}
                >
                  {getSortIcon("occurrenceCategory")}
                </span>
              </div>
            </th>

            <th
              style={{
                cursor: "pointer",
                display: "flex",
              }}
              onClick={() => handleSort("status")}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <span>Status</span>
                <span
                  style={{
                    marginTop: "5px",
                    marginLeft: "5px",
                  }}
                >
                  {getSortIcon("status")}
                </span>
              </div>
            </th>

            <th className="actions-header">Actions</th>
          </tr>
        </thead>

        <tbody>
          {occurrenceData?.map((item, index) => (
            <tr key={index}>
              <td
                onClick={() =>
                  navigate("/viewOccurrenceType", {
                    state: {
                      occurrenceTypeDescription: item.occurrenceTypeDescription,
                      status: item.status,
                      edit: false,
                      occurrenceId: item.occurrenceId,
                      occurrenceCategory: item.occurrenceCategory,
                      occurrenceImage: item.occurrenceImage,
                      optionHigh: item.optionHigh,
                      optionMedium: item.optionMedium,
                      optionLow: item.optionLow,
                      occurrenceOnSeedBed: item.occurrenceOnSeedBed,
                      occurrenceOnField: item.occurrenceOnField,
                      occurrenceInput: item.occurrenceInput,
                    },
                  })
                }
                style={{
                  textAlign: "left",
                  cursor: "pointer",
                  maxWidth: "40%", // Set this to your desired width percentage
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Typography
                  sx={{
                    justifyContent: "left",
                    fontWeight: 400,
                    color: "#3B454F",
                    display: "inline-block", // Make it inline-block for text overflow to work
                    maxWidth: "100%", // Ensure it respects the td's width
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis"
                  }}
                >
                  {item.occurrenceTypeDescription}
                </Typography>
              </td>

              <td
                onClick={() =>
                  navigate("/viewOccurrenceType", {
                    state: {
                      occurrenceTypeDescription: item.occurrenceTypeDescription,
                      status: item.status,
                      edit: false,
                      occurrenceId: item.occurrenceId,
                      occurrenceCategory: item.occurrenceCategory,
                      occurrenceImage: item.occurrenceImage,
                      optionHigh: item.optionHigh,
                      optionMedium: item.optionMedium,
                      optionLow: item.optionLow,
                      occurrenceOnSeedBed: item.occurrenceOnSeedBed,
                      occurrenceOnField: item.occurrenceOnField,
                      occurrenceInput: item.occurrenceInput,
                    },
                  })
                }
                style={{ textAlign: "left", cursor: "pointer" }}
              >
                <Typography
                  sx={{
                    justifyContent: "left",
                    fontWeight: 400,
                    color: "#3B454F",
                  }}
                >
                  {item.occurrenceCategory}
                </Typography>
              </td>

              <td
                onClick={() =>
                  navigate("/viewOccurrenceType", {
                    state: {
                      occurrenceTypeDescription: item.occurrenceTypeDescription,
                      status: item.status,
                      edit: false,
                      occurrenceId: item.occurrenceId,
                      occurrenceCategory: item.occurrenceCategory,
                      occurrenceImage: item.occurrenceImage,
                      optionHigh: item.optionHigh,
                      optionMedium: item.optionMedium,
                      optionLow: item.optionLow,
                      occurrenceOnSeedBed: item.occurrenceOnSeedBed,
                      occurrenceOnField: item.occurrenceOnField,
                      occurrenceInput: item.occurrenceInput,
                    },
                  })
                }
                style={{
                  textAlign: "left",
                  fontWeight: 400,
                  color: "#3B454F",
                  cursor: "pointer",
                }}
              >
                {item.status ? "Active" : "Inactive"}
              </td>
              <td className="actions-cell">
                <Tooltip
                  title="EDIT"
                  placement="top"
                  arrow
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -5],
                        },
                      },
                    ],
                  }}
                >
                  <img
                    src={_ButtonEdit_}
                    alt="Edit Button"
                    onClick={() =>
                      navigate("/editOccurrenceType", {
                        state: {
                          edit: true,
                          occurrenceTypeDescription:
                            item.occurrenceTypeDescription,
                          status: item.status,
                          occurrenceId: item.occurrenceId,
                          occurrenceCategory: item.occurrenceCategory,
                          occurrenceImage: item.occurrenceImage,
                          optionHigh: item.optionHigh,
                          optionMedium: item.optionMedium,
                          optionLow: item.optionLow,
                          occurrenceOnSeedBed: item.occurrenceOnSeedBed,
                          occurrenceOnField: item.occurrenceOnField,
                          occurrenceInput: item.occurrenceInput,
                        },
                      })
                    }
                  />
                </Tooltip>
                <span style={{ display: "inline-block", width: "6px" }}></span>
                <Tooltip
                  title="DELETE"
                  placement="top"
                  arrow
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -5],
                        },
                      },
                    ],
                  }}
                >
                  <img
                    src={_ButtonDelete_}
                    alt="Delete"
                    onClick={() => handleDeleteClick(item.occurrenceId)}
                  />
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal for delete confirmation */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "520px",
            margin: "auto",
            mt: 25,
            height: "200px",
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
            >
              Delete Confirmation
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, fontWeight: 400 }}
            >
              Are you sure you want to delete this record? Deleted records
              cannot be recovered.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              mb: 1,
            }}
          >
            <Button
              onClick={handleClose}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              No
            </Button>
            <Button
              onClick={handleDeleteConfirm}
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#004F9F" },
              }}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

// }

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 5,
      width: "100%",
    }}
  />
);

export default OccurrenceTable;
