import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CustomButton from 'utils/CustomButton';
import { Input } from '@mui/base';
import { CircularProgress, TextField } from '@mui/material';
import { SnackbarContext } from 'context/snackBarContext';
import { htmlToPlainText } from 'utils/utils';
import './SurityModal2.css'



export default function SurityModal(props) {
    console.log('props', props)
    const [loading, setLoading] = React.useState(false);

    const {
        setOpenAlert,
        setSeverity,
        setMessage,
    } = React.useContext(SnackbarContext);

    const onNext = () => {
        if (props.string == "") {
            setMessage("Please enter level name to proceed")
            setOpenAlert(true)
            setSeverity("error")
        } else {
            props.onNext()
        }
    }

    return (
        <div>
            <Modal
                open={props.open}
                onClose={() => { props.onClose() }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box className="SurityModal2Container">
                    <Box>
                        <Typography style={{ display: "flex", justifyContent: "center", minHeight: "50px", backgroundColor: "#102A62", position: 'absolute', color: "white", textAlign: "center", borderRadius: "12px 12px 0px 0px", top: "0px", width: "100%" }} id="modal-modal-title" variant="h6" component="h2">
                            <span style={{ display: "flex", alignItems: "center" }} >{props?.surityModalHeader}</span>
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, justifyContent: "center", padding: "32px", marginTop: "50px" }}>
                        {/* <Box sx={{ textAlign: "center" }}>
                            <img src={props?.iconName} />
                        </Box> */}
                        <Box sx={{ textAlign: "center" }}>
                            <Typography>{htmlToPlainText(props.message)}</Typography>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                gap: "1rem",
                                justifyContent: "center",
                            }}
                        >
                            <Button
                                variant="contained"
                                disabled={loading}
                                onClick={() => { props.onClose() }}
                                sx={{ backgroundColor: "#102A62" }}
                            >
                                {props?.loading  && <CircularProgress size={20} />}
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                disabled={loading}
                                onClick={() => { onNext() }}
                                sx={{ backgroundColor: "red" }}
                            >
                                {props?.loading && <CircularProgress size={20} />}
                                Delete Agricultural Supply
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
