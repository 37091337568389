import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import generalInformationReducer from './slices/generalInformation';
import taxInformationReducer from 'slices/taxInformationSlice';
import cropReducer from './slices/cropslice';
import propertyReducer from 'slices/propertyAddSlice';
import dependantReducer from 'slices/dependantSlice';
import adultsReducer from 'slices/adultsSlice';
import environmentReducer from 'slices/environmentalSlice';
import farmerReducer from 'slices/farmerObjSlice';





const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  farmers: generalInformationReducer,
  tax:taxInformationReducer,
  property:propertyReducer,
  dependant:dependantReducer,
  adult:adultsReducer,
  environment:environmentReducer,
  farmerObj:farmerReducer,
  crop: cropReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
});

export const persistor = persistStore(store);
