import Layout from "components/structure/Layout";
import { GridActionsCellItem } from "@mui/x-data-grid";
import editButton from "assets/editButton.svg";
import deleteButton from "assets/delete.svg";
import { useLocation, useNavigate } from "react-router-dom";
import DataTable from "utils/DataTable";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useContext, useEffect, useState } from "react";
import CustomButton from "utils/CustomButton";
import IosShareIcon from "@mui/icons-material/IosShare";
import IrregularityService from "services/IrregularityService";
import { SnackbarContext } from "context/snackBarContext";
import { json2csv } from "json-2-csv";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30.5rem",
  bgcolor: "background.paper",
  height: "20.125rem",
  borderRadius: "0.625rem",
};

const data = [
  {
    id: 1,
    code: 3432,
    description: "Unenrolled school-age children and adolescents",
    status: true,
  },
  {
    id: 2,
    code: 3431,
    description: "Unenrolled school-age children and adolescents",
    status: true,
  },
  {
    id: 3,
    code: 3436,
    description: "Unenrolled school-age children and adolescents",
    status: false,
  },
  {
    id: 4,
    code: 3439,
    description: "Unenrolled school-age children and adolescents",
    status: true,
  },
];

const IrregularityRegistration = () => {
  const navigate = useNavigate();
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  console.log("🚀 ~ IrregularityRegistration ~ data:", data);
  const [rowsData, setRowsData] = useState([]);
  console.log("🚀 ~ IrregularityRegistration ~ rowsData:", rowsData);
  const [selectedId, setSelectedId] = useState(null);
  const handleAdd = () => {
    return navigate("/addIrregularity");
  };

  const handleNo = () => setOpen(false);

  const columns = [
    {
      field: "code",
      headerName: "Code",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      headerAlign: "center",
      align: "center",
      getActions: (params) => {
        console.log("ddd", params.row); // Log the params object
        return [
          <GridActionsCellItem
            icon={<img src={editButton} alt="Edit" />}
            label="Edit"
            className="gridActionCellEditSingle"
            onClick={() =>
              navigate(`/addIrregularity`, {
                state: {
                  id: params.row.id,
                  code: params.row.code,
                  desc: params.row.description,
                  status: params.row.status,
                  edit: true,
                },
              })
            }
          />,
          <GridActionsCellItem
            icon={<img src={deleteButton} alt="Delete" />}
            label="Delete"
            className="gridActionCellEditSingle"
            onClick={() => {
              setSelectedId(params.row.id);
              setOpen(true);
            }}
          />,
        ];
      },
    },
  ];

  const handleDelete = async () => {
    try {
      await IrregularityService.deleteIrregularity(selectedId);
      setOpen(false);
      setSeverity("success");
      setMessage("Deleted successfully");
      setOpenAlert(true);

      const updatedRowsData = rowsData.filter((row) => row.id !== selectedId);
      setRowsData(updatedRowsData);
    } catch (error) {
      setSeverity("error");
      setMessage("Couldn't delete");
      setOpenAlert(true);
      console.error("Error deleting irregularity:", error);
    }
  };

  const handleData = async () => {
    try {
      const response = await IrregularityService.getIrregularity();
      const data = response.data;
      setData(data);
      const rows = data.response.map((irregular) => ({
        id: irregular.gitIrregularityId,
        code: irregular.gitCode,
        description: irregular.gitIrregularityTypeDescription,
        status: irregular.gitIrregularityStatus === true ? "Active" : "InActive",
      }));
      setRowsData(rows);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleData();
  }, []);

  const handleExport = () => {
    // Convert data to CSV format
    const csvContent =
      "data:text/csv;charset=utf-8," +
      rowsData.map((row) => Object.values(row).join(",")).join("\n");

    // Create a temporary anchor element to trigger the download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
  };

  const handleDownload = () => {
    const timestamp = new Date()
      .toISOString()
      .replace(/[-:]/g, "")
      .replace("T", "_")
      .split(".")[0];

    let cd = json2csv(data.response, { excelBOM: true });

    const blob = new Blob([cd]);
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `irregulaitydata_${timestamp}.csv`);

    // Append link to the body, click it, and remove it
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <>
      <Layout title="Irregularity Type Registration">
        <div style={{ float: "right" }}>
          <Button
            sx={{
              borderRadius: 100,
              minWidth: 95,
              minHeight: 44,
              background: "#102A62",
              marginTop: { xs: 1 },
              marginLeft: { xs: 1 },
            }}
            variant="contained"
            startIcon={<AddCircleIcon />}
            onClick={handleAdd}
          >
            Add
          </Button>
          <Button
            sx={{
              borderRadius: 100,
              minWidth: 95,
              minHeight: 44,
              background: "#102A62",
              marginTop: { xs: 1 },
              marginLeft: { xs: 1 },
            }}
            variant="contained"
            startIcon={<IosShareIcon />}
            onClick={handleDownload}
          >
            Export
          </Button>
        </div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              sx={{
                mt: 4,
                justifyContent: "center",
                display: "flex",
                gap: "1rem",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={deleteButton}
                alt="Delete Button Icon"
                style={{ height: "100px" }}
              />
              <div>Are you sure!</div>
              <div>You want to delete</div>
            </Box>
            <Box
              sx={{
                mt: 4,
                justifyContent: "center",
                display: "flex",
                gap: "1rem",
              }}
            >
              <CustomButton variant="cancel" label="No" onClick={handleNo} />

              <CustomButton
                color="#EF7D00"
                label="Yes"
                onClick={handleDelete}
              />
            </Box>
          </Box>
        </Modal>
        <DataContainer>
          {data ? (
            <DataTable data={data} rows={rowsData} columns={columns} />
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              No Data Available
            </Box>
          )}
        </DataContainer>
      </Layout>
    </>
  );
};

const DataContainer = styled.div`
  margin-top: 4rem;
`;

export default IrregularityRegistration;
