// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.task {
    touch-action: none;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    background-color: #ffff;
}`, "",{"version":3,"sources":["webpack://./src/components/Task/Task.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,+CAA+C;IAC/C,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,uBAAuB;AAC3B","sourcesContent":[".task {\n    touch-action: none;\n    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);\n    padding: 1rem;\n    margin-bottom: 1rem;\n    border-radius: 0.5rem;\n    background-color: #ffff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
