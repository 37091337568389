import { Autocomplete, FormControl, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import Divider from 'components/common/GrowingDivider';
import Layout from 'components/structure/Layout';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import InvestmentGroupLimitsService from 'services/InvestmentGroupLimitsService';
import InvestmentGroupTable from './InvestmentGroupTable';
import SearchIcon from '../../../../assets/SearchIcon.svg';



const InvestmentGroupLimitsDistribution = () => {
    const { t } = useTranslation();
    const [cropSeason, setCropSeason] = useState("");
    const [cropSeasonId, setCropSeasonId] = useState("");
    const [cropSeasonOptions, setCropSeasonOptions] = useState([]);
    const [hierarchicalNode, setHierarchicalNode] = useState("");
    const [hierarchicalNodeId, setHierarchicalNodeId] = useState("");
    const [hierarchyOptions, setHierarchyOptions] = useState([]);
    const [childNodes, setChildNodes] = useState([])
    const [isTopHierarchyNode, setIsTopHierarchyNode] = useState(false);
    const [investmentGLD, setInvestmentGLD] = useState(null)

    console.log("iHid", hierarchicalNodeId, "csId", cropSeasonId)
    useEffect(() => {
        fetchInitialData();
        // handleViewHistory();
    }, []);

    // Remove or comment out this useEffect since we'll handle it in the new one
    // useEffect(() => {
    //   if (cropSeason && tobaccoType && hierarchicalNode) {
    //     fetchProductionTargets();
    //   }
    // }, [cropSeason, tobaccoType, hierarchicalNode]);

    // Add new useEffect to watch the IDs
    useEffect(() => {
        if (cropSeasonId && hierarchicalNodeId) {
            console.log("INSIDE GLD EFFECT")
            fetchInvestmentGLD();
        } else {
            setInvestmentGLD(null);
            //   setSelectedPtdId(null); // Reset ptdId when filters change
        }
    }, [cropSeasonId, hierarchicalNodeId]);

    const fetchInitialData = async () => {
        try {
            const [cropSeasonResponse, hierarchyResponse] = await Promise.all([
                InvestmentGroupLimitsService.getCropSeasonAPI(),
                InvestmentGroupLimitsService.getHierarchyNodeAPI()
            ]);

            setCropSeasonOptions(cropSeasonResponse.data.response.map(item => ({
                label: item.cropYear.toString(),
                value: item.cropYear.toString(),
                cropSeasonId: item.cropYearUid,// Assuming there's an id field      
            })));

            // Flatten hierarchy tree for simplicity. You might want to implement a more sophisticated approach.
            const flattenHierarchy = (node, result = [], level = 0) => {
                result.push({
                    label: node.locationName,
                    value: node.id,
                    level,
                    tag: node.levelName,
                    parentId: node.parentId || "parentNode"
                });
                node.childHierarchies.forEach(child => flattenHierarchy(child, result, level + 1));
                return result;
            };
            setHierarchyOptions(flattenHierarchy(hierarchyResponse.data.response));

        } catch (error) {
            console.error("Error fetching initial data:", error);
        }
    };

    console.log("HierarchyOptions", hierarchyOptions)
    console.log("Selected node: ChildNodes", childNodes)

    const fetchInvestmentGLD = async () => {
        try {
            const InvGLDresponse = await InvestmentGroupLimitsService.getAPI({
                cropYearUUID: cropSeasonId,
                ihId: hierarchicalNodeId
            });
            console.log("INSIDE GLD EFFECT", InvGLDresponse.data.response)
            setInvestmentGLD(InvGLDresponse.data.response)
        } catch (error) {
            console.error("Error fetching initial data:", error);
        }
    }
    const handleHierarchyChange = (event, newValue) => {
        if (newValue) {
            setHierarchicalNode(newValue.value);
            setHierarchicalNodeId(newValue.value);
            setIsTopHierarchyNode(newValue.level === 0);
            setChildNodes(
                hierarchyOptions.filter((node) =>
                    node.level === newValue.level+1 &&
                    node.parentId=== newValue.value                 
                )
            )
            // Log for debugging
            console.log('Selected node:', newValue);
            console.log('Node level:', newValue.level);
        } else {
            setHierarchicalNode("");
            setHierarchicalNodeId("");
            setIsTopHierarchyNode(false);
        }
    };

    const renderInvestmentGroupTable = () => {
        if (!cropSeasonId || !hierarchicalNodeId) {
            return null;
        }
        return (
            <InvestmentGroupTable
                data={investmentGLD}
                cropYearUUID={cropSeasonId}
                ihId={hierarchicalNodeId}
                // onSave={handleSaveProductionTargets}
                childNodes={childNodes}
                isTopHierarchyNode={isTopHierarchyNode}
                refreshData={fetchInvestmentGLD}
            />
        );
    };
    return (
        <Layout
            title={t('investmentGroupLimits.pageTitle')}
            background="#ffffff"
        >
            <Grid
                container
                id="mainfilter"
                spacing={2}
                sx={{
                    background: "#EFEFEF",
                    borderRadius: "5px",
                    p: 2,
                    maxWidth: "100%",
                    margin: "auto",
                    paddingBottom: "34px",
                    marginBottom: "12px",
                }}
            >
                <Grid item xs={12} style={{ paddingTop: "0px", paddingLeft: "0px" }}>
                    <Typography variant="h8" display="flex">
                        <ArrowDropDownIcon />
                        {t('investmentGroupLimits.mandatoryFilters')}
                    </Typography>
                </Grid>

                <Grid item xs={4} style={{ paddingLeft: "0px" }}>
                    <FormControl fullWidth>
                        <Autocomplete
                            value={cropSeasonOptions.find(option => option.value === cropSeason) || null}
                            disableClearable={true}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    setCropSeason(newValue.value);
                                    setCropSeasonId(newValue.cropSeasonId); // This will trigger useEffect
                                } else {
                                    setCropSeason("");
                                    setCropSeasonId(""); // This will trigger useEffect
                                }
                            }}
                            options={cropSeasonOptions}
                            renderInput={(params) => (
                                <TextField {...params}
                                    label={t('investmentGroupLimits.csSearchField')}
                                    fullWidth
                                    placeholder='Select an Option'
                                    required={true}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{
                                        '& .MuiInputBase-input::placeholder': {
                                            opacity: 1,
                                        },
                                    }}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>

                {/* <Grid item xs={4}>
                    <FormControl fullWidth>
                        <Autocomplete
                            value={tobaccoTypeOptions.find(option => option.value === tobaccoType) || null}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    setTobaccoType(newValue.value);
                                    setTobaccoTypeId(newValue.tobaccoTypeId); // This will trigger useEffect
                                } else {
                                    setTobaccoType("");
                                    setTobaccoTypeId(""); // This will trigger useEffect
                                }
                            }}
                            // onInputChange={renderProductionTargetsTable()}
                            options={tobaccoTypeOptions}
                            renderInput={(params) => (
                                <TextField {...params} label={t('investmentGroupLimits.hnSearchField')} fullWidth />
                            )}
                        />
                    </FormControl>
                </Grid> */}

                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <Autocomplete
                            value={hierarchyOptions.find(option => option.value === hierarchicalNode) || null}
                            disableClearable={true}
                            onChange={handleHierarchyChange}
                            options={hierarchyOptions}
                            renderOption={(props, option) => (
                                <li {...props}>
                                    <div>
                                        <div style={{ fontSize: '16px' }}>
                                            {option.label}
                                        </div>
                                        <div style={{ fontSize: '10px', color: '#5A646E' }}>
                                            {option.tag}
                                        </div>
                                    </div>
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField {...params}
                                    label={t('investmentGroupLimits.hnSearchField')}
                                    fullWidth
                                    placeholder='Select an Option'
                                    required={true}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{
                                        '& .MuiInputBase-input::placeholder': {
                                            opacity: 1,
                                        },
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={SearchIcon} alt="search" style={{ width: '20px', height: '20px', color: '#737D86' }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
            </Grid>

            {cropSeasonId && hierarchicalNodeId &&
                <Divider title={t('investmentGroupLimits.subordinateHierarchiesDistributionLimit')} />}
            {renderInvestmentGroupTable()}
        </Layout>
    )
}

export default InvestmentGroupLimitsDistribution