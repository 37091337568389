import { AuthData } from 'auth/AuthWarpper';
import { useNavigate, /* other hooks */ } from 'react-router-dom';

const withRouter = WrappedComponent => props => {
  const navigate = useNavigate();
  const { user } = AuthData()
  // other hooks

  return (
    <WrappedComponent
      {...user}
      {...{ navigate, /* other hooks */ }}
    />
  );
};

export default withRouter;