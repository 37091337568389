import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Box,
  Button,
  Modal,
  FormControl,
  TextField,
} from "@mui/material";
import "./HierarchyTree.css";
import EditButton from "utils/EditButton";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useHistory, useNavigate } from "react-router-dom";
import moreActions from "assets/moreActions.svg";
import HierarchyService from "services/GrowingHierarchyService";
import { SnackbarContext } from "context/snackBarContext";
import iconEdit from "assets/iconEdit.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CustomButton from "utils/CustomButton";
import CropSeasonService from "services/CropSeasonService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50rem",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: "8px",
  // p: 4,
};

const Table = (props) => {
  const [hierarchyData, setHierarchyData] = useState([]);
  const [menuIndex, setMenuIndex] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = React.useState({});
  const [formData, setFormData] = useState({
    cyCropYear: "",
  });

  const [cropYears, setCropYears] = useState([]);

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const [seasonData, setseasonData] = useState([]);
  console.log("🚀 ~ SupplyTable ~ seasonData:", seasonData);

  useEffect(() => {
    setseasonData(props.seasonData);
  }, [props.seasonData]);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const handleClick = (event, index) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setMenuIndex(index);
  };
  const handleClose = () => {
    // event.stopPropagation();
    setAnchorEl(null);
    setMenuIndex(null);
  };

  const handleButtonClick = (event, row) => {
    event.stopPropagation();
    handleMenuClose({ action: "edit", data: row, tab: 1 });
  };

  const handleMenuClose = ({ action, data }) => {
    // event.stopPropagation();
    if (!action || action == undefined) return;

    setSelectedRow(data);
    console.log("data", data);
    if (action == "details") {
      navigate("/addcropSeasonRegistration", {
        state: {
          year: data.cropYear,
          cultureNameEdit: data.cultureId,
          editSeason: data.season,
          status: data.isActive,
          edit: false,
          details: true,
          editSeasonId: data.cropSeasonId,
        },
      });
    } else {
      navigate("/addcropSeasonRegistration", {
        state: {
          year: data.cropYear,
          cultureNameEdit: data.cultureId,
          editSeason: data.season,
          status: data.isActive,
          edit: true,
          editSeasonId: data.cropSeasonId,
        },
      });
    }
    setAnchorEl(null);
  };

  const handleEdit = () => {
    handleClose();
  };

  const handleDelete = () => {
    // Add your delete logic here
    console.log("Delete action");
    handleClose();
  };

  const handleDuplicate = () => {
    // Add your duplicate logic here
    console.log("Duplicate action");
    handleClose();
  };

  const handleCropYear = (event) => {
    const selectedYear = cropYears.find(
      (year) => year.cropYear === event.target.value
    );

    setFormData({
      ...formData,
      cyCropYear: event.target.value,
      cropYearUid: selectedYear.cropYearUid,
    });
  };

  const handleConfirm = async () => {
    try {
      // const response = await GrowingHierarchyService.getByVersion({ id });
      const response = await CropSeasonService.makeDublicate({
        cropYear: formData.cyCropYear,
        userName: "Sk",
      });

      if (response.status === 201) {
        setSeverity("success");
        setMessage("Crop season created successfully");
        setOpenAlert(true);
        setOpenModal(false);
        props.callCropSeasonApi(props.currentPage, props.itemsPerPage);
      }

      // console.log("🚀 ~ handleNodeSelect ~ response:", response);
      // setNodeData(response?.data?.response);
      // setUserValue(response?.data?.response?.linkedBackOfficeUsers);
      // setFarmerValue(response?.data?.response?.linkedFarmers);
      // setCropPhases()
    } catch (error) {
      console.error("There was an error!", error);
      setSeverity("error");
      setMessage("Something went wrong");
      setOpenModal(false);
      setOpenAlert(true);
    }
  };

  useEffect(() => {
    setHierarchyData(props.hierarchyData);
  }, [props.hierarchyData]);

  useEffect(() => {
    CropSeasonService.getCropYears()
      .then((response) => {
        setCropYears(response.data.response);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <div className="table-container">
      <table className="full-width-table">
        {" "}
        {/* Add a class for styling */}
        <thead>
          <tr className="HierarchyHead">
            <th style={{ fontWeight: "normal" }}>Crop Season</th>
            <th style={{ fontWeight: "normal" }}>Year</th>
            <th style={{ fontWeight: "normal" }}>Culture</th>
            <th style={{ fontWeight: "normal" }}>Status</th>
            <th style={{ fontWeight: "normal" }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {seasonData?.map((row, index) => (
            <tr
              className="hierarchyColumn"
              key={index}
              style={{ cursor: "pointer" }}
            // onClick={() => {
            //   navigate("/addcropSeasonRegistration", {
            //     state: {
            //       year: row.cropYear,
            //       cultureNameEdit: row.cultureId,
            //       editSeason: row.season,
            //       status: row.isActive,
            //       edit: false,
            //       details: true,
            //       editSeasonId: row.cropSeasonId,
            //     },
            //   });
            // }}
            >
              <td style={{ textAlign: "center" }}>
                <Typography
                  sx={{
                    justifyContent: "left",
                    fontWeight: 600,
                    color: "#3B454F",
                  }}
                >
                  {row.season}
                </Typography>
              </td>
              <td style={{ textAlign: "center" }}>
                <Box
                  sx={{
                    whiteSpace: "nowrap",
                    paddingLeft: "5px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontWeight: 600,
                    color: "#3B454F",
                  }}
                >
                  <span title={row.cropYear}>{row.cropYear}</span>
                </Box>
              </td>
              <td style={{ textAlign: "center" }}>
                <div
                  className="hierarchyConfig"
                  style={{
                    fontWeight: 600,
                    color: "#3B454F",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span title={row.cultureName}>{row.cultureName}</span>
                </div>
              </td>

              <td
                style={{
                  textAlign: "center",
                  fontWeight: 600,
                  color: "#3B454F",
                }}
              >
                {row.status === true ? "Active" : "Inactive"}
              </td>
              <td
                style={{
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      borderRadius: "2.116rem",
                      backgroundColor: "#FEF4D5",
                      borderColor: "#F2BB40",
                      height: "2.8rem",
                      width: "2.8rem",
                      color: "#F2BB40",
                      minWidth: "2.8rem",
                      "&:hover": {
                        backgroundColor: "#F2BB40",
                        color: "#FEF4D5",
                        borderColor: "#F2BB40",
                      },
                    }}
                    onClick={(event) => handleButtonClick(event, row)}
                  >
                    <EditIcon
                      sx={{
                        fontSize: "1.5rem",
                      }}
                    />
                  </Button>
                  <Button
                    variant="outlined"
                    className="moreButton"
                    style={{ marginLeft: "10px" }}
                    aria-label="more"
                    aria-controls={
                      menuIndex === index ? "long-menu" : undefined
                    }
                    aria-haspopup="true"
                    onClick={(event) => handleClick(event, index)}
                  >
                    <div>
                      <img src={moreActions} alt="more-actions" />
                    </div>
                    <div style={{ height: "14px" }}>More</div>
                  </Button>
                  <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={menuIndex === index}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        handleMenuClose({
                          action: "details",
                          data: row,
                          tab: 1,
                        });
                        handleClose();
                      }}
                    >
                      {/* <Button
                        variant="outlined"
                        sx={{
                          borderRadius: "2.116rem",
                          backgroundColor: "#FEF4D5",
                          borderColor: "#F2BB40",
                          height: "3.125rem",
                          width: "7.938rem",
                          color: "#F2BB40",
                          "&:hover": {
                            backgroundColor: "#F2BB40",
                            color: "#FEF4D5",
                            borderColor: "#F2BB40",
                          },
                        }}
                        onClick={() =>
                          handleMenuClose({ action: "edit", data: row, tab: 1 })
                        }
                        startIcon={<EditIcon />}
                      >
                        Edit
                      </Button> */}
                      {/* <ListItemText primary="Edit" /> */}
                      {/* </MenuItem> */}
                      {/* <MenuItem
                    // onClick={async () => {
                    //   try {
                    //     const response =
                    //       await HierarchyService.getDuplicateHierarchyList(
                    //         row?.ihvId
                    //       );
                    //     setSeverity("success");
                    //     setMessage("Duplicate Added successfully");
                    //     setOpenAlert(true);
                    //     props.fetchData();
                    //     handleClose();
                    //   } catch (error) {
                    //     console.error("Error calling API:", error);
                    //   }
                    // }}
                    > */}
                      <Button
                        variant="outlined"
                        sx={{
                          borderRadius: "2.116rem",
                          backgroundColor: "#D4EDDA",
                          borderColor: "#28A745",
                          height: "3.125rem",
                          width: "7.938rem",
                          color: "#28A745",
                          "&:hover": {
                            backgroundColor: "#28A745",
                            color: "#D4EDDA",
                            borderColor: "#28A745",
                          },
                        }}
                        startIcon={<VisibilityIcon />}
                        onClick={() => {
                          handleMenuClose({
                            action: "details",
                            data: row,
                            tab: 1,
                          });
                          handleClose();
                        }}
                      >
                        View
                      </Button>
                    </MenuItem>
                    <MenuItem onClick={handleOpenModal}>
                      <Button
                        variant="outlined"
                        sx={{
                          borderRadius: "2.116rem",
                          backgroundColor: "#FEF4D5",
                          borderColor: "#F2BB40",
                          height: "3.125rem",
                          width: "7.938rem",
                          color: "#F2BB40",
                          "&:hover": {
                            backgroundColor: "#F2BB40",
                            color: "#FEF4D5",
                            borderColor: "#F2BB40",
                          },
                        }}
                        startIcon={<FileCopyIcon />}
                      >
                        Duplicate
                      </Button>
                    </MenuItem>
                  </Menu>
                  {/* <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        Text in a modal
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Duis mollis, est non commodo luctus, nisi erat porttitor
                        ligula.
                      </Typography>
                    </Box>
                  </Modal> */}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              background: "#102A63",
              color: "#ffffff",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopRightRadius: "8px",
              borderTopLeftRadius: "8px",
            }}
          >
            <Typography sx={{ fontWeight: 600, fontSize: "1.25rem" }}>
              Duplicate Registration
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 4,
              display: "flex",
              gap: "1rem",
              flexDirection: "column",
              fontSize: "1.25rem",
              color: "#3B454F",
              marginLeft: "2rem",
            }}
          >
            <Box sx={{ fontWeight: 400 }}>
              Please confirm this duplicate operation by confirming the target
              year.
            </Box>
            <Box>
              <FormControl
                sx={{
                  width: { xs: "100%", md: 222 },
                  minHeight: 50,
                  marginRight: { xs: 0, md: 2 },
                }}
              >
                <TextField
                  select
                  id="cyCropYear"
                  labelId="cyCropYear-label"
                  label={" Crop Year"}
                  value={formData.cyCropYear}
                  name="cyCropYear"
                  onChange={(e) => handleCropYear(e)}
                // error={!!errors.cyCropYear}
                >
                  {cropYears.map((year) => (
                    <MenuItem key={year.cropYearUid} value={year.cropYear}>
                      {year.cropYear}
                    </MenuItem>
                  ))}
                </TextField>
                {/* <FormHelperText error={true}>{errors.tobaccoType}</FormHelperText> */}
              </FormControl>
            </Box>
            <Box style={{ fontWeight: 500 }}>
              Warning, this operation also duplicates other related records,
              like Crop calendar, Spacing registration, etc.
            </Box>
          </Box>
          <Box
            sx={{
              mt: 4,
              justifyContent: "center",
              display: "flex",
              gap: "1rem",
              marginBottom: "2rem",
            }}
          >
            <CustomButton
              variant="cancel"
              label="No"
              onClick={() => setOpenModal(false)}
            />

            <CustomButton
              width="15rem"
              onClick={handleConfirm}
              color="#EF7D00"
              label="Confirm and Duplicate"
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default Table;
