import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import NoData from "assets/noData.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "components/structure/Layout";
import DataTable from "utils/DataTable/DataTable";
import CloseIcon from "assets/closeIcon.svg";
import SeasonRegistrationService from "services/SeasonRegistrationService";
import CustomButton from "utils/CustomButton";
import { SnackbarContext } from "context/snackBarContext";
import { formatDate } from "utils/utils";
import { AuthData } from "auth/AuthWarpper";
import EditButton from "utils/EditButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "49.5rem",
  bgcolor: "background.paper",
  height: "18.125rem",
  borderRadius: "0.625rem",
};
const columns = [
  {
    field: "country",
    headerName: "Country",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "cropYear",
    headerName: "Crop Year",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "seasonCode",
    headerName: "Season Code",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "seasonName",
    headerName: "Season Name",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "initialDate",
    headerName: "Start Date",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "endDate",
    headerName: "End Date",
    flex: 1,
    // width: 200,

    headerAlign: "center",
    align: "center",
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "actions",
    type: "actions",
    headerName: "Actions",
    width: 250,
    headerAlign: "center",
    align: "center",
    getActions: (params) => {
      const state = {
        id: params.id,
        seasonCode: params.row.seasonCode,
        country: params.row.country,
        cropYear: params.row.cropYear,
        initialDate: params.row.initialDate,
        endDate: params.row.endDate,
        status: params.row.status,
        countryCode: params.row.countryCode,
        edit: true,
        isFetched: true,
      };
      const url = `/editSeason`;
      return [<EditButton state={state} url={url} />];
    },
  },
];

let year = "";
let addClick = false;
const SeasonRegistration = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const yearSuccess = location.state?.year;
  const { user } = AuthData();
  const [data, setData] = useState([]);
  const [fetchError, setFetchError] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [cropYears, setCropYears] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchedYear, setFetchedYear] = useState("");
  const [isFetched, setIsFetched] = useState(false);
  const [yearReload, setYearReload] = useState(false);

  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const handleChange = (event) => {
    setFetchError(false);
    year = event.target.value;
    setYearReload(true);
  };

  const fetchData = async () => {
    if (!year && !yearSuccess) return;
    if (!user?.countryCode) return; // Check if user.countryCode is null or undefined
    setLoading(true);
    try {
      const response = await SeasonRegistrationService.getSeasonRegistration(
        year || yearSuccess,
        user.countryCode
      );

      const responseData = response.data.response;

      const rows = responseData.map((item) => ({
        id: item.cropSeasonUid,
        country: item.countryName,
        cropYear: item.cyCropYear,
        seasonCode: item.seasonCode,
        seasonName: item.seasonName,
        initialDate: formatDate(item.startDate),
        endDate: formatDate(item.endDate),
        status: item.isActive === true ? "Active" : "InActive",
        countryCode: item.countryCode,
      }));
      setData(rows);
      setLoading(false);
      setFetchedYear(year || yearSuccess);
      setIsFetched(true);
      if (addClick === true) {
        navigate("/addSeason", {
          state: { cropYear: year || yearSuccess, isFetched: isFetched },
        });
        addClick = false;
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        if (addClick === true) {
          handleOpen();
          addClick = false;
        }
        setFetchError(true);
      }
      console.error("Error fetching data:", error.message);
      setSeverity("error");
      setMessage(error?.response?.data?.message || error?.message);
      setOpenAlert(true);
      setData([]);
    }
    setLoading(false);
  };

  const handleAdd = () => {
    addClick = true;

    fetchData();
    // if (!fetchError) {
    //   return navigate("/addSeason" ,{state:{cropYear:year || yearSuccess,isFetched:isFetched}});
    // }
  };

  const handleYes = async () => {
    setLoading(true);
    try {
      const payload = {
        createdByUser: user.userName,
        modifiedByUser: user.userName,
        cyCropYear: year,
        countryCode: user?.countryCode,
      };

      const response = await SeasonRegistrationService.handleYesRegistration(
        fetchError,
        payload
      );

      const responseData = response.data.response;
      if (responseData) {
        SeasonRegistrationService.addCropStageCalendar(responseData).catch(
          (err) => console.log(err)
        );
      }
      //
      const rows = responseData.map((item) => ({
        id: item.cropSeasonUid,
        country: item.countryName,
        cropYear: item.cyCropYear,
        seasonCode: item.seasonCode,
        seasonName: item.seasonName,
        initialDate: formatDate(item.startDate),
        endDate: formatDate(item.endDate),
        status: item.isActive === true ? "Active" : "InActive",
      }));
      setData(rows);
      setSeverity("success");
      setMessage("Added successfully");
      setOpenAlert(true);
      setLoading(false);
      handleClose();
    } catch (error) {
      console.error("Error fetching data:", error.response.data.message);
      setSeverity("error");
      setMessage(error?.response?.data?.message);
      setOpenAlert(true);
    }
    setLoading(false);
  };

  const handleNo = () => {
    return navigate("/addSeason", {
      state: { cropYear: year || yearSuccess, isFetched: isFetched },
    });
  };

  useEffect(() => {
    SeasonRegistrationService.getCropYears()
      .then((response) => {
        // Extract crop years from response
        const cropYearsFromApi = response.data.response.map(
          (item) => item.cropYear
        );
        setCropYears(cropYearsFromApi);
      })
      .catch((error) => console.error("Error fetching data:", error));
    if (location?.state?.isFetched) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout title="Season Registration List">
      <div
        style={{
          fontWeight: 600,
          fontSize: "1.25rem",
          color: "rgba(115, 125, 134, 1)",
          marginBottom: "1rem",
          paddingLeft: "0.5rem",
        }}
      >
        Season Registration List
      </div>
      <RegistrationOptions>
        <div style={{ display: "flex", alignItems: "center" }}>
          <FormControl
            sx={{ m: 1, minWidth: { xs: 150, md: 300 }, minHeight: 50 }}
          >
            <TextField
              select
              value={year || yearSuccess}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              label={year ? "" : "Crop Year"}
            >
              <MenuItem value="">
                <em>Crop Year</em>
              </MenuItem>
              {cropYears.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <Button
            sx={{
              borderRadius: 10,
              minWidth: 95,
              minHeight: 44,
              background: "#102A62",
              marginTop: { xs: 1 },
              marginLeft: { xs: 1 },
            }}
            startIcon={loading && <CircularProgress size={20} />}
            variant="contained"
            onClick={fetchData}
            disabled={loading}
          >
            Get
          </Button>
        </div>
        <div>
          {(year || yearSuccess || yearReload) && (
            <Button
              sx={{
                borderRadius: 100,
                minWidth: 95,
                minHeight: 44,
                background: "#102A62",
                marginTop: { xs: 1 },
                marginLeft: { xs: 1 },
              }}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={handleAdd}
              disabled={loading}
            >
              {loading && <CircularProgress size={20} />}
              Add
            </Button>
          )}
        </div>
      </RegistrationOptions>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              background: " #F2BB40",
              borderTopLeftRadius: "0.625rem",
              borderTopRightRadius: "0.625rem",
              p: 4,
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                sx={{ fontWeight: 600, fontSize: "1.25rem", color: "#191E31" }}
              >
                Add
              </Typography>
              <img
                style={{ cursor: "pointer" }}
                onClick={handleClose}
                src={CloseIcon}
                alt="Close Icon"
              />
            </Box>
          </Box>
          <Box sx={{ p: 4 }}>
            <Typography sx={{ fontWeight: 500, fontSize: "1.125rem" }}>
              You are creating a new crop season{" "}
              <span style={{ fontWeight: 700 }}>({year})</span> , do you want to
              use the same setup as the
              <span style={{ fontWeight: 700 }}> previous crop year?</span> You
              will be able to edit it later if desired.
            </Typography>
            <Box
              sx={{
                mt: 4,
                justifyContent: "flex-end",
                display: "flex",
                gap: "1rem",
              }}
            >
              <CustomButton variant="cancel" onClick={handleNo} label="No" />

              <CustomButton onClick={handleYes} label="Yes" loading={loading} />
            </Box>
          </Box>
        </Box>
      </Modal>

      {loading ? (
        ""
      ) : (
        <DataContainer>
          {data.length !== 0 ? (
            <DataTable data={data} rows={data} columns={columns} hideFooter={true}/>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={NoData} style={{ height: "100px" }} alt="No Data" />
              <p>No Data Available</p>
            </Box>
          )}
        </DataContainer>
      )}
    </Layout>
  );
};

const RegistrationOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5rem;

  @media (max-width: 768px) {
    display: block;
  }
`;

const DataContainer = styled.div`
  margin-top: 2rem;
`;

export default SeasonRegistration;
