// GetLocalityConfiguration.jsx
import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import {
  Autocomplete,
  Checkbox,
  InputAdornment,
  FormLabel,
  Modal,
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
  TableContainer,
} from "@mui/material";
import Layout from "components/structure/Layout";
import arrowLeft from "assets/arrowLeft.svg";
import { SnackbarContext } from "context/snackBarContext";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import useCultureStore from "./useCultureStore";
import Divider from "components/common/GrowingDivider";
import ListFilter from "./ListFilter";
import Save from "assets/SaveNew.svg";
import { set } from "date-fns";
import { useCultureData } from "./useCultureData";
import { useQueryClient } from '@tanstack/react-query';
import "./GrowingBaseTable.css";

const FormCTR = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { setMessage, setSeverity, setOpenAlert } = useContext(SnackbarContext);
  
  // Get location state to determine view mode and potentially get culture ID
  const location = useLocation();
  const isViewMode = location.state?.edit === false;
  const initialCultureId = location.state?.row?.cuCultureId || useCultureStore.getState().cultureTypeId;

  // Update to use initialCultureId
  const { 
    cultureTypeQuery, 
    postCultureTypeMutation,
    curingTypesQuery,  // Add this line
    geneticTypesQuery  // Add this line
  } = useCultureData({
    cultureTypeId: initialCultureId
  });

  // Update selectedCultureName from location state or store
  const selectedCultureName = location.state?.row?.cuCultureName || 
    useCultureStore.getState().selectedCultureName;

  // Add state to track list filters with initial data
  const [filterList, setFilterList] = useState([]);

  // Update useEffect to populate filters when data is loaded
  useEffect(() => {
    console.log('Culture Type Query Data:', cultureTypeQuery.data);
    if (cultureTypeQuery.data?.response) {
      const cultureTypes = cultureTypeQuery.data.response;
      console.log('Setting filter list with culture types:', cultureTypes);
      
      // Create filter list based on available culture types
      setFilterList(Array.from({ length: cultureTypes.length }, (_, i) => i));
    }
  }, [cultureTypeQuery.data]);

  // Modify getInitialDataForFilter to use cultureTypeQuery data
  const getInitialDataForFilter = (index) => {
    if (!cultureTypeQuery.data?.response) return null;
    return cultureTypeQuery.data.response[index];
  };

  // Handler to add new filter
  const handleAddNew = () => {
    setFilterList(prev => [...prev, prev.length]);
  };

  // Update handleRemoveFilter to handle last filter case
  const handleRemoveFilter = async (indexToRemove) => {
    try {
      if (filterRefs.current[indexToRemove]) {
        const filterData = filterRefs.current[indexToRemove].getData();
        const initialData = getInitialDataForFilter(indexToRemove);
        
        if (initialData?.ctCultureTypeId) {
          // Prepare data for the API call
          const updatedData = [{
            gtGeneticTypeId: filterData?.geneticTypeId,
            cultureTypeCountryLinkDTOs: [],
            createdDateTimeUTC: new Date().toISOString(),
            createdByUser: "System_User",
            modifiedDateTimeUTC: new Date().toISOString(),
            modifiedByUser: "System_User",
            cuCultureId: useCultureStore.getState().cultureTypeId,
            ctCultureTypeId: initialData.ctCultureTypeId,
            ctAbbreviation: filterData?.abbreviation || "",
            ctDescription: filterData?.description || "",
            ctLSM: filterData?.lsm || "",
            ctrId: filterData?.ctrId || "",
            curingTypeName: filterData?.curingType || "",
            geneticTypeName: filterData?.geneticType || "",
            ctStatus: filterData?.status || false,
            isDeleted: true // Mark as deleted
          }];

          // Call the mutation
          await postCultureTypeMutation.mutateAsync(updatedData);
          
          // If this was the last filter, invalidate queries and clear cache
          if (filterList.length === 1) {
            queryClient.invalidateQueries(['cultureType', initialCultureId]);
            queryClient.setQueryData(['cultureType', initialCultureId], null);
            useCultureStore.getState().setCultureTypeId(null);
          }
          
          // Show success message using translation
          setMessage(t("deletedSuccessfully"));
          setSeverity('success');
          setOpenAlert(true);
          
          // Remove from UI
          setFilterList(prev => prev.filter((_, index) => index !== indexToRemove));
        } else {
          // For new records, just remove from the list
          setFilterList(prev => prev.filter((_, index) => index !== indexToRemove));
        }
      }
    } catch (error) {
      console.error('Error deleting filter:', error);
      
      // Generic error message
      setMessage(t("errorUpdatingRecord"));
      setSeverity('error');
      setOpenAlert(true);
    }
  };

  // Add this handler for cancel navigation
  const handleCancel = () => {
    setOpen(true);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleDeleteConfirm = () => {
    setOpen(false);
    navigate("/listCultureTypeRegistration");
  };

  // Add refs to store ListFilter data
  const filterRefs = useRef({});

  // Add this function to register refs
  const registerFilterRef = (index, ref) => {
    filterRefs.current[index] = ref;
  };

  // Add save handler
  const handleSave = async () => {
    try {
      // Check if all filters are empty or have validation errors
      const filtersWithErrors = filterList.filter((index) => {
        const filterRef = filterRefs.current[index];
        const filterData = filterRef?.getData();
        return filterData === null; // getData() returns null if validation fails
      });

      // If there are filters with validation errors, prevent saving
      if (filtersWithErrors.length > 0) {
        return; // Silently prevent saving
      }

      const filtersData = filterList.map((index) => {
        const filterData = filterRefs.current[index]?.getData();
        const initialData = getInitialDataForFilter(index);
        
        // Find the curing type to get the correct ctrId
        const selectedCuringType = curingTypesQuery.data?.curingTypes?.find(
            type => type.curingType === filterData?.curingType
        );

        // Find the genetic type to get the correct geneticTypeId
        const selectedGeneticType = geneticTypesQuery.data?.geneticTypes?.find(
            type => type.gtGeneticDescription === filterData?.geneticType
        );

        return {
            gtGeneticTypeId: selectedGeneticType?.gtGeneticTypeId || filterData?.geneticTypeId,
            cultureTypeCountryLinkDTOs: [],
            createdDateTimeUTC: new Date().toISOString(),
            createdByUser: "System_User",
            modifiedDateTimeUTC: new Date().toISOString(),
            modifiedByUser: "System_User",
            cuCultureId: useCultureStore.getState().cultureTypeId,
            ctCultureTypeId: initialData?.ctCultureTypeId || "",
            ctAbbreviation: filterData?.abbreviation || "",
            ctDescription: filterData?.description || "",
            ctLSM: filterData?.lsm || "",
            ctrId: selectedCuringType?.ctrId || filterData?.ctrId || "", // Dynamically get ctrId
            curingTypeName: filterData?.curingType || "",
            geneticTypeName: filterData?.geneticType || "",
            ctStatus: filterData?.status || false,
            isDeleted: filterData?.isDeleted || false
        };
      });

      // Call the mutation
      await postCultureTypeMutation.mutateAsync(filtersData);
      
      // Invalidate and refetch relevant queries
      queryClient.invalidateQueries(['cultures']);
      queryClient.invalidateQueries(['cultureType']);
      queryClient.invalidateQueries(['geneticTypes']);
      queryClient.invalidateQueries(['curingTypes']);

      // Optional: Force a refetch of specific queries
      cultureTypeQuery.refetch();
      geneticTypesQuery.refetch();
      curingTypesQuery.refetch();

      // Show success message
      setMessage(t("recordUpdatedSuccessfully"));
      setSeverity('success');
      setOpenAlert(true);
      
      // Navigate on success
      navigate("/listCultureTypeRegistration");
      
    } catch (error) {
      console.error('Error saving culture types:', error);
      
      // Generic error message
      setMessage(t("errorSavingChanges"));
      setSeverity('error');
      setOpenAlert(true);
    }
  };

  const queryClient = useQueryClient();

  return (
    <Layout
      title={t("curingTypeRegistration.pageTitle2")}
      background="#ffffff"
      useNewPagination={true}
    >
      {/* Culture Name Input Field */}
      <Grid
        container
        spacing={2}
        sx={{
          background: "#EFEFEF",
          borderRadius: "10px",
          p: 2,
          maxWidth: "100%",
          margin: "auto",
          paddingBottom: "8px",
        }}
      >
        <Grid item xs={12} style={{ paddingTop: "0px", paddingLeft: "0px" }}>
          <Typography variant="h8" display="flex">
            <ArrowDropDownIcon />
            {t("curingTypeRegistration.cultureinfo")}
          </Typography>
        </Grid>

        <Grid item xs={12} style={{ paddingLeft: "0px", marginBottom: "10px" }}>
          <FormControl fullWidth>
            <TextField
              fullWidth
              id="culture-type-input"
              variant="outlined"
              size="small"
              disabled
              value={selectedCultureName}
              sx={{
                height: '45px',
                '& .MuiOutlinedInput-root': {
                  height: '45px',
                  '&.Mui-disabled': {
                    '& fieldset': {
                      borderColor: 'rgba(0, 0, 0, 0)', // Default disabled border color
                      '&:hover': {
                        borderColor: 'rgba(0, 0, 0, 0)', // Prevent hover effect
                      }
                    }
                  }
                }
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      {/* Divider Section */}
      <Box
        display=""
        alignItems="center"
        flexDirection={"column"}
        mt={3}
        mb={3}
      >
        <Box display="flex" alignItems="center" flexDirection={"row"}>
          <FormLabel
            sx={{
              ml: 1,
              color: "#000000",
              display: "flex",
            }}
          >
            {t("cultureTypeRegistration.form")}
          </FormLabel>
          {!isViewMode && (
            <Button
              style={{
                position: "absolute",
                right: 0,
                float: "right",
                marginLeft: "100px",
                marginRight: "25px",
                marginBottom: "8px",
                background: "#004F9F",
                alignSelf: "center",
              }}
              variant="contained"
              onClick={handleAddNew}
            >
              {t("addNew")}
            </Button>
          )}
        </Box>

        <hr
          style={{
            color: "#004F9F",
            backgroundColor: "#004F9F",
            height: 2,
            borderColor: "#004F9F",
          }}
        />
      </Box>

      {/* Render ListFilter components */}
      {filterList.map((_, index) => (
        <Box key={index} sx={{ mb: 2 }}>
          <ListFilter 
            ref={(ref) => registerFilterRef(index, ref)}
            onDelete={() => handleRemoveFilter(index)}
            showDelete={filterList.length > 0 && !isViewMode}
            initialData={getInitialDataForFilter(index)}
            disabled={isViewMode}
          />
        </Box>
      ))}

      {/* Replace the static margin Box with a dynamic one */}
      <Box 
        sx={{ 
          mb: filterList.length > 0 ? 20 : 10,
          minHeight: '100px' // Ensures minimum space even with no filters
        }}
      />

      {/* Show bottom buttons only when not in view mode */}
      {!isViewMode && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={3}
          mb={3}
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "#ffffff",
            padding: "10px",
            boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
            zIndex: 1000
          }}
        >
          <Button
            style={{
              backgroundColor: "#737D86",
              padding: "6px 16px",
              borderRadius: "4px",
            }}
            variant="contained"
            startIcon={<img src={arrowLeft} alt="Arrow" />}
            onClick={handleCancel}
          >
            {t("cancel")}
          </Button>

          <Button
            sx={{
              padding: "6px 16px",
              borderRadius: "4px",
              backgroundColor: "#EF7D00",
              color: "white",
              "&:hover": {
                backgroundColor: "#EF7D00",
              },
            }}
            startIcon={<img src={Save} alt="Right Arrow" />}
            variant="contained"
            onClick={handleSave}
          >
            {t("save")}
          </Button>
        </Box>
      )}

      {/* Show back button in view mode */}
      {isViewMode && (
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          mt={3}
          mb={3}
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "#ffffff",
            padding: "10px",
            boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
            zIndex: 1000
          }}
        >
          <Button
            style={{
              backgroundColor: "#737D86",
              padding: "6px 16px",
              borderRadius: "4px",
            }}
            variant="contained"
            startIcon={<img src={arrowLeft} alt="Arrow" />}
            onClick={() => navigate("/listCultureTypeRegistration")}
          >
            {t("back")}
          </Button>
        </Box>
      )}

      {/* Modal confirmation */}
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "585px",
            margin: "auto",
            mt: 25,
            height: "200px",
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
            >
              Cancel Operation
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, fontWeight: 400 }}
            >
              {t("cancelMessage")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              mb: 1,
            }}
          >
            <Button
              onClick={handleCloseModal}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              {t("no")}
            </Button>
            <Button
              onClick={handleDeleteConfirm}
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#004F9F" },
              }}
            >
              {t("yes")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Layout>
  );
};

export default FormCTR;
