import React, { useEffect, useState } from "react";
import { Typography, Box, Button } from "@mui/material";
import "./Culture.css";
// import EditButton from "utils/EditButton";
import { useNavigate } from "react-router-dom";
import editIcon from "../../../../assets/edit.svg";
import moreActions from "../../../../assets/moreActions.svg";
import detailsIcon from "../../../../assets/iconView.svg";
import deleteIcon from "../../../../assets/iconDelete.svg";
import { Menu, MenuItem } from "@mui/material";

const CultureTable = (props) => {
  const navigate = useNavigate();
  const [cultureData, setCultureData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuIndex, setMenuIndex] = useState(null);
  console.log("🚀 ~ CultureTable ~ cultureData:", cultureData);

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setMenuIndex(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setMenuIndex(null);
  };
  const handleMenuClose = ({ action, data }) => {
    if (!action || action == undefined) return;
    if (action == "details") {
      console.log("ButtonClicked", "Details");
    } else {
      console.log("ButtonClicked", "Delete");
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    setCultureData(props.cultureData);
  }, [props.cultureData]);

  return (
    <div className="table-container">
      <table className="full-width-table">
        {" "}
        <thead>
          <tr className="HierarchyHead">
            <th>Culture Code</th>
            <th>Culture Description</th>
            <th>Amount Culture Type</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {cultureData?.map((row, index) => (
            <tr className="hierarchyColumn" key={index} style={{}}>
              <td style={{ textAlign: "center" }}>
                <Typography
                  sx={{
                    justifyContent: "left",
                    fontWeight: 600,
                    color: "#3B454F",
                  }}
                >
                  {row.cuCultureCode}
                </Typography>
              </td>
              <td style={{ textAlign: "center" }}>
                <Box
                  sx={{
                    whiteSpace: "nowrap",
                    paddingLeft: "5px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontWeight: 600,
                    color: "#3B454F",
                  }}
                >
                  <span title={row.cuCultureName}>{row.cuCultureName}</span>
                </Box>
              </td>
              <td style={{ textAlign: "center" }}>
                <div
                  className="hierarchyConfig"
                  style={{
                    fontWeight: 600,
                    color: "#3B454F",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {row.cultureTypes.length}
                </div>
              </td>
              <td
                style={{
                  textAlign: "center",
                  fontWeight: 600,
                  color: "#3B454F",
                }}
              >
                {row.cuStatus ? "Active" : "Inactive"}
              </td>
              <td
                style={{
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* <EditButton
                    url={`/editcultureRegistration/${row.cuCultureId}`}
                  /> */}
                  <Button
                    variant="outlined"
                    sx={{
                      borderRadius: "2.116rem",
                      backgroundColor: "#FEF4D5",
                      borderColor: "#F2BB40",
                      height: "2.8rem",
                      width: "2.8rem",
                      color: "#F2BB40",
                      minWidth: "2.8rem",
                      "&:hover": {
                        backgroundColor: "#F2BB40",
                        color: "#FEF4D5",
                        borderColor: "#F2BB40",
                      },
                    }}
                    onClick={() =>
                      navigate(`/editcultureRegistration/${row.cuCultureId}`)
                    }
                  >
                    <img src={editIcon} alt="Edit Button" />
                  </Button>
                  <Button
                    variant="outlined"
                    className="moreButton"
                    style={{ marginLeft: "10px" }}
                    aria-label="more"
                    aria-controls={
                      menuIndex === index ? "long-menu" : undefined
                    }
                    aria-haspopup="true"
                    onClick={(event) => handleClick(event, index)}
                  >
                    <div>
                      <img src={moreActions} alt="more-actions" />
                    </div>
                    <div style={{ height: "14px" }}>More</div>
                  </Button>
                  <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={menuIndex === index}
                    onClose={handleClose}
                  >
                    <MenuItem>
                      <Button
                        sx={{
                          borderRadius: "2.116rem",
                          backgroundColor: "#0AD0AD1F",
                          borderColor: "#50AF47",
                          color: "#F2BB40",
                          // "&:hover": {
                          //   backgroundColor: "#F2BB40",
                          //   color: "#FEF4D5",
                          //   borderColor: "#F2BB40",
                          // },
                        }}
                        variant="outlined"
                        aria-label="more"
                        aria-controls={
                          menuIndex === index ? "long-menu" : undefined
                        }
                        aria-haspopup="true"
                        onClick={(event) => {
                          handleClick(event, index);
                          handleMenuClose({
                            action: "details",
                            data: row,
                            tab: 1,
                          });
                          handleClose();
                          navigate(
                            `/editcultureRegistration/${row.cuCultureId}`,
                            {
                              state: {
                                details: true,
                              },
                            }
                          );
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            marginRight: "5px",
                          }}
                        >
                          <img src={detailsIcon} alt="more-actions" />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            height: "14px",
                            color: "#50AF47",
                            marginBottom: "5px",
                          }}
                        >
                          Details
                        </div>
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        sx={{
                          borderRadius: "2.116rem",
                          backgroundColor: "#9F00001F",
                          borderColor: "#EF0000",
                          color: "#EF0000",
                          // "&:hover": {
                          //   backgroundColor: "#F2BB40",
                          //   color: "#FEF4D5",
                          //   borderColor: "#F2BB40",
                          // },
                        }}
                        variant="outlined"
                        aria-label="more"
                        aria-controls={
                          menuIndex === index ? "long-menu" : undefined
                        }
                        aria-haspopup="true"
                        onClick={(event) => {
                          handleClick(event, index);
                          handleMenuClose({
                            action: "delete",
                            data: row,
                            tab: 1,
                          });
                          handleClose();
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            marginRight: "5px",
                          }}
                        >
                          <img src={deleteIcon} alt="more-actions" />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            height: "14px",
                            color: "#EF0000",
                            marginBottom: "5px",
                          }}
                        >
                          Delete
                        </div>
                      </Button>
                    </MenuItem>
                  </Menu>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CultureTable;
