import React, { useEffect, useState } from "react";
import { DataGrid, GridColumnMenu, GridToolbar } from "@mui/x-data-grid";
import { Button, IconButton } from "@mui/material";
import moreActions from "../../assets/moreActions.svg";

import "./DataTable.css";
import { useLocation, useNavigate } from "react-router-dom";

const DataTable = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [columnsVisible, setColumnsVisible] = useState(null);

  console.log("props", props.rows);
  const CustomButton = () => (
    <Button variant="outlined" className="moreButton">
      <div>
        <img src={moreActions} alt="More Actions" />
      </div>{" "}
      <div style={{ height: "14px" }}>More</div>
    </Button>
  );
  const handleRowClick = (
    params, // GridRowParams
    event // MuiEvent<React.MouseEvent<HTMLElement>>
  ) => {
    event.preventDefault();
    if (location && location.pathname === "/users") {
      // navigate(`${params?.row?.batOneId}`, {
      //   state: { ...params.row, mode: "Edit" },
      // });
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        paddingTop: "2rem",
      }}
    >
      <div
        style={{
          // height: props.rows.length <= 5 ? "100vh" : "auto",
          height: "100vh",
          width: "100%",
          cursor: "pointer",
        }}
      >
        <DataGrid
          hideScrollbar={true}
          slots={{
            MoreActionsIcon: CustomButton,
            columnMenu: CustomColumnMenu,
          }}
          onRowClick={(p, e) => handleRowClick(p, e)}
          rows={props.rows}
          columns={props.columns}
          // initialState={{
          //   pagination: {
          //     paginationModel: { page: props.total, pageSize: 5 },
          //   },
          // }}
          // pagination

          rowCount={props.totalRowCount}
          paginationModel={props.paginationModel}
          paginationMode="server"
          hideFooterPagination={
            props.hideFooter || props.rows.length === 0 ? true : false
          }
          onPaginationModelChange={props.setPaginationModel}
          style={{ borderStyle: "none" }}
          sx={{
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            "& .MuiDataGrid-row": {
              backgroundColor: "#ffffff",
              position: "relative",
              marginTop: "10px",
              marginBottom: "10px",
              borderRadius: "10px",
              height: "4.688rem",
              // boxShadow: '2px 2px 10px 0px rgba(172, 180, 186, 0.3)',
              // width: "99.8%",
              maxHeight: "unset !important",
              alignItems: "center",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "unset",
              color: "rgba(59, 69, 79, 1)",
              fontFamily: "Montserrat",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "24.38px",
              letterSpacing: "0em",
              textAlign: "left",
              outline: "none !important",
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: "#F5F5F7",
              justifyContent: "center",
              paddingBottom: "4rem",
              borderTop: "none",
              margin: "0px -26px",
            },
            "&  .MuiDataGrid-columnHeaders": {
              border: "none",
              fontFamily: "Montserrat",
              fontSize: "20px",
              fontWeight: 400,
              // color: ' rgba(19, 22, 25, 1)',
              color: "#737D86",
              lineHeight: "24.38px",
            },
            "& .MuiDataGrid-virtualScroller": {
              overflowY: "scroll",
            },
            "& .MuiDataGrid-root": {},
            "& .MuiDataGrid-main": {
              // overflowY:"scroll"
            },
          }}
        ></DataGrid>
      </div>
    </div>
  );
};

export default DataTable;

function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide `columnMenuColumnsItem`
        columnMenuColumnsItem: null,
      }}
    />
  );
}
