import PickListService from "services/PickListService";
import {
    EMPTY_PICK_LIST_CODE,
    LOV_FETCH_FAILED,
    LOV_NOT_FOUND
} from "./AgriErrorMsg";

export const fetchLovDetails = async (pickListCodes = []) => {
    try {
        if (pickListCodes.length <= 0) {
            throw new Error(EMPTY_PICK_LIST_CODE);
        }

        let lovDetails = null;
        const response = await PickListService.picklist({
            "pickListCodes": pickListCodes
        });
        if (response.status === 200) {
            const responseData = response?.data?.response;
            if (responseData) {
                lovDetails = Object.keys(responseData).map((pickListCode) => ({
                    pickListCode,
                    items: responseData[pickListCode].map(item => ({
                        pickListItemCode: item.pickListItemCode,
                        pickListDescription: item.pickListDescription,
                    })),
                }));
                return lovDetails;
            } else {
                throw new Error(LOV_NOT_FOUND);
            }
        } else {
            throw new Error(LOV_FETCH_FAILED);
        }
    } catch (error) {
        console.log("Error while fetching LOV details: ", error);
        throw error;
    }
}