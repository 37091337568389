import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addDependant: [],
};

const dependantSlice = createSlice({
  name: "addDependant",
  initialState,
  reducers: {
    updateDependant(state, action) {
      if (Array.isArray(action.payload)) {
        action.payload.forEach((newDependant) => {
          const existingDependantIndex = state.addDependant.findIndex(
            (dependant) => dependant.dependentId === newDependant.dependentId
          );
          if (existingDependantIndex !== -1) {
            state.addDependant[existingDependantIndex] = newDependant;
          } else {
            state.addDependant.push(newDependant);
          }
        });
      } else {
        console.error("Payload for updateDependant should be an array of dependant objects.");
      }
    },
    deleteDependant(state, action) {
      state.addDependant = state.addDependant.filter(
        (dependant) => dependant.dependentId !== action.payload
      );
    },
    clearDependant(state) {
      state.addDependant = [];
    },
  },
});

export const { updateDependant, deleteDependant, clearDependant } = dependantSlice.actions;
export default dependantSlice.reducer;
