import React, { useState, useEffect, useContext, useCallback } from "react";
import { Box, Button, FormControl, Grid, MenuItem, TextField, Typography, Select, Checkbox, OutlinedInput, Paper } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { httpGrow } from "http-common";
import { debounce } from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import { tr } from "date-fns/locale";
import ExpensesTypeService from "services/ExpensesTypeService";
import Lable from "utils/lables.json";
import InputLabel from "@mui/material/InputLabel";
import Expenses3rdPartyTable from "./Expenses3rdPartyTable";
import styled from "@emotion/styled";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SoilAnalysisParameterList = () => {

  const [personName, setPersonName] = React.useState([]);

  const navigate = useNavigate();

  const [expensesData, setExpensesData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  const [tobaccoGroups, setTobaccoGroups] = useState([]);

  // const [searchKeyword, setSearchKeyword] = useState("");

  var isApiCalled = 0;

  const [searchParams, setSearchParams] = useState({
    description: "",
    tobaccoTypeId: "",
    status: "",
  });

  const handleChanges = (event) => {
    let val = event.target.value;
    setPersonName(
      // On autofill we get a stringified value.
      typeof val === "string" ? val.split(",") : val
    );
    console.log("val::::::", val, tobaccoGroups);
    let tobaccoTypeIds = [];
    if (val.length) {
      const result = val.map((item) => item.split("-")[0]);
      console.log("result::", result);
      if (result.length) {
        result.forEach((cultureType) => {
          tobaccoGroups?.forEach((element) => {
            if (element.cultureType == cultureType) {
              tobaccoTypeIds.push(element.id);
            }
          });
        });
      }
    }
    console.log("tobaccoTypeIds::", tobaccoTypeIds);
    setSearchParams({
      ...searchParams,
      ["tobaccoTypeId"]: tobaccoTypeIds.length > 0 ? tobaccoTypeIds : "",
    });
    handleSearchApi();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
    console.log("searching parameters", searchParams);

    handleSearchApi();
  };

  const handleClear = (name) => {
    setSearchParams({
      ...searchParams,
      [name]: "",
    });
  };

  const cleanSearchParams = (params) => {
    const cleanedParams = { ...params };
    Object.keys(cleanedParams).forEach((key) => {
      if (cleanedParams[key] === "") {
        delete cleanedParams[key];
      }
    });
    return cleanedParams;
  };

  const callExpensesTypeListAPI = (pageNumber, size) => {
    setLoading(true);
    ExpensesTypeService.getExpensesTypeList({ pageNumber, size })
      .then((res) => {
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setLoading(false);
        setExpensesData(res?.data?.response.expenseWithThirdPartiesGroups);
        setTotalItems(res?.data?.response?.totalItems);
      })
      .catch((error) => {
        setLoading(false);
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setExpensesData([]);
      });
  };

  const handleSearchApi = useCallback(
    debounce((pNumber, size) => {
      const cleanedParams = cleanSearchParams(searchParams);
      console.log("cleanedParams::", cleanedParams);
      const queryParams = {
        pageNumber: pNumber,
        size: size,
        expenseswith3rdPartiesGroupDescription: cleanedParams.description,
        tobaccoTypeId: cleanedParams.tobaccoTypeId,
        status: cleanedParams.status,
      };
      ExpensesTypeService.searchExpenses(queryParams)
        .then((res) => {
          setCurrentPage(pNumber);
          setItemsPerPage(size);
          setLoading(false);
          setTotalItems(res?.data?.response?.totalItems);
          setExpensesData(res?.data?.response?.expenseWithThirdPartiesGroups);
          // setRecordsError(false);
        })
        .catch((error) => {
          setLoading(false);
          setMessage(error?.response?.data?.message || error?.message);
          setOpenAlert(true);
          setSeverity("error");
          setExpensesData([]);
        });
    }, 500),
    [searchParams]
  );

  const paginate = (pageNumber, itemsPerPage) => {
    callExpensesTypeListAPI(pageNumber - 1, itemsPerPage);
  };

  useEffect(() => {
    ExpensesTypeService.getTobbacoType()
      .then((response) => {
        setTobaccoGroups(response.data.response);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    callExpensesTypeListAPI(currentPage, itemsPerPage);
  }, []);

  useEffect(() => {
    if (Object.keys(cleanSearchParams(searchParams)).length > 0) {
      isApiCalled = 1;
      handleSearchApi(currentPage, itemsPerPage);
      console.log("api call times if", isApiCalled);
    } else if (Object.keys(cleanSearchParams(searchParams)).length == 0) {
      callExpensesTypeListAPI(currentPage, itemsPerPage);
      console.log(
        "api call times else if",
        Object.keys(cleanSearchParams(searchParams)).length,
        isApiCalled
      );
    } else {
    }

    return () => {
      handleSearchApi.cancel();
    };
  }, [searchParams]);


  return (
    <Layout
      title={"Soil Analysis Parameter - List"}
      background="#ffffff"
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      currentPage={currentPage}
      paginate={paginate}
      useNewPagination={true}
    >
      <Box
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          padding: "1rem",
          paddingBottom: "2rem",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <ArrowDropDownIcon sx={{ marginRight: 1 }} />
          <Typography>{Lable.filtersLable}</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={10}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={5}>
                <TextField
                  name="description"
                  label="Expenses with 3rd Parties Group Description"
                  value={searchParams.description}
                  onChange={handleChange}
                  sx={{
                    width: "100%",
                    height: "46px",
                  }}
                ></TextField>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="Tobacco Type">Tobacco Type</InputLabel>
                  <Select
                    labelId="Tobacco Type"
                    id="demo-multiple-checkbox"
                    multiple
                    //  / name={searchParams.tobaccoType}
                    value={personName}
                    required
                    onChange={handleChanges}
                    input={<OutlinedInput label="Tobacco Type" />}
                    renderValue={(selected) => selected.join(",")}
                    MenuProps={MenuProps}
                    variant="outlined"
                    sx={{
                      height: "55px",
                    }}
                    SelectProps={{
                      IconComponent: ArrowDropDownIcon,
                      endAdornment: (
                        <CloseIcon
                          onClick={() => handleClear("tobaccoType")}
                          style={{
                            width: "18px",
                            cursor: "pointer",
                            marginRight: "12px",
                          }}
                        />
                      ),
                    }}
                  >
                    {tobaccoGroups.map((tobacco, index) => (
                      <MenuItem
                        key={index}
                        value={tobacco.cultureType + "-" + tobacco.tobaccoGroup}
                      >
                        <Checkbox
                          checked={personName.includes(
                            tobacco.cultureType + "-" + tobacco.tobaccoGroup
                          )}
                        />
                        <ListItemText
                          primary={
                            tobacco.cultureType + "-" + tobacco.tobaccoGroup
                          }
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* <FormControl>
                            
                            <TextField
                              select
                              multiple
                              required
                              name="tobaccoType"
                              label={"Tobacco Type ssfsd"}
                              value={searchParams.tobaccoType || []}
                              onChange={handleChange}
                              variant="outlined"
                              sx={
                                {width : "420px",
                                  height:"46px"
                                }   
                             }
                              SelectProps={{
                                IconComponent: ArrowDropDownIcon,
                                endAdornment: (
                                  <CloseIcon
                                    onClick={() => handleClear("tobaccoType")}
                                    style={{
                                      width:"18px",
                                      cursor: "pointer",
                                      marginRight: "12px",
                                    }}
                                  />
                                ),
                              }}
                            >
                            {tobaccoGroups.map((tobacco,index) => (
                              <MenuItem key={index} value={tobacco.id}>
                               {tobacco.cultureType+"-"+tobacco.tobaccoGroup}
                              </MenuItem>
                            ))}
            
                            </TextField>
                          </FormControl> */}
              </Grid>

              <Grid item xs={12} sm={3}>
                <FormControl sx={{ width: "100%" }}>
                  <TextField
                    select
                    name="status"
                    label="Status"
                    sx={{
                      height: "46px",
                    }}
                    value={searchParams.status}
                    onChange={handleChange}
                    SelectProps={{
                      IconComponent: ArrowDropDownIcon,
                      endAdornment: (
                        <CloseIcon
                          onClick={() => handleClear("status")}
                          style={{
                            width: "18px",
                            cursor: "pointer",
                            marginRight: "12px",
                          }}
                        />
                      ),
                    }}
                  >
                    <MenuItem value={"Active"}>Active</MenuItem>
                    <MenuItem value={"Inactive"}>Inactive</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={2} textAlign={"right"}>
            <Button
              sx={{
                // borderRadius: 100,
                minWidth: 95,
                minHeight: 44,
                background: "#2F6FAF",
                marginTop: { xs: 1 },
                marginLeft: { xs: 1 },
              }}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => navigate("/addExpenses")}
            >
              Add New
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box>
        {!loading && expensesData && expensesData.length === 0 && (
          <Typography variant="h6" align="center" color="textSecondary">
            No Data Available
          </Typography>
        )}

        {!loading && expensesData && expensesData.length > 0 && (
          <Expenses3rdPartyTable expensesData={expensesData} />
        )}
      </Box>
    </Layout>
  );
};

const PaperContainer = styled(Paper)`
          padding: 2rem 1rem 1rem 1rem;
          background: ${(props) =>
    props.background || props.theme.palette.background.default};
        `;

export default SoilAnalysisParameterList