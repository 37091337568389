export const ONLY_NUMBER_AND_DECIMAL_ALLOWED = "Only numbers and decimals with two decimal places are allowed";
export const FIELD_REQUIRED = "This field is required";
export const PERCENTAGE_RANGE_ERROR = "Enter a value between 0 and 100";
export const SPECIAL_CHARACTER_ERROR = "This field must not contain special characters";
export const TRAILING_SPACE_ERROR = "This field must not have leading or trailing spaces";
export const CHARACTER_LIMIT_ERROR = "Character limit exceeds";

export const ADD_SUCCESS_MSG = "Added successfully";
export const UPDATE_SUCCESS_MSG = "Updated successfully";
export const DELETE_SUCCESS_MSG = "Deleted successfully";
export const SUBMISSION_FAILED = "Error while submitting form";
export const EMPTY_PICK_LIST_CODE = "Pick list code is empty";
export const LOV_NOT_FOUND = "LOV details not found!";
export const LOV_FETCH_FAILED = "Error while fetching Lov details";