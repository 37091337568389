import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Box,
  Checkbox,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  InputAdornment,
} from "@mui/material";
import { SnackbarContext } from "context/snackBarContext";
import { AuthData } from "auth/AuthWarpper";
import "./AgriSupply.css";
import EditButton from "../../../../assets/editButton.svg";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Icon } from "@mui/material";
import SurityModal2 from "../../../../../src/utils/SurityModal2/SurityModal2";
import moreActions from "../../../../assets/moreActions.svg";
import iconView from "../../../../assets/iconView.svg";
import iconEdit from "../../../../assets/iconEdit.svg";
import iconDelete from "../../../../assets/iconDelete.svg";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import plusIcon from "assets/iconLeft.svg";
import iconSearchs from "../../../../assets/iconSearchs.svg";
import AsrService from "services/AsrService";

// let selectedRow = {}

const AgriSupplyList = (props) => {
  const navigate = useNavigate();
  const { user } = AuthData();
  const [loading, setLoading] = React.useState(false);
  const { setOpenAlert, setSeverity, setMessage, handleClose } =
    useContext(SnackbarContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchParams, setSearchParams] = useState({
    search: "",
    target: "",
    thirdParty: "",
    // countryCode: user ? user.countryCode : "BRA",
  });
  const [surityModalOpen, setSurityModalOpen] = React.useState(false);
  const [surityModalMessage, setSurityModalMessage] = React.useState("");
  const [surityModalHeader, setSurityModalHeader] = React.useState("");
  const [selectedRow, setSelectedRow] = React.useState({});
  const handleMenuClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(data);
  };

  const handleMenuClose = ({ action, data }) => {
    if (!action || action == undefined) return;
    setSelectedRow(data);
    if (action == "details") {
      props.changeTab({ tab: 1, mode: "Details", state: selectedRow });
    }
    if (action == "edit") {
      props.changeTab({ tab: 1, mode: "Edit", state: selectedRow });
    }
    if (action == "edit1") {
      props.changeTab({ tab: 1, mode: "Edit", state: data });
    }
    if (action == "delete") {
      setSurityModalMessage(
        "Are you sure you want to delete this Agricultural Supply?"
      );
      setSurityModalHeader("Delete Agricultural Supply?");
      setSurityModalOpen(true);
    }
    setAnchorEl(null);
  };
  const handleClear = (name) => {
    setSearchParams({
      ...searchParams,
      [name]: "",
    });
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };
  useEffect(() => {
    props.setQueryData(searchParams);
  }, [searchParams]);

  const handleSubmit = () => {
    handleDelete(selectedRow?.asrSupplyId);
  };
  const handleDelete = (id) => {
    setLoading(true);
    AsrService.deleteAsr(id)
      .then((res) => {
        setSurityModalOpen(false);
        setLoading(false);
        setMessage("Delete Successfully");
        setOpenAlert(true);
        setSeverity("success");
        props.changeTab({ tab: 0 + 0, mode: "Add", state: {} });
      })
      .catch((error) => {
        setLoading(false);
        setSurityModalOpen(false);
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  };

  return (
    <div className="table-container">
      <div className="btns-filters">
        <Button
          style={{
            margin: "10px 30px 30px 10px",
            color: "#004F9F",
            borderColor: "#004F9F",
          }}
          required
          variant="outlined"
          startIcon={
            <Icon>
              <img
                alt="edit"
                style={{ display: "flex", justifyContent: "center" }}
                width="100%"
                height="100%"
                src={plusIcon}
              />
            </Icon>
          }
          onClick={(event) => navigate("/")}
        >
          {" "}
          Back Home
        </Button>
        <Grid container>
          <Grid item md={8}>
            <Grid container spacing={0}>
              <Grid item xs={4} md={4} lg={4} style={{ padding: "2px" }}>
                <TextField
                  sx={{ width: "200px" }}
                  name={"search"}
                  className="farmerTextField"
                  placeholder="Search"
                  variant="outlined"
                  onChange={handleChange}
                  autoComplete="off"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={iconSearchs} />
                      </InputAdornment>
                    ),
                  }}
                />
                {!props.filtersApplied && (
                  <div className="farmerNoFilter">No filters applied</div>
                )}
              </Grid>
              <Grid item xs={12} md={4} style={{ padding: "2px" }}>
                <FormControl sx={{ minWidth: "200px" }}>
                  <TextField
                    select
                    label="Target"
                    value={searchParams.tobaccoGroup}
                    onChange={handleChange}
                    name="target"
                    variant="outlined"
                    SelectProps={{
                      IconComponent: ArrowDropDownIcon,
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ padding: "2px" }}>
                <FormControl sx={{ minWidth: "200px" }}>
                  <TextField
                    select
                    label="Third Party"
                    value={searchParams.isNew}
                    onChange={handleChange}
                    name="thirdParty"
                    variant="outlined"
                    // endAdornment={
                    //   searchParams.isNew && (
                    //     <CloseIcon
                    //       onClick={() => handleClear("thirdParty")}
                    //       style={{ cursor: "pointer", marginLeft: "auto", paddingRight: "20px" }}
                    //     />
                    //   )
                    // }
                    SelectProps={{
                      IconComponent: ArrowDropDownIcon,
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={4} className="farmer-addbutton-container">
            <Button
              sx={{
                borderRadius: 100,
                minWidth: 95,
                minHeight: 44,
                background: "#102A62",
                marginBottom: { md: 4 },
                marginTop: { xs: 1 },
                marginLeft: { xs: 1 },
              }}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() =>
                props.changeTab({ tab: 1, mode: "Add", state: {} })
              }
            >
              Add Agricultural Supply
            </Button>
          </Grid>
        </Grid>
      </div>

      <table className="agri-supply-table">
        {" "}
        <thead>
          <tr className="HierarchyHead">
            <th>Agricultural Supply</th>
            <th>Code</th>
            <th>Target</th>
            <th>Third-Party</th>
            <th>Unlocked</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {props?.agriSupplyData?.map((row, index) => (
            <tr key={index} className="hierarchyColumn" style={{}}>
              <td style={{ textAlign: "center" }}>
                <Typography
                  sx={{
                    justifyContent: "left",
                    fontWeight: 600,
                    color: "#3B454F",
                  }}
                >
                  {row.asrSupplyName}
                </Typography>
              </td>
              <td style={{ textAlign: "center" }}>
                <Box
                  sx={{
                    whiteSpace: "nowrap",
                    paddingLeft: "5px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontWeight: 600,
                    color: "#3B454F",
                  }}
                >
                  {row.asrCode}
                </Box>
              </td>
              <td style={{ textAlign: "center" }}>
                <div
                  className="hierarchyConfig"
                  style={{
                    fontWeight: 600,
                    color: "#3B454F",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {row.asrTarget ? "Yes" : "No"}
                </div>
              </td>
              <td
                style={{
                  textAlign: "center",
                  fontWeight: 600,
                  color: "#3B454F",
                }}
              >
                {row.asrThirdPartyAgriculturalSupply ? "Yes" : "No"}
              </td>
              <td
                style={{
                  textAlign: "center",
                  fontWeight: 600,
                  color: "#3B454F",
                }}
              >
                {row.asrUnlockModality}
              </td>
              <td
                style={{
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={EditButton}
                    onClick={() =>
                      handleMenuClose({ action: "edit1", data: row, tab: 1 })
                    }
                  />
                  <Button
                    variant="outlined"
                    className="moreButton"
                    style={{ marginLeft: "10px" }}
                    onClick={(e) => handleMenuClick(e, row)}
                  >
                    <div>
                      <img src={moreActions} alt="more-actions" />
                    </div>{" "}
                    <div style={{ height: "14px" }}>More</div>
                  </Button>
                  <div key={index}>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={() =>
                        handleMenuClose({ action: "Add", data: row, tab: 0 })
                      }
                      key={index}
                    >
                      <div className="">
                        <MenuItem
                          className="gridActionCell"
                          sx={{
                            border: "1px solid #50AF47 !important",
                            display: "flex",
                            justifyContent: "space-around",
                            cursor: "ponter",
                          }}
                          onClick={() =>
                            handleMenuClose({
                              action: "details",
                              data: selectedRow,
                              tab: 1,
                            })
                          }
                        >
                          <img src={iconView} height="30px" width="30px" />
                          <div>Details</div>
                        </MenuItem>
                        <MenuItem
                          className="gridActionCell"
                          sx={{
                            border: "1px solid #FBBA00 !important",
                            display: "flex",
                            justifyContent: "space-around",
                            cursor: "ponter",
                          }}
                          onClick={() =>
                            handleMenuClose({
                              action: "edit",
                              data: selectedRow,
                              tab: 1,
                            })
                          }
                        >
                          <img src={iconEdit} height="30px" width="30px" />
                          <div>Edit</div>
                        </MenuItem>
                        <MenuItem
                          className="gridActionCell"
                          sx={{
                            border: "1px solid #EF0000 !important",
                            display: "flex",
                            justifyContent: "space-around",
                            cursor: "ponter",
                          }}
                          onClick={() =>
                            handleMenuClose({
                              action: "delete",
                              data: selectedRow,
                              tab: 0,
                            })
                          }
                        >
                          <img src={iconDelete} height="30px" width="30px" />
                          <div>Delete</div>
                        </MenuItem>
                      </div>
                    </Menu>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <SurityModal2
        message={surityModalMessage}
        surityModalHeader={surityModalHeader}
        loading={loading}
        string={"string"}
        onNext={() => {
          handleSubmit();
        }}
        open={surityModalOpen}
        onClose={() => {
          setSurityModalOpen(false);
        }}
      />
    </div>
  );
};

export default AgriSupplyList;
