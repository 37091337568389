import * as React from "react";
import Box from "@mui/material/Box";
import { Button, CircularProgress } from "@mui/material";
import * as Yup from "yup";
import { Icon } from "@mui/material";
import plusIcon from "assets/iconLeft.svg";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { asrSchema } from "utils/validations";
import RenderField from "utils/RenderField";
import { useLocation, useNavigate } from "react-router-dom";
import { asrField } from "./AgriSupplyFormConfig";
import { useEffect } from "react";
import { useContext } from "react";
import { SnackbarContext } from "context/snackBarContext";
import AsrService from "services/AsrService";
import { useTranslation } from 'react-i18next';

export default function AgriSupplyForm(props) {
  const { t } = useTranslation();
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [values, setValues] = useState({});
  const [asrSupply, setAsrSupply] = useState(null);

  const [errors, setErrors] = useState({});
  const [dateError, setDateError] = useState(false);
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    if (props.state) {
      setValues(props.state);
    }
  }, [props.state]);

  useEffect(() => {
    if (props.mode == "Details") {
      asrField.forEach((item, i) => (item["disabled"] = true));
      setValues((prevValues) => ({
        ...prevValues,
        ["asrSupplyName"]: props?.state?.asrCode,
        ["asrTarget"]: props?.state?.asrTarget == true ? "Yes" : "No",
      }));
    } else if (props.mode == "Edit") {
      setValues((prevValues) => ({
        ...prevValues,
        ["asrSupplyName"]: props?.state?.asrCode,
        ["asrTarget"]: props?.state?.asrTarget == true ? "Yes" : "No",
      }));
      asrField.forEach((item, i) => {
        if (
          item.name == "asrCode" ||
          item.name == "asrExternalCode" ||
          item.name == "asrSupplyName"
        ) {
          item["disabled"] = true;
        } else {
          item["disabled"] = false;
        }
      });
    } else {
      asrField.forEach((item, i) => (item["disabled"] = false));
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    AsrService.getAsrSupply()
      .then((res) => {
        setLoading(false);
        let data = res?.data?.response;
        setAsrSupply(data);
        let options = [{ value: "", label: "None" }];
        if (data) {
          data.map((ele) => {
            let obj = {
              value: ele?.asrCode,
              label: ele?.asrSupplyName,
            };
            options.push(obj);
          });
        }
        asrField.forEach((item, i) => {
          if (item.name == "asrSupplyName") {
            item.options = options;
          }
          if (item.name == "asrCode") {
            item.disabled = true;
          }
          if (item.name == "asrExternalCode") {
            item.disabled = true;
          }
        });
        console.log("asrFields", asrField);
      })
      .catch((error) => {
        setAsrSupply([]);
        setLoading(false);
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
      });
  }, []);

  const handleChange = (event, data) => {
    if (data && data == "Manage Price Button") {
      setMessage(
        "This operation is not allowed. Please contact system administrator"
      );
      setSeverity("error");
      setOpenAlert(true);
      return;
    }
    const { name, value, type, checked } = event.target;
    if (name === "asrSupplyName") {
      let asrExternal = asrSupply.find((ele) => ele.asrCode == value);
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
        ["asrName"]: asrExternal?.asrSupplyName,
        ["asrCode"]: value,
        ["asrExternalCode"]: asrExternal?.asrExternalCode,
      }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };
  const handleDateChange = (date, fieldName) => {
    if (!date || date === "Invalid Date") {
      setDateError(true);
      setValues((prevValues) => ({
        ...prevValues,
        [fieldName]: "",
      }));
      return;
    }

    setDateError(false);

    const dateValue = new Date(date);
    if (isNaN(dateValue.getTime())) {
      setDateError(true);
      setValues((prevValues) => ({
        ...prevValues,
        [fieldName]: "",
      }));
      return;
    }
    const year = dateValue.getFullYear();
    const month = String(dateValue.getMonth() + 1).padStart(2, "0");
    const day = String(dateValue.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setValues((prevValues) => ({
      ...prevValues,
      [fieldName]: formattedDate,
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await asrSchema.validate(values, { abortEarly: false });
      setErrors({});
      const payload = {
        createdByUser: "Sunil kumar",
        modifiedDateTimeUTC: "2024-06-12T10:30:52.707Z",
        modifiedByUser: "Sunil Kumar",
        asrSupplyName: values.asrName,
        asrCode: values.asrCode,
        asrExternalCode: values.asrExternalCode,
        asrUnlockModality: values.asrUnlockModality,
        asrUnitOfMeasurement: values.asrUnitOfMeasurement,
        asrTarget: values.asrTarget == "Yes" ? true : false,
        asrRecommendationType: values.asrRecommendationType,
        asrRecommendation: values.asrRecommendation,
        // asrRecommendation: values.asrRecommendation,
        asrSupplyType: values.asrSupplyType,
        asrMaxPacking: values.asrMaxPacking,
        // asrSupplyType: "Ferti",
        asrThirdPartyAgriculturalSupply:
          values.asrThirdPartyAgriculturalSupply == true ? true : false,
      };
      setLoading(true);
      let response;
      if (props.mode == "Edit") {
        payload.asrSupplyId = props?.state?.asrSupplyId;
        payload.asrSupplyName = props?.state?.asrSupplyName;
        response = await AsrService.updateAsr(payload);
      } else {
        response = await AsrService.createAsr(payload);
      }
      if (response.status === 200) {
        props.changeTab({ tab: 0, mode: "Add", state: {} });
        setSeverity("success");
        setMessage(
          props.mode == "Edit" ? t("snackbarMessage.updatedSuccessfully") : t("snackbarMessage.successAdd")
        );
        setOpenAlert(true);
        setLoading(false);
      } else {
        setSeverity("error");
        setMessage("Failed to submit form");
        setOpenAlert(true);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        let newError = {};
        err?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setErrors(newError);
      } else {
        setSeverity("error");
        setMessage(err?.response?.data?.message || err.message);
        setOpenAlert(true);
      }
    }
  };

  const handleClear = (field) => {
    setValues((prevValues) => ({
      ...prevValues,
      [field]: "",
    }));
  };

  return (
    <Box
      sx={{
        marginBottom: "200px",
        minHeight: 180,
        flexGrow: 1,
        width: "100%",
        "& .MuiTextField-root": {
          m: 1,
          color: "green !important",
          width: "25ch",
        },
        "& .MuiFormLabel-root": {
          color: "rgba(16, 42, 98, 1)",
          "& .MuiFormLabel-asterisk": {
            color: "rgba(239, 0, 0, 1)",
            fontSize: "18px",
          },
          "& .Mui-focused": {
            color: "red !important",
          },
        },
      }}
    >
      <Button
        style={{
          margin: "30px 0px 30px 10px",
          color: "#004F9F",
          borderColor: "#004F9F",
        }}
        required
        variant="outlined"
        startIcon={
          <Icon>
            <img
              alt="edit"
              style={{ display: "flex", justifyContent: "center" }}
              width="100%"
              height="100%"
              src={plusIcon}
            />
          </Icon>
        }
        onClick={() => props.changeTab({ tab: 0, mode: "Add", state: {} })}
      >
        {" "}
        Back Home
      </Button>
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <div>
          {asrField.slice(0, 3).map((field) => (
            <RenderField
              key={field.name}
              field={field}
              values={values}
              errors={errors}
              handleChange={handleChange}
              handleDateChange={handleDateChange}
              handleClear={handleClear}
            />
          ))}
        </div>

        <div>
          {asrField.slice(3, 7).map((field) => (
            <RenderField
              key={field.name}
              field={field}
              values={values}
              errors={errors}
              handleChange={handleChange}
              handleDateChange={handleDateChange}
              handleClear={handleClear}
            />
          ))}
        </div>
        <div>
          {asrField.slice(7, 11).map((field) => (
            <>
              <RenderField
                key={field.name}
                field={field}
                values={values}
                errors={errors}
                handleChange={handleChange}
                handleDateChange={handleDateChange}
                handleClear={handleClear}
              />
            </>
          ))}
        </div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "2rem",
            position: "absolute",
            bottom: "160px",
            right: "80px",
          }}
        >
          {props.mode != "Details" && (
            <Button
              sx={{
                backgroundColor: "#EF7D00",
                padding: " 6px, 16px, 6px, 11px",
                borderRadius: " 4px",
                color: "white",
              }}
              type="submit"
              variant="contained"
            >
              {loading && <CircularProgress size={20} />}
              {props.mode === "Edit" ? "Update Changes" : "Save Changes"}
            </Button>
          )}
        </Box>
      </form>
    </Box>
  );
}
