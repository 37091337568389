import React, { useEffect, useState, useContext } from "react";
import { Typography, Box, Button, Modal, Tooltip } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import "./PaymentPolicyTable.css";
import _ButtonEdit_ from "assets/_ButtonEdit_.svg";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import { useNavigate, useLocation } from "react-router-dom";
import PaymentService from "services/PaymentPolicyService";
import { SnackbarContext } from "context/snackBarContext";
import {
    DELETE_SUCCESS_MSG
} from 'utils/AgriErrorMsg';


const PaymentPolicyTable = (props) => {
    const navigate = useNavigate();
    const [paymentData, setPaymentData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
    const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false); // Modal state
    const [deleteId, setDeleteId] = useState(null);
    useEffect(() => {
        setPaymentData(props.paymentData);
        setOriginalData(props.paymentData);
    }, [props.paymentData]);

    // const handleSort = (key) => {
    //     let direction = "asc";
    //     if (sortConfig.key === key && sortConfig.direction === "asc") {
    //         direction = "desc";
    //     }
    //     setSortConfig({ key, direction });

    //     const sortedData = [...paymentData].sort((a, b) => {
    //         if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
    //         if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
    //         return 0;
    //     });
    //     console.log("orgData", paymentData);
    //     console.log("sortData", sortedData);
    //     setPaymentData(sortedData);
    // };

    const handleSort = (key) => {
        let direction = "asc";
        if (sortConfig.key === key && sortConfig.direction === "asc") {
            direction = "desc";
        }
        setSortConfig({ key, direction });

        const sortedData = [...paymentData].sort((a, b) => {
            const aValue = a[key] ? String(a[key]).toLowerCase() : "";
            const bValue = b[key] ? String(b[key]).toLowerCase() : "";

            if (aValue < bValue) return direction === "asc" ? -1 : 1;
            if (aValue > bValue) return direction === "asc" ? 1 : -1;
            return 0;
        });
        console.log("orgData", paymentData);
        console.log("sortData", sortedData);
        setPaymentData(sortedData);
    };

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            if (sortConfig.direction === "asc") {
                return <ArrowUpwardIcon fontSize="small" />;
            } else if (sortConfig.direction === "desc") {
                return <ArrowDownwardIcon fontSize="small" />;
            }
        }
        return <ArrowDownwardIcon fontSize="small" />;
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleDeleteClick = (paymentTypeId) => {
        setDeleteId(paymentTypeId);
        setOpen(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            setLoading(true);
            const response = await PaymentService.deletePayment(deleteId);
            if (response.status === 200) {
                setMessage(DELETE_SUCCESS_MSG);
                setSeverity("success");
                setOpenAlert(true);
                setPaymentData((prevData) =>
                    prevData.filter((item) => item.paymentPolicyId !== deleteId)
                );
                props.refreshData()
            } else {
                setMessage("Failed to delete payment policy");
                setSeverity("error");
                setOpenAlert(true);
            }
        } catch (error) {
            setMessage("Error deleting payment policy");
            setSeverity("error");
            setOpenAlert(true);
            console.error("Error deleting payment policy:", error);
        } finally {
            setLoading(false);
            setOpen(false);
        }
    };


    return (
        <div className="table-container">
            <table className="custom-table">
                <thead>
                    <tr>
                        <th
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer",
                                }}
                                onClick={() => handleSort("PPDescription")}
                            >
                                Payment Policy Description {getSortIcon("PPDescription")}
                            </div>
                        </th>
                        <th
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    justifyContent: "left",
                                }}
                                onClick={() => handleSort("status")}
                            >
                                Status {getSortIcon("status")}
                            </div>
                        </th>
                        <th className="actions-header">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {paymentData?.map((row, index) => (
                        <tr
                            key={index}
                        >

                            <td
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                    navigate("/viewPaymentPolicies", {
                                        state: { row: row, edit: false },
                                    })
                                }>{row.PPDescription}</td>
                            <td
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                    navigate("/viewPaymentPolicies", {
                                        state: { row: row, edit: false },
                                    })
                                }>{row.status ? "Active" : "Inactive"}</td>
                            <td className="actions-cell">
                                <Tooltip
                                    title="EDIT"
                                    placement="top"
                                    arrow
                                    PopperProps={{
                                        modifiers: [
                                          {
                                            name: "offset",
                                            options: {
                                              offset: [0, -5],
                                            },
                                          },
                                        ],
                                      }}
                                    >
                                    <img
                                        className="btn edit-btn"
                                        src={_ButtonEdit_}
                                        alt="Edit Button"
                                        onClick={() =>
                                            navigate("/editPaymentPolicies", {
                                                state: { row: row, edit: true },
                                            })
                                        }
                                    />
                                </Tooltip>
                                <Tooltip
                                    title="DELETE"
                                    placement="top"
                                    arrow
                                    PopperProps={{
                                        modifiers: [
                                          {
                                            name: "offset",
                                            options: {
                                              offset: [0, -5],
                                            },
                                          },
                                        ],
                                      }}
                                    >
                                    <img
                                        className="btn delete-btn"
                                        onClick={() => handleDeleteClick(row.paymentPolicyId)}
                                        src={_ButtonDelete_}
                                        alt="Delete"
                                    />
                                </Tooltip>

                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Modal for delete confirmation */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        backgroundColor: "#fff",
                        borderRadius: "7px",
                        width: "520px",
                        margin: "auto",
                        mt: 25,
                        height: "200px",
                    }}
                >
                    <Box
                        sx={{
                            background: "#102A62",
                            height: "50px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderTopLeftRadius: "7px",
                            borderTopRightRadius: "7px",
                        }}
                    >
                        <Typography
                            sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
                        >
                            Delete Confirmation
                        </Typography>
                    </Box>
                    <Box sx={{ p: 2 }}>
                        <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2, fontWeight: 400 }}
                        >
                            Are you sure you want to delete this record? Deleted records
                            cannot be recovered.
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "20px",
                            mb: 1,
                        }}
                    >
                        <Button
                            onClick={handleClose}
                            sx={{
                                background: "#737D86",
                                width: "100px",
                                color: "#ffffff",
                                "&:hover": { background: "#737D86" },
                            }}
                        >
                            No
                        </Button>
                        <Button
                            onClick={handleDeleteConfirm}
                            sx={{
                                background: "#004F9F",
                                width: "100px",
                                color: "#ffffff",
                                "&:hover": { background: "#004F9F" },
                            }}
                        >
                            Yes
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

export default PaymentPolicyTable;