import { httpGrow } from "../http-common";

const getPlanting = async (query) => {
  const response = await httpGrow.get(`/plantingType?pageNumber=${query?.pageNumber}&pageSize=${query?.size}`)
  return response;
}
const createPlanting = (data) => {
  return httpGrow.post(
    `/plantingType`, data
  );
};
const updatePlanting = (data) => {
  return httpGrow.put(`plantingType`, data);
};

//  const searchPlanting = async (query) => {
//   const response = await httpGrow.get(`/plantingType/search?pageNumber=${query?.pageNumber}&size=${query?.size}&keyword=${query?.keyword}&status=${query?.status}`);
//   return response;
// };

const searchPlanting = async (query) => {
  const response = await httpGrow.get(
    `/plantingType/search?pageNumber=${query?.pageNumber}&pageSize=${query?.size || 50}&keyword=${query?.keyword || ''}&status=${query?.status !== undefined ? query.status : ''}`
  );
  return response;
};


const deletePlanting = (plantingTypeId) => {
  return httpGrow.delete(`/plantingType/${plantingTypeId}`);
};

const PlantingService = {
  getPlanting,
  createPlanting,
  updatePlanting,
  searchPlanting,
  deletePlanting
};



export default PlantingService;