import { navigationHistory } from "utils/navigationHistory";
import iconView from "assets/iconView.svg";
import { GridActionsCellItem } from "@mui/x-data-grid";
import editButton from "assets/editButton.svg";
import {
    ACTIONS_TBL_FIELD_LABEL,
    ACTIONS_TBL_FIELD_NAME,
    CODE_TBL_FIELD_LABEL,
    CODE_TBL_FIELD_NAME,
    DESCRIPTION_TBL_FIELD_LABEL,
    DESCRIPTION_TBL_FIELD_NAME,
    DETAILS_TBL_FIELD_LABEL,
    EDIT_TBL_FIELD_LABEL,
    STATUS_FIELD_LABEL,
    STATUS_FIELD_NAME,
    SUPPLY_TYPE_FIELD_LABEL,
    SUPPLY_TYPE_FIELD_NAME,
    UOM_FIELD_NAME,
    UOM_TBL_FIELD_LABEL
} from "./util/FieldNotebookSupplyConstants";

export const fieldNotebookSupplyColumns = [
    {
        field: CODE_TBL_FIELD_NAME,
        headerName: CODE_TBL_FIELD_LABEL,
        flex: 1,
        headerAlign: "center",
        align: "center",
    },
    {
        field: DESCRIPTION_TBL_FIELD_NAME,
        headerName: DESCRIPTION_TBL_FIELD_LABEL,
        flex: 1,
        headerAlign: "center",
        align: "center",
    },
    {
        field: UOM_FIELD_NAME,
        headerName: UOM_TBL_FIELD_LABEL,
        flex: 1,
        headerAlign: "center",
        align: "center",
    },
    {
        field: SUPPLY_TYPE_FIELD_NAME,
        headerName: SUPPLY_TYPE_FIELD_LABEL,
        flex: 1,
        headerAlign: "center",
        align: "center",
    },
    {
        field: STATUS_FIELD_NAME,
        headerName: STATUS_FIELD_LABEL,
        flex: 1,
        headerAlign: "center",
        align: "center",
    },
    {
        field: ACTIONS_TBL_FIELD_NAME,
        type: "actions",
        headerName: ACTIONS_TBL_FIELD_LABEL,
        flex: 1,
        headerAlign: "center",
        align: "center",
        getActions: (params) => {
            return [
                <GridActionsCellItem
                    icon={<img src={editButton} alt="edit" />}
                    label={EDIT_TBL_FIELD_LABEL}
                    className="gridActionCellEditSingle"
                    onClick={() =>
                        navigationHistory.navigate(`/editAgriculturalSupplyRegistraion`, {
                            state: {
                                id: params.id,
                                edit: true,
                            },
                        })
                    }
                />,

                <GridActionsCellItem
                    icon={<img src={iconView} alt="View" />}
                    label={DETAILS_TBL_FIELD_LABEL}
                    className="gridActionCellEditSingle"
                    onClick={() =>
                        navigationHistory.navigate(`/viewAgriculturalSupplyRegistraion`, {
                            state: {
                                id: params.id,
                                edit: false,
                            },
                        })
                    }
                />,
            ];
        },
    },
];