import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  Grid,
  InputLabel,
} from "@mui/material";
import { debounce } from "lodash";
import Layout from "components/structure/Layout";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React, { useCallback, useContext, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import GreenManureService from "services/GreenManureService";
import { useEffect } from "react";
import Lable from "utils/lables.json";
import InvestmentTable from "./InvestmentTable";
import InvestmentService from "services/InvestmentService";
import { id } from "date-fns/locale";
import { SnackbarContext } from "context/snackBarContext";
import CloseIcon from "@mui/icons-material/Close";
import label from "../growingLabel.json";

const InvestmentsGroupRegistration = () => {
  // const navigate = useNavigate();
  // const [greenData, setGreenData] = useState([]);
  // console.log("🚀 ~ InvestmentsGroupRegistration ~ greenData:", greenData);
  // const [tobaccoType, setTobaccoType] = useState("");
  // console.log("🚀 ~ tobaccoType:", tobaccoType);
  // const [currentPage, setCurrentPage] = useState(0);
  // const [itemsPerPage, setItemsPerPage] = useState(7);
  // const [totalItems, setTotalItems] = useState(5); // Number of items per page
  // const [manureOptions, setManureOptions] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [keyword, setKeyword] = useState("");
  // const [status, setStatus] = useState("");
  // console.log(
  //   "🚀 ~ InvestmentsGroupRegistration ~ manureOptions:",
  //   manureOptions
  // );
  // const [options, setOptions] = useState([]);
  // console.log("🚀 ~ AddInvestment ~ options:", options);


  // useEffect(() => {
  //   const fetchTobaccoTypes = async () => {
  //     try {
  //       const response = await InvestmentService.getTobaccoType();
  //       console.log("🚀 ~ fetchTobaccoTypes ~ response:", response);
  //       const options = response.data.response.map((item) => ({
  //         value: item.id, // Adjust according to your response structure
  //         label: `${item.cultureType} - ${item.tobaccoGroup}`, // Adjust according to your response structure
  //       }));
  //       console.log("🚀 ~ options ~ options:", options);

  //       setOptions(options);
  //     } catch (error) {
  //       console.error("Error fetching tobacco types:", error);
  //     }
  //   };

  //   fetchTobaccoTypes();
  // }, []);

  // const paginate = (pageNumber, itemsPerPage) => {
  //   callManureApi(pageNumber - 1, itemsPerPage);
  // };

  // const fetchManureDataFull = async () => {
  //   try {
  //     const response = await InvestmentService.searchInvestmentFull();
  //     const data = response?.data?.response?.investmentGroups;
  //     setManureOptions(data);
  //   } catch (error) {
  //     console.error("Error fetching manure data:", error);
  //   }
  // };

  // useEffect(() => {
  //   if (keyword) {
  //     setLoading(true);
  //     const fetchManureData = async () => {
  //       try {
  //         const response = await InvestmentService.searchInvestmentData(
  //           keyword
  //         );
  //         const data = response?.data?.response?.investmentGroups;
  //         setManureOptions(data);
  //       } catch (error) {
  //         console.error("Error fetching manure data:", error);
  //         setManureOptions([]); // Clear options on error
  //       } finally {
  //         setLoading(false);
  //       }
  //     };
  //     fetchManureData();
  //   } else {
  //     fetchManureDataFull();
  //     // setManureOptions([]); // Clear options if no keyword
  //   }
  // }, [keyword]);

  // const fetchManureData = async (keyword) => {
  //   console.log('api called')
  //   try {
  //     const response = await InvestmentService.searchInvestmentData(keyword);
  //     console.Console.log('response:::', response)
  //     const data = response?.data?.response?.investmentGroups;

  //     setManureOptions(data);
  //     setGreenData(data);
  //     setTotalItems(response?.data?.response?.totalItems);
  //   } catch (error) {
  //     console.error("Error fetching manure data:", error);
  //     setManureOptions([]); // Clear options on error
  //     setGreenData([]);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const callManureApi = (pNumber, size) => {
  //   // setLoading(true);
  //   // setRecordsError(false);
  //   InvestmentService.getInvestment({ pageNumber: pNumber, size: size })
  //     .then((res) => {
  //       // setLoading(false);
  //       // setRecordsError(false);
  //       console.log('resresres:', res);
  //       setGreenData(res?.data?.response?.investmentGroups);
  //       setItemsPerPage(size);
  //       setTotalItems(res?.data?.response?.totalItems);
  //       setCurrentPage(pNumber);
  //     })
  //     .catch((error) => {
  //       // setMessage(error?.response?.data?.message || error?.message);
  //       // setOpenAlert(true);
  //       // setSeverity("error");
  //     });
  // };

  // const filterInvestment = async (status) => {
  //   try {
  //     const response = await InvestmentService.filterInvestment(status);
  //     const data = response?.data?.response?.investmentGroups;
  //     setGreenData(data);
  //     setTotalItems(response?.data?.response?.totalItems);

  //     console.log(response.data); // Handle the API response
  //   } catch (error) {
  //     setGreenData([]);
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // const filterInvestmentStatus = async (status) => {
  //   try {
  //     const response = await InvestmentService.filterInvestmentStatus(status);
  //     const data = response?.data?.response?.investmentGroups;
  //     setGreenData(data);
  //     setTotalItems(response?.data?.response?.totalItems);

  //     console.log(response.data); // Handle the API response
  //   } catch (error) {
  //     setGreenData([]);
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // const filterInvestmentTobaccoAndStatus = async (tobacco, status) => {
  //   try {
  //     const response = await InvestmentService.filterInvestmentTobaccoAndStatus(
  //       tobacco,
  //       status
  //     );
  //     const data = response?.data?.response?.investmentGroups;
  //     setGreenData(data);
  //     setTotalItems(response?.data?.response?.totalItems);
  //     console.log(response.data); // Handle the API response
  //   } catch (error) {
  //     setGreenData([]);
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // const filterInvestmentKeywordAndTobacco = async (keyword, tobacco) => {
  //   try {
  //     const response =
  //       await InvestmentService.filterInvestmentKeywordAndTobacco(
  //         keyword,
  //         tobacco
  //       );
  //     const data = response?.data?.response?.investmentGroups;
  //     setGreenData(data);
  //     setTotalItems(response?.data?.response?.totalItems);
  //     console.log(response.data); // Handle the API response
  //   } catch (error) {
  //     setGreenData([]);
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // const handleTobaccoTypeChange = (e) => {
  //   const value = e.target.value;
  //   console.log("🚀 ~ handleTobaccoTypeChange ~ value:", value);
  //   setTobaccoType(value);

  //   // if (value === "Active") {
  //   //   filterManure(true);
  //   // } else if (value === "Inactive") {
  //   //   filterManure(false);
  //   // }

  //   if (keyword) {
  //     filterInvestmentKeywordAndTobacco(keyword, value);
  //   } else {
  //     filterInvestment(value);
  //   }
  // };

  // const filterInvestmentKeywordAndStatus = async (keyword, status) => {
  //   try {
  //     const response = await InvestmentService.filterInvestmentKeywordAndStatus(
  //       keyword,
  //       status
  //     );
  //     const data = response?.data?.response?.investmentGroups;
  //     setGreenData(data);
  //     setTotalItems(response?.data?.response?.totalItems);
  //     console.log(response.data); // Handle the API response
  //   } catch (error) {
  //     setGreenData([]);
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // const filterAll = async (keyword, tobacco, status) => {
  //   try {
  //     const response = await InvestmentService.filterAll(
  //       keyword,
  //       tobacco,
  //       status
  //     );
  //     const data = response?.data?.response?.investmentGroups;
  //     setGreenData(data);
  //     setTotalItems(response?.data?.response?.totalItems);
  //     console.log(response.data); // Handle the API response
  //   } catch (error) {
  //     setGreenData([]);
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // const handleStatusChange = (e) => {
  //   const value = e.target.value;
  //   setStatus(value);

  //   // Call based on tobacco type and status if tobaccoType is selected
  //   if (tobaccoType && keyword) {
  //     if (value === "Active") {
  //       filterAll(keyword, tobaccoType, true);
  //     } else if (value === "Inactive") {
  //       filterAll(keyword, tobaccoType, false);
  //     }
  //   } else if (keyword) {
  //     if (value === "Active") {
  //       filterInvestmentKeywordAndStatus(keyword, true);
  //     } else if (value === "Inactive") {
  //       filterInvestmentKeywordAndStatus(keyword, false);
  //     }
  //   } else if (tobaccoType) {
  //     if (value === "Active") {
  //       filterInvestmentTobaccoAndStatus(tobaccoType, true);
  //     } else if (value === "Inactive") {
  //       filterInvestmentTobaccoAndStatus(tobaccoType, false);
  //     }
  //   } else {
  //     if (value === "Active") {
  //       filterInvestmentStatus(true);
  //     } else if (value === "Inactive") {
  //       filterInvestmentStatus(false);
  //     }
  //   }
  // };

  // const clearFilter = async () => {
  //   setTobaccoType("");
  //   if (tobaccoType && keyword) {
  //     filterAll(keyword, tobaccoType);
  //   } else if (keyword) {
  //     fetchManureData(keyword);
  //   } else {
  //     callManureApi(currentPage, itemsPerPage);
  //   }
  // };

  // //clearFilterStatus

  // const clearFilterStatus = async () => {
  //   setStatus("");
  //   if (tobaccoType && keyword) {
  //     filterAll(keyword, tobaccoType);
  //   } else {
  //     callManureApi(currentPage, itemsPerPage);
  //   }
  // };

  // const filterManureAutocomplete = async (keyword) => {
  //   try {
  //     const response = await GreenManureService.filterManureAutocomplete(
  //       keyword
  //     );
  //     const data = response?.data?.response?.greenManures;
  //     setTobaccoType("");
  //     setGreenData(data);
  //   } catch (error) {
  //     console.error("Error fetching manure data:", error);
  //     return [];
  //   }
  // };
  const navigate = useNavigate();
  const [investmentData, setInvestmentData] = useState([]);
  const [filteredInvestmentData, setFilteredInvestmentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  // const [itemsPerPage, setItemsPerPage] = useState(5);
  const [itemsPerPage, setItemsPerPage] = useState(() => {
    return parseInt(localStorage.getItem("IPPIG")) || 5;
  });
  const [totalItems, setTotalItems] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [tobaccoType, settobaccoType] = useState("");
  const [focused, setFocused] = useState(false);
  const [menu, setMenu] = useState([]);
  const [tobaccoTypeMenu, settobaccoTypeMenu] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [tobaccoTypeFullList, settobaccoTypeFullList] = useState([]);

  const handleTobaccoChange = (value) => {
    console.log("cllll");

    if (value.length) {
      const selected = value.split("-")[0];
      if (selected.length) {
        tobaccoTypeFullList.forEach(element => {
          if (element === selected) {
            settobaccoType(element.id)
          }
        });
      }
    }
    // settobaccoType(value);
  };
  const handleStatusChange = (status) => {

    setStatusFilter(status === "Active" ? "Active" : status === "Inactive" ? "Inactive" : null); // Update the status filter
    setCurrentPage(0); // Reset to the first page when the status changes

    // Convert the status to a boolean value or null
    const isActive =
      status === "Active" ? true : status === "Inactive" ? false : null;

    // Call the API with the status filter
    callSearchInvestmentAPI(
      0,
      itemsPerPage,
      searchKeyword,
      tobaccoType,
      isActive
    );
  };

  const callInvestmentAPI = (pageNumber, size) => {
    console.log("7654kjhgf", pageNumber, size);
    setLoading(true);
    InvestmentService.getInvestment({ pageNumber, size })
      .then((res) => {
        const investmentResults = res?.data?.response?.investmentGroups || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setInvestmentData(investmentResults);
        setFilteredInvestmentData(investmentResults);
        setTotalItems(res?.data?.response?.totalItems || 0);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setInvestmentData([]);
        setFilteredInvestmentData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getfullInvestmentMenuAPI = () => {
    // console.log("asdfghjkl");
    setLoading(true);
    InvestmentService.getInvestment({ pageNumber: currentPage, size: 50 })
      .then((res) => {
        console.log("ress", res);
        const investmentResults = res?.data?.response?.investmentGroups || [];
        const tobaccoType = investmentResults.map((e) => e.tobaccoType || []);
        const menudes = investmentResults.map((e) => e.igInvestmentGroupDescription || []);
        setMenu(menudes);
        settobaccoTypeMenu(tobaccoType);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setInvestmentData([]);
        setFilteredInvestmentData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const callSearchInvestmentAPI = (pageNumber, size, Keyword, tobaccoType, status) => {
    setLoading(true);
    setSearchKeyword(Keyword);

    let query = {
      pageNumber,
      size,
      investmentGroupDescription: Keyword,
      tobaccoType: tobaccoType,
    };

    // Only add the status filter if it's defined (true or false)
    if (status !== null && status !== undefined) {
      query.status = status;
    }

    InvestmentService.searchInvestment(query)
      .then((res) => {
        const Results = res?.data?.response?.investmentGroups || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setInvestmentData(Results); // Update trayData
        setFilteredInvestmentData(Results); // Update filtered data
        setTotalItems(res?.data?.response?.totalItems || 0);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setInvestmentData([]);
        setFilteredInvestmentData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    InvestmentService.getTobaccoType()
      .then((response) => {
        settobaccoTypeFullList(response.data.response);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    if (searchKeyword == "" && tobaccoType == "" && statusFilter == "") {
      callInvestmentAPI(currentPage, itemsPerPage);
    }
    else {
      callSearchInvestmentAPI(currentPage, itemsPerPage, searchKeyword, tobaccoType, statusFilter)
    }
    // Fetch default data
    console.log("cp", currentPage);
    if (currentPage <= 0) {
      getfullInvestmentMenuAPI();
    }
  }, [currentPage, itemsPerPage]);

  const paginate = (pageNumber, itemsPerPage) => {
    localStorage.setItem("IPPIG", itemsPerPage);
    setCurrentPage(pageNumber - 1);
    if (searchKeyword || statusFilter || tobaccoType) {
      const isActive =
        statusFilter === "Active" ? true : statusFilter === "Inactive" ? false : null;
      callSearchInvestmentAPI(pageNumber - 1, itemsPerPage, searchKeyword, tobaccoType, isActive);
    } else {
      callInvestmentAPI(pageNumber - 1, itemsPerPage);
    }
  };

  const refreshData = () => {
    // Call the API again after an item is deleted
    const totalItemsOnPage = filteredInvestmentData?.length ?? 0;

    if (searchKeyword === "" && statusFilter === "" && tobaccoType == "") {
      console.log("NO FILTERS APPLIED")
      if (totalItemsOnPage === 1 && currentPage > 0) {
        callInvestmentAPI(currentPage - 1, itemsPerPage);
      } else if (totalItemsOnPage === 1 && currentPage == 0) {
        setFilteredInvestmentData([]);
      } else {
        callInvestmentAPI(currentPage, itemsPerPage);
      }
    }
    else {
      const isActive =
        statusFilter === "Active" ? true : statusFilter === "Inactive" ? false : null;
      if (totalItemsOnPage === 1 && currentPage > 0) {
        callSearchInvestmentAPI(currentPage - 1, itemsPerPage, searchKeyword, tobaccoType, isActive);
      } else if (totalItemsOnPage === 1 && currentPage == 0) {
        setFilteredInvestmentData([]);
        callSearchInvestmentAPI(currentPage, itemsPerPage, searchKeyword, tobaccoType, isActive);
      } else {
        callSearchInvestmentAPI(currentPage, itemsPerPage, searchKeyword, tobaccoType, isActive);
      }
    }
  };

  const handleSearchChange = (event) => {
    const isActive =
        statusFilter === "Active" ? true : statusFilter === "Inactive" ? false : null;
    const { value } = event.target;
    setSearchKeyword(value);
    debouncedSearch(value, tobaccoType, isActive);
  };

  const debouncedSearch = useCallback(
    debounce((value, tobaccoType, isActive) => {
      console.log("StatusInside", isActive);
      callSearchInvestmentAPI(currentPage, itemsPerPage, value, tobaccoType, isActive)
    }, 300),
    [itemsPerPage]
  );

  return (
    <Layout
      background="#ffffff"
      title="Investment Registration Group List"
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      useNewPagination={true}
    >
      {/* Filters options */}
      <Grid
        container
        xs={12}
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          // padding: "1rem",
          padding: "8px",
          height: "130px",
        }}
      >
        <Grid item xs={12} sx={{ display: "flex" }}>
          <ArrowDropDownIcon sx={{ marginRight: 1 }} />
          <Typography>{Lable.filtersLable}</Typography>
        </Grid>

        {/* <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        > */}
        <Grid item xs={8} display="flex" justifyContent="space-between">
          <Box>
            {/* <FormControl sx={{ width: "35vw", flexGrow: 1 }}>
              <Autocomplete
                disablePortal
                onChange={(event, newValue) => {
                  // const newValue = event.target.value;

                  // console.log("called change", newValue);

                  if (newValue) {
                    setSearchKeyword(newValue);
                    callSearchInvestmentAPI(
                      0,
                      itemsPerPage,
                      newValue,
                      tobaccoType,
                      statusFilter
                    );
                  } else {
                    setSearchKeyword("");
                    callSearchInvestmentAPI(0, itemsPerPage, "", tobaccoType, statusFilter);
                    // console.log("zxcvbnm,");
                    // callTrayAPI(currentPage, itemsPerPage);
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  if (newInputValue) handleSearchChange(newInputValue);
                  else getfullInvestmentMenuAPI();
                }}
                options={menu}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "7px", // Adjust padding for the input content
                    minHeight: "50px", // Adjust the overall height
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1.2em", // Adjust label spacing if needed
                  },
                }}
                InputProps={{
                  style: { height: "60%" }, // Directly setting the height
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="trayType"
                    label="Investment Group Description"
                    fullWidth
                    placeholder="-"
                    InputLabelProps={{
                      shrink: true
                    }}
                    sx={{
                      '& .MuiInputBase-input::placeholder': {
                        opacity: 1,
                      },
                    }}
                  />
                )}
              />
            </FormControl> */}
            <TextField
                  autoComplete="off"
                  name="description"
                  label="Investment Group Description"
                  value={searchKeyword}
                  onChange={handleSearchChange}
                  placeholder="-"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    width: "35vw",
                    height: "46px",
                    '& .MuiInputBase-input::placeholder': {
                      opacity: 1,
                    },
                    "& .MuiOutlinedInput-root": {
                      padding: "7px",
                      minHeight: "50px",
                      // width: "10rem",
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "1.2em",
                    },
                  }}
                ></TextField>
          </Box>

          <Box>
            <FormControl sx={{ width: "13vw" }}>
              {/* <Autocomplete
                disablePortal
                onChange={(event, newValue) => {
                  console.log("called change", newValue);
                  if (newValue) {
                    settobaccoType(newValue);
                    callSearchInvestmentAPI(
                      0,
                      itemsPerPage,
                      searchKeyword,
                      newValue,
                    );
                  } else {
                    settobaccoType("");
                    callSearchInvestmentAPI(0, itemsPerPage, searchKeyword, "");
                    // callTrayAPI(currentPage, itemsPerPage);
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  if (newInputValue) handleTobaccoChange(newInputValue);
                  else getfullInvestmentMenuAPI();
                }}
                options={tobaccoTypeMenu}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "7px", // Adjust padding for the input content
                    minHeight: "50px", // Adjust the overall height
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1.2em", // Adjust label spacing if needed
                  },
                }}
                InputProps={{
                  style: { height: "60%" }, // Directly setting the height
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="tobaccoType"
                    label="Tobacco Type"
                    fullWidth
                  />
                )}
              /> */}
              <TextField
                select
                id="tobaccoType"
                name="tobaccoType"
                labelId="tobacco-select-label"
                label={"Tobacco Type"}
                value={tobaccoType || "tt"}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "7px", // Adjust padding for the input content
                    minHeight: "50px", // Adjust the overall height
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1.2em", // Adjust label spacing if needed
                  },
                }}
                onChange={(event) => {
                  const newValue = event.target.value;
                  console.log("called change", newValue);
                  if (newValue && newValue != "tt") {
                    settobaccoType(newValue);
                    callSearchInvestmentAPI(
                      0,
                      itemsPerPage,
                      searchKeyword,
                      newValue,
                      statusFilter
                    );
                  } else {
                    settobaccoType("");
                    callSearchInvestmentAPI(0, itemsPerPage, searchKeyword, "", statusFilter);
                    // callTrayAPI(currentPage, itemsPerPage);
                  }
                }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  style: { height: "50px" },
                  endAdornment: tobaccoType && (
                    <InputAdornment position="end">
                      <ClearIcon
                        onClick={() => {
                          settobaccoType("")
                          callSearchInvestmentAPI(0, itemsPerPage, searchKeyword, "", statusFilter);
                        }

                        }
                        // sx={{ mr: 2, cursor: "pointer" }}
                        style={{
                          width: "18px",
                          cursor: "pointer",
                          marginRight: "20px",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              >
                <MenuItem value="tt">
                  Select an Option
                </MenuItem>
                {tobaccoTypeFullList.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.cultureType} - {option.tobaccoGroup}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Box>

          {/* <Grid item xs={1} mr={-15}></Grid> */}

          <Box>
            <FormControl sx={{ width: "13vw" }}>
              {/* <FormControl sx={{ minWidth: "7rem" }}> */}
              <TextField
                select
                name="status"
                label="Status"
                value={statusFilter || "PL"}
                onChange={(event) => handleStatusChange(event.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "7px",
                    minHeight: "50px",
                    // width: "10rem",
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "1.2em",
                  },
                }}
                InputProps={{
                  endAdornment: statusFilter && statusFilter != "pl" && (
                    <InputAdornment position="end">
                      <ClearIcon
                        onClick={() => handleStatusChange("")}
                        // sx={{ mr: 2, cursor: "pointer" }}
                        style={{
                          width: "18px",
                          cursor: "pointer",
                          marginRight: "20px",
                        }}
                      />
                    </InputAdornment>
                  ),
                  style: { height: "50px" },
                }}
              // InputLabelProps={{ shrink: shrink }}
              >
                <MenuItem value="PL">
                  Select an option
                </MenuItem>
                <MenuItem
                  value="Active"
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: "#EFEFEF", // Customize this to your desired color
                    },
                    "&:hover": {
                      backgroundColor: "#EFEFEF", // Customize hover color
                    },
                  }}
                >
                  Active
                </MenuItem>
                <MenuItem
                  value="Inactive"
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: "#EFEFEF", // Customize this to your desired color
                    },
                    "&:hover": {
                      backgroundColor: "#EFEFEF", // Customize hover color
                    },
                  }}
                >
                  Inactive
                </MenuItem>
              </TextField>
            </FormControl>
          </Box>
        </Grid>

        <Grid item display="flex" justifyContent="flex-end" xs={4}>
          <Button
            sx={{
              borderRadius: 1,
              minWidth: 95,
              // minHeight: 44
              height: "60%",
              background: "#004F9F",
              //marginRight: "10px",
              //marginLeft:"20px"
              "&:hover": {
                backgroundColor: "#102A62",
              },
            }}
            variant="contained"
            startIcon={<AddCircleIcon />}
            onClick={() => navigate("/addInvestment")}
          >
            ADD NEW
          </Button>
        </Grid>
      </Grid>

      {/* Do not Render table header if no data is found */}
      {filteredInvestmentData.length === 0 ? (
        <Typography variant="h6" align="center" color="textSecondary">
          {label.noDataMessage}
        </Typography>
      ) : (
        <InvestmentTable
          setCulturenull={() => setInvestmentData([])}
          callInvestmentAPI={() => callInvestmentAPI(currentPage, itemsPerPage)}
          investmentData={filteredInvestmentData}
          refreshData={refreshData}
        />
      )}
    </Layout>
  );
};

export default InvestmentsGroupRegistration;
