import React, { createContext, useContext, useEffect, useState } from "react";
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { RenderRoutes } from "components/structure/RenderNavigations";
import UserService from "services/UserService";
import { useNavigate } from "react-router-dom";
import { SnackbarContext } from "context/snackBarContext";
import { useMsal } from "@azure/msal-react";

const msalConfig = {
  auth: {
    clientId: "74ae6837-169f-4b25-bb69-e2e04605dd49", // Replace with your Azure AD application client ID
    authority: "https://login.microsoftonline.com/MBLMFAAPP", // Replace with your Azure AD tenant ID
    redirectUri: "http://localhost:3001" // Replace with your redirect URI
  }
};

// const msalInstance = new PublicClientApplication(msalConfig);

const AuthContext = createContext();
export const AuthData = () => useContext(AuthContext);

export const AuthWrapper = (props) => {
  console.log('props', props)
  const [user, setUser] = useState(props.result);
  // const [isMsalInitialized, setIsMsalInitialized] = useState(false);
  // const [msalInstance, setMsalInstance] = useState(null);
  const [loading, setLoading] = useState("")
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const navigate = useNavigate();
  // const { instance, accounts } = useMsal();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  // useEffect(() => {
  //   let token = sessionStorage.getItem('accessToken');
  //   console.log('token', token);
  // }, [])
  // useEffect(() => {
  //   const checkLogin = async () => {
  //     const accountss = await instance.getAllAccounts();
  //     console.log('rrrrrrrrrrrr' ,accountss)
  //     setIsLoggedIn(accounts.length > 0);
  //   };

  //   checkLogin();
  // }, []);


  // useEffect(() => {
  //   const instance = new PublicClientApplication(msalConfig);
  //   setMsalInstance(instance);

  //   instance.handleRedirectPromise().then(account => {
  //     if (account) {
  //       setUser(account);
  //       scheduleTokenRefresh();
  //     }
  //   }).catch(error => {
  //     console.error("Redirect error:", error);
  //   });

  //   instance.addEventCallback((event) => {
  //     if (event.eventType === EventType.LOGIN_SUCCESS) {
  //       setIsMsalInitialized(true);
  //     }
  //   });

  //   return () => {
  //     instance.removeEventCallback();
  //   };
  // }, []);

  // useEffect(() => {
  //   let user = window.sessionStorage.getItem("BAT_USER");
  //   let result = window.sessionStorage.getItem("MSAL_AUTHENTICATION_RESULT");
  //   console.log('resultresult', result)

  //   if (result) {
  //     let parsedResult = JSON.parse(result);
  //     let batOneID = parsedResult?.account?.username;
  //     let split = batOneID.split("@")
  //     console.log('batOneIDbatOneID', split[0])

  //     if (batOneID) login(split[0])
  //   }

  //   console.log('ussssre', user)
  //   // if (!user) {
  //   //   navigate('/login')
  //   // } else {
  //   //   setUser(JSON.parse(user))
  //   // }
  // }, [])

  const login = async (batOneID) => {
    setLoading(true)
    UserService.FetchSingleUser(batOneID).then((res) => {
      setLoading(false)
      if (res?.data?.response?.active == false) {
        setSeverity("error");
        setMessage("User not active")
        setOpenAlert(true);
      } else {
        window.sessionStorage.setItem("BAT_USER", JSON.stringify(res?.data?.response));
        setUser(res?.data?.response)
        navigate("/")
        // setSeverity("success");
        // setMessage("Login success")
        // setOpenAlert(true);
      }
    }).catch((error) => {
      setLoading(false)
      setSeverity("error");
      setMessage(error?.response?.data?.message || error?.message)
      setOpenAlert(true);
    })

  };

  const logout = async () => {
    window.sessionStorage.removeItem("BAT_USER");
    msalLogout()
  };

  const msalLogout = async () => {
      // instance.logout({
      //   account: accounts[0],
      // });
  };

 


  // const scheduleTokenRefresh = () => {
  //   setTimeout(refreshToken, 30 * 60 * 1000); // Refresh token after 30 minutes
  // };

  // const refreshToken = async () => {
  //   try {
  //     if (user && msalInstance) {
  //       const silentRequest = {
  //         account: user
  //       };
  //       const tokenResponse = await msalInstance.acquireTokenSilent(silentRequest);
  //       scheduleTokenRefresh();
  //     } else {
  //       console.warn("User is not signed in or MSAL is not initialized. Token refresh skipped.");
  //     }
  //   } catch (error) {
  //     console.error("Token refresh error:", error);
  //   }
  // };
  return (
    <AuthContext.Provider value={{ loading, user, login, logout }}>
      <RenderRoutes />
    </AuthContext.Provider>
  );
};

export default AuthWrapper;
