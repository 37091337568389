import React, { Suspense, createContext, useEffect } from "react";
import "./App.css";
import { AuthWrapper } from "./auth/AuthWarpper";
import { SnackbarProvider } from "./context/snackBarContext";
import CustomSnackbar from "./utils/SnackBar";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from "@azure/msal-browser";
import RequestInterceptor from "./RequestInterceptor";
import { loginRequest } from "authConfig";
import Login from "components/pages/Login/Login";
import ErrorBoundary from "ErrorBoundary";
import { useLocation, useNavigate } from 'react-router-dom';
import TrackClicks from './TrackClicks';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
import { navigationHistory } from "utils/navigationHistory";
const browserHistory = createBrowserHistory({ basename: process.env.REACT_APP_BASE_URL });
var reactPlugin = new ReactPlugin();
export const UserContext = createContext()
function App() {
  navigationHistory.navigate = useNavigate();
  navigationHistory.location = useLocation();
  const location = useLocation();
  // const { result, error } = useMsalAuthentication(InteractionType.Redirect,{scopes: ["openid", "profile", "user.read"]});
  const { result, error } = useMsalAuthentication(InteractionType.Redirect,
    // {scopes:[]
    // { scopes: ['api://a00f11a6-049d-4707-b5db-c5bb6bcba1db/Agri360-test'] } //dinesh ji
    { scopes: [process.env.REACT_APP_SSO_SCOPE] } //bat
    // {scopes: ['api://fc95b423-54c9-45f5-bd9c-ab7577e73ed7/SP-AGP-WEBAGRI360-DEV-01']} //bivash
    // {scopes: ['api://42a5f597-9c31-452d-94ac-7f666160a3c2/test-newapp-newad-p1']} //test-newapp-newad-p1


    // {scopes: ["api://e1fe6dd8-ba31-4d61-89e7-88639da4683d/"]}

  )


  // const { result, error } = useMsalAuthentication(InteractionType.Redirect,{scopes: ["directory.read.all,user.read"]});


  // const { instance, account } = useMsal();
  const [user, setUser] = React.useState(

    // {
    //       "userName": "Local Admin 1",
    //       "profileCode": "L_ADMIN",
    //       "profileName": "Local Admin",
    //       "profileAttribute": "Local",
    //       "batOneId": 999101,
    //       "countryCode": "BRA",
    //       "countryName": "BRAZIL",
    //       "email": "Localadmin1@bat.com",
    //       "legacyCode": 12346,
    //       "active": true,
    //       "contactNumber": null,
    //       "modifiedTime": "2024-04-15T07:39:01.820Z",
    //       "functionalities": [
    //           "Manage User"
    //       ],
    //       "userAttachmentsDtoList": [
    //           {
    //               "fileName": "file-sample_100kB.docx",
    //               "fileContent": null,
    //               "fileUrl": "https://batsalenagriesgnprod01.blob.core.windows.net/agri360esguserdata/Sri%20Lanka%2F20240415%2F999101%2Ffile-sample_100kB.docx",
    //               "country": null,
    //               "mimeType": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    //               "batOneId": null
    //           }
    //       ],
    //       "createdByUser": null,
    //       "modifiedByUser": null
    //   }

    {
      "userName": "Global Admin 1",
      "profileCode": "G_ADMIN",
      "profileName": "Global Admin 1",
      "profileAttribute": "Global",
      "batOneId": 999100,
      "countryCode": "BRA",
      "countryName": "BRAZIL",
      "email": "Globaladmin1@bat.com",
      "legacyCode": 12345,
      "active": true,
      "contactNumber": null,
      "modifiedTime": "2024-04-15T07:28:45.550Z",
      "functionalities": [],
      "userAttachmentsDtoList": [
        {
          "fileName": "test.eml",
          "fileContent": null,
          "fileUrl": "https://batsalenagriesgnprod01.blob.core.windows.net/agri360esguserdata/BRAZIL%2F20240415%2F999100%2Ftest.eml",
          "country": null,
          "mimeType": "message/rfc822",
          "batOneId": null
        },
        {
          "fileName": "file-sample_100kB.docx",
          "fileContent": null,
          "fileUrl": "https://batsalenagriesgnprod01.blob.core.windows.net/agri360esguserdata/BRAZIL%2F20240415%2F999100%2Ffile-sample_100kB.docx",
          "country": null,
          "mimeType": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "batOneId": null
        }
      ],
      "createdByUser": null,
      "modifiedByUser": null
    }
  );

  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: process.env.REACT_APP_LOGGER_INSTRUMENT_STRING,
      enableAutoRouteTracking: true,
      enableDebug: true, // Enable debug mode for development
      // *** If you're adding the Click Analytics plug-in, delete the next line. ***
      // extensions: [reactPlugin],
      // *** Add the Click Analytics plug-in. ***
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
        // *** Add the Click Analytics plug-in. ***
      }
    }
  });
  useEffect(() => {
    appInsights.loadAppInsights();
    // Set up global error handler
    const errorHandler = (error) => {
      appInsights.trackException({ error });
    };
    window.onerror = errorHandler;
    window.addEventListener('unhandledrejection', event => {
      errorHandler(event.reason);
    });
    // Optionally track page views
    appInsights.trackPageView({ name: location.pathname });
    return () => {
      window.onerror = null;
      window.removeEventListener('unhandledrejection', errorHandler);
    };
  }, [location.pathname]);



  // console.log('result', result)
  // console.log('error', error)

  // const handleLogin = () => {
  //   alert('enter login')
  //   instance.loginRedirect(loginRequest).then((res) => {
  //     console.log('login rep', res)
  //   }).catch((e) => {
  //     console.log(e);
  //   });
  // };

  // if (result) {
  //   if (window) window.sessionStorage.setItem('MSAL_AUTHENTICATION_RESULT', JSON.stringify(result))
  // }



  if (!error) {
    return (
      // <ErrorBoundary>
      <>
        <TrackClicks appInsights={appInsights} />
        {/* <AuthenticatedTemplate> */}
          {/* <RequestInterceptor> */}
            <Suspense fallback="...loading">
              <SnackbarProvider>
                <UserContext.Provider value={user}>
                  <AuthWrapper result={user} />
                  <CustomSnackbar />
                </UserContext.Provider>
              </SnackbarProvider>
            </Suspense>
          {/* </RequestInterceptor> */}
        {/* </AuthenticatedTemplate> */}
        {/* <UnauthenticatedTemplate> */}
          {/* <div onClick={() => { handleLogin() }}>Login</div> */}
        {/* </UnauthenticatedTemplate> */}
      </>
      // </ErrorBoundary>
      //   <>
      //       <Suspense fallback="...loading">
      //         <SnackbarProvider>
      //           <UserContext.Provider value={user}>
      //             <AuthWrapper />
      //             <CustomSnackbar />
      //           </UserContext.Provider>
      //         </SnackbarProvider>
      //       </Suspense>
      // </>
    )
  }


}

export default App;
