import React, { useState } from "react";
import "./InvestmentTable.css";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Edit from "assets/EditNew.svg";
import Delete from "assets/DeleteNew.svg";
import { Box, Button, Modal, Tooltip, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GreenManureService from "services/GreenManureService";
import { useContext } from "react";
import { SnackbarContext } from "context/snackBarContext";
import InvestmentService from "services/InvestmentService";
import {DELETE_SUCCESS_MSG} from "utils/AgriErrorMsg"


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 580,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  // p: 4,
};

const InvestmentTable = (props) => {
  console.log("🚀 ~ InvestmentTable ~ greenManure:", props.investmentData);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  console.log("🚀 ~ InvestmentTable ~ data:", data);
  const [isAscending, setIsAscending] = useState(true);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  const handleOpen = (id) => {
    setDeleteId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteId(null);
  };

  const handleDelete = async () => {
    try {
      await InvestmentService.deleteInvestmentGroup({ id: deleteId });
      console.log(`Manure with id ${deleteId} deleted successfully.`);
      setData(data.filter((item) => item.igInvestmentGroupId !== deleteId));
      handleClose();
      setSeverity("success");
      setMessage(DELETE_SUCCESS_MSG);
      setOpenAlert(true);
      props.refreshData()
    } catch (error) {
      console.error(`Failed to delete manure with id ${deleteId}:`, error);
    }
  };

  // useEffect(() => {
  //   if (greenManure) {
  //     setData(greenManure);
  //   }
  // }, [greenManure]);
  useEffect(() => {
    setData(props.investmentData);
    // setOriginalData(props.investmentData);
  }, [props.investmentData]);

  // const handleSort = () => {
  //   const sortedData = [...data].sort((a, b) => {
  //     if (isAscending) {
  //       return a.status > b.status ? 1 : -1;
  //     } else {
  //       return a.status < b.status ? 1 : -1;
  //     }
  //   });

  //   setData(sortedData);
  //   setIsAscending(!isAscending);
  // };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setData(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        return <ArrowUpwardIcon fontSize="small" />;
      } else if (sortConfig.direction === "desc") {
        return <ArrowDownwardIcon fontSize="small" />;
      }
    }
    // Default icon (ArrowDownward) when no sorting is applied
    return <ArrowDownwardIcon fontSize="small" />;
  };

  // const handleSorttobaccoType = () => {
  //   const sortedData = [...data].sort((a, b) => {
  //     if (isAscending) {
  //       return a.tobaccoType > b.tobaccoType ? 1 : -1;
  //     } else {
  //       return a.tobaccoType < b.tobaccoType ? 1 : -1;
  //     }
  //   });

  //   setData(sortedData);
  //   setIsAscending(!isAscending);
  // };


  // const handleSortigInvestmentGroupDescription = () => {
  //   const sortedData = [...data].sort((a, b) => {
  //     if (isAscending) {
  //       return a.igInvestmentGroupDescription > b.igInvestmentGroupDescription ? 1 : -1;
  //     } else {
  //       return a.igInvestmentGroupDescription < b.igInvestmentGroupDescription ? 1 : -1;
  //     }
  //   });

  //   setData(sortedData);
  //   setIsAscending(!isAscending);
  // };



  return (
    <div className="table-container">
      <table className="custom-table">
        <thead>
          <tr>
            <th
              onClick={() => handleSort("igInvestmentGroupDescription")}
              style={{
                cursor: "pointer",
                display: "flex",
                // justifyContent: "space-between",
              }}
            >
              Investment Group Description{getSortIcon("igInvestmentGroupDescription")}
              {/* {isAscending === true ? (
                <SouthIcon sx={{ ml: 2, color: "#3B454F", fontSize: "18px" }} />
              ) : (
                <NorthIcon sx={{ ml: 2, color: "#3B454F", fontSize: "18px" }} />
              )} */}
            </th>
            <th
              onClick={() => handleSort("tobaccoType")}
              style={{
                cursor: "pointer",
                display: "flex",
                // justifyContent: "space-between",
              }}
            >
              Tobacco Type{getSortIcon("tobaccoType")}
              {/* {isAscending === true ? (
                <SouthIcon sx={{ ml: 2, color: "#3B454F", fontSize: "18px" }} />
              ) : (
                <NorthIcon sx={{ ml: 2, color: "#3B454F", fontSize: "18px" }} />
              )} */}
            </th>
            <th
              onClick={() => handleSort("isActive")}
              style={{
                cursor: "pointer",
                display: "flex",
                // justifyContent: "space-between",
              }}
            >
              Status{getSortIcon("isActive")}
              {/* {isAscending === true ? (
                <SouthIcon sx={{ ml: 2, color: "#3B454F", fontSize: "18px" }} />
              ) : (
                <NorthIcon sx={{ ml: 2, color: "#3B454F", fontSize: "18px" }} />
              )} */}
            </th>

            <th className="actions-header">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/viewInvestment", {
                    state: {
                      investmentGroupIdEdit: row.igInvestmentGroupId,
                      statusEdit: row.isActive,
                      createdByUserEdit: row.createdByUser,
                      modifiedByUserEdit: row.modifiedByUser,
                      investmentGroupDescriptionEdit: row.igInvestmentGroupDescription,
                      investmentsEdit: row.investements,
                      tobaccoTypeEdit: row.tobaccoTypeId,
                      details: true,
                    },
                  })
                }
              >
                {row.igInvestmentGroupDescription}
              </td>
              <td
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/viewInvestment", {
                    state: {
                      investmentGroupIdEdit: row.igInvestmentGroupId,
                      statusEdit: row.isActive,
                      createdByUserEdit: row.createdByUser,
                      modifiedByUserEdit: row.modifiedByUser,
                      investmentGroupDescriptionEdit: row.igInvestmentGroupDescription,
                      investmentsEdit: row.investements,
                      tobaccoTypeEdit: row.tobaccoTypeId,
                      details: true,
                    },
                  })
                }
              >
                {row.tobaccoType}
              </td>
              <td
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/viewInvestment", {
                    state: {
                      investmentGroupIdEdit: row.igInvestmentGroupId,
                      statusEdit: row.isActive,
                      createdByUserEdit: row.createdByUser,
                      modifiedByUserEdit: row.modifiedByUser,
                      investmentGroupDescriptionEdit: row.igInvestmentGroupDescription,
                      investmentsEdit: row.investements,
                      tobaccoTypeEdit: row.tobaccoTypeId,
                      details: true,
                    },
                  })
                }
              >
                {row.isActive === true ? "Active" : "Inactive"}
              </td>
              <td className="actions-cell">
                <Tooltip
                  title="EDIT"
                  placement="top"
                  arrow
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -5],
                        },
                      },
                    ],
                  }}
                  >
                  <img
                    className="btn edit-btn"
                    style={{ cursor: "pointer", marginRight: "10px" }}
                    onClick={() =>
                      navigate("/editInvestment", {
                        state: {
                          investmentGroupIdEdit: row.igInvestmentGroupId,
                          statusEdit: row.isActive,
                          createdByUserEdit: row.createdByUser,
                          modifiedByUserEdit: row.modifiedByUser,
                          investmentGroupDescriptionEdit: row.igInvestmentGroupDescription,
                          investmentsEdit: row.investements,
                          tobaccoTypeEdit: row.tobaccoTypeId,
                          edit: true,
                        },
                      })
                    }
                    src={Edit}
                    alt="Edit"
                  />
                </Tooltip>

                <Tooltip
                  title="DELETE"
                  placement="top"
                  arrow
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -5],
                        },
                      },
                    ],
                  }}
                  >
                  <img
                    style={{ cursor: "pointer" }}
                    className="btn delete-btn"
                    onClick={() => handleOpen(row.igInvestmentGroupId)}
                    src={Delete}
                    alt="Delete"
                  />
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
            >
              Delete Confirmation
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, fontWeight: 400 }}
            >
              Are you sure you want to delete this record? Deleted records
              cannot be recovered.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              mb: 1,
            }}
          >
            <Button
              onClick={()=>handleClose()}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": {
                  background: "#737D86",
                },
              }}
            >
              No
            </Button>
            <Button
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": {
                  background: "#004F9F",
                },
              }}
              onClick={()=>handleDelete()}
            >
              {" "}
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default InvestmentTable;
