// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div:has(.moduleContainer) {
    padding-bottom: 4rem;
    background: #F5F5F7;
}

/* .CollapseImage1:hover {
    cursor: pointer;
    transform: scale(1.2);
    background-image:url('../../../assets/ExpandAllBlue.svg') ;
}

.expand-all:hover {
    fill: red;
}

.CollapseImage2:hover {
    background-color: blue !important;
    cursor: pointer;

    transform: scale(0.5);
} */

.collapseImageContainer {
    position: relative;
    cursor: pointer;
    /* overflow: hidden; */
}

.collapseImageContainer.clicked .CollapseImage2 {
    opacity: 1;
    transform: scale(1.2);
}



.CollapseImage2 {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.collapseImageContainer:hover .CollapseImage2 {
    opacity: 1;
    transform: scale(1.2);

}


.collapseImageContainer1 {
    position: relative;
    cursor: pointer;
}

.collapseImageContainer1.clicked .CollapseImage22 {
    opacity: 1;
    transform: scale(1);
}



.CollapseImage22 {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transform: scale(1.2);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.collapseImageContainer1:hover .CollapseImage22 {
    opacity: 1;
        transform: scale(1);

}`, "",{"version":3,"sources":["webpack://./src/components/pages/ModulesRegistration/Module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;;;;;;;;;;;;;;;GAeG;;AAEH;IACI,kBAAkB;IAClB,eAAe;IACf,sBAAsB;AAC1B;;AAEA;IACI,UAAU;IACV,qBAAqB;AACzB;;;;AAIA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,UAAU;IACV,qBAAqB;IACrB,kDAAkD;AACtD;;AAEA;IACI,UAAU;IACV,qBAAqB;;AAEzB;;;AAGA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,mBAAmB;AACvB;;;;AAIA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,UAAU;IACV,qBAAqB;IACrB,kDAAkD;AACtD;;AAEA;IACI,UAAU;QACN,mBAAmB;;AAE3B","sourcesContent":["div:has(.moduleContainer) {\n    padding-bottom: 4rem;\n    background: #F5F5F7;\n}\n\n/* .CollapseImage1:hover {\n    cursor: pointer;\n    transform: scale(1.2);\n    background-image:url('../../../assets/ExpandAllBlue.svg') ;\n}\n\n.expand-all:hover {\n    fill: red;\n}\n\n.CollapseImage2:hover {\n    background-color: blue !important;\n    cursor: pointer;\n\n    transform: scale(0.5);\n} */\n\n.collapseImageContainer {\n    position: relative;\n    cursor: pointer;\n    /* overflow: hidden; */\n}\n\n.collapseImageContainer.clicked .CollapseImage2 {\n    opacity: 1;\n    transform: scale(1.2);\n}\n\n\n\n.CollapseImage2 {\n    position: absolute;\n    top: 0;\n    left: 0;\n    opacity: 0;\n    transform: scale(0.9);\n    transition: opacity 0.3s ease, transform 0.3s ease;\n}\n\n.collapseImageContainer:hover .CollapseImage2 {\n    opacity: 1;\n    transform: scale(1.2);\n\n}\n\n\n.collapseImageContainer1 {\n    position: relative;\n    cursor: pointer;\n}\n\n.collapseImageContainer1.clicked .CollapseImage22 {\n    opacity: 1;\n    transform: scale(1);\n}\n\n\n\n.CollapseImage22 {\n    position: absolute;\n    top: 0;\n    left: 0;\n    opacity: 0;\n    transform: scale(1.2);\n    transition: opacity 0.3s ease, transform 0.3s ease;\n}\n\n.collapseImageContainer1:hover .CollapseImage22 {\n    opacity: 1;\n        transform: scale(1);\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
