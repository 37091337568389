// src\services\LocalityConfigurationService.jsx
import { httpGrow } from "../http-common";

// User Story constants
const basename = "localityFieldTechnicianConfig";

const getAPI = async (query) => {
    const { pageNumber = 0, size = 50, keyword = '',postalcode, stateCode, cityName } = query;
    const response = await httpGrow.get(`/${basename}/search`, {
        params: {
            pageNumber,
            pageSize: size,
            locality: keyword || undefined,
            localityPostalCode: postalcode || undefined,
            stateCode: stateCode || undefined,
            cityName: cityName || undefined,
        }
    });
    return response;
};

const updateAPI = (data) => {
    return httpGrow.put(`/${basename}`, data);
};

const getCityAPI = async () => {
    const response = await httpGrow.get(`/city`);
    return response;
};

const getRegionAPI = async () => {
    const response = await httpGrow.get(`/region`);
    return response;
};

const LocalityConfigurationService = {
    getAPI,
    updateAPI,
    getCityAPI,
    getRegionAPI,
};

export default LocalityConfigurationService;
