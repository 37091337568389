import React, { useState, useEffect, useContext } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FurnaceService from "services/FurnaceService";
import SoilParameterService from "services/SoilParameterService";
import TransporterGetService from "services/TransporterGetService";
import Lable from "utils/lables.json";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import TransporterTable from "./TransporterTable";
import { Transcribe } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import search from "assets/search.svg";

const GetTransporterRegistration = () => {
  const navigate = useNavigate();
  const [soilParameterData, setSoilParameterData] = useState([]);
  const [transporterData, setTransporterData] = useState([]);
  const [filteredSoilParameterData, setFilteredSoilParameterData] = useState(
    []
  );
  const [filteredTransporterData, setFilteredTransporterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [menu, setMenu] = useState([]);
  const [fieldTech, setFieldTech] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");

  const handleSearchChange = (value) => {
    setSearchKeyword(value);
  };

  const handleStatusChange = (status) => {
    setStatusFilter(status); // Update the status filter
    setCurrentPage(0); // Reset to the first page when the status changes

    const isActive =
      status === "Active" ? true : status === "Inactive" ? false : null;

    callSearchSoilParameterAPI(0, itemsPerPage, searchKeyword, isActive);
  };

  const callTransporterAPI = (pageNumber, size) => {
    setLoading(true);
    TransporterGetService.getTransporter({ pageNumber, size })
      .then((res) => {
        const transporterResults = res?.data?.response?.transportDetails || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        console.log("dsd", transporterResults);
        setTransporterData(transporterResults);
        setFilteredTransporterData(transporterResults);
        setTotalItems(res?.data?.response?.totalItems || 0);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setTransporterData([]);
        setFilteredTransporterData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getfullTransporterAPI = () => {
    setLoading(true);
    TransporterGetService.getTransporter({ pageNumber: currentPage, size: 50 })
      .then((res) => {
       
        const transporterResults = res?.data?.response?.transportDetails || [];
        // console.log();
        const transporterList = transporterResults.map((e) => e.transporterName || []);
        const fieldTechList = [...new Set(transporterResults.map((e) => e.fieldTechnicianUser||'')
          .filter((user) => user.trim() !== '') )];
        setMenu(transporterList);
        console.log("set ",fieldTechList)
        setFieldTech(fieldTechList)
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setTransporterData([]);
        setFilteredTransporterData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const callSearchSoilParameterAPI = (pageNumber, size, Keyword, status) => {
    setLoading(true);
    setSearchKeyword(Keyword);

    let query = {
      pageNumber,
      size,
      sapParameterName: Keyword,
    };

    const params = [];

    if (query?.pageNumber !== undefined) {
      params.push(`pageNumber=${query.pageNumber}`);
    }
    if (query?.size !== undefined) {
      params.push(`pageSize=${query.size}`);
    }
    if (query?.sapParameterName) {
      params.push(`sapParameterName=${query.sapParameterName}`);
    }
    if (status !== null && status !== undefined) {
      params.push(`sapStatus=${status}`);
    }
    const queryString = params.length ? `?${params.join("&")}` : "";

    SoilParameterService.searchSoilParameter(queryString)
      .then((res) => {
        const Results = res?.data?.response?.soilAnalysisParameters || [];
        setCurrentPage(pageNumber);
        setItemsPerPage(size);
        setSoilParameterData(Results);
        setFilteredSoilParameterData(Results);
        setTotalItems(res?.data?.response?.totalItems || 0);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setSoilParameterData([]);
        setFilteredSoilParameterData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    callTransporterAPI(currentPage, itemsPerPage);
    if (currentPage <= 0) {
      getfullTransporterAPI();
    }
  }, [currentPage, itemsPerPage]);

  const paginate = (pageNumber, itemsPerPage) => {
    setCurrentPage(pageNumber - 1);
    if (searchKeyword) {
      callSearchSoilParameterAPI(pageNumber - 1, itemsPerPage, searchKeyword);
    } else {
      callTransporterAPI(pageNumber - 1, itemsPerPage);
    }
  };

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={"Transporter Registration"}
      background="#ffffff"
      useNewPagination={true}
    >
      <Box
        sx={{
          background: "#EFEFEF",
          borderRadius: 1,
          padding: 2,
          height: "auto",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ArrowDropDownIcon sx={{ marginRight: 1 }} />
          <Typography>{Lable.filtersLable}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            //justifyContent: "space-between",
            gap: 2,
          }}
        >
          <FormControl sx={{ flex: 1, maxWidth: "30rem", width: "100%" }}>
            <Autocomplete
              disablePortal
              onChange={(event, newValue) => {
                if (newValue) {
                  setSearchKeyword(newValue);
                  callSearchSoilParameterAPI(0, itemsPerPage, newValue);
                } else {
                  setSearchKeyword("");
                  callSearchSoilParameterAPI(0, itemsPerPage, "");
                }
              }}
              onInputChange={(event, newInputValue) => {
                if (newInputValue) handleSearchChange(newInputValue);
                else getfullTransporterAPI();
              }}
              options={menu}
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "0.438rem",
                  minHeight: "3.125rem",
                },
              }}
              InputProps={{
                style: { height: "3.125rem" },
              }}
              renderInput={(params) => (
                <TextField {...params} label="Transporter Name" fullWidth />
              )}
            />
          </FormControl>

          <FormControl sx={{ flex: 1, maxWidth: "23rem", width: "100%" }}>
            <Autocomplete
              disablePortal
              onChange={(event, newValue) => {
                if (newValue) {
                  setSearchKeyword(newValue);
                  // callSearchFurnaceAPI(0, itemsPerPage, newValue);
                } else {
                  setSearchKeyword("");
                  // callSearchFurnaceAPI(0, itemsPerPage, "");
                }
              }}
              onInputChange={(event, newInputValue) => {
                if (newInputValue) handleSearchChange(newInputValue);
                else getfullTransporterAPI();
              }}
              options={fieldTech}
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "0.438rem",
                  minHeight: "3.125rem",
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Field Technician"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={search} alt="search" />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>

          <FormControl sx={{ flex: 1, maxWidth: "20rem", width: "100%" }}>
            <TextField
              select
              name="status"
              label="Status"
              value={statusFilter || ""}
              onChange={(event) => handleStatusChange(event.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "0.438rem",
                  minHeight: "3.125rem",
                },
                "& .MuiInputLabel-root": {
                  lineHeight: "1.2em",
                },
              }}
              InputProps={{
                style: { height: "50px" },
              }}
            >
              <MenuItem value="pl">Select an option</MenuItem>
              {/* <MenuItem value="">
                  <em>None</em>
                </MenuItem> */}
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Inactive">Inactive</MenuItem>
              <MenuItem value="Active">Pending</MenuItem>
              <MenuItem value="Inactive">Blocked</MenuItem>
            </TextField>
          </FormControl>

          <Button
            variant="contained"
            startIcon={<AddCircleIcon />}
            sx={{
              minWidth: 95,
              borderRadius: 1,
              background: "#004F9F",
              height: { xs: "40px", md: "44px" },
              marginLeft: { xs: 0, md: "auto" },
            }}
            onClick={() => navigate("/addtransporterRegistration")}
          >
            ADD NEW
          </Button>
        </Box>
      </Box>
      <TransporterTable transporterData={transporterData} />
    </Layout>
  );
};

export default GetTransporterRegistration;
