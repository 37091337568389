// src\services\ProductionTargetsService.jsx
import { httpGrow } from "../http-common";

const getAPI = async (query) => {
    const { cropYearUUID, ihId } = query;
    const response = await httpGrow.get(`/investmentGLDistribution`, {
        params: {
            cropYearUUID,
            ihId
        }
    });
    return response;
};

const getCropSeasonAPI = async () => {
    const response = await httpGrow.get('/cropYears');
    return response;
};

const getHierarchyNodeAPI = async () => {
    const response = await httpGrow.get('/importHierarchyVersion/tree');
    return response;
};

const getInvestmentGroupListAPI = async (query) => {
    const { investmentGroupDescription } = query;
    const response = await httpGrow.get('investment-group/find?pageNumber=0&size=50', {
        params: {
            investmentGroupDescription: investmentGroupDescription || ""
        }
    });
    return response;
};

const putInvestmentGLDAPI = (data) => {
    return httpGrow.put('investmentGLDistribution', data);
};

const deleteInvestmentDistributionAPI = async (query) => {
    const { investmentGroupID , cropYearUUID } = query;
    const response = await httpGrow.delete(`/investmentGLDistribution/${investmentGroupID}`, {
        params: {
            cropYearUUID
        }
    });
    return response;
};

const InvestmentGroupLimitsService = {
    getAPI,
    getCropSeasonAPI,
    getHierarchyNodeAPI,
    getInvestmentGroupListAPI,
    putInvestmentGLDAPI,
    deleteInvestmentDistributionAPI
};

export default InvestmentGroupLimitsService;
