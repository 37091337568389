import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import FormControlLabel from "@mui/material/FormControlLabel";
import RedSwitch from "./RedSwitch";
import { Button } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { FormControl, FormLabel } from "@mui/material";
import { Height } from "@mui/icons-material";
import InputAdornment from '@mui/material/InputAdornment';

const RenderField = ({
  field,
  values,
  errors,
  handleChange,
  handleDateChange,
  handleClear,
  details,
  width,
  shrink
}) => {
  console.log("🚀 ~ RenderField ~ update:", field);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // const handleDropdownToggle = () => {
  //   setDropdownOpen(!dropdownOpen);
  // };
  switch (field?.type) {
    case "text":
      return (
        <TextField
          key={field.name}
          value={values[field.name] || ""}
          onChange={handleChange}
          name={field.name}
          error={!!errors[field.name]}
          helperText={errors[field.name]}
          margin="normal"
          label={field.label}
          required={field.required}
          variant="outlined"
          disabled={field.disabled || details}
          placeholder={field.name === "freight" || field.name === "igInvestmentGroupDescription" ? "-" : ""}
          // style = {{width: 400,height:56,marginLeft:38}}
          sx={{
            borderRadius: "2px",
            width: `${width} !important`,
            ".MuiOutlinedInput-root.Mui-disabled": {
              "& fieldset": {
                borderColor: "gray",
                borderWidth: "1px",
              },
              "&:hover fieldset": {
                borderColor: "gray",
                borderWidth: "1px",
              },
            },
            '& .MuiInputBase-input::placeholder': {
              opacity: field.name === "freight" || field.name === "igInvestmentGroupDescription" ? 1 : 0,
            },
            "& .MuiInputLabel-root": {
              "&.Mui-disabled": {
                color: "#102a62",
              },
            },
          }}
          InputLabelProps={{ shrink: shrink }}
        />
      );
    case "code":
      return (
        <TextField
          key={field.name}
          value={values[field.name] || ""}
          onChange={handleChange}
          name={field.name}
          error={!!errors[field.name]}
          helperText={errors[field.name]}
          margin="normal"
          label={field.label}
          required={field.required}
          variant="outlined"
          disabled={field.disabled || details}
          style={{ width: "226px", height: 56 }}
          sx={{ borderRadius: "2px" }}
        />
      );
    case "date":
      const isDateOfBirthField = field.name === 'dateOfBirth';
      const maxDate = isDateOfBirthField ? dayjs() : null;
      return (
        <LocalizationProvider key={field.name} dateAdapter={AdapterDayjs}>
          <DatePicker
            label={
              <>
                {field.label}
                {field.required && (
                  <span style={{ color: '#FF0000' }}> *</span> // Red color for asterisk
                )}
              </>
            }
            value={values[field.name] ? dayjs(values[field.name]) : null}
            onChange={(date) => handleDateChange(date, field.name)}
            maxDate={maxDate}
            slotProps={{
              textField: {
                error: !!errors[field.name],
                helperText: errors[field.name],
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name={field.name}
                error={!!errors[field.name]}
                helperText={errors[field.name]}
                required={field.required}
                disabled={field.disabled || details}
                variant="outlined"
              />
            )}
          />
        </LocalizationProvider>
      );
    case "select":

      return (
        <TextField
          key={field.name}
          select
          label={field.label}
          value={values[field.name] || "PL"}
          onChange={handleChange}
          name={field.name}
          error={!!errors[field.name]}
          helperText={errors[field.name]}
          margin="normal"
          variant="outlined"
          sx={{
            "& .MuiOutlinedInput-root": {
              "&.Mui-disabled": {
                "& fieldset": {
                  borderColor: "gray",
                  borderWidth: "1px",
                },
                "&:hover fieldset": {
                  borderColor: "gray",
                  borderWidth: "1px",
                },
              },
            },
            "& .MuiInputLabel-root": {
              "&.Mui-disabled": {
                color: "#102a62",
              },
            },
          }}
          required={field.required}
          disabled={field.disabled || details}
          InputLabelProps={{ shrink: shrink }}
          SelectProps={{
            // IconComponent: ArrowDropDownIcon,
            open: dropdownOpen,
            onOpen: () => setDropdownOpen(true),
            onClose: () => setDropdownOpen(false),
            displayEmpty: true,
            MenuProps: {
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
            },
          }}
          InputProps={{
            endAdornment: values[field.name] && (
              <>
                <InputAdornment position="end">
                  <CloseIcon
                    onClick={() => !field.disabled && !details && handleClear(field.name)}
                    style={{
                      cursor: !field.disabled && !details ? "pointer" : "default",
                      marginLeft: "auto",
                      // paddingRight: "20px",
                    }}
                  />
                </InputAdornment>
                <InputAdornment position="end">
                  <ArrowDropDownIcon
                    onClick={(event) => !field.disabled && !details && setDropdownOpen(!dropdownOpen)}
                    style={{
                      width: "10px",
                      cursor: !field.disabled && !details ? "pointer" : "default",
                      // marginLeft: "28px",
                    }}
                  />
                </InputAdornment>
              </>
            ),
          }}
        >
          <MenuItem value="PL" disabled sx={{ display: "none" }}>
            Select an option
          </MenuItem>
          {field.options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              sx={{
                backgroundColor: option.label === values[field.name] ? "#D3D3D3 !important" : "transparent !important",
                '&:hover': {
                  backgroundColor: '#D3D3D3 !important',
                },
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      );
    case "number": return (
      <TextField
        key={field.name}
        type="number"
        value={values[field.name] || ""}
        onChange={handleChange}
        name={field.name}
        error={!!errors[field.name]}
        helperText={errors[field.name]}
        margin="normal"
        label={field.label}
        required={field.required}
        variant="outlined"
        disabled={field.disabled || details}
        // style = {{width: 400,height:56,marginLeft:38}}
        sx={{ borderRadius: "2px" }}
        InputProps={{
          endAdornment: field.unit ? (
            <InputAdornment position="end">
              {field.unit}
            </InputAdornment>
          ) : null,
        }}
      />
    );
    case "switch":
      // console.log("label",field)
      return (
        <FormControl component="fieldset" sx={{ paddingTop: 1 }}>
          <FormLabel
            sx={{
              color: "#0E2B63",
              mb: 1.0,
              fontWeight: "500",
              ml: 2,
              "&.Mui-focused": { color: "#0E2B63" },
              "&:hover": { color: "#0E2B63" },
            }}
          >
            {field.label}
            {field.required && <span style={{ color: "red" }}> *</span>}
          </FormLabel>
          <FormControlLabel
            key={field.name}

            control={
              <RedSwitch
                checked={values[field.name]}
                onChange={handleChange}
                disabled={field.disabled || details}
                name={field.name}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={values[field.name] ? "Active" : "Inactive"}
            labelPlacement="start"
            sx={{ ml: 2, "& .MuiFormControlLabel-label": { fontWeight: 500 } }}
          />
        </FormControl>
      );
    case "switch-two-label":
      return (

        <div style={{ display: "flex" }}>
          <div style={{
            color: '#102A62',
            marginBottom: "4px",
            fontFamily: "Montserrat",
            fontSize: '18px',
            fontWeight: 500,
            lineHeight: '20px',
            marginTop: "10px",
            marginLeft: "10px",
          }}>
            <div style={{ color: '#102A62', marginBottom: "8px" }}> Third-Party Agriculture Supply ?</div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ color: '#000000' }}>No</span>
              <RedSwitch
                checked={values[field.name]}
                onChange={handleChange}
                sx={{ marginLeft: '10px', marginRight: '10px' }}
                disabled={field.disabled}
                name={field.name}
                inputProps={{ "aria-label": "controlled" }}
              />
              <span style={{ color: '#000000' }}>Yes</span>
            </div>
          </div>
          <Button
            sx={{
              borderRadius: 1,
              background: "#004F9F",
              marginTop: 3,
              marginLeft: 2,
              height: { xs: "auto", md: "auto" },
            }}
            variant="contained"
            onClick={(e) => handleChange(e, 'Manage Price Button')}
            disabled={!values[field.name]}
            endIcon={<ArrowRightAltIcon />}
          >
            Manage Price List
          </Button>
        </div>
      );
    default:
      return null;
  }
};

export default RenderField;
